<div class="modal-body">
  <button type="button" class="close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close" (click)="onHandleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3" *ngIf="type === 'CL' && patients?.length === 1">{{ 'modals.edit_cl_title.single' | translate }}</h2>
  <h2 class="mb-3" *ngIf="type === 'CL' && patients?.length > 1">{{ 'modals.edit_cl_title.multiple' | translate : {value:patients?.length } }}</h2>
  <h2 class="mb-3" *ngIf="type === 'CM' && patients?.length === 1">{{ 'modals.edit_cm_title.single' | translate }}</h2>
  <h2 class="mb-3" *ngIf="type === 'CM' && patients?.length > 1">{{ 'modals.edit_cm_title.multiple' | translate : {value:patients?.length }  }}</h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <app-select 
        [placeholder]="placeholder" 
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hcpList"
        [searchFn]="customSearchFn"
        (search)="searchHcpList($event)"
        [bindValue]="'value'"
        formControlName="hcp"
        [asFilter]="false" 
        [showCheckboxes]="false"
        [showUserDetails]="true" 

      ></app-select>
      <app-feedback-field [field]="form.get('hcp')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-cl-cm-form-cancel" (click)="onHandleClose()">{{ 'action.cancel' | translate}}</button>
    <button class="btn btn-secondary m-start-3" id="edit-cl-cm-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
