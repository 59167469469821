<div class="modal-body">
  <button type="button" class="close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.assign_task.title' | translate }}</h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <div class="form-group required">
      <app-select
        [placeholder]="('modals.assign_task.hcp' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hcpList"
        [searchFn]="customSearchFn"
        (search)="searchHcpListEvent.next($event)"
        (open)="searchHcpList()"
        [loading]="isLoadingHcps"
        [bindValue]="'value'"
        formControlName="hcp"
        [asFilter]="false"
        [showCheckboxes]="false"
        [showUserDetails]="true"

      ></app-select>
      <app-feedback-field [field]="form.get('hcp')"></app-feedback-field>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="assign-task-form-cancel" (click)="handleClose()">{{ 'action.cancel' | translate}}</button>
    <button class="btn btn-secondary m-start-3" id="assign-task-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
