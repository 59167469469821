<div class="is-loading row" *ngIf="isLoading">
  <app-page-loader class="col-12 align-self-center"></app-page-loader>
</div>

<div class="care-module" *ngIf="!isLoading">
  <p class="font-weight-bold">{{ 'modals.add_patient_wizard.select_pathway' | translate }}</p>

  <!-- 2-5 care-modules === buttons -->
  <div class="care-modules" *ngIf="showCareModuleBtns()">
    <ul>
      <li *ngFor="let careModule of careModules">
        <button class="btn btn-light btn-block text-left my-1 p-start-2 py-2 text-truncate" (click)="selectCareModule(careModule)" [ngClass]="{'active': careModule?.uid === selectedCareModule?.uid}">
          {{ careModule.translationKey | translate }}
        </button>
      </li>
    </ul>
  </div>

  <!-- >5 care-modules === select -->
  <div class="care-modules" *ngIf="!showCareModuleBtns()">
    <app-select [placeholder]="('modals.add_patient.treatment' | translate)" [searchable]="true" [clearable]="false"
      [hideSelected]="false" [multiple]="false" [items]="careModules" [(ngModel)]="selectedCareModule" (change)="selectCareModule(selectedCareModule)">
    </app-select>
  </div>
</div>
