import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FlowLayoutComponent } from './layouts/flow-layout/flow-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';

import { GenericErrorComponent } from './pages/general/generic-error/generic-error.component';
import { HomePageComponent } from './pages/flow/home-page/home-page.component';
import { DashboardPageComponent } from './pages/default/dashboard-page/dashboard-page.component';
import { AuthGuard } from './helpers/auth.guard';
import { SettingsOverviewComponent } from './pages/default/settings/settings-overview/settings-overview.component';
import { SettingsPersonalDataComponent } from "./pages/default/settings/settings-personal-data/settings-personal-data.component";
import { DeviceNotSupportedPageComponent } from './pages/flow/device-not-supported-page/device-not-supported-page.component';
import { LinkInvalidPageComponent } from './pages/flow/link-invalid-page/link-invalid-page.component';
import { LocaleRegistrationComponent } from './pages/flow/locale-registration/locale-registration.component';
import { SettingsConsentsOverviewComponent } from "./pages/default/settings/settings-consents-overview/settings-consents-overview.component";
import { SettingsConsentDetailComponent } from "./pages/default/settings/settings-consent-detail/settings-consent-detail.component";
import { SettingsPreferencesComponent } from "./pages/default/settings/settings-preferences/settings-preferences.component";
import {MfaCodeComponent} from "./pages/flow/mfa-code/mfa-code.component";
import { ExpiredPasswordResetComponent } from './pages/flow/expired-password-reset/expired-password-reset.component';
import { OnboardingPasswordComponent } from './pages/flow/onboarding-password/onboarding-password.component';
import { SettingsPersonalInfoComponent } from './pages/default/settings/settings-personal-info/settings-personal-info.component';
import { OnboardingConsentComponent } from './pages/flow/onboarding-consent/onboarding-consent.component';
import { ForgotPasswordResetComponent } from './pages/flow/forgot-password-reset/forgot-password-reset.component';
import { ForgotPasswordComponent } from './pages/flow/forgot-password/forgot-password.component';

import {OnboardingDetailsComponent} from "./pages/flow/onboarding-details/onboarding-details.component";
import { AdminOverviewComponent } from './pages/default/admin/admin-overview/admin-overview.component';
import { AdminOnboardingRequestsComponent } from './pages/default/admin/admin-onboarding-requests/admin-onboarding-requests.component';
import { AdminOnboardingCodesComponent } from './pages/default/admin/admin-onboarding-codes/admin-onboarding-codes.component';
import { ConversationsComponent } from './pages/default/conversations/conversations.component';
import { CalendarComponent } from './pages/default/calendar/calendar.component';
import { SettingsSecurityComponent } from './pages/default/settings/settings-security/settings-security.component';
import { AdminMdtsComponent } from './pages/default/admin/admin-mdts/admin-mdts.component';
import { PatientDetailComponent } from './pages/default/patient-detail/patient-detail.component';
import {AdminHcpsComponent} from './pages/default/admin/admin-hcps/admin-hcps.component';
import { PatientMaterialsComponent } from './pages/default/patient-materials/patient-materials.component';
import { MaterialDetailComponent } from './pages/default/material-detail/material-detail.component';
import { CookiePageComponent } from './pages/general/cookie-page/cookie-page.component';
import {AdminPatientsComponent} from './pages/default/admin/admin-patients/admin-patients.component';
import {MobileRedirectComponent} from './pages/general/mobile-redirect/mobile-redirect.component';
import { TasksComponent } from './pages/default/tasks/tasks.component';
import { TimelineComponent } from './pages/default/timeline/timeline.component';
import { GoalDetailComponent } from './pages/default/goal-detail/goal-detail.component';
import { SettingsOooComponent } from './pages/default/settings/settings-ooo/settings-ooo.component';
import { DnaOverviewComponent } from './pages/default/dna/dna-overview/dna-overview.component';
import { DnaPatientOnboardingComponent } from './pages/default/dna/dna-patient-onboarding/dna-patient-onboarding.component';
import { HelpCenterOverviewComponent } from './pages/default/help-center/help-center-overview/help-center-overview.component';
import { HelpCenterArticlesOverviewComponent } from './pages/default/help-center/help-center-articles-overview/help-center-articles-overview.component';
import { HelpCenterFaqsOverviewComponent } from './pages/default/help-center/help-center-faqs-overview/help-center-faqs-overview.component';
import { HelpCenterArticlesDetailComponent } from './pages/default/help-center/help-center-articles-detail/help-center-articles-detail.component';
import { HelpCenterFaqsDetailComponent } from './pages/default/help-center/help-center-faqs-detail/help-center-faqs-detail.component';
import { MaintenanceComponent } from './pages/general/maintenance/maintenance.component';
import { InactiveComponent } from './pages/general/inactive/inactive.component';
import { PatientNotesComponent } from './pages/default/patient-notes/patient-notes.component';
import { NoteDetailComponent } from './pages/default/note-detail/note-detail.component';
import { QueryListDetailComponent } from './pages/default/query-list-detail/query-list-detail.component';
import { QueryListOverviewComponent } from './pages/default/query-list-overview/query-list-overview.component';
import { QueryListCanDeactivateGuard } from './helpers/query-list-form.guard';
import { AdminTasksComponent } from './pages/default/admin/admin-tasks/admin-tasks.component';
import {  DnaPathwaysOverviewComponent } from './pages/default/dna/dna-pathways-overview/dna-pathways-overview.component';
import { NoteCanDeactivateGuard } from './helpers/note-form.guard';
import { AdminCareModulesComponent } from './pages/default/admin/admin-care-modules/admin-care-modules.component';
import { AdminCareModulesDetailsComponent } from './pages/default/admin/admin-care-modules-details/admin-care-modules-details.component';


export const routes: Routes = [
  {
    path: '',
    component: FlowLayoutComponent,
    children: [
      { path: '', component: HomePageComponent, pathMatch: 'full' },
      { path: 'portal-not-supported', component: DeviceNotSupportedPageComponent },
      { path: 'link-invalid', component: LinkInvalidPageComponent },

      { path: 'onboarding/consent', component: OnboardingConsentComponent, canActivate: [AuthGuard] },
      { path: 'onboarding/language', component: LocaleRegistrationComponent, canActivate: [AuthGuard] },
      { path: 'onboarding/details', component: OnboardingDetailsComponent, canActivate: [AuthGuard] },
      { path: 'onboarding/password', component: OnboardingPasswordComponent, canActivate: [AuthGuard] },

      { path: 'security-code', component: MfaCodeComponent, canActivate: [AuthGuard] },
      { path: 'password-expired', component: ExpiredPasswordResetComponent, canActivate: [AuthGuard] },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'password-reset', component: ForgotPasswordResetComponent },

      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'cookie-policy', component: CookiePageComponent },

      { path: 'mobile-redirect/:deepLinkUrl', component: MobileRedirectComponent}
    ]
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardPageComponent, canActivate: [AuthGuard] },
      { path: 'patient/:patientUid', component: PatientDetailComponent, canActivate: [AuthGuard], data: { breadcrumb: 'patientDetail' }},
      { path: 'patient/:patientUid/:patientPathwayUid/learning-materials', component: PatientMaterialsComponent, canActivate: [AuthGuard], data: { breadcrumb: 'learningMaterials' }},
      { path: 'patient/:patientUid/:patientPathwayUid/learning-materials/article/:educationalMaterialUid', component: MaterialDetailComponent, canActivate: [AuthGuard], data: { breadcrumb: 'learningMaterialsDetail' }},
      { path: 'patient/:patientUid/:patientPathwayUid/notes', component: PatientNotesComponent, canActivate: [AuthGuard], data: { breadcrumb: 'notes' }},
      { path: 'patient/:patientUid/:patientPathwayUid/notes/new', component: NoteDetailComponent, canActivate: [AuthGuard], canDeactivate: [NoteCanDeactivateGuard], data: { breadcrumb: 'notesNew' }  },
      { path: 'patient/:patientUid/:patientPathwayUid/notes/:noteUid/:action', component: NoteDetailComponent, canActivate: [AuthGuard], canDeactivate: [NoteCanDeactivateGuard], data: { breadcrumb: 'notesDetail' }  },
      { path: 'patient/:patientUid/:patientPathwayUid/timeline', component: TimelineComponent, canActivate: [AuthGuard], data: { breadcrumb: 'timeline' }},
      { path: 'patient/:patientUid/:patientPathwayUid/goal/:goalId', component: GoalDetailComponent, canActivate: [AuthGuard], data: { breadcrumb: 'goalDetail' }},
      { path: 'patient/:patientUid/:patientPathwayUid/query-lists', component: QueryListOverviewComponent, canActivate: [AuthGuard], data: { breadcrumb: 'queryLists' }},
      { path: 'patient/:patientUid/:patientPathwayUid/query-lists/:formUid', component: QueryListDetailComponent, canActivate: [AuthGuard], canDeactivate: [QueryListCanDeactivateGuard],  data: { breadcrumb: 'queryListDetail' }  },

      { path: 'settings', component: SettingsOverviewComponent, canActivate: [AuthGuard] },
      { path: 'settings/personal-information', component: SettingsPersonalInfoComponent, canActivate: [AuthGuard] },
      { path: 'settings/personal-data', component: SettingsPersonalDataComponent, canActivate: [AuthGuard] },
      { path: 'settings/preferences', component: SettingsPreferencesComponent, canActivate: [AuthGuard] },
      { path: 'settings/privacy', component: SettingsConsentsOverviewComponent, canActivate: [AuthGuard] },
      { path: 'settings/privacy/:consentKey', component: SettingsConsentDetailComponent, canActivate: [AuthGuard] },
      { path: 'settings/security', component: SettingsSecurityComponent, canActivate: [AuthGuard] },
      { path: 'settings/ooo', component: SettingsOooComponent, canActivate: [AuthGuard]},

      { path: 'administration', component: AdminOverviewComponent, canActivate: [AuthGuard] },
      { path: 'administration/patient/onboarding-requests', component: AdminOnboardingRequestsComponent, canActivate: [AuthGuard] },
      { path: 'administration/patient/onboarding-codes', component: AdminOnboardingCodesComponent, canActivate: [AuthGuard] },
      { path: 'administration/staff/mdts', component: AdminMdtsComponent, canActivate: [AuthGuard] },
      { path: 'administration/staff/hcps', component: AdminHcpsComponent, canActivate: [AuthGuard] },
      { path: 'administration/staff/patients', component: AdminPatientsComponent, canActivate: [AuthGuard] },
      { path: 'administration/tasks', component: AdminTasksComponent, canActivate: [AuthGuard] },
      { path: 'administration/care-modules', component: AdminCareModulesComponent, canActivate: [AuthGuard] ,data: { breadcrumb: 'careModules' }},
      { path: 'administration/care-modules/:careModuleUid', component: AdminCareModulesDetailsComponent, canActivate: [AuthGuard] ,data: { breadcrumb: 'careModulesDetails' }},

      { path: 'conversations', component: ConversationsComponent, canActivate: [AuthGuard] },

      { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },

      { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard] },

      { path: 'analytics', component: DnaOverviewComponent, canActivate: [AuthGuard] },
      { path: 'analytics/registration', component: DnaPatientOnboardingComponent, canActivate: [AuthGuard] },
      { path: 'analytics/pathways-overview', component: DnaPathwaysOverviewComponent, canActivate: [AuthGuard] },

      { path: 'help-center', component: HelpCenterOverviewComponent, canActivate: [AuthGuard] },
      { path: 'help-center/articles', component: HelpCenterArticlesOverviewComponent, canActivate: [AuthGuard] },
      { path: 'help-center/articles/:articleUid', component: HelpCenterArticlesDetailComponent, canActivate: [AuthGuard] },
      { path: 'help-center/faqs', component: HelpCenterFaqsOverviewComponent, canActivate: [AuthGuard] },
      { path: 'help-center/faqs/:faqUid', component: HelpCenterFaqsDetailComponent, canActivate: [AuthGuard] },
    ]
  },

  { path: 'error', component: GenericErrorComponent },
  { path: 'inactive', component: InactiveComponent },
  { path: 'error/:code', component: GenericErrorComponent },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
