<div class="modal-body">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <!-- Title User Task -->
  <h2>
    <span *ngIf="userTask?.title">{{ userTask?.translationKeyTitle | translate | evalTranslation }}</span>
    <span *ngIf="!userTask?.title">...</span>
  </h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <!-- Patient information -->
    <div class="user-compact mb-3">
      <app-avatar [profilePicture]="userTask?.patient?.profile_picture"></app-avatar>

      <div class="user-compact-info">
        <p class="title text-truncate">{{ userTask?.patient?.getFullName() || '...' }}</p>
        <p *ngIf="userTask?.patient">{{ userTask?.patient?.date_of_birth | timeZoneDate:dateFormat?.format  }} ({{ userTask?.patient?.age() | translateNumber }}{{ 'pages.default.dashboard.yo' | translate }})</p>
      </div>
    </div>

    <!-- Care Module Name (Pathway) title -->
    <div class="form-group styled floating-label">
      <input [placeholder]="('form.labels.pathway' | translate)" class="form-control w-100" formControlName="pathway" id="inputPathway" type="text">
      <label class="required" for="inputPathway">{{ 'form.labels.pathway' | translate }}</label>
    </div>

    <!-- Title (Appointment) -->
    <div class="form-group styled floating-label">
      <input [placeholder]="('form.labels.title' | translate)" class="form-control w-100" formControlName="title" id="inputTitle" type="text" [maxlength]="titleMaxLength">
      <label class="required" for="inputTitle">{{ 'form.labels.title' | translate }}</label>
      <app-feedback-field [field]="form.get('title')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !form?.controls?.title?.value)">
        {{( form?.controls?.title?.value?.length || '0' ) | translateNumber}}/{{titleMaxLength | translateNumber}}
      </p>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <!-- Date -->
        <div class="form-group styled required floating-label">
          <input type="text" id="inputDate" class="form-control w-100" formControlName="start_date" required bsDatepicker >
          <label for="inputDate" class="required">{{ 'modals.appointment.form.date' | translate }}</label>
          <app-feedback-field [field]="form.get('start_date')"></app-feedback-field>
        </div>
      </div>
      <div class="col-6">
        <!-- Full day toggle -->
        <div class="custom-control custom-checkbox custom-control-inline mb-1">
          <input type="checkbox" class="custom-control-input" id="check-full-day" formControlName="full_day">
          <label class="custom-control-label" for="check-full-day">{{ 'modals.appointment.form.full_day' | translate
            }}</label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!form.get('full_day')?.value">
      <div class="col-6">
        <!-- Start time -->
        <div class="form-group mb-0">
          <timepicker id="inputStartTime" class="with-label" formControlName="start_time" [showMeridian]="!time_24_hours" [showSpinners]="false" ></timepicker>
          <label for="inputStartTime" class="required">{{ 'modals.appointment.form.start_time' | translate }}</label>
          <app-feedback-field [field]="form.get('start_time')"></app-feedback-field>

        </div>
      </div>
      <div class="col-6">
        <!-- End time -->
        <div class="form-group mb-0">
          <timepicker id="inputEndTime" class="with-label" formControlName="end_time" [showMeridian]="!time_24_hours" [showSpinners]="false" ></timepicker>
          <label for="inputEndTime" class="required">{{ 'modals.appointment.form.end_time' | translate }}</label>
          <app-feedback-field [field]="form.get('end_time')"></app-feedback-field>
        </div>
      </div>
    </div>

    <hr>

    <p><strong>Attendees</strong></p>

    <div class="form-group">
      <ng-select
        class="users-select"
        #hcpselect
        placeholder="{{ 'modals.appointment.form.search_role_or_hcp' | translate }}"
        [items]="hcps"
        (search)="searchHCPs($event)"
        [searchFn]="customSearchFn"
        bindLabel="first_name"
        (change)="hcpSelected($event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item">{{item?.getFullName()}}</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="wrapper" *ngIf="item">
              <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>
              <div class="user-info">
                <p class="title">{{ item?.getFullName() }}</p>
                <p class="sub-text">{{ item?.job_title }}</p>
              </div>
              <div class="status small text-muted" *ngIf="isHcpSelected(item)">
                <span>{{ 'modals.appointment.form.already_added' | translate }}</span>
              </div>
              <div class="status small text-secondary" *ngIf="!isHcpSelected(item)">
                <span class="icon" [inlineSVG]="'/assets/svg/add.svg'"></span>
                <span>{{ 'modals.appointment.form.add' | translate }}</span>
              </div>
            </div>
          </ng-template>
        </ng-select>

      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>
    </div>

    <div class="participant-item" *ngIf="appointment?.patient_invitee">
      <app-avatar [profilePicture]="appointment?.patient_invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ appointment?.patient_invitee?.getFullName() }}</p>
        <p class="description">{{ 'modals.appointment.form.patient' | translate }}</p>
      </div>
      <div class="text-muted text-align-end">{{ 'modals.appointment.form.patient_not_removed' | translate }}</div>
    </div>

    <div class="participant-item clickable" *ngFor="let invitee of appointment?.invitees">
      <app-avatar [profilePicture]="invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ invitee?.getFullName() }}</p>
        <p class="description">{{ invitee?.job_title }}</p>
      </div>

      <a href="" class="status text-secondary" id="appointment-form-remove-hcp" (click)="removeHcp($event, invitee)">
        <span class="icon" [inlineSVG]="'/assets/svg/close.svg'"></span>
        <span>{{ 'modals.appointment.form.remove' | translate }}</span>
     </a>
    </div>

    <hr>

    <div class="form-group styled required floating-label mb-0">
      <input type="text" id="inputLocation" class="form-control w-100" formControlName="location_name" [maxlength]="locationMaxLength" [placeholder]="'modals.appointment.form.location' | translate">
      <label for="inputLocation">{{ 'modals.appointment.form.location' | translate }}</label>
      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>
      <p class="small text-muted text-align-end m-0">
        {{( form?.controls?.location_name?.value?.length || '0' ) | translateNumber}}/{{locationMaxLength | translateNumber}}
      </p>
    </div>

    <div class="custom-control custom-checkbox custom-control-inline mb-1">
      <input type="checkbox" class="custom-control-input" id="check_link" [(ngModel)]="linkAvailable" [ngModelOptions]="{standalone: true}">
      <label class="custom-control-label" for="check_link" >{{ 'modals.appointment.form.include_link' | translate }}</label>
    </div>

    <div class="form-group styled  floating-label" *ngIf="linkAvailable">
      <input type="text" id="inputLink" class="form-control w-100" formControlName="location_url"  [placeholder]="'modals.appointment.form.link' | translate">
      <label for="inputLink">{{ 'modals.appointment.form.link' | translate }}</label>
    </div>

    <hr>

    <div class="form-group styled required floating-label">
      <textarea appTextareaAutoresize id="inputDescription" class="form-control w-100" formControlName="description" [placeholder]="'modals.appointment.form.description' | translate" [maxlength]="descriptionMaxLength"></textarea>
      <label for="inputDescription">{{ 'modals.appointment.form.description' | translate }}</label>
      <p class="validation-feedback">{{ 'form.feedback.field_required' | translate }}</p>
      <p class="small text-muted text-align-end m-0">
        {{( form?.controls?.description?.value?.length || '0' ) | translateNumber }}/{{descriptionMaxLength | translateNumber}}
      </p>

      <p class="text-danger small">{{ 'modals.appointment.form.medical_date' | translate }}</p>

    </div>


  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="user-task-appointment-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <!--
    <button class="btn btn-link" id="user-task-appointment-save" [ngClass]="{'loader': isSaving}" (click)="handleSaveChanges()">
      <span class="loader text-secondary"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save-changes' | translate }}</span>
    </button>
  -->

    <button class="btn btn-secondary m-start-3" id="user-task-appointment-submit" [ngClass]="{'loader': isSubmitting}" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></em></span>
      <span>{{ 'action.submit' | translate }}</span>
    </button>
  </div>
</div>
