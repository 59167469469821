import { TranslationInterface } from "../interfaces/translation.interface";

export class PatientDnaMeasure {
  id: string;
  label: TranslationInterface;
  unit_of_value: TranslationInterface;
  value: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id;
    this.value = item.value;
    this.unit_of_value = item.unit_of_value;
    this.label = item.label;
  }
}