<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h1>{{ 'pages.default.administration.overview.administration' | translate }}</h1>
    </div>
  </div>
</div>

<div class="container" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<div class="body-content" *ngIf="!isLoading">
  <div class="container">

    <div class="heading-block mb-2">
      <div class="block-wrapper overflow-hidden">
        <h2 class="text-truncate">{{ 'pages.default.administration.overview.patients' | translate }}</h2>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-patients"
      [routerLink]="['/administration/staff/patients']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/profile.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.manage_patients' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.manage_patients_description' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="rtl-mirrored-inline-svg"></span>
      </div>
    </div>

    <div class="pb-3 pb-lg-4"></div>

    <div *ngIf="hasClinicalCareModules">
      <div class="heading-block mb-2">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.administration.overview.staff' | translate }}</h2>
        </div>
      </div>

      <div [routerLink]="['/administration/staff/hcps']" class="single-item-block item-clickable mb-2"
        id="admin-overview-manage-hcps">
        <div class="block-wrapper p-start-3 p-end-1">
          <span [inlineSVG]="'/assets/svg/person.svg'" class="icon"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_hcps' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_hcps_description' | translate }}</p>
        </div>
        <div class="block-action m-start-auto">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-mdts"
        [routerLink]="['/administration/staff/mdts']">
        <div class="block-wrapper p-start-3 p-end-1">
          <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_mdts' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_mdts_description' | translate }}</p>
        </div>
        <div class="block-action m-start-auto">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-tasks"
        [routerLink]="['/administration/tasks']">
        <div class="block-wrapper p-start-3 p-end-1">
          <span class="icon" [inlineSVG]="'/assets/svg/approve.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_tasks' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_tasks_description' | translate }}</p>
        </div>
        <div class="block-action m-start-auto">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-care-modules"
        [routerLink]="['/administration/care-modules']">
        <div class="block-wrapper p-start-3 p-end-1">
          <span class="icon" [inlineSVG]="'/assets/svg/package.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.administration.overview.manage_care_modules' | translate }}</p>
          <p class="value">{{ 'pages.default.administration.overview.manage_care_modules_description' | translate }}</p>
        </div>
        <div class="block-action m-start-auto">
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </div>
      </div>

      <div class="pb-3 pb-lg-4"></div>
    </div>

    <div class="heading-block mb-2">
      <div class="block-wrapper overflow-hidden">
        <h2 class="text-truncate">{{ 'pages.default.administration.overview.patient_onboarding' | translate }}</h2>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2" id="admin-overview-view-codes"
      [routerLink]="['/administration/patient/onboarding-codes']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/code.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.view_codes' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.view_codes_description' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2" id="admin-overview-manage-requests"
      [routerLink]="['/administration/patient/onboarding-requests']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
      </div>
      <div class="block-wrapper w-100">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <p class="label">{{ 'pages.default.administration.overview.manage_requests' | translate }}</p>
            <p class="value">{{ 'pages.default.administration.overview.manage_requests_description' | translate }}</p>
          </div>

          <p *ngIf="pendingRequests > 0" class="text-secondary small font-weight-bold">{{ pendingRequests }} {{ 'pages.default.administration.overview.manage_requests_pending' | translate }}</p>
        </div>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
      </div>
    </div>

    <div class="pb-3 pb-lg-4"></div>

    <!-- d-none ->  temp hidden -->
    <div class="heading-block mb-2 d-none">
      <div class="block-wrapper overflow-hidden">
        <h2 class="text-truncate">{{ 'pages.default.administration.overview.templates' | translate }}</h2>
      </div>
    </div>

    <!-- d-none ->  temp hidden -->
    <div class="d-none single-item-block item-clickable mb-2" id="admin-overview-manage-appointments"
      [routerLink]="['/administration/templates/appointments']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/calendar.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.manage_appointments' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.manage_appointments_description' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
      </div>
    </div>

    <!-- d-none ->  temp hidden -->
    <div class="d-none single-item-block item-clickable mb-2" id="admin-overview-manage-messages"
      [routerLink]="['/administration/templates/messages']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon" [inlineSVG]="'/assets/svg/message.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.administration.overview.manage_messages' | translate }}</p>
        <p class="value">{{ 'pages.default.administration.overview.manage_messages_description' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
      </div>
    </div>

  </div>
</div>
