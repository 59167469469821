<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <div class="position-relative pb-3">
    <button type="button" class="close" id="generate-code-modal-cancel" data-dismiss="modal" aria-label="Close" (click)="handleCancel()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2 class="m-0">{{ 'modals.generate_onboarding_code.generate_new_code' | translate }}</h2>
  </div>

  <form [formGroup]="form">
    <div class="form-group styled floating-label">
      <app-select
        [placeholder]="('form.labels.pathway' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="hospitalCodes"
        [bindValue]="'value'"

        formControlName="pathway"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
    </div>
  </form>


  <div class="mt-3" *ngIf="showNewCode">
    <p class="small text-muted m-0">{{ 'modals.generate_onboarding_code.registration_code' | translate }}</p>
    <p class="mb-1">
      <span class="align-middle font-weight-semibold" id="generate-code-modal-code">{{ getCodeNotation() }}</span>
      <a href="" class="text-secondary m-start-1" id="generate-code-modal-copy-code" (click)="actionCopyCode($event)">
        <span [inlineSVG]="'/assets/svg/multpage.svg'"></span>
      </a>
    </p>
    <p class="small text-muted m-0" id="generate-code-modal-valid-until">
      @if(newTimedCode.end_at) {
        {{ 'pages.default.administration.onboarding_codes.valid_until' | translate }} {{ newTimedCode.end_at | timeZoneDate:dateFormat.format }}
      } @else {
        {{ 'pages.default.administration.onboarding_codes.no_expiry' | translate }}
      }
    </p>
  </div>

  <!--
  <div *ngIf="showNewCode">
    <div class="single-item-block with-shadow">
      <div class="row d-flex align-items-center w-100">
        <div class="col-6">
          <div class="block-wrapper py-3 p-start-3">
            <p class="label text-truncate">{{ ('shared.pathway_titles.' + newTimedCode?.care_module?.name?.key ) | translate }}</p>
            <p class="text-truncate">{{ 'pages.default.administration.onboarding_codes.valid_until' | translate }} {{ newTimedCode.end_at | date:dateFormat.format }}</p>
          </div>
        </div>

        <div class="col-6">
          <div class="block-wrapper">
            <p class="text-truncate text-dark">{{ getCodeNotation() }}</p>
          </div>
        </div>
      </div>

      <div class="block-action-wrapper justify-content-end">
        <a href="" class="block-action action-compact" (click)="actionCopyCode($event)">
          <span [inlineSVG]="'/assets/svg/multpage.svg'"></span>
        </a>
      </div>
    </div>
  </div>
  -->

</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button *ngIf="!showNewCode" class="btn btn-link" id="generate-code-modal-action-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button *ngIf="showNewCode" class="btn btn-secondary" id="generate-code-modal-close" (click)="handleCancel()">{{ 'action.close' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="generate-code-modal-generate" [ngClass]="{'loader': isLoading}" *ngIf="!showNewCode" (click)="handleGenerate()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'action.generate' | translate }}</span>
    </button>
  </div>
</div>
