import { Output } from "@angular/core";
import { GoalInstanceBase } from "../interfaces/goal-instance-base.interface";
import { TranslationInterface } from "../interfaces/translation.interface";
import { GoalEvaluation } from "./goal-evaluation";

export class GoalInstanceOverview implements GoalInstanceBase {
  public id: string;
  public title: TranslationInterface;
  public started_at: string;
  public ended_at: string;
  public input_initial: number;
  public target_initial: number;
  public input_unit: TranslationInterface;
  public evaluation: GoalEvaluation;
  
  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id || item.uid;
    this.title = item.title;
    this.started_at = item.started_at;
    this.ended_at = item.ended_at;
    this.input_initial = item.input_initial;
    this.target_initial = item.target_initial;
    this.input_unit = item.input_unit;
    
    if(item.evaluation) {
      this.evaluation = new GoalEvaluation(item.evaluation);
    }
  }

  get translationTitleKey():string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }
  
  get translationInputUnitKey():string {
    return `shared.${this.input_unit?.region}.${this.input_unit?.key}`;
  }
}