import { PhoneNumberInterface } from '../interfaces/phone-number.interface';
import moment from 'moment';
import { Pathway } from './pathway';
import { Image } from './image';

export class Patient {
  uid: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  email: string;
  phone_number: PhoneNumberInterface;
  gender: string;
  country: string;
  language: string;
  status: string;
  approved: boolean;
  reviewed: boolean;
  profile_picture: Image;
  date_of_birth: string;
  pathways: Array<Pathway>
  unread_messages: number;
  upcoming_appointments: number;
  consented_at: string; // date
  created_at: string; // date
  electronic_medical_record: string;
  email_change_allowed: boolean;
  resend_onboarding_email_allowed: boolean;

  label: string;
  value: any;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.first_name = item.first_name ? item.first_name : '';
    this.last_name = item.last_name ? item.last_name : '';
    this.preferred_name = item.preferred_name ? item.preferred_name : '';
    this.email = item.email ? item.email : '';
    this.phone_number = item.phone_number ? item.phone_number : '';
    this.gender = item.gender ? item.gender : '';
    this.country = item.country ? item.country : '';
    this.language = item.language ? item.language : '';
    if(item.language_code) {
      this.language = item.language_code;
    }

    this.status = item.status ? item.status : '';
    this.approved = item.approved ? item.approved : '';
    this.reviewed = item.reviewed ? item.reviewed :  '';

    if(item.profile_picture) {
      this.profile_picture  = new Image(item.profile_picture);
    }

    if(item.language_code) {
      this.language = item.language_code;
    }

    this.date_of_birth  = item.date_of_birth;
    this.unread_messages = item.unread_messages;
    this.upcoming_appointments = item.upcoming_appointments;
    this.consented_at = item.consented_at;
    this.created_at = item.created_at;
    this.electronic_medical_record = item.electronic_medical_record;
    this.email_change_allowed = item.email_change_allowed;
    this.resend_onboarding_email_allowed = item.resend_onboarding_email_allowed;

    this.pathways = [];
    if(item.patient_pathways) {
      item.patient_pathways.forEach(pathwayData => {
        this.pathways.push(new Pathway(pathwayData));
      });
    }

    this.label = this.getFullName();
    this.value = this.uid;
  }

  age(today = moment()) {
    return today.diff(this.date_of_birth, 'years');
  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }

  getBirthDate() {
    if(this.date_of_birth) {
      return new Date(this.date_of_birth);
    } else {
      return undefined;
    }
  }
}
