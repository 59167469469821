import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MfaService } from './../../../services/mfa.service';
import { ToastrService } from 'ngx-toastr';
import { HomeFlowService } from '../../../services/home-flow.service';
import {TranslateService} from '@ngx-translate/core';
import { MfaMethod } from '../../../models/mfa-method';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-mfa-code',
  templateUrl: './mfa-code.component.html',
  styleUrls: ['./mfa-code.component.scss']
})
export class MfaCodeComponent implements OnInit {
  public codeInput: string;
  public codeIncorrect: boolean;
  public codeEmpty: boolean;
  public isLoading: boolean;
  public view: string;
  public mfaMethods: Array<MfaMethod>;
  public currentMfaMethod: MfaMethod;

  constructor(
    public router: Router,
    public mfaService: MfaService,
    public homeFlowService: HomeFlowService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.view = "CODE";

    if(!this.authenticationService.getAuthenticationData()) {
      this.authenticationService.destroyAuth();
      this.router.navigateByUrl('/');
      return;
    }

    this.mfaMethods = this.authenticationService.getMfaMethods();
    this.currentMfaMethod = this.authenticationService.getMfaMethods(true)[0];
  }

  submit() {
    if(this.isLoading) {
      return;
    }

    this.codeIncorrect = false;
    this.codeEmpty = false;

    if(!this.codeInput) {
      this.codeEmpty = true;
      return;
    }

    this.isLoading = true;

    this.mfaService.postCode(this.codeInput, this.currentMfaMethod).subscribe(
      () => this.onCodeSubmissionSuccess(),
      (error) => this.onCodeSubmissionError(error)
    );
  }

  onCodeSubmissionSuccess() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isLoading = false;
    });
  }

  onCodeSubmissionError(error) {
    this.isLoading = false;

    if (error.status === 400) {
      this.codeIncorrect = true;
    }
  }

  submitRequestNewCode(event: MouseEvent): void {
    event.preventDefault();

    this.toastrService.info(this.translate.instant('pages.flow.mfa_code.sent_new_code'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.mfaService.requestCode(this.currentMfaMethod).subscribe(
      () => {},
      () => this.onRequestCodeError()
    );
  }

  onRequestCodeError() {
    this.toastrService.clear();
    this.toastrService.error(this.translate.instant('pages.flow.mfa_code.error_requesting_code'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  showFieldValidation() {
    return (this.codeIncorrect || (this.codeEmpty && !this.codeInput));
  }

  viewOtherMethods(event: MouseEvent): void {
    event?.preventDefault();
    this.view = "METHODS";
  }

  selectMethod(event: MouseEvent, method: MfaMethod): void {
    event?.preventDefault();
    this.currentMfaMethod = method;
    this.submitRequestNewCode(event);

    this.codeInput = null;
    this.codeIncorrect = undefined;
    this.codeEmpty = undefined;

    this.view = "CODE";
  }
}
