import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {UserService} from '../../services/user.service';
import {TranslateService} from "@ngx-translate/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-export-personal-data-modal',
  templateUrl: './export-personal-data-modal.component.html',
  styleUrls: ['./export-personal-data-modal.component.scss']
})
export class ExportPersonalDataModalComponent {
  public isLoading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public errorService: ErrorService
  ) {
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleExport() {
    this.isLoading = true;
    this.userService.rightOfPortability().subscribe(
      () => this.onExportSuccess(),
      () => this.onExportError()
    )
  }

  onExportSuccess() {
    this.isLoading = false;
    this.toastrService.info(this.translate.instant('modals.export_personal_data.personal_data_exported'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
    return this.bsModalRef.hide();
  }

  onExportError() {
    this.isLoading = false;
    return this.bsModalRef.hide();
  }
}
