<div class="layout-header-pusher header-patient-meta"></div>

<div class="layout-header header-patient-meta">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="queryList"></app-breadcrumb>
    </div>

    <div class="container mb-1">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1 *ngIf="queryList">{{ queryList?.title | translateHelp | translate }}</h1>
        </div>
        <div class="col col-auto" *ngIf="queryList && !isReadOnly">
          <button class="btn btn-sm btn-muted m-start-1" id="query-list-save" [ngClass]="{'loader': isSaving}" (click)="saveUserTask()">
            <span class="loader text-muted"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
            <span>{{ 'action.save' | translate }}</span>
          </button>
          <button class="btn btn-sm btn-secondary m-start-1" id="query-list-submit" [ngClass]="{'loader': isSubmitting}" (click)="confirmSubmitUserTask()">
            <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></em></span>
            <span>{{ 'action.submit' | translate }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="queryList?.candidate_users.length === 0">
      <div class="user-compact">
        <app-avatar [profilePicture]="queryList?.assignee?.profile_picture"></app-avatar>

        <div class="user-compact-info">
          <p class="title text-truncate">{{ queryList?.assignee?.getFullName() }}</p>
          <p *ngIf="queryList?.assignee?.uid === hcpUid">{{ 'pages.default.query_lists.you' | translate }}</p>
          <p *ngIf="queryList?.assignee?.uid != hcpUid">{{ queryList?.assignee?.job_title }}</p>
          <p *ngIf="queryList?.assignee_type === 'PATIENT'">{{ 'pages.default.query_lists.patient' | translate }}</p>

        </div>
      </div>
    </div>

    <div class="container participants" *ngIf="queryList?.candidate_users.length > 0">
      <div *ngIf="(queryList?.candidate_users.length <= visibleCandidateUsers)">
        <div class="d-inline-block">
          <div class="item" *ngFor="let user of queryList?.candidate_users; index as index">
            <app-avatar [profilePicture]="user?.profile_picture"></app-avatar>
            <span class="label">{{ user?.getFullName() }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="(queryList?.candidate_users.length > visibleCandidateUsers)">
        <div class="d-inline-block">
          <div class="item" *ngFor="let user of queryList?.candidate_users.slice(0, visibleCandidateUsers -1); index as index">
            <app-avatar [profilePicture]="user?.profile_picture"></app-avatar>
            <span class="label">{{ user?.getFullName() }}</span>
          </div>
          <a href="" class="item" id="query-list-show-candidate-users-modal"  (click)="showCandidateUsersModal($event, queryList?.candidate_users.slice(visibleCandidateUsers -1))">
            <span class="avatar">
              <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
            </span>
            <span class="label">{{ 'pages.default.query_lists.and' | translate }} {{ queryList?.candidate_users.length - (visibleCandidateUsers-1)}} {{ 'pages.default.query_lists.others' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="inline-page-notification" *ngIf="queryList?.accessible_by_patient">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/stethoscope.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.accessible_by_patient.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.accessible_by_patient.description' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="inline-page-notification muted" *ngIf="queryList?.isCompleted">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/edit-disabled.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.no_update.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.completed_description' | translate : {value:editedBy()}  }} {{ queryList?.completed_at | timeZoneDate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="inline-page-notification muted" *ngIf="queryList?.isTimedOut">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/edit-disabled.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.timed_out.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.timed_out.description' | translate : {value:queryList?.due_days_ago?.toString()}  }}</p>
      </div>
    </div>
  </div>
</div>

<div class="inline-page-notification muted" *ngIf="queryList && !queryList.isAssignedTo(hcpUid)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <span class="notification-icon" [inlineSVG]="'/assets/svg/edit-disabled.svg'"></span>
      </div>
      <div class="col">
        <p class="title">{{ 'pages.default.query_lists.not_assigned.title' | translate }}</p>
        <p>{{ 'pages.default.query_lists.not_assigned.description' | translate  }}</p>
      </div>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="!task">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="task">
    <p *ngIf="queryList?.description" class="small">{{ queryList?.description | translateHelp | translate }}</p>

    <app-user-task-form [userTask]="task" [isReadOnly]="isReadOnly" [validationVisible]="validationVisible" [(form)]="form"></app-user-task-form>

    <p class="small" *ngIf="queryList?.isSaved">
      {{ 'pages.default.query_lists.edited_by' | translate : {value:editedBy()  } }} {{ queryList?.changed_at | timeZoneDate }} {{ queryList?.changed_at | time }}
    </p>

    <p class="small" *ngIf="queryList?.isCompleted">
      {{ 'pages.default.query_lists.submitted_by' | translate : {value:editedBy()  } }} {{ queryList?.completed_at | timeZoneDate }} {{ queryList?.completed_at | time }}
    </p>

    <p class="small" *ngIf="queryList?.isTimedOut">
      {{ 'pages.default.query_lists.time_out_at' | translate : {value:queryList?.timedout_at | timeZoneDate} }}
    </p>
  </div>
</div>
