import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-creating-account-modal',
  templateUrl: './creating-account-modal.component.html',
  styleUrls: ['./creating-account-modal.component.scss']
})
export class CreatingAccountModalComponent implements OnInit {
  constructor(
    
  ) { }

  ngOnInit(): void {
    
  }
}
