import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PathwayMessage } from '../../models/pathway-message';
import { PathwayService } from '../../services/pathway.service';

@Component({
  selector: 'app-pathway-message-modal',
  templateUrl: './pathway-message-modal.component.html',
  styleUrls: ['./pathway-message-modal.component.scss']
})
export class PathwayMessageModalComponent implements OnInit {
  @Input() pathwayMessage: PathwayMessage;

  @Output() onRead: EventEmitter<void> = new EventEmitter();

  public isLoading: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public pathwayService: PathwayService
  ) { }

  ngOnInit(): void {
    this.getMessage();
  }

  getMessage() {
    if (!this.pathwayMessage) {
      return;
    }

    this.isLoading = true;
    this.pathwayService.getMessage(this.pathwayMessage.id).subscribe(
      (pathwayMessage: PathwayMessage) => this.onMessageSuccess(pathwayMessage),
      (error: Error) => this.onMessageError(error),
    );
  }

  onMessageSuccess(pathwayMessage: PathwayMessage) {
    this.pathwayMessage = pathwayMessage;
    this.readPathwayMessage();

    setTimeout(() => {
      this.isLoading = false;
    }, 600);
  }

  onMessageError(error: Error) {
    this.isLoading = false;
  }

  readPathwayMessage() {
    if (this.pathwayMessage.isRead) {
      return;
    }

    this.pathwayService.readMessage(this.pathwayMessage, true).subscribe(() => {
      this.pathwayMessage.read_at = new Date().toDateString();
      this.onRead.emit();
    });
  }

  handleClose() {
    return this.bsModalRef.hide();
  }
}
