<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-hcp.svg'"></div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': validationVisible}">
      <p>
        <a id="link_forgot_password_back" [routerLink]="['/']" class="text-icon-link hoverable text-secondary">
          <span class="icon-start">
            <span class="rtl-d-none" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
            <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          </span>
          <span class="label">{{ 'pages.flow.forgot_password.back_to_home' | translate }}</span>
        </a>
      </p>

      <h2>{{ 'pages.flow.forgot_password.forgot_pw' | translate }}</h2>

      <p>{{ 'pages.flow.forgot_password.forgot_pw_text' | translate }}</p>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" class="form-group styled floating-label mb-1" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
        <input id="input_forgot_password_email_address" type="email" class="form-control w-100" [placeholder]="('form.labels.email_address'|translate)" formControlName="email" >
        <label id="label_forgot_password_email_address" for="input_forgot_password_email_address">{{ 'form.labels.email_address' | translate }}</label>
        <p id="feedback_forgot_password_email_invalid" class="validation-feedback" *ngIf="form?.controls['email']?.errors?.email || form?.controls['email']?.errors?.required">{{ 'pages.flow.forgot_password.email_invalid' | translate }}</p>
      </form>

      <button id="button_forgot_password_submit" type="submit" class="btn btn-sm btn-secondary mt-2 mt-lg-3" [ngClass]="{'loader': isLoading}" (click)="formSubmit()">
        <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
        <span>{{ 'pages.flow.forgot_password.send_instructions' | translate }}</span>
      </button>
    </div>

    <div class="footer-part mt-auto">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background d-flex flex-column">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>

  <div class="body-content mt-auto m-start-auto">
    <button class="btn btn-light btn-rounded" (click)="openHelpCenter()">
      <span class="icon" [inlineSVG]="'/assets/svg/chat-question.svg'"></span>
      {{ 'pages.flow.home.need_help' | translate }}
    </button>
  </div>
</div>
