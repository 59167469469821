import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { HomeFlowService } from '../../../services/home-flow.service';
import { LanguageCode } from '../../../models/language-code';

@Component({
  selector: 'app-locale-registration',
  templateUrl: './locale-registration.component.html',
  styleUrls: ['./locale-registration.component.scss']
})
export class LocaleRegistrationComponent implements OnInit {
  public languageOptions: LanguageCode[];
  public selectedLanguage: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public languageService: LanguageService,
    public homeFlowService: HomeFlowService
  ) { }

  ngOnInit() {
    this.getLanguages();

    this.languageService.onLanguageUpdated.subscribe(() => {
      this.getLanguages();
    });
  }

  getLanguages() {
    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
      this.selectedLanguage = this.languageService.getCurrentLanguageCode().value;
    });
  }

  onLanguageSelection() {
    this.languageService.setCurrentLanguageByEnum(this.selectedLanguage);
    this.getLanguages();
  }

  submit() {
    if(this.router.url.includes("/onboarding/language")) {
      this.homeFlowService.goNextStepOrDone().subscribe(() => {});
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
