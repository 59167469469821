import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { Patient } from '../../models/patient';
import { HcpService } from '../../services/hcp.service';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'app-edit-other-email-modal',
  templateUrl: './edit-other-email-modal.component.html'
})
export class EditOtherEmailModalComponent implements OnInit {
  public isSaving: boolean = false;
  public validationVisible: boolean = false;

  public patient: Patient;
  public hcp: HealthCareProfessional;
  public form: UntypedFormGroup;

  public reasonMinLength = 1;
  public reasonMaxLength = 50;

  @Output() public emailChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public patientService: PatientService,
    public hcpService: HcpService,
    public toastService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.formSetup()
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = new UntypedFormGroup({
      new_email: new UntypedFormControl('', [
        Validators.required,
        Validators.email]),
      reason: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.reasonMinLength),
        Validators.maxLength(this.reasonMaxLength)
      ]),
    });
  }

  handleClose() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.isSaving = true;
    this.validationVisible = false;
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    if (this.patient) {
      this.patientService.changeEmailAddressForPatient(
        hospitalUid,
        this.patient.uid,
        this.form.get('new_email').value,
        this.form.get('reason').value
      ).subscribe(() => {
        this.submitSuccessHandler();
      }, error => {
        this.submitErrorHandler(error)
      })
    } else if (this.hcp) {
      this.hcpService.changeEmailAddressForHcp(
        hospitalUid,
        this.hcp.uid,
        this.form.get('new_email').value,
        this.form.get('reason').value
      ).subscribe(() => {
        this.submitSuccessHandler();
      }, error => {
        this.submitErrorHandler(error)
      })
    } else {
      this.isSaving = false;
    }

  }

  submitSuccessHandler() {
    this.isSaving = false;

    this.toastService.info(
      this.translate.instant('modals.edit_other_email_modal.change_email_success_notification_description'),
      this.translate.instant('modals.edit_other_email_modal.change_email_success_notification'
      ), {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.emailChanged.emit(true);
  }

  submitErrorHandler(error) {
    this.isSaving = false;
    this.validationVisible = true;

    // SHOW PF ERROR!
  }
}
