import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';

import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from './../../../services/user.service';
import {Profile} from './../../../models/profile';
import {Image} from './../../../models/image';
import {forkJoin} from 'rxjs';
import {HomeFlowService} from '../../../services/home-flow.service';
import {TranslateService} from '@ngx-translate/core';
import {HcpService} from "../../../services/hcp.service";
import {HealthCareProfessional} from "../../../models/health-care-professional";
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../../services/general.service';
import { MobileVerificationCodeModalComponent } from '../../../modals/mobile-verification-code-modal/mobile-verification-code-modal.component';
import { cloneDeep} from 'lodash';
import { ErrorService } from '../../../services/error.service';
import { OnboardingState } from '../../../enums/onboarding-state';
import { OnboardingFlow } from '../../../enums/onboarding-flow';


@Component({
  selector: 'app-onboarding-details',
  templateUrl: './onboarding-details.component.html',
  styleUrls: ['./onboarding-details.component.scss']
})
export class OnboardingDetailsComponent implements OnInit {
  @ViewChild('inputProfilePicture') inputProfilePicture: ElementRef;

  public hcp: HealthCareProfessional;
  public profile: Profile;
  public isFormDataLoaded: boolean;
  public isUpdatingDetails: boolean;
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public errorToast: any;
  public formComponentData: any;

  public currentState = OnboardingState.PROFILE;
  public showOnboardingIconSteps: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastrService,
    public homeFlowService: HomeFlowService,
    public userService: UserService,
    public hcpService: HcpService,
    public router: Router,
    public translate: TranslateService,
    public modalService: BsModalService,
    public generalService: GeneralService,
    public errorService: ErrorService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.flow === OnboardingFlow.ONBOARDING) this.showOnboardingIconSteps = true;
    });

    forkJoin([
      this.homeFlowService.getFirstHcpByPermissions(),
      this.userService.getProfile(),
    ]).subscribe(data => {
      this.onDataLoaded(data);
    });
  }

  onDataLoaded(data) {
    this.hcp = data[0];
    this.profile = data[1];

    this.formComponentData = {
      hcp: this.hcp,
      profile: this.profile,
      locale: this.profile?.locale
    };

    this.isFormDataLoaded = true;
  }

  onFormInitialized(form: UntypedFormGroup) {
    this.form = form;
    this.form.statusChanges.subscribe(() => this.toggleErrorToast());
  }

  formSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.handleSubmission();
    }

    this.toggleErrorToast();
  }

  toggleErrorToast() {
    if (this.validationVisible && !this.form.valid && !this.toastService.toasts.includes(this.errorToast)) {
      const error = this.translate.instant('form.feedback.data_error');
      this.errorToast = this.toastService.error(error, null);
    } else if (this.form.valid && this.errorToast) {
      this.toastService.clear(this.errorToast.toastId);
      this.errorToast = undefined;
    }
  }

  handleSubmission(code?) {
    const hcp_payload = this.createHcpPayLoad();
    const profile_payload = this.createProfilePayLoad(code);
    this.form.disable();

     // trigger verification code modal
    if (!code && this.verificationCodeRequired()) {
      const mobile_number = {
        code: this.form.value.country_code,
        number: this.form.value.phone
      };

      this.openVerificationCodeModal(mobile_number);
    } else {
      this.isUpdatingDetails = true;

      forkJoin([
        this.hcpService.updateForHcpRole(hcp_payload),
        this.userService.updateProfile(profile_payload)
      ]).subscribe(
        () => this.onUpdateSuccess(),
        (error) => this.onUpdateError(error)
      );
    }
  }

  createHcpPayLoad():HealthCareProfessional {
    const hcp:HealthCareProfessional = cloneDeep(this.hcp, true);
    hcp.gender = this.form.value.gender;
    if(!hcp.profile_picture) {
      hcp.profile_picture = new Image();
    }
    hcp.profile_picture = this.form.value.profile_picture;
    hcp.first_name = this.form.value.first_name;
    hcp.last_name = this.form.value.last_name;
    hcp.location = {
      name: this.form.value.location || '',
      url: this.form.value.location_url || ''
    };

    hcp.job_title = this.form.value.job_title;

    return hcp;
  }

  createProfilePayLoad(code?):Profile {
    const profile:Profile = cloneDeep(this.profile, true);

    if(!profile.mobile_number) {
      profile.mobile_number = {code: '', number: ''};
    }

    profile.mobile_number.code = this.form.value.country_code;
    profile.mobile_number.number = this.form.value.phone;
    profile.contact_channel = this.form.value.contact_channel;

    if (code) {
      profile.mobile_verification_code = code;
    }

    if (profile.locale) {
      profile.locale.notification_channels = [];

      if (this.form.value.notifications_method_email) {
        profile.locale.notification_channels.push("EMAIL");
      }

      if (this.form.value.notifications_method_sms) {
        profile.locale.notification_channels.push("SMS");
      }
    }


    return profile;
  }

  verificationCodeRequired() {
    const smsNotificationSelected = (this.form.value.notifications_method_sms && !this.profile.locale.notification_channels.includes('SMS'))
    const phoneNumberChanged = (this.form.value.phone !== this.profile?.mobile_number?.number || this.form.value.country_code !== this.profile.mobile_number.code)

    if((smsNotificationSelected || phoneNumberChanged) && this.form.value.notifications_method_sms) {
      return true;
    }
    return false;
  }

  openVerificationCodeModal(mobile_number) {
    const initialState = {
      mobile_number
    };

    const modalref = this.modalService.show(MobileVerificationCodeModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-small',
        initialState
      })
    );

    modalref.content.onSubmitClicked.subscribe(result => {
      this.handleSubmission(result);
    });

    modalref.content.onCancelClicked.subscribe(result => {
      this.form.enable();
    });
  }

  onUpdateSuccess() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isUpdatingDetails = false;
    });
  }

  onUpdateError(error) {
    this.form.enable();
    this.isUpdatingDetails = false;
    const key = error?.error?.errors[0]['key'];

    if (key === 'ERROR_IDENTITY_VERIFICATION_CODE_INVALID" || key === "ERROR_IDENTITY_VERIFICATION_CODE_EXPIRED') {
      this.toastService.error(this.translate.instant('pages.flow.onboarding_details.wrong_code'), null);
    } else if (key === 'ERROR_IDENTITY_MOBILE_NUMBER_VERIFICATION_CODE_INVALID') {
      const mobile_number = {
        mobile_number: {
          code: this.form.value.country_code,
          number: this.form.value.phone
        }
      };
      this.openVerificationCodeModal(mobile_number);
    } else {
      //this.router.navigateByUrl('error');
    }
  }
}
