<div class="modal-body">
  <button type="button" class="close" id="add-patient-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="onHandleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.add_patient.title' | translate }}</h2>

  <div *ngIf="step == 'choice'">
    <div>
      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input class="custom-control-input" [(ngModel)]="method" id="selfRegistration" type="radio"
          value="selfRegistration">
        <label class="custom-control-label" for="selfRegistration">{{ 'modals.add_patient.label_self_registration' |
          translate }}</label>
      </div>
    </div>
    <div>
      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input class="custom-control-input" [(ngModel)]="method" id="onboarding" type="radio" value="onboarding">
        <label class="custom-control-label" for="onboarding">{{ 'modals.add_patient.label_onboarding' | translate
          }}</label>
      </div>
    </div>
    <hr>
    <div *ngIf="method == 'onboarding'">
      <div class="custom-control custom-checkbox custom-control-inline mb-1">
        <input class="custom-control-input" id="consent" type="checkbox" [(ngModel)]="consent">
        <label class="custom-control-label" for="consent">{{ 'modals.add_patient.consent' | translate }}</label>
      </div>
    </div>

    <div *ngIf="method == 'selfRegistration'">
      <div class="form-group required">
        <app-select [placeholder]="('modals.add_patient.treatment' | translate)" [searchable]="true" [clearable]="true"
          [hideSelected]="false" [multiple]="false" [items]="careModules" (change)="careModuleSelected($event)">
        </app-select>
      </div>

      <div class="my-3 text-center text-muted" *ngIf="isLoadingTimedCode">
        <em class="spinner-border spinner-border-sm"></em>
      </div>

      <div class="mt-3" *ngIf="selectedCareModule && timedCode && !isLoadingTimedCode">
        <p class="small text-muted m-0">{{ 'modals.add_patient.registration_code' | translate }}</p>
        <p class="mb-1">
          <span class="align-middle font-weight-semibold">{{ timedCode?.hospital_code }}{{ timedCode?.code }}</span>
          <a href="" class="text-secondary m-start-1"
            (click)="actionCopyCode($event, (timedCode?.hospital_code+''+timedCode?.code))">
            <span [inlineSVG]="'/assets/svg/multpage.svg'"></span>
          </a>
        </p>
        <p class="small text-muted m-0">{{ 'modals.add_patient.valid_until' | translate }} {{ timedCode?.end_at |
          timeZoneDate:dateFormat.format }}</p>
      </div>

      <div class="text-center my-3" *ngIf="selectedCareModule && !timedCode && !isLoadingTimedCode">
        <p class="small text-muted m-0">{{ 'modals.add_patient.no_timed_codes_available' | translate }}</p>
      </div>

    </div>
  </div>

  <div *ngIf="step == 'form'">
    <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
      <div formGroupName="patient_pathway">

        <!-- Care Module -->
        <div class="form-group required">
          <app-select [placeholder]="('modals.add_patient.treatment' | translate)" [searchable]="true"
            [hideSelected]="false" [multiple]="false" [items]="careModules" [bindValue]="'uid'"
            formControlName="care_module_uid" (change)="careModuleSelectedInForm($event)"></app-select>
          <app-feedback-field [field]="form.get('patient_pathway.care_module_uid')"></app-feedback-field>
        </div>

        <!-- Clinical Lead -->
        <div class="form-group required">
          <app-select [placeholder]="('modals.add_patient.clinical_lead' | translate)" [hideSelected]="false"
            [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcpSearchResults"
            [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
            [bindValue]="'uid'" formControlName="clinical_lead_uid" [asFilter]="false" [showCheckboxes]="false"
            [showUserDetails]="true"></app-select>
          <app-feedback-field [field]="form.get('patient_pathway.clinical_lead_uid')"></app-feedback-field>
        </div>


        <!-- Case Manager -->
        <div class="form-group required">
          <app-select [placeholder]="('modals.add_patient.case_manager' | translate)" [hideSelected]="false"
            [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcpSearchResults"
            [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
            [bindValue]="'uid'" formControlName="case_manager_uid" [asFilter]="false" [showCheckboxes]="false"
            [showUserDetails]="true"></app-select>
          <app-feedback-field [field]="form.get('patient_pathway.case_manager_uid')"></app-feedback-field>
          <p class="validation-feedback general-feedback" *ngIf="form?.errors?.roles">{{
            'form.feedback.clinical_lead_and_case_manager_cant_be_equal' | translate }}</p>
        </div>
      </div>

      <hr>
      <!-- Gender -->
      <div class="pb-2">
        <label class="small d-block required">{{ 'form.labels.gender' | translate }}</label>

        <div class="custom-control custom-radio custom-control-inline mb-1">
          <input type="radio" class="custom-control-input" id="radioSexMale" formControlName="gender" value="MALE">
          <label class="custom-control-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline mb-1">
          <input type="radio" class="custom-control-input" id="radioSexFemale" formControlName="gender" value="FEMALE">
          <label class="custom-control-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline mb-1">
          <input type="radio" class="custom-control-input" id="radioSexOther" formControlName="gender" value="UNKNOWN">
          <label class="custom-control-label" for="radioSexOther">{{ 'form.labels.prefer_not_say' | translate }}</label>
        </div>
        <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"></app-feedback-field>
      </div>

      <!-- First name -->
      <div class="form-group styled floating-label">
        <input type="text" id="inputFirstName" class="form-control w-100"
          [placeholder]="('form.labels.first_name' | translate)" formControlName="first_name">
        <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>
        <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
      </div>

      <!-- Preferred name -->
      <div class="form-group styled floating-label">
        <input type="text" id="inputPreferredName" class="form-control w-100"
          [placeholder]="'.form.labels.preferred_name' | translate" formControlName="preferred_name">
        <label for="inputPreferredName">{{ 'form.labels.preferred_name' | translate }}</label>
        <app-feedback-field [field]="form.get('preferred_name')"></app-feedback-field>
      </div>

      <!-- Last name -->
      <div class="form-group styled floating-label">
        <input type="text" id="inputLastName" class="form-control w-100"
          [placeholder]="'.form.labels.last_name' | translate" formControlName="last_name">
        <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>
        <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
      </div>

      <!-- Last name -->
      <div class="form-group styled floating-label">
        <input type="text" id="inputEMR" class="form-control w-100" [placeholder]="'.form.labels.emr' | translate"
          formControlName="electronic_medical_record">
        <label for="inputEMR">{{ 'form.labels.emr' | translate }}</label>
        <app-feedback-field [field]="form.get('electronic_medical_record')"></app-feedback-field>
      </div>

      <!-- Country -->
      <div class="form-group">
        <app-select [placeholder]="('form.labels.country' | translate)" [clearable]="false" [searchable]="true"
          [hideSelected]="false" [multiple]="false" [items]="countryOptions" [bindValue]="'value'"
          [autoSortOnLabel]="true" formControlName="country" [asFilter]="false" [showCheckboxes]="false"></app-select>
      </div>

      <!-- Language -->
      <div class="form-group required">
        <app-select [placeholder]="('form.labels.language' | translate)" [clearable]="false" [searchable]="true"
          [hideSelected]="false" [multiple]="false" [items]="languageOptions" [bindValue]="'value'"
          [autoSortOnLabel]="true" [selectedItemsOnTop]="false" formControlName="language" [asFilter]="false"
          [showCheckboxes]="false"></app-select>
        <app-feedback-field [field]="form.get('language')"></app-feedback-field>
      </div>

      <!-- Date of birth -->
      <div class="form-group styled floating-label required">
        <input type="text" id="inputBirth" class="form-control w-100"
          [placeholder]="('form.labels.date_of_birth' | translate)" bsDatepicker formControlName="date_of_birth">
        <label for="inputBirth" class="required">{{ 'form.labels.date_of_birth' | translate }}</label>
        <app-feedback-field [field]="form.get('date_of_birth')"></app-feedback-field>
      </div>
      <hr>
      <!-- Email -->
      <div class="form-group styled floating-label required">
        <input type="text" id="inputEmail" class="form-control w-100" [placeholder]="'.form.labels.email' | translate"
          formControlName="email">
        <label for="inputEmail" class="required">{{ 'form.labels.email' | translate }}</label>
        <app-feedback-field [field]="form.get('email')"></app-feedback-field>
      </div>

      <!-- Phone number -->
      <div class="row validation-group" formGroupName="phone_number">
        <div class="col-5" [ngClass]="{'order-last': languageService.isHebrew}">
          <div class="form-group styled floating-label"
          [ngClass]="{'required': form.get('phone_number.code')?.errors?.required}">
            <app-select [placeholder]="('form.labels.country_code' | translate)" [clearable]="true" [searchable]="true"
              [hideSelected]="false" [multiple]="false" [items]="dialCodeOptions" [bindValue]="'value'"
              [autoSortOnLabel]="true" [selectedItemsOnTop]="false" formControlName="code" [asFilter]="false"
              [showCheckboxes]="false"></app-select>
            <app-feedback-field [field]="form.get('phone_number.code')"></app-feedback-field>
          </div>
        </div>
        <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
          <div class="form-group styled floating-label"
          [ngClass]="{'required': form.get('phone_number.number')?.errors?.required}">
            <input [placeholder]="('form.labels.phone_number' | translate)" class="form-control w-100"
              formControlName="number" id="inputPhone" type="tel">
            <label for="inputPhone" [ngClass]="{'required': form.get('phone_number.number')?.errors?.required}">{{ 'form.labels.phone_number' | translate }}</label>
            <app-feedback-field [field]="form.get('phone_number.number')"></app-feedback-field>
          </div>
        </div>
      </div>
      <app-feedback-field [field]="form.get('phone_number')"></app-feedback-field>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" id="add-patient-modal-action-close" (click)="onHandleClose()"
      *ngIf="method == 'selfRegistration' && step == 'choice'">{{ 'action.close' | translate }}</button>
    <button class="btn btn-link" id="add-patient-modal-cancel" (click)="onHandleClose()"
      *ngIf="method == 'onboarding'">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary" id="add-patient-modal-next" (click)="onHandleNext()"
      *ngIf="method == 'onboarding' && step == 'choice'" [disabled]="!consent">{{ 'action.next' | translate }}</button>
    <button class="btn btn-secondary m-start-3" id="add-patient-modal-confirm" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()" *ngIf="step == 'form'">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
