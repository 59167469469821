<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-filters"></div>

  <div class="layout-header header-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.dashboard.patients' | translate }}</h1>
          </div>
          <div class="col col-auto">
            <button (click)="openAddPatientModal()" *ngIf="canCreatePatient()" class="btn btn-secondary btn-sm">{{ 'pages.default.dashboard.add_patient' | translate }}</button>
          </div>

        </div>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">

              <div class="form-group styled floating-label">
                <input type="text" id="searchPatientLastName" class="form-control w-100" [placeholder]="('form.labels.patient_last_name' | translate)"
                [(ngModel)]="filters.last_name"
                (ngModelChange)="patientLastNameSearch.next($event)" required>
                <label for="searchPatientLastName">{{ 'form.labels.patient_last_name' | translate }}</label>
              </div>

              <div class="divider"></div>

              <app-select
                id="dashboard-select-clinician"
                [placeholder]="('pages.default.dashboard.hcp' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="hcps"
                [searchFn]="customSearchFn"
                [bindValue]="'value'"
                (open)="getHCPs()"
                [loading]="isLoadingHCPs"
                [(ngModel)]="filters.patient_mdt_hcp_uid"

                (ngModelChange)="onFilterChange()"
                (search)="hcpLastNameSearch.next($event)"

                [asFilter]="true"
                [showUserDetails]="true"
                [showCheckboxes]="false"
              ></app-select>

              <div class="divider"></div>

              <app-select
                id="dashboard-select-pathway"
                [placeholder]="('general.pathways' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="true"
                [items]="careModules"
                [bindValue]="'value'"

                [(ngModel)]="filters.care_module_uids"

                (ngModelChange)="onFilterChange()"

                [asFilter]="true"
                [showCheckboxes]="true"
              ></app-select>

              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info" id="dashboard-action-reset-filters" *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
            </div>

          </div>
          <div class="col col-auto">

            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sorting == 'care_module_id,asc'">{{ 'pages.default.dashboard.pathway' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'last_name,asc;first_name,asc'">{{ 'pages.default.dashboard.name_az' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'last_name,desc;first_name,desc'">{{ 'pages.default.dashboard.name_za' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'created_at,asc'">{{ 'pages.default.dashboard.date_added_asc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'created_at,desc'">{{ 'pages.default.dashboard.date_added_desc' | translate }}&#x200E;</span>

                <span class="icon" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirection($event, 'care_module_id,asc')">{{ 'pages.default.dashboard.pathway' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirection($event, 'last_name,asc;first_name,asc')">{{ 'pages.default.dashboard.name_az' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirection($event, 'last_name,desc;first_name,desc')">{{ 'pages.default.dashboard.name_za' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirection($event, 'created_at,asc')">{{ 'pages.default.dashboard.date_added_asc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSortDirection($event, 'created_at,desc')">{{ 'pages.default.dashboard.date_added_desc' | translate }}&#x200E; </a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="patients && patients.length <= 0">
        <span [inlineSVG]="'/assets/svg-color/stethoscope.svg'"></span>
        <h3>{{ 'pages.default.dashboard.no_patients_yet' | translate }}</h3>
      </div>

      <div *ngFor="let patient of patients">
        <app-patient-tabbed-details class="mb-2" [patient]="patient"></app-patient-tabbed-details>
      </div>

    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto" id="total-patients">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
