<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/analytics']" class="text-secondary font-weight-bold d-flex align-items-center" id="analytics-detail-back">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'pages.default.analytics.back-to-analytics' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1>{{ 'pages.default.analytics.onboarding.title' | translate }}</h1>
        </div>

        <div class="col col-auto">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-sm" id="analytics-change-view-to-weeks"
              [ngClass]="(currentView == AnalyticsPeriod.WEEK_MONDAY || currentView == AnalyticsPeriod.WEEK_SUNDAY) ? 'btn-primary' : 'btn-muted'"
              (click)="changeViewTo('week')">{{ 'pages.default.analytics.weeks' | translate
              }}</button>
            <button type="button" class="btn btn-sm" id="analytics-change-view-to-months"
              [ngClass]="(currentView == AnalyticsPeriod.MONTH) ? 'btn-primary' : 'btn-muted'"
              (click)="changeViewTo('month')">{{ 'pages.default.analytics.months' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content body-content-analytics">
  <div class="container">
    <div class="row">
      <div class="col-3">
        <div class=" analytics-filters pb-7">
          <app-dna-filters type="onboarding" [hospital]="hospital"
            (onFiltersChange)="changeFiltersTo($event)"></app-dna-filters>
        </div>
      </div>

      <div class="col-9">
        <div class="analytics-content">
          <div class="h-100 d-flex align-items-center justify-content-center" *ngIf="isLoading">
            <app-page-loader></app-page-loader>
          </div>
          <div class="d-flex flex-column justify-content-start h-100">
            <div class="row mb-3" *ngIf="!isLoading && kpis?.length">
              <div class="col-3" *ngFor="let kpi of kpis; index as i">
                <ng-template #info>
                  <div class="'tooltip-'{{kpi.title}}">
                    <p class="font-weight-bold">
                      {{ 'pages.default.analytics.onboarding.patient_onboarding_chart.kpi.' + kpi.title | translate }}
                    </p>
                    <p>
                      {{ 'pages.default.analytics.onboarding.patient_onboarding_chart.kpi.' + kpi.title + '.paragraph_1'
                      |
                      translate
                      }}
                    </p>

                    <p>
                      {{ 'pages.default.analytics.onboarding.patient_onboarding_chart.kpi.' + kpi.title + '.paragraph_2'
                      |
                      translate
                      }}
                    </p>
                  </div>
                </ng-template>

                <div class="card kpi-card">
                  <div class="card-body">
                    <div class="kpi-content">
                      <p class="kpi-title with-tooltip">
                        <span>{{ 'pages.default.analytics.onboarding.patient_onboarding_chart.kpi.' + kpi.title |
                          translate
                          }}</span>
                        <span class="icon icon-xs" [inlineSVG]="'/assets/svg/info-circle.svg'" [popover]="info"
                          triggers="mouseenter:mouseleave" placement="auto" *ngIf="kpi.hasTooltip"></span>
                      </p>
                      <p class="kpi-description" *ngIf="kpi.description">{{ kpi.description }}</p>
                    </div>

                    <div class="kpi-value">
                      <div class="d-flex align-items-end mb-1">
                        <p class="h1 kpi-value--current">{{ kpi.current_period.amount }}</p>
                        @if(kpi.current_period.isUp || kpi.current_period.isDown){
                        <p class="kpi-value--trend"
                          [ngClass]="{ 'trend-increasing': kpi.current_period.isUp, 'trend-decreasing': kpi.current_period.isDown, 'trend-positive': kpi.current_period.isPositive, 'trend-negative': kpi.current_period.isNegative}"
                          [inlineSVG]="'/assets/svg/down-arrow-big.svg'"></p>
                        } @else {
                        <div class="kpi-value--trend trend-stable"></div>
                        }
                        <p class="kpi-value--percentage"
                          *ngIf="kpi.current_period.percentage !== undefined && kpi.current_period.percentage !== null">
                          {{
                          kpi.current_period.percentage }}%</p>
                      </div>

                      <p class="kpi-value--previous">
                        {{'pages.default.analytics.onboarding.patient_onboarding_chart.compared_to' | translate}}
                        <strong>{{ kpi.previous_period.amount }}</strong>
                        @switch (currentView) {
                        @case (AnalyticsPeriod.WEEK_MONDAY) {
                        {{'pages.default.analytics.onboarding.patient_onboarding_chart.last_week' | translate}}
                        }
                        @case (AnalyticsPeriod.WEEK_SUNDAY) {
                        {{'pages.default.analytics.onboarding.patient_onboarding_chart.last_week' | translate}}
                        }
                        @case (AnalyticsPeriod.MONTH) {
                        {{'pages.default.analytics.onboarding.patient_onboarding_chart.last_month' | translate}}
                        }
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-3" *ngIf="!isLoading">
              <div class="col-12">

                <ng-template #info>
                  <div [innerHtml]="'pages.default.analytics.onboarding.patient-onboarding-chart.info' | translate">
                  </div>
                </ng-template>

                <div class="card chart-card">
                  <div class="card-body">
                    <h3 class="chart-title with-tooltip">
                      <span>{{ 'pages.default.analytics.onboarding.patient-onboarding-chart.title' | translate }}</span>
                      <span class="icon icon-xs" [inlineSVG]="'/assets/svg/info-circle.svg'" [popover]="info"
                        triggers="mouseenter:mouseleave" placement="bottom"></span>
                    </h3>

                    <div class="highcharts-wrapper" id="patient-onboarding-chart" [chart]="patientOnboardingChart">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!isLoading">
              <div class="col-12">
                <ng-template #info>
                  <div
                    [innerHtml]="'pages.default.analytics.onboarding.patient-status-over-time-chart.info' | translate">
                  </div>
                </ng-template>
                <div class="card chart-card">
                  <div class="card-body">
                    <h3 class="chart-title with-tooltip">
                      <span>{{ 'pages.default.analytics.onboarding.patient-status-over-time-chart.title' | translate
                        }}</span>
                      <span class="icon icon-xs" [inlineSVG]="'/assets/svg/info-circle.svg'" [popover]="info"
                        triggers="mouseenter:mouseleave" placement="bottom"></span>
                    </h3>

                    <div class="highcharts-wrapper" id="patient-status-over-time-chart" [chart]="patientStatusOverTime">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
