import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BackButtonData } from '../../../models/back-button-data';
import { GeneralService } from '../../../services/general.service';
import { Material } from '../../../models/material';
import { LanguageService } from '../../../services/language.service';
import { MaterialService } from '../../../services/material.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.scss']
})
export class MaterialDetailComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;
  public educationalMaterialUid: string;
  public material: Material;
  public isLoadingMaterial: boolean;

  public backButtonData: BackButtonData;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public materialService: MaterialService,
    public generalService: GeneralService,
    private readonly languageService: LanguageService,
    public dataService: DataService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;
      this.educationalMaterialUid = params.educationalMaterialUid;

      this.loadMaterial();
    });

    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.handleQueryParams(queryParams);
    });
  }

  loadMaterial() {
    this.isLoadingMaterial = true;
    const language = this.languageService.getCurrentLanguageCode().locale;

    this.materialService.getMaterial(this.educationalMaterialUid, language).subscribe(result => {
      this.material = result;
      this.dataService.set(DataService.BreadCrumbMaterialTitle, this.material.title);

      this.isLoadingMaterial = false;
    }, error => {
      this.dataService.set(DataService.BreadCrumbMaterialTitle, this.translate.instant('pages.default.patient_materials.unavailable'));

      this.isLoadingMaterial = false;
    });
  }

  handleQueryParams(queryParams) {
    const backToUrl = queryParams['back-to-url'];

    if (backToUrl) {
      this.backButtonData = this.generalService.defineBackButton(backToUrl);
    }
  }
}
