!
/**
* Highmaps JS v11.4.8 (2024-08-29)
*
* (c) 2011-2024 Torstein Honsi
*
* License: www.highcharts.com/license
*/
function (t, e) {
  "object" == typeof module && module.exports ? (e.default = e, module.exports = t && t.document ? e(t) : e) : "function" == typeof define && define.amd ? define("highcharts/highmaps", function () {
    return e(t);
  }) : (t.Highcharts && t.Highcharts.error(16, !0), t.Highcharts = e(t));
}("undefined" != typeof window ? window : this, function (t) {
  "use strict";

  var e = {};
  function i(e, i, s, o) {
    !e.hasOwnProperty(i) && (e[i] = o.apply(null, s), t && "function" == typeof CustomEvent && t.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: i,
        module: e[i]
      }
    })));
  }
  return i(e, "Core/Globals.js", [], function () {
    var e, i;
    return (i = e || (e = {})).SVG_NS = "http://www.w3.org/2000/svg", i.product = "Highcharts", i.version = "11.4.8", i.win = void 0 !== t ? t : {}, i.doc = i.win.document, i.svg = i.doc && i.doc.createElementNS && !!i.doc.createElementNS(i.SVG_NS, "svg").createSVGRect, i.userAgent = i.win.navigator && i.win.navigator.userAgent || "", i.isChrome = i.win.chrome, i.isFirefox = -1 !== i.userAgent.indexOf("Firefox"), i.isMS = /(edge|msie|trident)/i.test(i.userAgent) && !i.win.opera, i.isSafari = !i.isChrome && -1 !== i.userAgent.indexOf("Safari"), i.isTouchDevice = /(Mobile|Android|Windows Phone)/.test(i.userAgent), i.isWebKit = -1 !== i.userAgent.indexOf("AppleWebKit"), i.deg2rad = 2 * Math.PI / 360, i.hasBidiBug = i.isFirefox && 4 > parseInt(i.userAgent.split("Firefox/")[1], 10), i.marginNames = ["plotTop", "marginRight", "marginBottom", "plotLeft"], i.noop = function () {}, i.supportsPassiveEvents = function () {
      let t = !1;
      if (!i.isMS) {
        let e = Object.defineProperty({}, "passive", {
          get: function () {
            t = !0;
          }
        });
        i.win.addEventListener && i.win.removeEventListener && (i.win.addEventListener("testPassive", i.noop, e), i.win.removeEventListener("testPassive", i.noop, e));
      }
      return t;
    }(), i.charts = [], i.composed = [], i.dateFormats = {}, i.seriesTypes = {}, i.symbolSizes = {}, i.chartCount = 0, e;
  }), i(e, "Core/Utilities.js", [e["Core/Globals.js"]], function (t) {
    let e;
    let {
      charts: i,
      doc: s,
      win: o
    } = t;
    function r(e, i, s, a) {
      let n = i ? "Highcharts error" : "Highcharts warning";
      32 === e && (e = `${n}: Deprecated member`);
      let l = p(e),
        h = l ? `${n} #${e}: www.highcharts.com/errors/${e}/` : e.toString();
      if (void 0 !== a) {
        let t = "";
        l && (h += "?"), C(a, function (e, i) {
          t += `
 - ${i}: ${e}`, l && (h += encodeURI(i) + "=" + encodeURI(e));
        }), h += t;
      }
      w(t, "displayError", {
        chart: s,
        code: e,
        message: h,
        params: a
      }, function () {
        if (i) throw Error(h);
        o.console && -1 === r.messages.indexOf(h) && console.warn(h);
      }), r.messages.push(h);
    }
    function a(t, e) {
      return parseInt(t, e || 10);
    }
    function n(t) {
      return "string" == typeof t;
    }
    function l(t) {
      let e = Object.prototype.toString.call(t);
      return "[object Array]" === e || "[object Array Iterator]" === e;
    }
    function h(t, e) {
      return !!t && "object" == typeof t && (!e || !l(t));
    }
    function d(t) {
      return h(t) && "number" == typeof t.nodeType;
    }
    function c(t) {
      let e = t && t.constructor;
      return !!(h(t, !0) && !d(t) && e && e.name && "Object" !== e.name);
    }
    function p(t) {
      return "number" == typeof t && !isNaN(t) && t < 1 / 0 && t > -1 / 0;
    }
    function u(t) {
      return null != t;
    }
    function g(t, e, i) {
      let s;
      let o = n(e) && !u(i),
        r = (e, i) => {
          u(e) ? t.setAttribute(i, e) : o ? (s = t.getAttribute(i)) || "class" !== i || (s = t.getAttribute(i + "Name")) : t.removeAttribute(i);
        };
      return n(e) ? r(i, e) : C(e, r), s;
    }
    function f(t) {
      return l(t) ? t : [t];
    }
    function m(t, e) {
      let i;
      for (i in t || (t = {}), e) t[i] = e[i];
      return t;
    }
    function x() {
      let t = arguments,
        e = t.length;
      for (let i = 0; i < e; i++) {
        let e = t[i];
        if (null != e) return e;
      }
    }
    function y(t, e) {
      m(t.style, e);
    }
    function b(t) {
      return Math.pow(10, Math.floor(Math.log(t) / Math.LN10));
    }
    function v(t, e) {
      return t > 1e14 ? t : parseFloat(t.toPrecision(e || 14));
    }
    (r || (r = {})).messages = [], Math.easeInOutSine = function (t) {
      return -.5 * (Math.cos(Math.PI * t) - 1);
    };
    let M = Array.prototype.find ? function (t, e) {
      return t.find(e);
    } : function (t, e) {
      let i;
      let s = t.length;
      for (i = 0; i < s; i++) if (e(t[i], i)) return t[i];
    };
    function C(t, e, i) {
      for (let s in t) Object.hasOwnProperty.call(t, s) && e.call(i || t[s], t[s], s, t);
    }
    function S(t, e, i) {
      function s(e, i) {
        let s = t.removeEventListener;
        s && s.call(t, e, i, !1);
      }
      function o(i) {
        let o, r;
        t.nodeName && (e ? (o = {})[e] = !0 : o = i, C(o, function (t, e) {
          if (i[e]) for (r = i[e].length; r--;) s(e, i[e][r].fn);
        }));
      }
      let r = "function" == typeof t && t.prototype || t;
      if (Object.hasOwnProperty.call(r, "hcEvents")) {
        let t = r.hcEvents;
        if (e) {
          let r = t[e] || [];
          i ? (t[e] = r.filter(function (t) {
            return i !== t.fn;
          }), s(e, i)) : (o(t), t[e] = []);
        } else o(t), delete r.hcEvents;
      }
    }
    function w(e, i, o, r) {
      if (o = o || {}, s.createEvent && (e.dispatchEvent || e.fireEvent && e !== t)) {
        let t = s.createEvent("Events");
        t.initEvent(i, !0, !0), o = m(t, o), e.dispatchEvent ? e.dispatchEvent(o) : e.fireEvent(i, o);
      } else if (e.hcEvents) {
        o.target || m(o, {
          preventDefault: function () {
            o.defaultPrevented = !0;
          },
          target: e,
          type: i
        });
        let t = [],
          s = e,
          r = !1;
        for (; s.hcEvents;) Object.hasOwnProperty.call(s, "hcEvents") && s.hcEvents[i] && (t.length && (r = !0), t.unshift.apply(t, s.hcEvents[i])), s = Object.getPrototypeOf(s);
        r && t.sort((t, e) => t.order - e.order), t.forEach(t => {
          !1 === t.fn.call(e, o) && o.preventDefault();
        });
      }
      r && !o.defaultPrevented && r.call(e, o);
    }
    C({
      map: "map",
      each: "forEach",
      grep: "filter",
      reduce: "reduce",
      some: "some"
    }, function (e, i) {
      t[i] = function (t) {
        return r(32, !1, void 0, {
          [`Highcharts.${i}`]: `use Array.${e}`
        }), Array.prototype[e].apply(t, [].slice.call(arguments, 1));
      };
    });
    let k = function () {
      let t = Math.random().toString(36).substring(2, 9) + "-",
        i = 0;
      return function () {
        return "highcharts-" + (e ? "" : t) + i++;
      };
    }();
    return o.jQuery && (o.jQuery.fn.highcharts = function () {
      let e = [].slice.call(arguments);
      if (this[0]) return e[0] ? (new t[n(e[0]) ? e.shift() : "Chart"](this[0], e[0], e[1]), this) : i[g(this[0], "data-highcharts-chart")];
    }), {
      addEvent: function (e, i, s, o = {}) {
        let r = "function" == typeof e && e.prototype || e;
        Object.hasOwnProperty.call(r, "hcEvents") || (r.hcEvents = {});
        let a = r.hcEvents;
        t.Point && e instanceof t.Point && e.series && e.series.chart && (e.series.chart.runTrackerClick = !0);
        let n = e.addEventListener;
        n && n.call(e, i, s, !!t.supportsPassiveEvents && {
          passive: void 0 === o.passive ? -1 !== i.indexOf("touch") : o.passive,
          capture: !1
        }), a[i] || (a[i] = []);
        let l = {
          fn: s,
          order: "number" == typeof o.order ? o.order : 1 / 0
        };
        return a[i].push(l), a[i].sort((t, e) => t.order - e.order), function () {
          S(e, i, s);
        };
      },
      arrayMax: function (t) {
        let e = t.length,
          i = t[0];
        for (; e--;) t[e] > i && (i = t[e]);
        return i;
      },
      arrayMin: function (t) {
        let e = t.length,
          i = t[0];
        for (; e--;) t[e] < i && (i = t[e]);
        return i;
      },
      attr: g,
      clamp: function (t, e, i) {
        return t > e ? t < i ? t : i : e;
      },
      clearTimeout: function (t) {
        u(t) && clearTimeout(t);
      },
      correctFloat: v,
      createElement: function (t, e, i, o, r) {
        let a = s.createElement(t);
        return e && m(a, e), r && y(a, {
          padding: "0",
          border: "none",
          margin: "0"
        }), i && y(a, i), o && o.appendChild(a), a;
      },
      crisp: (t, e = 0, i) => {
        let s = e % 2 / 2,
          o = i ? -1 : 1;
        return (Math.round(t * o - s) + s) * o;
      },
      css: y,
      defined: u,
      destroyObjectProperties: function (t, e, i) {
        C(t, function (s, o) {
          s !== e && s?.destroy && s.destroy(), (s?.destroy || !i) && delete t[o];
        });
      },
      diffObjects: function (t, e, i, s) {
        let o = {};
        return function t(e, o, r, a) {
          let n = i ? o : e;
          C(e, function (i, d) {
            if (!a && s && s.indexOf(d) > -1 && o[d]) {
              i = f(i), r[d] = [];
              for (let e = 0; e < Math.max(i.length, o[d].length); e++) o[d][e] && (void 0 === i[e] ? r[d][e] = o[d][e] : (r[d][e] = {}, t(i[e], o[d][e], r[d][e], a + 1)));
            } else h(i, !0) && !i.nodeType ? (r[d] = l(i) ? [] : {}, t(i, o[d] || {}, r[d], a + 1), 0 !== Object.keys(r[d]).length || "colorAxis" === d && 0 === a || delete r[d]) : (e[d] !== o[d] || d in e && !(d in o)) && "__proto__" !== d && "constructor" !== d && (r[d] = n[d]);
          });
        }(t, e, o, 0), o;
      },
      discardElement: function (t) {
        t && t.parentElement && t.parentElement.removeChild(t);
      },
      erase: function (t, e) {
        let i = t.length;
        for (; i--;) if (t[i] === e) {
          t.splice(i, 1);
          break;
        }
      },
      error: r,
      extend: m,
      extendClass: function (t, e) {
        let i = function () {};
        return i.prototype = new t(), m(i.prototype, e), i;
      },
      find: M,
      fireEvent: w,
      getClosestDistance: function (t, e) {
        let i, s, o, r;
        let a = !e;
        return t.forEach(t => {
          if (t.length > 1) for (r = s = t.length - 1; r > 0; r--) (o = t[r] - t[r - 1]) < 0 && !a ? (e?.(), e = void 0) : o && (void 0 === i || o < i) && (i = o);
        }), i;
      },
      getMagnitude: b,
      getNestedProperty: function (t, e) {
        let i = t.split(".");
        for (; i.length && u(e);) {
          let t = i.shift();
          if (void 0 === t || "__proto__" === t) return;
          if ("this" === t) {
            let t;
            return h(e) && (t = e["@this"]), t ?? e;
          }
          let s = e[t];
          if (!u(s) || "function" == typeof s || "number" == typeof s.nodeType || s === o) return;
          e = s;
        }
        return e;
      },
      getStyle: function t(e, i, s) {
        let r;
        if ("width" === i) {
          let i = Math.min(e.offsetWidth, e.scrollWidth),
            s = e.getBoundingClientRect && e.getBoundingClientRect().width;
          return s < i && s >= i - 1 && (i = Math.floor(s)), Math.max(0, i - (t(e, "padding-left", !0) || 0) - (t(e, "padding-right", !0) || 0));
        }
        if ("height" === i) return Math.max(0, Math.min(e.offsetHeight, e.scrollHeight) - (t(e, "padding-top", !0) || 0) - (t(e, "padding-bottom", !0) || 0));
        let n = o.getComputedStyle(e, void 0);
        return n && (r = n.getPropertyValue(i), x(s, "opacity" !== i) && (r = a(r))), r;
      },
      inArray: function (t, e, i) {
        return r(32, !1, void 0, {
          "Highcharts.inArray": "use Array.indexOf"
        }), e.indexOf(t, i);
      },
      insertItem: function (t, e) {
        let i;
        let s = t.options.index,
          o = e.length;
        for (i = t.options.isInternal ? o : 0; i < o + 1; i++) if (!e[i] || p(s) && s < x(e[i].options.index, e[i]._i) || e[i].options.isInternal) {
          e.splice(i, 0, t);
          break;
        }
        return i;
      },
      isArray: l,
      isClass: c,
      isDOMElement: d,
      isFunction: function (t) {
        return "function" == typeof t;
      },
      isNumber: p,
      isObject: h,
      isString: n,
      keys: function (t) {
        return r(32, !1, void 0, {
          "Highcharts.keys": "use Object.keys"
        }), Object.keys(t);
      },
      merge: function () {
        let t,
          e = arguments,
          i = {},
          s = function (t, e) {
            return "object" != typeof t && (t = {}), C(e, function (i, o) {
              "__proto__" !== o && "constructor" !== o && (!h(i, !0) || c(i) || d(i) ? t[o] = e[o] : t[o] = s(t[o] || {}, i));
            }), t;
          };
        !0 === e[0] && (i = e[1], e = Array.prototype.slice.call(e, 2));
        let o = e.length;
        for (t = 0; t < o; t++) i = s(i, e[t]);
        return i;
      },
      normalizeTickInterval: function (t, e, i, s, o) {
        let r,
          a = t;
        i = x(i, b(t));
        let n = t / i;
        for (!e && (e = o ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === s && (1 === i ? e = e.filter(function (t) {
          return t % 1 == 0;
        }) : i <= .1 && (e = [1 / i]))), r = 0; r < e.length && (a = e[r], (!o || !(a * i >= t)) && (o || !(n <= (e[r] + (e[r + 1] || e[r])) / 2))); r++);
        return v(a * i, -Math.round(Math.log(.001) / Math.LN10));
      },
      objectEach: C,
      offset: function (t) {
        let e = s.documentElement,
          i = t.parentElement || t.parentNode ? t.getBoundingClientRect() : {
            top: 0,
            left: 0,
            width: 0,
            height: 0
          };
        return {
          top: i.top + (o.pageYOffset || e.scrollTop) - (e.clientTop || 0),
          left: i.left + (o.pageXOffset || e.scrollLeft) - (e.clientLeft || 0),
          width: i.width,
          height: i.height
        };
      },
      pad: function (t, e, i) {
        return Array((e || 2) + 1 - String(t).replace("-", "").length).join(i || "0") + t;
      },
      pick: x,
      pInt: a,
      pushUnique: function (t, e) {
        return 0 > t.indexOf(e) && !!t.push(e);
      },
      relativeLength: function (t, e, i) {
        return /%$/.test(t) ? e * parseFloat(t) / 100 + (i || 0) : parseFloat(t);
      },
      removeEvent: S,
      replaceNested: function (t, ...e) {
        let i, s;
        do for (s of (i = t, e)) t = t.replace(s[0], s[1]); while (t !== i);
        return t;
      },
      splat: f,
      stableSort: function (t, e) {
        let i, s;
        let o = t.length;
        for (s = 0; s < o; s++) t[s].safeI = s;
        for (t.sort(function (t, s) {
          return 0 === (i = e(t, s)) ? t.safeI - s.safeI : i;
        }), s = 0; s < o; s++) delete t[s].safeI;
      },
      syncTimeout: function (t, e, i) {
        return e > 0 ? setTimeout(t, e, i) : (t.call(0, i), -1);
      },
      timeUnits: {
        millisecond: 1,
        second: 1e3,
        minute: 6e4,
        hour: 36e5,
        day: 864e5,
        week: 6048e5,
        month: 24192e5,
        year: 314496e5
      },
      uniqueKey: k,
      useSerialIds: function (t) {
        return e = x(t, e);
      },
      wrap: function (t, e, i) {
        let s = t[e];
        t[e] = function () {
          let t = arguments,
            e = this;
          return i.apply(this, [function () {
            return s.apply(e, arguments.length ? arguments : t);
          }].concat([].slice.call(arguments)));
        };
      }
    };
  }), i(e, "Core/Chart/ChartDefaults.js", [], function () {
    return {
      alignThresholds: !1,
      panning: {
        enabled: !1,
        type: "x"
      },
      styledMode: !1,
      borderRadius: 0,
      colorCount: 10,
      allowMutatingData: !0,
      ignoreHiddenSeries: !0,
      spacing: [10, 10, 15, 10],
      resetZoomButton: {
        theme: {},
        position: {}
      },
      reflow: !0,
      type: "line",
      zooming: {
        singleTouch: !1,
        resetButton: {
          theme: {
            zIndex: 6
          },
          position: {
            align: "right",
            x: -10,
            y: 10
          }
        }
      },
      width: null,
      height: null,
      borderColor: "#334eff",
      backgroundColor: "#ffffff",
      plotBorderColor: "#cccccc"
    };
  }), i(e, "Core/Color/Palettes.js", [], function () {
    return {
      colors: ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"]
    };
  }), i(e, "Core/Time.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        win: i
      } = t,
      {
        defined: s,
        error: o,
        extend: r,
        isNumber: a,
        isObject: n,
        merge: l,
        objectEach: h,
        pad: d,
        pick: c,
        splat: p,
        timeUnits: u
      } = e,
      g = t.isSafari && i.Intl && i.Intl.DateTimeFormat.prototype.formatRange,
      f = t.isSafari && i.Intl && !i.Intl.DateTimeFormat.prototype.formatRange;
    class m {
      constructor(t) {
        this.options = {}, this.useUTC = !1, this.variableTimezone = !1, this.Date = i.Date, this.getTimezoneOffset = this.timezoneOffsetFunction(), this.update(t);
      }
      get(t, e) {
        if (this.variableTimezone || this.timezoneOffset) {
          let i = e.getTime(),
            s = i - this.getTimezoneOffset(e);
          e.setTime(s);
          let o = e["getUTC" + t]();
          return e.setTime(i), o;
        }
        return this.useUTC ? e["getUTC" + t]() : e["get" + t]();
      }
      set(t, e, i) {
        if (this.variableTimezone || this.timezoneOffset) {
          if ("Milliseconds" === t || "Seconds" === t || "Minutes" === t && this.getTimezoneOffset(e) % 36e5 == 0) return e["setUTC" + t](i);
          let s = this.getTimezoneOffset(e),
            o = e.getTime() - s;
          e.setTime(o), e["setUTC" + t](i);
          let r = this.getTimezoneOffset(e);
          return o = e.getTime() + r, e.setTime(o);
        }
        return this.useUTC || g && "FullYear" === t ? e["setUTC" + t](i) : e["set" + t](i);
      }
      update(t = {}) {
        let e = c(t.useUTC, !0);
        this.options = t = l(!0, this.options, t), this.Date = t.Date || i.Date || Date, this.useUTC = e, this.timezoneOffset = e && t.timezoneOffset || void 0, this.getTimezoneOffset = this.timezoneOffsetFunction(), this.variableTimezone = e && !!(t.getTimezoneOffset || t.timezone);
      }
      makeTime(t, e, i, s, o, r) {
        let a, n, l;
        return this.useUTC ? (a = this.Date.UTC.apply(0, arguments), n = this.getTimezoneOffset(a), a += n, n !== (l = this.getTimezoneOffset(a)) ? a += l - n : n - 36e5 !== this.getTimezoneOffset(a - 36e5) || f || (a -= 36e5)) : a = new this.Date(t, e, c(i, 1), c(s, 0), c(o, 0), c(r, 0)).getTime(), a;
      }
      timezoneOffsetFunction() {
        let t = this,
          e = this.options,
          i = e.getTimezoneOffset;
        return this.useUTC ? e.timezone ? t => {
          try {
            let i = `shortOffset,${e.timezone || ""}`,
              [s, o, r, n, l = 0] = (m.formatCache[i] = m.formatCache[i] || Intl.DateTimeFormat("en", {
                timeZone: e.timezone,
                timeZoneName: "shortOffset"
              })).format(t).split(/(GMT|:)/).map(Number),
              h = -(36e5 * (r + l / 60));
            if (a(h)) return h;
          } catch (t) {
            o(34);
          }
          return 0;
        } : this.useUTC && i ? t => 6e4 * i(t.valueOf()) : () => 6e4 * (t.timezoneOffset || 0) : t => 6e4 * new Date(t.toString()).getTimezoneOffset();
      }
      dateFormat(e, i, o) {
        if (!s(i) || isNaN(i)) return t.defaultOptions.lang && t.defaultOptions.lang.invalidDate || "";
        e = c(e, "%Y-%m-%d %H:%M:%S");
        let a = this,
          n = new this.Date(i),
          l = this.get("Hours", n),
          p = this.get("Day", n),
          u = this.get("Date", n),
          g = this.get("Month", n),
          f = this.get("FullYear", n),
          m = t.defaultOptions.lang,
          x = m && m.weekdays,
          y = m && m.shortWeekdays;
        return h(r({
          a: y ? y[p] : x[p].substr(0, 3),
          A: x[p],
          d: d(u),
          e: d(u, 2, " "),
          w: p,
          b: m.shortMonths[g],
          B: m.months[g],
          m: d(g + 1),
          o: g + 1,
          y: f.toString().substr(2, 2),
          Y: f,
          H: d(l),
          k: l,
          I: d(l % 12 || 12),
          l: l % 12 || 12,
          M: d(this.get("Minutes", n)),
          p: l < 12 ? "AM" : "PM",
          P: l < 12 ? "am" : "pm",
          S: d(this.get("Seconds", n)),
          L: d(Math.floor(i % 1e3), 3)
        }, t.dateFormats), function (t, s) {
          for (; -1 !== e.indexOf("%" + s);) e = e.replace("%" + s, "function" == typeof t ? t.call(a, i) : t);
        }), o ? e.substr(0, 1).toUpperCase() + e.substr(1) : e;
      }
      resolveDTLFormat(t) {
        return n(t, !0) ? t : {
          main: (t = p(t))[0],
          from: t[1],
          to: t[2]
        };
      }
      getTimeTicks(t, e, i, o) {
        let a, n, l, h;
        let d = this,
          p = d.Date,
          g = [],
          f = {},
          m = new p(e),
          x = t.unitRange,
          y = t.count || 1;
        if (o = c(o, 1), s(e)) {
          d.set("Milliseconds", m, x >= u.second ? 0 : y * Math.floor(d.get("Milliseconds", m) / y)), x >= u.second && d.set("Seconds", m, x >= u.minute ? 0 : y * Math.floor(d.get("Seconds", m) / y)), x >= u.minute && d.set("Minutes", m, x >= u.hour ? 0 : y * Math.floor(d.get("Minutes", m) / y)), x >= u.hour && d.set("Hours", m, x >= u.day ? 0 : y * Math.floor(d.get("Hours", m) / y)), x >= u.day && d.set("Date", m, x >= u.month ? 1 : Math.max(1, y * Math.floor(d.get("Date", m) / y))), x >= u.month && (d.set("Month", m, x >= u.year ? 0 : y * Math.floor(d.get("Month", m) / y)), n = d.get("FullYear", m)), x >= u.year && (n -= n % y, d.set("FullYear", m, n)), x === u.week && (h = d.get("Day", m), d.set("Date", m, d.get("Date", m) - h + o + (h < o ? -7 : 0))), n = d.get("FullYear", m);
          let t = d.get("Month", m),
            r = d.get("Date", m),
            c = d.get("Hours", m);
          e = m.getTime(), (d.variableTimezone || !d.useUTC) && s(i) && (l = i - e > 4 * u.month || d.getTimezoneOffset(e) !== d.getTimezoneOffset(i));
          let p = m.getTime();
          for (a = 1; p < i;) g.push(p), x === u.year ? p = d.makeTime(n + a * y, 0) : x === u.month ? p = d.makeTime(n, t + a * y) : l && (x === u.day || x === u.week) ? p = d.makeTime(n, t, r + a * y * (x === u.day ? 1 : 7)) : l && x === u.hour && y > 1 ? p = d.makeTime(n, t, r, c + a * y) : p += x * y, a++;
          g.push(p), x <= u.hour && g.length < 1e4 && g.forEach(function (t) {
            t % 18e5 == 0 && "000000000" === d.dateFormat("%H%M%S%L", t) && (f[t] = "day");
          });
        }
        return g.info = r(t, {
          higherRanks: f,
          totalRange: x * y
        }), g;
      }
      getDateFormat(t, e, i, s) {
        let o = this.dateFormat("%m-%d %H:%M:%S.%L", e),
          r = "01-01 00:00:00.000",
          a = {
            millisecond: 15,
            second: 12,
            minute: 9,
            hour: 6,
            day: 3
          },
          n = "millisecond",
          l = n;
        for (n in u) {
          if (t === u.week && +this.dateFormat("%w", e) === i && o.substr(6) === r.substr(6)) {
            n = "week";
            break;
          }
          if (u[n] > t) {
            n = l;
            break;
          }
          if (a[n] && o.substr(a[n]) !== r.substr(a[n])) break;
          "week" !== n && (l = n);
        }
        return this.resolveDTLFormat(s[n]).main;
      }
    }
    return m.formatCache = {}, m;
  }), i(e, "Core/Defaults.js", [e["Core/Chart/ChartDefaults.js"], e["Core/Globals.js"], e["Core/Color/Palettes.js"], e["Core/Time.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    let {
        isTouchDevice: r
      } = e,
      {
        fireEvent: a,
        merge: n
      } = o,
      l = {
        colors: i.colors,
        symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
        lang: {
          loading: "Loading...",
          months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          decimalPoint: ".",
          numericSymbols: ["k", "M", "G", "T", "P", "E"],
          resetZoom: "Reset zoom",
          resetZoomTitle: "Reset zoom level 1:1",
          thousandsSep: " "
        },
        global: {
          buttonTheme: {
            fill: "#f7f7f7",
            padding: 8,
            r: 2,
            stroke: "#cccccc",
            "stroke-width": 1,
            style: {
              color: "#333333",
              cursor: "pointer",
              fontSize: "0.8em",
              fontWeight: "normal"
            },
            states: {
              hover: {
                fill: "#e6e6e6"
              },
              select: {
                fill: "#e6e9ff",
                style: {
                  color: "#000000",
                  fontWeight: "bold"
                }
              },
              disabled: {
                style: {
                  color: "#cccccc"
                }
              }
            }
          }
        },
        time: {
          Date: void 0,
          getTimezoneOffset: void 0,
          timezone: void 0,
          timezoneOffset: 0,
          useUTC: !0
        },
        chart: t,
        title: {
          style: {
            color: "#333333",
            fontWeight: "bold"
          },
          text: "Chart title",
          align: "center",
          margin: 15,
          widthAdjust: -44
        },
        subtitle: {
          style: {
            color: "#666666",
            fontSize: "0.8em"
          },
          text: "",
          align: "center",
          widthAdjust: -44
        },
        caption: {
          margin: 15,
          style: {
            color: "#666666",
            fontSize: "0.8em"
          },
          text: "",
          align: "left",
          verticalAlign: "bottom"
        },
        plotOptions: {},
        legend: {
          enabled: !0,
          align: "center",
          alignColumns: !0,
          className: "highcharts-no-tooltip",
          events: {},
          layout: "horizontal",
          itemMarginBottom: 2,
          itemMarginTop: 2,
          labelFormatter: function () {
            return this.name;
          },
          borderColor: "#999999",
          borderRadius: 0,
          navigation: {
            style: {
              fontSize: "0.8em"
            },
            activeColor: "#0022ff",
            inactiveColor: "#cccccc"
          },
          itemStyle: {
            color: "#333333",
            cursor: "pointer",
            fontSize: "0.8em",
            textDecoration: "none",
            textOverflow: "ellipsis"
          },
          itemHoverStyle: {
            color: "#000000"
          },
          itemHiddenStyle: {
            color: "#666666",
            textDecoration: "line-through"
          },
          shadow: !1,
          itemCheckboxStyle: {
            position: "absolute",
            width: "13px",
            height: "13px"
          },
          squareSymbol: !0,
          symbolPadding: 5,
          verticalAlign: "bottom",
          x: 0,
          y: 0,
          title: {
            style: {
              fontSize: "0.8em",
              fontWeight: "bold"
            }
          }
        },
        loading: {
          labelStyle: {
            fontWeight: "bold",
            position: "relative",
            top: "45%"
          },
          style: {
            position: "absolute",
            backgroundColor: "#ffffff",
            opacity: .5,
            textAlign: "center"
          }
        },
        tooltip: {
          enabled: !0,
          animation: {
            duration: 300,
            easing: t => Math.sqrt(1 - Math.pow(t - 1, 2))
          },
          borderRadius: 3,
          dateTimeLabelFormats: {
            millisecond: "%A, %e %b, %H:%M:%S.%L",
            second: "%A, %e %b, %H:%M:%S",
            minute: "%A, %e %b, %H:%M",
            hour: "%A, %e %b, %H:%M",
            day: "%A, %e %b %Y",
            week: "Week from %A, %e %b %Y",
            month: "%B %Y",
            year: "%Y"
          },
          footerFormat: "",
          headerShape: "callout",
          hideDelay: 500,
          padding: 8,
          shape: "callout",
          shared: !1,
          snap: r ? 25 : 10,
          headerFormat: '<span style="font-size: 0.8em">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
          backgroundColor: "#ffffff",
          borderWidth: void 0,
          shadow: !0,
          stickOnContact: !1,
          style: {
            color: "#333333",
            cursor: "default",
            fontSize: "0.8em"
          },
          useHTML: !1
        },
        credits: {
          enabled: !0,
          href: "https://www.highcharts.com?credits",
          position: {
            align: "right",
            x: -10,
            verticalAlign: "bottom",
            y: -5
          },
          style: {
            cursor: "pointer",
            color: "#999999",
            fontSize: "0.6em"
          },
          text: "Highcharts.com"
        }
      };
    l.chart.styledMode = !1;
    let h = new s(l.time);
    return {
      defaultOptions: l,
      defaultTime: h,
      getOptions: function () {
        return l;
      },
      setOptions: function (t) {
        return a(e, "setOptions", {
          options: t
        }), n(!0, l, t), (t.time || t.global) && (e.time ? e.time.update(n(l.global, l.time, t.global, t.time)) : e.time = h), l;
      }
    };
  }), i(e, "Core/Color/Color.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      isNumber: i,
      merge: s,
      pInt: o
    } = e;
    class r {
      static parse(t) {
        return t ? new r(t) : r.None;
      }
      constructor(e) {
        let i, s, o, a;
        this.rgba = [NaN, NaN, NaN, NaN], this.input = e;
        let n = t.Color;
        if (n && n !== r) return new n(e);
        if ("object" == typeof e && void 0 !== e.stops) this.stops = e.stops.map(t => new r(t[1]));else if ("string" == typeof e) {
          if (this.input = e = r.names[e.toLowerCase()] || e, "#" === e.charAt(0)) {
            let t = e.length,
              i = parseInt(e.substr(1), 16);
            7 === t ? s = [(16711680 & i) >> 16, (65280 & i) >> 8, 255 & i, 1] : 4 === t && (s = [(3840 & i) >> 4 | (3840 & i) >> 8, (240 & i) >> 4 | 240 & i, (15 & i) << 4 | 15 & i, 1]);
          }
          if (!s) for (o = r.parsers.length; o-- && !s;) (i = (a = r.parsers[o]).regex.exec(e)) && (s = a.parse(i));
        }
        s && (this.rgba = s);
      }
      get(t) {
        let e = this.input,
          o = this.rgba;
        if ("object" == typeof e && void 0 !== this.stops) {
          let i = s(e);
          return i.stops = [].slice.call(i.stops), this.stops.forEach((e, s) => {
            i.stops[s] = [i.stops[s][0], e.get(t)];
          }), i;
        }
        return o && i(o[0]) ? "rgb" !== t && (t || 1 !== o[3]) ? "a" === t ? `${o[3]}` : "rgba(" + o.join(",") + ")" : "rgb(" + o[0] + "," + o[1] + "," + o[2] + ")" : e;
      }
      brighten(t) {
        let e = this.rgba;
        if (this.stops) this.stops.forEach(function (e) {
          e.brighten(t);
        });else if (i(t) && 0 !== t) for (let i = 0; i < 3; i++) e[i] += o(255 * t), e[i] < 0 && (e[i] = 0), e[i] > 255 && (e[i] = 255);
        return this;
      }
      setOpacity(t) {
        return this.rgba[3] = t, this;
      }
      tweenTo(t, e) {
        let s = this.rgba,
          o = t.rgba;
        if (!i(s[0]) || !i(o[0])) return t.input || "none";
        let r = 1 !== o[3] || 1 !== s[3];
        return (r ? "rgba(" : "rgb(") + Math.round(o[0] + (s[0] - o[0]) * (1 - e)) + "," + Math.round(o[1] + (s[1] - o[1]) * (1 - e)) + "," + Math.round(o[2] + (s[2] - o[2]) * (1 - e)) + (r ? "," + (o[3] + (s[3] - o[3]) * (1 - e)) : "") + ")";
      }
    }
    return r.names = {
      white: "#ffffff",
      black: "#000000"
    }, r.parsers = [{
      regex: /rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d?(?:\.\d+)?)\s*\)/,
      parse: function (t) {
        return [o(t[1]), o(t[2]), o(t[3]), parseFloat(t[4], 10)];
      }
    }, {
      regex: /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/,
      parse: function (t) {
        return [o(t[1]), o(t[2]), o(t[3]), 1];
      }
    }], r.None = new r(""), r;
  }), i(e, "Core/Animation/Fx.js", [e["Core/Color/Color.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        parse: s
      } = t,
      {
        win: o
      } = e,
      {
        isNumber: r,
        objectEach: a
      } = i;
    class n {
      constructor(t, e, i) {
        this.pos = NaN, this.options = e, this.elem = t, this.prop = i;
      }
      dSetter() {
        let t = this.paths,
          e = t && t[0],
          i = t && t[1],
          s = this.now || 0,
          o = [];
        if (1 !== s && e && i) {
          if (e.length === i.length && s < 1) for (let t = 0; t < i.length; t++) {
            let a = e[t],
              n = i[t],
              l = [];
            for (let t = 0; t < n.length; t++) {
              let e = a[t],
                i = n[t];
              r(e) && r(i) && !("A" === n[0] && (4 === t || 5 === t)) ? l[t] = e + s * (i - e) : l[t] = i;
            }
            o.push(l);
          } else o = i;
        } else o = this.toD || [];
        this.elem.attr("d", o, void 0, !0);
      }
      update() {
        let t = this.elem,
          e = this.prop,
          i = this.now,
          s = this.options.step;
        this[e + "Setter"] ? this[e + "Setter"]() : t.attr ? t.element && t.attr(e, i, null, !0) : t.style[e] = i + this.unit, s && s.call(t, i, this);
      }
      run(t, e, i) {
        let s = this,
          r = s.options,
          a = function (t) {
            return !a.stopped && s.step(t);
          },
          l = o.requestAnimationFrame || function (t) {
            setTimeout(t, 13);
          },
          h = function () {
            for (let t = 0; t < n.timers.length; t++) n.timers[t]() || n.timers.splice(t--, 1);
            n.timers.length && l(h);
          };
        t !== e || this.elem["forceAnimate:" + this.prop] ? (this.startTime = +new Date(), this.start = t, this.end = e, this.unit = i, this.now = this.start, this.pos = 0, a.elem = this.elem, a.prop = this.prop, a() && 1 === n.timers.push(a) && l(h)) : (delete r.curAnim[this.prop], r.complete && 0 === Object.keys(r.curAnim).length && r.complete.call(this.elem));
      }
      step(t) {
        let e, i;
        let s = +new Date(),
          o = this.options,
          r = this.elem,
          n = o.complete,
          l = o.duration,
          h = o.curAnim;
        return r.attr && !r.element ? e = !1 : t || s >= l + this.startTime ? (this.now = this.end, this.pos = 1, this.update(), h[this.prop] = !0, i = !0, a(h, function (t) {
          !0 !== t && (i = !1);
        }), i && n && n.call(r), e = !1) : (this.pos = o.easing((s - this.startTime) / l), this.now = this.start + (this.end - this.start) * this.pos, this.update(), e = !0), e;
      }
      initPath(t, e, i) {
        let s = t.startX,
          o = t.endX,
          a = i.slice(),
          n = t.isArea,
          l = n ? 2 : 1,
          h = e && i.length > e.length && i.hasStackedCliffs,
          d,
          c,
          p,
          u,
          g = e && e.slice();
        if (!g || h) return [a, a];
        function f(t, e) {
          for (; t.length < c;) {
            let i = t[0],
              s = e[c - t.length];
            if (s && "M" === i[0] && ("C" === s[0] ? t[0] = ["C", i[1], i[2], i[1], i[2], i[1], i[2]] : t[0] = ["L", i[1], i[2]]), t.unshift(i), n) {
              let e = t.pop();
              t.push(t[t.length - 1], e);
            }
          }
        }
        function m(t) {
          for (; t.length < c;) {
            let e = t[Math.floor(t.length / l) - 1].slice();
            if ("C" === e[0] && (e[1] = e[5], e[2] = e[6]), n) {
              let i = t[Math.floor(t.length / l)].slice();
              t.splice(t.length / 2, 0, e, i);
            } else t.push(e);
          }
        }
        if (s && o && o.length) {
          for (p = 0; p < s.length; p++) {
            if (s[p] === o[0]) {
              d = p;
              break;
            }
            if (s[0] === o[o.length - s.length + p]) {
              d = p, u = !0;
              break;
            }
            if (s[s.length - 1] === o[o.length - s.length + p]) {
              d = s.length - p;
              break;
            }
          }
          void 0 === d && (g = []);
        }
        return g.length && r(d) && (c = a.length + d * l, u ? (f(g, a), m(a)) : (f(a, g), m(g))), [g, a];
      }
      fillSetter() {
        n.prototype.strokeSetter.apply(this, arguments);
      }
      strokeSetter() {
        this.elem.attr(this.prop, s(this.start).tweenTo(s(this.end), this.pos), void 0, !0);
      }
    }
    return n.timers = [], n;
  }), i(e, "Core/Animation/AnimationUtilities.js", [e["Core/Animation/Fx.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      defined: i,
      getStyle: s,
      isArray: o,
      isNumber: r,
      isObject: a,
      merge: n,
      objectEach: l,
      pick: h
    } = e;
    function d(t) {
      return a(t) ? n({
        duration: 500,
        defer: 0
      }, t) : {
        duration: t ? 500 : 0,
        defer: 0
      };
    }
    function c(e, i) {
      let s = t.timers.length;
      for (; s--;) t.timers[s].elem !== e || i && i !== t.timers[s].prop || (t.timers[s].stopped = !0);
    }
    return {
      animate: function (e, i, h) {
        let d,
          p = "",
          u,
          g,
          f;
        a(h) || (f = arguments, h = {
          duration: f[2],
          easing: f[3],
          complete: f[4]
        }), r(h.duration) || (h.duration = 400), h.easing = "function" == typeof h.easing ? h.easing : Math[h.easing] || Math.easeInOutSine, h.curAnim = n(i), l(i, function (r, a) {
          c(e, a), g = new t(e, h, a), u = void 0, "d" === a && o(i.d) ? (g.paths = g.initPath(e, e.pathArray, i.d), g.toD = i.d, d = 0, u = 1) : e.attr ? d = e.attr(a) : (d = parseFloat(s(e, a)) || 0, "opacity" !== a && (p = "px")), u || (u = r), "string" == typeof u && u.match("px") && (u = u.replace(/px/g, "")), g.run(d, u, p);
        });
      },
      animObject: d,
      getDeferredAnimation: function (t, e, s) {
        let o = d(e),
          r = s ? [s] : t.series,
          n = 0,
          l = 0;
        return r.forEach(t => {
          let s = d(t.options.animation);
          n = a(e) && i(e.defer) ? o.defer : Math.max(n, s.duration + s.defer), l = Math.min(o.duration, s.duration);
        }), t.renderer.forExport && (n = 0), {
          defer: Math.max(0, n - l),
          duration: Math.min(n, l)
        };
      },
      setAnimation: function (t, e) {
        e.renderer.globalAnimation = h(t, e.options.chart.animation, !0);
      },
      stop: c
    };
  }), i(e, "Core/Renderer/HTML/AST.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        SVG_NS: i,
        win: s
      } = t,
      {
        attr: o,
        createElement: r,
        css: a,
        error: n,
        isFunction: l,
        isString: h,
        objectEach: d,
        splat: c
      } = e,
      {
        trustedTypes: p
      } = s,
      u = p && l(p.createPolicy) && p.createPolicy("highcharts", {
        createHTML: t => t
      }),
      g = u ? u.createHTML("") : "",
      f = function () {
        try {
          return !!new DOMParser().parseFromString(g, "text/html");
        } catch (t) {
          return !1;
        }
      }();
    class m {
      static filterUserAttributes(t) {
        return d(t, (e, i) => {
          let s = !0;
          -1 === m.allowedAttributes.indexOf(i) && (s = !1), -1 !== ["background", "dynsrc", "href", "lowsrc", "src"].indexOf(i) && (s = h(e) && m.allowedReferences.some(t => 0 === e.indexOf(t))), s || (n(33, !1, void 0, {
            "Invalid attribute in config": `${i}`
          }), delete t[i]), h(e) && t[i] && (t[i] = e.replace(/</g, "&lt;"));
        }), t;
      }
      static parseStyle(t) {
        return t.split(";").reduce((t, e) => {
          let i = e.split(":").map(t => t.trim()),
            s = i.shift();
          return s && i.length && (t[s.replace(/-([a-z])/g, t => t[1].toUpperCase())] = i.join(":")), t;
        }, {});
      }
      static setElementHTML(t, e) {
        t.innerHTML = m.emptyHTML, e && new m(e).addToDOM(t);
      }
      constructor(t) {
        this.nodes = "string" == typeof t ? this.parseMarkup(t) : t;
      }
      addToDOM(e) {
        return function e(s, r) {
          let l;
          return c(s).forEach(function (s) {
            let h;
            let c = s.tagName,
              p = s.textContent ? t.doc.createTextNode(s.textContent) : void 0,
              u = m.bypassHTMLFiltering;
            if (c) {
              if ("#text" === c) h = p;else if (-1 !== m.allowedTags.indexOf(c) || u) {
                let n = "svg" === c ? i : r.namespaceURI || i,
                  l = t.doc.createElementNS(n, c),
                  g = s.attributes || {};
                d(s, function (t, e) {
                  "tagName" !== e && "attributes" !== e && "children" !== e && "style" !== e && "textContent" !== e && (g[e] = t);
                }), o(l, u ? g : m.filterUserAttributes(g)), s.style && a(l, s.style), p && l.appendChild(p), e(s.children || [], l), h = l;
              } else n(33, !1, void 0, {
                "Invalid tagName in config": c
              });
            }
            h && r.appendChild(h), l = h;
          }), l;
        }(this.nodes, e);
      }
      parseMarkup(t) {
        let e;
        let i = [];
        if (t = t.trim().replace(/ style=(["'])/g, " data-style=$1"), f) e = new DOMParser().parseFromString(u ? u.createHTML(t) : t, "text/html");else {
          let i = r("div");
          i.innerHTML = t, e = {
            body: i
          };
        }
        let s = (t, e) => {
          let i = t.nodeName.toLowerCase(),
            o = {
              tagName: i
            };
          "#text" === i && (o.textContent = t.textContent || "");
          let r = t.attributes;
          if (r) {
            let t = {};
            [].forEach.call(r, e => {
              "data-style" === e.name ? o.style = m.parseStyle(e.value) : t[e.name] = e.value;
            }), o.attributes = t;
          }
          if (t.childNodes.length) {
            let e = [];
            [].forEach.call(t.childNodes, t => {
              s(t, e);
            }), e.length && (o.children = e);
          }
          e.push(o);
        };
        return [].forEach.call(e.body.childNodes, t => s(t, i)), i;
      }
    }
    return m.allowedAttributes = ["alt", "aria-controls", "aria-describedby", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-label", "aria-labelledby", "aria-live", "aria-pressed", "aria-readonly", "aria-roledescription", "aria-selected", "class", "clip-path", "color", "colspan", "cx", "cy", "d", "dx", "dy", "disabled", "fill", "filterUnits", "flood-color", "flood-opacity", "height", "href", "id", "in", "in2", "markerHeight", "markerWidth", "offset", "opacity", "operator", "orient", "padding", "paddingLeft", "paddingRight", "patternUnits", "r", "radius", "refX", "refY", "role", "scope", "slope", "src", "startOffset", "stdDeviation", "stroke", "stroke-linecap", "stroke-width", "style", "tableValues", "result", "rowspan", "summary", "target", "tabindex", "text-align", "text-anchor", "textAnchor", "textLength", "title", "type", "valign", "width", "x", "x1", "x2", "xlink:href", "y", "y1", "y2", "zIndex"], m.allowedReferences = ["https://", "http://", "mailto:", "/", "../", "./", "#"], m.allowedTags = ["a", "abbr", "b", "br", "button", "caption", "circle", "clipPath", "code", "dd", "defs", "div", "dl", "dt", "em", "feComponentTransfer", "feComposite", "feDropShadow", "feFlood", "feFuncA", "feFuncB", "feFuncG", "feFuncR", "feGaussianBlur", "feMorphology", "feOffset", "feMerge", "feMergeNode", "filter", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "li", "linearGradient", "marker", "ol", "p", "path", "pattern", "pre", "rect", "small", "span", "stop", "strong", "style", "sub", "sup", "svg", "table", "text", "textPath", "thead", "title", "tbody", "tspan", "td", "th", "tr", "u", "ul", "#text"], m.emptyHTML = g, m.bypassHTMLFiltering = !1, m;
  }), i(e, "Core/Templating.js", [e["Core/Defaults.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        defaultOptions: i,
        defaultTime: s
      } = t,
      {
        extend: o,
        getNestedProperty: r,
        isArray: a,
        isNumber: n,
        isObject: l,
        pick: h,
        pInt: d
      } = e,
      c = {
        add: (t, e) => t + e,
        divide: (t, e) => 0 !== e ? t / e : "",
        eq: (t, e) => t == e,
        each: function (t) {
          let e = arguments[arguments.length - 1];
          return !!a(t) && t.map((i, s) => p(e.body, o(l(i) ? i : {
            "@this": i
          }, {
            "@index": s,
            "@first": 0 === s,
            "@last": s === t.length - 1
          }))).join("");
        },
        ge: (t, e) => t >= e,
        gt: (t, e) => t > e,
        if: t => !!t,
        le: (t, e) => t <= e,
        lt: (t, e) => t < e,
        multiply: (t, e) => t * e,
        ne: (t, e) => t != e,
        subtract: (t, e) => t - e,
        unless: t => !t
      };
    function p(t = "", e, o) {
      let a = /\{([\w\:\.\,;\-\/<>%@"'’= #\(\)]+)\}/g,
        n = /\(([\w\:\.\,;\-\/<>%@"'= ]+)\)/g,
        l = [],
        d = /f$/,
        g = /\.(\d)/,
        f = i.lang,
        m = o && o.time || s,
        x = o && o.numberFormatter || u,
        y = (t = "") => {
          let i;
          return "true" === t || "false" !== t && ((i = Number(t)).toString() === t ? i : r(t, e));
        },
        b,
        v,
        M = 0,
        C;
      for (; null !== (b = a.exec(t));) {
        let i = n.exec(b[1]);
        i && (b = i, C = !0), v && v.isBlock || (v = {
          ctx: e,
          expression: b[1],
          find: b[0],
          isBlock: "#" === b[1].charAt(0),
          start: b.index,
          startInner: b.index + b[0].length,
          length: b[0].length
        });
        let s = b[1].split(" ")[0].replace("#", "");
        c[s] && (v.isBlock && s === v.fn && M++, v.fn || (v.fn = s));
        let o = "else" === b[1];
        if (v.isBlock && v.fn && (b[1] === `/${v.fn}` || o)) {
          if (M) !o && M--;else {
            let e = v.startInner,
              i = t.substr(e, b.index - e);
            void 0 === v.body ? (v.body = i, v.startInner = b.index + b[0].length) : v.elseBody = i, v.find += i + b[0], o || (l.push(v), v = void 0);
          }
        } else v.isBlock || l.push(v);
        if (i && !v?.isBlock) break;
      }
      return l.forEach(i => {
        let s, r;
        let {
          body: a,
          elseBody: n,
          expression: l,
          fn: u
        } = i;
        if (u) {
          let t = [i],
            h = l.split(" ");
          for (r = c[u].length; r--;) t.unshift(y(h[r + 1]));
          s = c[u].apply(e, t), i.isBlock && "boolean" == typeof s && (s = p(s ? a : n, e, o));
        } else {
          let t = l.split(":");
          if (s = y(t.shift() || ""), t.length && "number" == typeof s) {
            let e = t.join(":");
            if (d.test(e)) {
              let t = parseInt((e.match(g) || ["", "-1"])[1], 10);
              null !== s && (s = x(s, t, f.decimalPoint, e.indexOf(",") > -1 ? f.thousandsSep : ""));
            } else s = m.dateFormat(e, s);
          }
        }
        t = t.replace(i.find, h(s, ""));
      }), C ? p(t, e, o) : t;
    }
    function u(t, e, s, o) {
      let r, a;
      t = +t || 0, e = +e;
      let l = i.lang,
        c = (t.toString().split(".")[1] || "").split("e")[0].length,
        p = t.toString().split("e"),
        u = e;
      -1 === e ? e = Math.min(c, 20) : n(e) ? e && p[1] && p[1] < 0 && ((a = e + +p[1]) >= 0 ? (p[0] = (+p[0]).toExponential(a).split("e")[0], e = a) : (p[0] = p[0].split(".")[0] || 0, t = e < 20 ? (p[0] * Math.pow(10, p[1])).toFixed(e) : 0, p[1] = 0)) : e = 2;
      let g = (Math.abs(p[1] ? p[0] : t) + Math.pow(10, -Math.max(e, c) - 1)).toFixed(e),
        f = String(d(g)),
        m = f.length > 3 ? f.length % 3 : 0;
      return s = h(s, l.decimalPoint), o = h(o, l.thousandsSep), r = (t < 0 ? "-" : "") + (m ? f.substr(0, m) + o : ""), 0 > +p[1] && !u ? r = "0" : r += f.substr(m).replace(/(\d{3})(?=\d)/g, "$1" + o), e ? r += s + g.slice(-e) : 0 == +r && (r = "0"), p[1] && 0 != +r && (r += "e" + p[1]), r;
    }
    return {
      dateFormat: function (t, e, i) {
        return s.dateFormat(t, e, i);
      },
      format: p,
      helpers: c,
      numberFormat: u
    };
  }), i(e, "Core/Renderer/RendererRegistry.js", [e["Core/Globals.js"]], function (t) {
    var e, i;
    let s;
    return (i = e || (e = {})).rendererTypes = {}, i.getRendererType = function (t = s) {
      return i.rendererTypes[t] || i.rendererTypes[s];
    }, i.registerRendererType = function (e, o, r) {
      i.rendererTypes[e] = o, (!s || r) && (s = e, t.Renderer = o);
    }, e;
  }), i(e, "Core/Renderer/RendererUtilities.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      clamp: i,
      pick: s,
      pushUnique: o,
      stableSort: r
    } = t;
    return (e || (e = {})).distribute = function t(e, a, n) {
      let l = e,
        h = l.reducedLen || a,
        d = (t, e) => t.target - e.target,
        c = [],
        p = e.length,
        u = [],
        g = c.push,
        f,
        m,
        x,
        y = !0,
        b,
        v,
        M = 0,
        C;
      for (f = p; f--;) M += e[f].size;
      if (M > h) {
        for (r(e, (t, e) => (e.rank || 0) - (t.rank || 0)), x = (C = e[0].rank === e[e.length - 1].rank) ? p / 2 : -1, m = C ? x : p - 1; x && M > h;) b = e[f = Math.floor(m)], o(u, f) && (M -= b.size), m += x, C && m >= e.length && (x /= 2, m = x);
        u.sort((t, e) => e - t).forEach(t => g.apply(c, e.splice(t, 1)));
      }
      for (r(e, d), e = e.map(t => ({
        size: t.size,
        targets: [t.target],
        align: s(t.align, .5)
      })); y;) {
        for (f = e.length; f--;) b = e[f], v = (Math.min.apply(0, b.targets) + Math.max.apply(0, b.targets)) / 2, b.pos = i(v - b.size * b.align, 0, a - b.size);
        for (f = e.length, y = !1; f--;) f > 0 && e[f - 1].pos + e[f - 1].size > e[f].pos && (e[f - 1].size += e[f].size, e[f - 1].targets = e[f - 1].targets.concat(e[f].targets), e[f - 1].align = .5, e[f - 1].pos + e[f - 1].size > a && (e[f - 1].pos = a - e[f - 1].size), e.splice(f, 1), y = !0);
      }
      return g.apply(l, c), f = 0, e.some(e => {
        let i = 0;
        return (e.targets || []).some(() => (l[f].pos = e.pos + i, void 0 !== n && Math.abs(l[f].pos - l[f].target) > n) ? (l.slice(0, f + 1).forEach(t => delete t.pos), l.reducedLen = (l.reducedLen || a) - .1 * a, l.reducedLen > .1 * a && t(l, a, n), !0) : (i += l[f].size, f++, !1));
      }), r(l, d), l;
    }, e;
  }), i(e, "Core/Renderer/SVG/SVGElement.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Color/Color.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
        animate: o,
        animObject: r,
        stop: a
      } = t,
      {
        deg2rad: n,
        doc: l,
        svg: h,
        SVG_NS: d,
        win: c
      } = i,
      {
        addEvent: p,
        attr: u,
        createElement: g,
        crisp: f,
        css: m,
        defined: x,
        erase: y,
        extend: b,
        fireEvent: v,
        isArray: M,
        isFunction: C,
        isObject: S,
        isString: w,
        merge: k,
        objectEach: A,
        pick: T,
        pInt: P,
        pushUnique: L,
        replaceNested: j,
        syncTimeout: O,
        uniqueKey: D
      } = s;
    class E {
      _defaultGetter(t) {
        let e = T(this[t + "Value"], this[t], this.element ? this.element.getAttribute(t) : null, 0);
        return /^-?[\d\.]+$/.test(e) && (e = parseFloat(e)), e;
      }
      _defaultSetter(t, e, i) {
        i.setAttribute(e, t);
      }
      add(t) {
        let e;
        let i = this.renderer,
          s = this.element;
        return t && (this.parentGroup = t), void 0 !== this.textStr && "text" === this.element.nodeName && i.buildText(this), this.added = !0, (!t || t.handleZ || this.zIndex) && (e = this.zIndexSetter()), e || (t ? t.element : i.box).appendChild(s), this.onAdd && this.onAdd(), this;
      }
      addClass(t, e) {
        let i = e ? "" : this.attr("class") || "";
        return (t = (t || "").split(/ /g).reduce(function (t, e) {
          return -1 === i.indexOf(e) && t.push(e), t;
        }, i ? [i] : []).join(" ")) !== i && this.attr("class", t), this;
      }
      afterSetters() {
        this.doTransform && (this.updateTransform(), this.doTransform = !1);
      }
      align(t, e, i, s = !0) {
        let o, r, a, n;
        let l = {},
          h = this.renderer,
          d = h.alignedObjects,
          c = !!t;
        t ? (this.alignOptions = t, this.alignByTranslate = e, this.alignTo = i) : (t = this.alignOptions || {}, e = this.alignByTranslate, i = this.alignTo);
        let p = !i || w(i) ? i || "renderer" : void 0;
        p && (c && L(d, this), i = void 0);
        let u = T(i, h[p], h),
          g = t.align,
          f = t.verticalAlign;
        return o = (u.x || 0) + (t.x || 0), r = (u.y || 0) + (t.y || 0), "right" === g ? a = 1 : "center" === g && (a = 2), a && (o += ((u.width || 0) - (t.width || 0)) / a), l[e ? "translateX" : "x"] = Math.round(o), "bottom" === f ? n = 1 : "middle" === f && (n = 2), n && (r += ((u.height || 0) - (t.height || 0)) / n), l[e ? "translateY" : "y"] = Math.round(r), s && (this[this.placed ? "animate" : "attr"](l), this.placed = !0), this.alignAttr = l, this;
      }
      alignSetter(t) {
        let e = {
          left: "start",
          center: "middle",
          right: "end"
        };
        e[t] && (this.alignValue = t, this.element.setAttribute("text-anchor", e[t]));
      }
      animate(t, e, i) {
        let s = r(T(e, this.renderer.globalAnimation, !0)),
          a = s.defer;
        return l.hidden && (s.duration = 0), 0 !== s.duration ? (i && (s.complete = i), O(() => {
          this.element && o(this, t, s);
        }, a)) : (this.attr(t, void 0, i || s.complete), A(t, function (t, e) {
          s.step && s.step.call(this, t, {
            prop: e,
            pos: 1,
            elem: this
          });
        }, this)), this;
      }
      applyTextOutline(t) {
        let e = this.element;
        -1 !== t.indexOf("contrast") && (t = t.replace(/contrast/g, this.renderer.getContrast(e.style.fill)));
        let s = t.split(" "),
          o = s[s.length - 1],
          r = s[0];
        if (r && "none" !== r && i.svg) {
          this.fakeTS = !0, r = r.replace(/(^[\d\.]+)(.*?)$/g, function (t, e, i) {
            return 2 * Number(e) + i;
          }), this.removeTextOutline();
          let t = l.createElementNS(d, "tspan");
          u(t, {
            class: "highcharts-text-outline",
            fill: o,
            stroke: o,
            "stroke-width": r,
            "stroke-linejoin": "round"
          });
          let i = e.querySelector("textPath") || e;
          [].forEach.call(i.childNodes, e => {
            let i = e.cloneNode(!0);
            i.removeAttribute && ["fill", "stroke", "stroke-width", "stroke"].forEach(t => i.removeAttribute(t)), t.appendChild(i);
          });
          let s = 0;
          [].forEach.call(i.querySelectorAll("text tspan"), t => {
            s += Number(t.getAttribute("dy"));
          });
          let a = l.createElementNS(d, "tspan");
          a.textContent = "​", u(a, {
            x: Number(e.getAttribute("x")),
            dy: -s
          }), t.appendChild(a), i.insertBefore(t, i.firstChild);
        }
      }
      attr(t, e, i, s) {
        let {
            element: o
          } = this,
          r = E.symbolCustomAttribs,
          n,
          l,
          h = this,
          d;
        return "string" == typeof t && void 0 !== e && (n = t, (t = {})[n] = e), "string" == typeof t ? h = (this[t + "Getter"] || this._defaultGetter).call(this, t, o) : (A(t, function (e, i) {
          d = !1, s || a(this, i), this.symbolName && -1 !== r.indexOf(i) && (l || (this.symbolAttr(t), l = !0), d = !0), this.rotation && ("x" === i || "y" === i) && (this.doTransform = !0), d || (this[i + "Setter"] || this._defaultSetter).call(this, e, i, o);
        }, this), this.afterSetters()), i && i.call(this), h;
      }
      clip(t) {
        if (t && !t.clipPath) {
          let e = D() + "-",
            i = this.renderer.createElement("clipPath").attr({
              id: e
            }).add(this.renderer.defs);
          b(t, {
            clipPath: i,
            id: e,
            count: 0
          }), t.add(i);
        }
        return this.attr("clip-path", t ? `url(${this.renderer.url}#${t.id})` : "none");
      }
      crisp(t, e) {
        e = Math.round(e || t.strokeWidth || 0);
        let i = t.x || this.x || 0,
          s = t.y || this.y || 0,
          o = (t.width || this.width || 0) + i,
          r = (t.height || this.height || 0) + s,
          a = f(i, e),
          n = f(s, e);
        return b(t, {
          x: a,
          y: n,
          width: f(o, e) - a,
          height: f(r, e) - n
        }), x(t.strokeWidth) && (t.strokeWidth = e), t;
      }
      complexColor(t, i, s) {
        let o = this.renderer,
          r,
          a,
          n,
          l,
          h,
          d,
          c,
          p,
          u,
          g,
          f = [],
          m;
        v(this.renderer, "complexColor", {
          args: arguments
        }, function () {
          if (t.radialGradient ? a = "radialGradient" : t.linearGradient && (a = "linearGradient"), a) {
            if (n = t[a], h = o.gradients, d = t.stops, u = s.radialReference, M(n) && (t[a] = n = {
              x1: n[0],
              y1: n[1],
              x2: n[2],
              y2: n[3],
              gradientUnits: "userSpaceOnUse"
            }), "radialGradient" === a && u && !x(n.gradientUnits) && (l = n, n = k(n, o.getRadialAttr(u, l), {
              gradientUnits: "userSpaceOnUse"
            })), A(n, function (t, e) {
              "id" !== e && f.push(e, t);
            }), A(d, function (t) {
              f.push(t);
            }), h[f = f.join(",")]) g = h[f].attr("id");else {
              n.id = g = D();
              let t = h[f] = o.createElement(a).attr(n).add(o.defs);
              t.radAttr = l, t.stops = [], d.forEach(function (i) {
                0 === i[1].indexOf("rgba") ? (c = (r = e.parse(i[1])).get("rgb"), p = r.get("a")) : (c = i[1], p = 1);
                let s = o.createElement("stop").attr({
                  offset: i[0],
                  "stop-color": c,
                  "stop-opacity": p
                }).add(t);
                t.stops.push(s);
              });
            }
            m = "url(" + o.url + "#" + g + ")", s.setAttribute(i, m), s.gradient = f, t.toString = function () {
              return m;
            };
          }
        });
      }
      css(t) {
        let e = this.styles,
          i = {},
          s = this.element,
          o,
          r = !e;
        if (e && A(t, function (t, s) {
          e && e[s] !== t && (i[s] = t, r = !0);
        }), r) {
          e && (t = b(e, i)), null === t.width || "auto" === t.width ? delete this.textWidth : "text" === s.nodeName.toLowerCase() && t.width && (o = this.textWidth = P(t.width)), b(this.styles, t), o && !h && this.renderer.forExport && delete t.width;
          let r = k(t);
          s.namespaceURI === this.SVG_NS && (["textOutline", "textOverflow", "width"].forEach(t => r && delete r[t]), r.color && (r.fill = r.color)), m(s, r);
        }
        return this.added && ("text" === this.element.nodeName && this.renderer.buildText(this), t.textOutline && this.applyTextOutline(t.textOutline)), this;
      }
      dashstyleSetter(t) {
        let e,
          i = this["stroke-width"];
        if ("inherit" === i && (i = 1), t = t && t.toLowerCase()) {
          let s = t.replace("shortdashdotdot", "3,1,1,1,1,1,").replace("shortdashdot", "3,1,1,1").replace("shortdot", "1,1,").replace("shortdash", "3,1,").replace("longdash", "8,3,").replace(/dot/g, "1,3,").replace("dash", "4,3,").replace(/,$/, "").split(",");
          for (e = s.length; e--;) s[e] = "" + P(s[e]) * T(i, NaN);
          t = s.join(",").replace(/NaN/g, "none"), this.element.setAttribute("stroke-dasharray", t);
        }
      }
      destroy() {
        let t = this,
          e = t.element || {},
          i = t.renderer,
          s = e.ownerSVGElement,
          o = "SPAN" === e.nodeName && t.parentGroup || void 0,
          r,
          n;
        if (e.onclick = e.onmouseout = e.onmouseover = e.onmousemove = e.point = null, a(t), t.clipPath && s) {
          let e = t.clipPath;
          [].forEach.call(s.querySelectorAll("[clip-path],[CLIP-PATH]"), function (t) {
            t.getAttribute("clip-path").indexOf(e.element.id) > -1 && t.removeAttribute("clip-path");
          }), t.clipPath = e.destroy();
        }
        if (t.connector = t.connector?.destroy(), t.stops) {
          for (n = 0; n < t.stops.length; n++) t.stops[n].destroy();
          t.stops.length = 0, t.stops = void 0;
        }
        for (t.safeRemoveChild(e); o && o.div && 0 === o.div.childNodes.length;) r = o.parentGroup, t.safeRemoveChild(o.div), delete o.div, o = r;
        t.alignOptions && y(i.alignedObjects, t), A(t, function (e, i) {
          t[i] && t[i].parentGroup === t && t[i].destroy && t[i].destroy(), delete t[i];
        });
      }
      dSetter(t, e, i) {
        M(t) && ("string" == typeof t[0] && (t = this.renderer.pathToSegments(t)), this.pathArray = t, t = t.reduce((t, e, i) => e && e.join ? (i ? t + " " : "") + e.join(" ") : (e || "").toString(), "")), /(NaN| {2}|^$)/.test(t) && (t = "M 0 0"), this[e] !== t && (i.setAttribute(e, t), this[e] = t);
      }
      fillSetter(t, e, i) {
        "string" == typeof t ? i.setAttribute(e, t) : t && this.complexColor(t, e, i);
      }
      hrefSetter(t, e, i) {
        i.setAttributeNS("http://www.w3.org/1999/xlink", e, t);
      }
      getBBox(t, e) {
        let i, s, o, r;
        let {
            alignValue: a,
            element: n,
            renderer: l,
            styles: h,
            textStr: d
          } = this,
          {
            cache: c,
            cacheKeys: p
          } = l,
          u = n.namespaceURI === this.SVG_NS,
          g = T(e, this.rotation, 0),
          f = l.styledMode ? n && E.prototype.getStyle.call(n, "font-size") : h.fontSize;
        if (x(d) && (-1 === (r = d.toString()).indexOf("<") && (r = r.replace(/\d/g, "0")), r += ["", l.rootFontSize, f, g, this.textWidth, a, h.textOverflow, h.fontWeight].join(",")), r && !t && (i = c[r]), !i || i.polygon) {
          if (u || l.forExport) {
            try {
              o = this.fakeTS && function (t) {
                let e = n.querySelector(".highcharts-text-outline");
                e && m(e, {
                  display: t
                });
              }, C(o) && o("none"), i = n.getBBox ? b({}, n.getBBox()) : {
                width: n.offsetWidth,
                height: n.offsetHeight,
                x: 0,
                y: 0
              }, C(o) && o("");
            } catch (t) {}
            (!i || i.width < 0) && (i = {
              x: 0,
              y: 0,
              width: 0,
              height: 0
            });
          } else i = this.htmlGetBBox();
          s = i.height, u && (i.height = s = {
            "11px,17": 14,
            "13px,20": 16
          }[`${f || ""},${Math.round(s)}`] || s), g && (i = this.getRotatedBox(i, g));
          let t = {
            bBox: i
          };
          v(this, "afterGetBBox", t), i = t.bBox;
        }
        if (r && ("" === d || i.height > 0)) {
          for (; p.length > 250;) delete c[p.shift()];
          c[r] || p.push(r), c[r] = i;
        }
        return i;
      }
      getRotatedBox(t, e) {
        let {
            x: i,
            y: s,
            width: o,
            height: r
          } = t,
          {
            alignValue: a,
            translateY: l,
            rotationOriginX: h = 0,
            rotationOriginY: d = 0
          } = this,
          c = {
            right: 1,
            center: .5
          }[a || 0] || 0,
          p = Number(this.element.getAttribute("y") || 0) - (l ? 0 : s),
          u = e * n,
          g = (e - 90) * n,
          f = Math.cos(u),
          m = Math.sin(u),
          x = o * f,
          y = o * m,
          b = Math.cos(g),
          v = Math.sin(g),
          [[M, C], [S, w]] = [h, d].map(t => [t - t * f, t * m]),
          k = i + c * (o - x) + M + w + p * b,
          A = k + x,
          T = A - r * b,
          P = T - x,
          L = s + p - c * y - C + S + p * v,
          j = L + y,
          O = j - r * v,
          D = O - y,
          E = Math.min(k, A, T, P),
          I = Math.min(L, j, O, D),
          B = Math.max(k, A, T, P) - E,
          z = Math.max(L, j, O, D) - I;
        return {
          x: E,
          y: I,
          width: B,
          height: z,
          polygon: [[k, L], [A, j], [T, O], [P, D]]
        };
      }
      getStyle(t) {
        return c.getComputedStyle(this.element || this, "").getPropertyValue(t);
      }
      hasClass(t) {
        return -1 !== ("" + this.attr("class")).split(" ").indexOf(t);
      }
      hide() {
        return this.attr({
          visibility: "hidden"
        });
      }
      htmlGetBBox() {
        return {
          height: 0,
          width: 0,
          x: 0,
          y: 0
        };
      }
      constructor(t, e) {
        this.onEvents = {}, this.opacity = 1, this.SVG_NS = d, this.element = "span" === e || "body" === e ? g(e) : l.createElementNS(this.SVG_NS, e), this.renderer = t, this.styles = {}, v(this, "afterInit");
      }
      on(t, e) {
        let {
          onEvents: i
        } = this;
        return i[t] && i[t](), i[t] = p(this.element, t, e), this;
      }
      opacitySetter(t, e, i) {
        let s = Number(Number(t).toFixed(3));
        this.opacity = s, i.setAttribute(e, s);
      }
      reAlign() {
        this.alignOptions?.width && "left" !== this.alignOptions.align && (this.alignOptions.width = this.getBBox().width, this.placed = !1, this.align());
      }
      removeClass(t) {
        return this.attr("class", ("" + this.attr("class")).replace(w(t) ? RegExp(`(^| )${t}( |$)`) : t, " ").replace(/ +/g, " ").trim());
      }
      removeTextOutline() {
        let t = this.element.querySelector("tspan.highcharts-text-outline");
        t && this.safeRemoveChild(t);
      }
      safeRemoveChild(t) {
        let e = t.parentNode;
        e && e.removeChild(t);
      }
      setRadialReference(t) {
        let e = this.element.gradient && this.renderer.gradients[this.element.gradient];
        return this.element.radialReference = t, e && e.radAttr && e.animate(this.renderer.getRadialAttr(t, e.radAttr)), this;
      }
      shadow(t) {
        let {
            renderer: e
          } = this,
          i = k(this.parentGroup?.rotation === 90 ? {
            offsetX: -1,
            offsetY: -1
          } : {}, S(t) ? t : {}),
          s = e.shadowDefinition(i);
        return this.attr({
          filter: t ? `url(${e.url}#${s})` : "none"
        });
      }
      show(t = !0) {
        return this.attr({
          visibility: t ? "inherit" : "visible"
        });
      }
      "stroke-widthSetter"(t, e, i) {
        this[e] = t, i.setAttribute(e, t);
      }
      strokeWidth() {
        if (!this.renderer.styledMode) return this["stroke-width"] || 0;
        let t = this.getStyle("stroke-width"),
          e = 0,
          i;
        return /px$/.test(t) ? e = P(t) : "" !== t && (u(i = l.createElementNS(d, "rect"), {
          width: t,
          "stroke-width": 0
        }), this.element.parentNode.appendChild(i), e = i.getBBox().width, i.parentNode.removeChild(i)), e;
      }
      symbolAttr(t) {
        let e = this;
        E.symbolCustomAttribs.forEach(function (i) {
          e[i] = T(t[i], e[i]);
        }), e.attr({
          d: e.renderer.symbols[e.symbolName](e.x, e.y, e.width, e.height, e)
        });
      }
      textSetter(t) {
        t !== this.textStr && (delete this.textPxLength, this.textStr = t, this.added && this.renderer.buildText(this), this.reAlign());
      }
      titleSetter(t) {
        let e = this.element,
          i = e.getElementsByTagName("title")[0] || l.createElementNS(this.SVG_NS, "title");
        e.insertBefore ? e.insertBefore(i, e.firstChild) : e.appendChild(i), i.textContent = j(T(t, ""), [/<[^>]*>/g, ""]).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      }
      toFront() {
        let t = this.element;
        return t.parentNode.appendChild(t), this;
      }
      translate(t, e) {
        return this.attr({
          translateX: t,
          translateY: e
        });
      }
      updateTransform(t = "transform") {
        let {
            element: e,
            matrix: i,
            rotation: s = 0,
            rotationOriginX: o,
            rotationOriginY: r,
            scaleX: a,
            scaleY: n,
            translateX: l = 0,
            translateY: h = 0
          } = this,
          d = ["translate(" + l + "," + h + ")"];
        x(i) && d.push("matrix(" + i.join(",") + ")"), s && (d.push("rotate(" + s + " " + T(o, e.getAttribute("x"), 0) + " " + T(r, e.getAttribute("y") || 0) + ")"), this.text?.element.tagName === "SPAN" && this.text.attr({
          rotation: s,
          rotationOriginX: (o || 0) - this.padding,
          rotationOriginY: (r || 0) - this.padding
        })), (x(a) || x(n)) && d.push("scale(" + T(a, 1) + " " + T(n, 1) + ")"), d.length && !(this.text || this).textPath && e.setAttribute(t, d.join(" "));
      }
      visibilitySetter(t, e, i) {
        "inherit" === t ? i.removeAttribute(e) : this[e] !== t && i.setAttribute(e, t), this[e] = t;
      }
      xGetter(t) {
        return "circle" === this.element.nodeName && ("x" === t ? t = "cx" : "y" === t && (t = "cy")), this._defaultGetter(t);
      }
      zIndexSetter(t, e) {
        let i = this.renderer,
          s = this.parentGroup,
          o = (s || i).element || i.box,
          r = this.element,
          a = o === i.box,
          n,
          l,
          h,
          d = !1,
          c,
          p = this.added,
          u;
        if (x(t) ? (r.setAttribute("data-z-index", t), t = +t, this[e] === t && (p = !1)) : x(this[e]) && r.removeAttribute("data-z-index"), this[e] = t, p) {
          for ((t = this.zIndex) && s && (s.handleZ = !0), u = (n = o.childNodes).length - 1; u >= 0 && !d; u--) c = !x(h = (l = n[u]).getAttribute("data-z-index")), l !== r && (t < 0 && c && !a && !u ? (o.insertBefore(r, n[u]), d = !0) : (P(h) <= t || c && (!x(t) || t >= 0)) && (o.insertBefore(r, n[u + 1]), d = !0));
          d || (o.insertBefore(r, n[a ? 3 : 0]), d = !0);
        }
        return d;
      }
    }
    return E.symbolCustomAttribs = ["anchorX", "anchorY", "clockwise", "end", "height", "innerR", "r", "start", "width", "x", "y"], E.prototype.strokeSetter = E.prototype.fillSetter, E.prototype.yGetter = E.prototype.xGetter, E.prototype.matrixSetter = E.prototype.rotationOriginXSetter = E.prototype.rotationOriginYSetter = E.prototype.rotationSetter = E.prototype.scaleXSetter = E.prototype.scaleYSetter = E.prototype.translateXSetter = E.prototype.translateYSetter = E.prototype.verticalAlignSetter = function (t, e) {
      this[e] = t, this.doTransform = !0;
    }, E;
  }), i(e, "Core/Renderer/SVG/SVGLabel.js", [e["Core/Renderer/SVG/SVGElement.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      defined: i,
      extend: s,
      isNumber: o,
      merge: r,
      pick: a,
      removeEvent: n
    } = e;
    class l extends t {
      constructor(t, e, i, s, o, r, a, n, h, d) {
        let c;
        super(t, "g"), this.paddingLeftSetter = this.paddingSetter, this.paddingRightSetter = this.paddingSetter, this.doUpdate = !1, this.textStr = e, this.x = i, this.y = s, this.anchorX = r, this.anchorY = a, this.baseline = h, this.className = d, this.addClass("button" === d ? "highcharts-no-tooltip" : "highcharts-label"), d && this.addClass("highcharts-" + d), this.text = t.text(void 0, 0, 0, n).attr({
          zIndex: 1
        }), "string" == typeof o && ((c = /^url\((.*?)\)$/.test(o)) || this.renderer.symbols[o]) && (this.symbolKey = o), this.bBox = l.emptyBBox, this.padding = 3, this.baselineOffset = 0, this.needsBox = t.styledMode || c, this.deferredAttr = {}, this.alignFactor = 0;
      }
      alignSetter(t) {
        let e = {
          left: 0,
          center: .5,
          right: 1
        }[t];
        e !== this.alignFactor && (this.alignFactor = e, this.bBox && o(this.xSetting) && this.attr({
          x: this.xSetting
        }));
      }
      anchorXSetter(t, e) {
        this.anchorX = t, this.boxAttr(e, Math.round(t) - this.getCrispAdjust() - this.xSetting);
      }
      anchorYSetter(t, e) {
        this.anchorY = t, this.boxAttr(e, t - this.ySetting);
      }
      boxAttr(t, e) {
        this.box ? this.box.attr(t, e) : this.deferredAttr[t] = e;
      }
      css(e) {
        if (e) {
          let t = {};
          e = r(e), l.textProps.forEach(i => {
            void 0 !== e[i] && (t[i] = e[i], delete e[i]);
          }), this.text.css(t), "fontSize" in t || "fontWeight" in t ? this.updateTextPadding() : ("width" in t || "textOverflow" in t) && this.updateBoxSize();
        }
        return t.prototype.css.call(this, e);
      }
      destroy() {
        n(this.element, "mouseenter"), n(this.element, "mouseleave"), this.text && this.text.destroy(), this.box && (this.box = this.box.destroy()), t.prototype.destroy.call(this);
      }
      fillSetter(t, e) {
        t && (this.needsBox = !0), this.fill = t, this.boxAttr(e, t);
      }
      getBBox(t, e) {
        this.textStr && 0 === this.bBox.width && 0 === this.bBox.height && this.updateBoxSize();
        let {
            padding: i,
            height: s = 0,
            translateX: o = 0,
            translateY: r = 0,
            width: n = 0
          } = this,
          l = a(this.paddingLeft, i),
          h = e ?? (this.rotation || 0),
          d = {
            width: n,
            height: s,
            x: o + this.bBox.x - l,
            y: r + this.bBox.y - i + this.baselineOffset
          };
        return h && (d = this.getRotatedBox(d, h)), d;
      }
      getCrispAdjust() {
        return (this.renderer.styledMode && this.box ? this.box.strokeWidth() : this["stroke-width"] ? parseInt(this["stroke-width"], 10) : 0) % 2 / 2;
      }
      heightSetter(t) {
        this.heightSetting = t, this.doUpdate = !0;
      }
      afterSetters() {
        super.afterSetters(), this.doUpdate && (this.updateBoxSize(), this.doUpdate = !1);
      }
      onAdd() {
        this.text.add(this), this.attr({
          text: a(this.textStr, ""),
          x: this.x || 0,
          y: this.y || 0
        }), this.box && i(this.anchorX) && this.attr({
          anchorX: this.anchorX,
          anchorY: this.anchorY
        });
      }
      paddingSetter(t, e) {
        o(t) ? t !== this[e] && (this[e] = t, this.updateTextPadding()) : this[e] = void 0;
      }
      rSetter(t, e) {
        this.boxAttr(e, t);
      }
      strokeSetter(t, e) {
        this.stroke = t, this.boxAttr(e, t);
      }
      "stroke-widthSetter"(t, e) {
        t && (this.needsBox = !0), this["stroke-width"] = t, this.boxAttr(e, t);
      }
      "text-alignSetter"(t) {
        this.textAlign = t;
      }
      textSetter(t) {
        void 0 !== t && this.text.attr({
          text: t
        }), this.updateTextPadding(), this.reAlign();
      }
      updateBoxSize() {
        let t;
        let e = this.text,
          r = {},
          a = this.padding,
          n = this.bBox = (!o(this.widthSetting) || !o(this.heightSetting) || this.textAlign) && i(e.textStr) ? e.getBBox(void 0, 0) : l.emptyBBox;
        this.width = this.getPaddedWidth(), this.height = (this.heightSetting || n.height || 0) + 2 * a;
        let h = this.renderer.fontMetrics(e);
        if (this.baselineOffset = a + Math.min((this.text.firstLineMetrics || h).b, n.height || 1 / 0), this.heightSetting && (this.baselineOffset += (this.heightSetting - h.h) / 2), this.needsBox && !e.textPath) {
          if (!this.box) {
            let t = this.box = this.symbolKey ? this.renderer.symbol(this.symbolKey) : this.renderer.rect();
            t.addClass(("button" === this.className ? "" : "highcharts-label-box") + (this.className ? " highcharts-" + this.className + "-box" : "")), t.add(this);
          }
          t = this.getCrispAdjust(), r.x = t, r.y = (this.baseline ? -this.baselineOffset : 0) + t, r.width = Math.round(this.width), r.height = Math.round(this.height), this.box.attr(s(r, this.deferredAttr)), this.deferredAttr = {};
        }
      }
      updateTextPadding() {
        let t = this.text;
        if (!t.textPath) {
          this.updateBoxSize();
          let e = this.baseline ? 0 : this.baselineOffset,
            s = a(this.paddingLeft, this.padding);
          i(this.widthSetting) && this.bBox && ("center" === this.textAlign || "right" === this.textAlign) && (s += {
            center: .5,
            right: 1
          }[this.textAlign] * (this.widthSetting - this.bBox.width)), (s !== t.x || e !== t.y) && (t.attr("x", s), t.hasBoxWidthChanged && (this.bBox = t.getBBox(!0)), void 0 !== e && t.attr("y", e)), t.x = s, t.y = e;
        }
      }
      widthSetter(t) {
        this.widthSetting = o(t) ? t : void 0, this.doUpdate = !0;
      }
      getPaddedWidth() {
        let t = this.padding,
          e = a(this.paddingLeft, t),
          i = a(this.paddingRight, t);
        return (this.widthSetting || this.bBox.width || 0) + e + i;
      }
      xSetter(t) {
        this.x = t, this.alignFactor && (t -= this.alignFactor * this.getPaddedWidth(), this["forceAnimate:x"] = !0), this.xSetting = Math.round(t), this.attr("translateX", this.xSetting);
      }
      ySetter(t) {
        this.ySetting = this.y = Math.round(t), this.attr("translateY", this.ySetting);
      }
    }
    return l.emptyBBox = {
      width: 0,
      height: 0,
      x: 0,
      y: 0
    }, l.textProps = ["color", "direction", "fontFamily", "fontSize", "fontStyle", "fontWeight", "lineHeight", "textAlign", "textDecoration", "textOutline", "textOverflow", "whiteSpace", "width"], l;
  }), i(e, "Core/Renderer/SVG/Symbols.js", [e["Core/Utilities.js"]], function (t) {
    let {
      defined: e,
      isNumber: i,
      pick: s
    } = t;
    function o(t, i, o, r, a) {
      let n = [];
      if (a) {
        let l = a.start || 0,
          h = s(a.r, o),
          d = s(a.r, r || o),
          c = 2e-4 / (a.borderRadius ? 1 : Math.max(h, 1)),
          p = Math.abs((a.end || 0) - l - 2 * Math.PI) < c,
          u = (a.end || 0) - (p ? c : 0),
          g = a.innerR,
          f = s(a.open, p),
          m = Math.cos(l),
          x = Math.sin(l),
          y = Math.cos(u),
          b = Math.sin(u),
          v = s(a.longArc, u - l - Math.PI < c ? 0 : 1),
          M = ["A", h, d, 0, v, s(a.clockwise, 1), t + h * y, i + d * b];
        M.params = {
          start: l,
          end: u,
          cx: t,
          cy: i
        }, n.push(["M", t + h * m, i + d * x], M), e(g) && ((M = ["A", g, g, 0, v, e(a.clockwise) ? 1 - a.clockwise : 0, t + g * m, i + g * x]).params = {
          start: u,
          end: l,
          cx: t,
          cy: i
        }, n.push(f ? ["M", t + g * y, i + g * b] : ["L", t + g * y, i + g * b], M)), f || n.push(["Z"]);
      }
      return n;
    }
    function r(t, e, i, s, o) {
      return o && o.r ? a(t, e, i, s, o) : [["M", t, e], ["L", t + i, e], ["L", t + i, e + s], ["L", t, e + s], ["Z"]];
    }
    function a(t, e, i, s, o) {
      let r = o?.r || 0;
      return [["M", t + r, e], ["L", t + i - r, e], ["A", r, r, 0, 0, 1, t + i, e + r], ["L", t + i, e + s - r], ["A", r, r, 0, 0, 1, t + i - r, e + s], ["L", t + r, e + s], ["A", r, r, 0, 0, 1, t, e + s - r], ["L", t, e + r], ["A", r, r, 0, 0, 1, t + r, e], ["Z"]];
    }
    return {
      arc: o,
      callout: function (t, e, s, o, r) {
        let n = Math.min(r && r.r || 0, s, o),
          l = n + 6,
          h = r && r.anchorX,
          d = r && r.anchorY || 0,
          c = a(t, e, s, o, {
            r: n
          });
        if (!i(h) || h < s && h > 0 && d < o && d > 0) return c;
        if (t + h > s - l) {
          if (d > e + l && d < e + o - l) c.splice(3, 1, ["L", t + s, d - 6], ["L", t + s + 6, d], ["L", t + s, d + 6], ["L", t + s, e + o - n]);else if (h < s) {
            let i = d < e + l,
              r = i ? e : e + o;
            c.splice(i ? 2 : 5, 0, ["L", h, d], ["L", t + s - n, r]);
          } else c.splice(3, 1, ["L", t + s, o / 2], ["L", h, d], ["L", t + s, o / 2], ["L", t + s, e + o - n]);
        } else if (t + h < l) {
          if (d > e + l && d < e + o - l) c.splice(7, 1, ["L", t, d + 6], ["L", t - 6, d], ["L", t, d - 6], ["L", t, e + n]);else if (h > 0) {
            let i = d < e + l,
              s = i ? e : e + o;
            c.splice(i ? 1 : 6, 0, ["L", h, d], ["L", t + n, s]);
          } else c.splice(7, 1, ["L", t, o / 2], ["L", h, d], ["L", t, o / 2], ["L", t, e + n]);
        } else d > o && h < s - l ? c.splice(5, 1, ["L", h + 6, e + o], ["L", h, e + o + 6], ["L", h - 6, e + o], ["L", t + n, e + o]) : d < 0 && h > l && c.splice(1, 1, ["L", h - 6, e], ["L", h, e - 6], ["L", h + 6, e], ["L", s - n, e]);
        return c;
      },
      circle: function (t, e, i, s) {
        return o(t + i / 2, e + s / 2, i / 2, s / 2, {
          start: .5 * Math.PI,
          end: 2.5 * Math.PI,
          open: !1
        });
      },
      diamond: function (t, e, i, s) {
        return [["M", t + i / 2, e], ["L", t + i, e + s / 2], ["L", t + i / 2, e + s], ["L", t, e + s / 2], ["Z"]];
      },
      rect: r,
      roundedRect: a,
      square: r,
      triangle: function (t, e, i, s) {
        return [["M", t + i / 2, e], ["L", t + i, e + s], ["L", t, e + s], ["Z"]];
      },
      "triangle-down": function (t, e, i, s) {
        return [["M", t, e], ["L", t + i, e], ["L", t + i / 2, e + s], ["Z"]];
      }
    };
  }), i(e, "Core/Renderer/SVG/TextBuilder.js", [e["Core/Renderer/HTML/AST.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        doc: s,
        SVG_NS: o,
        win: r
      } = e,
      {
        attr: a,
        extend: n,
        fireEvent: l,
        isString: h,
        objectEach: d,
        pick: c
      } = i;
    return class {
      constructor(t) {
        let e = t.styles;
        this.renderer = t.renderer, this.svgElement = t, this.width = t.textWidth, this.textLineHeight = e && e.lineHeight, this.textOutline = e && e.textOutline, this.ellipsis = !!(e && "ellipsis" === e.textOverflow), this.noWrap = !!(e && "nowrap" === e.whiteSpace);
      }
      buildSVG() {
        let e = this.svgElement,
          i = e.element,
          o = e.renderer,
          r = c(e.textStr, "").toString(),
          a = -1 !== r.indexOf("<"),
          n = i.childNodes,
          l = !e.added && o.box,
          d = [r, this.ellipsis, this.noWrap, this.textLineHeight, this.textOutline, e.getStyle("font-size"), this.width].join(",");
        if (d !== e.textCache) {
          e.textCache = d, delete e.actualWidth;
          for (let t = n.length; t--;) i.removeChild(n[t]);
          if (a || this.ellipsis || this.width || e.textPath || -1 !== r.indexOf(" ") && (!this.noWrap || /<br.*?>/g.test(r))) {
            if ("" !== r) {
              l && l.appendChild(i);
              let s = new t(r);
              this.modifyTree(s.nodes), s.addToDOM(i), this.modifyDOM(), this.ellipsis && -1 !== (i.textContent || "").indexOf("…") && e.attr("title", this.unescapeEntities(e.textStr || "", ["&lt;", "&gt;"])), l && l.removeChild(i);
            }
          } else i.appendChild(s.createTextNode(this.unescapeEntities(r)));
          h(this.textOutline) && e.applyTextOutline && e.applyTextOutline(this.textOutline);
        }
      }
      modifyDOM() {
        let t;
        let e = this.svgElement,
          i = a(e.element, "x");
        for (e.firstLineMetrics = void 0; t = e.element.firstChild;) if (/^[\s\u200B]*$/.test(t.textContent || " ")) e.element.removeChild(t);else break;
        [].forEach.call(e.element.querySelectorAll("tspan.highcharts-br"), (t, s) => {
          t.nextSibling && t.previousSibling && (0 === s && 1 === t.previousSibling.nodeType && (e.firstLineMetrics = e.renderer.fontMetrics(t.previousSibling)), a(t, {
            dy: this.getLineHeight(t.nextSibling),
            x: i
          }));
        });
        let n = this.width || 0;
        if (!n) return;
        let l = (t, r) => {
            let l = t.textContent || "",
              h = l.replace(/([^\^])-/g, "$1- ").split(" "),
              d = !this.noWrap && (h.length > 1 || e.element.childNodes.length > 1),
              c = this.getLineHeight(r),
              p = 0,
              u = e.actualWidth;
            if (this.ellipsis) l && this.truncate(t, l, void 0, 0, Math.max(0, n - .8 * c), (t, e) => t.substring(0, e) + "…");else if (d) {
              let l = [],
                d = [];
              for (; r.firstChild && r.firstChild !== t;) d.push(r.firstChild), r.removeChild(r.firstChild);
              for (; h.length;) h.length && !this.noWrap && p > 0 && (l.push(t.textContent || ""), t.textContent = h.join(" ").replace(/- /g, "-")), this.truncate(t, void 0, h, 0 === p && u || 0, n, (t, e) => h.slice(0, e).join(" ").replace(/- /g, "-")), u = e.actualWidth, p++;
              d.forEach(e => {
                r.insertBefore(e, t);
              }), l.forEach(e => {
                r.insertBefore(s.createTextNode(e), t);
                let n = s.createElementNS(o, "tspan");
                n.textContent = "​", a(n, {
                  dy: c,
                  x: i
                }), r.insertBefore(n, t);
              });
            }
          },
          h = t => {
            [].slice.call(t.childNodes).forEach(i => {
              i.nodeType === r.Node.TEXT_NODE ? l(i, t) : (-1 !== i.className.baseVal.indexOf("highcharts-br") && (e.actualWidth = 0), h(i));
            });
          };
        h(e.element);
      }
      getLineHeight(t) {
        let e = t.nodeType === r.Node.TEXT_NODE ? t.parentElement : t;
        return this.textLineHeight ? parseInt(this.textLineHeight.toString(), 10) : this.renderer.fontMetrics(e || this.svgElement.element).h;
      }
      modifyTree(t) {
        let e = (i, s) => {
          let {
              attributes: o = {},
              children: r,
              style: a = {},
              tagName: l
            } = i,
            h = this.renderer.styledMode;
          if ("b" === l || "strong" === l ? h ? o.class = "highcharts-strong" : a.fontWeight = "bold" : ("i" === l || "em" === l) && (h ? o.class = "highcharts-emphasized" : a.fontStyle = "italic"), a && a.color && (a.fill = a.color), "br" === l) {
            o.class = "highcharts-br", i.textContent = "​";
            let e = t[s + 1];
            e && e.textContent && (e.textContent = e.textContent.replace(/^ +/gm, ""));
          } else "a" === l && r && r.some(t => "#text" === t.tagName) && (i.children = [{
            children: r,
            tagName: "tspan"
          }]);
          "#text" !== l && "a" !== l && (i.tagName = "tspan"), n(i, {
            attributes: o,
            style: a
          }), r && r.filter(t => "#text" !== t.tagName).forEach(e);
        };
        t.forEach(e), l(this.svgElement, "afterModifyTree", {
          nodes: t
        });
      }
      truncate(t, e, i, s, o, r) {
        let a, n;
        let l = this.svgElement,
          {
            rotation: h
          } = l,
          d = [],
          c = i ? 1 : 0,
          p = (e || i || "").length,
          u = p,
          g = function (e, o) {
            let r = o || e,
              a = t.parentNode;
            if (a && void 0 === d[r] && a.getSubStringLength) try {
              d[r] = s + a.getSubStringLength(0, i ? r + 1 : r);
            } catch (t) {}
            return d[r];
          };
        if (l.rotation = 0, s + (n = g(t.textContent.length)) > o) {
          for (; c <= p;) u = Math.ceil((c + p) / 2), i && (a = r(i, u)), n = g(u, a && a.length - 1), c === p ? c = p + 1 : n > o ? p = u - 1 : c = u;
          0 === p ? t.textContent = "" : e && p === e.length - 1 || (t.textContent = a || r(e || i, u));
        }
        i && i.splice(0, u), l.actualWidth = n, l.rotation = h;
      }
      unescapeEntities(t, e) {
        return d(this.renderer.escapes, function (i, s) {
          e && -1 !== e.indexOf(i) || (t = t.toString().replace(RegExp(i, "g"), s));
        }), t;
      }
    };
  }), i(e, "Core/Renderer/SVG/SVGRenderer.js", [e["Core/Renderer/HTML/AST.js"], e["Core/Defaults.js"], e["Core/Color/Color.js"], e["Core/Globals.js"], e["Core/Renderer/RendererRegistry.js"], e["Core/Renderer/SVG/SVGElement.js"], e["Core/Renderer/SVG/SVGLabel.js"], e["Core/Renderer/SVG/Symbols.js"], e["Core/Renderer/SVG/TextBuilder.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n, l, h) {
    let d;
    let {
        defaultOptions: c
      } = e,
      {
        charts: p,
        deg2rad: u,
        doc: g,
        isFirefox: f,
        isMS: m,
        isWebKit: x,
        noop: y,
        SVG_NS: b,
        symbolSizes: v,
        win: M
      } = s,
      {
        addEvent: C,
        attr: S,
        createElement: w,
        crisp: k,
        css: A,
        defined: T,
        destroyObjectProperties: P,
        extend: L,
        isArray: j,
        isNumber: O,
        isObject: D,
        isString: E,
        merge: I,
        pick: B,
        pInt: z,
        replaceNested: R,
        uniqueKey: N
      } = h;
    class G {
      constructor(t, e, i, s, o, r, a) {
        let n, l;
        let h = this.createElement("svg").attr({
            version: "1.1",
            class: "highcharts-root"
          }),
          d = h.element;
        a || h.css(this.getStyle(s || {})), t.appendChild(d), S(t, "dir", "ltr"), -1 === t.innerHTML.indexOf("xmlns") && S(d, "xmlns", this.SVG_NS), this.box = d, this.boxWrapper = h, this.alignedObjects = [], this.url = this.getReferenceURL(), this.createElement("desc").add().element.appendChild(g.createTextNode("Created with Highcharts 11.4.8")), this.defs = this.createElement("defs").add(), this.allowHTML = r, this.forExport = o, this.styledMode = a, this.gradients = {}, this.cache = {}, this.cacheKeys = [], this.imgCount = 0, this.rootFontSize = h.getStyle("font-size"), this.setSize(e, i, !1), f && t.getBoundingClientRect && ((n = function () {
          A(t, {
            left: 0,
            top: 0
          }), l = t.getBoundingClientRect(), A(t, {
            left: Math.ceil(l.left) - l.left + "px",
            top: Math.ceil(l.top) - l.top + "px"
          });
        })(), this.unSubPixelFix = C(M, "resize", n));
      }
      definition(e) {
        return new t([e]).addToDOM(this.defs.element);
      }
      getReferenceURL() {
        if ((f || x) && g.getElementsByTagName("base").length) {
          if (!T(d)) {
            let e = N(),
              i = new t([{
                tagName: "svg",
                attributes: {
                  width: 8,
                  height: 8
                },
                children: [{
                  tagName: "defs",
                  children: [{
                    tagName: "clipPath",
                    attributes: {
                      id: e
                    },
                    children: [{
                      tagName: "rect",
                      attributes: {
                        width: 4,
                        height: 4
                      }
                    }]
                  }]
                }, {
                  tagName: "rect",
                  attributes: {
                    id: "hitme",
                    width: 8,
                    height: 8,
                    "clip-path": `url(#${e})`,
                    fill: "rgba(0,0,0,0.001)"
                  }
                }]
              }]).addToDOM(g.body);
            A(i, {
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 9e5
            });
            let s = g.elementFromPoint(6, 6);
            d = "hitme" === (s && s.id), g.body.removeChild(i);
          }
          if (d) return R(M.location.href.split("#")[0], [/<[^>]*>/g, ""], [/([\('\)])/g, "\\$1"], [/ /g, "%20"]);
        }
        return "";
      }
      getStyle(t) {
        return this.style = L({
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "1rem"
        }, t), this.style;
      }
      setStyle(t) {
        this.boxWrapper.css(this.getStyle(t));
      }
      isHidden() {
        return !this.boxWrapper.getBBox().width;
      }
      destroy() {
        let t = this.defs;
        return this.box = null, this.boxWrapper = this.boxWrapper.destroy(), P(this.gradients || {}), this.gradients = null, this.defs = t.destroy(), this.unSubPixelFix && this.unSubPixelFix(), this.alignedObjects = null, null;
      }
      createElement(t) {
        return new this.Element(this, t);
      }
      getRadialAttr(t, e) {
        return {
          cx: t[0] - t[2] / 2 + (e.cx || 0) * t[2],
          cy: t[1] - t[2] / 2 + (e.cy || 0) * t[2],
          r: (e.r || 0) * t[2]
        };
      }
      shadowDefinition(t) {
        let e = [`highcharts-drop-shadow-${this.chartIndex}`, ...Object.keys(t).map(e => `${e}-${t[e]}`)].join("-").toLowerCase().replace(/[^a-z\d\-]/g, ""),
          i = I({
            color: "#000000",
            offsetX: 1,
            offsetY: 1,
            opacity: .15,
            width: 5
          }, t);
        return this.defs.element.querySelector(`#${e}`) || this.definition({
          tagName: "filter",
          attributes: {
            id: e,
            filterUnits: i.filterUnits
          },
          children: this.getShadowFilterContent(i)
        }), e;
      }
      getShadowFilterContent(t) {
        return [{
          tagName: "feDropShadow",
          attributes: {
            dx: t.offsetX,
            dy: t.offsetY,
            "flood-color": t.color,
            "flood-opacity": Math.min(5 * t.opacity, 1),
            stdDeviation: t.width / 2
          }
        }];
      }
      buildText(t) {
        new l(t).buildSVG();
      }
      getContrast(t) {
        let e = i.parse(t).rgba.map(t => {
            let e = t / 255;
            return e <= .03928 ? e / 12.92 : Math.pow((e + .055) / 1.055, 2.4);
          }),
          s = .2126 * e[0] + .7152 * e[1] + .0722 * e[2];
        return 1.05 / (s + .05) > (s + .05) / .05 ? "#FFFFFF" : "#000000";
      }
      button(e, i, s, o, r = {}, a, n, l, h, d) {
        let p = this.label(e, i, s, h, void 0, void 0, d, void 0, "button"),
          u = this.styledMode,
          g = arguments,
          f = 0;
        r = I(c.global.buttonTheme, r), u && (delete r.fill, delete r.stroke, delete r["stroke-width"]);
        let x = r.states || {},
          y = r.style || {};
        delete r.states, delete r.style;
        let b = [t.filterUserAttributes(r)],
          v = [y];
        return u || ["hover", "select", "disabled"].forEach((e, i) => {
          b.push(I(b[0], t.filterUserAttributes(g[i + 5] || x[e] || {}))), v.push(b[i + 1].style), delete b[i + 1].style;
        }), C(p.element, m ? "mouseover" : "mouseenter", function () {
          3 !== f && p.setState(1);
        }), C(p.element, m ? "mouseout" : "mouseleave", function () {
          3 !== f && p.setState(f);
        }), p.setState = (t = 0) => {
          if (1 !== t && (p.state = f = t), p.removeClass(/highcharts-button-(normal|hover|pressed|disabled)/).addClass("highcharts-button-" + ["normal", "hover", "pressed", "disabled"][t]), !u) {
            p.attr(b[t]);
            let e = v[t];
            D(e) && p.css(e);
          }
        }, p.attr(b[0]), !u && (p.css(L({
          cursor: "default"
        }, y)), d && p.text.css({
          pointerEvents: "none"
        })), p.on("touchstart", t => t.stopPropagation()).on("click", function (t) {
          3 !== f && o.call(p, t);
        });
      }
      crispLine(t, e) {
        let [i, s] = t;
        return T(i[1]) && i[1] === s[1] && (i[1] = s[1] = k(i[1], e)), T(i[2]) && i[2] === s[2] && (i[2] = s[2] = k(i[2], e)), t;
      }
      path(t) {
        let e = this.styledMode ? {} : {
          fill: "none"
        };
        return j(t) ? e.d = t : D(t) && L(e, t), this.createElement("path").attr(e);
      }
      circle(t, e, i) {
        let s = D(t) ? t : void 0 === t ? {} : {
            x: t,
            y: e,
            r: i
          },
          o = this.createElement("circle");
        return o.xSetter = o.ySetter = function (t, e, i) {
          i.setAttribute("c" + e, t);
        }, o.attr(s);
      }
      arc(t, e, i, s, o, r) {
        let a;
        D(t) ? (e = (a = t).y, i = a.r, s = a.innerR, o = a.start, r = a.end, t = a.x) : a = {
          innerR: s,
          start: o,
          end: r
        };
        let n = this.symbol("arc", t, e, i, i, a);
        return n.r = i, n;
      }
      rect(t, e, i, s, o, r) {
        let a = D(t) ? t : void 0 === t ? {} : {
            x: t,
            y: e,
            r: o,
            width: Math.max(i || 0, 0),
            height: Math.max(s || 0, 0)
          },
          n = this.createElement("rect");
        return this.styledMode || (void 0 !== r && (a["stroke-width"] = r, L(a, n.crisp(a))), a.fill = "none"), n.rSetter = function (t, e, i) {
          n.r = t, S(i, {
            rx: t,
            ry: t
          });
        }, n.rGetter = function () {
          return n.r || 0;
        }, n.attr(a);
      }
      roundedRect(t) {
        return this.symbol("roundedRect").attr(t);
      }
      setSize(t, e, i) {
        this.width = t, this.height = e, this.boxWrapper.animate({
          width: t,
          height: e
        }, {
          step: function () {
            this.attr({
              viewBox: "0 0 " + this.attr("width") + " " + this.attr("height")
            });
          },
          duration: B(i, !0) ? void 0 : 0
        }), this.alignElements();
      }
      g(t) {
        let e = this.createElement("g");
        return t ? e.attr({
          class: "highcharts-" + t
        }) : e;
      }
      image(t, e, i, s, o, r) {
        let a = {
          preserveAspectRatio: "none"
        };
        O(e) && (a.x = e), O(i) && (a.y = i), O(s) && (a.width = s), O(o) && (a.height = o);
        let n = this.createElement("image").attr(a),
          l = function (e) {
            n.attr({
              href: t
            }), r.call(n, e);
          };
        if (r) {
          n.attr({
            href: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          });
          let e = new M.Image();
          C(e, "load", l), e.src = t, e.complete && l({});
        } else n.attr({
          href: t
        });
        return n;
      }
      symbol(t, e, i, s, o, r) {
        let a, n, l, h;
        let d = this,
          c = /^url\((.*?)\)$/,
          u = c.test(t),
          f = !u && (this.symbols[t] ? t : "circle"),
          m = f && this.symbols[f];
        if (m) "number" == typeof e && (n = m.call(this.symbols, e || 0, i || 0, s || 0, o || 0, r)), a = this.path(n), d.styledMode || a.attr("fill", "none"), L(a, {
          symbolName: f || void 0,
          x: e,
          y: i,
          width: s,
          height: o
        }), r && L(a, r);else if (u) {
          l = t.match(c)[1];
          let s = a = this.image(l);
          s.imgwidth = B(r && r.width, v[l] && v[l].width), s.imgheight = B(r && r.height, v[l] && v[l].height), h = t => t.attr({
            width: t.width,
            height: t.height
          }), ["width", "height"].forEach(t => {
            s[`${t}Setter`] = function (t, e) {
              this[e] = t;
              let {
                  alignByTranslate: i,
                  element: s,
                  width: o,
                  height: a,
                  imgwidth: n,
                  imgheight: l
                } = this,
                h = "width" === e ? n : l,
                d = 1;
              r && "within" === r.backgroundSize && o && a && n && l ? (d = Math.min(o / n, a / l), S(s, {
                width: Math.round(n * d),
                height: Math.round(l * d)
              })) : s && h && s.setAttribute(e, h), !i && n && l && this.translate(((o || 0) - n * d) / 2, ((a || 0) - l * d) / 2);
            };
          }), T(e) && s.attr({
            x: e,
            y: i
          }), s.isImg = !0, s.symbolUrl = t, T(s.imgwidth) && T(s.imgheight) ? h(s) : (s.attr({
            width: 0,
            height: 0
          }), w("img", {
            onload: function () {
              let t = p[d.chartIndex];
              0 === this.width && (A(this, {
                position: "absolute",
                top: "-999em"
              }), g.body.appendChild(this)), v[l] = {
                width: this.width,
                height: this.height
              }, s.imgwidth = this.width, s.imgheight = this.height, s.element && h(s), this.parentNode && this.parentNode.removeChild(this), d.imgCount--, d.imgCount || !t || t.hasLoaded || t.onload();
            },
            src: l
          }), this.imgCount++);
        }
        return a;
      }
      clipRect(t, e, i, s) {
        return this.rect(t, e, i, s, 0);
      }
      text(t, e, i, s) {
        let o = {};
        if (s && (this.allowHTML || !this.forExport)) return this.html(t, e, i);
        o.x = Math.round(e || 0), i && (o.y = Math.round(i)), T(t) && (o.text = t);
        let r = this.createElement("text").attr(o);
        return s && (!this.forExport || this.allowHTML) || (r.xSetter = function (t, e, i) {
          let s = i.getElementsByTagName("tspan"),
            o = i.getAttribute(e);
          for (let i = 0, r; i < s.length; i++) (r = s[i]).getAttribute(e) === o && r.setAttribute(e, t);
          i.setAttribute(e, t);
        }), r;
      }
      fontMetrics(t) {
        let e = z(r.prototype.getStyle.call(t, "font-size") || 0),
          i = e < 24 ? e + 3 : Math.round(1.2 * e),
          s = Math.round(.8 * i);
        return {
          h: i,
          b: s,
          f: e
        };
      }
      rotCorr(t, e, i) {
        let s = t;
        return e && i && (s = Math.max(s * Math.cos(e * u), 4)), {
          x: -t / 3 * Math.sin(e * u),
          y: s
        };
      }
      pathToSegments(t) {
        let e = [],
          i = [],
          s = {
            A: 8,
            C: 7,
            H: 2,
            L: 3,
            M: 3,
            Q: 5,
            S: 5,
            T: 3,
            V: 2
          };
        for (let o = 0; o < t.length; o++) E(i[0]) && O(t[o]) && i.length === s[i[0].toUpperCase()] && t.splice(o, 0, i[0].replace("M", "L").replace("m", "l")), "string" == typeof t[o] && (i.length && e.push(i.slice(0)), i.length = 0), i.push(t[o]);
        return e.push(i.slice(0)), e;
      }
      label(t, e, i, s, o, r, n, l, h) {
        return new a(this, t, e, i, s, o, r, n, l, h);
      }
      alignElements() {
        this.alignedObjects.forEach(t => t.align());
      }
    }
    return L(G.prototype, {
      Element: r,
      SVG_NS: b,
      escapes: {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "'": "&#39;",
        '"': "&quot;"
      },
      symbols: n,
      draw: y
    }), o.registerRendererType("svg", G, !0), G;
  }), i(e, "Core/Renderer/HTML/HTMLElement.js", [e["Core/Renderer/HTML/AST.js"], e["Core/Globals.js"], e["Core/Renderer/SVG/SVGElement.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
        composed: o
      } = e,
      {
        attr: r,
        css: a,
        createElement: n,
        defined: l,
        extend: h,
        pInt: d,
        pushUnique: c
      } = s;
    function p(t, e, s) {
      let o = this.div?.style || s.style;
      i.prototype[`${e}Setter`].call(this, t, e, s), o && (o[e] = t);
    }
    let u = (t, e) => {
      if (!t.div) {
        let s = r(t.element, "class"),
          o = t.css,
          a = n("div", s ? {
            className: s
          } : void 0, {
            position: "absolute",
            left: `${t.translateX || 0}px`,
            top: `${t.translateY || 0}px`,
            ...t.styles,
            display: t.display,
            opacity: t.opacity,
            visibility: t.visibility
          }, t.parentGroup?.div || e);
        t.classSetter = (t, e, i) => {
          i.setAttribute("class", t), a.className = t;
        }, t.translateXSetter = t.translateYSetter = (e, i) => {
          t[i] = e, a.style["translateX" === i ? "left" : "top"] = `${e}px`, t.doTransform = !0;
        }, t.opacitySetter = t.visibilitySetter = p, t.css = e => (o.call(t, e), e.cursor && (a.style.cursor = e.cursor), e.pointerEvents && (a.style.pointerEvents = e.pointerEvents), t), t.on = function () {
          return i.prototype.on.apply({
            element: a,
            onEvents: t.onEvents
          }, arguments), t;
        }, t.div = a;
      }
      return t.div;
    };
    class g extends i {
      static compose(t) {
        c(o, this.compose) && (t.prototype.html = function (t, e, i) {
          return new g(this, "span").attr({
            text: t,
            x: Math.round(e),
            y: Math.round(i)
          });
        });
      }
      constructor(t, e) {
        super(t, e), this.css({
          position: "absolute",
          ...(t.styledMode ? {} : {
            fontFamily: t.style.fontFamily,
            fontSize: t.style.fontSize
          })
        }), this.element.style.whiteSpace = "nowrap";
      }
      getSpanCorrection(t, e, i) {
        this.xCorr = -t * i, this.yCorr = -e;
      }
      css(t) {
        let e;
        let {
            element: i
          } = this,
          s = "SPAN" === i.tagName && t && "width" in t,
          o = s && t.width;
        return s && (delete t.width, this.textWidth = d(o) || void 0, e = !0), t?.textOverflow === "ellipsis" && (t.whiteSpace = "nowrap", t.overflow = "hidden"), h(this.styles, t), a(i, t), e && this.updateTransform(), this;
      }
      htmlGetBBox() {
        let {
          element: t
        } = this;
        return {
          x: t.offsetLeft,
          y: t.offsetTop,
          width: t.offsetWidth,
          height: t.offsetHeight
        };
      }
      updateTransform() {
        if (!this.added) {
          this.alignOnAdd = !0;
          return;
        }
        let {
            element: t,
            renderer: e,
            rotation: i,
            rotationOriginX: s,
            rotationOriginY: o,
            styles: r,
            textAlign: n = "left",
            textWidth: h,
            translateX: d = 0,
            translateY: c = 0,
            x: p = 0,
            y: u = 0
          } = this,
          g = {
            left: 0,
            center: .5,
            right: 1
          }[n],
          f = r.whiteSpace;
        if (a(t, {
          marginLeft: `${d}px`,
          marginTop: `${c}px`
        }), "SPAN" === t.tagName) {
          let r = [i, n, t.innerHTML, h, this.textAlign].join(","),
            d = -(this.parentGroup?.padding * 1) || 0,
            c,
            m = !1;
          if (h !== this.oldTextWidth) {
            let e = this.textPxLength ? this.textPxLength : (a(t, {
                width: "",
                whiteSpace: f || "nowrap"
              }), t.offsetWidth),
              s = h || 0;
            (s > this.oldTextWidth || e > s) && (/[ \-]/.test(t.textContent || t.innerText) || "ellipsis" === t.style.textOverflow) && (a(t, {
              width: e > s || i ? h + "px" : "auto",
              display: "block",
              whiteSpace: f || "normal"
            }), this.oldTextWidth = h, m = !0);
          }
          this.hasBoxWidthChanged = m, r !== this.cTT && (c = e.fontMetrics(t).b, l(i) && (i !== (this.oldRotation || 0) || n !== this.oldAlign) && this.setSpanRotation(i, d, d), this.getSpanCorrection(!l(i) && this.textPxLength || t.offsetWidth, c, g));
          let {
              xCorr: x = 0,
              yCorr: y = 0
            } = this,
            b = (s ?? p) - x - p - d,
            v = (o ?? u) - y - u - d;
          a(t, {
            left: `${p + x}px`,
            top: `${u + y}px`,
            transformOrigin: `${b}px ${v}px`
          }), this.cTT = r, this.oldRotation = i, this.oldAlign = n;
        }
      }
      setSpanRotation(t, e, i) {
        a(this.element, {
          transform: `rotate(${t}deg)`,
          transformOrigin: `${e}% ${i}px`
        });
      }
      add(t) {
        let e;
        let i = this.renderer.box.parentNode,
          s = [];
        if (this.parentGroup = t, t && !(e = t.div)) {
          let o = t;
          for (; o;) s.push(o), o = o.parentGroup;
          for (let t of s.reverse()) e = u(t, i);
        }
        return (e || i).appendChild(this.element), this.added = !0, this.alignOnAdd && this.updateTransform(), this;
      }
      textSetter(e) {
        e !== this.textStr && (delete this.bBox, delete this.oldTextWidth, t.setElementHTML(this.element, e ?? ""), this.textStr = e, this.doTransform = !0);
      }
      alignSetter(t) {
        this.alignValue = this.textAlign = t, this.doTransform = !0;
      }
      xSetter(t, e) {
        this[e] = t, this.doTransform = !0;
      }
    }
    let f = g.prototype;
    return f.visibilitySetter = f.opacitySetter = p, f.ySetter = f.rotationSetter = f.rotationOriginXSetter = f.rotationOriginYSetter = f.xSetter, g;
  }), i(e, "Core/Axis/AxisDefaults.js", [], function () {
    var t, e;
    return (e = t || (t = {})).xAxis = {
      alignTicks: !0,
      allowDecimals: void 0,
      panningEnabled: !0,
      zIndex: 2,
      zoomEnabled: !0,
      dateTimeLabelFormats: {
        millisecond: {
          main: "%H:%M:%S.%L",
          range: !1
        },
        second: {
          main: "%H:%M:%S",
          range: !1
        },
        minute: {
          main: "%H:%M",
          range: !1
        },
        hour: {
          main: "%H:%M",
          range: !1
        },
        day: {
          main: "%e %b"
        },
        week: {
          main: "%e %b"
        },
        month: {
          main: "%b '%y"
        },
        year: {
          main: "%Y"
        }
      },
      endOnTick: !1,
      gridLineDashStyle: "Solid",
      gridZIndex: 1,
      labels: {
        autoRotationLimit: 80,
        distance: 15,
        enabled: !0,
        indentation: 10,
        overflow: "justify",
        reserveSpace: void 0,
        rotation: void 0,
        staggerLines: 0,
        step: 0,
        useHTML: !1,
        zIndex: 7,
        style: {
          color: "#333333",
          cursor: "default",
          fontSize: "0.8em"
        }
      },
      maxPadding: .01,
      minorGridLineDashStyle: "Solid",
      minorTickLength: 2,
      minorTickPosition: "outside",
      minorTicksPerMajor: 5,
      minPadding: .01,
      offset: void 0,
      reversed: void 0,
      reversedStacks: !1,
      showEmpty: !0,
      showFirstLabel: !0,
      showLastLabel: !0,
      startOfWeek: 1,
      startOnTick: !1,
      tickLength: 10,
      tickPixelInterval: 100,
      tickmarkPlacement: "between",
      tickPosition: "outside",
      title: {
        align: "middle",
        useHTML: !1,
        x: 0,
        y: 0,
        style: {
          color: "#666666",
          fontSize: "0.8em"
        }
      },
      visible: !0,
      minorGridLineColor: "#f2f2f2",
      minorGridLineWidth: 1,
      minorTickColor: "#999999",
      lineColor: "#333333",
      lineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineWidth: void 0,
      tickColor: "#333333"
    }, e.yAxis = {
      reversedStacks: !0,
      endOnTick: !0,
      maxPadding: .05,
      minPadding: .05,
      tickPixelInterval: 72,
      showLastLabel: !0,
      labels: {
        x: void 0
      },
      startOnTick: !0,
      title: {
        text: "Values"
      },
      stackLabels: {
        animation: {},
        allowOverlap: !1,
        enabled: !1,
        crop: !0,
        overflow: "justify",
        formatter: function () {
          let {
            numberFormatter: t
          } = this.axis.chart;
          return t(this.total || 0, -1);
        },
        style: {
          color: "#000000",
          fontSize: "0.7em",
          fontWeight: "bold",
          textOutline: "1px contrast"
        }
      },
      gridLineWidth: 1,
      lineWidth: 0
    }, t;
  }), i(e, "Core/Foundation.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      addEvent: i,
      isFunction: s,
      objectEach: o,
      removeEvent: r
    } = t;
    return (e || (e = {})).registerEventOptions = function (t, e) {
      t.eventOptions = t.eventOptions || {}, o(e.events, function (e, o) {
        t.eventOptions[o] !== e && (t.eventOptions[o] && (r(t, o, t.eventOptions[o]), delete t.eventOptions[o]), s(e) && (t.eventOptions[o] = e, i(t, o, e, {
          order: 0
        })));
      });
    }, e;
  }), i(e, "Core/Axis/Tick.js", [e["Core/Templating.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        deg2rad: s
      } = e,
      {
        clamp: o,
        correctFloat: r,
        defined: a,
        destroyObjectProperties: n,
        extend: l,
        fireEvent: h,
        isNumber: d,
        merge: c,
        objectEach: p,
        pick: u
      } = i;
    return class {
      constructor(t, e, i, s, o) {
        this.isNew = !0, this.isNewLabel = !0, this.axis = t, this.pos = e, this.type = i || "", this.parameters = o || {}, this.tickmarkOffset = this.parameters.tickmarkOffset, this.options = this.parameters.options, h(this, "init"), i || s || this.addLabel();
      }
      addLabel() {
        let e = this,
          i = e.axis,
          s = i.options,
          o = i.chart,
          n = i.categories,
          c = i.logarithmic,
          p = i.names,
          g = e.pos,
          f = u(e.options && e.options.labels, s.labels),
          m = i.tickPositions,
          x = g === m[0],
          y = g === m[m.length - 1],
          b = (!f.step || 1 === f.step) && 1 === i.tickInterval,
          v = m.info,
          M = e.label,
          C,
          S,
          w,
          k = this.parameters.category || (n ? u(n[g], p[g], g) : g);
        c && d(k) && (k = r(c.lin2log(k))), i.dateTime && (v ? C = (S = o.time.resolveDTLFormat(s.dateTimeLabelFormats[!s.grid && v.higherRanks[g] || v.unitName])).main : d(k) && (C = i.dateTime.getXDateFormat(k, s.dateTimeLabelFormats || {}))), e.isFirst = x, e.isLast = y;
        let A = {
          axis: i,
          chart: o,
          dateTimeLabelFormat: C,
          isFirst: x,
          isLast: y,
          pos: g,
          tick: e,
          tickPositionInfo: v,
          value: k
        };
        h(this, "labelFormat", A);
        let T = e => f.formatter ? f.formatter.call(e, e) : f.format ? (e.text = i.defaultLabelFormatter.call(e), t.format(f.format, e, o)) : i.defaultLabelFormatter.call(e),
          P = T.call(A, A),
          L = S && S.list;
        L ? e.shortenLabel = function () {
          for (w = 0; w < L.length; w++) if (l(A, {
            dateTimeLabelFormat: L[w]
          }), M.attr({
            text: T.call(A, A)
          }), M.getBBox().width < i.getSlotWidth(e) - 2 * (f.padding || 0)) return;
          M.attr({
            text: ""
          });
        } : e.shortenLabel = void 0, b && i._addedPlotLB && e.moveLabel(P, f), a(M) || e.movedLabel ? M && M.textStr !== P && !b && (!M.textWidth || f.style.width || M.styles.width || M.css({
          width: null
        }), M.attr({
          text: P
        }), M.textPxLength = M.getBBox().width) : (e.label = M = e.createLabel(P, f), e.rotation = 0);
      }
      createLabel(t, e, i) {
        let s = this.axis,
          o = s.chart,
          r = a(t) && e.enabled ? o.renderer.text(t, i?.x, i?.y, e.useHTML).add(s.labelGroup) : void 0;
        return r && (o.styledMode || r.css(c(e.style)), r.textPxLength = r.getBBox().width), r;
      }
      destroy() {
        n(this, this.axis);
      }
      getPosition(t, e, i, s) {
        let a = this.axis,
          n = a.chart,
          l = s && n.oldChartHeight || n.chartHeight,
          d = {
            x: t ? r(a.translate(e + i, void 0, void 0, s) + a.transB) : a.left + a.offset + (a.opposite ? (s && n.oldChartWidth || n.chartWidth) - a.right - a.left : 0),
            y: t ? l - a.bottom + a.offset - (a.opposite ? a.height : 0) : r(l - a.translate(e + i, void 0, void 0, s) - a.transB)
          };
        return d.y = o(d.y, -1e9, 1e9), h(this, "afterGetPosition", {
          pos: d
        }), d;
      }
      getLabelPosition(t, e, i, o, r, n, l, d) {
        let c, p;
        let g = this.axis,
          f = g.transA,
          m = g.isLinked && g.linkedParent ? g.linkedParent.reversed : g.reversed,
          x = g.staggerLines,
          y = g.tickRotCorr || {
            x: 0,
            y: 0
          },
          b = o || g.reserveSpaceDefault ? 0 : -g.labelOffset * ("center" === g.labelAlign ? .5 : 1),
          v = r.distance,
          M = {};
        return c = 0 === g.side ? i.rotation ? -v : -i.getBBox().height : 2 === g.side ? y.y + v : Math.cos(i.rotation * s) * (y.y - i.getBBox(!1, 0).height / 2), a(r.y) && (c = 0 === g.side && g.horiz ? r.y + c : r.y), t = t + u(r.x, [0, 1, 0, -1][g.side] * v) + b + y.x - (n && o ? n * f * (m ? -1 : 1) : 0), e = e + c - (n && !o ? n * f * (m ? 1 : -1) : 0), x && (p = l / (d || 1) % x, g.opposite && (p = x - p - 1), e += p * (g.labelOffset / x)), M.x = t, M.y = Math.round(e), h(this, "afterGetLabelPosition", {
          pos: M,
          tickmarkOffset: n,
          index: l
        }), M;
      }
      getLabelSize() {
        return this.label ? this.label.getBBox()[this.axis.horiz ? "height" : "width"] : 0;
      }
      getMarkPath(t, e, i, s, o = !1, r) {
        return r.crispLine([["M", t, e], ["L", t + (o ? 0 : -i), e + (o ? i : 0)]], s);
      }
      handleOverflow(t) {
        let e = this.axis,
          i = e.options.labels,
          o = t.x,
          r = e.chart.chartWidth,
          a = e.chart.spacing,
          n = u(e.labelLeft, Math.min(e.pos, a[3])),
          l = u(e.labelRight, Math.max(e.isRadial ? 0 : e.pos + e.len, r - a[1])),
          h = this.label,
          d = this.rotation,
          c = {
            left: 0,
            center: .5,
            right: 1
          }[e.labelAlign || h.attr("align")],
          p = h.getBBox().width,
          g = e.getSlotWidth(this),
          f = {},
          m = g,
          x = 1,
          y,
          b,
          v;
        d || "justify" !== i.overflow ? d < 0 && o - c * p < n ? v = Math.round(o / Math.cos(d * s) - n) : d > 0 && o + c * p > l && (v = Math.round((r - o) / Math.cos(d * s))) : (y = o - c * p, b = o + (1 - c) * p, y < n ? m = t.x + m * (1 - c) - n : b > l && (m = l - t.x + m * c, x = -1), (m = Math.min(g, m)) < g && "center" === e.labelAlign && (t.x += x * (g - m - c * (g - Math.min(p, m)))), (p > m || e.autoRotation && (h.styles || {}).width) && (v = m)), v && (this.shortenLabel ? this.shortenLabel() : (f.width = Math.floor(v) + "px", (i.style || {}).textOverflow || (f.textOverflow = "ellipsis"), h.css(f)));
      }
      moveLabel(t, e) {
        let i = this,
          s = i.label,
          o = i.axis,
          r = !1,
          a;
        s && s.textStr === t ? (i.movedLabel = s, r = !0, delete i.label) : p(o.ticks, function (e) {
          r || e.isNew || e === i || !e.label || e.label.textStr !== t || (i.movedLabel = e.label, r = !0, e.labelPos = i.movedLabel.xy, delete e.label);
        }), !r && (i.labelPos || s) && (a = i.labelPos || s.xy, i.movedLabel = i.createLabel(t, e, a), i.movedLabel && i.movedLabel.attr({
          opacity: 0
        }));
      }
      render(t, e, i) {
        let s = this.axis,
          o = s.horiz,
          a = this.pos,
          n = u(this.tickmarkOffset, s.tickmarkOffset),
          l = this.getPosition(o, a, n, e),
          d = l.x,
          c = l.y,
          p = s.pos,
          g = p + s.len,
          f = o ? d : c;
        !s.chart.polar && this.isNew && (r(f) < p || f > g) && (i = 0);
        let m = u(i, this.label && this.label.newOpacity, 1);
        i = u(i, 1), this.isActive = !0, this.renderGridLine(e, i), this.renderMark(l, i), this.renderLabel(l, e, m, t), this.isNew = !1, h(this, "afterRender");
      }
      renderGridLine(t, e) {
        let i = this.axis,
          s = i.options,
          o = {},
          r = this.pos,
          a = this.type,
          n = u(this.tickmarkOffset, i.tickmarkOffset),
          l = i.chart.renderer,
          h = this.gridLine,
          d,
          c = s.gridLineWidth,
          p = s.gridLineColor,
          g = s.gridLineDashStyle;
        "minor" === this.type && (c = s.minorGridLineWidth, p = s.minorGridLineColor, g = s.minorGridLineDashStyle), h || (i.chart.styledMode || (o.stroke = p, o["stroke-width"] = c || 0, o.dashstyle = g), a || (o.zIndex = 1), t && (e = 0), this.gridLine = h = l.path().attr(o).addClass("highcharts-" + (a ? a + "-" : "") + "grid-line").add(i.gridGroup)), h && (d = i.getPlotLinePath({
          value: r + n,
          lineWidth: h.strokeWidth(),
          force: "pass",
          old: t,
          acrossPanes: !1
        })) && h[t || this.isNew ? "attr" : "animate"]({
          d: d,
          opacity: e
        });
      }
      renderMark(t, e) {
        let i = this.axis,
          s = i.options,
          o = i.chart.renderer,
          r = this.type,
          a = i.tickSize(r ? r + "Tick" : "tick"),
          n = t.x,
          l = t.y,
          h = u(s["minor" !== r ? "tickWidth" : "minorTickWidth"], !r && i.isXAxis ? 1 : 0),
          d = s["minor" !== r ? "tickColor" : "minorTickColor"],
          c = this.mark,
          p = !c;
        a && (i.opposite && (a[0] = -a[0]), c || (this.mark = c = o.path().addClass("highcharts-" + (r ? r + "-" : "") + "tick").add(i.axisGroup), i.chart.styledMode || c.attr({
          stroke: d,
          "stroke-width": h
        })), c[p ? "attr" : "animate"]({
          d: this.getMarkPath(n, l, a[0], c.strokeWidth(), i.horiz, o),
          opacity: e
        }));
      }
      renderLabel(t, e, i, s) {
        let o = this.axis,
          r = o.horiz,
          a = o.options,
          n = this.label,
          l = a.labels,
          h = l.step,
          c = u(this.tickmarkOffset, o.tickmarkOffset),
          p = t.x,
          g = t.y,
          f = !0;
        n && d(p) && (n.xy = t = this.getLabelPosition(p, g, n, r, l, c, s, h), (!this.isFirst || this.isLast || a.showFirstLabel) && (!this.isLast || this.isFirst || a.showLastLabel) ? !r || l.step || l.rotation || e || 0 === i || this.handleOverflow(t) : f = !1, h && s % h && (f = !1), f && d(t.y) ? (t.opacity = i, n[this.isNewLabel ? "attr" : "animate"](t).show(!0), this.isNewLabel = !1) : (n.hide(), this.isNewLabel = !0));
      }
      replaceMovedLabel() {
        let t = this.label,
          e = this.axis;
        t && !this.isNew && (t.animate({
          opacity: 0
        }, void 0, t.destroy), delete this.label), e.isDirty = !0, this.label = this.movedLabel, delete this.movedLabel;
      }
    };
  }), i(e, "Core/Axis/Axis.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Axis/AxisDefaults.js"], e["Core/Color/Color.js"], e["Core/Defaults.js"], e["Core/Foundation.js"], e["Core/Globals.js"], e["Core/Axis/Tick.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n) {
    let {
        animObject: l
      } = t,
      {
        xAxis: h,
        yAxis: d
      } = e,
      {
        defaultOptions: c
      } = s,
      {
        registerEventOptions: p
      } = o,
      {
        deg2rad: u
      } = r,
      {
        arrayMax: g,
        arrayMin: f,
        clamp: m,
        correctFloat: x,
        defined: y,
        destroyObjectProperties: b,
        erase: v,
        error: M,
        extend: C,
        fireEvent: S,
        getClosestDistance: w,
        insertItem: k,
        isArray: A,
        isNumber: T,
        isString: P,
        merge: L,
        normalizeTickInterval: j,
        objectEach: O,
        pick: D,
        relativeLength: E,
        removeEvent: I,
        splat: B,
        syncTimeout: z
      } = n,
      R = (t, e) => j(e, void 0, void 0, D(t.options.allowDecimals, e < .5 || void 0 !== t.tickAmount), !!t.tickAmount);
    C(c, {
      xAxis: h,
      yAxis: L(h, d)
    });
    class N {
      constructor(t, e, i) {
        this.init(t, e, i);
      }
      init(t, e, i = this.coll) {
        let s = "xAxis" === i,
          o = this.isZAxis || (t.inverted ? !s : s);
        this.chart = t, this.horiz = o, this.isXAxis = s, this.coll = i, S(this, "init", {
          userOptions: e
        }), this.opposite = D(e.opposite, this.opposite), this.side = D(e.side, this.side, o ? this.opposite ? 0 : 2 : this.opposite ? 1 : 3), this.setOptions(e);
        let r = this.options,
          a = r.labels;
        this.type ?? (this.type = r.type || "linear"), this.uniqueNames ?? (this.uniqueNames = r.uniqueNames ?? !0), S(this, "afterSetType"), this.userOptions = e, this.minPixelPadding = 0, this.reversed = D(r.reversed, this.reversed), this.visible = r.visible, this.zoomEnabled = r.zoomEnabled, this.hasNames = "category" === this.type || !0 === r.categories, this.categories = A(r.categories) && r.categories || (this.hasNames ? [] : void 0), this.names || (this.names = [], this.names.keys = {}), this.plotLinesAndBandsGroups = {}, this.positiveValuesOnly = !!this.logarithmic, this.isLinked = y(r.linkedTo), this.ticks = {}, this.labelEdge = [], this.minorTicks = {}, this.plotLinesAndBands = [], this.alternateBands = {}, this.len ?? (this.len = 0), this.minRange = this.userMinRange = r.minRange || r.maxZoom, this.range = r.range, this.offset = r.offset || 0, this.max = void 0, this.min = void 0;
        let n = D(r.crosshair, B(t.options.tooltip.crosshairs)[s ? 0 : 1]);
        this.crosshair = !0 === n ? {} : n, -1 === t.axes.indexOf(this) && (s ? t.axes.splice(t.xAxis.length, 0, this) : t.axes.push(this), k(this, t[this.coll])), t.orderItems(this.coll), this.series = this.series || [], t.inverted && !this.isZAxis && s && !y(this.reversed) && (this.reversed = !0), this.labelRotation = T(a.rotation) ? a.rotation : void 0, p(this, r), S(this, "afterInit");
      }
      setOptions(t) {
        let e = this.horiz ? {
          labels: {
            autoRotation: [-45],
            padding: 4
          },
          margin: 15
        } : {
          labels: {
            padding: 1
          },
          title: {
            rotation: 90 * this.side
          }
        };
        this.options = L(e, c[this.coll], t), S(this, "afterSetOptions", {
          userOptions: t
        });
      }
      defaultLabelFormatter() {
        let t = this.axis,
          {
            numberFormatter: e
          } = this.chart,
          i = T(this.value) ? this.value : NaN,
          s = t.chart.time,
          o = t.categories,
          r = this.dateTimeLabelFormat,
          a = c.lang,
          n = a.numericSymbols,
          l = a.numericSymbolMagnitude || 1e3,
          h = t.logarithmic ? Math.abs(i) : t.tickInterval,
          d = n && n.length,
          p,
          u;
        if (o) u = `${this.value}`;else if (r) u = s.dateFormat(r, i);else if (d && n && h >= 1e3) for (; d-- && void 0 === u;) h >= (p = Math.pow(l, d + 1)) && 10 * i % p == 0 && null !== n[d] && 0 !== i && (u = e(i / p, -1) + n[d]);
        return void 0 === u && (u = Math.abs(i) >= 1e4 ? e(i, -1) : e(i, -1, void 0, "")), u;
      }
      getSeriesExtremes() {
        let t;
        let e = this;
        S(this, "getSeriesExtremes", null, function () {
          e.hasVisibleSeries = !1, e.dataMin = e.dataMax = e.threshold = void 0, e.softThreshold = !e.isXAxis, e.series.forEach(i => {
            if (i.reserveSpace()) {
              let s = i.options,
                o,
                r = s.threshold,
                a,
                n;
              if (e.hasVisibleSeries = !0, e.positiveValuesOnly && 0 >= (r || 0) && (r = void 0), e.isXAxis) (o = i.xData) && o.length && (o = e.logarithmic ? o.filter(t => t > 0) : o, a = (t = i.getXExtremes(o)).min, n = t.max, T(a) || a instanceof Date || (o = o.filter(T), a = (t = i.getXExtremes(o)).min, n = t.max), o.length && (e.dataMin = Math.min(D(e.dataMin, a), a), e.dataMax = Math.max(D(e.dataMax, n), n)));else {
                let t = i.applyExtremes();
                T(t.dataMin) && (a = t.dataMin, e.dataMin = Math.min(D(e.dataMin, a), a)), T(t.dataMax) && (n = t.dataMax, e.dataMax = Math.max(D(e.dataMax, n), n)), y(r) && (e.threshold = r), (!s.softThreshold || e.positiveValuesOnly) && (e.softThreshold = !1);
              }
            }
          });
        }), S(this, "afterGetSeriesExtremes");
      }
      translate(t, e, i, s, o, r) {
        let a = this.linkedParent || this,
          n = s && a.old ? a.old.min : a.min;
        if (!T(n)) return NaN;
        let l = a.minPixelPadding,
          h = (a.isOrdinal || a.brokenAxis?.hasBreaks || a.logarithmic && o) && a.lin2val,
          d = 1,
          c = 0,
          p = s && a.old ? a.old.transA : a.transA,
          u = 0;
        return p || (p = a.transA), i && (d *= -1, c = a.len), a.reversed && (d *= -1, c -= d * (a.sector || a.len)), e ? (u = (t = t * d + c - l) / p + n, h && (u = a.lin2val(u))) : (h && (t = a.val2lin(t)), u = d * (t - n) * p + c + d * l + (T(r) ? p * r : 0), a.isRadial || (u = x(u))), u;
      }
      toPixels(t, e) {
        return this.translate(t, !1, !this.horiz, void 0, !0) + (e ? 0 : this.pos);
      }
      toValue(t, e) {
        return this.translate(t - (e ? 0 : this.pos), !0, !this.horiz, void 0, !0);
      }
      getPlotLinePath(t) {
        let e = this,
          i = e.chart,
          s = e.left,
          o = e.top,
          r = t.old,
          a = t.value,
          n = t.lineWidth,
          l = r && i.oldChartHeight || i.chartHeight,
          h = r && i.oldChartWidth || i.chartWidth,
          d = e.transB,
          c = t.translatedValue,
          p = t.force,
          u,
          g,
          f,
          x,
          y;
        function b(t, e, i) {
          return "pass" !== p && (t < e || t > i) && (p ? t = m(t, e, i) : y = !0), t;
        }
        let v = {
          value: a,
          lineWidth: n,
          old: r,
          force: p,
          acrossPanes: t.acrossPanes,
          translatedValue: c
        };
        return S(this, "getPlotLinePath", v, function (t) {
          u = f = (c = m(c = D(c, e.translate(a, void 0, void 0, r)), -1e9, 1e9)) + d, g = x = l - c - d, T(c) ? e.horiz ? (g = o, x = l - e.bottom + (e.options.isInternal ? 0 : i.scrollablePixelsY || 0), u = f = b(u, s, s + e.width)) : (u = s, f = h - e.right + (i.scrollablePixelsX || 0), g = x = b(g, o, o + e.height)) : (y = !0, p = !1), t.path = y && !p ? void 0 : i.renderer.crispLine([["M", u, g], ["L", f, x]], n || 1);
        }), v.path;
      }
      getLinearTickPositions(t, e, i) {
        let s, o, r;
        let a = x(Math.floor(e / t) * t),
          n = x(Math.ceil(i / t) * t),
          l = [];
        if (x(a + t) === a && (r = 20), this.single) return [e];
        for (s = a; s <= n && (l.push(s), (s = x(s + t, r)) !== o);) o = s;
        return l;
      }
      getMinorTickInterval() {
        let {
          minorTicks: t,
          minorTickInterval: e
        } = this.options;
        return !0 === t ? D(e, "auto") : !1 !== t ? e : void 0;
      }
      getMinorTickPositions() {
        let t = this.options,
          e = this.tickPositions,
          i = this.minorTickInterval,
          s = this.pointRangePadding || 0,
          o = (this.min || 0) - s,
          r = (this.max || 0) + s,
          a = r - o,
          n = [],
          l;
        if (a && a / i < this.len / 3) {
          let s = this.logarithmic;
          if (s) this.paddedTicks.forEach(function (t, e, o) {
            e && n.push.apply(n, s.getLogTickPositions(i, o[e - 1], o[e], !0));
          });else if (this.dateTime && "auto" === this.getMinorTickInterval()) n = n.concat(this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(i), o, r, t.startOfWeek));else for (l = o + (e[0] - o) % i; l <= r && l !== n[0]; l += i) n.push(l);
        }
        return 0 !== n.length && this.trimTicks(n), n;
      }
      adjustForMinRange() {
        let t = this.options,
          e = this.logarithmic,
          {
            max: i,
            min: s,
            minRange: o
          } = this,
          r,
          a,
          n,
          l;
        this.isXAxis && void 0 === o && !e && (o = y(t.min) || y(t.max) || y(t.floor) || y(t.ceiling) ? null : Math.min(5 * (w(this.series.map(t => (t.xIncrement ? t.xData?.slice(0, 2) : t.xData) || [])) || 0), this.dataMax - this.dataMin)), T(i) && T(s) && T(o) && i - s < o && (a = this.dataMax - this.dataMin >= o, r = (o - i + s) / 2, n = [s - r, D(t.min, s - r)], a && (n[2] = e ? e.log2lin(this.dataMin) : this.dataMin), l = [(s = g(n)) + o, D(t.max, s + o)], a && (l[2] = e ? e.log2lin(this.dataMax) : this.dataMax), (i = f(l)) - s < o && (n[0] = i - o, n[1] = D(t.min, i - o), s = g(n))), this.minRange = o, this.min = s, this.max = i;
      }
      getClosest() {
        let t, e;
        if (this.categories) e = 1;else {
          let i = [];
          this.series.forEach(function (t) {
            let s = t.closestPointRange;
            t.xData?.length === 1 ? i.push(t.xData[0]) : !t.noSharedTooltip && y(s) && t.reserveSpace() && (e = y(e) ? Math.min(e, s) : s);
          }), i.length && (i.sort((t, e) => t - e), t = w([i]));
        }
        return t && e ? Math.min(t, e) : t || e;
      }
      nameToX(t) {
        let e = A(this.options.categories),
          i = e ? this.categories : this.names,
          s = t.options.x,
          o;
        return t.series.requireSorting = !1, y(s) || (s = this.uniqueNames && i ? e ? i.indexOf(t.name) : D(i.keys[t.name], -1) : t.series.autoIncrement()), -1 === s ? !e && i && (o = i.length) : o = s, void 0 !== o ? (this.names[o] = t.name, this.names.keys[t.name] = o) : t.x && (o = t.x), o;
      }
      updateNames() {
        let t = this,
          e = this.names;
        e.length > 0 && (Object.keys(e.keys).forEach(function (t) {
          delete e.keys[t];
        }), e.length = 0, this.minRange = this.userMinRange, (this.series || []).forEach(e => {
          e.xIncrement = null, (!e.points || e.isDirtyData) && (t.max = Math.max(t.max, e.xData.length - 1), e.processData(), e.generatePoints()), e.data.forEach(function (i, s) {
            let o;
            i?.options && void 0 !== i.name && void 0 !== (o = t.nameToX(i)) && o !== i.x && (i.x = o, e.xData[s] = o);
          });
        }));
      }
      setAxisTranslation() {
        let t = this,
          e = t.max - t.min,
          i = t.linkedParent,
          s = !!t.categories,
          o = t.isXAxis,
          r = t.axisPointRange || 0,
          a,
          n = 0,
          l = 0,
          h,
          d = t.transA;
        (o || s || r) && (a = t.getClosest(), i ? (n = i.minPointOffset, l = i.pointRangePadding) : t.series.forEach(function (e) {
          let i = s ? 1 : o ? D(e.options.pointRange, a, 0) : t.axisPointRange || 0,
            h = e.options.pointPlacement;
          if (r = Math.max(r, i), !t.single || s) {
            let t = e.is("xrange") ? !o : o;
            n = Math.max(n, t && P(h) ? 0 : i / 2), l = Math.max(l, t && "on" === h ? 0 : i);
          }
        }), h = t.ordinal && t.ordinal.slope && a ? t.ordinal.slope / a : 1, t.minPointOffset = n *= h, t.pointRangePadding = l *= h, t.pointRange = Math.min(r, t.single && s ? 1 : e), o && a && (t.closestPointRange = a)), t.translationSlope = t.transA = d = t.staticScale || t.len / (e + l || 1), t.transB = t.horiz ? t.left : t.bottom, t.minPixelPadding = d * n, S(this, "afterSetAxisTranslation");
      }
      minFromRange() {
        let {
          max: t,
          min: e
        } = this;
        return T(t) && T(e) && t - e || void 0;
      }
      setTickInterval(t) {
        let {
            categories: e,
            chart: i,
            dataMax: s,
            dataMin: o,
            dateTime: r,
            isXAxis: a,
            logarithmic: n,
            options: l,
            softThreshold: h
          } = this,
          d = T(this.threshold) ? this.threshold : void 0,
          c = this.minRange || 0,
          {
            ceiling: p,
            floor: u,
            linkedTo: g,
            softMax: f,
            softMin: m
          } = l,
          b = T(g) && i[this.coll]?.[g],
          v = l.tickPixelInterval,
          C = l.maxPadding,
          w = l.minPadding,
          k = 0,
          A,
          P = T(l.tickInterval) && l.tickInterval >= 0 ? l.tickInterval : void 0,
          L,
          j,
          O,
          E;
        if (r || e || b || this.getTickAmount(), O = D(this.userMin, l.min), E = D(this.userMax, l.max), b ? (this.linkedParent = b, A = b.getExtremes(), this.min = D(A.min, A.dataMin), this.max = D(A.max, A.dataMax), this.type !== b.type && M(11, !0, i)) : (h && y(d) && T(s) && T(o) && (o >= d ? (L = d, w = 0) : s <= d && (j = d, C = 0)), this.min = D(O, L, o), this.max = D(E, j, s)), T(this.max) && T(this.min) && (n && (this.positiveValuesOnly && !t && 0 >= Math.min(this.min, D(o, this.min)) && M(10, !0, i), this.min = x(n.log2lin(this.min), 16), this.max = x(n.log2lin(this.max), 16)), this.range && T(o) && (this.userMin = this.min = O = Math.max(o, this.minFromRange() || 0), this.userMax = E = this.max, this.range = void 0)), S(this, "foundExtremes"), this.adjustForMinRange(), T(this.min) && T(this.max)) {
          if (!T(this.userMin) && T(m) && m < this.min && (this.min = O = m), !T(this.userMax) && T(f) && f > this.max && (this.max = E = f), e || this.axisPointRange || this.stacking?.usePercentage || b || !(k = this.max - this.min) || (!y(O) && w && (this.min -= k * w), y(E) || !C || (this.max += k * C)), !T(this.userMin) && T(u) && (this.min = Math.max(this.min, u)), !T(this.userMax) && T(p) && (this.max = Math.min(this.max, p)), h && T(o) && T(s)) {
            let t = d || 0;
            !y(O) && this.min < t && o >= t ? this.min = l.minRange ? Math.min(t, this.max - c) : t : !y(E) && this.max > t && s <= t && (this.max = l.minRange ? Math.max(t, this.min + c) : t);
          }
          !i.polar && this.min > this.max && (y(l.min) ? this.max = this.min : y(l.max) && (this.min = this.max)), k = this.max - this.min;
        }
        if (this.min !== this.max && T(this.min) && T(this.max) ? b && !P && v === b.options.tickPixelInterval ? this.tickInterval = P = b.tickInterval : this.tickInterval = D(P, this.tickAmount ? k / Math.max(this.tickAmount - 1, 1) : void 0, e ? 1 : k * v / Math.max(this.len, v)) : this.tickInterval = 1, a && !t) {
          let t = this.min !== this.old?.min || this.max !== this.old?.max;
          this.series.forEach(function (e) {
            e.forceCrop = e.forceCropping?.(), e.processData(t);
          }), S(this, "postProcessData", {
            hasExtremesChanged: t
          });
        }
        this.setAxisTranslation(), S(this, "initialAxisTranslation"), this.pointRange && !P && (this.tickInterval = Math.max(this.pointRange, this.tickInterval));
        let I = D(l.minTickInterval, r && !this.series.some(t => t.noSharedTooltip) ? this.closestPointRange : 0);
        !P && this.tickInterval < I && (this.tickInterval = I), r || n || P || (this.tickInterval = R(this, this.tickInterval)), this.tickAmount || (this.tickInterval = this.unsquish()), this.setTickPositions();
      }
      setTickPositions() {
        let t = this.options,
          e = t.tickPositions,
          i = t.tickPositioner,
          s = this.getMinorTickInterval(),
          o = !this.isPanning,
          r = o && t.startOnTick,
          a = o && t.endOnTick,
          n = [],
          l;
        if (this.tickmarkOffset = this.categories && "between" === t.tickmarkPlacement && 1 === this.tickInterval ? .5 : 0, this.single = this.min === this.max && y(this.min) && !this.tickAmount && (this.min % 1 == 0 || !1 !== t.allowDecimals), e) n = e.slice();else if (T(this.min) && T(this.max)) {
          if (!this.ordinal?.positions && (this.max - this.min) / this.tickInterval > Math.max(2 * this.len, 200)) n = [this.min, this.max], M(19, !1, this.chart);else if (this.dateTime) n = this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(this.tickInterval, t.units), this.min, this.max, t.startOfWeek, this.ordinal?.positions, this.closestPointRange, !0);else if (this.logarithmic) n = this.logarithmic.getLogTickPositions(this.tickInterval, this.min, this.max);else {
            let t = this.tickInterval,
              e = t;
            for (; e <= 2 * t;) if (n = this.getLinearTickPositions(this.tickInterval, this.min, this.max), this.tickAmount && n.length > this.tickAmount) this.tickInterval = R(this, e *= 1.1);else break;
          }
          n.length > this.len && (n = [n[0], n[n.length - 1]])[0] === n[1] && (n.length = 1), i && (this.tickPositions = n, (l = i.apply(this, [this.min, this.max])) && (n = l));
        }
        this.tickPositions = n, this.minorTickInterval = "auto" === s && this.tickInterval ? this.tickInterval / t.minorTicksPerMajor : s, this.paddedTicks = n.slice(0), this.trimTicks(n, r, a), !this.isLinked && T(this.min) && T(this.max) && (this.single && n.length < 2 && !this.categories && !this.series.some(t => t.is("heatmap") && "between" === t.options.pointPlacement) && (this.min -= .5, this.max += .5), e || l || this.adjustTickAmount()), S(this, "afterSetTickPositions");
      }
      trimTicks(t, e, i) {
        let s = t[0],
          o = t[t.length - 1],
          r = !this.isOrdinal && this.minPointOffset || 0;
        if (S(this, "trimTicks"), !this.isLinked) {
          if (e && s !== -1 / 0) this.min = s;else for (; this.min - r > t[0];) t.shift();
          if (i) this.max = o;else for (; this.max + r < t[t.length - 1];) t.pop();
          0 === t.length && y(s) && !this.options.tickPositions && t.push((o + s) / 2);
        }
      }
      alignToOthers() {
        let t;
        let e = this,
          i = e.chart,
          s = [this],
          o = e.options,
          r = i.options.chart,
          a = "yAxis" === this.coll && r.alignThresholds,
          n = [];
        if (e.thresholdAlignment = void 0, (!1 !== r.alignTicks && o.alignTicks || a) && !1 !== o.startOnTick && !1 !== o.endOnTick && !e.logarithmic) {
          let o = t => {
              let {
                horiz: e,
                options: i
              } = t;
              return [e ? i.left : i.top, i.width, i.height, i.pane].join(",");
            },
            r = o(this);
          i[this.coll].forEach(function (i) {
            let {
              series: a
            } = i;
            a.length && a.some(t => t.visible) && i !== e && o(i) === r && (t = !0, s.push(i));
          });
        }
        if (t && a) {
          s.forEach(t => {
            let i = t.getThresholdAlignment(e);
            T(i) && n.push(i);
          });
          let t = n.length > 1 ? n.reduce((t, e) => t += e, 0) / n.length : void 0;
          s.forEach(e => {
            e.thresholdAlignment = t;
          });
        }
        return t;
      }
      getThresholdAlignment(t) {
        if ((!T(this.dataMin) || this !== t && this.series.some(t => t.isDirty || t.isDirtyData)) && this.getSeriesExtremes(), T(this.threshold)) {
          let t = m((this.threshold - (this.dataMin || 0)) / ((this.dataMax || 0) - (this.dataMin || 0)), 0, 1);
          return this.options.reversed && (t = 1 - t), t;
        }
      }
      getTickAmount() {
        let t = this.options,
          e = t.tickPixelInterval,
          i = t.tickAmount;
        y(t.tickInterval) || i || !(this.len < e) || this.isRadial || this.logarithmic || !t.startOnTick || !t.endOnTick || (i = 2), !i && this.alignToOthers() && (i = Math.ceil(this.len / e) + 1), i < 4 && (this.finalTickAmt = i, i = 5), this.tickAmount = i;
      }
      adjustTickAmount() {
        let t = this,
          {
            finalTickAmt: e,
            max: i,
            min: s,
            options: o,
            tickPositions: r,
            tickAmount: a,
            thresholdAlignment: n
          } = t,
          l = r?.length,
          h = D(t.threshold, t.softThreshold ? 0 : null),
          d,
          c,
          p = t.tickInterval,
          u,
          g = () => r.push(x(r[r.length - 1] + p)),
          f = () => r.unshift(x(r[0] - p));
        if (T(n) && (u = n < .5 ? Math.ceil(n * (a - 1)) : Math.floor(n * (a - 1)), o.reversed && (u = a - 1 - u)), t.hasData() && T(s) && T(i)) {
          let n = () => {
            t.transA *= (l - 1) / (a - 1), t.min = o.startOnTick ? r[0] : Math.min(s, r[0]), t.max = o.endOnTick ? r[r.length - 1] : Math.max(i, r[r.length - 1]);
          };
          if (T(u) && T(t.threshold)) {
            for (; r[u] !== h || r.length !== a || r[0] > s || r[r.length - 1] < i;) {
              for (r.length = 0, r.push(t.threshold); r.length < a;) void 0 === r[u] || r[u] > t.threshold ? f() : g();
              if (p > 8 * t.tickInterval) break;
              p *= 2;
            }
            n();
          } else if (l < a) {
            for (; r.length < a;) r.length % 2 || s === h ? g() : f();
            n();
          }
          if (y(e)) {
            for (c = d = r.length; c--;) (3 === e && c % 2 == 1 || e <= 2 && c > 0 && c < d - 1) && r.splice(c, 1);
            t.finalTickAmt = void 0;
          }
        }
      }
      setScale() {
        let {
            coll: t,
            stacking: e
          } = this,
          i = !1,
          s = !1;
        this.series.forEach(t => {
          i = i || t.isDirtyData || t.isDirty, s = s || t.xAxis && t.xAxis.isDirty || !1;
        }), this.setAxisSize();
        let o = this.len !== (this.old && this.old.len);
        o || i || s || this.isLinked || this.forceRedraw || this.userMin !== (this.old && this.old.userMin) || this.userMax !== (this.old && this.old.userMax) || this.alignToOthers() ? (e && "yAxis" === t && e.buildStacks(), this.forceRedraw = !1, this.userMinRange || (this.minRange = void 0), this.getSeriesExtremes(), this.setTickInterval(), e && "xAxis" === t && e.buildStacks(), this.isDirty || (this.isDirty = o || this.min !== this.old?.min || this.max !== this.old?.max)) : e && e.cleanStacks(), i && delete this.allExtremes, S(this, "afterSetScale");
      }
      setExtremes(t, e, i = !0, s, o) {
        this.series.forEach(t => {
          delete t.kdTree;
        }), S(this, "setExtremes", o = C(o, {
          min: t,
          max: e
        }), t => {
          this.userMin = t.min, this.userMax = t.max, this.eventArgs = t, i && this.chart.redraw(s);
        });
      }
      setAxisSize() {
        let t = this.chart,
          e = this.options,
          i = e.offsets || [0, 0, 0, 0],
          s = this.horiz,
          o = this.width = Math.round(E(D(e.width, t.plotWidth - i[3] + i[1]), t.plotWidth)),
          r = this.height = Math.round(E(D(e.height, t.plotHeight - i[0] + i[2]), t.plotHeight)),
          a = this.top = Math.round(E(D(e.top, t.plotTop + i[0]), t.plotHeight, t.plotTop)),
          n = this.left = Math.round(E(D(e.left, t.plotLeft + i[3]), t.plotWidth, t.plotLeft));
        this.bottom = t.chartHeight - r - a, this.right = t.chartWidth - o - n, this.len = Math.max(s ? o : r, 0), this.pos = s ? n : a;
      }
      getExtremes() {
        let t = this.logarithmic;
        return {
          min: t ? x(t.lin2log(this.min)) : this.min,
          max: t ? x(t.lin2log(this.max)) : this.max,
          dataMin: this.dataMin,
          dataMax: this.dataMax,
          userMin: this.userMin,
          userMax: this.userMax
        };
      }
      getThreshold(t) {
        let e = this.logarithmic,
          i = e ? e.lin2log(this.min) : this.min,
          s = e ? e.lin2log(this.max) : this.max;
        return null === t || t === -1 / 0 ? t = i : t === 1 / 0 ? t = s : i > t ? t = i : s < t && (t = s), this.translate(t, 0, 1, 0, 1);
      }
      autoLabelAlign(t) {
        let e = (D(t, 0) - 90 * this.side + 720) % 360,
          i = {
            align: "center"
          };
        return S(this, "autoLabelAlign", i, function (t) {
          e > 15 && e < 165 ? t.align = "right" : e > 195 && e < 345 && (t.align = "left");
        }), i.align;
      }
      tickSize(t) {
        let e = this.options,
          i = D(e["tick" === t ? "tickWidth" : "minorTickWidth"], "tick" === t && this.isXAxis && !this.categories ? 1 : 0),
          s = e["tick" === t ? "tickLength" : "minorTickLength"],
          o;
        i && s && ("inside" === e[t + "Position"] && (s = -s), o = [s, i]);
        let r = {
          tickSize: o
        };
        return S(this, "afterTickSize", r), r.tickSize;
      }
      labelMetrics() {
        let t = this.chart.renderer,
          e = this.ticks,
          i = e[Object.keys(e)[0]] || {};
        return this.chart.renderer.fontMetrics(i.label || i.movedLabel || t.box);
      }
      unsquish() {
        let t = this.options.labels,
          e = t.padding || 0,
          i = this.horiz,
          s = this.tickInterval,
          o = this.len / (((this.categories ? 1 : 0) + this.max - this.min) / s),
          r = t.rotation,
          a = x(.8 * this.labelMetrics().h),
          n = Math.max(this.max - this.min, 0),
          l = function (t) {
            let i = (t + 2 * e) / (o || 1);
            return (i = i > 1 ? Math.ceil(i) : 1) * s > n && t !== 1 / 0 && o !== 1 / 0 && n && (i = Math.ceil(n / s)), x(i * s);
          },
          h = s,
          d,
          c = Number.MAX_VALUE,
          p;
        if (i) {
          if (!t.staggerLines && (T(r) ? p = [r] : o < t.autoRotationLimit && (p = t.autoRotation)), p) {
            let t, e;
            for (let i of p) (i === r || i && i >= -90 && i <= 90) && (e = (t = l(Math.abs(a / Math.sin(u * i)))) + Math.abs(i / 360)) < c && (c = e, d = i, h = t);
          }
        } else h = l(.75 * a);
        return this.autoRotation = p, this.labelRotation = D(d, T(r) ? r : 0), t.step ? s : h;
      }
      getSlotWidth(t) {
        let e = this.chart,
          i = this.horiz,
          s = this.options.labels,
          o = Math.max(this.tickPositions.length - (this.categories ? 0 : 1), 1),
          r = e.margin[3];
        if (t && T(t.slotWidth)) return t.slotWidth;
        if (i && s.step < 2) return s.rotation ? 0 : (this.staggerLines || 1) * this.len / o;
        if (!i) {
          let t = s.style.width;
          if (void 0 !== t) return parseInt(String(t), 10);
          if (r) return r - e.spacing[3];
        }
        return .33 * e.chartWidth;
      }
      renderUnsquish() {
        let t = this.chart,
          e = t.renderer,
          i = this.tickPositions,
          s = this.ticks,
          o = this.options.labels,
          r = o.style,
          a = this.horiz,
          n = this.getSlotWidth(),
          l = Math.max(1, Math.round(n - (a ? 2 * (o.padding || 0) : o.distance || 0))),
          h = {},
          d = this.labelMetrics(),
          c = r.textOverflow,
          p,
          u,
          g = 0,
          f,
          m;
        if (P(o.rotation) || (h.rotation = o.rotation || 0), i.forEach(function (t) {
          let e = s[t];
          e.movedLabel && e.replaceMovedLabel(), e && e.label && e.label.textPxLength > g && (g = e.label.textPxLength);
        }), this.maxLabelLength = g, this.autoRotation) g > l && g > d.h ? h.rotation = this.labelRotation : this.labelRotation = 0;else if (n && (p = l, !c)) for (u = "clip", m = i.length; !a && m--;) (f = s[i[m]].label) && ("ellipsis" === f.styles.textOverflow ? f.css({
          textOverflow: "clip"
        }) : f.textPxLength > n && f.css({
          width: n + "px"
        }), f.getBBox().height > this.len / i.length - (d.h - d.f) && (f.specificTextOverflow = "ellipsis"));
        h.rotation && (p = g > .5 * t.chartHeight ? .33 * t.chartHeight : g, c || (u = "ellipsis")), this.labelAlign = o.align || this.autoLabelAlign(this.labelRotation), this.labelAlign && (h.align = this.labelAlign), i.forEach(function (t) {
          let e = s[t],
            i = e && e.label,
            o = r.width,
            a = {};
          i && (i.attr(h), e.shortenLabel ? e.shortenLabel() : p && !o && "nowrap" !== r.whiteSpace && (p < i.textPxLength || "SPAN" === i.element.tagName) ? (a.width = p + "px", c || (a.textOverflow = i.specificTextOverflow || u), i.css(a)) : !i.styles.width || a.width || o || i.css({
            width: null
          }), delete i.specificTextOverflow, e.rotation = h.rotation);
        }, this), this.tickRotCorr = e.rotCorr(d.b, this.labelRotation || 0, 0 !== this.side);
      }
      hasData() {
        return this.series.some(function (t) {
          return t.hasData();
        }) || this.options.showEmpty && y(this.min) && y(this.max);
      }
      addTitle(t) {
        let e;
        let i = this.chart.renderer,
          s = this.horiz,
          o = this.opposite,
          r = this.options.title,
          a = this.chart.styledMode;
        this.axisTitle || ((e = r.textAlign) || (e = (s ? {
          low: "left",
          middle: "center",
          high: "right"
        } : {
          low: o ? "right" : "left",
          middle: "center",
          high: o ? "left" : "right"
        })[r.align]), this.axisTitle = i.text(r.text || "", 0, 0, r.useHTML).attr({
          zIndex: 7,
          rotation: r.rotation || 0,
          align: e
        }).addClass("highcharts-axis-title"), a || this.axisTitle.css(L(r.style)), this.axisTitle.add(this.axisGroup), this.axisTitle.isNew = !0), a || r.style.width || this.isRadial || this.axisTitle.css({
          width: this.len + "px"
        }), this.axisTitle[t ? "show" : "hide"](t);
      }
      generateTick(t) {
        let e = this.ticks;
        e[t] ? e[t].addLabel() : e[t] = new a(this, t);
      }
      createGroups() {
        let {
            axisParent: t,
            chart: e,
            coll: i,
            options: s
          } = this,
          o = e.renderer,
          r = (e, r, a) => o.g(e).attr({
            zIndex: a
          }).addClass(`highcharts-${i.toLowerCase()}${r} ` + (this.isRadial ? `highcharts-radial-axis${r} ` : "") + (s.className || "")).add(t);
        this.axisGroup || (this.gridGroup = r("grid", "-grid", s.gridZIndex), this.axisGroup = r("axis", "", s.zIndex), this.labelGroup = r("axis-labels", "-labels", s.labels.zIndex));
      }
      getOffset() {
        let t = this,
          {
            chart: e,
            horiz: i,
            options: s,
            side: o,
            ticks: r,
            tickPositions: a,
            coll: n
          } = t,
          l = e.inverted && !t.isZAxis ? [1, 0, 3, 2][o] : o,
          h = t.hasData(),
          d = s.title,
          c = s.labels,
          p = T(s.crossing),
          u = e.axisOffset,
          g = e.clipOffset,
          f = [-1, 1, 1, -1][o],
          m,
          x = 0,
          b,
          v = 0,
          M = 0,
          C,
          w;
        if (t.showAxis = m = h || s.showEmpty, t.staggerLines = t.horiz && c.staggerLines || void 0, t.createGroups(), h || t.isLinked ? (a.forEach(function (e) {
          t.generateTick(e);
        }), t.renderUnsquish(), t.reserveSpaceDefault = 0 === o || 2 === o || {
          1: "left",
          3: "right"
        }[o] === t.labelAlign, D(c.reserveSpace, !p && null, "center" === t.labelAlign || null, t.reserveSpaceDefault) && a.forEach(function (t) {
          M = Math.max(r[t].getLabelSize(), M);
        }), t.staggerLines && (M *= t.staggerLines), t.labelOffset = M * (t.opposite ? -1 : 1)) : O(r, function (t, e) {
          t.destroy(), delete r[e];
        }), d?.text && !1 !== d.enabled && (t.addTitle(m), m && !p && !1 !== d.reserveSpace && (t.titleOffset = x = t.axisTitle.getBBox()[i ? "height" : "width"], v = y(b = d.offset) ? 0 : D(d.margin, i ? 5 : 10))), t.renderLine(), t.offset = f * D(s.offset, u[o] ? u[o] + (s.margin || 0) : 0), t.tickRotCorr = t.tickRotCorr || {
          x: 0,
          y: 0
        }, w = 0 === o ? -t.labelMetrics().h : 2 === o ? t.tickRotCorr.y : 0, C = Math.abs(M) + v, M && (C -= w, C += f * (i ? D(c.y, t.tickRotCorr.y + f * c.distance) : D(c.x, f * c.distance))), t.axisTitleMargin = D(b, C), t.getMaxLabelDimensions && (t.maxLabelDimensions = t.getMaxLabelDimensions(r, a)), "colorAxis" !== n && g) {
          let e = this.tickSize("tick");
          u[o] = Math.max(u[o], (t.axisTitleMargin || 0) + x + f * t.offset, C, a && a.length && e ? e[0] + f * t.offset : 0);
          let i = !t.axisLine || s.offset ? 0 : t.axisLine.strokeWidth() / 2;
          g[l] = Math.max(g[l], i);
        }
        S(this, "afterGetOffset");
      }
      getLinePath(t) {
        let e = this.chart,
          i = this.opposite,
          s = this.offset,
          o = this.horiz,
          r = this.left + (i ? this.width : 0) + s,
          a = e.chartHeight - this.bottom - (i ? this.height : 0) + s;
        return i && (t *= -1), e.renderer.crispLine([["M", o ? this.left : r, o ? a : this.top], ["L", o ? e.chartWidth - this.right : r, o ? a : e.chartHeight - this.bottom]], t);
      }
      renderLine() {
        this.axisLine || (this.axisLine = this.chart.renderer.path().addClass("highcharts-axis-line").add(this.axisGroup), this.chart.styledMode || this.axisLine.attr({
          stroke: this.options.lineColor,
          "stroke-width": this.options.lineWidth,
          zIndex: 7
        }));
      }
      getTitlePosition(t) {
        let e = this.horiz,
          i = this.left,
          s = this.top,
          o = this.len,
          r = this.options.title,
          a = e ? i : s,
          n = this.opposite,
          l = this.offset,
          h = r.x,
          d = r.y,
          c = this.chart.renderer.fontMetrics(t),
          p = t ? Math.max(t.getBBox(!1, 0).height - c.h - 1, 0) : 0,
          u = {
            low: a + (e ? 0 : o),
            middle: a + o / 2,
            high: a + (e ? o : 0)
          }[r.align],
          g = (e ? s + this.height : i) + (e ? 1 : -1) * (n ? -1 : 1) * (this.axisTitleMargin || 0) + [-p, p, c.f, -p][this.side],
          f = {
            x: e ? u + h : g + (n ? this.width : 0) + l + h,
            y: e ? g + d - (n ? this.height : 0) + l : u + d
          };
        return S(this, "afterGetTitlePosition", {
          titlePosition: f
        }), f;
      }
      renderMinorTick(t, e) {
        let i = this.minorTicks;
        i[t] || (i[t] = new a(this, t, "minor")), e && i[t].isNew && i[t].render(null, !0), i[t].render(null, !1, 1);
      }
      renderTick(t, e, i) {
        let s = this.isLinked,
          o = this.ticks;
        (!s || t >= this.min && t <= this.max || this.grid && this.grid.isColumn) && (o[t] || (o[t] = new a(this, t)), i && o[t].isNew && o[t].render(e, !0, -1), o[t].render(e));
      }
      render() {
        let t, e;
        let i = this,
          s = i.chart,
          o = i.logarithmic,
          n = s.renderer,
          h = i.options,
          d = i.isLinked,
          c = i.tickPositions,
          p = i.axisTitle,
          u = i.ticks,
          g = i.minorTicks,
          f = i.alternateBands,
          m = h.stackLabels,
          x = h.alternateGridColor,
          y = h.crossing,
          b = i.tickmarkOffset,
          v = i.axisLine,
          M = i.showAxis,
          C = l(n.globalAnimation);
        if (i.labelEdge.length = 0, i.overlap = !1, [u, g, f].forEach(function (t) {
          O(t, function (t) {
            t.isActive = !1;
          });
        }), T(y)) {
          let t = this.isXAxis ? s.yAxis[0] : s.xAxis[0],
            e = [1, -1, -1, 1][this.side];
          if (t) {
            let s = t.toPixels(y, !0);
            i.horiz && (s = t.len - s), i.offset = e * s;
          }
        }
        if (i.hasData() || d) {
          let n = i.chart.hasRendered && i.old && T(i.old.min);
          i.minorTickInterval && !i.categories && i.getMinorTickPositions().forEach(function (t) {
            i.renderMinorTick(t, n);
          }), c.length && (c.forEach(function (t, e) {
            i.renderTick(t, e, n);
          }), b && (0 === i.min || i.single) && (u[-1] || (u[-1] = new a(i, -1, null, !0)), u[-1].render(-1))), x && c.forEach(function (a, n) {
            e = void 0 !== c[n + 1] ? c[n + 1] + b : i.max - b, n % 2 == 0 && a < i.max && e <= i.max + (s.polar ? -b : b) && (f[a] || (f[a] = new r.PlotLineOrBand(i, {})), t = a + b, f[a].options = {
              from: o ? o.lin2log(t) : t,
              to: o ? o.lin2log(e) : e,
              color: x,
              className: "highcharts-alternate-grid"
            }, f[a].render(), f[a].isActive = !0);
          }), i._addedPlotLB || (i._addedPlotLB = !0, (h.plotLines || []).concat(h.plotBands || []).forEach(function (t) {
            i.addPlotBandOrLine(t);
          }));
        }
        [u, g, f].forEach(function (t) {
          let e = [],
            i = C.duration;
          O(t, function (t, i) {
            t.isActive || (t.render(i, !1, 0), t.isActive = !1, e.push(i));
          }), z(function () {
            let i = e.length;
            for (; i--;) t[e[i]] && !t[e[i]].isActive && (t[e[i]].destroy(), delete t[e[i]]);
          }, t !== f && s.hasRendered && i ? i : 0);
        }), v && (v[v.isPlaced ? "animate" : "attr"]({
          d: this.getLinePath(v.strokeWidth())
        }), v.isPlaced = !0, v[M ? "show" : "hide"](M)), p && M && (p[p.isNew ? "attr" : "animate"](i.getTitlePosition(p)), p.isNew = !1), m && m.enabled && i.stacking && i.stacking.renderStackTotals(), i.old = {
          len: i.len,
          max: i.max,
          min: i.min,
          transA: i.transA,
          userMax: i.userMax,
          userMin: i.userMin
        }, i.isDirty = !1, S(this, "afterRender");
      }
      redraw() {
        this.visible && (this.render(), this.plotLinesAndBands.forEach(function (t) {
          t.render();
        })), this.series.forEach(function (t) {
          t.isDirty = !0;
        });
      }
      getKeepProps() {
        return this.keepProps || N.keepProps;
      }
      destroy(t) {
        let e = this,
          i = e.plotLinesAndBands,
          s = this.eventOptions;
        if (S(this, "destroy", {
          keepEvents: t
        }), t || I(e), [e.ticks, e.minorTicks, e.alternateBands].forEach(function (t) {
          b(t);
        }), i) {
          let t = i.length;
          for (; t--;) i[t].destroy();
        }
        for (let t in ["axisLine", "axisTitle", "axisGroup", "gridGroup", "labelGroup", "cross", "scrollbar"].forEach(function (t) {
          e[t] && (e[t] = e[t].destroy());
        }), e.plotLinesAndBandsGroups) e.plotLinesAndBandsGroups[t] = e.plotLinesAndBandsGroups[t].destroy();
        O(e, function (t, i) {
          -1 === e.getKeepProps().indexOf(i) && delete e[i];
        }), this.eventOptions = s;
      }
      drawCrosshair(t, e) {
        let s = this.crosshair,
          o = D(s && s.snap, !0),
          r = this.chart,
          a,
          n,
          l,
          h = this.cross,
          d;
        if (S(this, "drawCrosshair", {
          e: t,
          point: e
        }), t || (t = this.cross && this.cross.e), s && !1 !== (y(e) || !o)) {
          if (o ? y(e) && (n = D("colorAxis" !== this.coll ? e.crosshairPos : null, this.isXAxis ? e.plotX : this.len - e.plotY)) : n = t && (this.horiz ? t.chartX - this.pos : this.len - t.chartY + this.pos), y(n) && (d = {
            value: e && (this.isXAxis ? e.x : D(e.stackY, e.y)),
            translatedValue: n
          }, r.polar && C(d, {
            isCrosshair: !0,
            chartX: t && t.chartX,
            chartY: t && t.chartY,
            point: e
          }), a = this.getPlotLinePath(d) || null), !y(a)) {
            this.hideCrosshair();
            return;
          }
          l = this.categories && !this.isRadial, h || (this.cross = h = r.renderer.path().addClass("highcharts-crosshair highcharts-crosshair-" + (l ? "category " : "thin ") + (s.className || "")).attr({
            zIndex: D(s.zIndex, 2)
          }).add(), !r.styledMode && (h.attr({
            stroke: s.color || (l ? i.parse("#ccd3ff").setOpacity(.25).get() : "#cccccc"),
            "stroke-width": D(s.width, 1)
          }).css({
            "pointer-events": "none"
          }), s.dashStyle && h.attr({
            dashstyle: s.dashStyle
          }))), h.show().attr({
            d: a
          }), l && !s.width && h.attr({
            "stroke-width": this.transA
          }), this.cross.e = t;
        } else this.hideCrosshair();
        S(this, "afterDrawCrosshair", {
          e: t,
          point: e
        });
      }
      hideCrosshair() {
        this.cross && this.cross.hide(), S(this, "afterHideCrosshair");
      }
      update(t, e) {
        let i = this.chart;
        t = L(this.userOptions, t), this.destroy(!0), this.init(i, t), i.isDirtyBox = !0, D(e, !0) && i.redraw();
      }
      remove(t) {
        let e = this.chart,
          i = this.coll,
          s = this.series,
          o = s.length;
        for (; o--;) s[o] && s[o].remove(!1);
        v(e.axes, this), v(e[i] || [], this), e.orderItems(i), this.destroy(), e.isDirtyBox = !0, D(t, !0) && e.redraw();
      }
      setTitle(t, e) {
        this.update({
          title: t
        }, e);
      }
      setCategories(t, e) {
        this.update({
          categories: t
        }, e);
      }
    }
    return N.keepProps = ["coll", "extKey", "hcEvents", "len", "names", "series", "userMax", "userMin"], N;
  }), i(e, "Core/Axis/DateTimeAxis.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      addEvent: i,
      getMagnitude: s,
      normalizeTickInterval: o,
      timeUnits: r
    } = t;
    return function (t) {
      function e() {
        return this.chart.time.getTimeTicks.apply(this.chart.time, arguments);
      }
      function a() {
        if ("datetime" !== this.type) {
          this.dateTime = void 0;
          return;
        }
        this.dateTime || (this.dateTime = new n(this));
      }
      t.compose = function (t) {
        return t.keepProps.includes("dateTime") || (t.keepProps.push("dateTime"), t.prototype.getTimeTicks = e, i(t, "afterSetType", a)), t;
      };
      class n {
        constructor(t) {
          this.axis = t;
        }
        normalizeTimeTickInterval(t, e) {
          let i = e || [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2]], ["week", [1, 2]], ["month", [1, 2, 3, 4, 6]], ["year", null]],
            a = i[i.length - 1],
            n = r[a[0]],
            l = a[1],
            h;
          for (h = 0; h < i.length && (n = r[(a = i[h])[0]], l = a[1], !i[h + 1] || !(t <= (n * l[l.length - 1] + r[i[h + 1][0]]) / 2)); h++);
          n === r.year && t < 5 * n && (l = [1, 2, 5]);
          let d = o(t / n, l, "year" === a[0] ? Math.max(s(t / n), 1) : 1);
          return {
            unitRange: n,
            count: d,
            unitName: a[0]
          };
        }
        getXDateFormat(t, e) {
          let {
              axis: i
            } = this,
            s = i.chart.time;
          return i.closestPointRange ? s.getDateFormat(i.closestPointRange, t, i.options.startOfWeek, e) || s.resolveDTLFormat(e.year).main : s.resolveDTLFormat(e.day).main;
        }
      }
      t.Additions = n;
    }(e || (e = {})), e;
  }), i(e, "Core/Axis/LogarithmicAxis.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      addEvent: i,
      normalizeTickInterval: s,
      pick: o
    } = t;
    return function (t) {
      function e() {
        "logarithmic" !== this.type ? this.logarithmic = void 0 : this.logarithmic ?? (this.logarithmic = new a(this));
      }
      function r() {
        let t = this.logarithmic;
        t && (this.lin2val = function (e) {
          return t.lin2log(e);
        }, this.val2lin = function (e) {
          return t.log2lin(e);
        });
      }
      t.compose = function (t) {
        return t.keepProps.includes("logarithmic") || (t.keepProps.push("logarithmic"), i(t, "afterSetType", e), i(t, "afterInit", r)), t;
      };
      class a {
        constructor(t) {
          this.axis = t;
        }
        getLogTickPositions(t, e, i, r) {
          let a = this.axis,
            n = a.len,
            l = a.options,
            h = [];
          if (r || (this.minorAutoInterval = void 0), t >= .5) t = Math.round(t), h = a.getLinearTickPositions(t, e, i);else if (t >= .08) {
            let s, o, a, n, l, d, c;
            let p = Math.floor(e);
            for (s = t > .3 ? [1, 2, 4] : t > .15 ? [1, 2, 4, 6, 8] : [1, 2, 3, 4, 5, 6, 7, 8, 9], o = p; o < i + 1 && !c; o++) for (a = 0, n = s.length; a < n && !c; a++) (l = this.log2lin(this.lin2log(o) * s[a])) > e && (!r || d <= i) && void 0 !== d && h.push(d), d > i && (c = !0), d = l;
          } else {
            let d = this.lin2log(e),
              c = this.lin2log(i),
              p = r ? a.getMinorTickInterval() : l.tickInterval,
              u = l.tickPixelInterval / (r ? 5 : 1),
              g = r ? n / a.tickPositions.length : n;
            t = s(t = o("auto" === p ? null : p, this.minorAutoInterval, (c - d) * u / (g || 1))), h = a.getLinearTickPositions(t, d, c).map(this.log2lin), r || (this.minorAutoInterval = t / 5);
          }
          return r || (a.tickInterval = t), h;
        }
        lin2log(t) {
          return Math.pow(10, t);
        }
        log2lin(t) {
          return Math.log(t) / Math.LN10;
        }
      }
      t.Additions = a;
    }(e || (e = {})), e;
  }), i(e, "Core/Axis/PlotLineOrBand/PlotLineOrBandAxis.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      erase: i,
      extend: s,
      isNumber: o
    } = t;
    return function (t) {
      let e;
      function r(t) {
        return this.addPlotBandOrLine(t, "plotBands");
      }
      function a(t, i) {
        let s = this.userOptions,
          o = new e(this, t);
        if (this.visible && (o = o.render()), o) {
          if (this._addedPlotLB || (this._addedPlotLB = !0, (s.plotLines || []).concat(s.plotBands || []).forEach(t => {
            this.addPlotBandOrLine(t);
          })), i) {
            let e = s[i] || [];
            e.push(t), s[i] = e;
          }
          this.plotLinesAndBands.push(o);
        }
        return o;
      }
      function n(t) {
        return this.addPlotBandOrLine(t, "plotLines");
      }
      function l(t, e, i) {
        i = i || this.options;
        let s = this.getPlotLinePath({
            value: e,
            force: !0,
            acrossPanes: i.acrossPanes
          }),
          r = [],
          a = this.horiz,
          n = !o(this.min) || !o(this.max) || t < this.min && e < this.min || t > this.max && e > this.max,
          l = this.getPlotLinePath({
            value: t,
            force: !0,
            acrossPanes: i.acrossPanes
          }),
          h,
          d = 1,
          c;
        if (l && s) for (n && (c = l.toString() === s.toString(), d = 0), h = 0; h < l.length; h += 2) {
          let t = l[h],
            e = l[h + 1],
            i = s[h],
            o = s[h + 1];
          ("M" === t[0] || "L" === t[0]) && ("M" === e[0] || "L" === e[0]) && ("M" === i[0] || "L" === i[0]) && ("M" === o[0] || "L" === o[0]) && (a && i[1] === t[1] ? (i[1] += d, o[1] += d) : a || i[2] !== t[2] || (i[2] += d, o[2] += d), r.push(["M", t[1], t[2]], ["L", e[1], e[2]], ["L", o[1], o[2]], ["L", i[1], i[2]], ["Z"])), r.isFlat = c;
        }
        return r;
      }
      function h(t) {
        this.removePlotBandOrLine(t);
      }
      function d(t) {
        let e = this.plotLinesAndBands,
          s = this.options,
          o = this.userOptions;
        if (e) {
          let r = e.length;
          for (; r--;) e[r].id === t && e[r].destroy();
          [s.plotLines || [], o.plotLines || [], s.plotBands || [], o.plotBands || []].forEach(function (e) {
            for (r = e.length; r--;) (e[r] || {}).id === t && i(e, e[r]);
          });
        }
      }
      function c(t) {
        this.removePlotBandOrLine(t);
      }
      t.compose = function (t, i) {
        let o = i.prototype;
        return o.addPlotBand || (e = t, s(o, {
          addPlotBand: r,
          addPlotLine: n,
          addPlotBandOrLine: a,
          getPlotBandPath: l,
          removePlotBand: h,
          removePlotLine: c,
          removePlotBandOrLine: d
        })), i;
      };
    }(e || (e = {})), e;
  }), i(e, "Core/Axis/PlotLineOrBand/PlotLineOrBand.js", [e["Core/Axis/PlotLineOrBand/PlotLineOrBandAxis.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      addEvent: i,
      arrayMax: s,
      arrayMin: o,
      defined: r,
      destroyObjectProperties: a,
      erase: n,
      fireEvent: l,
      merge: h,
      objectEach: d,
      pick: c
    } = e;
    class p {
      static compose(e, s) {
        return i(e, "afterInit", function () {
          this.labelCollectors.push(() => {
            let t = [];
            for (let e of this.axes) for (let {
              label: i,
              options: s
            } of e.plotLinesAndBands) i && !s?.label?.allowOverlap && t.push(i);
            return t;
          });
        }), t.compose(p, s);
      }
      constructor(t, e) {
        this.axis = t, this.options = e, this.id = e.id;
      }
      render() {
        l(this, "render");
        let {
            axis: t,
            options: e
          } = this,
          {
            horiz: i,
            logarithmic: s
          } = t,
          {
            color: o,
            events: a,
            zIndex: n = 0
          } = e,
          p = {},
          u = t.chart.renderer,
          g = e.to,
          f = e.from,
          m = e.value,
          x = e.borderWidth,
          y = e.label,
          {
            label: b,
            svgElem: v
          } = this,
          M = [],
          C,
          S = r(f) && r(g),
          w = r(m),
          k = !v,
          A = {
            class: "highcharts-plot-" + (S ? "band " : "line ") + (e.className || "")
          },
          T = S ? "bands" : "lines";
        if (!t.chart.styledMode && (w ? (A.stroke = o || "#999999", A["stroke-width"] = c(e.width, 1), e.dashStyle && (A.dashstyle = e.dashStyle)) : S && (A.fill = o || "#e6e9ff", x && (A.stroke = e.borderColor, A["stroke-width"] = x))), p.zIndex = n, T += "-" + n, (C = t.plotLinesAndBandsGroups[T]) || (t.plotLinesAndBandsGroups[T] = C = u.g("plot-" + T).attr(p).add()), v || (this.svgElem = v = u.path().attr(A).add(C)), r(m)) M = t.getPlotLinePath({
          value: s?.log2lin(m) ?? m,
          lineWidth: v.strokeWidth(),
          acrossPanes: e.acrossPanes
        });else {
          if (!(r(f) && r(g))) return;
          M = t.getPlotBandPath(s?.log2lin(f) ?? f, s?.log2lin(g) ?? g, e);
        }
        return !this.eventsAdded && a && (d(a, (t, e) => {
          v?.on(e, t => {
            a[e].apply(this, [t]);
          });
        }), this.eventsAdded = !0), (k || !v.d) && M?.length ? v.attr({
          d: M
        }) : v && (M ? (v.show(), v.animate({
          d: M
        })) : v.d && (v.hide(), b && (this.label = b = b.destroy()))), y && (r(y.text) || r(y.formatter)) && M?.length && t.width > 0 && t.height > 0 && !M.isFlat ? (y = h({
          align: i && S ? "center" : void 0,
          x: i ? !S && 4 : 10,
          verticalAlign: !i && S ? "middle" : void 0,
          y: i ? S ? 16 : 10 : S ? 6 : -4,
          rotation: i && !S ? 90 : 0,
          ...(S ? {
            inside: !0
          } : {})
        }, y), this.renderLabel(y, M, S, n)) : b && b.hide(), this;
      }
      renderLabel(t, e, i, a) {
        let n = this.axis,
          l = n.chart.renderer,
          d = t.inside,
          c = this.label;
        c || (this.label = c = l.text(this.getLabelText(t), 0, 0, t.useHTML).attr({
          align: t.textAlign || t.align,
          rotation: t.rotation,
          class: "highcharts-plot-" + (i ? "band" : "line") + "-label " + (t.className || ""),
          zIndex: a
        }), n.chart.styledMode || c.css(h({
          fontSize: "0.8em",
          textOverflow: i && !d ? "" : "ellipsis"
        }, t.style)), c.add());
        let p = e.xBounds || [e[0][1], e[1][1], i ? e[2][1] : e[0][1]],
          u = e.yBounds || [e[0][2], e[1][2], i ? e[2][2] : e[0][2]],
          g = o(p),
          f = o(u),
          m = s(p) - g;
        c.align(t, !1, {
          x: g,
          y: f,
          width: m,
          height: s(u) - f
        }), (!c.alignValue || "left" === c.alignValue || r(d)) && c.css({
          width: (t.style?.width || (i && d ? m : 90 === c.rotation ? n.height - (c.alignAttr.y - n.top) : (t.clip ? n.width : n.chart.chartWidth) - (c.alignAttr.x - n.left))) + "px"
        }), c.show(!0);
      }
      getLabelText(t) {
        return r(t.formatter) ? t.formatter.call(this) : t.text;
      }
      destroy() {
        n(this.axis.plotLinesAndBands, this), delete this.axis, a(this);
      }
    }
    return p;
  }), i(e, "Core/Tooltip.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Templating.js"], e["Core/Globals.js"], e["Core/Renderer/RendererUtilities.js"], e["Core/Renderer/RendererRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r) {
    var a;
    let {
        animObject: n
      } = t,
      {
        format: l
      } = e,
      {
        composed: h,
        doc: d,
        isSafari: c
      } = i,
      {
        distribute: p
      } = s,
      {
        addEvent: u,
        clamp: g,
        css: f,
        discardElement: m,
        extend: x,
        fireEvent: y,
        isArray: b,
        isNumber: v,
        isString: M,
        merge: C,
        pick: S,
        pushUnique: w,
        splat: k,
        syncTimeout: A
      } = r;
    class T {
      constructor(t, e, i) {
        this.allowShared = !0, this.crosshairs = [], this.distance = 0, this.isHidden = !0, this.isSticky = !1, this.options = {}, this.outside = !1, this.chart = t, this.init(t, e), this.pointer = i;
      }
      bodyFormatter(t) {
        return t.map(function (t) {
          let e = t.series.tooltipOptions;
          return (e[(t.point.formatPrefix || "point") + "Formatter"] || t.point.tooltipFormatter).call(t.point, e[(t.point.formatPrefix || "point") + "Format"] || "");
        });
      }
      cleanSplit(t) {
        this.chart.series.forEach(function (e) {
          let i = e && e.tt;
          i && (!i.isActive || t ? e.tt = i.destroy() : i.isActive = !1);
        });
      }
      defaultFormatter(t) {
        let e;
        let i = this.points || k(this);
        return (e = (e = [t.tooltipFooterHeaderFormatter(i[0])]).concat(t.bodyFormatter(i))).push(t.tooltipFooterHeaderFormatter(i[0], !0)), e;
      }
      destroy() {
        this.label && (this.label = this.label.destroy()), this.split && (this.cleanSplit(!0), this.tt && (this.tt = this.tt.destroy())), this.renderer && (this.renderer = this.renderer.destroy(), m(this.container)), r.clearTimeout(this.hideTimer);
      }
      getAnchor(t, e) {
        let i;
        let {
            chart: s,
            pointer: o
          } = this,
          r = s.inverted,
          a = s.plotTop,
          n = s.plotLeft;
        if ((t = k(t))[0].series && t[0].series.yAxis && !t[0].series.yAxis.options.reversedStacks && (t = t.slice().reverse()), this.followPointer && e) void 0 === e.chartX && (e = o.normalize(e)), i = [e.chartX - n, e.chartY - a];else if (t[0].tooltipPos) i = t[0].tooltipPos;else {
          let s = 0,
            o = 0;
          t.forEach(function (t) {
            let e = t.pos(!0);
            e && (s += e[0], o += e[1]);
          }), s /= t.length, o /= t.length, this.shared && t.length > 1 && e && (r ? s = e.chartX : o = e.chartY), i = [s - n, o - a];
        }
        return i.map(Math.round);
      }
      getClassName(t, e, i) {
        let s = this.options,
          o = t.series,
          r = o.options;
        return [s.className, "highcharts-label", i && "highcharts-tooltip-header", e ? "highcharts-tooltip-box" : "highcharts-tooltip", !i && "highcharts-color-" + S(t.colorIndex, o.colorIndex), r && r.className].filter(M).join(" ");
      }
      getLabel({
        anchorX: t,
        anchorY: e
      } = {
        anchorX: 0,
        anchorY: 0
      }) {
        let s = this,
          r = this.chart.styledMode,
          a = this.options,
          n = this.split && this.allowShared,
          l = this.container,
          h = this.chart.renderer;
        if (this.label) {
          let t = !this.label.hasClass("highcharts-label");
          (!n && t || n && !t) && this.destroy();
        }
        if (!this.label) {
          if (this.outside) {
            let t = this.chart.options.chart.style,
              e = o.getRendererType();
            this.container = l = i.doc.createElement("div"), l.className = "highcharts-tooltip-container", f(l, {
              position: "absolute",
              top: "1px",
              pointerEvents: "none",
              zIndex: Math.max(this.options.style.zIndex || 0, (t && t.zIndex || 0) + 3)
            }), this.renderer = h = new e(l, 0, 0, t, void 0, void 0, h.styledMode);
          }
          if (n ? this.label = h.g("tooltip") : (this.label = h.label("", t, e, a.shape, void 0, void 0, a.useHTML, void 0, "tooltip").attr({
            padding: a.padding,
            r: a.borderRadius
          }), r || this.label.attr({
            fill: a.backgroundColor,
            "stroke-width": a.borderWidth || 0
          }).css(a.style).css({
            pointerEvents: a.style.pointerEvents || (this.shouldStickOnContact() ? "auto" : "none")
          })), s.outside) {
            let t = this.label;
            [t.xSetter, t.ySetter].forEach((e, i) => {
              t[i ? "ySetter" : "xSetter"] = o => {
                e.call(t, s.distance), t[i ? "y" : "x"] = o, l && (l.style[i ? "top" : "left"] = `${o}px`);
              };
            });
          }
          this.label.attr({
            zIndex: 8
          }).shadow(a.shadow).add();
        }
        return l && !l.parentElement && i.doc.body.appendChild(l), this.label;
      }
      getPlayingField() {
        let {
            body: t,
            documentElement: e
          } = d,
          {
            chart: i,
            distance: s,
            outside: o
          } = this;
        return {
          width: o ? Math.max(t.scrollWidth, e.scrollWidth, t.offsetWidth, e.offsetWidth, e.clientWidth) - 2 * s : i.chartWidth,
          height: o ? Math.max(t.scrollHeight, e.scrollHeight, t.offsetHeight, e.offsetHeight, e.clientHeight) : i.chartHeight
        };
      }
      getPosition(t, e, i) {
        let {
            distance: s,
            chart: o,
            outside: r,
            pointer: a
          } = this,
          {
            inverted: n,
            plotLeft: l,
            plotTop: h,
            polar: d
          } = o,
          {
            plotX: c = 0,
            plotY: p = 0
          } = i,
          u = {},
          g = n && i.h || 0,
          {
            height: f,
            width: m
          } = this.getPlayingField(),
          x = a.getChartPosition(),
          y = t => t * x.scaleX,
          b = t => t * x.scaleY,
          v = i => {
            let a = "x" === i;
            return [i, a ? m : f, a ? t : e].concat(r ? [a ? y(t) : b(e), a ? x.left - s + y(c + l) : x.top - s + b(p + h), 0, a ? m : f] : [a ? t : e, a ? c + l : p + h, a ? l : h, a ? l + o.plotWidth : h + o.plotHeight]);
          },
          M = v("y"),
          C = v("x"),
          w,
          k = !!i.negative;
        !d && o.hoverSeries?.yAxis?.reversed && (k = !k);
        let A = !this.followPointer && S(i.ttBelow, !d && !n === k),
          T = function (t, e, i, o, a, n, l) {
            let h = r ? "y" === t ? b(s) : y(s) : s,
              d = (i - o) / 2,
              c = o < a - s,
              p = a + s + o < e,
              f = a - h - i + d,
              m = a + h - d;
            if (A && p) u[t] = m;else if (!A && c) u[t] = f;else if (c) u[t] = Math.min(l - o, f - g < 0 ? f : f - g);else {
              if (!p) return !1;
              u[t] = Math.max(n, m + g + i > e ? m : m + g);
            }
          },
          P = function (t, e, i, o, r) {
            if (r < s || r > e - s) return !1;
            r < i / 2 ? u[t] = 1 : r > e - o / 2 ? u[t] = e - o - 2 : u[t] = r - i / 2;
          },
          L = function (t) {
            [M, C] = [C, M], w = t;
          },
          j = () => {
            !1 !== T.apply(0, M) ? !1 !== P.apply(0, C) || w || (L(!0), j()) : w ? u.x = u.y = 0 : (L(!0), j());
          };
        return (n && !d || this.len > 1) && L(), j(), u;
      }
      hide(t) {
        let e = this;
        r.clearTimeout(this.hideTimer), t = S(t, this.options.hideDelay), this.isHidden || (this.hideTimer = A(function () {
          let i = e.getLabel();
          e.getLabel().animate({
            opacity: 0
          }, {
            duration: t ? 150 : t,
            complete: () => {
              i.hide(), e.container && e.container.remove();
            }
          }), e.isHidden = !0;
        }, t));
      }
      init(t, e) {
        this.chart = t, this.options = e, this.crosshairs = [], this.isHidden = !0, this.split = e.split && !t.inverted && !t.polar, this.shared = e.shared || this.split, this.outside = S(e.outside, !!(t.scrollablePixelsX || t.scrollablePixelsY));
      }
      shouldStickOnContact(t) {
        return !!(!this.followPointer && this.options.stickOnContact && (!t || this.pointer.inClass(t.target, "highcharts-tooltip")));
      }
      move(t, e, i, s) {
        let o = this,
          r = n(!o.isHidden && o.options.animation),
          a = o.followPointer || (o.len || 0) > 1,
          l = {
            x: t,
            y: e
          };
        a || (l.anchorX = i, l.anchorY = s), r.step = () => o.drawTracker(), o.getLabel().animate(l, r);
      }
      refresh(t, e) {
        let {
            chart: i,
            options: s,
            pointer: o,
            shared: a
          } = this,
          n = k(t),
          h = n[0],
          d = [],
          c = s.format,
          p = s.formatter || this.defaultFormatter,
          u = i.styledMode,
          f = {},
          m = this.allowShared;
        if (!s.enabled || !h.series) return;
        r.clearTimeout(this.hideTimer), this.allowShared = !(!b(t) && t.series && t.series.noSharedTooltip), m = m && !this.allowShared, this.followPointer = !this.split && h.series.tooltipOptions.followPointer;
        let x = this.getAnchor(t, e),
          v = x[0],
          C = x[1];
        a && this.allowShared ? (o.applyInactiveState(n), n.forEach(function (t) {
          t.setState("hover"), d.push(t.getLabelConfig());
        }), (f = h.getLabelConfig()).points = d) : f = h.getLabelConfig(), this.len = d.length;
        let w = M(c) ? l(c, f, i) : p.call(f, this),
          A = h.series;
        if (this.distance = S(A.tooltipOptions.distance, 16), !1 === w) this.hide();else {
          if (this.split && this.allowShared) this.renderSplit(w, n);else {
            let t = v,
              r = C;
            if (e && o.isDirectTouch && (t = e.chartX - i.plotLeft, r = e.chartY - i.plotTop), i.polar || !1 === A.options.clip || n.some(e => o.isDirectTouch || e.series.shouldShowTooltip(t, r))) {
              let t = this.getLabel(m && this.tt || {});
              (!s.style.width || u) && t.css({
                width: (this.outside ? this.getPlayingField() : i.spacingBox).width + "px"
              }), t.attr({
                class: this.getClassName(h),
                text: w && w.join ? w.join("") : w
              }), this.outside && t.attr({
                x: g(t.x || 0, 0, this.getPlayingField().width - (t.width || 0))
              }), u || t.attr({
                stroke: s.borderColor || h.color || A.color || "#666666"
              }), this.updatePosition({
                plotX: v,
                plotY: C,
                negative: h.negative,
                ttBelow: h.ttBelow,
                h: x[2] || 0
              });
            } else {
              this.hide();
              return;
            }
          }
          this.isHidden && this.label && this.label.attr({
            opacity: 1
          }).show(), this.isHidden = !1;
        }
        y(this, "refresh");
      }
      renderSplit(t, e) {
        let i = this,
          {
            chart: s,
            chart: {
              chartWidth: o,
              chartHeight: r,
              plotHeight: a,
              plotLeft: n,
              plotTop: l,
              scrollablePixelsY: h = 0,
              scrollablePixelsX: u,
              styledMode: f
            },
            distance: m,
            options: y,
            options: {
              positioner: b
            },
            pointer: v
          } = i,
          {
            scrollLeft: C = 0,
            scrollTop: w = 0
          } = s.scrollablePlotArea?.scrollingContainer || {},
          k = i.outside && "number" != typeof u ? d.documentElement.getBoundingClientRect() : {
            left: C,
            right: C + o,
            top: w,
            bottom: w + r
          },
          A = i.getLabel(),
          T = this.renderer || s.renderer,
          P = !!(s.xAxis[0] && s.xAxis[0].opposite),
          {
            left: L,
            top: j
          } = v.getChartPosition(),
          O = l + w,
          D = 0,
          E = a - h;
        function I(t, e, s, o, r = !0) {
          let a, n;
          return s ? (a = P ? 0 : E, n = g(t - o / 2, k.left, k.right - o - (i.outside ? L : 0))) : (a = e - O, n = g(n = r ? t - o - m : t + m, r ? n : k.left, k.right)), {
            x: n,
            y: a
          };
        }
        M(t) && (t = [!1, t]);
        let B = t.slice(0, e.length + 1).reduce(function (t, s, o) {
          if (!1 !== s && "" !== s) {
            let r = e[o - 1] || {
                isHeader: !0,
                plotX: e[0].plotX,
                plotY: a,
                series: {}
              },
              h = r.isHeader,
              d = h ? i : r.series,
              c = d.tt = function (t, e, s) {
                let o = t,
                  {
                    isHeader: r,
                    series: a
                  } = e;
                if (!o) {
                  let t = {
                    padding: y.padding,
                    r: y.borderRadius
                  };
                  f || (t.fill = y.backgroundColor, t["stroke-width"] = y.borderWidth ?? 1), o = T.label("", 0, 0, y[r ? "headerShape" : "shape"], void 0, void 0, y.useHTML).addClass(i.getClassName(e, !0, r)).attr(t).add(A);
                }
                return o.isActive = !0, o.attr({
                  text: s
                }), f || o.css(y.style).attr({
                  stroke: y.borderColor || e.color || a.color || "#333333"
                }), o;
              }(d.tt, r, s.toString()),
              p = c.getBBox(),
              u = p.width + c.strokeWidth();
            h && (D = p.height, E += D, P && (O -= D));
            let {
              anchorX: x,
              anchorY: v
            } = function (t) {
              let e, i;
              let {
                isHeader: s,
                plotX: o = 0,
                plotY: r = 0,
                series: h
              } = t;
              if (s) e = Math.max(n + o, n), i = l + a / 2;else {
                let {
                  xAxis: t,
                  yAxis: s
                } = h;
                e = t.pos + g(o, -m, t.len + m), h.shouldShowTooltip(0, s.pos - l + r, {
                  ignoreX: !0
                }) && (i = s.pos + r);
              }
              return {
                anchorX: e = g(e, k.left - m, k.right + m),
                anchorY: i
              };
            }(r);
            if ("number" == typeof v) {
              let e = p.height + 1,
                s = b ? b.call(i, u, e, r) : I(x, v, h, u);
              t.push({
                align: b ? 0 : void 0,
                anchorX: x,
                anchorY: v,
                boxWidth: u,
                point: r,
                rank: S(s.rank, h ? 1 : 0),
                size: e,
                target: s.y,
                tt: c,
                x: s.x
              });
            } else c.isActive = !1;
          }
          return t;
        }, []);
        !b && B.some(t => {
          let {
              outside: e
            } = i,
            s = (e ? L : 0) + t.anchorX;
          return s < k.left && s + t.boxWidth < k.right || s < L - k.left + t.boxWidth && k.right - s > s;
        }) && (B = B.map(t => {
          let {
            x: e,
            y: i
          } = I(t.anchorX, t.anchorY, t.point.isHeader, t.boxWidth, !1);
          return x(t, {
            target: i,
            x: e
          });
        })), i.cleanSplit(), p(B, E);
        let z = {
          left: L,
          right: L
        };
        B.forEach(function (t) {
          let {
            x: e,
            boxWidth: s,
            isHeader: o
          } = t;
          !o && (i.outside && L + e < z.left && (z.left = L + e), !o && i.outside && z.left + s > z.right && (z.right = L + e));
        }), B.forEach(function (t) {
          let {
              x: e,
              anchorX: s,
              anchorY: o,
              pos: r,
              point: {
                isHeader: a
              }
            } = t,
            n = {
              visibility: void 0 === r ? "hidden" : "inherit",
              x: e,
              y: (r || 0) + O,
              anchorX: s,
              anchorY: o
            };
          if (i.outside && e < s) {
            let t = L - z.left;
            t > 0 && (a || (n.x = e + t, n.anchorX = s + t), a && (n.x = (z.right - z.left) / 2, n.anchorX = s + t));
          }
          t.tt.attr(n);
        });
        let {
          container: R,
          outside: N,
          renderer: G
        } = i;
        if (N && R && G) {
          let {
            width: t,
            height: e,
            x: i,
            y: s
          } = A.getBBox();
          G.setSize(t + i, e + s, !1), R.style.left = z.left + "px", R.style.top = j + "px";
        }
        c && A.attr({
          opacity: 1 === A.opacity ? .999 : 1
        });
      }
      drawTracker() {
        if (!this.shouldStickOnContact()) {
          this.tracker && (this.tracker = this.tracker.destroy());
          return;
        }
        let t = this.chart,
          e = this.label,
          i = this.shared ? t.hoverPoints : t.hoverPoint;
        if (!e || !i) return;
        let s = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          },
          o = this.getAnchor(i),
          r = e.getBBox();
        o[0] += t.plotLeft - (e.translateX || 0), o[1] += t.plotTop - (e.translateY || 0), s.x = Math.min(0, o[0]), s.y = Math.min(0, o[1]), s.width = o[0] < 0 ? Math.max(Math.abs(o[0]), r.width - o[0]) : Math.max(Math.abs(o[0]), r.width), s.height = o[1] < 0 ? Math.max(Math.abs(o[1]), r.height - Math.abs(o[1])) : Math.max(Math.abs(o[1]), r.height), this.tracker ? this.tracker.attr(s) : (this.tracker = e.renderer.rect(s).addClass("highcharts-tracker").add(e), t.styledMode || this.tracker.attr({
          fill: "rgba(0,0,0,0)"
        }));
      }
      styledModeFormat(t) {
        return t.replace('style="font-size: 0.8em"', 'class="highcharts-header"').replace(/style="color:{(point|series)\.color}"/g, 'class="highcharts-color-{$1.colorIndex} {series.options.className} {point.options.className}"');
      }
      tooltipFooterHeaderFormatter(t, e) {
        let i = t.series,
          s = i.tooltipOptions,
          o = i.xAxis,
          r = o && o.dateTime,
          a = {
            isFooter: e,
            labelConfig: t
          },
          n = s.xDateFormat,
          h = s[e ? "footerFormat" : "headerFormat"];
        return y(this, "headerFormatter", a, function (e) {
          r && !n && v(t.key) && (n = r.getXDateFormat(t.key, s.dateTimeLabelFormats)), r && n && (t.point && t.point.tooltipDateKeys || ["key"]).forEach(function (t) {
            h = h.replace("{point." + t + "}", "{point." + t + ":" + n + "}");
          }), i.chart.styledMode && (h = this.styledModeFormat(h)), e.text = l(h, {
            point: t,
            series: i
          }, this.chart);
        }), a.text;
      }
      update(t) {
        this.destroy(), this.init(this.chart, C(!0, this.options, t));
      }
      updatePosition(t) {
        let {
            chart: e,
            container: i,
            distance: s,
            options: o,
            pointer: r,
            renderer: a
          } = this,
          {
            height: n = 0,
            width: l = 0
          } = this.getLabel(),
          {
            left: h,
            top: d,
            scaleX: c,
            scaleY: p
          } = r.getChartPosition(),
          u = (o.positioner || this.getPosition).call(this, l, n, t),
          g = (t.plotX || 0) + e.plotLeft,
          m = (t.plotY || 0) + e.plotTop,
          x;
        a && i && (o.positioner && (u.x += h - s, u.y += d - s), x = (o.borderWidth || 0) + 2 * s + 2, a.setSize(l + x, n + x, !1), (1 !== c || 1 !== p) && (f(i, {
          transform: `scale(${c}, ${p})`
        }), g *= c, m *= p), g += h - u.x, m += d - u.y), this.move(Math.round(u.x), Math.round(u.y || 0), g, m);
      }
    }
    return (a = T || (T = {})).compose = function (t) {
      w(h, "Core.Tooltip") && u(t, "afterInit", function () {
        let t = this.chart;
        t.options.tooltip && (t.tooltip = new a(t, t.options.tooltip, this));
      });
    }, T;
  }), i(e, "Core/Series/Point.js", [e["Core/Renderer/HTML/AST.js"], e["Core/Animation/AnimationUtilities.js"], e["Core/Defaults.js"], e["Core/Templating.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    let {
        animObject: r
      } = e,
      {
        defaultOptions: a
      } = i,
      {
        format: n
      } = s,
      {
        addEvent: l,
        crisp: h,
        erase: d,
        extend: c,
        fireEvent: p,
        getNestedProperty: u,
        isArray: g,
        isFunction: f,
        isNumber: m,
        isObject: x,
        merge: y,
        pick: b,
        syncTimeout: v,
        removeEvent: M,
        uniqueKey: C
      } = o;
    class S {
      animateBeforeDestroy() {
        let t = this,
          e = {
            x: t.startXPos,
            opacity: 0
          },
          i = t.getGraphicalProps();
        i.singular.forEach(function (i) {
          t[i] = t[i].animate("dataLabel" === i ? {
            x: t[i].startXPos,
            y: t[i].startYPos,
            opacity: 0
          } : e);
        }), i.plural.forEach(function (e) {
          t[e].forEach(function (e) {
            e.element && e.animate(c({
              x: t.startXPos
            }, e.startYPos ? {
              x: e.startXPos,
              y: e.startYPos
            } : {}));
          });
        });
      }
      applyOptions(t, e) {
        let i = this.series,
          s = i.options.pointValKey || i.pointValKey;
        return c(this, t = S.prototype.optionsToObject.call(this, t)), this.options = this.options ? c(this.options, t) : t, t.group && delete this.group, t.dataLabels && delete this.dataLabels, s && (this.y = S.prototype.getNestedProperty.call(this, s)), this.selected && (this.state = "select"), "name" in this && void 0 === e && i.xAxis && i.xAxis.hasNames && (this.x = i.xAxis.nameToX(this)), void 0 === this.x && i ? this.x = e ?? i.autoIncrement() : m(t.x) && i.options.relativeXValue && (this.x = i.autoIncrement(t.x)), this.isNull = this.isValid && !this.isValid(), this.formatPrefix = this.isNull ? "null" : "point", this;
      }
      destroy() {
        if (!this.destroyed) {
          let t = this,
            e = t.series,
            i = e.chart,
            s = e.options.dataSorting,
            o = i.hoverPoints,
            a = r(t.series.chart.renderer.globalAnimation),
            n = () => {
              for (let e in (t.graphic || t.graphics || t.dataLabel || t.dataLabels) && (M(t), t.destroyElements()), t) delete t[e];
            };
          t.legendItem && i.legend.destroyItem(t), o && (t.setState(), d(o, t), o.length || (i.hoverPoints = null)), t === i.hoverPoint && t.onMouseOut(), s && s.enabled ? (this.animateBeforeDestroy(), v(n, a.duration)) : n(), i.pointCount--;
        }
        this.destroyed = !0;
      }
      destroyElements(t) {
        let e = this,
          i = e.getGraphicalProps(t);
        i.singular.forEach(function (t) {
          e[t] = e[t].destroy();
        }), i.plural.forEach(function (t) {
          e[t].forEach(function (t) {
            t && t.element && t.destroy();
          }), delete e[t];
        });
      }
      firePointEvent(t, e, i) {
        let s = this,
          o = this.series.options;
        s.manageEvent(t), "click" === t && o.allowPointSelect && (i = function (t) {
          !s.destroyed && s.select && s.select(null, t.ctrlKey || t.metaKey || t.shiftKey);
        }), p(s, t, e, i);
      }
      getClassName() {
        return "highcharts-point" + (this.selected ? " highcharts-point-select" : "") + (this.negative ? " highcharts-negative" : "") + (this.isNull ? " highcharts-null-point" : "") + (void 0 !== this.colorIndex ? " highcharts-color-" + this.colorIndex : "") + (this.options.className ? " " + this.options.className : "") + (this.zone && this.zone.className ? " " + this.zone.className.replace("highcharts-negative", "") : "");
      }
      getGraphicalProps(t) {
        let e, i;
        let s = this,
          o = [],
          r = {
            singular: [],
            plural: []
          };
        for ((t = t || {
          graphic: 1,
          dataLabel: 1
        }).graphic && o.push("graphic", "connector"), t.dataLabel && o.push("dataLabel", "dataLabelPath", "dataLabelUpper"), i = o.length; i--;) s[e = o[i]] && r.singular.push(e);
        return ["graphic", "dataLabel"].forEach(function (e) {
          let i = e + "s";
          t[e] && s[i] && r.plural.push(i);
        }), r;
      }
      getLabelConfig() {
        return {
          x: this.category,
          y: this.y,
          color: this.color,
          colorIndex: this.colorIndex,
          key: this.name || this.category,
          series: this.series,
          point: this,
          percentage: this.percentage,
          total: this.total || this.stackTotal
        };
      }
      getNestedProperty(t) {
        return t ? 0 === t.indexOf("custom.") ? u(t, this.options) : this[t] : void 0;
      }
      getZone() {
        let t = this.series,
          e = t.zones,
          i = t.zoneAxis || "y",
          s,
          o = 0;
        for (s = e[0]; this[i] >= s.value;) s = e[++o];
        return this.nonZonedColor || (this.nonZonedColor = this.color), s && s.color && !this.options.color ? this.color = s.color : this.color = this.nonZonedColor, s;
      }
      hasNewShapeType() {
        return (this.graphic && (this.graphic.symbolName || this.graphic.element.nodeName)) !== this.shapeType;
      }
      constructor(t, e, i) {
        this.formatPrefix = "point", this.visible = !0, this.series = t, this.applyOptions(e, i), this.id ?? (this.id = C()), this.resolveColor(), t.chart.pointCount++, p(this, "afterInit");
      }
      isValid() {
        return (m(this.x) || this.x instanceof Date) && m(this.y);
      }
      optionsToObject(t) {
        let e = this.series,
          i = e.options.keys,
          s = i || e.pointArrayMap || ["y"],
          o = s.length,
          r = {},
          a,
          n = 0,
          l = 0;
        if (m(t) || null === t) r[s[0]] = t;else if (g(t)) for (!i && t.length > o && ("string" == (a = typeof t[0]) ? r.name = t[0] : "number" === a && (r.x = t[0]), n++); l < o;) i && void 0 === t[n] || (s[l].indexOf(".") > 0 ? S.prototype.setNestedProperty(r, t[n], s[l]) : r[s[l]] = t[n]), n++, l++;else "object" == typeof t && (r = t, t.dataLabels && (e.hasDataLabels = () => !0), t.marker && (e._hasPointMarkers = !0));
        return r;
      }
      pos(t, e = this.plotY) {
        if (!this.destroyed) {
          let {
              plotX: i,
              series: s
            } = this,
            {
              chart: o,
              xAxis: r,
              yAxis: a
            } = s,
            n = 0,
            l = 0;
          if (m(i) && m(e)) return t && (n = r ? r.pos : o.plotLeft, l = a ? a.pos : o.plotTop), o.inverted && r && a ? [a.len - e + l, r.len - i + n] : [i + n, e + l];
        }
      }
      resolveColor() {
        let t = this.series,
          e = t.chart.options.chart,
          i = t.chart.styledMode,
          s,
          o,
          r = e.colorCount,
          a;
        delete this.nonZonedColor, t.options.colorByPoint ? (i || (s = (o = t.options.colors || t.chart.options.colors)[t.colorCounter], r = o.length), a = t.colorCounter, t.colorCounter++, t.colorCounter === r && (t.colorCounter = 0)) : (i || (s = t.color), a = t.colorIndex), this.colorIndex = b(this.options.colorIndex, a), this.color = b(this.options.color, s);
      }
      setNestedProperty(t, e, i) {
        return i.split(".").reduce(function (t, i, s, o) {
          let r = o.length - 1 === s;
          return t[i] = r ? e : x(t[i], !0) ? t[i] : {}, t[i];
        }, t), t;
      }
      shouldDraw() {
        return !this.isNull;
      }
      tooltipFormatter(t) {
        let e = this.series,
          i = e.tooltipOptions,
          s = b(i.valueDecimals, ""),
          o = i.valuePrefix || "",
          r = i.valueSuffix || "";
        return e.chart.styledMode && (t = e.chart.tooltip.styledModeFormat(t)), (e.pointArrayMap || ["y"]).forEach(function (e) {
          e = "{point." + e, (o || r) && (t = t.replace(RegExp(e + "}", "g"), o + e + "}" + r)), t = t.replace(RegExp(e + "}", "g"), e + ":,." + s + "f}");
        }), n(t, {
          point: this,
          series: this.series
        }, e.chart);
      }
      update(t, e, i, s) {
        let o;
        let r = this,
          a = r.series,
          n = r.graphic,
          l = a.chart,
          h = a.options;
        function d() {
          r.applyOptions(t);
          let s = n && r.hasMockGraphic,
            d = null === r.y ? !s : s;
          n && d && (r.graphic = n.destroy(), delete r.hasMockGraphic), x(t, !0) && (n && n.element && t && t.marker && void 0 !== t.marker.symbol && (r.graphic = n.destroy()), t?.dataLabels && r.dataLabel && (r.dataLabel = r.dataLabel.destroy())), o = r.index, a.updateParallelArrays(r, o), h.data[o] = x(h.data[o], !0) || x(t, !0) ? r.options : b(t, h.data[o]), a.isDirty = a.isDirtyData = !0, !a.fixedBox && a.hasCartesianSeries && (l.isDirtyBox = !0), "point" === h.legendType && (l.isDirtyLegend = !0), e && l.redraw(i);
        }
        e = b(e, !0), !1 === s ? d() : r.firePointEvent("update", {
          options: t
        }, d);
      }
      remove(t, e) {
        this.series.removePoint(this.series.data.indexOf(this), t, e);
      }
      select(t, e) {
        let i = this,
          s = i.series,
          o = s.chart;
        t = b(t, !i.selected), this.selectedStaging = t, i.firePointEvent(t ? "select" : "unselect", {
          accumulate: e
        }, function () {
          i.selected = i.options.selected = t, s.options.data[s.data.indexOf(i)] = i.options, i.setState(t && "select"), e || o.getSelectedPoints().forEach(function (t) {
            let e = t.series;
            t.selected && t !== i && (t.selected = t.options.selected = !1, e.options.data[e.data.indexOf(t)] = t.options, t.setState(o.hoverPoints && e.options.inactiveOtherPoints ? "inactive" : ""), t.firePointEvent("unselect"));
          });
        }), delete this.selectedStaging;
      }
      onMouseOver(t) {
        let {
          inverted: e,
          pointer: i
        } = this.series.chart;
        i && (t = t ? i.normalize(t) : i.getChartCoordinatesFromPoint(this, e), i.runPointActions(t, this));
      }
      onMouseOut() {
        let t = this.series.chart;
        this.firePointEvent("mouseOut"), this.series.options.inactiveOtherPoints || (t.hoverPoints || []).forEach(function (t) {
          t.setState();
        }), t.hoverPoints = t.hoverPoint = null;
      }
      manageEvent(t) {
        let e = y(this.series.options.point, this.options),
          i = e.events?.[t];
        f(i) && (!this.hcEvents?.[t] || this.hcEvents?.[t]?.map(t => t.fn).indexOf(i) === -1) ? (this.importedUserEvent?.(), this.importedUserEvent = l(this, t, i)) : this.importedUserEvent && !i && this.hcEvents?.[t] && (M(this, t), delete this.hcEvents[t], Object.keys(this.hcEvents) || delete this.importedUserEvent);
      }
      setState(e, i) {
        let s = this.series,
          o = this.state,
          r = s.options.states[e || "normal"] || {},
          n = a.plotOptions[s.type].marker && s.options.marker,
          l = n && !1 === n.enabled,
          h = n && n.states && n.states[e || "normal"] || {},
          d = !1 === h.enabled,
          u = this.marker || {},
          g = s.chart,
          f = n && s.markerAttribs,
          x = s.halo,
          y,
          v,
          M,
          C = s.stateMarkerGraphic,
          S;
        if ((e = e || "") === this.state && !i || this.selected && "select" !== e || !1 === r.enabled || e && (d || l && !1 === h.enabled) || e && u.states && u.states[e] && !1 === u.states[e].enabled) return;
        if (this.state = e, f && (y = s.markerAttribs(this, e)), this.graphic && !this.hasMockGraphic) {
          if (o && this.graphic.removeClass("highcharts-point-" + o), e && this.graphic.addClass("highcharts-point-" + e), !g.styledMode) {
            v = s.pointAttribs(this, e), M = b(g.options.chart.animation, r.animation);
            let t = v.opacity;
            s.options.inactiveOtherPoints && m(t) && (this.dataLabels || []).forEach(function (e) {
              e && !e.hasClass("highcharts-data-label-hidden") && (e.animate({
                opacity: t
              }, M), e.connector && e.connector.animate({
                opacity: t
              }, M));
            }), this.graphic.animate(v, M);
          }
          y && this.graphic.animate(y, b(g.options.chart.animation, h.animation, n.animation)), C && C.hide();
        } else e && h && (S = u.symbol || s.symbol, C && C.currentSymbol !== S && (C = C.destroy()), y && (C ? C[i ? "animate" : "attr"]({
          x: y.x,
          y: y.y
        }) : S && (s.stateMarkerGraphic = C = g.renderer.symbol(S, y.x, y.y, y.width, y.height).add(s.markerGroup), C.currentSymbol = S)), !g.styledMode && C && "inactive" !== this.state && C.attr(s.pointAttribs(this, e))), C && (C[e && this.isInside ? "show" : "hide"](), C.element.point = this, C.addClass(this.getClassName(), !0));
        let w = r.halo,
          k = this.graphic || C,
          A = k && k.visibility || "inherit";
        w && w.size && k && "hidden" !== A && !this.isCluster ? (x || (s.halo = x = g.renderer.path().add(k.parentGroup)), x.show()[i ? "animate" : "attr"]({
          d: this.haloPath(w.size)
        }), x.attr({
          class: "highcharts-halo highcharts-color-" + b(this.colorIndex, s.colorIndex) + (this.className ? " " + this.className : ""),
          visibility: A,
          zIndex: -1
        }), x.point = this, g.styledMode || x.attr(c({
          fill: this.color || s.color,
          "fill-opacity": w.opacity
        }, t.filterUserAttributes(w.attributes || {})))) : x?.point?.haloPath && !x.point.destroyed && x.animate({
          d: x.point.haloPath(0)
        }, null, x.hide), p(this, "afterSetState", {
          state: e
        });
      }
      haloPath(t) {
        let e = this.pos();
        return e ? this.series.chart.renderer.symbols.circle(h(e[0], 1) - t, e[1] - t, 2 * t, 2 * t) : [];
      }
    }
    return S;
  }), i(e, "Core/Pointer.js", [e["Core/Color/Color.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s;
    let {
        parse: o
      } = t,
      {
        charts: r,
        composed: a,
        isTouchDevice: n
      } = e,
      {
        addEvent: l,
        attr: h,
        css: d,
        extend: c,
        find: p,
        fireEvent: u,
        isNumber: g,
        isObject: f,
        objectEach: m,
        offset: x,
        pick: y,
        pushUnique: b,
        splat: v
      } = i;
    class M {
      applyInactiveState(t) {
        let e = [],
          i;
        (t || []).forEach(function (t) {
          i = t.series, e.push(i), i.linkedParent && e.push(i.linkedParent), i.linkedSeries && (e = e.concat(i.linkedSeries)), i.navigatorSeries && e.push(i.navigatorSeries);
        }), this.chart.series.forEach(function (t) {
          -1 === e.indexOf(t) ? t.setState("inactive", !0) : t.options.inactiveOtherPoints && t.setAllPointsToState("inactive");
        });
      }
      destroy() {
        let t = this;
        this.eventsToUnbind.forEach(t => t()), this.eventsToUnbind = [], !e.chartCount && (M.unbindDocumentMouseUp && M.unbindDocumentMouseUp.forEach(t => t()), M.unbindDocumentTouchEnd && (M.unbindDocumentTouchEnd = M.unbindDocumentTouchEnd())), clearInterval(t.tooltipTimeout), m(t, function (e, i) {
          t[i] = void 0;
        });
      }
      getSelectionMarkerAttrs(t, e) {
        let i = {
          args: {
            chartX: t,
            chartY: e
          },
          attrs: {},
          shapeType: "rect"
        };
        return u(this, "getSelectionMarkerAttrs", i, i => {
          let s;
          let {
              chart: o,
              zoomHor: r,
              zoomVert: a
            } = this,
            {
              mouseDownX: n = 0,
              mouseDownY: l = 0
            } = o,
            h = i.attrs;
          h.x = o.plotLeft, h.y = o.plotTop, h.width = r ? 1 : o.plotWidth, h.height = a ? 1 : o.plotHeight, r && (s = t - n, h.width = Math.max(1, Math.abs(s)), h.x = (s > 0 ? 0 : s) + n), a && (s = e - l, h.height = Math.max(1, Math.abs(s)), h.y = (s > 0 ? 0 : s) + l);
        }), i;
      }
      drag(t) {
        let {
            chart: e
          } = this,
          {
            mouseDownX: i = 0,
            mouseDownY: s = 0
          } = e,
          {
            panning: r,
            panKey: a,
            selectionMarkerFill: n
          } = e.options.chart,
          l = e.plotLeft,
          h = e.plotTop,
          d = e.plotWidth,
          c = e.plotHeight,
          p = f(r) ? r.enabled : r,
          u = a && t[`${a}Key`],
          g = t.chartX,
          m = t.chartY,
          x,
          y = this.selectionMarker;
        if ((!y || !y.touch) && (g < l ? g = l : g > l + d && (g = l + d), m < h ? m = h : m > h + c && (m = h + c), this.hasDragged = Math.sqrt(Math.pow(i - g, 2) + Math.pow(s - m, 2)), this.hasDragged > 10)) {
          x = e.isInsidePlot(i - l, s - h, {
            visiblePlotOnly: !0
          });
          let {
            shapeType: a,
            attrs: d
          } = this.getSelectionMarkerAttrs(g, m);
          (e.hasCartesianSeries || e.mapView) && this.hasZoom && x && !u && !y && (this.selectionMarker = y = e.renderer[a](), y.attr({
            class: "highcharts-selection-marker",
            zIndex: 7
          }).add(), e.styledMode || y.attr({
            fill: n || o("#334eff").setOpacity(.25).get()
          })), y && y.attr(d), x && !y && p && e.pan(t, r);
        }
      }
      dragStart(t) {
        let e = this.chart;
        e.mouseIsDown = t.type, e.cancelClick = !1, e.mouseDownX = t.chartX, e.mouseDownY = t.chartY;
      }
      getSelectionBox(t) {
        let e = {
          args: {
            marker: t
          },
          result: t.getBBox()
        };
        return u(this, "getSelectionBox", e), e.result;
      }
      drop(t) {
        let e;
        let {
          chart: i,
          selectionMarker: s
        } = this;
        for (let t of i.axes) t.isPanning && (t.isPanning = !1, (t.options.startOnTick || t.options.endOnTick || t.series.some(t => t.boosted)) && (t.forceRedraw = !0, t.setExtremes(t.userMin, t.userMax, !1), e = !0));
        if (e && i.redraw(), s && t) {
          if (this.hasDragged) {
            let e = this.getSelectionBox(s);
            i.transform({
              axes: i.axes.filter(t => t.zoomEnabled && ("xAxis" === t.coll && this.zoomX || "yAxis" === t.coll && this.zoomY)),
              selection: {
                originalEvent: t,
                xAxis: [],
                yAxis: [],
                ...e
              },
              from: e
            });
          }
          g(i.index) && (this.selectionMarker = s.destroy());
        }
        i && g(i.index) && (d(i.container, {
          cursor: i._cursor
        }), i.cancelClick = this.hasDragged > 10, i.mouseIsDown = !1, this.hasDragged = 0, this.pinchDown = []);
      }
      findNearestKDPoint(t, e, i) {
        let s;
        return t.forEach(function (t) {
          let o = !(t.noSharedTooltip && e) && 0 > t.options.findNearestPointBy.indexOf("y"),
            r = t.searchPoint(i, o);
          f(r, !0) && r.series && (!f(s, !0) || function (t, i) {
            let s = t.distX - i.distX,
              o = t.dist - i.dist,
              r = i.series.group?.zIndex - t.series.group?.zIndex;
            return 0 !== s && e ? s : 0 !== o ? o : 0 !== r ? r : t.series.index > i.series.index ? -1 : 1;
          }(s, r) > 0) && (s = r);
        }), s;
      }
      getChartCoordinatesFromPoint(t, e) {
        let {
            xAxis: i,
            yAxis: s
          } = t.series,
          o = t.shapeArgs;
        if (i && s) {
          let r = t.clientX ?? t.plotX ?? 0,
            a = t.plotY || 0;
          return t.isNode && o && g(o.x) && g(o.y) && (r = o.x, a = o.y), e ? {
            chartX: s.len + s.pos - a,
            chartY: i.len + i.pos - r
          } : {
            chartX: r + i.pos,
            chartY: a + s.pos
          };
        }
        if (o && o.x && o.y) return {
          chartX: o.x,
          chartY: o.y
        };
      }
      getChartPosition() {
        if (this.chartPosition) return this.chartPosition;
        let {
            container: t
          } = this.chart,
          e = x(t);
        this.chartPosition = {
          left: e.left,
          top: e.top,
          scaleX: 1,
          scaleY: 1
        };
        let {
          offsetHeight: i,
          offsetWidth: s
        } = t;
        return s > 2 && i > 2 && (this.chartPosition.scaleX = e.width / s, this.chartPosition.scaleY = e.height / i), this.chartPosition;
      }
      getCoordinates(t) {
        let e = {
          xAxis: [],
          yAxis: []
        };
        for (let i of this.chart.axes) e[i.isXAxis ? "xAxis" : "yAxis"].push({
          axis: i,
          value: i.toValue(t[i.horiz ? "chartX" : "chartY"])
        });
        return e;
      }
      getHoverData(t, e, i, s, o, r) {
        let a = [],
          n = function (t) {
            return t.visible && !(!o && t.directTouch) && y(t.options.enableMouseTracking, !0);
          },
          l = e,
          h,
          d = {
            chartX: r ? r.chartX : void 0,
            chartY: r ? r.chartY : void 0,
            shared: o
          };
        u(this, "beforeGetHoverData", d), h = l && !l.stickyTracking ? [l] : i.filter(t => t.stickyTracking && (d.filter || n)(t));
        let c = s && t || !r ? t : this.findNearestKDPoint(h, o, r);
        return l = c && c.series, c && (o && !l.noSharedTooltip ? (h = i.filter(function (t) {
          return d.filter ? d.filter(t) : n(t) && !t.noSharedTooltip;
        })).forEach(function (t) {
          let e = p(t.points, function (t) {
            return t.x === c.x && !t.isNull;
          });
          f(e) && (t.boosted && t.boost && (e = t.boost.getPoint(e)), a.push(e));
        }) : a.push(c)), u(this, "afterGetHoverData", d = {
          hoverPoint: c
        }), {
          hoverPoint: d.hoverPoint,
          hoverSeries: l,
          hoverPoints: a
        };
      }
      getPointFromEvent(t) {
        let e = t.target,
          i;
        for (; e && !i;) i = e.point, e = e.parentNode;
        return i;
      }
      onTrackerMouseOut(t) {
        let e = this.chart,
          i = t.relatedTarget,
          s = e.hoverSeries;
        this.isDirectTouch = !1, !s || !i || s.stickyTracking || this.inClass(i, "highcharts-tooltip") || this.inClass(i, "highcharts-series-" + s.index) && this.inClass(i, "highcharts-tracker") || s.onMouseOut();
      }
      inClass(t, e) {
        let i = t,
          s;
        for (; i;) {
          if (s = h(i, "class")) {
            if (-1 !== s.indexOf(e)) return !0;
            if (-1 !== s.indexOf("highcharts-container")) return !1;
          }
          i = i.parentElement;
        }
      }
      constructor(t, e) {
        this.hasDragged = 0, this.pointerCaptureEventsToUnbind = [], this.eventsToUnbind = [], this.options = e, this.chart = t, this.runChartClick = !!e.chart.events?.click, this.pinchDown = [], this.setDOMEvents(), u(this, "afterInit");
      }
      normalize(t, e) {
        let i = t.touches,
          s = i ? i.length ? i.item(0) : y(i.changedTouches, t.changedTouches)[0] : t;
        e || (e = this.getChartPosition());
        let o = s.pageX - e.left,
          r = s.pageY - e.top;
        return c(t, {
          chartX: Math.round(o /= e.scaleX),
          chartY: Math.round(r /= e.scaleY)
        });
      }
      onContainerClick(t) {
        let e = this.chart,
          i = e.hoverPoint,
          s = this.normalize(t),
          o = e.plotLeft,
          r = e.plotTop;
        !e.cancelClick && (i && this.inClass(s.target, "highcharts-tracker") ? (u(i.series, "click", c(s, {
          point: i
        })), e.hoverPoint && i.firePointEvent("click", s)) : (c(s, this.getCoordinates(s)), e.isInsidePlot(s.chartX - o, s.chartY - r, {
          visiblePlotOnly: !0
        }) && u(e, "click", s)));
      }
      onContainerMouseDown(t) {
        let i = (1 & (t.buttons || t.button)) == 1;
        t = this.normalize(t), e.isFirefox && 0 !== t.button && this.onContainerMouseMove(t), (void 0 === t.button || i) && (this.zoomOption(t), i && t.preventDefault?.(), this.dragStart(t));
      }
      onContainerMouseLeave(t) {
        let {
          pointer: e
        } = r[y(M.hoverChartIndex, -1)] || {};
        t = this.normalize(t), this.onContainerMouseMove(t), e && !this.inClass(t.relatedTarget, "highcharts-tooltip") && (e.reset(), e.chartPosition = void 0);
      }
      onContainerMouseEnter() {
        delete this.chartPosition;
      }
      onContainerMouseMove(t) {
        let e = this.chart,
          i = e.tooltip,
          s = this.normalize(t);
        this.setHoverChartIndex(t), ("mousedown" === e.mouseIsDown || this.touchSelect(s)) && this.drag(s), !e.openMenu && (this.inClass(s.target, "highcharts-tracker") || e.isInsidePlot(s.chartX - e.plotLeft, s.chartY - e.plotTop, {
          visiblePlotOnly: !0
        })) && !(i && i.shouldStickOnContact(s)) && (this.inClass(s.target, "highcharts-no-tooltip") ? this.reset(!1, 0) : this.runPointActions(s));
      }
      onDocumentTouchEnd(t) {
        this.onDocumentMouseUp(t);
      }
      onContainerTouchMove(t) {
        this.touchSelect(t) ? this.onContainerMouseMove(t) : this.touch(t);
      }
      onContainerTouchStart(t) {
        this.touchSelect(t) ? this.onContainerMouseDown(t) : (this.zoomOption(t), this.touch(t, !0));
      }
      onDocumentMouseMove(t) {
        let e = this.chart,
          i = e.tooltip,
          s = this.chartPosition,
          o = this.normalize(t, s);
        !s || e.isInsidePlot(o.chartX - e.plotLeft, o.chartY - e.plotTop, {
          visiblePlotOnly: !0
        }) || i && i.shouldStickOnContact(o) || o.target !== e.container.ownerDocument && this.inClass(o.target, "highcharts-tracker") || this.reset();
      }
      onDocumentMouseUp(t) {
        r[y(M.hoverChartIndex, -1)]?.pointer?.drop(t);
      }
      pinch(t) {
        let e = this,
          {
            chart: i,
            hasZoom: s,
            lastTouches: o
          } = e,
          r = [].map.call(t.touches || [], t => e.normalize(t)),
          a = r.length,
          n = 1 === a && (e.inClass(t.target, "highcharts-tracker") && i.runTrackerClick || e.runChartClick),
          l = i.tooltip,
          h = 1 === a && y(l?.options.followTouchMove, !0);
        a > 1 ? e.initiated = !0 : h && (e.initiated = !1), s && e.initiated && !n && !1 !== t.cancelable && t.preventDefault(), "touchstart" === t.type ? (e.pinchDown = r, e.res = !0, i.mouseDownX = t.chartX) : h ? this.runPointActions(e.normalize(t)) : o && (u(i, "touchpan", {
          originalEvent: t,
          touches: r
        }, () => {
          let e = t => {
            let e = t[0],
              i = t[1] || e;
            return {
              x: e.chartX,
              y: e.chartY,
              width: i.chartX - e.chartX,
              height: i.chartY - e.chartY
            };
          };
          i.transform({
            axes: i.axes.filter(t => t.zoomEnabled && (this.zoomHor && t.horiz || this.zoomVert && !t.horiz)),
            to: e(r),
            from: e(o),
            trigger: t.type
          });
        }), e.res && (e.res = !1, this.reset(!1, 0))), e.lastTouches = r;
      }
      reset(t, e) {
        let i = this.chart,
          s = i.hoverSeries,
          o = i.hoverPoint,
          r = i.hoverPoints,
          a = i.tooltip,
          n = a && a.shared ? r : o;
        t && n && v(n).forEach(function (e) {
          e.series.isCartesian && void 0 === e.plotX && (t = !1);
        }), t ? a && n && v(n).length && (a.refresh(n), a.shared && r ? r.forEach(function (t) {
          t.setState(t.state, !0), t.series.isCartesian && (t.series.xAxis.crosshair && t.series.xAxis.drawCrosshair(null, t), t.series.yAxis.crosshair && t.series.yAxis.drawCrosshair(null, t));
        }) : o && (o.setState(o.state, !0), i.axes.forEach(function (t) {
          t.crosshair && o.series[t.coll] === t && t.drawCrosshair(null, o);
        }))) : (o && o.onMouseOut(), r && r.forEach(function (t) {
          t.setState();
        }), s && s.onMouseOut(), a && a.hide(e), this.unDocMouseMove && (this.unDocMouseMove = this.unDocMouseMove()), i.axes.forEach(function (t) {
          t.hideCrosshair();
        }), i.hoverPoints = i.hoverPoint = void 0);
      }
      runPointActions(t, e, i) {
        let s = this.chart,
          o = s.series,
          a = s.tooltip && s.tooltip.options.enabled ? s.tooltip : void 0,
          n = !!a && a.shared,
          h = e || s.hoverPoint,
          d = h && h.series || s.hoverSeries,
          c = (!t || "touchmove" !== t.type) && (!!e || d && d.directTouch && this.isDirectTouch),
          u = this.getHoverData(h, d, o, c, n, t);
        h = u.hoverPoint, d = u.hoverSeries;
        let g = u.hoverPoints,
          f = d && d.tooltipOptions.followPointer && !d.tooltipOptions.split,
          m = n && d && !d.noSharedTooltip;
        if (h && (i || h !== s.hoverPoint || a && a.isHidden)) {
          if ((s.hoverPoints || []).forEach(function (t) {
            -1 === g.indexOf(t) && t.setState();
          }), s.hoverSeries !== d && d.onMouseOver(), this.applyInactiveState(g), (g || []).forEach(function (t) {
            t.setState("hover");
          }), s.hoverPoint && s.hoverPoint.firePointEvent("mouseOut"), !h.series) return;
          s.hoverPoints = g, s.hoverPoint = h, h.firePointEvent("mouseOver", void 0, () => {
            a && h && a.refresh(m ? g : h, t);
          });
        } else if (f && a && !a.isHidden) {
          let e = a.getAnchor([{}], t);
          s.isInsidePlot(e[0], e[1], {
            visiblePlotOnly: !0
          }) && a.updatePosition({
            plotX: e[0],
            plotY: e[1]
          });
        }
        this.unDocMouseMove || (this.unDocMouseMove = l(s.container.ownerDocument, "mousemove", t => r[M.hoverChartIndex ?? -1]?.pointer?.onDocumentMouseMove(t)), this.eventsToUnbind.push(this.unDocMouseMove)), s.axes.forEach(function (e) {
          let i;
          let o = y((e.crosshair || {}).snap, !0);
          !o || (i = s.hoverPoint) && i.series[e.coll] === e || (i = p(g, t => t.series && t.series[e.coll] === e)), i || !o ? e.drawCrosshair(t, i) : e.hideCrosshair();
        });
      }
      setDOMEvents() {
        let t = this.chart.container,
          e = t.ownerDocument;
        t.onmousedown = this.onContainerMouseDown.bind(this), t.onmousemove = this.onContainerMouseMove.bind(this), t.onclick = this.onContainerClick.bind(this), this.eventsToUnbind.push(l(t, "mouseenter", this.onContainerMouseEnter.bind(this)), l(t, "mouseleave", this.onContainerMouseLeave.bind(this))), M.unbindDocumentMouseUp || (M.unbindDocumentMouseUp = []), M.unbindDocumentMouseUp.push(l(e, "mouseup", this.onDocumentMouseUp.bind(this)));
        let i = this.chart.renderTo.parentElement;
        for (; i && "BODY" !== i.tagName;) this.eventsToUnbind.push(l(i, "scroll", () => {
          delete this.chartPosition;
        })), i = i.parentElement;
        this.eventsToUnbind.push(l(t, "touchstart", this.onContainerTouchStart.bind(this), {
          passive: !1
        }), l(t, "touchmove", this.onContainerTouchMove.bind(this), {
          passive: !1
        })), M.unbindDocumentTouchEnd || (M.unbindDocumentTouchEnd = l(e, "touchend", this.onDocumentTouchEnd.bind(this), {
          passive: !1
        })), this.setPointerCapture(), l(this.chart, "redraw", this.setPointerCapture.bind(this));
      }
      setPointerCapture() {
        if (!n) return;
        let t = this.pointerCaptureEventsToUnbind,
          e = this.chart,
          i = e.container,
          s = y(e.options.tooltip?.followTouchMove, !0) && e.series.some(t => t.options.findNearestPointBy.indexOf("y") > -1);
        !this.hasPointerCapture && s ? (t.push(l(i, "pointerdown", t => {
          t.target?.hasPointerCapture(t.pointerId) && t.target?.releasePointerCapture(t.pointerId);
        }), l(i, "pointermove", t => {
          e.pointer?.getPointFromEvent(t)?.onMouseOver(t);
        })), e.styledMode || d(i, {
          "touch-action": "none"
        }), i.className += " highcharts-no-touch-action", this.hasPointerCapture = !0) : this.hasPointerCapture && !s && (t.forEach(t => t()), t.length = 0, e.styledMode || d(i, {
          "touch-action": y(e.options.chart.style?.["touch-action"], "manipulation")
        }), i.className = i.className.replace(" highcharts-no-touch-action", ""), this.hasPointerCapture = !1);
      }
      setHoverChartIndex(t) {
        let i = this.chart,
          s = e.charts[y(M.hoverChartIndex, -1)];
        if (s && s !== i) {
          let e = {
            relatedTarget: i.container
          };
          t && !t?.relatedTarget && (t = {
            ...e,
            ...t
          }), s.pointer?.onContainerMouseLeave(t || e);
        }
        s && s.mouseIsDown || (M.hoverChartIndex = i.index);
      }
      touch(t, e) {
        let i;
        let {
          chart: s,
          pinchDown: o = []
        } = this;
        this.setHoverChartIndex(), 1 === (t = this.normalize(t)).touches.length ? s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop, {
          visiblePlotOnly: !0
        }) && !s.openMenu ? (e && this.runPointActions(t), "touchmove" === t.type && (i = !!o[0] && Math.pow(o[0].chartX - t.chartX, 2) + Math.pow(o[0].chartY - t.chartY, 2) >= 16), y(i, !0) && this.pinch(t)) : e && this.reset() : 2 === t.touches.length && this.pinch(t);
      }
      touchSelect(t) {
        return !!(this.chart.zooming.singleTouch && t.touches && 1 === t.touches.length);
      }
      zoomOption(t) {
        let e = this.chart,
          i = e.inverted,
          s = e.zooming.type || "",
          o,
          r;
        /touch/.test(t.type) && (s = y(e.zooming.pinchType, s)), this.zoomX = o = /x/.test(s), this.zoomY = r = /y/.test(s), this.zoomHor = o && !i || r && i, this.zoomVert = r && !i || o && i, this.hasZoom = o || r;
      }
    }
    return (s = M || (M = {})).compose = function (t) {
      b(a, "Core.Pointer") && l(t, "beforeRender", function () {
        this.pointer = new s(this, this.options);
      });
    }, M;
  }), i(e, "Core/Legend/LegendSymbol.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      extend: i,
      merge: s,
      pick: o
    } = t;
    return function (t) {
      function e(t, e, r) {
        let a = this.legendItem = this.legendItem || {},
          {
            chart: n,
            options: l
          } = this,
          {
            baseline: h = 0,
            symbolWidth: d,
            symbolHeight: c
          } = t,
          p = this.symbol || "circle",
          u = c / 2,
          g = n.renderer,
          f = a.group,
          m = h - Math.round((t.fontMetrics?.b || c) * (r ? .4 : .3)),
          x = {},
          y,
          b = l.marker,
          v = 0;
        if (n.styledMode || (x["stroke-width"] = Math.min(l.lineWidth || 0, 24), l.dashStyle ? x.dashstyle = l.dashStyle : "square" === l.linecap || (x["stroke-linecap"] = "round")), a.line = g.path().addClass("highcharts-graph").attr(x).add(f), r && (a.area = g.path().addClass("highcharts-area").add(f)), x["stroke-linecap"] && (v = Math.min(a.line.strokeWidth(), d) / 2), d) {
          let t = [["M", v, m], ["L", d - v, m]];
          a.line.attr({
            d: t
          }), a.area?.attr({
            d: [...t, ["L", d - v, h], ["L", v, h]]
          });
        }
        if (b && !1 !== b.enabled && d) {
          let t = Math.min(o(b.radius, u), u);
          0 === p.indexOf("url") && (b = s(b, {
            width: c,
            height: c
          }), t = 0), a.symbol = y = g.symbol(p, d / 2 - t, m - t, 2 * t, 2 * t, i({
            context: "legend"
          }, b)).addClass("highcharts-point").add(f), y.isMarker = !0;
        }
      }
      t.areaMarker = function (t, i) {
        e.call(this, t, i, !0);
      }, t.lineMarker = e, t.rectangle = function (t, e) {
        let i = e.legendItem || {},
          s = t.options,
          r = t.symbolHeight,
          a = s.squareSymbol,
          n = a ? r : t.symbolWidth;
        i.symbol = this.chart.renderer.rect(a ? (t.symbolWidth - r) / 2 : 0, t.baseline - r + 1, n, r, o(t.options.symbolRadius, r / 2)).addClass("highcharts-point").attr({
          zIndex: 3
        }).add(i.group);
      };
    }(e || (e = {})), e;
  }), i(e, "Core/Series/SeriesDefaults.js", [], function () {
    return {
      lineWidth: 2,
      allowPointSelect: !1,
      crisp: !0,
      showCheckbox: !1,
      animation: {
        duration: 1e3
      },
      enableMouseTracking: !0,
      events: {},
      marker: {
        enabledThreshold: 2,
        lineColor: "#ffffff",
        lineWidth: 0,
        radius: 4,
        states: {
          normal: {
            animation: !0
          },
          hover: {
            animation: {
              duration: 150
            },
            enabled: !0,
            radiusPlus: 2,
            lineWidthPlus: 1
          },
          select: {
            fillColor: "#cccccc",
            lineColor: "#000000",
            lineWidth: 2
          }
        }
      },
      point: {
        events: {}
      },
      dataLabels: {
        animation: {},
        align: "center",
        borderWidth: 0,
        defer: !0,
        formatter: function () {
          let {
            numberFormatter: t
          } = this.series.chart;
          return "number" != typeof this.y ? "" : t(this.y, -1);
        },
        padding: 5,
        style: {
          fontSize: "0.7em",
          fontWeight: "bold",
          color: "contrast",
          textOutline: "1px contrast"
        },
        verticalAlign: "bottom",
        x: 0,
        y: 0
      },
      cropThreshold: 300,
      opacity: 1,
      pointRange: 0,
      softThreshold: !0,
      states: {
        normal: {
          animation: !0
        },
        hover: {
          animation: {
            duration: 150
          },
          lineWidthPlus: 1,
          marker: {},
          halo: {
            size: 10,
            opacity: .25
          }
        },
        select: {
          animation: {
            duration: 0
          }
        },
        inactive: {
          animation: {
            duration: 150
          },
          opacity: .2
        }
      },
      stickyTracking: !0,
      turboThreshold: 1e3,
      findNearestPointBy: "x"
    };
  }), i(e, "Core/Series/SeriesRegistry.js", [e["Core/Globals.js"], e["Core/Defaults.js"], e["Core/Series/Point.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    var o;
    let {
        defaultOptions: r
      } = e,
      {
        extend: a,
        extendClass: n,
        merge: l
      } = s;
    return function (e) {
      function s(t, s) {
        let o = r.plotOptions || {},
          a = s.defaultOptions,
          n = s.prototype;
        return n.type = t, n.pointClass || (n.pointClass = i), !e.seriesTypes[t] && (a && (o[t] = a), e.seriesTypes[t] = s, !0);
      }
      e.seriesTypes = t.seriesTypes, e.registerSeriesType = s, e.seriesType = function (t, o, h, d, c) {
        let p = r.plotOptions || {};
        if (o = o || "", p[t] = l(p[o], h), delete e.seriesTypes[t], s(t, n(e.seriesTypes[o] || function () {}, d)), e.seriesTypes[t].prototype.type = t, c) {
          class s extends i {}
          a(s.prototype, c), e.seriesTypes[t].prototype.pointClass = s;
        }
        return e.seriesTypes[t];
      };
    }(o || (o = {})), o;
  }), i(e, "Core/Series/Series.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Defaults.js"], e["Core/Foundation.js"], e["Core/Globals.js"], e["Core/Legend/LegendSymbol.js"], e["Core/Series/Point.js"], e["Core/Series/SeriesDefaults.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Renderer/SVG/SVGElement.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n, l, h) {
    let {
        animObject: d,
        setAnimation: c
      } = t,
      {
        defaultOptions: p
      } = e,
      {
        registerEventOptions: u
      } = i,
      {
        svg: g,
        win: f
      } = s,
      {
        seriesTypes: m
      } = n,
      {
        arrayMax: x,
        arrayMin: y,
        clamp: b,
        correctFloat: v,
        crisp: M,
        defined: C,
        destroyObjectProperties: S,
        diffObjects: w,
        erase: k,
        error: A,
        extend: T,
        find: P,
        fireEvent: L,
        getClosestDistance: j,
        getNestedProperty: O,
        insertItem: D,
        isArray: E,
        isNumber: I,
        isString: B,
        merge: z,
        objectEach: R,
        pick: N,
        removeEvent: G,
        splat: W,
        syncTimeout: X
      } = h;
    class H {
      constructor() {
        this.zoneAxis = "y";
      }
      init(t, e) {
        let i;
        L(this, "init", {
          options: e
        });
        let s = this,
          o = t.series;
        this.eventsToUnbind = [], s.chart = t, s.options = s.setOptions(e);
        let r = s.options,
          a = !1 !== r.visible;
        s.linkedSeries = [], s.bindAxes(), T(s, {
          name: r.name,
          state: "",
          visible: a,
          selected: !0 === r.selected
        }), u(this, r);
        let n = r.events;
        (n && n.click || r.point && r.point.events && r.point.events.click || r.allowPointSelect) && (t.runTrackerClick = !0), s.getColor(), s.getSymbol(), s.parallelArrays.forEach(function (t) {
          s[t + "Data"] || (s[t + "Data"] = []);
        }), s.isCartesian && (t.hasCartesianSeries = !0), o.length && (i = o[o.length - 1]), s._i = N(i && i._i, -1) + 1, s.opacity = s.options.opacity, t.orderItems("series", D(this, o)), r.dataSorting && r.dataSorting.enabled ? s.setDataSortingOptions() : s.points || s.data || s.setData(r.data, !1), L(this, "afterInit");
      }
      is(t) {
        return m[t] && this instanceof m[t];
      }
      bindAxes() {
        let t;
        let e = this,
          i = e.options,
          s = e.chart;
        L(this, "bindAxes", null, function () {
          (e.axisTypes || []).forEach(function (o) {
            (s[o] || []).forEach(function (s) {
              t = s.options, (N(i[o], 0) === s.index || void 0 !== i[o] && i[o] === t.id) && (D(e, s.series), e[o] = s, s.isDirty = !0);
            }), e[o] || e.optionalAxis === o || A(18, !0, s);
          });
        }), L(this, "afterBindAxes");
      }
      updateParallelArrays(t, e, i) {
        let s = t.series,
          o = I(e) ? function (i) {
            let o = "y" === i && s.toYData ? s.toYData(t) : t[i];
            s[i + "Data"][e] = o;
          } : function (t) {
            Array.prototype[e].apply(s[t + "Data"], i);
          };
        s.parallelArrays.forEach(o);
      }
      hasData() {
        return this.visible && void 0 !== this.dataMax && void 0 !== this.dataMin || this.visible && this.yData && this.yData.length > 0;
      }
      hasMarkerChanged(t, e) {
        let i = t.marker,
          s = e.marker || {};
        return i && (s.enabled && !i.enabled || s.symbol !== i.symbol || s.height !== i.height || s.width !== i.width);
      }
      autoIncrement(t) {
        let e = this.options,
          i = e.pointIntervalUnit,
          s = e.relativeXValue,
          o = this.chart.time,
          r = this.xIncrement,
          a,
          n;
        return (r = N(r, e.pointStart, 0), this.pointInterval = n = N(this.pointInterval, e.pointInterval, 1), s && I(t) && (n *= t), i && (a = new o.Date(r), "day" === i ? o.set("Date", a, o.get("Date", a) + n) : "month" === i ? o.set("Month", a, o.get("Month", a) + n) : "year" === i && o.set("FullYear", a, o.get("FullYear", a) + n), n = a.getTime() - r), s && I(t)) ? r + n : (this.xIncrement = r + n, r);
      }
      setDataSortingOptions() {
        let t = this.options;
        T(this, {
          requireSorting: !1,
          sorted: !1,
          enabledDataSorting: !0,
          allowDG: !1
        }), C(t.pointRange) || (t.pointRange = 1);
      }
      setOptions(t) {
        let e;
        let i = this.chart,
          s = i.options.plotOptions,
          o = i.userOptions || {},
          r = z(t),
          a = i.styledMode,
          n = {
            plotOptions: s,
            userOptions: r
          };
        L(this, "setOptions", n);
        let l = n.plotOptions[this.type],
          h = o.plotOptions || {},
          d = h.series || {},
          c = p.plotOptions[this.type] || {},
          u = h[this.type] || {};
        this.userOptions = n.userOptions;
        let g = z(l, s.series, u, r);
        this.tooltipOptions = z(p.tooltip, p.plotOptions.series?.tooltip, c?.tooltip, i.userOptions.tooltip, h.series?.tooltip, u.tooltip, r.tooltip), this.stickyTracking = N(r.stickyTracking, u.stickyTracking, d.stickyTracking, !!this.tooltipOptions.shared && !this.noSharedTooltip || g.stickyTracking), null === l.marker && delete g.marker, this.zoneAxis = g.zoneAxis || "y";
        let f = this.zones = (g.zones || []).map(t => ({
          ...t
        }));
        return (g.negativeColor || g.negativeFillColor) && !g.zones && (e = {
          value: g[this.zoneAxis + "Threshold"] || g.threshold || 0,
          className: "highcharts-negative"
        }, a || (e.color = g.negativeColor, e.fillColor = g.negativeFillColor), f.push(e)), f.length && C(f[f.length - 1].value) && f.push(a ? {} : {
          color: this.color,
          fillColor: this.fillColor
        }), L(this, "afterSetOptions", {
          options: g
        }), g;
      }
      getName() {
        return N(this.options.name, "Series " + (this.index + 1));
      }
      getCyclic(t, e, i) {
        let s, o;
        let r = this.chart,
          a = `${t}Index`,
          n = `${t}Counter`,
          l = i?.length || r.options.chart.colorCount;
        !e && (C(o = N("color" === t ? this.options.colorIndex : void 0, this[a])) ? s = o : (r.series.length || (r[n] = 0), s = r[n] % l, r[n] += 1), i && (e = i[s])), void 0 !== s && (this[a] = s), this[t] = e;
      }
      getColor() {
        this.chart.styledMode ? this.getCyclic("color") : this.options.colorByPoint ? this.color = "#cccccc" : this.getCyclic("color", this.options.color || p.plotOptions[this.type].color, this.chart.options.colors);
      }
      getPointsCollection() {
        return (this.hasGroupedData ? this.points : this.data) || [];
      }
      getSymbol() {
        let t = this.options.marker;
        this.getCyclic("symbol", t.symbol, this.chart.options.symbols);
      }
      findPointIndex(t, e) {
        let i, s, o;
        let a = t.id,
          n = t.x,
          l = this.points,
          h = this.options.dataSorting;
        if (a) {
          let t = this.chart.get(a);
          t instanceof r && (i = t);
        } else if (this.linkedParent || this.enabledDataSorting || this.options.relativeXValue) {
          let e = e => !e.touched && e.index === t.index;
          if (h && h.matchByName ? e = e => !e.touched && e.name === t.name : this.options.relativeXValue && (e = e => !e.touched && e.options.x === t.x), !(i = P(l, e))) return;
        }
        return i && void 0 !== (o = i && i.index) && (s = !0), void 0 === o && I(n) && (o = this.xData.indexOf(n, e)), -1 !== o && void 0 !== o && this.cropped && (o = o >= this.cropStart ? o - this.cropStart : o), !s && I(o) && l[o] && l[o].touched && (o = void 0), o;
      }
      updateData(t, e) {
        let i = this.options,
          s = i.dataSorting,
          o = this.points,
          r = [],
          a = this.requireSorting,
          n = t.length === o.length,
          l,
          h,
          d,
          c,
          p = !0;
        if (this.xIncrement = null, t.forEach(function (t, e) {
          let h;
          let d = C(t) && this.pointClass.prototype.optionsToObject.call({
              series: this
            }, t) || {},
            p = d.x;
          d.id || I(p) ? (-1 === (h = this.findPointIndex(d, c)) || void 0 === h ? r.push(t) : o[h] && t !== i.data[h] ? (o[h].update(t, !1, null, !1), o[h].touched = !0, a && (c = h + 1)) : o[h] && (o[h].touched = !0), (!n || e !== h || s && s.enabled || this.hasDerivedData) && (l = !0)) : r.push(t);
        }, this), l) for (h = o.length; h--;) (d = o[h]) && !d.touched && d.remove && d.remove(!1, e);else !n || s && s.enabled ? p = !1 : (t.forEach(function (t, e) {
          t === o[e].y || o[e].destroyed || o[e].update(t, !1, null, !1);
        }), r.length = 0);
        return o.forEach(function (t) {
          t && (t.touched = !1);
        }), !!p && (r.forEach(function (t) {
          this.addPoint(t, !1, null, null, !1);
        }, this), null === this.xIncrement && this.xData && this.xData.length && (this.xIncrement = x(this.xData), this.autoIncrement()), !0);
      }
      setData(t, e = !0, i, s) {
        let o = this,
          r = o.points,
          a = r && r.length || 0,
          n = o.options,
          l = o.chart,
          h = n.dataSorting,
          d = o.xAxis,
          c = n.turboThreshold,
          p = this.xData,
          u = this.yData,
          g = o.pointArrayMap,
          f = g && g.length,
          m = n.keys,
          x,
          y,
          b,
          v = 0,
          M = 1,
          C;
        l.options.chart.allowMutatingData || (n.data && delete o.options.data, o.userOptions.data && delete o.userOptions.data, C = z(!0, t));
        let S = (t = C || t || []).length;
        if (h && h.enabled && (t = this.sortData(t)), l.options.chart.allowMutatingData && !1 !== s && S && a && !o.cropped && !o.hasGroupedData && o.visible && !o.boosted && (b = this.updateData(t, i)), !b) {
          o.xIncrement = null, o.colorCounter = 0, this.parallelArrays.forEach(function (t) {
            o[t + "Data"].length = 0;
          });
          let e = c && S > c;
          if (e) {
            let i = o.getFirstValidPoint(t),
              s = o.getFirstValidPoint(t, S - 1, -1),
              r = t => !!(E(t) && (m || I(t[0])));
            if (I(i) && I(s)) for (x = 0; x < S; x++) p[x] = this.autoIncrement(), u[x] = t[x];else if (r(i) && r(s)) {
              if (f) {
                if (i.length === f) for (x = 0; x < S; x++) p[x] = this.autoIncrement(), u[x] = t[x];else for (x = 0; x < S; x++) y = t[x], p[x] = y[0], u[x] = y.slice(1, f + 1);
              } else if (m && (v = m.indexOf("x"), M = m.indexOf("y"), v = v >= 0 ? v : 0, M = M >= 0 ? M : 1), 1 === i.length && (M = 0), v === M) for (x = 0; x < S; x++) p[x] = this.autoIncrement(), u[x] = t[x][M];else for (x = 0; x < S; x++) y = t[x], p[x] = y[v], u[x] = y[M];
            } else e = !1;
          }
          if (!e) for (x = 0; x < S; x++) y = {
            series: o
          }, o.pointClass.prototype.applyOptions.apply(y, [t[x]]), o.updateParallelArrays(y, x);
          for (u && B(u[0]) && A(14, !0, l), o.data = [], o.options.data = o.userOptions.data = t, x = a; x--;) r[x]?.destroy();
          d && (d.minRange = d.userMinRange), o.isDirty = l.isDirtyBox = !0, o.isDirtyData = !!r, i = !1;
        }
        "point" === n.legendType && (this.processData(), this.generatePoints()), e && l.redraw(i);
      }
      sortData(t) {
        let e = this,
          i = e.options.dataSorting.sortKey || "y",
          s = function (t, e) {
            return C(e) && t.pointClass.prototype.optionsToObject.call({
              series: t
            }, e) || {};
          };
        return t.forEach(function (i, o) {
          t[o] = s(e, i), t[o].index = o;
        }, this), t.concat().sort((t, e) => {
          let s = O(i, t),
            o = O(i, e);
          return o < s ? -1 : o > s ? 1 : 0;
        }).forEach(function (t, e) {
          t.x = e;
        }, this), e.linkedSeries && e.linkedSeries.forEach(function (e) {
          let i = e.options,
            o = i.data;
          i.dataSorting && i.dataSorting.enabled || !o || (o.forEach(function (i, r) {
            o[r] = s(e, i), t[r] && (o[r].x = t[r].x, o[r].index = r);
          }), e.setData(o, !1));
        }), t;
      }
      getProcessedData(t) {
        let e = this,
          i = e.xAxis,
          s = e.options.cropThreshold,
          o = i?.logarithmic,
          r = e.isCartesian,
          a,
          n,
          l = 0,
          h,
          d,
          c,
          p = e.xData,
          u = e.yData,
          g = !1,
          f = p.length;
        i && (d = (h = i.getExtremes()).min, c = h.max, g = !!(i.categories && !i.names.length)), r && e.sorted && !t && (!s || f > s || e.forceCrop) && (p[f - 1] < d || p[0] > c ? (p = [], u = []) : e.yData && (p[0] < d || p[f - 1] > c) && (p = (a = this.cropData(e.xData, e.yData, d, c)).xData, u = a.yData, l = a.start, n = !0));
        let m = j([o ? p.map(o.log2lin) : p], () => e.requireSorting && !g && A(15, !1, e.chart));
        return {
          xData: p,
          yData: u,
          cropped: n,
          cropStart: l,
          closestPointRange: m
        };
      }
      processData(t) {
        let e = this.xAxis;
        if (this.isCartesian && !this.isDirty && !e.isDirty && !this.yAxis.isDirty && !t) return !1;
        let i = this.getProcessedData();
        this.cropped = i.cropped, this.cropStart = i.cropStart, this.processedXData = i.xData, this.processedYData = i.yData, this.closestPointRange = this.basePointRange = i.closestPointRange, L(this, "afterProcessData");
      }
      cropData(t, e, i, s) {
        let o = t.length,
          r,
          a,
          n = 0,
          l = o;
        for (r = 0; r < o; r++) if (t[r] >= i) {
          n = Math.max(0, r - 1);
          break;
        }
        for (a = r; a < o; a++) if (t[a] > s) {
          l = a + 1;
          break;
        }
        return {
          xData: t.slice(n, l),
          yData: e.slice(n, l),
          start: n,
          end: l
        };
      }
      generatePoints() {
        let t = this.options,
          e = this.processedData || t.data,
          i = this.processedXData,
          s = this.processedYData,
          o = this.pointClass,
          r = i.length,
          a = this.cropStart || 0,
          n = this.hasGroupedData,
          l = t.keys,
          h = [],
          d = t.dataGrouping && t.dataGrouping.groupAll ? a : 0,
          c,
          p,
          u,
          g,
          f = this.data;
        if (!f && !n) {
          let t = [];
          t.length = e.length, f = this.data = t;
        }
        for (l && n && (this.options.keys = !1), g = 0; g < r; g++) p = a + g, n ? ((u = new o(this, [i[g]].concat(W(s[g])))).dataGroup = this.groupMap[d + g], u.dataGroup.options && (u.options = u.dataGroup.options, T(u, u.dataGroup.options), delete u.dataLabels)) : (u = f[p]) || void 0 === e[p] || (f[p] = u = new o(this, e[p], i[g])), u && (u.index = n ? d + g : p, h[g] = u);
        if (this.options.keys = l, f && (r !== (c = f.length) || n)) for (g = 0; g < c; g++) g !== a || n || (g += r), f[g] && (f[g].destroyElements(), f[g].plotX = void 0);
        this.data = f, this.points = h, L(this, "afterGeneratePoints");
      }
      getXExtremes(t) {
        return {
          min: y(t),
          max: x(t)
        };
      }
      getExtremes(t, e) {
        let i = this.xAxis,
          s = this.yAxis,
          o = [],
          r = this.requireSorting && !this.is("column") ? 1 : 0,
          a = !!s && s.positiveValuesOnly,
          n = e || this.getExtremesFromAll || this.options.getExtremesFromAll,
          {
            processedXData: l,
            processedYData: h
          } = this,
          d,
          c,
          p,
          u,
          g,
          f,
          m,
          b = 0,
          v = 0,
          M = 0;
        if (this.cropped && n) {
          let t = this.getProcessedData(!0);
          l = t.xData, h = t.yData;
        }
        let C = (t = t || this.stackedYData || h || []).length,
          S = l || this.xData;
        for (i && (b = (d = i.getExtremes()).min, v = d.max), f = 0; f < C; f++) if (u = S[f], c = (I(g = t[f]) || E(g)) && ((I(g) ? g > 0 : g.length) || !a), p = e || this.getExtremesFromAll || this.options.getExtremesFromAll || this.cropped || !i || (S[f + r] || u) >= b && (S[f - r] || u) <= v, c && p) {
          if (m = g.length) for (; m--;) I(g[m]) && (o[M++] = g[m]);else o[M++] = g;
        }
        let w = {
          activeYData: o,
          dataMin: y(o),
          dataMax: x(o)
        };
        return L(this, "afterGetExtremes", {
          dataExtremes: w
        }), w;
      }
      applyExtremes() {
        let t = this.getExtremes();
        return this.dataMin = t.dataMin, this.dataMax = t.dataMax, t;
      }
      getFirstValidPoint(t, e = 0, i = 1) {
        let s = t.length,
          o = e;
        for (; o >= 0 && o < s;) {
          if (C(t[o])) return t[o];
          o += i;
        }
      }
      translate() {
        this.processedXData || this.processData(), this.generatePoints();
        let t = this.options,
          e = t.stacking,
          i = this.xAxis,
          s = i.categories,
          o = this.enabledDataSorting,
          r = this.yAxis,
          a = this.points,
          n = a.length,
          l = this.pointPlacementToXValue(),
          h = !!l,
          d = t.threshold,
          c = t.startFromThreshold ? d : 0,
          p,
          u,
          g,
          f,
          m = Number.MAX_VALUE;
        function x(t) {
          return b(t, -1e9, 1e9);
        }
        for (p = 0; p < n; p++) {
          let t;
          let n = a[p],
            y = n.x,
            b,
            M,
            S = n.y,
            w = n.low,
            k = e && r.stacking?.stacks[(this.negStacks && S < (c ? 0 : d) ? "-" : "") + this.stackKey];
          u = i.translate(y, !1, !1, !1, !0, l), n.plotX = I(u) ? v(x(u)) : void 0, e && this.visible && k && k[y] && (f = this.getStackIndicator(f, y, this.index), !n.isNull && f.key && (M = (b = k[y]).points[f.key]), b && E(M) && (w = M[0], S = M[1], w === c && f.key === k[y].base && (w = N(I(d) ? d : r.min)), r.positiveValuesOnly && C(w) && w <= 0 && (w = void 0), n.total = n.stackTotal = N(b.total), n.percentage = C(n.y) && b.total ? n.y / b.total * 100 : void 0, n.stackY = S, this.irregularWidths || b.setOffset(this.pointXOffset || 0, this.barW || 0, void 0, void 0, void 0, this.xAxis))), n.yBottom = C(w) ? x(r.translate(w, !1, !0, !1, !0)) : void 0, this.dataModify && (S = this.dataModify.modifyValue(S, p)), I(S) && void 0 !== n.plotX && (t = I(t = r.translate(S, !1, !0, !1, !0)) ? x(t) : void 0), n.plotY = t, n.isInside = this.isPointInside(n), n.clientX = h ? v(i.translate(y, !1, !1, !1, !0, l)) : u, n.negative = (n.y || 0) < (d || 0), n.category = N(s && s[n.x], n.x), n.isNull || !1 === n.visible || (void 0 !== g && (m = Math.min(m, Math.abs(u - g))), g = u), n.zone = this.zones.length ? n.getZone() : void 0, !n.graphic && this.group && o && (n.isNew = !0);
        }
        this.closestPointRangePx = m, L(this, "afterTranslate");
      }
      getValidPoints(t, e, i) {
        let s = this.chart;
        return (t || this.points || []).filter(function (t) {
          let {
            plotX: o,
            plotY: r
          } = t;
          return !!((i || !t.isNull && I(r)) && (!e || s.isInsidePlot(o, r, {
            inverted: s.inverted
          }))) && !1 !== t.visible;
        });
      }
      getClipBox() {
        let {
            chart: t,
            xAxis: e,
            yAxis: i
          } = this,
          {
            x: s,
            y: o,
            width: r,
            height: a
          } = z(t.clipBox);
        return e && e.len !== t.plotSizeX && (r = e.len), i && i.len !== t.plotSizeY && (a = i.len), t.inverted && !this.invertible && ([r, a] = [a, r]), {
          x: s,
          y: o,
          width: r,
          height: a
        };
      }
      getSharedClipKey() {
        return this.sharedClipKey = (this.options.xAxis || 0) + "," + (this.options.yAxis || 0), this.sharedClipKey;
      }
      setClip() {
        let {
            chart: t,
            group: e,
            markerGroup: i
          } = this,
          s = t.sharedClips,
          o = t.renderer,
          r = this.getClipBox(),
          a = this.getSharedClipKey(),
          n = s[a];
        n ? n.animate(r) : s[a] = n = o.clipRect(r), e && e.clip(!1 === this.options.clip ? void 0 : n), i && i.clip();
      }
      animate(t) {
        let {
            chart: e,
            group: i,
            markerGroup: s
          } = this,
          o = e.inverted,
          r = d(this.options.animation),
          a = [this.getSharedClipKey(), r.duration, r.easing, r.defer].join(","),
          n = e.sharedClips[a],
          l = e.sharedClips[a + "m"];
        if (t && i) {
          let t = this.getClipBox();
          if (n) n.attr("height", t.height);else {
            t.width = 0, o && (t.x = e.plotHeight), n = e.renderer.clipRect(t), e.sharedClips[a] = n;
            let i = {
              x: -99,
              y: -99,
              width: o ? e.plotWidth + 199 : 99,
              height: o ? 99 : e.plotHeight + 199
            };
            l = e.renderer.clipRect(i), e.sharedClips[a + "m"] = l;
          }
          i.clip(n), s?.clip(l);
        } else if (n && !n.hasClass("highcharts-animating")) {
          let t = this.getClipBox(),
            i = r.step;
          (s?.element.childNodes.length || e.series.length > 1) && (r.step = function (t, e) {
            i && i.apply(e, arguments), "width" === e.prop && l?.element && l.attr(o ? "height" : "width", t + 99);
          }), n.addClass("highcharts-animating").animate(t, r);
        }
      }
      afterAnimate() {
        this.setClip(), R(this.chart.sharedClips, (t, e, i) => {
          t && !this.chart.container.querySelector(`[clip-path="url(#${t.id})"]`) && (t.destroy(), delete i[e]);
        }), this.finishedAnimating = !0, L(this, "afterAnimate");
      }
      drawPoints(t = this.points) {
        let e, i, s, o, r, a, n;
        let l = this.chart,
          h = l.styledMode,
          {
            colorAxis: d,
            options: c
          } = this,
          p = c.marker,
          u = this[this.specialGroup || "markerGroup"],
          g = this.xAxis,
          f = N(p.enabled, !g || !!g.isRadial || null, this.closestPointRangePx >= p.enabledThreshold * p.radius);
        if (!1 !== p.enabled || this._hasPointMarkers) for (e = 0; e < t.length; e++) if (o = (s = (i = t[e]).graphic) ? "animate" : "attr", r = i.marker || {}, a = !!i.marker, (f && void 0 === r.enabled || r.enabled) && !i.isNull && !1 !== i.visible) {
          let t = N(r.symbol, this.symbol, "rect");
          n = this.markerAttribs(i, i.selected && "select"), this.enabledDataSorting && (i.startXPos = g.reversed ? -(n.width || 0) : g.width);
          let e = !1 !== i.isInside;
          if (!s && e && ((n.width || 0) > 0 || i.hasImage) && (i.graphic = s = l.renderer.symbol(t, n.x, n.y, n.width, n.height, a ? r : p).add(u), this.enabledDataSorting && l.hasRendered && (s.attr({
            x: i.startXPos
          }), o = "animate")), s && "animate" === o && s[e ? "show" : "hide"](e).animate(n), s) {
            let t = this.pointAttribs(i, h || !i.selected ? void 0 : "select");
            h ? d && s.css({
              fill: t.fill
            }) : s[o](t);
          }
          s && s.addClass(i.getClassName(), !0);
        } else s && (i.graphic = s.destroy());
      }
      markerAttribs(t, e) {
        let i = this.options,
          s = i.marker,
          o = t.marker || {},
          r = o.symbol || s.symbol,
          a = {},
          n,
          l,
          h = N(o.radius, s && s.radius);
        e && (n = s.states[e], h = N((l = o.states && o.states[e]) && l.radius, n && n.radius, h && h + (n && n.radiusPlus || 0))), t.hasImage = r && 0 === r.indexOf("url"), t.hasImage && (h = 0);
        let d = t.pos();
        return I(h) && d && (i.crisp && (d[0] = M(d[0], t.hasImage ? 0 : "rect" === r ? s?.lineWidth || 0 : 1)), a.x = d[0] - h, a.y = d[1] - h), h && (a.width = a.height = 2 * h), a;
      }
      pointAttribs(t, e) {
        let i = this.options.marker,
          s = t && t.options,
          o = s && s.marker || {},
          r = s && s.color,
          a = t && t.color,
          n = t && t.zone && t.zone.color,
          l,
          h,
          d = this.color,
          c,
          p,
          u = N(o.lineWidth, i.lineWidth),
          g = 1;
        return d = r || n || a || d, c = o.fillColor || i.fillColor || d, p = o.lineColor || i.lineColor || d, e = e || "normal", l = i.states[e] || {}, u = N((h = o.states && o.states[e] || {}).lineWidth, l.lineWidth, u + N(h.lineWidthPlus, l.lineWidthPlus, 0)), c = h.fillColor || l.fillColor || c, {
          stroke: p = h.lineColor || l.lineColor || p,
          "stroke-width": u,
          fill: c,
          opacity: g = N(h.opacity, l.opacity, g)
        };
      }
      destroy(t) {
        let e, i, s;
        let o = this,
          r = o.chart,
          a = /AppleWebKit\/533/.test(f.navigator.userAgent),
          n = o.data || [];
        for (L(o, "destroy", {
          keepEventsForUpdate: t
        }), this.removeEvents(t), (o.axisTypes || []).forEach(function (t) {
          (s = o[t]) && s.series && (k(s.series, o), s.isDirty = s.forceRedraw = !0);
        }), o.legendItem && o.chart.legend.destroyItem(o), e = n.length; e--;) (i = n[e]) && i.destroy && i.destroy();
        for (let t of o.zones) S(t, void 0, !0);
        h.clearTimeout(o.animationTimeout), R(o, function (t, e) {
          t instanceof l && !t.survive && t[a && "group" === e ? "hide" : "destroy"]();
        }), r.hoverSeries === o && (r.hoverSeries = void 0), k(r.series, o), r.orderItems("series"), R(o, function (e, i) {
          t && "hcEvents" === i || delete o[i];
        });
      }
      applyZones() {
        let {
            area: t,
            chart: e,
            graph: i,
            zones: s,
            points: o,
            xAxis: r,
            yAxis: a,
            zoneAxis: n
          } = this,
          {
            inverted: l,
            renderer: h
          } = e,
          d = this[`${n}Axis`],
          {
            isXAxis: c,
            len: p = 0
          } = d || {},
          u = (i?.strokeWidth() || 0) / 2 + 1,
          g = (t, e = 0, i = 0) => {
            l && (i = p - i);
            let {
                translated: s = 0,
                lineClip: o
              } = t,
              r = i - s;
            o?.push(["L", e, Math.abs(r) < u ? i - u * (r <= 0 ? -1 : 1) : s]);
          };
        if (s.length && (i || t) && d && I(d.min)) {
          let e = d.getExtremes().max,
            u = t => {
              t.forEach((e, i) => {
                ("M" === e[0] || "L" === e[0]) && (t[i] = [e[0], c ? p - e[1] : e[1], c ? e[2] : p - e[2]]);
              });
            };
          if (s.forEach(t => {
            t.lineClip = [], t.translated = b(d.toPixels(N(t.value, e), !0) || 0, 0, p);
          }), i && !this.showLine && i.hide(), t && t.hide(), "y" === n && o.length < r.len) for (let t of o) {
            let {
                plotX: e,
                plotY: i,
                zone: o
              } = t,
              r = o && s[s.indexOf(o) - 1];
            o && g(o, e, i), r && g(r, e, i);
          }
          let f = [],
            m = d.toPixels(d.getExtremes().min, !0);
          s.forEach(e => {
            let s = e.lineClip || [],
              o = Math.round(e.translated || 0);
            r.reversed && s.reverse();
            let {
                clip: n,
                simpleClip: d
              } = e,
              p = 0,
              g = 0,
              x = r.len,
              y = a.len;
            c ? (p = o, x = m) : (g = o, y = m);
            let b = [["M", p, g], ["L", x, g], ["L", x, y], ["L", p, y], ["Z"]],
              v = [b[0], ...s, b[1], b[2], ...f, b[3], b[4]];
            f = s.reverse(), m = o, l && (u(v), t && u(b)), n ? (n.animate({
              d: v
            }), d?.animate({
              d: b
            })) : (n = e.clip = h.path(v), t && (d = e.simpleClip = h.path(b))), i && e.graph?.clip(n), t && e.area?.clip(d);
          });
        } else this.visible && (i && i.show(), t && t.show());
      }
      plotGroup(t, e, i, s, o) {
        let r = this[t],
          a = !r,
          n = {
            visibility: i,
            zIndex: s || .1
          };
        return C(this.opacity) && !this.chart.styledMode && "inactive" !== this.state && (n.opacity = this.opacity), r || (this[t] = r = this.chart.renderer.g().add(o)), r.addClass("highcharts-" + e + " highcharts-series-" + this.index + " highcharts-" + this.type + "-series " + (C(this.colorIndex) ? "highcharts-color-" + this.colorIndex + " " : "") + (this.options.className || "") + (r.hasClass("highcharts-tracker") ? " highcharts-tracker" : ""), !0), r.attr(n)[a ? "attr" : "animate"](this.getPlotBox(e)), r;
      }
      getPlotBox(t) {
        let e = this.xAxis,
          i = this.yAxis,
          s = this.chart,
          o = s.inverted && !s.polar && e && this.invertible && "series" === t;
        return s.inverted && (e = i, i = this.xAxis), {
          translateX: e ? e.left : s.plotLeft,
          translateY: i ? i.top : s.plotTop,
          rotation: o ? 90 : 0,
          rotationOriginX: o ? (e.len - i.len) / 2 : 0,
          rotationOriginY: o ? (e.len + i.len) / 2 : 0,
          scaleX: o ? -1 : 1,
          scaleY: 1
        };
      }
      removeEvents(t) {
        let {
          eventsToUnbind: e
        } = this;
        t || G(this), e.length && (e.forEach(t => {
          t();
        }), e.length = 0);
      }
      render() {
        let t = this,
          {
            chart: e,
            options: i,
            hasRendered: s
          } = t,
          o = d(i.animation),
          r = t.visible ? "inherit" : "hidden",
          a = i.zIndex,
          n = e.seriesGroup,
          l = t.finishedAnimating ? 0 : o.duration;
        L(this, "render"), t.plotGroup("group", "series", r, a, n), t.markerGroup = t.plotGroup("markerGroup", "markers", r, a, n), !1 !== i.clip && t.setClip(), l && t.animate?.(!0), t.drawGraph && (t.drawGraph(), t.applyZones()), t.visible && t.drawPoints(), t.drawDataLabels?.(), t.redrawPoints?.(), i.enableMouseTracking && t.drawTracker?.(), l && t.animate?.(), s || (l && o.defer && (l += o.defer), t.animationTimeout = X(() => {
          t.afterAnimate();
        }, l || 0)), t.isDirty = !1, t.hasRendered = !0, L(t, "afterRender");
      }
      redraw() {
        let t = this.isDirty || this.isDirtyData;
        this.translate(), this.render(), t && delete this.kdTree;
      }
      reserveSpace() {
        return this.visible || !this.chart.options.chart.ignoreHiddenSeries;
      }
      searchPoint(t, e) {
        let {
            xAxis: i,
            yAxis: s
          } = this,
          o = this.chart.inverted;
        return this.searchKDTree({
          clientX: o ? i.len - t.chartY + i.pos : t.chartX - i.pos,
          plotY: o ? s.len - t.chartX + s.pos : t.chartY - s.pos
        }, e, t);
      }
      buildKDTree(t) {
        this.buildingKdTree = !0;
        let e = this,
          i = e.options.findNearestPointBy.indexOf("y") > -1 ? 2 : 1;
        delete e.kdTree, X(function () {
          e.kdTree = function t(i, s, o) {
            let r, a;
            let n = i?.length;
            if (n) return r = e.kdAxisArray[s % o], i.sort((t, e) => (t[r] || 0) - (e[r] || 0)), {
              point: i[a = Math.floor(n / 2)],
              left: t(i.slice(0, a), s + 1, o),
              right: t(i.slice(a + 1), s + 1, o)
            };
          }(e.getValidPoints(void 0, !e.directTouch), i, i), e.buildingKdTree = !1;
        }, e.options.kdNow || t?.type === "touchstart" ? 0 : 1);
      }
      searchKDTree(t, e, i) {
        let s = this,
          [o, r] = this.kdAxisArray,
          a = e ? "distX" : "dist",
          n = (s.options.findNearestPointBy || "").indexOf("y") > -1 ? 2 : 1,
          l = !!s.isBubble;
        if (this.kdTree || this.buildingKdTree || this.buildKDTree(i), this.kdTree) return function t(e, i, n, h) {
          let d = i.point,
            c = s.kdAxisArray[n % h],
            p,
            u,
            g = d;
          !function (t, e) {
            let i = t[o],
              s = e[o],
              a = C(i) && C(s) ? i - s : null,
              n = t[r],
              h = e[r],
              d = C(n) && C(h) ? n - h : 0,
              c = l && e.marker?.radius || 0;
            e.dist = Math.sqrt((a && a * a || 0) + d * d) - c, e.distX = C(a) ? Math.abs(a) - c : Number.MAX_VALUE;
          }(e, d);
          let f = (e[c] || 0) - (d[c] || 0) + (l && d.marker?.radius || 0),
            m = f < 0 ? "left" : "right",
            x = f < 0 ? "right" : "left";
          return i[m] && (g = (p = t(e, i[m], n + 1, h))[a] < g[a] ? p : d), i[x] && Math.sqrt(f * f) < g[a] && (g = (u = t(e, i[x], n + 1, h))[a] < g[a] ? u : g), g;
        }(t, this.kdTree, n, n);
      }
      pointPlacementToXValue() {
        let {
            options: t,
            xAxis: e
          } = this,
          i = t.pointPlacement;
        return "between" === i && (i = e.reversed ? -.5 : .5), I(i) ? i * (t.pointRange || e.pointRange) : 0;
      }
      isPointInside(t) {
        let {
            chart: e,
            xAxis: i,
            yAxis: s
          } = this,
          {
            plotX: o = -1,
            plotY: r = -1
          } = t;
        return r >= 0 && r <= (s ? s.len : e.plotHeight) && o >= 0 && o <= (i ? i.len : e.plotWidth);
      }
      drawTracker() {
        let t = this,
          e = t.options,
          i = e.trackByArea,
          s = [].concat((i ? t.areaPath : t.graphPath) || []),
          o = t.chart,
          r = o.pointer,
          a = o.renderer,
          n = o.options.tooltip?.snap || 0,
          l = () => {
            e.enableMouseTracking && o.hoverSeries !== t && t.onMouseOver();
          },
          h = "rgba(192,192,192," + (g ? 1e-4 : .002) + ")",
          d = t.tracker;
        d ? d.attr({
          d: s
        }) : t.graph && (t.tracker = d = a.path(s).attr({
          visibility: t.visible ? "inherit" : "hidden",
          zIndex: 2
        }).addClass(i ? "highcharts-tracker-area" : "highcharts-tracker-line").add(t.group), o.styledMode || d.attr({
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          stroke: h,
          fill: i ? h : "none",
          "stroke-width": t.graph.strokeWidth() + (i ? 0 : 2 * n)
        }), [t.tracker, t.markerGroup, t.dataLabelsGroup].forEach(t => {
          t && (t.addClass("highcharts-tracker").on("mouseover", l).on("mouseout", t => {
            r?.onTrackerMouseOut(t);
          }), e.cursor && !o.styledMode && t.css({
            cursor: e.cursor
          }), t.on("touchstart", l));
        })), L(this, "afterDrawTracker");
      }
      addPoint(t, e, i, s, o) {
        let r, a;
        let n = this.options,
          l = this.data,
          h = this.chart,
          d = this.xAxis,
          c = d && d.hasNames && d.names,
          p = n.data,
          u = this.xData;
        e = N(e, !0);
        let g = {
          series: this
        };
        this.pointClass.prototype.applyOptions.apply(g, [t]);
        let f = g.x;
        if (a = u.length, this.requireSorting && f < u[a - 1]) for (r = !0; a && u[a - 1] > f;) a--;
        this.updateParallelArrays(g, "splice", [a, 0, 0]), this.updateParallelArrays(g, a), c && g.name && (c[f] = g.name), p.splice(a, 0, t), (r || this.processedData) && (this.data.splice(a, 0, null), this.processData()), "point" === n.legendType && this.generatePoints(), i && (l[0] && l[0].remove ? l[0].remove(!1) : (l.shift(), this.updateParallelArrays(g, "shift"), p.shift())), !1 !== o && L(this, "addPoint", {
          point: g
        }), this.isDirty = !0, this.isDirtyData = !0, e && h.redraw(s);
      }
      removePoint(t, e, i) {
        let s = this,
          o = s.data,
          r = o[t],
          a = s.points,
          n = s.chart,
          l = function () {
            a && a.length === o.length && a.splice(t, 1), o.splice(t, 1), s.options.data.splice(t, 1), s.updateParallelArrays(r || {
              series: s
            }, "splice", [t, 1]), r && r.destroy(), s.isDirty = !0, s.isDirtyData = !0, e && n.redraw();
          };
        c(i, n), e = N(e, !0), r ? r.firePointEvent("remove", null, l) : l();
      }
      remove(t, e, i, s) {
        let o = this,
          r = o.chart;
        function a() {
          o.destroy(s), r.isDirtyLegend = r.isDirtyBox = !0, r.linkSeries(s), N(t, !0) && r.redraw(e);
        }
        !1 !== i ? L(o, "remove", null, a) : a();
      }
      update(t, e) {
        L(this, "update", {
          options: t = w(t, this.userOptions)
        });
        let i = this,
          s = i.chart,
          o = i.userOptions,
          r = i.initialType || i.type,
          a = s.options.plotOptions,
          n = m[r].prototype,
          l = i.finishedAnimating && {
            animation: !1
          },
          h = {},
          d,
          c,
          p = ["colorIndex", "eventOptions", "navigatorSeries", "symbolIndex", "baseSeries"],
          u = t.type || o.type || s.options.chart.type,
          g = !(this.hasDerivedData || u && u !== this.type || void 0 !== t.pointStart || void 0 !== t.pointInterval || void 0 !== t.relativeXValue || t.joinBy || t.mapData || ["dataGrouping", "pointStart", "pointInterval", "pointIntervalUnit", "keys"].some(t => i.hasOptionChanged(t)));
        u = u || r, g && (p.push("data", "isDirtyData", "isDirtyCanvas", "points", "processedData", "processedXData", "processedYData", "xIncrement", "cropped", "_hasPointMarkers", "hasDataLabels", "nodes", "layout", "level", "mapMap", "mapData", "minY", "maxY", "minX", "maxX", "transformGroups"), !1 !== t.visible && p.push("area", "graph"), i.parallelArrays.forEach(function (t) {
          p.push(t + "Data");
        }), t.data && (t.dataSorting && T(i.options.dataSorting, t.dataSorting), this.setData(t.data, !1))), t = z(o, {
          index: void 0 === o.index ? i.index : o.index,
          pointStart: a?.series?.pointStart ?? o.pointStart ?? i.xData?.[0]
        }, !g && {
          data: i.options.data
        }, t, l), g && t.data && (t.data = i.options.data), (p = ["group", "markerGroup", "dataLabelsGroup", "transformGroup"].concat(p)).forEach(function (t) {
          p[t] = i[t], delete i[t];
        });
        let f = !1;
        if (m[u]) {
          if (f = u !== i.type, i.remove(!1, !1, !1, !0), f) {
            if (s.propFromSeries(), Object.setPrototypeOf) Object.setPrototypeOf(i, m[u].prototype);else {
              let t = Object.hasOwnProperty.call(i, "hcEvents") && i.hcEvents;
              for (c in n) i[c] = void 0;
              T(i, m[u].prototype), t ? i.hcEvents = t : delete i.hcEvents;
            }
          }
        } else A(17, !0, s, {
          missingModuleFor: u
        });
        if (p.forEach(function (t) {
          i[t] = p[t];
        }), i.init(s, t), g && this.points) for (let t of (!1 === (d = i.options).visible ? (h.graphic = 1, h.dataLabel = 1) : (this.hasMarkerChanged(d, o) && (h.graphic = 1), i.hasDataLabels?.() || (h.dataLabel = 1)), this.points)) t && t.series && (t.resolveColor(), Object.keys(h).length && t.destroyElements(h), !1 === d.showInLegend && t.legendItem && s.legend.destroyItem(t));
        i.initialType = r, s.linkSeries(), s.setSortedData(), f && i.linkedSeries.length && (i.isDirtyData = !0), L(this, "afterUpdate"), N(e, !0) && s.redraw(!!g && void 0);
      }
      setName(t) {
        this.name = this.options.name = this.userOptions.name = t, this.chart.isDirtyLegend = !0;
      }
      hasOptionChanged(t) {
        let e = this.chart,
          i = this.options[t],
          s = e.options.plotOptions,
          o = this.userOptions[t],
          r = N(s?.[this.type]?.[t], s?.series?.[t]);
        return o && !C(r) ? i !== o : i !== N(r, i);
      }
      onMouseOver() {
        let t = this.chart,
          e = t.hoverSeries,
          i = t.pointer;
        i?.setHoverChartIndex(), e && e !== this && e.onMouseOut(), this.options.events.mouseOver && L(this, "mouseOver"), this.setState("hover"), t.hoverSeries = this;
      }
      onMouseOut() {
        let t = this.options,
          e = this.chart,
          i = e.tooltip,
          s = e.hoverPoint;
        e.hoverSeries = null, s && s.onMouseOut(), this && t.events.mouseOut && L(this, "mouseOut"), i && !this.stickyTracking && (!i.shared || this.noSharedTooltip) && i.hide(), e.series.forEach(function (t) {
          t.setState("", !0);
        });
      }
      setState(t, e) {
        let i = this,
          s = i.options,
          o = i.graph,
          r = s.inactiveOtherPoints,
          a = s.states,
          n = N(a[t || "normal"] && a[t || "normal"].animation, i.chart.options.chart.animation),
          l = s.lineWidth,
          h = s.opacity;
        if (t = t || "", i.state !== t && ([i.group, i.markerGroup, i.dataLabelsGroup].forEach(function (e) {
          e && (i.state && e.removeClass("highcharts-series-" + i.state), t && e.addClass("highcharts-series-" + t));
        }), i.state = t, !i.chart.styledMode)) {
          if (a[t] && !1 === a[t].enabled) return;
          if (t && (l = a[t].lineWidth || l + (a[t].lineWidthPlus || 0), h = N(a[t].opacity, h)), o && !o.dashstyle && I(l)) for (let t of [o, ...this.zones.map(t => t.graph)]) t?.animate({
            "stroke-width": l
          }, n);
          r || [i.group, i.markerGroup, i.dataLabelsGroup, i.labelBySeries].forEach(function (t) {
            t && t.animate({
              opacity: h
            }, n);
          });
        }
        e && r && i.points && i.setAllPointsToState(t || void 0);
      }
      setAllPointsToState(t) {
        this.points.forEach(function (e) {
          e.setState && e.setState(t);
        });
      }
      setVisible(t, e) {
        let i = this,
          s = i.chart,
          o = s.options.chart.ignoreHiddenSeries,
          r = i.visible;
        i.visible = t = i.options.visible = i.userOptions.visible = void 0 === t ? !r : t;
        let a = t ? "show" : "hide";
        ["group", "dataLabelsGroup", "markerGroup", "tracker", "tt"].forEach(t => {
          i[t]?.[a]();
        }), (s.hoverSeries === i || s.hoverPoint?.series === i) && i.onMouseOut(), i.legendItem && s.legend.colorizeItem(i, t), i.isDirty = !0, i.options.stacking && s.series.forEach(t => {
          t.options.stacking && t.visible && (t.isDirty = !0);
        }), i.linkedSeries.forEach(e => {
          e.setVisible(t, !1);
        }), o && (s.isDirtyBox = !0), L(i, a), !1 !== e && s.redraw();
      }
      show() {
        this.setVisible(!0);
      }
      hide() {
        this.setVisible(!1);
      }
      select(t) {
        this.selected = t = this.options.selected = void 0 === t ? !this.selected : t, this.checkbox && (this.checkbox.checked = t), L(this, t ? "select" : "unselect");
      }
      shouldShowTooltip(t, e, i = {}) {
        return i.series = this, i.visiblePlotOnly = !0, this.chart.isInsidePlot(t, e, i);
      }
      drawLegendSymbol(t, e) {
        o[this.options.legendSymbol || "rectangle"]?.call(this, t, e);
      }
    }
    return H.defaultOptions = a, H.types = n.seriesTypes, H.registerType = n.registerSeriesType, T(H.prototype, {
      axisTypes: ["xAxis", "yAxis"],
      coll: "series",
      colorCounter: 0,
      directTouch: !1,
      invertible: !0,
      isCartesian: !0,
      kdAxisArray: ["clientX", "plotY"],
      parallelArrays: ["x", "y"],
      pointClass: r,
      requireSorting: !0,
      sorted: !0
    }), n.series = H, H;
  }), i(e, "Core/Legend/Legend.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Foundation.js"], e["Core/Globals.js"], e["Core/Series/Series.js"], e["Core/Series/Point.js"], e["Core/Renderer/RendererUtilities.js"], e["Core/Templating.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n) {
    var l;
    let {
        animObject: h,
        setAnimation: d
      } = t,
      {
        registerEventOptions: c
      } = e,
      {
        composed: p,
        marginNames: u
      } = i,
      {
        distribute: g
      } = r,
      {
        format: f
      } = a,
      {
        addEvent: m,
        createElement: x,
        css: y,
        defined: b,
        discardElement: v,
        find: M,
        fireEvent: C,
        isNumber: S,
        merge: w,
        pick: k,
        pushUnique: A,
        relativeLength: T,
        stableSort: P,
        syncTimeout: L
      } = n;
    class j {
      constructor(t, e) {
        this.allItems = [], this.initialItemY = 0, this.itemHeight = 0, this.itemMarginBottom = 0, this.itemMarginTop = 0, this.itemX = 0, this.itemY = 0, this.lastItemY = 0, this.lastLineHeight = 0, this.legendHeight = 0, this.legendWidth = 0, this.maxItemWidth = 0, this.maxLegendWidth = 0, this.offsetWidth = 0, this.padding = 0, this.pages = [], this.symbolHeight = 0, this.symbolWidth = 0, this.titleHeight = 0, this.totalItemWidth = 0, this.widthOption = 0, this.chart = t, this.setOptions(e), e.enabled && (this.render(), c(this, e), m(this.chart, "endResize", function () {
          this.legend.positionCheckboxes();
        })), m(this.chart, "render", () => {
          this.options.enabled && this.proximate && (this.proximatePositions(), this.positionItems());
        });
      }
      setOptions(t) {
        let e = k(t.padding, 8);
        this.options = t, this.chart.styledMode || (this.itemStyle = t.itemStyle, this.itemHiddenStyle = w(this.itemStyle, t.itemHiddenStyle)), this.itemMarginTop = t.itemMarginTop, this.itemMarginBottom = t.itemMarginBottom, this.padding = e, this.initialItemY = e - 5, this.symbolWidth = k(t.symbolWidth, 16), this.pages = [], this.proximate = "proximate" === t.layout && !this.chart.inverted, this.baseline = void 0;
      }
      update(t, e) {
        let i = this.chart;
        this.setOptions(w(!0, this.options, t)), "events" in this.options && c(this, this.options), this.destroy(), i.isDirtyLegend = i.isDirtyBox = !0, k(e, !0) && i.redraw(), C(this, "afterUpdate", {
          redraw: e
        });
      }
      colorizeItem(t, e) {
        let {
          area: i,
          group: s,
          label: o,
          line: r,
          symbol: a
        } = t.legendItem || {};
        if (s?.[e ? "removeClass" : "addClass"]("highcharts-legend-item-hidden"), !this.chart.styledMode) {
          let {
              itemHiddenStyle: s = {}
            } = this,
            n = s.color,
            {
              fillColor: l,
              fillOpacity: h,
              lineColor: d,
              marker: c
            } = t.options,
            p = t => (!e && (t.fill && (t.fill = n), t.stroke && (t.stroke = n)), t);
          o?.css(w(e ? this.itemStyle : s)), r?.attr(p({
            stroke: d || t.color
          })), a && a.attr(p(c && a.isMarker ? t.pointAttribs() : {
            fill: t.color
          })), i?.attr(p({
            fill: l || t.color,
            "fill-opacity": l ? 1 : h ?? .75
          }));
        }
        C(this, "afterColorizeItem", {
          item: t,
          visible: e
        });
      }
      positionItems() {
        this.allItems.forEach(this.positionItem, this), this.chart.isResizing || this.positionCheckboxes();
      }
      positionItem(t) {
        let {
            group: e,
            x: i = 0,
            y: s = 0
          } = t.legendItem || {},
          o = this.options,
          r = o.symbolPadding,
          a = !o.rtl,
          n = t.checkbox;
        if (e && e.element) {
          let o = {
            translateX: a ? i : this.legendWidth - i - 2 * r - 4,
            translateY: s
          };
          e[b(e.translateY) ? "animate" : "attr"](o, void 0, () => {
            C(this, "afterPositionItem", {
              item: t
            });
          });
        }
        n && (n.x = i, n.y = s);
      }
      destroyItem(t) {
        let e = t.checkbox,
          i = t.legendItem || {};
        for (let t of ["group", "label", "line", "symbol"]) i[t] && (i[t] = i[t].destroy());
        e && v(e), t.legendItem = void 0;
      }
      destroy() {
        for (let t of this.getAllItems()) this.destroyItem(t);
        for (let t of ["clipRect", "up", "down", "pager", "nav", "box", "title", "group"]) this[t] && (this[t] = this[t].destroy());
        this.display = null;
      }
      positionCheckboxes() {
        let t;
        let e = this.group && this.group.alignAttr,
          i = this.clipHeight || this.legendHeight,
          s = this.titleHeight;
        e && (t = e.translateY, this.allItems.forEach(function (o) {
          let r;
          let a = o.checkbox;
          a && (r = t + s + a.y + (this.scrollOffset || 0) + 3, y(a, {
            left: e.translateX + o.checkboxOffset + a.x - 20 + "px",
            top: r + "px",
            display: this.proximate || r > t - 6 && r < t + i - 6 ? "" : "none"
          }));
        }, this));
      }
      renderTitle() {
        let t = this.options,
          e = this.padding,
          i = t.title,
          s,
          o = 0;
        i.text && (this.title || (this.title = this.chart.renderer.label(i.text, e - 3, e - 4, void 0, void 0, void 0, t.useHTML, void 0, "legend-title").attr({
          zIndex: 1
        }), this.chart.styledMode || this.title.css(i.style), this.title.add(this.group)), i.width || this.title.css({
          width: this.maxLegendWidth + "px"
        }), o = (s = this.title.getBBox()).height, this.offsetWidth = s.width, this.contentGroup.attr({
          translateY: o
        })), this.titleHeight = o;
      }
      setText(t) {
        let e = this.options;
        t.legendItem.label.attr({
          text: e.labelFormat ? f(e.labelFormat, t, this.chart) : e.labelFormatter.call(t)
        });
      }
      renderItem(t) {
        let e = t.legendItem = t.legendItem || {},
          i = this.chart,
          s = i.renderer,
          o = this.options,
          r = "horizontal" === o.layout,
          a = this.symbolWidth,
          n = o.symbolPadding || 0,
          l = this.itemStyle,
          h = this.itemHiddenStyle,
          d = r ? k(o.itemDistance, 20) : 0,
          c = !o.rtl,
          p = !t.series,
          u = !p && t.series.drawLegendSymbol ? t.series : t,
          g = u.options,
          f = !!this.createCheckboxForItem && g && g.showCheckbox,
          m = o.useHTML,
          x = t.options.className,
          y = e.label,
          b = a + n + d + (f ? 20 : 0);
        !y && (e.group = s.g("legend-item").addClass("highcharts-" + u.type + "-series highcharts-color-" + t.colorIndex + (x ? " " + x : "") + (p ? " highcharts-series-" + t.index : "")).attr({
          zIndex: 1
        }).add(this.scrollGroup), e.label = y = s.text("", c ? a + n : -n, this.baseline || 0, m), i.styledMode || y.css(w(t.visible ? l : h)), y.attr({
          align: c ? "left" : "right",
          zIndex: 2
        }).add(e.group), !this.baseline && (this.fontMetrics = s.fontMetrics(y), this.baseline = this.fontMetrics.f + 3 + this.itemMarginTop, y.attr("y", this.baseline), this.symbolHeight = k(o.symbolHeight, this.fontMetrics.f), o.squareSymbol && (this.symbolWidth = k(o.symbolWidth, Math.max(this.symbolHeight, 16)), b = this.symbolWidth + n + d + (f ? 20 : 0), c && y.attr("x", this.symbolWidth + n))), u.drawLegendSymbol(this, t), this.setItemEvents && this.setItemEvents(t, y, m)), f && !t.checkbox && this.createCheckboxForItem && this.createCheckboxForItem(t), this.colorizeItem(t, t.visible), (i.styledMode || !l.width) && y.css({
          width: (o.itemWidth || this.widthOption || i.spacingBox.width) - b + "px"
        }), this.setText(t);
        let v = y.getBBox(),
          M = this.fontMetrics && this.fontMetrics.h || 0;
        t.itemWidth = t.checkboxOffset = o.itemWidth || e.labelWidth || v.width + b, this.maxItemWidth = Math.max(this.maxItemWidth, t.itemWidth), this.totalItemWidth += t.itemWidth, this.itemHeight = t.itemHeight = Math.round(e.labelHeight || (v.height > 1.5 * M ? v.height : M));
      }
      layoutItem(t) {
        let e = this.options,
          i = this.padding,
          s = "horizontal" === e.layout,
          o = t.itemHeight,
          r = this.itemMarginBottom,
          a = this.itemMarginTop,
          n = s ? k(e.itemDistance, 20) : 0,
          l = this.maxLegendWidth,
          h = e.alignColumns && this.totalItemWidth > l ? this.maxItemWidth : t.itemWidth,
          d = t.legendItem || {};
        s && this.itemX - i + h > l && (this.itemX = i, this.lastLineHeight && (this.itemY += a + this.lastLineHeight + r), this.lastLineHeight = 0), this.lastItemY = a + this.itemY + r, this.lastLineHeight = Math.max(o, this.lastLineHeight), d.x = this.itemX, d.y = this.itemY, s ? this.itemX += h : (this.itemY += a + o + r, this.lastLineHeight = o), this.offsetWidth = this.widthOption || Math.max((s ? this.itemX - i - (t.checkbox ? 0 : n) : h) + i, this.offsetWidth);
      }
      getAllItems() {
        let t = [];
        return this.chart.series.forEach(function (e) {
          let i = e && e.options;
          e && k(i.showInLegend, !b(i.linkedTo) && void 0, !0) && (t = t.concat((e.legendItem || {}).labels || ("point" === i.legendType ? e.data : e)));
        }), C(this, "afterGetAllItems", {
          allItems: t
        }), t;
      }
      getAlignment() {
        let t = this.options;
        return this.proximate ? t.align.charAt(0) + "tv" : t.floating ? "" : t.align.charAt(0) + t.verticalAlign.charAt(0) + t.layout.charAt(0);
      }
      adjustMargins(t, e) {
        let i = this.chart,
          s = this.options,
          o = this.getAlignment();
        o && [/(lth|ct|rth)/, /(rtv|rm|rbv)/, /(rbh|cb|lbh)/, /(lbv|lm|ltv)/].forEach(function (r, a) {
          r.test(o) && !b(t[a]) && (i[u[a]] = Math.max(i[u[a]], i.legend[(a + 1) % 2 ? "legendHeight" : "legendWidth"] + [1, -1, -1, 1][a] * s[a % 2 ? "x" : "y"] + k(s.margin, 12) + e[a] + (i.titleOffset[a] || 0)));
        });
      }
      proximatePositions() {
        let t;
        let e = this.chart,
          i = [],
          s = "left" === this.options.align;
        for (let o of (this.allItems.forEach(function (t) {
          let o,
            r,
            a = s,
            n,
            l;
          t.yAxis && (t.xAxis.options.reversed && (a = !a), t.points && (o = M(a ? t.points : t.points.slice(0).reverse(), function (t) {
            return S(t.plotY);
          })), r = this.itemMarginTop + t.legendItem.label.getBBox().height + this.itemMarginBottom, l = t.yAxis.top - e.plotTop, n = t.visible ? (o ? o.plotY : t.yAxis.height) + (l - .3 * r) : l + t.yAxis.height, i.push({
            target: n,
            size: r,
            item: t
          }));
        }, this), g(i, e.plotHeight))) t = o.item.legendItem || {}, S(o.pos) && (t.y = e.plotTop - e.spacing[0] + o.pos);
      }
      render() {
        let t = this.chart,
          e = t.renderer,
          i = this.options,
          s = this.padding,
          o = this.getAllItems(),
          r,
          a,
          n,
          l = this.group,
          h,
          d = this.box;
        this.itemX = s, this.itemY = this.initialItemY, this.offsetWidth = 0, this.lastItemY = 0, this.widthOption = T(i.width, t.spacingBox.width - s), h = t.spacingBox.width - 2 * s - i.x, ["rm", "lm"].indexOf(this.getAlignment().substring(0, 2)) > -1 && (h /= 2), this.maxLegendWidth = this.widthOption || h, l || (this.group = l = e.g("legend").addClass(i.className || "").attr({
          zIndex: 7
        }).add(), this.contentGroup = e.g().attr({
          zIndex: 1
        }).add(l), this.scrollGroup = e.g().add(this.contentGroup)), this.renderTitle(), P(o, (t, e) => (t.options && t.options.legendIndex || 0) - (e.options && e.options.legendIndex || 0)), i.reversed && o.reverse(), this.allItems = o, this.display = r = !!o.length, this.lastLineHeight = 0, this.maxItemWidth = 0, this.totalItemWidth = 0, this.itemHeight = 0, o.forEach(this.renderItem, this), o.forEach(this.layoutItem, this), a = (this.widthOption || this.offsetWidth) + s, n = this.lastItemY + this.lastLineHeight + this.titleHeight, n = this.handleOverflow(n) + s, d || (this.box = d = e.rect().addClass("highcharts-legend-box").attr({
          r: i.borderRadius
        }).add(l)), t.styledMode || d.attr({
          stroke: i.borderColor,
          "stroke-width": i.borderWidth || 0,
          fill: i.backgroundColor || "none"
        }).shadow(i.shadow), a > 0 && n > 0 && d[d.placed ? "animate" : "attr"](d.crisp.call({}, {
          x: 0,
          y: 0,
          width: a,
          height: n
        }, d.strokeWidth())), l[r ? "show" : "hide"](), t.styledMode && "none" === l.getStyle("display") && (a = n = 0), this.legendWidth = a, this.legendHeight = n, r && this.align(), this.proximate || this.positionItems(), C(this, "afterRender");
      }
      align(t = this.chart.spacingBox) {
        let e = this.chart,
          i = this.options,
          s = t.y;
        /(lth|ct|rth)/.test(this.getAlignment()) && e.titleOffset[0] > 0 ? s += e.titleOffset[0] : /(lbh|cb|rbh)/.test(this.getAlignment()) && e.titleOffset[2] > 0 && (s -= e.titleOffset[2]), s !== t.y && (t = w(t, {
          y: s
        })), e.hasRendered || (this.group.placed = !1), this.group.align(w(i, {
          width: this.legendWidth,
          height: this.legendHeight,
          verticalAlign: this.proximate ? "top" : i.verticalAlign
        }), !0, t);
      }
      handleOverflow(t) {
        let e = this,
          i = this.chart,
          s = i.renderer,
          o = this.options,
          r = o.y,
          a = "top" === o.verticalAlign,
          n = this.padding,
          l = o.maxHeight,
          h = o.navigation,
          d = k(h.animation, !0),
          c = h.arrowSize || 12,
          p = this.pages,
          u = this.allItems,
          g = function (t) {
            "number" == typeof t ? M.attr({
              height: t
            }) : M && (e.clipRect = M.destroy(), e.contentGroup.clip()), e.contentGroup.div && (e.contentGroup.div.style.clip = t ? "rect(" + n + "px,9999px," + (n + t) + "px,0)" : "auto");
          },
          f = function (t) {
            return e[t] = s.circle(0, 0, 1.3 * c).translate(c / 2, c / 2).add(v), i.styledMode || e[t].attr("fill", "rgba(0,0,0,0.0001)"), e[t];
          },
          m,
          x,
          y,
          b = i.spacingBox.height + (a ? -r : r) - n,
          v = this.nav,
          M = this.clipRect;
        return "horizontal" !== o.layout || "middle" === o.verticalAlign || o.floating || (b /= 2), l && (b = Math.min(b, l)), p.length = 0, t && b > 0 && t > b && !1 !== h.enabled ? (this.clipHeight = m = Math.max(b - 20 - this.titleHeight - n, 0), this.currentPage = k(this.currentPage, 1), this.fullHeight = t, u.forEach((t, e) => {
          let i = (y = t.legendItem || {}).y || 0,
            s = Math.round(y.label.getBBox().height),
            o = p.length;
          (!o || i - p[o - 1] > m && (x || i) !== p[o - 1]) && (p.push(x || i), o++), y.pageIx = o - 1, x && ((u[e - 1].legendItem || {}).pageIx = o - 1), e === u.length - 1 && i + s - p[o - 1] > m && i > p[o - 1] && (p.push(i), y.pageIx = o), i !== x && (x = i);
        }), M || (M = e.clipRect = s.clipRect(0, n - 2, 9999, 0), e.contentGroup.clip(M)), g(m), v || (this.nav = v = s.g().attr({
          zIndex: 1
        }).add(this.group), this.up = s.symbol("triangle", 0, 0, c, c).add(v), f("upTracker").on("click", function () {
          e.scroll(-1, d);
        }), this.pager = s.text("", 15, 10).addClass("highcharts-legend-navigation"), !i.styledMode && h.style && this.pager.css(h.style), this.pager.add(v), this.down = s.symbol("triangle-down", 0, 0, c, c).add(v), f("downTracker").on("click", function () {
          e.scroll(1, d);
        })), e.scroll(0), t = b) : v && (g(), this.nav = v.destroy(), this.scrollGroup.attr({
          translateY: 1
        }), this.clipHeight = 0), t;
      }
      scroll(t, e) {
        let i = this.chart,
          s = this.pages,
          o = s.length,
          r = this.clipHeight,
          a = this.options.navigation,
          n = this.pager,
          l = this.padding,
          c = this.currentPage + t;
        c > o && (c = o), c > 0 && (void 0 !== e && d(e, i), this.nav.attr({
          translateX: l,
          translateY: r + this.padding + 7 + this.titleHeight,
          visibility: "inherit"
        }), [this.up, this.upTracker].forEach(function (t) {
          t.attr({
            class: 1 === c ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
          });
        }), n.attr({
          text: c + "/" + o
        }), [this.down, this.downTracker].forEach(function (t) {
          t.attr({
            x: 18 + this.pager.getBBox().width,
            class: c === o ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
          });
        }, this), i.styledMode || (this.up.attr({
          fill: 1 === c ? a.inactiveColor : a.activeColor
        }), this.upTracker.css({
          cursor: 1 === c ? "default" : "pointer"
        }), this.down.attr({
          fill: c === o ? a.inactiveColor : a.activeColor
        }), this.downTracker.css({
          cursor: c === o ? "default" : "pointer"
        })), this.scrollOffset = -s[c - 1] + this.initialItemY, this.scrollGroup.animate({
          translateY: this.scrollOffset
        }), this.currentPage = c, this.positionCheckboxes(), L(() => {
          C(this, "afterScroll", {
            currentPage: c
          });
        }, h(k(e, i.renderer.globalAnimation, !0)).duration));
      }
      setItemEvents(t, e, i) {
        let r = this,
          a = t.legendItem || {},
          n = r.chart.renderer.boxWrapper,
          l = t instanceof o,
          h = t instanceof s,
          d = "highcharts-legend-" + (l ? "point" : "series") + "-active",
          c = r.chart.styledMode,
          p = i ? [e, a.symbol] : [a.group],
          u = e => {
            r.allItems.forEach(i => {
              t !== i && [i].concat(i.linkedSeries || []).forEach(t => {
                t.setState(e, !l);
              });
            });
          };
        for (let i of p) i && i.on("mouseover", function () {
          t.visible && u("inactive"), t.setState("hover"), t.visible && n.addClass(d), c || e.css(r.options.itemHoverStyle);
        }).on("mouseout", function () {
          r.chart.styledMode || e.css(w(t.visible ? r.itemStyle : r.itemHiddenStyle)), u(""), n.removeClass(d), t.setState();
        }).on("click", function (e) {
          let i = function () {
            t.setVisible && t.setVisible(), u(t.visible ? "inactive" : "");
          };
          n.removeClass(d), C(r, "itemClick", {
            browserEvent: e,
            legendItem: t
          }, i), l ? t.firePointEvent("legendItemClick", {
            browserEvent: e
          }) : h && C(t, "legendItemClick", {
            browserEvent: e
          });
        });
      }
      createCheckboxForItem(t) {
        t.checkbox = x("input", {
          type: "checkbox",
          className: "highcharts-legend-checkbox",
          checked: t.selected,
          defaultChecked: t.selected
        }, this.options.itemCheckboxStyle, this.chart.container), m(t.checkbox, "click", function (e) {
          let i = e.target;
          C(t.series || t, "checkboxClick", {
            checked: i.checked,
            item: t
          }, function () {
            t.select();
          });
        });
      }
    }
    return (l = j || (j = {})).compose = function (t) {
      A(p, "Core.Legend") && m(t, "beforeMargins", function () {
        this.legend = new l(this, this.options.legend);
      });
    }, j;
  }), i(e, "Core/Chart/Chart.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Axis/Axis.js"], e["Core/Defaults.js"], e["Core/Templating.js"], e["Core/Foundation.js"], e["Core/Globals.js"], e["Core/Renderer/RendererRegistry.js"], e["Core/Series/Series.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Renderer/SVG/SVGRenderer.js"], e["Core/Time.js"], e["Core/Utilities.js"], e["Core/Renderer/HTML/AST.js"], e["Core/Axis/Tick.js"]], function (t, e, i, s, o, r, a, n, l, h, d, c, p, u) {
    let {
        animate: g,
        animObject: f,
        setAnimation: m
      } = t,
      {
        defaultOptions: x,
        defaultTime: y
      } = i,
      {
        numberFormat: b
      } = s,
      {
        registerEventOptions: v
      } = o,
      {
        charts: M,
        doc: C,
        marginNames: S,
        svg: w,
        win: k
      } = r,
      {
        seriesTypes: A
      } = l,
      {
        addEvent: T,
        attr: P,
        createElement: L,
        css: j,
        defined: O,
        diffObjects: D,
        discardElement: E,
        erase: I,
        error: B,
        extend: z,
        find: R,
        fireEvent: N,
        getStyle: G,
        isArray: W,
        isNumber: X,
        isObject: H,
        isString: U,
        merge: F,
        objectEach: Y,
        pick: V,
        pInt: Z,
        relativeLength: $,
        removeEvent: _,
        splat: q,
        syncTimeout: K,
        uniqueKey: J
      } = c;
    class Q {
      static chart(t, e, i) {
        return new Q(t, e, i);
      }
      constructor(t, e, i) {
        this.sharedClips = {};
        let s = [...arguments];
        (U(t) || t.nodeName) && (this.renderTo = s.shift()), this.init(s[0], s[1]);
      }
      setZoomOptions() {
        let t = this.options.chart,
          e = t.zooming;
        this.zooming = {
          ...e,
          type: V(t.zoomType, e.type),
          key: V(t.zoomKey, e.key),
          pinchType: V(t.pinchType, e.pinchType),
          singleTouch: V(t.zoomBySingleTouch, e.singleTouch, !1),
          resetButton: F(e.resetButton, t.resetZoomButton)
        };
      }
      init(t, e) {
        N(this, "init", {
          args: arguments
        }, function () {
          let i = F(x, t),
            s = i.chart;
          this.userOptions = z({}, t), this.margin = [], this.spacing = [], this.labelCollectors = [], this.callback = e, this.isResizing = 0, this.options = i, this.axes = [], this.series = [], this.time = t.time && Object.keys(t.time).length ? new d(t.time) : r.time, this.numberFormatter = s.numberFormatter || b, this.styledMode = s.styledMode, this.hasCartesianSeries = s.showAxes, this.index = M.length, M.push(this), r.chartCount++, v(this, s), this.xAxis = [], this.yAxis = [], this.pointCount = this.colorCounter = this.symbolCounter = 0, this.setZoomOptions(), N(this, "afterInit"), this.firstRender();
        });
      }
      initSeries(t) {
        let e = this.options.chart,
          i = t.type || e.type,
          s = A[i];
        s || B(17, !0, this, {
          missingModuleFor: i
        });
        let o = new s();
        return "function" == typeof o.init && o.init(this, t), o;
      }
      setSortedData() {
        this.getSeriesOrderByLinks().forEach(function (t) {
          t.points || t.data || !t.enabledDataSorting || t.setData(t.options.data, !1);
        });
      }
      getSeriesOrderByLinks() {
        return this.series.concat().sort(function (t, e) {
          return t.linkedSeries.length || e.linkedSeries.length ? e.linkedSeries.length - t.linkedSeries.length : 0;
        });
      }
      orderItems(t, e = 0) {
        let i = this[t],
          s = this.options[t] = q(this.options[t]).slice(),
          o = this.userOptions[t] = this.userOptions[t] ? q(this.userOptions[t]).slice() : [];
        if (this.hasRendered && (s.splice(e), o.splice(e)), i) for (let t = e, r = i.length; t < r; ++t) {
          let e = i[t];
          e && (e.index = t, e instanceof n && (e.name = e.getName()), e.options.isInternal || (s[t] = e.options, o[t] = e.userOptions));
        }
      }
      isInsidePlot(t, e, i = {}) {
        let {
            inverted: s,
            plotBox: o,
            plotLeft: r,
            plotTop: a,
            scrollablePlotBox: n
          } = this,
          {
            scrollLeft: l = 0,
            scrollTop: h = 0
          } = i.visiblePlotOnly && this.scrollablePlotArea?.scrollingContainer || {},
          d = i.series,
          c = i.visiblePlotOnly && n || o,
          p = i.inverted ? e : t,
          u = i.inverted ? t : e,
          g = {
            x: p,
            y: u,
            isInsidePlot: !0,
            options: i
          };
        if (!i.ignoreX) {
          let t = d && (s && !this.polar ? d.yAxis : d.xAxis) || {
              pos: r,
              len: 1 / 0
            },
            e = i.paneCoordinates ? t.pos + p : r + p;
          e >= Math.max(l + r, t.pos) && e <= Math.min(l + r + c.width, t.pos + t.len) || (g.isInsidePlot = !1);
        }
        if (!i.ignoreY && g.isInsidePlot) {
          let t = !s && i.axis && !i.axis.isXAxis && i.axis || d && (s ? d.xAxis : d.yAxis) || {
              pos: a,
              len: 1 / 0
            },
            e = i.paneCoordinates ? t.pos + u : a + u;
          e >= Math.max(h + a, t.pos) && e <= Math.min(h + a + c.height, t.pos + t.len) || (g.isInsidePlot = !1);
        }
        return N(this, "afterIsInsidePlot", g), g.isInsidePlot;
      }
      redraw(t) {
        N(this, "beforeRedraw");
        let e = this.hasCartesianSeries ? this.axes : this.colorAxis || [],
          i = this.series,
          s = this.pointer,
          o = this.legend,
          r = this.userOptions.legend,
          a = this.renderer,
          n = a.isHidden(),
          l = [],
          h,
          d,
          c,
          p = this.isDirtyBox,
          u = this.isDirtyLegend,
          g;
        for (a.rootFontSize = a.boxWrapper.getStyle("font-size"), this.setResponsive && this.setResponsive(!1), m(!!this.hasRendered && t, this), n && this.temporaryDisplay(), this.layOutTitles(!1), c = i.length; c--;) if (((g = i[c]).options.stacking || g.options.centerInCategory) && (d = !0, g.isDirty)) {
          h = !0;
          break;
        }
        if (h) for (c = i.length; c--;) (g = i[c]).options.stacking && (g.isDirty = !0);
        i.forEach(function (t) {
          t.isDirty && ("point" === t.options.legendType ? ("function" == typeof t.updateTotals && t.updateTotals(), u = !0) : r && (r.labelFormatter || r.labelFormat) && (u = !0)), t.isDirtyData && N(t, "updatedData");
        }), u && o && o.options.enabled && (o.render(), this.isDirtyLegend = !1), d && this.getStacks(), e.forEach(function (t) {
          t.updateNames(), t.setScale();
        }), this.getMargins(), e.forEach(function (t) {
          t.isDirty && (p = !0);
        }), e.forEach(function (t) {
          let e = t.min + "," + t.max;
          t.extKey !== e && (t.extKey = e, l.push(function () {
            N(t, "afterSetExtremes", z(t.eventArgs, t.getExtremes())), delete t.eventArgs;
          })), (p || d) && t.redraw();
        }), p && this.drawChartBox(), N(this, "predraw"), i.forEach(function (t) {
          (p || t.isDirty) && t.visible && t.redraw(), t.isDirtyData = !1;
        }), s && s.reset(!0), a.draw(), N(this, "redraw"), N(this, "render"), n && this.temporaryDisplay(!0), l.forEach(function (t) {
          t.call();
        });
      }
      get(t) {
        let e = this.series;
        function i(e) {
          return e.id === t || e.options && e.options.id === t;
        }
        let s = R(this.axes, i) || R(this.series, i);
        for (let t = 0; !s && t < e.length; t++) s = R(e[t].points || [], i);
        return s;
      }
      getAxes() {
        let t = this.userOptions;
        for (let i of (N(this, "getAxes"), ["xAxis", "yAxis"])) for (let s of t[i] = q(t[i] || {})) new e(this, s, i);
        N(this, "afterGetAxes");
      }
      getSelectedPoints() {
        return this.series.reduce((t, e) => (e.getPointsCollection().forEach(e => {
          V(e.selectedStaging, e.selected) && t.push(e);
        }), t), []);
      }
      getSelectedSeries() {
        return this.series.filter(function (t) {
          return t.selected;
        });
      }
      setTitle(t, e, i) {
        this.applyDescription("title", t), this.applyDescription("subtitle", e), this.applyDescription("caption", void 0), this.layOutTitles(i);
      }
      applyDescription(t, e) {
        let i = this,
          s = this.options[t] = F(this.options[t], e),
          o = this[t];
        o && e && (this[t] = o = o.destroy()), s && !o && ((o = this.renderer.text(s.text, 0, 0, s.useHTML).attr({
          align: s.align,
          class: "highcharts-" + t,
          zIndex: s.zIndex || 4
        }).add()).update = function (e, s) {
          i.applyDescription(t, e), i.layOutTitles(s);
        }, this.styledMode || o.css(z("title" === t ? {
          fontSize: this.options.isStock ? "1em" : "1.2em"
        } : {}, s.style)), this[t] = o);
      }
      layOutTitles(t = !0) {
        let e = [0, 0, 0],
          i = this.renderer,
          s = this.spacingBox;
        ["title", "subtitle", "caption"].forEach(function (t) {
          let o = this[t],
            r = this.options[t],
            a = r.verticalAlign || "top",
            n = "title" === t ? "top" === a ? -3 : 0 : "top" === a ? e[0] + 2 : 0;
          if (o) {
            o.css({
              width: (r.width || s.width + (r.widthAdjust || 0)) + "px"
            });
            let t = i.fontMetrics(o).b,
              l = Math.round(o.getBBox(r.useHTML).height);
            o.align(z({
              y: "bottom" === a ? t : n + t,
              height: l
            }, r), !1, "spacingBox"), r.floating || ("top" === a ? e[0] = Math.ceil(e[0] + l) : "bottom" === a && (e[2] = Math.ceil(e[2] + l)));
          }
        }, this), e[0] && "top" === (this.options.title.verticalAlign || "top") && (e[0] += this.options.title.margin), e[2] && "bottom" === this.options.caption.verticalAlign && (e[2] += this.options.caption.margin);
        let o = !this.titleOffset || this.titleOffset.join(",") !== e.join(",");
        this.titleOffset = e, N(this, "afterLayOutTitles"), !this.isDirtyBox && o && (this.isDirtyBox = this.isDirtyLegend = o, this.hasRendered && t && this.isDirtyBox && this.redraw());
      }
      getContainerBox() {
        let t = [].map.call(this.renderTo.children, t => {
            if (t !== this.container) {
              let e = t.style.display;
              return t.style.display = "none", [t, e];
            }
          }),
          e = {
            width: G(this.renderTo, "width", !0) || 0,
            height: G(this.renderTo, "height", !0) || 0
          };
        return t.filter(Boolean).forEach(([t, e]) => {
          t.style.display = e;
        }), e;
      }
      getChartSize() {
        let t = this.options.chart,
          e = t.width,
          i = t.height,
          s = this.getContainerBox(),
          o = s.height > 1 && !(!this.renderTo.parentElement?.style.height && "100%" === this.renderTo.style.height);
        this.chartWidth = Math.max(0, e || s.width || 600), this.chartHeight = Math.max(0, $(i, this.chartWidth) || (o ? s.height : 400)), this.containerBox = s;
      }
      temporaryDisplay(t) {
        let e = this.renderTo,
          i;
        if (t) for (; e && e.style;) e.hcOrigStyle && (j(e, e.hcOrigStyle), delete e.hcOrigStyle), e.hcOrigDetached && (C.body.removeChild(e), e.hcOrigDetached = !1), e = e.parentNode;else for (; e && e.style && (C.body.contains(e) || e.parentNode || (e.hcOrigDetached = !0, C.body.appendChild(e)), ("none" === G(e, "display", !1) || e.hcOricDetached) && (e.hcOrigStyle = {
          display: e.style.display,
          height: e.style.height,
          overflow: e.style.overflow
        }, i = {
          display: "block",
          overflow: "hidden"
        }, e !== this.renderTo && (i.height = 0), j(e, i), e.offsetWidth || e.style.setProperty("display", "block", "important")), (e = e.parentNode) !== C.body););
      }
      setClassName(t) {
        this.container.className = "highcharts-container " + (t || "");
      }
      getContainer() {
        let t = this.options,
          e = t.chart,
          i = "data-highcharts-chart",
          s = J(),
          o,
          r = this.renderTo;
        r || (this.renderTo = r = e.renderTo), U(r) && (this.renderTo = r = C.getElementById(r)), r || B(13, !0, this);
        let n = Z(P(r, i));
        X(n) && M[n] && M[n].hasRendered && M[n].destroy(), P(r, i, this.index), r.innerHTML = p.emptyHTML, e.skipClone || r.offsetWidth || this.temporaryDisplay(), this.getChartSize();
        let l = this.chartHeight,
          d = this.chartWidth;
        j(r, {
          overflow: "hidden"
        }), this.styledMode || (o = z({
          position: "relative",
          overflow: "hidden",
          width: d + "px",
          height: l + "px",
          textAlign: "left",
          lineHeight: "normal",
          zIndex: 0,
          "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
          userSelect: "none",
          "touch-action": "manipulation",
          outline: "none",
          padding: "0px"
        }, e.style || {}));
        let c = L("div", {
          id: s
        }, o, r);
        this.container = c, this.getChartSize(), d === this.chartWidth || (d = this.chartWidth, this.styledMode || j(c, {
          width: V(e.style?.width, d + "px")
        })), this.containerBox = this.getContainerBox(), this._cursor = c.style.cursor;
        let u = e.renderer || !w ? a.getRendererType(e.renderer) : h;
        if (this.renderer = new u(c, d, l, void 0, e.forExport, t.exporting && t.exporting.allowHTML, this.styledMode), m(void 0, this), this.setClassName(e.className), this.styledMode) for (let e in t.defs) this.renderer.definition(t.defs[e]);else this.renderer.setStyle(e.style);
        this.renderer.chartIndex = this.index, N(this, "afterGetContainer");
      }
      getMargins(t) {
        let {
          spacing: e,
          margin: i,
          titleOffset: s
        } = this;
        this.resetMargins(), s[0] && !O(i[0]) && (this.plotTop = Math.max(this.plotTop, s[0] + e[0])), s[2] && !O(i[2]) && (this.marginBottom = Math.max(this.marginBottom, s[2] + e[2])), this.legend && this.legend.display && this.legend.adjustMargins(i, e), N(this, "getMargins"), t || this.getAxisMargins();
      }
      getAxisMargins() {
        let t = this,
          e = t.axisOffset = [0, 0, 0, 0],
          i = t.colorAxis,
          s = t.margin,
          o = function (t) {
            t.forEach(function (t) {
              t.visible && t.getOffset();
            });
          };
        t.hasCartesianSeries ? o(t.axes) : i && i.length && o(i), S.forEach(function (i, o) {
          O(s[o]) || (t[i] += e[o]);
        }), t.setChartSize();
      }
      getOptions() {
        return D(this.userOptions, x);
      }
      reflow(t) {
        let e = this,
          i = e.containerBox,
          s = e.getContainerBox();
        delete e.pointer?.chartPosition, !e.isPrinting && !e.isResizing && i && s.width && ((s.width !== i.width || s.height !== i.height) && (c.clearTimeout(e.reflowTimeout), e.reflowTimeout = K(function () {
          e.container && e.setSize(void 0, void 0, !1);
        }, t ? 100 : 0)), e.containerBox = s);
      }
      setReflow() {
        let t = this,
          e = e => {
            t.options?.chart.reflow && t.hasLoaded && t.reflow(e);
          };
        if ("function" == typeof ResizeObserver) new ResizeObserver(e).observe(t.renderTo);else {
          let t = T(k, "resize", e);
          T(this, "destroy", t);
        }
      }
      setSize(t, e, i) {
        let s = this,
          o = s.renderer;
        s.isResizing += 1, m(i, s);
        let r = o.globalAnimation;
        s.oldChartHeight = s.chartHeight, s.oldChartWidth = s.chartWidth, void 0 !== t && (s.options.chart.width = t), void 0 !== e && (s.options.chart.height = e), s.getChartSize();
        let {
          chartWidth: a,
          chartHeight: n,
          scrollablePixelsX: l = 0,
          scrollablePixelsY: h = 0
        } = s;
        (s.isDirtyBox || a !== s.oldChartWidth || n !== s.oldChartHeight) && (s.styledMode || (r ? g : j)(s.container, {
          width: `${a + l}px`,
          height: `${n + h}px`
        }, r), s.setChartSize(!0), o.setSize(a, n, r), s.axes.forEach(function (t) {
          t.isDirty = !0, t.setScale();
        }), s.isDirtyLegend = !0, s.isDirtyBox = !0, s.layOutTitles(), s.getMargins(), s.redraw(r), s.oldChartHeight = void 0, N(s, "resize"), setTimeout(() => {
          s && N(s, "endResize");
        }, f(r).duration)), s.isResizing -= 1;
      }
      setChartSize(t) {
        let e, i, s, o;
        let {
            chartHeight: r,
            chartWidth: a,
            inverted: n,
            spacing: l,
            renderer: h
          } = this,
          d = this.clipOffset,
          c = Math[n ? "floor" : "round"];
        this.plotLeft = e = Math.round(this.plotLeft), this.plotTop = i = Math.round(this.plotTop), this.plotWidth = s = Math.max(0, Math.round(a - e - this.marginRight)), this.plotHeight = o = Math.max(0, Math.round(r - i - this.marginBottom)), this.plotSizeX = n ? o : s, this.plotSizeY = n ? s : o, this.spacingBox = h.spacingBox = {
          x: l[3],
          y: l[0],
          width: a - l[3] - l[1],
          height: r - l[0] - l[2]
        }, this.plotBox = h.plotBox = {
          x: e,
          y: i,
          width: s,
          height: o
        }, d && (this.clipBox = {
          x: c(d[3]),
          y: c(d[0]),
          width: c(this.plotSizeX - d[1] - d[3]),
          height: c(this.plotSizeY - d[0] - d[2])
        }), t || (this.axes.forEach(function (t) {
          t.setAxisSize(), t.setAxisTranslation();
        }), h.alignElements()), N(this, "afterSetChartSize", {
          skipAxes: t
        });
      }
      resetMargins() {
        N(this, "resetMargins");
        let t = this,
          e = t.options.chart,
          i = e.plotBorderWidth || 0,
          s = i / 2;
        ["margin", "spacing"].forEach(function (i) {
          let s = e[i],
            o = H(s) ? s : [s, s, s, s];
          ["Top", "Right", "Bottom", "Left"].forEach(function (s, r) {
            t[i][r] = V(e[i + s], o[r]);
          });
        }), S.forEach(function (e, i) {
          t[e] = V(t.margin[i], t.spacing[i]);
        }), t.axisOffset = [0, 0, 0, 0], t.clipOffset = [s, s, s, s], t.plotBorderWidth = i;
      }
      drawChartBox() {
        let t = this.options.chart,
          e = this.renderer,
          i = this.chartWidth,
          s = this.chartHeight,
          o = this.styledMode,
          r = this.plotBGImage,
          a = t.backgroundColor,
          n = t.plotBackgroundColor,
          l = t.plotBackgroundImage,
          h = this.plotLeft,
          d = this.plotTop,
          c = this.plotWidth,
          p = this.plotHeight,
          u = this.plotBox,
          g = this.clipRect,
          f = this.clipBox,
          m = this.chartBackground,
          x = this.plotBackground,
          y = this.plotBorder,
          b,
          v,
          M,
          C = "animate";
        m || (this.chartBackground = m = e.rect().addClass("highcharts-background").add(), C = "attr"), o ? b = v = m.strokeWidth() : (v = (b = t.borderWidth || 0) + (t.shadow ? 8 : 0), M = {
          fill: a || "none"
        }, (b || m["stroke-width"]) && (M.stroke = t.borderColor, M["stroke-width"] = b), m.attr(M).shadow(t.shadow)), m[C]({
          x: v / 2,
          y: v / 2,
          width: i - v - b % 2,
          height: s - v - b % 2,
          r: t.borderRadius
        }), C = "animate", x || (C = "attr", this.plotBackground = x = e.rect().addClass("highcharts-plot-background").add()), x[C](u), !o && (x.attr({
          fill: n || "none"
        }).shadow(t.plotShadow), l && (r ? (l !== r.attr("href") && r.attr("href", l), r.animate(u)) : this.plotBGImage = e.image(l, h, d, c, p).add())), g ? g.animate({
          width: f.width,
          height: f.height
        }) : this.clipRect = e.clipRect(f), C = "animate", y || (C = "attr", this.plotBorder = y = e.rect().addClass("highcharts-plot-border").attr({
          zIndex: 1
        }).add()), o || y.attr({
          stroke: t.plotBorderColor,
          "stroke-width": t.plotBorderWidth || 0,
          fill: "none"
        }), y[C](y.crisp({
          x: h,
          y: d,
          width: c,
          height: p
        }, -y.strokeWidth())), this.isDirtyBox = !1, N(this, "afterDrawChartBox");
      }
      propFromSeries() {
        let t, e, i;
        let s = this,
          o = s.options.chart,
          r = s.options.series;
        ["inverted", "angular", "polar"].forEach(function (a) {
          for (e = A[o.type], i = o[a] || e && e.prototype[a], t = r && r.length; !i && t--;) (e = A[r[t].type]) && e.prototype[a] && (i = !0);
          s[a] = i;
        });
      }
      linkSeries(t) {
        let e = this,
          i = e.series;
        i.forEach(function (t) {
          t.linkedSeries.length = 0;
        }), i.forEach(function (t) {
          let {
            linkedTo: i
          } = t.options;
          if (U(i)) {
            let s;
            (s = ":previous" === i ? e.series[t.index - 1] : e.get(i)) && s.linkedParent !== t && (s.linkedSeries.push(t), t.linkedParent = s, s.enabledDataSorting && t.setDataSortingOptions(), t.visible = V(t.options.visible, s.options.visible, t.visible));
          }
        }), N(this, "afterLinkSeries", {
          isUpdating: t
        });
      }
      renderSeries() {
        this.series.forEach(function (t) {
          t.translate(), t.render();
        });
      }
      render() {
        let t = this.axes,
          e = this.colorAxis,
          i = this.renderer,
          s = this.options.chart.axisLayoutRuns || 2,
          o = t => {
            t.forEach(t => {
              t.visible && t.render();
            });
          },
          r = 0,
          a = !0,
          n,
          l = 0;
        for (let e of (this.setTitle(), N(this, "beforeMargins"), this.getStacks?.(), this.getMargins(!0), this.setChartSize(), t)) {
          let {
              options: t
            } = e,
            {
              labels: i
            } = t;
          if (this.hasCartesianSeries && e.horiz && e.visible && i.enabled && e.series.length && "colorAxis" !== e.coll && !this.polar) {
            r = t.tickLength, e.createGroups();
            let s = new u(e, 0, "", !0),
              o = s.createLabel("x", i);
            if (s.destroy(), o && V(i.reserveSpace, !X(t.crossing)) && (r = o.getBBox().height + i.distance + Math.max(t.offset || 0, 0)), r) {
              o?.destroy();
              break;
            }
          }
        }
        for (this.plotHeight = Math.max(this.plotHeight - r, 0); (a || n || s > 1) && l < s;) {
          let e = this.plotWidth,
            i = this.plotHeight;
          for (let e of t) 0 === l ? e.setScale() : (e.horiz && a || !e.horiz && n) && e.setTickInterval(!0);
          0 === l ? this.getAxisMargins() : this.getMargins(), a = e / this.plotWidth > (l ? 1 : 1.1), n = i / this.plotHeight > (l ? 1 : 1.05), l++;
        }
        this.drawChartBox(), this.hasCartesianSeries ? o(t) : e && e.length && o(e), this.seriesGroup || (this.seriesGroup = i.g("series-group").attr({
          zIndex: 3
        }).shadow(this.options.chart.seriesGroupShadow).add()), this.renderSeries(), this.addCredits(), this.setResponsive && this.setResponsive(), this.hasRendered = !0;
      }
      addCredits(t) {
        let e = this,
          i = F(!0, this.options.credits, t);
        i.enabled && !this.credits && (this.credits = this.renderer.text(i.text + (this.mapCredits || ""), 0, 0).addClass("highcharts-credits").on("click", function () {
          i.href && (k.location.href = i.href);
        }).attr({
          align: i.position.align,
          zIndex: 8
        }), e.styledMode || this.credits.css(i.style), this.credits.add().align(i.position), this.credits.update = function (t) {
          e.credits = e.credits.destroy(), e.addCredits(t);
        });
      }
      destroy() {
        let t;
        let e = this,
          i = e.axes,
          s = e.series,
          o = e.container,
          a = o && o.parentNode;
        for (N(e, "destroy"), e.renderer.forExport ? I(M, e) : M[e.index] = void 0, r.chartCount--, e.renderTo.removeAttribute("data-highcharts-chart"), _(e), t = i.length; t--;) i[t] = i[t].destroy();
        for (this.scroller && this.scroller.destroy && this.scroller.destroy(), t = s.length; t--;) s[t] = s[t].destroy();
        ["title", "subtitle", "chartBackground", "plotBackground", "plotBGImage", "plotBorder", "seriesGroup", "clipRect", "credits", "pointer", "rangeSelector", "legend", "resetZoomButton", "tooltip", "renderer"].forEach(function (t) {
          let i = e[t];
          i && i.destroy && (e[t] = i.destroy());
        }), o && (o.innerHTML = p.emptyHTML, _(o), a && E(o)), Y(e, function (t, i) {
          delete e[i];
        });
      }
      firstRender() {
        let t = this,
          e = t.options;
        t.getContainer(), t.resetMargins(), t.setChartSize(), t.propFromSeries(), t.getAxes();
        let i = W(e.series) ? e.series : [];
        e.series = [], i.forEach(function (e) {
          t.initSeries(e);
        }), t.linkSeries(), t.setSortedData(), N(t, "beforeRender"), t.render(), t.pointer?.getChartPosition(), t.renderer.imgCount || t.hasLoaded || t.onload(), t.temporaryDisplay(!0);
      }
      onload() {
        this.callbacks.concat([this.callback]).forEach(function (t) {
          t && void 0 !== this.index && t.apply(this, [this]);
        }, this), N(this, "load"), N(this, "render"), O(this.index) && this.setReflow(), this.warnIfA11yModuleNotLoaded(), this.hasLoaded = !0;
      }
      warnIfA11yModuleNotLoaded() {
        let {
          options: t,
          title: e
        } = this;
        !t || this.accessibility || (this.renderer.boxWrapper.attr({
          role: "img",
          "aria-label": (e && e.element.textContent || "").replace(/</g, "&lt;")
        }), t.accessibility && !1 === t.accessibility.enabled || B('Highcharts warning: Consider including the "accessibility.js" module to make your chart more usable for people with disabilities. Set the "accessibility.enabled" option to false to remove this warning. See https://www.highcharts.com/docs/accessibility/accessibility-module.', !1, this));
      }
      addSeries(t, e, i) {
        let s;
        let o = this;
        return t && (e = V(e, !0), N(o, "addSeries", {
          options: t
        }, function () {
          s = o.initSeries(t), o.isDirtyLegend = !0, o.linkSeries(), s.enabledDataSorting && s.setData(t.data, !1), N(o, "afterAddSeries", {
            series: s
          }), e && o.redraw(i);
        })), s;
      }
      addAxis(t, e, i, s) {
        return this.createAxis(e ? "xAxis" : "yAxis", {
          axis: t,
          redraw: i,
          animation: s
        });
      }
      addColorAxis(t, e, i) {
        return this.createAxis("colorAxis", {
          axis: t,
          redraw: e,
          animation: i
        });
      }
      createAxis(t, i) {
        let s = new e(this, i.axis, t);
        return V(i.redraw, !0) && this.redraw(i.animation), s;
      }
      showLoading(t) {
        let e = this,
          i = e.options,
          s = i.loading,
          o = function () {
            r && j(r, {
              left: e.plotLeft + "px",
              top: e.plotTop + "px",
              width: e.plotWidth + "px",
              height: e.plotHeight + "px"
            });
          },
          r = e.loadingDiv,
          a = e.loadingSpan;
        r || (e.loadingDiv = r = L("div", {
          className: "highcharts-loading highcharts-loading-hidden"
        }, null, e.container)), a || (e.loadingSpan = a = L("span", {
          className: "highcharts-loading-inner"
        }, null, r), T(e, "redraw", o)), r.className = "highcharts-loading", p.setElementHTML(a, V(t, i.lang.loading, "")), e.styledMode || (j(r, z(s.style, {
          zIndex: 10
        })), j(a, s.labelStyle), e.loadingShown || (j(r, {
          opacity: 0,
          display: ""
        }), g(r, {
          opacity: s.style.opacity || .5
        }, {
          duration: s.showDuration || 0
        }))), e.loadingShown = !0, o();
      }
      hideLoading() {
        let t = this.options,
          e = this.loadingDiv;
        e && (e.className = "highcharts-loading highcharts-loading-hidden", this.styledMode || g(e, {
          opacity: 0
        }, {
          duration: t.loading.hideDuration || 100,
          complete: function () {
            j(e, {
              display: "none"
            });
          }
        })), this.loadingShown = !1;
      }
      update(t, e, i, s) {
        let o, r, a;
        let n = this,
          l = {
            credits: "addCredits",
            title: "setTitle",
            subtitle: "setSubtitle",
            caption: "setCaption"
          },
          h = t.isResponsiveOptions,
          c = [];
        N(n, "update", {
          options: t
        }), h || n.setResponsive(!1, !0), t = D(t, n.options), n.userOptions = F(n.userOptions, t);
        let p = t.chart;
        p && (F(!0, n.options.chart, p), this.setZoomOptions(), "className" in p && n.setClassName(p.className), ("inverted" in p || "polar" in p || "type" in p) && (n.propFromSeries(), o = !0), "alignTicks" in p && (o = !0), "events" in p && v(this, p), Y(p, function (t, e) {
          -1 !== n.propsRequireUpdateSeries.indexOf("chart." + e) && (r = !0), -1 !== n.propsRequireDirtyBox.indexOf(e) && (n.isDirtyBox = !0), -1 === n.propsRequireReflow.indexOf(e) || (n.isDirtyBox = !0, h || (a = !0));
        }), !n.styledMode && p.style && n.renderer.setStyle(n.options.chart.style || {})), !n.styledMode && t.colors && (this.options.colors = t.colors), t.time && (this.time === y && (this.time = new d(t.time)), F(!0, n.options.time, t.time)), Y(t, function (e, i) {
          n[i] && "function" == typeof n[i].update ? n[i].update(e, !1) : "function" == typeof n[l[i]] ? n[l[i]](e) : "colors" !== i && -1 === n.collectionsWithUpdate.indexOf(i) && F(!0, n.options[i], t[i]), "chart" !== i && -1 !== n.propsRequireUpdateSeries.indexOf(i) && (r = !0);
        }), this.collectionsWithUpdate.forEach(function (e) {
          t[e] && (q(t[e]).forEach(function (t, s) {
            let o;
            let r = O(t.id);
            r && (o = n.get(t.id)), !o && n[e] && (o = n[e][V(t.index, s)]) && (r && O(o.options.id) || o.options.isInternal) && (o = void 0), o && o.coll === e && (o.update(t, !1), i && (o.touched = !0)), !o && i && n.collectionsWithInit[e] && (n.collectionsWithInit[e][0].apply(n, [t].concat(n.collectionsWithInit[e][1] || []).concat([!1])).touched = !0);
          }), i && n[e].forEach(function (t) {
            t.touched || t.options.isInternal ? delete t.touched : c.push(t);
          }));
        }), c.forEach(function (t) {
          t.chart && t.remove && t.remove(!1);
        }), o && n.axes.forEach(function (t) {
          t.update({}, !1);
        }), r && n.getSeriesOrderByLinks().forEach(function (t) {
          t.chart && t.update({}, !1);
        }, this);
        let u = p && p.width,
          g = p && (U(p.height) ? $(p.height, u || n.chartWidth) : p.height);
        a || X(u) && u !== n.chartWidth || X(g) && g !== n.chartHeight ? n.setSize(u, g, s) : V(e, !0) && n.redraw(s), N(n, "afterUpdate", {
          options: t,
          redraw: e,
          animation: s
        });
      }
      setSubtitle(t, e) {
        this.applyDescription("subtitle", t), this.layOutTitles(e);
      }
      setCaption(t, e) {
        this.applyDescription("caption", t), this.layOutTitles(e);
      }
      showResetZoom() {
        let t = this,
          e = x.lang,
          i = t.zooming.resetButton,
          s = i.theme,
          o = "chart" === i.relativeTo || "spacingBox" === i.relativeTo ? null : "plotBox";
        function r() {
          t.zoomOut();
        }
        N(this, "beforeShowResetZoom", null, function () {
          t.resetZoomButton = t.renderer.button(e.resetZoom, null, null, r, s).attr({
            align: i.position.align,
            title: e.resetZoomTitle
          }).addClass("highcharts-reset-zoom").add().align(i.position, !1, o);
        }), N(this, "afterShowResetZoom");
      }
      zoomOut() {
        N(this, "selection", {
          resetSelection: !0
        }, () => this.transform({
          reset: !0,
          trigger: "zoom"
        }));
      }
      pan(t, e) {
        let i = this,
          s = "object" == typeof e ? e : {
            enabled: e,
            type: "x"
          },
          o = s.type,
          r = o && i[{
            x: "xAxis",
            xy: "axes",
            y: "yAxis"
          }[o]].filter(t => t.options.panningEnabled && !t.options.isInternal),
          a = i.options.chart;
        a?.panning && (a.panning = s), N(this, "pan", {
          originalEvent: t
        }, () => {
          i.transform({
            axes: r,
            event: t,
            to: {
              x: t.chartX - (i.mouseDownX || 0),
              y: t.chartY - (i.mouseDownY || 0)
            },
            trigger: "pan"
          }), j(i.container, {
            cursor: "move"
          });
        });
      }
      transform(t) {
        let {
            axes: e = this.axes,
            event: i,
            from: s = {},
            reset: o,
            selection: r,
            to: a = {},
            trigger: n
          } = t,
          {
            inverted: l
          } = this,
          h = !1,
          d,
          c;
        for (let t of (this.hoverPoints?.forEach(t => t.setState()), e)) {
          let {
              horiz: e,
              len: p,
              minPointOffset: u = 0,
              options: g,
              reversed: f
            } = t,
            m = e ? "width" : "height",
            x = e ? "x" : "y",
            y = V(a[m], t.len),
            b = V(s[m], t.len),
            v = 10 > Math.abs(y) ? 1 : y / b,
            M = (s[x] || 0) + b / 2 - t.pos,
            C = M - ((a[x] ?? t.pos) + y / 2 - t.pos) / v,
            S = f && !l || !f && l ? -1 : 1;
          if (!o && (M < 0 || M > t.len)) continue;
          let w = t.toValue(C, !0) + (r || t.isOrdinal ? 0 : u * S),
            k = t.toValue(C + p / v, !0) - (r || t.isOrdinal ? 0 : u * S || 0),
            A = t.allExtremes;
          if (w > k && ([w, k] = [k, w]), 1 === v && !o && "yAxis" === t.coll && !A) {
            for (let e of t.series) {
              let t = e.getExtremes(e.getProcessedData(!0).yData, !0);
              A ?? (A = {
                dataMin: Number.MAX_VALUE,
                dataMax: -Number.MAX_VALUE
              }), X(t.dataMin) && X(t.dataMax) && (A.dataMin = Math.min(t.dataMin, A.dataMin), A.dataMax = Math.max(t.dataMax, A.dataMax));
            }
            t.allExtremes = A;
          }
          let {
              dataMin: T,
              dataMax: P,
              min: L,
              max: j
            } = z(t.getExtremes(), A || {}),
            D = T ?? g.min,
            E = P ?? g.max,
            I = k - w,
            B = t.categories ? 0 : Math.min(I, E - D),
            R = D - B * (O(g.min) ? 0 : g.minPadding),
            N = E + B * (O(g.max) ? 0 : g.maxPadding),
            G = t.allowZoomOutside || 1 === v || "zoom" !== n && v > 1,
            W = Math.min(g.min ?? R, R, G ? L : R),
            H = Math.max(g.max ?? N, N, G ? j : N);
          (!t.isOrdinal || t.options.overscroll || 1 !== v || o) && (w < W && (w = W, v >= 1 && (k = w + I)), k > H && (k = H, v >= 1 && (w = k - I)), (o || t.series.length && (w !== L || k !== j) && w >= W && k <= H) && (r ? r[t.coll].push({
            axis: t,
            min: w,
            max: k
          }) : (t.isPanning = "zoom" !== n, t.isPanning && (c = !0), t.setExtremes(o ? void 0 : w, o ? void 0 : k, !1, !1, {
            move: C,
            trigger: n,
            scale: v
          }), !o && (w > W || k < H) && "mousewheel" !== n && (d = !0)), h = !0), i && (this[e ? "mouseDownX" : "mouseDownY"] = i[e ? "chartX" : "chartY"]));
        }
        return h && (r ? N(this, "selection", r, () => {
          delete t.selection, t.trigger = "zoom", this.transform(t);
        }) : (!d || c || this.resetZoomButton ? !d && this.resetZoomButton && (this.resetZoomButton = this.resetZoomButton.destroy()) : this.showResetZoom(), this.redraw("zoom" === n && (this.options.chart.animation ?? this.pointCount < 100)))), h;
      }
    }
    return z(Q.prototype, {
      callbacks: [],
      collectionsWithInit: {
        xAxis: [Q.prototype.addAxis, [!0]],
        yAxis: [Q.prototype.addAxis, [!1]],
        series: [Q.prototype.addSeries]
      },
      collectionsWithUpdate: ["xAxis", "yAxis", "series"],
      propsRequireDirtyBox: ["backgroundColor", "borderColor", "borderWidth", "borderRadius", "plotBackgroundColor", "plotBackgroundImage", "plotBorderColor", "plotBorderWidth", "plotShadow", "shadow"],
      propsRequireReflow: ["margin", "marginTop", "marginRight", "marginBottom", "marginLeft", "spacing", "spacingTop", "spacingRight", "spacingBottom", "spacingLeft"],
      propsRequireUpdateSeries: ["chart.inverted", "chart.polar", "chart.ignoreHiddenSeries", "chart.type", "colors", "plotOptions", "time", "tooltip"]
    }), Q;
  }), i(e, "Extensions/ScrollablePlotArea.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Globals.js"], e["Core/Renderer/RendererRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
        stop: o
      } = t,
      {
        composed: r
      } = e,
      {
        addEvent: a,
        createElement: n,
        css: l,
        defined: h,
        merge: d,
        pushUnique: c
      } = s;
    function p() {
      let t = this.scrollablePlotArea;
      (this.scrollablePixelsX || this.scrollablePixelsY) && !t && (this.scrollablePlotArea = t = new g(this)), t?.applyFixed();
    }
    function u() {
      this.chart.scrollablePlotArea && (this.chart.scrollablePlotArea.isDirty = !0);
    }
    class g {
      static compose(t, e, i) {
        c(r, this.compose) && (a(t, "afterInit", u), a(e, "afterSetChartSize", t => this.afterSetSize(t.target, t)), a(e, "render", p), a(i, "show", u));
      }
      static afterSetSize(t, e) {
        let i, s, o;
        let {
            minWidth: r,
            minHeight: a
          } = t.options.chart.scrollablePlotArea || {},
          {
            clipBox: n,
            plotBox: l,
            inverted: c,
            renderer: p
          } = t;
        if (!p.forExport && (r ? (t.scrollablePixelsX = i = Math.max(0, r - t.chartWidth), i && (t.scrollablePlotBox = d(t.plotBox), l.width = t.plotWidth += i, n[c ? "height" : "width"] += i, o = !0)) : a && (t.scrollablePixelsY = s = Math.max(0, a - t.chartHeight), h(s) && (t.scrollablePlotBox = d(t.plotBox), l.height = t.plotHeight += s, n[c ? "width" : "height"] += s, o = !1)), h(o) && !e.skipAxes)) for (let e of t.axes) e.horiz === o && (e.setAxisSize(), e.setAxisTranslation());
      }
      constructor(t) {
        let e;
        let s = t.options.chart,
          o = i.getRendererType(),
          r = s.scrollablePlotArea || {},
          h = this.moveFixedElements.bind(this),
          d = {
            WebkitOverflowScrolling: "touch",
            overflowX: "hidden",
            overflowY: "hidden"
          };
        t.scrollablePixelsX && (d.overflowX = "auto"), t.scrollablePixelsY && (d.overflowY = "auto"), this.chart = t;
        let c = this.parentDiv = n("div", {
            className: "highcharts-scrolling-parent"
          }, {
            position: "relative"
          }, t.renderTo),
          p = this.scrollingContainer = n("div", {
            className: "highcharts-scrolling"
          }, d, c),
          u = this.innerContainer = n("div", {
            className: "highcharts-inner-container"
          }, void 0, p),
          g = this.fixedDiv = n("div", {
            className: "highcharts-fixed"
          }, {
            position: "absolute",
            overflow: "hidden",
            pointerEvents: "none",
            zIndex: (s.style?.zIndex || 0) + 2,
            top: 0
          }, void 0, !0),
          f = this.fixedRenderer = new o(g, t.chartWidth, t.chartHeight, s.style);
        this.mask = f.path().attr({
          fill: s.backgroundColor || "#fff",
          "fill-opacity": r.opacity ?? .85,
          zIndex: -1
        }).addClass("highcharts-scrollable-mask").add(), p.parentNode.insertBefore(g, p), l(t.renderTo, {
          overflow: "visible"
        }), a(t, "afterShowResetZoom", h), a(t, "afterApplyDrilldown", h), a(t, "afterLayOutTitles", h), a(p, "scroll", () => {
          let {
            pointer: i,
            hoverPoint: s
          } = t;
          i && (delete i.chartPosition, s && (e = s), i.runPointActions(void 0, e, !0));
        }), u.appendChild(t.container);
      }
      applyFixed() {
        let {
            chart: t,
            fixedRenderer: e,
            isDirty: i,
            scrollingContainer: s
          } = this,
          {
            axisOffset: r,
            chartWidth: a,
            chartHeight: n,
            container: d,
            plotHeight: c,
            plotLeft: p,
            plotTop: u,
            plotWidth: g,
            scrollablePixelsX: f = 0,
            scrollablePixelsY: m = 0
          } = t,
          {
            scrollPositionX: x = 0,
            scrollPositionY: y = 0
          } = t.options.chart.scrollablePlotArea || {},
          b = a + f,
          v = n + m;
        e.setSize(a, n), (i ?? !0) && (this.isDirty = !1, this.moveFixedElements()), o(t.container), l(d, {
          width: `${b}px`,
          height: `${v}px`
        }), t.renderer.boxWrapper.attr({
          width: b,
          height: v,
          viewBox: [0, 0, b, v].join(" ")
        }), t.chartBackground?.attr({
          width: b,
          height: v
        }), l(s, {
          width: `${a}px`,
          height: `${n}px`
        }), h(i) || (s.scrollLeft = f * x, s.scrollTop = m * y);
        let M = u - r[0] - 1,
          C = p - r[3] - 1,
          S = u + c + r[2] + 1,
          w = p + g + r[1] + 1,
          k = p + g - f,
          A = u + c - m,
          T = [["M", 0, 0]];
        f ? T = [["M", 0, M], ["L", p - 1, M], ["L", p - 1, S], ["L", 0, S], ["Z"], ["M", k, M], ["L", a, M], ["L", a, S], ["L", k, S], ["Z"]] : m && (T = [["M", C, 0], ["L", C, u - 1], ["L", w, u - 1], ["L", w, 0], ["Z"], ["M", C, A], ["L", C, n], ["L", w, n], ["L", w, A], ["Z"]]), "adjustHeight" !== t.redrawTrigger && this.mask.attr({
          d: T
        });
      }
      moveFixedElements() {
        let t;
        let {
            container: e,
            inverted: i,
            scrollablePixelsX: s,
            scrollablePixelsY: o
          } = this.chart,
          r = this.fixedRenderer,
          a = g.fixedSelectors;
        for (let n of (s && !i ? t = ".highcharts-yaxis" : s && i ? t = ".highcharts-xaxis" : o && !i ? t = ".highcharts-xaxis" : o && i && (t = ".highcharts-yaxis"), t && a.push(`${t}:not(.highcharts-radial-axis)`, `${t}-labels:not(.highcharts-radial-axis-labels)`), a)) [].forEach.call(e.querySelectorAll(n), t => {
          (t.namespaceURI === r.SVG_NS ? r.box : r.box.parentNode).appendChild(t), t.style.pointerEvents = "auto";
        });
      }
    }
    return g.fixedSelectors = [".highcharts-breadcrumbs-group", ".highcharts-contextbutton", ".highcharts-caption", ".highcharts-credits", ".highcharts-drillup-button", ".highcharts-legend", ".highcharts-legend-checkbox", ".highcharts-navigator-series", ".highcharts-navigator-xaxis", ".highcharts-navigator-yaxis", ".highcharts-navigator", ".highcharts-range-selector-group", ".highcharts-reset-zoom", ".highcharts-scrollbar", ".highcharts-subtitle", ".highcharts-title"], g;
  }), i(e, "Core/Axis/Stacking/StackItem.js", [e["Core/Templating.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        format: s
      } = t,
      {
        series: o
      } = e,
      {
        destroyObjectProperties: r,
        fireEvent: a,
        isNumber: n,
        pick: l
      } = i;
    return class {
      constructor(t, e, i, s, o) {
        let r = t.chart.inverted,
          a = t.reversed;
        this.axis = t;
        let n = this.isNegative = !!i != !!a;
        this.options = e = e || {}, this.x = s, this.total = null, this.cumulative = null, this.points = {}, this.hasValidPoints = !1, this.stack = o, this.leftCliff = 0, this.rightCliff = 0, this.alignOptions = {
          align: e.align || (r ? n ? "left" : "right" : "center"),
          verticalAlign: e.verticalAlign || (r ? "middle" : n ? "bottom" : "top"),
          y: e.y,
          x: e.x
        }, this.textAlign = e.textAlign || (r ? n ? "right" : "left" : "center");
      }
      destroy() {
        r(this, this.axis);
      }
      render(t) {
        let e = this.axis.chart,
          i = this.options,
          o = i.format,
          r = o ? s(o, this, e) : i.formatter.call(this);
        if (this.label) this.label.attr({
          text: r,
          visibility: "hidden"
        });else {
          this.label = e.renderer.label(r, null, void 0, i.shape, void 0, void 0, i.useHTML, !1, "stack-labels");
          let s = {
            r: i.borderRadius || 0,
            text: r,
            padding: l(i.padding, 5),
            visibility: "hidden"
          };
          e.styledMode || (s.fill = i.backgroundColor, s.stroke = i.borderColor, s["stroke-width"] = i.borderWidth, this.label.css(i.style || {})), this.label.attr(s), this.label.added || this.label.add(t);
        }
        this.label.labelrank = e.plotSizeY, a(this, "afterRender");
      }
      setOffset(t, e, i, s, r, h) {
        let {
            alignOptions: d,
            axis: c,
            label: p,
            options: u,
            textAlign: g
          } = this,
          f = c.chart,
          m = this.getStackBox({
            xOffset: t,
            width: e,
            boxBottom: i,
            boxTop: s,
            defaultX: r,
            xAxis: h
          }),
          {
            verticalAlign: x
          } = d;
        if (p && m) {
          let t = p.getBBox(void 0, 0),
            e = p.padding,
            i = "justify" === l(u.overflow, "justify"),
            s;
          d.x = u.x || 0, d.y = u.y || 0;
          let {
            x: r,
            y: a
          } = this.adjustStackPosition({
            labelBox: t,
            verticalAlign: x,
            textAlign: g
          });
          m.x -= r, m.y -= a, p.align(d, !1, m), (s = f.isInsidePlot(p.alignAttr.x + d.x + r, p.alignAttr.y + d.y + a)) || (i = !1), i && o.prototype.justifyDataLabel.call(c, p, d, p.alignAttr, t, m), p.attr({
            x: p.alignAttr.x,
            y: p.alignAttr.y,
            rotation: u.rotation,
            rotationOriginX: t.width * {
              left: 0,
              center: .5,
              right: 1
            }[u.textAlign || "center"],
            rotationOriginY: t.height / 2
          }), l(!i && u.crop, !0) && (s = n(p.x) && n(p.y) && f.isInsidePlot(p.x - e + (p.width || 0), p.y) && f.isInsidePlot(p.x + e, p.y)), p[s ? "show" : "hide"]();
        }
        a(this, "afterSetOffset", {
          xOffset: t,
          width: e
        });
      }
      adjustStackPosition({
        labelBox: t,
        verticalAlign: e,
        textAlign: i
      }) {
        let s = {
            bottom: 0,
            middle: 1,
            top: 2,
            right: 1,
            center: 0,
            left: -1
          },
          o = s[e],
          r = s[i];
        return {
          x: t.width / 2 + t.width / 2 * r,
          y: t.height / 2 * o
        };
      }
      getStackBox(t) {
        let e = this.axis,
          i = e.chart,
          {
            boxTop: s,
            defaultX: o,
            xOffset: r,
            width: a,
            boxBottom: h
          } = t,
          d = e.stacking.usePercentage ? 100 : l(s, this.total, 0),
          c = e.toPixels(d),
          p = t.xAxis || i.xAxis[0],
          u = l(o, p.translate(this.x)) + r,
          g = Math.abs(c - e.toPixels(h || n(e.min) && e.logarithmic && e.logarithmic.lin2log(e.min) || 0)),
          f = i.inverted,
          m = this.isNegative;
        return f ? {
          x: (m ? c : c - g) - i.plotLeft,
          y: p.height - u - a + p.top - i.plotTop,
          width: g,
          height: a
        } : {
          x: u + p.transB - i.plotLeft,
          y: (m ? c - g : c) - i.plotTop,
          width: a,
          height: g
        };
      }
    };
  }), i(e, "Core/Axis/Stacking/StackingAxis.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Axis/Axis.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Axis/Stacking/StackItem.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    var r;
    let {
        getDeferredAnimation: a
      } = t,
      {
        series: {
          prototype: n
        }
      } = i,
      {
        addEvent: l,
        correctFloat: h,
        defined: d,
        destroyObjectProperties: c,
        fireEvent: p,
        isArray: u,
        isNumber: g,
        objectEach: f,
        pick: m
      } = o;
    function x() {
      let t = this.inverted;
      this.axes.forEach(t => {
        t.stacking && t.stacking.stacks && t.hasVisibleSeries && (t.stacking.oldStacks = t.stacking.stacks);
      }), this.series.forEach(e => {
        let i = e.xAxis && e.xAxis.options || {};
        e.options.stacking && e.reserveSpace() && (e.stackKey = [e.type, m(e.options.stack, ""), t ? i.top : i.left, t ? i.height : i.width].join(","));
      });
    }
    function y() {
      let t = this.stacking;
      if (t) {
        let e = t.stacks;
        f(e, (t, i) => {
          c(t), delete e[i];
        }), t.stackTotalGroup?.destroy();
      }
    }
    function b() {
      this.stacking || (this.stacking = new k(this));
    }
    function v(t, e, i, s) {
      return !d(t) || t.x !== e || s && t.stackKey !== s ? t = {
        x: e,
        index: 0,
        key: s,
        stackKey: s
      } : t.index++, t.key = [i, e, t.index].join(","), t;
    }
    function M() {
      let t;
      let e = this,
        i = e.yAxis,
        s = e.stackKey || "",
        o = i.stacking.stacks,
        r = e.processedXData,
        a = e.options.stacking,
        n = e[a + "Stacker"];
      n && [s, "-" + s].forEach(i => {
        let s = r.length,
          a,
          l,
          h;
        for (; s--;) a = r[s], t = e.getStackIndicator(t, a, e.index, i), l = o[i]?.[a], (h = l?.points[t.key || ""]) && n.call(e, h, l, s);
      });
    }
    function C(t, e, i) {
      let s = e.total ? 100 / e.total : 0;
      t[0] = h(t[0] * s), t[1] = h(t[1] * s), this.stackedYData[i] = t[1];
    }
    function S(t) {
      (this.is("column") || this.is("columnrange")) && (this.options.centerInCategory && !this.options.stacking && this.chart.series.length > 1 ? n.setStackedPoints.call(this, t, "group") : t.stacking.resetStacks());
    }
    function w(t, e) {
      let i, o, r, a, n, l, c, p, g;
      let f = e || this.options.stacking;
      if (!f || !this.reserveSpace() || ({
        group: "xAxis"
      }[f] || "yAxis") !== t.coll) return;
      let x = this.processedXData,
        y = this.processedYData,
        b = [],
        v = y.length,
        M = this.options,
        C = M.threshold || 0,
        S = M.startFromThreshold ? C : 0,
        w = M.stack,
        k = e ? `${this.type},${f}` : this.stackKey || "",
        A = "-" + k,
        T = this.negStacks,
        P = t.stacking,
        L = P.stacks,
        j = P.oldStacks;
      for (P.stacksTouched += 1, c = 0; c < v; c++) {
        p = x[c], g = y[c], l = (i = this.getStackIndicator(i, p, this.index)).key || "", L[n = (o = T && g < (S ? 0 : C)) ? A : k] || (L[n] = {}), L[n][p] || (j[n]?.[p] ? (L[n][p] = j[n][p], L[n][p].total = null) : L[n][p] = new s(t, t.options.stackLabels, !!o, p, w)), r = L[n][p], null !== g ? (r.points[l] = r.points[this.index] = [m(r.cumulative, S)], d(r.cumulative) || (r.base = l), r.touched = P.stacksTouched, i.index > 0 && !1 === this.singleStacks && (r.points[l][0] = r.points[this.index + "," + p + ",0"][0])) : (delete r.points[l], delete r.points[this.index]);
        let e = r.total || 0;
        "percent" === f ? (a = o ? k : A, e = T && L[a]?.[p] ? (a = L[a][p]).total = Math.max(a.total || 0, e) + Math.abs(g) || 0 : h(e + (Math.abs(g) || 0))) : "group" === f ? (u(g) && (g = g[0]), null !== g && e++) : e = h(e + (g || 0)), "group" === f ? r.cumulative = (e || 1) - 1 : r.cumulative = h(m(r.cumulative, S) + (g || 0)), r.total = e, null !== g && (r.points[l].push(r.cumulative), b[c] = r.cumulative, r.hasValidPoints = !0);
      }
      "percent" === f && (P.usePercentage = !0), "group" !== f && (this.stackedYData = b), P.oldStacks = {};
    }
    class k {
      constructor(t) {
        this.oldStacks = {}, this.stacks = {}, this.stacksTouched = 0, this.axis = t;
      }
      buildStacks() {
        let t, e;
        let i = this.axis,
          s = i.series,
          o = "xAxis" === i.coll,
          r = i.options.reversedStacks,
          a = s.length;
        for (this.resetStacks(), this.usePercentage = !1, e = a; e--;) t = s[r ? e : a - e - 1], o && t.setGroupedPoints(i), t.setStackedPoints(i);
        if (!o) for (e = 0; e < a; e++) s[e].modifyStacks();
        p(i, "afterBuildStacks");
      }
      cleanStacks() {
        this.oldStacks && (this.stacks = this.oldStacks, f(this.stacks, t => {
          f(t, t => {
            t.cumulative = t.total;
          });
        }));
      }
      resetStacks() {
        f(this.stacks, t => {
          f(t, (e, i) => {
            g(e.touched) && e.touched < this.stacksTouched ? (e.destroy(), delete t[i]) : (e.total = null, e.cumulative = null);
          });
        });
      }
      renderStackTotals() {
        let t = this.axis,
          e = t.chart,
          i = e.renderer,
          s = this.stacks,
          o = a(e, t.options.stackLabels?.animation || !1),
          r = this.stackTotalGroup = this.stackTotalGroup || i.g("stack-labels").attr({
            zIndex: 6,
            opacity: 0
          }).add();
        r.translate(e.plotLeft, e.plotTop), f(s, t => {
          f(t, t => {
            t.render(r);
          });
        }), r.animate({
          opacity: 1
        }, o);
      }
    }
    return (r || (r = {})).compose = function (t, e, i) {
      let s = e.prototype,
        o = i.prototype;
      s.getStacks || (l(t, "init", b), l(t, "destroy", y), s.getStacks = x, o.getStackIndicator = v, o.modifyStacks = M, o.percentStacker = C, o.setGroupedPoints = S, o.setStackedPoints = w);
    }, r;
  }), i(e, "Series/Line/LineSeries.js", [e["Core/Series/Series.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
      defined: s,
      merge: o,
      isObject: r
    } = i;
    class a extends t {
      drawGraph() {
        let t = this.options,
          e = (this.gappedPath || this.getGraphPath).call(this),
          i = this.chart.styledMode;
        [this, ...this.zones].forEach((s, a) => {
          let n,
            l = s.graph,
            h = l ? "animate" : "attr",
            d = s.dashStyle || t.dashStyle;
          l ? (l.endX = this.preventGraphAnimation ? null : e.xMap, l.animate({
            d: e
          })) : e.length && (s.graph = l = this.chart.renderer.path(e).addClass("highcharts-graph" + (a ? ` highcharts-zone-graph-${a - 1} ` : " ") + (a && s.className || "")).attr({
            zIndex: 1
          }).add(this.group)), l && !i && (n = {
            stroke: !a && t.lineColor || s.color || this.color || "#cccccc",
            "stroke-width": t.lineWidth || 0,
            fill: this.fillGraph && this.color || "none"
          }, d ? n.dashstyle = d : "square" !== t.linecap && (n["stroke-linecap"] = n["stroke-linejoin"] = "round"), l[h](n).shadow(a < 2 && t.shadow && o({
            filterUnits: "userSpaceOnUse"
          }, r(t.shadow) ? t.shadow : {}))), l && (l.startX = e.xMap, l.isArea = e.isArea);
        });
      }
      getGraphPath(t, e, i) {
        let o = this,
          r = o.options,
          a = [],
          n = [],
          l,
          h = r.step,
          d = (t = t || o.points).reversed;
        return d && t.reverse(), (h = {
          right: 1,
          center: 2
        }[h] || h && 3) && d && (h = 4 - h), (t = this.getValidPoints(t, !1, !(r.connectNulls && !e && !i))).forEach(function (d, c) {
          let p;
          let u = d.plotX,
            g = d.plotY,
            f = t[c - 1],
            m = d.isNull || "number" != typeof g;
          (d.leftCliff || f && f.rightCliff) && !i && (l = !0), m && !s(e) && c > 0 ? l = !r.connectNulls : m && !e ? l = !0 : (0 === c || l ? p = [["M", d.plotX, d.plotY]] : o.getPointSpline ? p = [o.getPointSpline(t, d, c)] : h ? (p = 1 === h ? [["L", f.plotX, g]] : 2 === h ? [["L", (f.plotX + u) / 2, f.plotY], ["L", (f.plotX + u) / 2, g]] : [["L", u, f.plotY]]).push(["L", u, g]) : p = [["L", u, g]], n.push(d.x), h && (n.push(d.x), 2 === h && n.push(d.x)), a.push.apply(a, p), l = !1);
        }), a.xMap = n, o.graphPath = a, a;
      }
    }
    return a.defaultOptions = o(t.defaultOptions, {
      legendSymbol: "lineMarker"
    }), e.registerSeriesType("line", a), a;
  }), i(e, "Series/Area/AreaSeriesDefaults.js", [], function () {
    return {
      threshold: 0,
      legendSymbol: "areaMarker"
    };
  }), i(e, "Series/Area/AreaSeries.js", [e["Series/Area/AreaSeriesDefaults.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        seriesTypes: {
          line: s
        }
      } = e,
      {
        extend: o,
        merge: r,
        objectEach: a,
        pick: n
      } = i;
    class l extends s {
      drawGraph() {
        this.areaPath = [], super.drawGraph.apply(this);
        let {
          areaPath: t,
          options: e
        } = this;
        [this, ...this.zones].forEach((i, s) => {
          let o = {},
            r = i.fillColor || e.fillColor,
            a = i.area,
            n = a ? "animate" : "attr";
          a ? (a.endX = this.preventGraphAnimation ? null : t.xMap, a.animate({
            d: t
          })) : (o.zIndex = 0, (a = i.area = this.chart.renderer.path(t).addClass("highcharts-area" + (s ? ` highcharts-zone-area-${s - 1} ` : " ") + (s && i.className || "")).add(this.group)).isArea = !0), this.chart.styledMode || (o.fill = r || i.color || this.color, o["fill-opacity"] = r ? 1 : e.fillOpacity ?? .75, a.css({
            pointerEvents: this.stickyTracking ? "none" : "auto"
          })), a[n](o), a.startX = t.xMap, a.shiftUnit = e.step ? 2 : 1;
        });
      }
      getGraphPath(t) {
        let e, i, o;
        let r = s.prototype.getGraphPath,
          a = this.options,
          l = a.stacking,
          h = this.yAxis,
          d = [],
          c = [],
          p = this.index,
          u = h.stacking.stacks[this.stackKey],
          g = a.threshold,
          f = Math.round(h.getThreshold(a.threshold)),
          m = n(a.connectNulls, "percent" === l),
          x = function (i, s, o) {
            let r = t[i],
              a = l && u[r.x].points[p],
              n = r[o + "Null"] || 0,
              m = r[o + "Cliff"] || 0,
              x,
              y,
              b = !0;
            m || n ? (x = (n ? a[0] : a[1]) + m, y = a[0] + m, b = !!n) : !l && t[s] && t[s].isNull && (x = y = g), void 0 !== x && (c.push({
              plotX: e,
              plotY: null === x ? f : h.getThreshold(x),
              isNull: b,
              isCliff: !0
            }), d.push({
              plotX: e,
              plotY: null === y ? f : h.getThreshold(y),
              doCurve: !1
            }));
          };
        t = t || this.points, l && (t = this.getStackPoints(t));
        for (let s = 0, r = t.length; s < r; ++s) l || (t[s].leftCliff = t[s].rightCliff = t[s].leftNull = t[s].rightNull = void 0), i = t[s].isNull, e = n(t[s].rectPlotX, t[s].plotX), o = l ? n(t[s].yBottom, f) : f, i && !m || (m || x(s, s - 1, "left"), i && !l && m || (c.push(t[s]), d.push({
          x: s,
          plotX: e,
          plotY: o
        })), m || x(s, s + 1, "right"));
        let y = r.call(this, c, !0, !0);
        d.reversed = !0;
        let b = r.call(this, d, !0, !0),
          v = b[0];
        v && "M" === v[0] && (b[0] = ["L", v[1], v[2]]);
        let M = y.concat(b);
        M.length && M.push(["Z"]);
        let C = r.call(this, c, !1, m);
        return this.chart.series.length > 1 && l && c.some(t => t.isCliff) && (M.hasStackedCliffs = C.hasStackedCliffs = !0), M.xMap = y.xMap, this.areaPath = M, C;
      }
      getStackPoints(t) {
        let e = this,
          i = [],
          s = [],
          o = this.xAxis,
          r = this.yAxis,
          l = r.stacking.stacks[this.stackKey],
          h = {},
          d = r.series,
          c = d.length,
          p = r.options.reversedStacks ? 1 : -1,
          u = d.indexOf(e);
        if (t = t || this.points, this.options.stacking) {
          for (let e = 0; e < t.length; e++) t[e].leftNull = t[e].rightNull = void 0, h[t[e].x] = t[e];
          a(l, function (t, e) {
            null !== t.total && s.push(e);
          }), s.sort(function (t, e) {
            return t - e;
          });
          let g = d.map(t => t.visible);
          s.forEach(function (t, a) {
            let f = 0,
              m,
              x;
            if (h[t] && !h[t].isNull) i.push(h[t]), [-1, 1].forEach(function (i) {
              let o = 1 === i ? "rightNull" : "leftNull",
                r = l[s[a + i]],
                n = 0;
              if (r) {
                let i = u;
                for (; i >= 0 && i < c;) {
                  let s = d[i].index;
                  !(m = r.points[s]) && (s === e.index ? h[t][o] = !0 : g[i] && (x = l[t].points[s]) && (n -= x[1] - x[0])), i += p;
                }
              }
              h[t][1 === i ? "rightCliff" : "leftCliff"] = n;
            });else {
              let e = u;
              for (; e >= 0 && e < c;) {
                let i = d[e].index;
                if (m = l[t].points[i]) {
                  f = m[1];
                  break;
                }
                e += p;
              }
              f = n(f, 0), f = r.translate(f, 0, 1, 0, 1), i.push({
                isNull: !0,
                plotX: o.translate(t, 0, 0, 0, 1),
                x: t,
                plotY: f,
                yBottom: f
              });
            }
          });
        }
        return i;
      }
    }
    return l.defaultOptions = r(s.defaultOptions, t), o(l.prototype, {
      singleStacks: !1
    }), e.registerSeriesType("area", l), l;
  }), i(e, "Series/Spline/SplineSeries.js", [e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        line: i
      } = t.seriesTypes,
      {
        merge: s,
        pick: o
      } = e;
    class r extends i {
      getPointSpline(t, e, i) {
        let s, r, a, n;
        let l = e.plotX || 0,
          h = e.plotY || 0,
          d = t[i - 1],
          c = t[i + 1];
        function p(t) {
          return t && !t.isNull && !1 !== t.doCurve && !e.isCliff;
        }
        if (p(d) && p(c)) {
          let t = d.plotX || 0,
            i = d.plotY || 0,
            o = c.plotX || 0,
            p = c.plotY || 0,
            u = 0;
          s = (1.5 * l + t) / 2.5, r = (1.5 * h + i) / 2.5, a = (1.5 * l + o) / 2.5, n = (1.5 * h + p) / 2.5, a !== s && (u = (n - r) * (a - l) / (a - s) + h - n), r += u, n += u, r > i && r > h ? (r = Math.max(i, h), n = 2 * h - r) : r < i && r < h && (r = Math.min(i, h), n = 2 * h - r), n > p && n > h ? (n = Math.max(p, h), r = 2 * h - n) : n < p && n < h && (n = Math.min(p, h), r = 2 * h - n), e.rightContX = a, e.rightContY = n, e.controlPoints = {
            low: [s, r],
            high: [a, n]
          };
        }
        let u = ["C", o(d.rightContX, d.plotX, 0), o(d.rightContY, d.plotY, 0), o(s, l, 0), o(r, h, 0), l, h];
        return d.rightContX = d.rightContY = void 0, u;
      }
    }
    return r.defaultOptions = s(i.defaultOptions), t.registerSeriesType("spline", r), r;
  }), i(e, "Series/AreaSpline/AreaSplineSeries.js", [e["Series/Spline/SplineSeries.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        area: s,
        area: {
          prototype: o
        }
      } = e.seriesTypes,
      {
        extend: r,
        merge: a
      } = i;
    class n extends t {}
    return n.defaultOptions = a(t.defaultOptions, s.defaultOptions), r(n.prototype, {
      getGraphPath: o.getGraphPath,
      getStackPoints: o.getStackPoints,
      drawGraph: o.drawGraph
    }), e.registerSeriesType("areaspline", n), n;
  }), i(e, "Series/Column/ColumnSeriesDefaults.js", [], function () {
    return {
      borderRadius: 3,
      centerInCategory: !1,
      groupPadding: .2,
      marker: null,
      pointPadding: .1,
      minPointLength: 0,
      cropThreshold: 50,
      pointRange: null,
      states: {
        hover: {
          halo: !1,
          brightness: .1
        },
        select: {
          color: "#cccccc",
          borderColor: "#000000"
        }
      },
      dataLabels: {
        align: void 0,
        verticalAlign: void 0,
        y: void 0
      },
      startFromThreshold: !0,
      stickyTracking: !1,
      tooltip: {
        distance: 6
      },
      threshold: 0,
      borderColor: "#ffffff"
    };
  }), i(e, "Series/Column/ColumnSeries.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Color/Color.js"], e["Series/Column/ColumnSeriesDefaults.js"], e["Core/Globals.js"], e["Core/Series/Series.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a) {
    let {
        animObject: n
      } = t,
      {
        parse: l
      } = e,
      {
        noop: h
      } = s,
      {
        clamp: d,
        crisp: c,
        defined: p,
        extend: u,
        fireEvent: g,
        isArray: f,
        isNumber: m,
        merge: x,
        pick: y,
        objectEach: b
      } = a;
    class v extends o {
      animate(t) {
        let e, i;
        let s = this,
          o = this.yAxis,
          r = o.pos,
          a = o.reversed,
          l = s.options,
          {
            clipOffset: h,
            inverted: c
          } = this.chart,
          p = {},
          g = c ? "translateX" : "translateY";
        t && h ? (p.scaleY = .001, i = d(o.toPixels(l.threshold), r, r + o.len), c ? (i += a ? -Math.floor(h[0]) : Math.ceil(h[2]), p.translateX = i - o.len) : (i += a ? Math.ceil(h[0]) : -Math.floor(h[2]), p.translateY = i), s.clipBox && s.setClip(), s.group.attr(p)) : (e = Number(s.group.attr(g)), s.group.animate({
          scaleY: 1
        }, u(n(s.options.animation), {
          step: function (t, i) {
            s.group && (p[g] = e + i.pos * (r - e), s.group.attr(p));
          }
        })));
      }
      init(t, e) {
        super.init.apply(this, arguments);
        let i = this;
        (t = i.chart).hasRendered && t.series.forEach(function (t) {
          t.type === i.type && (t.isDirty = !0);
        });
      }
      getColumnMetrics() {
        let t = this,
          e = t.options,
          i = t.xAxis,
          s = t.yAxis,
          o = i.options.reversedStacks,
          r = i.reversed && !o || !i.reversed && o,
          a = {},
          n,
          l = 0;
        !1 === e.grouping ? l = 1 : t.chart.series.forEach(function (e) {
          let i;
          let o = e.yAxis,
            r = e.options;
          e.type === t.type && e.reserveSpace() && s.len === o.len && s.pos === o.pos && (r.stacking && "group" !== r.stacking ? (void 0 === a[n = e.stackKey] && (a[n] = l++), i = a[n]) : !1 !== r.grouping && (i = l++), e.columnIndex = i);
        });
        let h = Math.min(Math.abs(i.transA) * (!i.brokenAxis?.hasBreaks && i.ordinal?.slope || e.pointRange || i.closestPointRange || i.tickInterval || 1), i.len),
          d = h * e.groupPadding,
          c = (h - 2 * d) / (l || 1),
          p = Math.min(e.maxPointWidth || i.len, y(e.pointWidth, c * (1 - 2 * e.pointPadding))),
          u = (t.columnIndex || 0) + (r ? 1 : 0);
        return t.columnMetrics = {
          width: p,
          offset: (c - p) / 2 + (d + u * c - h / 2) * (r ? -1 : 1),
          paddedWidth: c,
          columnCount: l
        }, t.columnMetrics;
      }
      crispCol(t, e, i, s) {
        let o = this.borderWidth,
          r = this.chart.inverted;
        return s = c(e + s, o, r) - (e = c(e, o, r)), this.options.crisp && (i = c(t + i, o) - (t = c(t, o))), {
          x: t,
          y: e,
          width: i,
          height: s
        };
      }
      adjustForMissingColumns(t, e, i, s) {
        if (!i.isNull && s.columnCount > 1) {
          let o = this.xAxis.series.filter(t => t.visible).map(t => t.index),
            r = 0,
            a = 0;
          b(this.xAxis.stacking?.stacks, t => {
            if ("number" == typeof i.x) {
              let e = t[i.x.toString()];
              if (e && f(e.points[this.index])) {
                let t = Object.keys(e.points).filter(t => !t.match(",") && e.points[t] && e.points[t].length > 1).map(parseFloat).filter(t => -1 !== o.indexOf(t)).sort((t, e) => e - t);
                r = t.indexOf(this.index), a = t.length;
              }
            }
          }), r = this.xAxis.reversed ? a - 1 - r : r;
          let n = (a - 1) * s.paddedWidth + e;
          t = (i.plotX || 0) + n / 2 - e - r * s.paddedWidth;
        }
        return t;
      }
      translate() {
        let t = this,
          e = t.chart,
          i = t.options,
          s = t.dense = t.closestPointRange * t.xAxis.transA < 2,
          r = t.borderWidth = y(i.borderWidth, s ? 0 : 1),
          a = t.xAxis,
          n = t.yAxis,
          l = i.threshold,
          h = y(i.minPointLength, 5),
          c = t.getColumnMetrics(),
          u = c.width,
          f = t.pointXOffset = c.offset,
          x = t.dataMin,
          b = t.dataMax,
          v = t.translatedThreshold = n.getThreshold(l),
          M = t.barW = Math.max(u, 1 + 2 * r);
        i.pointPadding && (M = Math.ceil(M)), o.prototype.translate.apply(t), t.points.forEach(function (s) {
          let o = y(s.yBottom, v),
            r = 999 + Math.abs(o),
            g = s.plotX || 0,
            C = d(s.plotY, -r, n.len + r),
            S,
            w = Math.min(C, o),
            k = Math.max(C, o) - w,
            A = u,
            T = g + f,
            P = M;
          h && Math.abs(k) < h && (k = h, S = !n.reversed && !s.negative || n.reversed && s.negative, m(l) && m(b) && s.y === l && b <= l && (n.min || 0) < l && (x !== b || (n.max || 0) <= l) && (S = !S, s.negative = !s.negative), w = Math.abs(w - v) > h ? o - h : v - (S ? h : 0)), p(s.options.pointWidth) && (T -= Math.round(((A = P = Math.ceil(s.options.pointWidth)) - u) / 2)), i.centerInCategory && !i.stacking && (T = t.adjustForMissingColumns(T, A, s, c)), s.barX = T, s.pointWidth = A, s.tooltipPos = e.inverted ? [d(n.len + n.pos - e.plotLeft - C, n.pos - e.plotLeft, n.len + n.pos - e.plotLeft), a.len + a.pos - e.plotTop - T - P / 2, k] : [a.left - e.plotLeft + T + P / 2, d(C + n.pos - e.plotTop, n.pos - e.plotTop, n.len + n.pos - e.plotTop), k], s.shapeType = t.pointClass.prototype.shapeType || "roundedRect", s.shapeArgs = t.crispCol(T, s.isNull ? v : w, P, s.isNull ? 0 : k);
        }), g(this, "afterColumnTranslate");
      }
      drawGraph() {
        this.group[this.dense ? "addClass" : "removeClass"]("highcharts-dense-data");
      }
      pointAttribs(t, e) {
        let i = this.options,
          s = this.pointAttrToOptions || {},
          o = s.stroke || "borderColor",
          r = s["stroke-width"] || "borderWidth",
          a,
          n,
          h,
          d = t && t.color || this.color,
          c = t && t[o] || i[o] || d,
          p = t && t.options.dashStyle || i.dashStyle,
          u = t && t[r] || i[r] || this[r] || 0,
          g = y(t && t.opacity, i.opacity, 1);
        t && this.zones.length && (n = t.getZone(), d = t.options.color || n && (n.color || t.nonZonedColor) || this.color, n && (c = n.borderColor || c, p = n.dashStyle || p, u = n.borderWidth || u)), e && t && (h = (a = x(i.states[e], t.options.states && t.options.states[e] || {})).brightness, d = a.color || void 0 !== h && l(d).brighten(a.brightness).get() || d, c = a[o] || c, u = a[r] || u, p = a.dashStyle || p, g = y(a.opacity, g));
        let f = {
          fill: d,
          stroke: c,
          "stroke-width": u,
          opacity: g
        };
        return p && (f.dashstyle = p), f;
      }
      drawPoints(t = this.points) {
        let e;
        let i = this,
          s = this.chart,
          o = i.options,
          r = s.renderer,
          a = o.animationLimit || 250;
        t.forEach(function (t) {
          let n = t.plotY,
            l = t.graphic,
            h = !!l,
            d = l && s.pointCount < a ? "animate" : "attr";
          m(n) && null !== t.y ? (e = t.shapeArgs, l && t.hasNewShapeType() && (l = l.destroy()), i.enabledDataSorting && (t.startXPos = i.xAxis.reversed ? -(e && e.width || 0) : i.xAxis.width), !l && (t.graphic = l = r[t.shapeType](e).add(t.group || i.group), l && i.enabledDataSorting && s.hasRendered && s.pointCount < a && (l.attr({
            x: t.startXPos
          }), h = !0, d = "animate")), l && h && l[d](x(e)), s.styledMode || l[d](i.pointAttribs(t, t.selected && "select")).shadow(!1 !== t.allowShadow && o.shadow), l && (l.addClass(t.getClassName(), !0), l.attr({
            visibility: t.visible ? "inherit" : "hidden"
          }))) : l && (t.graphic = l.destroy());
        });
      }
      drawTracker(t = this.points) {
        let e;
        let i = this,
          s = i.chart,
          o = s.pointer,
          r = function (t) {
            let e = o?.getPointFromEvent(t);
            o && e && i.options.enableMouseTracking && (o.isDirectTouch = !0, e.onMouseOver(t));
          };
        t.forEach(function (t) {
          e = f(t.dataLabels) ? t.dataLabels : t.dataLabel ? [t.dataLabel] : [], t.graphic && (t.graphic.element.point = t), e.forEach(function (e) {
            (e.div || e.element).point = t;
          });
        }), i._hasTracking || (i.trackerGroups.forEach(function (t) {
          i[t] && (i[t].addClass("highcharts-tracker").on("mouseover", r).on("mouseout", function (t) {
            o?.onTrackerMouseOut(t);
          }).on("touchstart", r), !s.styledMode && i.options.cursor && i[t].css({
            cursor: i.options.cursor
          }));
        }), i._hasTracking = !0), g(this, "afterDrawTracker");
      }
      remove() {
        let t = this,
          e = t.chart;
        e.hasRendered && e.series.forEach(function (e) {
          e.type === t.type && (e.isDirty = !0);
        }), o.prototype.remove.apply(t, arguments);
      }
    }
    return v.defaultOptions = x(o.defaultOptions, i), u(v.prototype, {
      directTouch: !0,
      getSymbol: h,
      negStacks: !0,
      trackerGroups: ["group", "dataLabelsGroup"]
    }), r.registerSeriesType("column", v), v;
  }), i(e, "Core/Series/DataLabel.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Templating.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s;
    let {
        getDeferredAnimation: o
      } = t,
      {
        format: r
      } = e,
      {
        defined: a,
        extend: n,
        fireEvent: l,
        isArray: h,
        isString: d,
        merge: c,
        objectEach: p,
        pick: u,
        pInt: g,
        splat: f
      } = i;
    return function (t) {
      function e() {
        return v(this).some(t => t?.enabled);
      }
      function i(t, e, i, s, o) {
        let {
            chart: r,
            enabledDataSorting: l
          } = this,
          h = this.isCartesian && r.inverted,
          d = t.plotX,
          p = t.plotY,
          g = i.rotation || 0,
          f = a(d) && a(p) && r.isInsidePlot(d, Math.round(p), {
            inverted: h,
            paneCoordinates: !0,
            series: this
          }),
          m = 0 === g && "justify" === u(i.overflow, l ? "none" : "justify"),
          x = this.visible && !1 !== t.visible && a(d) && (t.series.forceDL || l && !m || f || u(i.inside, !!this.options.stacking) && s && r.isInsidePlot(d, h ? s.x + 1 : s.y + s.height - 1, {
            inverted: h,
            paneCoordinates: !0,
            series: this
          })),
          y = t.pos();
        if (x && y) {
          var b;
          let a = e.getBBox(),
            d = e.getBBox(void 0, 0),
            p = {
              right: 1,
              center: .5
            }[i.align || 0] || 0,
            v = {
              bottom: 1,
              middle: .5
            }[i.verticalAlign || 0] || 0;
          if (s = n({
            x: y[0],
            y: Math.round(y[1]),
            width: 0,
            height: 0
          }, s || {}), "plotEdges" === i.alignTo && this.isCartesian && (s[h ? "x" : "y"] = 0, s[h ? "width" : "height"] = this.yAxis?.len || 0), n(i, {
            width: a.width,
            height: a.height
          }), b = s, l && this.xAxis && !m && this.setDataLabelStartPos(t, e, o, f, b), e.align(c(i, {
            width: d.width,
            height: d.height
          }), !1, s, !1), e.alignAttr.x += p * (d.width - a.width), e.alignAttr.y += v * (d.height - a.height), e[e.placed ? "animate" : "attr"]({
            x: e.alignAttr.x + (a.width - d.width) / 2,
            y: e.alignAttr.y + (a.height - d.height) / 2,
            rotationOriginX: (e.width || 0) / 2,
            rotationOriginY: (e.height || 0) / 2
          }), m && s.height >= 0) this.justifyDataLabel(e, i, e.alignAttr, a, s, o);else if (u(i.crop, !0)) {
            let {
              x: t,
              y: i
            } = e.alignAttr;
            x = r.isInsidePlot(t, i, {
              paneCoordinates: !0,
              series: this
            }) && r.isInsidePlot(t + a.width - 1, i + a.height - 1, {
              paneCoordinates: !0,
              series: this
            });
          }
          i.shape && !g && e[o ? "attr" : "animate"]({
            anchorX: y[0],
            anchorY: y[1]
          });
        }
        o && l && (e.placed = !1), x || l && !m ? (e.show(), e.placed = !0) : (e.hide(), e.placed = !1);
      }
      function s() {
        return this.plotGroup("dataLabelsGroup", "data-labels", this.hasRendered ? "inherit" : "hidden", this.options.dataLabels.zIndex || 6);
      }
      function m(t) {
        let e = this.hasRendered || 0,
          i = this.initDataLabelsGroup().attr({
            opacity: +e
          });
        return !e && i && (this.visible && i.show(), this.options.animation ? i.animate({
          opacity: 1
        }, t) : i.attr({
          opacity: 1
        })), i;
      }
      function x(t) {
        let e;
        t = t || this.points;
        let i = this,
          s = i.chart,
          n = i.options,
          h = s.renderer,
          {
            backgroundColor: c,
            plotBackgroundColor: m
          } = s.options.chart,
          x = h.getContrast(d(m) && m || d(c) && c || "#000000"),
          y = v(i),
          {
            animation: M,
            defer: C
          } = y[0],
          S = C ? o(s, M, i) : {
            defer: 0,
            duration: 0
          };
        l(this, "drawDataLabels"), i.hasDataLabels?.() && (e = this.initDataLabels(S), t.forEach(t => {
          let o = t.dataLabels || [];
          f(b(y, t.dlOptions || t.options?.dataLabels)).forEach((c, f) => {
            let m = c.enabled && (t.visible || t.dataLabelOnHidden) && (!t.isNull || t.dataLabelOnNull) && function (t, e) {
                let i = e.filter;
                if (i) {
                  let e = i.operator,
                    s = t[i.property],
                    o = i.value;
                  return ">" === e && s > o || "<" === e && s < o || ">=" === e && s >= o || "<=" === e && s <= o || "==" === e && s == o || "===" === e && s === o || "!=" === e && s != o || "!==" === e && s !== o;
                }
                return !0;
              }(t, c),
              {
                backgroundColor: y,
                borderColor: b,
                distance: v,
                style: M = {}
              } = c,
              C,
              S,
              w,
              k,
              A = {},
              T = o[f],
              P = !T,
              L;
            m && (S = u(c[t.formatPrefix + "Format"], c.format), C = t.getLabelConfig(), w = a(S) ? r(S, C, s) : (c[t.formatPrefix + "Formatter"] || c.formatter).call(C, c), k = c.rotation, !s.styledMode && (M.color = u(c.color, M.color, d(i.color) ? i.color : void 0, "#000000"), "contrast" === M.color ? ("none" !== y && (L = y), t.contrastColor = h.getContrast("auto" !== L && L || t.color || i.color), M.color = L || !a(v) && c.inside || 0 > g(v || 0) || n.stacking ? t.contrastColor : x) : delete t.contrastColor, n.cursor && (M.cursor = n.cursor)), A = {
              r: c.borderRadius || 0,
              rotation: k,
              padding: c.padding,
              zIndex: 1
            }, s.styledMode || (A.fill = "auto" === y ? t.color : y, A.stroke = "auto" === b ? t.color : b, A["stroke-width"] = c.borderWidth), p(A, (t, e) => {
              void 0 === t && delete A[e];
            })), !T || m && a(w) && !!T.div == !!c.useHTML && (T.rotation && c.rotation || T.rotation === c.rotation) || (T = void 0, P = !0), m && a(w) && (T ? A.text = w : (T = h.label(w, 0, 0, c.shape, void 0, void 0, c.useHTML, void 0, "data-label")).addClass(" highcharts-data-label-color-" + t.colorIndex + " " + (c.className || "") + (c.useHTML ? " highcharts-tracker" : "")), T && (T.options = c, T.attr(A), s.styledMode ? M.width && T.css({
              width: M.width,
              textOverflow: M.textOverflow
            }) : T.css(M).shadow(c.shadow), l(T, "beforeAddingDataLabel", {
              labelOptions: c,
              point: t
            }), T.added || T.add(e), i.alignDataLabel(t, T, c, void 0, P), T.isActive = !0, o[f] && o[f] !== T && o[f].destroy(), o[f] = T));
          });
          let c = o.length;
          for (; c--;) o[c] && o[c].isActive ? o[c].isActive = !1 : (o[c]?.destroy(), o.splice(c, 1));
          t.dataLabel = o[0], t.dataLabels = o;
        })), l(this, "afterDrawDataLabels");
      }
      function y(t, e, i, s, o, r) {
        let a = this.chart,
          n = e.align,
          l = e.verticalAlign,
          h = t.box ? 0 : t.padding || 0,
          d = a.inverted ? this.yAxis : this.xAxis,
          c = d ? d.left - a.plotLeft : 0,
          p = a.inverted ? this.xAxis : this.yAxis,
          u = p ? p.top - a.plotTop : 0,
          {
            x: g = 0,
            y: f = 0
          } = e,
          m,
          x;
        return (m = (i.x || 0) + h + c) < 0 && ("right" === n && g >= 0 ? (e.align = "left", e.inside = !0) : g -= m, x = !0), (m = (i.x || 0) + s.width - h + c) > a.plotWidth && ("left" === n && g <= 0 ? (e.align = "right", e.inside = !0) : g += a.plotWidth - m, x = !0), (m = i.y + h + u) < 0 && ("bottom" === l && f >= 0 ? (e.verticalAlign = "top", e.inside = !0) : f -= m, x = !0), (m = (i.y || 0) + s.height - h + u) > a.plotHeight && ("top" === l && f <= 0 ? (e.verticalAlign = "bottom", e.inside = !0) : f += a.plotHeight - m, x = !0), x && (e.x = g, e.y = f, t.placed = !r, t.align(e, void 0, o)), x;
      }
      function b(t, e) {
        let i = [],
          s;
        if (h(t) && !h(e)) i = t.map(function (t) {
          return c(t, e);
        });else if (h(e) && !h(t)) i = e.map(function (e) {
          return c(t, e);
        });else if (h(t) || h(e)) {
          if (h(t) && h(e)) for (s = Math.max(t.length, e.length); s--;) i[s] = c(t[s], e[s]);
        } else i = c(t, e);
        return i;
      }
      function v(t) {
        let e = t.chart.options.plotOptions;
        return f(b(b(e?.series?.dataLabels, e?.[t.type]?.dataLabels), t.options.dataLabels));
      }
      function M(t, e, i, s, o) {
        let r = this.chart,
          a = r.inverted,
          n = this.xAxis,
          l = n.reversed,
          h = ((a ? e.height : e.width) || 0) / 2,
          d = t.pointWidth,
          c = d ? d / 2 : 0;
        e.startXPos = a ? o.x : l ? -h - c : n.width - h + c, e.startYPos = a ? l ? this.yAxis.height - h + c : -h - c : o.y, s ? "hidden" === e.visibility && (e.show(), e.attr({
          opacity: 0
        }).animate({
          opacity: 1
        })) : e.attr({
          opacity: 1
        }).animate({
          opacity: 0
        }, void 0, e.hide), r.hasRendered && (i && e.attr({
          x: e.startXPos,
          y: e.startYPos
        }), e.placed = !0);
      }
      t.compose = function (t) {
        let o = t.prototype;
        o.initDataLabels || (o.initDataLabels = m, o.initDataLabelsGroup = s, o.alignDataLabel = i, o.drawDataLabels = x, o.justifyDataLabel = y, o.setDataLabelStartPos = M, o.hasDataLabels = e);
      };
    }(s || (s = {})), s;
  }), i(e, "Series/Column/ColumnDataLabel.js", [e["Core/Series/DataLabel.js"], e["Core/Globals.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    var o;
    let {
        composed: r
      } = e,
      {
        series: a
      } = i,
      {
        merge: n,
        pick: l,
        pushUnique: h
      } = s;
    return function (e) {
      function i(t, e, i, s, o) {
        let r = this.chart.inverted,
          h = t.series,
          d = (h.xAxis ? h.xAxis.len : this.chart.plotSizeX) || 0,
          c = (h.yAxis ? h.yAxis.len : this.chart.plotSizeY) || 0,
          p = t.dlBox || t.shapeArgs,
          u = l(t.below, t.plotY > l(this.translatedThreshold, c)),
          g = l(i.inside, !!this.options.stacking);
        if (p) {
          if (s = n(p), !("allow" === i.overflow && !1 === i.crop)) {
            s.y < 0 && (s.height += s.y, s.y = 0);
            let t = s.y + s.height - c;
            t > 0 && t < s.height - 1 && (s.height -= t);
          }
          r && (s = {
            x: c - s.y - s.height,
            y: d - s.x - s.width,
            width: s.height,
            height: s.width
          }), g || (r ? (s.x += u ? 0 : s.width, s.width = 0) : (s.y += u ? s.height : 0, s.height = 0));
        }
        i.align = l(i.align, !r || g ? "center" : u ? "right" : "left"), i.verticalAlign = l(i.verticalAlign, r || g ? "middle" : u ? "top" : "bottom"), a.prototype.alignDataLabel.call(this, t, e, i, s, o), i.inside && t.contrastColor && e.css({
          color: t.contrastColor
        });
      }
      e.compose = function (e) {
        t.compose(a), h(r, "ColumnDataLabel") && (e.prototype.alignDataLabel = i);
      };
    }(o || (o = {})), o;
  }), i(e, "Series/Bar/BarSeries.js", [e["Series/Column/ColumnSeries.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
      extend: s,
      merge: o
    } = i;
    class r extends t {}
    return r.defaultOptions = o(t.defaultOptions, {}), s(r.prototype, {
      inverted: !0
    }), e.registerSeriesType("bar", r), r;
  }), i(e, "Series/Scatter/ScatterSeriesDefaults.js", [], function () {
    return {
      lineWidth: 0,
      findNearestPointBy: "xy",
      jitter: {
        x: 0,
        y: 0
      },
      marker: {
        enabled: !0
      },
      tooltip: {
        headerFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 0.8em"> {series.name}</span><br/>',
        pointFormat: "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
      }
    };
  }), i(e, "Series/Scatter/ScatterSeries.js", [e["Series/Scatter/ScatterSeriesDefaults.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        column: s,
        line: o
      } = e.seriesTypes,
      {
        addEvent: r,
        extend: a,
        merge: n
      } = i;
    class l extends o {
      applyJitter() {
        let t = this,
          e = this.options.jitter,
          i = this.points.length;
        e && this.points.forEach(function (s, o) {
          ["x", "y"].forEach(function (r, a) {
            if (e[r] && !s.isNull) {
              let n = `plot${r.toUpperCase()}`,
                l = t[`${r}Axis`],
                h = e[r] * l.transA;
              if (l && !l.logarithmic) {
                let t = Math.max(0, (s[n] || 0) - h),
                  e = Math.min(l.len, (s[n] || 0) + h);
                s[n] = t + (e - t) * function (t) {
                  let e = 1e4 * Math.sin(t);
                  return e - Math.floor(e);
                }(o + a * i), "x" === r && (s.clientX = s.plotX);
              }
            }
          });
        });
      }
      drawGraph() {
        this.options.lineWidth ? super.drawGraph() : this.graph && (this.graph = this.graph.destroy());
      }
    }
    return l.defaultOptions = n(o.defaultOptions, t), a(l.prototype, {
      drawTracker: s.prototype.drawTracker,
      sorted: !1,
      requireSorting: !1,
      noSharedTooltip: !0,
      trackerGroups: ["group", "markerGroup", "dataLabelsGroup"]
    }), r(l, "afterTranslate", function () {
      this.applyJitter();
    }), e.registerSeriesType("scatter", l), l;
  }), i(e, "Series/CenteredUtilities.js", [e["Core/Globals.js"], e["Core/Series/Series.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s, o;
    let {
        deg2rad: r
      } = t,
      {
        fireEvent: a,
        isNumber: n,
        pick: l,
        relativeLength: h
      } = i;
    return (o = s || (s = {})).getCenter = function () {
      let t = this.options,
        i = this.chart,
        s = 2 * (t.slicedOffset || 0),
        o = i.plotWidth - 2 * s,
        r = i.plotHeight - 2 * s,
        d = t.center,
        c = Math.min(o, r),
        p = t.thickness,
        u,
        g = t.size,
        f = t.innerSize || 0,
        m,
        x;
      "string" == typeof g && (g = parseFloat(g)), "string" == typeof f && (f = parseFloat(f));
      let y = [l(d[0], "50%"), l(d[1], "50%"), l(g && g < 0 ? void 0 : t.size, "100%"), l(f && f < 0 ? void 0 : t.innerSize || 0, "0%")];
      for (!i.angular || this instanceof e || (y[3] = 0), m = 0; m < 4; ++m) x = y[m], u = m < 2 || 2 === m && /%$/.test(x), y[m] = h(x, [o, r, c, y[2]][m]) + (u ? s : 0);
      return y[3] > y[2] && (y[3] = y[2]), n(p) && 2 * p < y[2] && p > 0 && (y[3] = y[2] - 2 * p), a(this, "afterGetCenter", {
        positions: y
      }), y;
    }, o.getStartAndEndRadians = function (t, e) {
      let i = n(t) ? t : 0,
        s = n(e) && e > i && e - i < 360 ? e : i + 360;
      return {
        start: r * (i + -90),
        end: r * (s + -90)
      };
    }, s;
  }), i(e, "Series/Pie/PiePoint.js", [e["Core/Animation/AnimationUtilities.js"], e["Core/Series/Point.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        setAnimation: s
      } = t,
      {
        addEvent: o,
        defined: r,
        extend: a,
        isNumber: n,
        pick: l,
        relativeLength: h
      } = i;
    class d extends e {
      getConnectorPath(t) {
        let e = t.dataLabelPosition,
          i = t.options || {},
          s = i.connectorShape,
          o = this.connectorShapes[s] || s;
        return e && o.call(this, {
          ...e.computed,
          alignment: e.alignment
        }, e.connectorPosition, i) || [];
      }
      getTranslate() {
        return this.sliced && this.slicedTranslation || {
          translateX: 0,
          translateY: 0
        };
      }
      haloPath(t) {
        let e = this.shapeArgs;
        return this.sliced || !this.visible ? [] : this.series.chart.renderer.symbols.arc(e.x, e.y, e.r + t, e.r + t, {
          innerR: e.r - 1,
          start: e.start,
          end: e.end,
          borderRadius: e.borderRadius
        });
      }
      constructor(t, e, i) {
        super(t, e, i), this.half = 0, this.name ?? (this.name = "Slice");
        let s = t => {
          this.slice("select" === t.type);
        };
        o(this, "select", s), o(this, "unselect", s);
      }
      isValid() {
        return n(this.y) && this.y >= 0;
      }
      setVisible(t, e = !0) {
        t !== this.visible && this.update({
          visible: t ?? !this.visible
        }, e, void 0, !1);
      }
      slice(t, e, i) {
        let o = this.series;
        s(i, o.chart), e = l(e, !0), this.sliced = this.options.sliced = t = r(t) ? t : !this.sliced, o.options.data[o.data.indexOf(this)] = this.options, this.graphic && this.graphic.animate(this.getTranslate());
      }
    }
    return a(d.prototype, {
      connectorShapes: {
        fixedOffset: function (t, e, i) {
          let s = e.breakAt,
            o = e.touchingSliceAt,
            r = i.softConnector ? ["C", t.x + ("left" === t.alignment ? -5 : 5), t.y, 2 * s.x - o.x, 2 * s.y - o.y, s.x, s.y] : ["L", s.x, s.y];
          return [["M", t.x, t.y], r, ["L", o.x, o.y]];
        },
        straight: function (t, e) {
          let i = e.touchingSliceAt;
          return [["M", t.x, t.y], ["L", i.x, i.y]];
        },
        crookedLine: function (t, e, i) {
          let {
              breakAt: s,
              touchingSliceAt: o
            } = e,
            {
              series: r
            } = this,
            [a, n, l] = r.center,
            d = l / 2,
            {
              plotLeft: c,
              plotWidth: p
            } = r.chart,
            u = "left" === t.alignment,
            {
              x: g,
              y: f
            } = t,
            m = s.x;
          if (i.crookDistance) {
            let t = h(i.crookDistance, 1);
            m = u ? a + d + (p + c - a - d) * (1 - t) : c + (a - d) * t;
          } else m = a + (n - f) * Math.tan((this.angle || 0) - Math.PI / 2);
          let x = [["M", g, f]];
          return (u ? m <= g && m >= s.x : m >= g && m <= s.x) && x.push(["L", m, f]), x.push(["L", s.x, s.y], ["L", o.x, o.y]), x;
        }
      }
    }), d;
  }), i(e, "Series/Pie/PieSeriesDefaults.js", [], function () {
    return {
      borderRadius: 3,
      center: [null, null],
      clip: !1,
      colorByPoint: !0,
      dataLabels: {
        connectorPadding: 5,
        connectorShape: "crookedLine",
        crookDistance: void 0,
        distance: 30,
        enabled: !0,
        formatter: function () {
          return this.point.isNull ? void 0 : this.point.name;
        },
        softConnector: !0,
        x: 0
      },
      fillColor: void 0,
      ignoreHiddenPoint: !0,
      inactiveOtherPoints: !0,
      legendType: "point",
      marker: null,
      size: null,
      showInLegend: !1,
      slicedOffset: 10,
      stickyTracking: !1,
      tooltip: {
        followPointer: !0
      },
      borderColor: "#ffffff",
      borderWidth: 1,
      lineWidth: void 0,
      states: {
        hover: {
          brightness: .1
        }
      }
    };
  }), i(e, "Series/Pie/PieSeries.js", [e["Series/CenteredUtilities.js"], e["Series/Column/ColumnSeries.js"], e["Core/Globals.js"], e["Series/Pie/PiePoint.js"], e["Series/Pie/PieSeriesDefaults.js"], e["Core/Series/Series.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Renderer/SVG/Symbols.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n, l) {
    let {
        getStartAndEndRadians: h
      } = t,
      {
        noop: d
      } = i,
      {
        clamp: c,
        extend: p,
        fireEvent: u,
        merge: g,
        pick: f
      } = l;
    class m extends r {
      animate(t) {
        let e = this,
          i = e.points,
          s = e.startAngleRad;
        t || i.forEach(function (t) {
          let i = t.graphic,
            o = t.shapeArgs;
          i && o && (i.attr({
            r: f(t.startR, e.center && e.center[3] / 2),
            start: s,
            end: s
          }), i.animate({
            r: o.r,
            start: o.start,
            end: o.end
          }, e.options.animation));
        });
      }
      drawEmpty() {
        let t, e;
        let i = this.startAngleRad,
          s = this.endAngleRad,
          o = this.options;
        0 === this.total && this.center ? (t = this.center[0], e = this.center[1], this.graph || (this.graph = this.chart.renderer.arc(t, e, this.center[1] / 2, 0, i, s).addClass("highcharts-empty-series").add(this.group)), this.graph.attr({
          d: n.arc(t, e, this.center[2] / 2, 0, {
            start: i,
            end: s,
            innerR: this.center[3] / 2
          })
        }), this.chart.styledMode || this.graph.attr({
          "stroke-width": o.borderWidth,
          fill: o.fillColor || "none",
          stroke: o.color || "#cccccc"
        })) : this.graph && (this.graph = this.graph.destroy());
      }
      drawPoints() {
        let t = this.chart.renderer;
        this.points.forEach(function (e) {
          e.graphic && e.hasNewShapeType() && (e.graphic = e.graphic.destroy()), e.graphic || (e.graphic = t[e.shapeType](e.shapeArgs).add(e.series.group), e.delayedRendering = !0);
        });
      }
      generatePoints() {
        super.generatePoints(), this.updateTotals();
      }
      getX(t, e, i, s) {
        let o = this.center,
          r = this.radii ? this.radii[i.index] || 0 : o[2] / 2,
          a = s.dataLabelPosition,
          n = a?.distance || 0,
          l = Math.asin(c((t - o[1]) / (r + n), -1, 1));
        return o[0] + Math.cos(l) * (r + n) * (e ? -1 : 1) + (n > 0 ? (e ? -1 : 1) * (s.padding || 0) : 0);
      }
      hasData() {
        return !!this.processedXData.length;
      }
      redrawPoints() {
        let t, e, i, s;
        let o = this,
          r = o.chart;
        this.drawEmpty(), o.group && !r.styledMode && o.group.shadow(o.options.shadow), o.points.forEach(function (a) {
          let n = {};
          e = a.graphic, !a.isNull && e ? (s = a.shapeArgs, t = a.getTranslate(), r.styledMode || (i = o.pointAttribs(a, a.selected && "select")), a.delayedRendering ? (e.setRadialReference(o.center).attr(s).attr(t), r.styledMode || e.attr(i).attr({
            "stroke-linejoin": "round"
          }), a.delayedRendering = !1) : (e.setRadialReference(o.center), r.styledMode || g(!0, n, i), g(!0, n, s, t), e.animate(n)), e.attr({
            visibility: a.visible ? "inherit" : "hidden"
          }), e.addClass(a.getClassName(), !0)) : e && (a.graphic = e.destroy());
        });
      }
      sortByAngle(t, e) {
        t.sort(function (t, i) {
          return void 0 !== t.angle && (i.angle - t.angle) * e;
        });
      }
      translate(t) {
        u(this, "translate"), this.generatePoints();
        let e = this.options,
          i = e.slicedOffset,
          s = h(e.startAngle, e.endAngle),
          o = this.startAngleRad = s.start,
          r = (this.endAngleRad = s.end) - o,
          a = this.points,
          n = e.ignoreHiddenPoint,
          l = a.length,
          d,
          c,
          p,
          g,
          f,
          m,
          x,
          y = 0;
        for (t || (this.center = t = this.getCenter()), m = 0; m < l; m++) {
          x = a[m], d = o + y * r, x.isValid() && (!n || x.visible) && (y += x.percentage / 100), c = o + y * r;
          let e = {
            x: t[0],
            y: t[1],
            r: t[2] / 2,
            innerR: t[3] / 2,
            start: Math.round(1e3 * d) / 1e3,
            end: Math.round(1e3 * c) / 1e3
          };
          x.shapeType = "arc", x.shapeArgs = e, (p = (c + d) / 2) > 1.5 * Math.PI ? p -= 2 * Math.PI : p < -Math.PI / 2 && (p += 2 * Math.PI), x.slicedTranslation = {
            translateX: Math.round(Math.cos(p) * i),
            translateY: Math.round(Math.sin(p) * i)
          }, g = Math.cos(p) * t[2] / 2, f = Math.sin(p) * t[2] / 2, x.tooltipPos = [t[0] + .7 * g, t[1] + .7 * f], x.half = p < -Math.PI / 2 || p > Math.PI / 2 ? 1 : 0, x.angle = p;
        }
        u(this, "afterTranslate");
      }
      updateTotals() {
        let t = this.points,
          e = t.length,
          i = this.options.ignoreHiddenPoint,
          s,
          o,
          r = 0;
        for (s = 0; s < e; s++) (o = t[s]).isValid() && (!i || o.visible) && (r += o.y);
        for (s = 0, this.total = r; s < e; s++) (o = t[s]).percentage = r > 0 && (o.visible || !i) ? o.y / r * 100 : 0, o.total = r;
      }
    }
    return m.defaultOptions = g(r.defaultOptions, o), p(m.prototype, {
      axisTypes: [],
      directTouch: !0,
      drawGraph: void 0,
      drawTracker: e.prototype.drawTracker,
      getCenter: t.getCenter,
      getSymbol: d,
      invertible: !1,
      isCartesian: !1,
      noSharedTooltip: !0,
      pointAttribs: e.prototype.pointAttribs,
      pointClass: s,
      requireSorting: !1,
      searchPoint: d,
      trackerGroups: ["group", "dataLabelsGroup"]
    }), a.registerSeriesType("pie", m), m;
  }), i(e, "Series/Pie/PieDataLabel.js", [e["Core/Series/DataLabel.js"], e["Core/Globals.js"], e["Core/Renderer/RendererUtilities.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    var r;
    let {
        composed: a,
        noop: n
      } = e,
      {
        distribute: l
      } = i,
      {
        series: h
      } = s,
      {
        arrayMax: d,
        clamp: c,
        defined: p,
        pick: u,
        pushUnique: g,
        relativeLength: f
      } = o;
    return function (e) {
      let i = {
        radialDistributionY: function (t, e) {
          return (e.dataLabelPosition?.top || 0) + t.distributeBox.pos;
        },
        radialDistributionX: function (t, e, i, s, o) {
          let r = o.dataLabelPosition;
          return t.getX(i < (r?.top || 0) + 2 || i > (r?.bottom || 0) - 2 ? s : i, e.half, e, o);
        },
        justify: function (t, e, i, s) {
          return s[0] + (t.half ? -1 : 1) * (i + (e.dataLabelPosition?.distance || 0));
        },
        alignToPlotEdges: function (t, e, i, s) {
          let o = t.getBBox().width;
          return e ? o + s : i - o - s;
        },
        alignToConnectors: function (t, e, i, s) {
          let o = 0,
            r;
          return t.forEach(function (t) {
            (r = t.dataLabel.getBBox().width) > o && (o = r);
          }), e ? o + s : i - o - s;
        }
      };
      function s(t, e) {
        let {
            center: i,
            options: s
          } = this,
          o = i[2] / 2,
          r = t.angle || 0,
          a = Math.cos(r),
          n = Math.sin(r),
          l = i[0] + a * o,
          h = i[1] + n * o,
          d = Math.min((s.slicedOffset || 0) + (s.borderWidth || 0), e / 5);
        return {
          natural: {
            x: l + a * e,
            y: h + n * e
          },
          computed: {},
          alignment: e < 0 ? "center" : t.half ? "right" : "left",
          connectorPosition: {
            breakAt: {
              x: l + a * d,
              y: h + n * d
            },
            touchingSliceAt: {
              x: l,
              y: h
            }
          },
          distance: e
        };
      }
      function o() {
        let t = this,
          e = t.points,
          i = t.chart,
          s = i.plotWidth,
          o = i.plotHeight,
          r = i.plotLeft,
          a = Math.round(i.chartWidth / 3),
          n = t.center,
          c = n[2] / 2,
          g = n[1],
          m = [[], []],
          x = [0, 0, 0, 0],
          y = t.dataLabelPositioners,
          b,
          v,
          M,
          C = 0;
        t.visible && t.hasDataLabels?.() && (e.forEach(t => {
          (t.dataLabels || []).forEach(t => {
            t.shortened && (t.attr({
              width: "auto"
            }).css({
              width: "auto",
              textOverflow: "clip"
            }), t.shortened = !1);
          });
        }), h.prototype.drawDataLabels.apply(t), e.forEach(t => {
          (t.dataLabels || []).forEach((e, i) => {
            let s = n[2] / 2,
              o = e.options,
              r = f(o?.distance || 0, s);
            0 === i && m[t.half].push(t), !p(o?.style?.width) && e.getBBox().width > a && (e.css({
              width: Math.round(.7 * a) + "px"
            }), e.shortened = !0), e.dataLabelPosition = this.getDataLabelPosition(t, r), C = Math.max(C, r);
          });
        }), m.forEach((e, a) => {
          let h = e.length,
            d = [],
            f,
            m,
            b = 0,
            S;
          h && (t.sortByAngle(e, a - .5), C > 0 && (f = Math.max(0, g - c - C), m = Math.min(g + c + C, i.plotHeight), e.forEach(t => {
            (t.dataLabels || []).forEach(e => {
              let s = e.dataLabelPosition;
              s && s.distance > 0 && (s.top = Math.max(0, g - c - s.distance), s.bottom = Math.min(g + c + s.distance, i.plotHeight), b = e.getBBox().height || 21, e.lineHeight = i.renderer.fontMetrics(e.text || e).h + 2 * e.padding, t.distributeBox = {
                target: (e.dataLabelPosition?.natural.y || 0) - s.top + e.lineHeight / 2,
                size: b,
                rank: t.y
              }, d.push(t.distributeBox));
            });
          }), l(d, S = m + b - f, S / 5)), e.forEach(i => {
            (i.dataLabels || []).forEach(l => {
              let h = l.options || {},
                g = i.distributeBox,
                f = l.dataLabelPosition,
                m = f?.natural.y || 0,
                b = h.connectorPadding || 0,
                C = l.lineHeight || 21,
                S = (C - l.getBBox().height) / 2,
                w = 0,
                k = m,
                A = "inherit";
              if (f) {
                if (d && p(g) && f.distance > 0 && (void 0 === g.pos ? A = "hidden" : (M = g.size, k = y.radialDistributionY(i, l))), h.justify) w = y.justify(i, l, c, n);else switch (h.alignTo) {
                  case "connectors":
                    w = y.alignToConnectors(e, a, s, r);
                    break;
                  case "plotEdges":
                    w = y.alignToPlotEdges(l, a, s, r);
                    break;
                  default:
                    w = y.radialDistributionX(t, i, k - S, m, l);
                }
                if (f.attribs = {
                  visibility: A,
                  align: f.alignment
                }, f.posAttribs = {
                  x: w + (h.x || 0) + ({
                    left: b,
                    right: -b
                  }[f.alignment] || 0),
                  y: k + (h.y || 0) - C / 2
                }, f.computed.x = w, f.computed.y = k - S, u(h.crop, !0)) {
                  let t;
                  w - (v = l.getBBox().width) < b && 1 === a ? (t = Math.round(v - w + b), x[3] = Math.max(t, x[3])) : w + v > s - b && 0 === a && (t = Math.round(w + v - s + b), x[1] = Math.max(t, x[1])), k - M / 2 < 0 ? x[0] = Math.max(Math.round(-k + M / 2), x[0]) : k + M / 2 > o && (x[2] = Math.max(Math.round(k + M / 2 - o), x[2])), f.sideOverflow = t;
                }
              }
            });
          }));
        }), (0 === d(x) || this.verifyDataLabelOverflow(x)) && (this.placeDataLabels(), this.points.forEach(e => {
          (e.dataLabels || []).forEach(s => {
            let {
                connectorColor: o,
                connectorWidth: r = 1
              } = s.options || {},
              a = s.dataLabelPosition;
            if (r) {
              let n;
              b = s.connector, a && a.distance > 0 ? (n = !b, b || (s.connector = b = i.renderer.path().addClass("highcharts-data-label-connector  highcharts-color-" + e.colorIndex + (e.className ? " " + e.className : "")).add(t.dataLabelsGroup)), i.styledMode || b.attr({
                "stroke-width": r,
                stroke: o || e.color || "#666666"
              }), b[n ? "attr" : "animate"]({
                d: e.getConnectorPath(s)
              }), b.attr({
                visibility: a.attribs?.visibility
              })) : b && (s.connector = b.destroy());
            }
          });
        })));
      }
      function r() {
        this.points.forEach(t => {
          (t.dataLabels || []).forEach(t => {
            let e = t.dataLabelPosition;
            e ? (e.sideOverflow && (t.css({
              width: Math.max(t.getBBox().width - e.sideOverflow, 0) + "px",
              textOverflow: (t.options?.style || {}).textOverflow || "ellipsis"
            }), t.shortened = !0), t.attr(e.attribs), t[t.moved ? "animate" : "attr"](e.posAttribs), t.moved = !0) : t && t.attr({
              y: -9999
            });
          }), delete t.distributeBox;
        }, this);
      }
      function m(t) {
        let e = this.center,
          i = this.options,
          s = i.center,
          o = i.minSize || 80,
          r = o,
          a = null !== i.size;
        return !a && (null !== s[0] ? r = Math.max(e[2] - Math.max(t[1], t[3]), o) : (r = Math.max(e[2] - t[1] - t[3], o), e[0] += (t[3] - t[1]) / 2), null !== s[1] ? r = c(r, o, e[2] - Math.max(t[0], t[2])) : (r = c(r, o, e[2] - t[0] - t[2]), e[1] += (t[0] - t[2]) / 2), r < e[2] ? (e[2] = r, e[3] = Math.min(i.thickness ? Math.max(0, r - 2 * i.thickness) : Math.max(0, f(i.innerSize || 0, r)), r), this.translate(e), this.drawDataLabels && this.drawDataLabels()) : a = !0), a;
      }
      e.compose = function (e) {
        if (t.compose(h), g(a, "PieDataLabel")) {
          let t = e.prototype;
          t.dataLabelPositioners = i, t.alignDataLabel = n, t.drawDataLabels = o, t.getDataLabelPosition = s, t.placeDataLabels = r, t.verifyDataLabelOverflow = m;
        }
      };
    }(r || (r = {})), r;
  }), i(e, "Core/Geometry/GeometryUtilities.js", [], function () {
    var t, e;
    return (e = t || (t = {})).getCenterOfPoints = function (t) {
      let e = t.reduce((t, e) => (t.x += e.x, t.y += e.y, t), {
        x: 0,
        y: 0
      });
      return {
        x: e.x / t.length,
        y: e.y / t.length
      };
    }, e.getDistanceBetweenPoints = function (t, e) {
      return Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2));
    }, e.getAngleBetweenPoints = function (t, e) {
      return Math.atan2(e.x - t.x, e.y - t.y);
    }, e.pointInPolygon = function ({
      x: t,
      y: e
    }, i) {
      let s = i.length,
        o,
        r,
        a = !1;
      for (o = 0, r = s - 1; o < s; r = o++) {
        let [s, n] = i[o],
          [l, h] = i[r];
        n > e != h > e && t < (l - s) * (e - n) / (h - n) + s && (a = !a);
      }
      return a;
    }, t;
  }), i(e, "Extensions/OverlappingDataLabels.js", [e["Core/Geometry/GeometryUtilities.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        pointInPolygon: i
      } = t,
      {
        addEvent: s,
        fireEvent: o,
        objectEach: r,
        pick: a
      } = e;
    function n(t) {
      let e = t.length,
        s = (t, e) => !(e.x >= t.x + t.width || e.x + e.width <= t.x || e.y >= t.y + t.height || e.y + e.height <= t.y),
        r = (t, e) => {
          for (let s of t) if (i({
            x: s[0],
            y: s[1]
          }, e)) return !0;
          return !1;
        },
        a,
        n,
        h,
        d,
        c,
        p = !1;
      for (let i = 0; i < e; i++) (a = t[i]) && (a.oldOpacity = a.opacity, a.newOpacity = 1, a.absoluteBox = function (t) {
        if (t && (!t.alignAttr || t.placed)) {
          let e = t.box ? 0 : t.padding || 0,
            i = t.alignAttr || {
              x: t.attr("x"),
              y: t.attr("y")
            },
            s = t.getBBox();
          return t.width = s.width, t.height = s.height, {
            x: i.x + (t.parentGroup?.translateX || 0) + e,
            y: i.y + (t.parentGroup?.translateY || 0) + e,
            width: (t.width || 0) - 2 * e,
            height: (t.height || 0) - 2 * e,
            polygon: s?.polygon
          };
        }
      }(a));
      t.sort((t, e) => (e.labelrank || 0) - (t.labelrank || 0));
      for (let i = 0; i < e; ++i) {
        d = (n = t[i]) && n.absoluteBox;
        let o = d?.polygon;
        for (let a = i + 1; a < e; ++a) {
          c = (h = t[a]) && h.absoluteBox;
          let e = !1;
          if (d && c && n !== h && 0 !== n.newOpacity && 0 !== h.newOpacity && "hidden" !== n.visibility && "hidden" !== h.visibility) {
            let t = c.polygon;
            if (o && t && o !== t ? r(o, t) && (e = !0) : s(d, c) && (e = !0), e) {
              let t = n.labelrank < h.labelrank ? n : h,
                e = t.text;
              t.newOpacity = 0, e?.element.querySelector("textPath") && e.hide();
            }
          }
        }
      }
      for (let e of t) l(e, this) && (p = !0);
      p && o(this, "afterHideAllOverlappingLabels");
    }
    function l(t, e) {
      let i,
        s,
        r = !1;
      return t && (s = t.newOpacity, t.oldOpacity !== s && (t.hasClass("highcharts-data-label") ? (t[s ? "removeClass" : "addClass"]("highcharts-data-label-hidden"), i = function () {
        e.styledMode || t.css({
          pointerEvents: s ? "auto" : "none"
        });
      }, r = !0, t[t.isOld ? "animate" : "attr"]({
        opacity: s
      }, void 0, i), o(e, "afterHideOverlappingLabel")) : t.attr({
        opacity: s
      })), t.isOld = !0), r;
    }
    function h() {
      let t = this,
        e = [];
      for (let i of t.labelCollectors || []) e = e.concat(i());
      for (let i of t.yAxis || []) i.stacking && i.options.stackLabels && !i.options.stackLabels.allowOverlap && r(i.stacking.stacks, t => {
        r(t, t => {
          t.label && e.push(t.label);
        });
      });
      for (let i of t.series || []) if (i.visible && i.hasDataLabels?.()) {
        let s = i => {
          for (let s of i) s.visible && (s.dataLabels || []).forEach(i => {
            let o = i.options || {};
            i.labelrank = a(o.labelrank, s.labelrank, s.shapeArgs?.height), o.allowOverlap ?? Number(o.distance) > 0 ? (i.oldOpacity = i.opacity, i.newOpacity = 1, l(i, t)) : e.push(i);
          });
        };
        s(i.nodes || []), s(i.points);
      }
      this.hideOverlappingLabels(e);
    }
    return {
      compose: function (t) {
        let e = t.prototype;
        e.hideOverlappingLabels || (e.hideOverlappingLabels = n, s(t, "render", h));
      }
    };
  }), i(e, "Extensions/BorderRadius.js", [e["Core/Defaults.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        defaultOptions: s
      } = t,
      {
        noop: o
      } = e,
      {
        addEvent: r,
        extend: a,
        isObject: n,
        merge: l,
        relativeLength: h
      } = i,
      d = {
        radius: 0,
        scope: "stack",
        where: void 0
      },
      c = o,
      p = o;
    function u(t, e, i, s, o = {}) {
      let r = c(t, e, i, s, o),
        {
          innerR: a = 0,
          r: n = i,
          start: l = 0,
          end: d = 0
        } = o;
      if (o.open || !o.borderRadius) return r;
      let p = d - l,
        g = Math.sin(p / 2),
        f = Math.max(Math.min(h(o.borderRadius || 0, n - a), (n - a) / 2, n * g / (1 + g)), 0),
        m = Math.min(f, p / Math.PI * 2 * a),
        x = r.length - 1;
      for (; x--;) !function (t, e, i) {
        let s, o, r;
        let a = t[e],
          n = t[e + 1];
        if ("Z" === n[0] && (n = t[0]), ("M" === a[0] || "L" === a[0]) && "A" === n[0] ? (s = a, o = n, r = !0) : "A" === a[0] && ("M" === n[0] || "L" === n[0]) && (s = n, o = a), s && o && o.params) {
          let a = o[1],
            n = o[5],
            l = o.params,
            {
              start: h,
              end: d,
              cx: c,
              cy: p
            } = l,
            u = n ? a - i : a + i,
            g = u ? Math.asin(i / u) : 0,
            f = n ? g : -g,
            m = Math.cos(g) * u;
          r ? (l.start = h + f, s[1] = c + m * Math.cos(h), s[2] = p + m * Math.sin(h), t.splice(e + 1, 0, ["A", i, i, 0, 0, 1, c + a * Math.cos(l.start), p + a * Math.sin(l.start)])) : (l.end = d - f, o[6] = c + a * Math.cos(l.end), o[7] = p + a * Math.sin(l.end), t.splice(e + 1, 0, ["A", i, i, 0, 0, 1, c + m * Math.cos(d), p + m * Math.sin(d)])), o[4] = Math.abs(l.end - l.start) < Math.PI ? 0 : 1;
        }
      }(r, x, x > 1 ? m : f);
      return r;
    }
    function g() {
      if (this.options.borderRadius && !(this.chart.is3d && this.chart.is3d())) {
        let {
            options: t,
            yAxis: e
          } = this,
          i = "percent" === t.stacking,
          o = s.plotOptions?.[this.type]?.borderRadius,
          r = f(t.borderRadius, n(o) ? o : {}),
          l = e.options.reversed;
        for (let s of this.points) {
          let {
            shapeArgs: o
          } = s;
          if ("roundedRect" === s.shapeType && o) {
            let {
                width: n = 0,
                height: d = 0,
                y: c = 0
              } = o,
              p = c,
              u = d;
            if ("stack" === r.scope && s.stackTotal) {
              let o = e.translate(i ? 100 : s.stackTotal, !1, !0, !1, !0),
                r = e.translate(t.threshold || 0, !1, !0, !1, !0),
                a = this.crispCol(0, Math.min(o, r), 0, Math.abs(o - r));
              p = a.y, u = a.height;
            }
            let g = (s.negative ? -1 : 1) * (l ? -1 : 1) == -1,
              f = r.where;
            !f && this.is("waterfall") && Math.abs((s.yBottom || 0) - (this.translatedThreshold || 0)) > this.borderWidth && (f = "all"), f || (f = "end");
            let m = Math.min(h(r.radius, n), n / 2, "all" === f ? d / 2 : 1 / 0) || 0;
            "end" === f && (g && (p -= m), u += m), a(o, {
              brBoxHeight: u,
              brBoxY: p,
              r: m
            });
          }
        }
      }
    }
    function f(t, e) {
      return n(t) || (t = {
        radius: t || 0
      }), l(d, e, t);
    }
    function m() {
      let t = f(this.options.borderRadius);
      for (let e of this.points) {
        let i = e.shapeArgs;
        i && (i.borderRadius = h(t.radius, (i.r || 0) - (i.innerR || 0)));
      }
    }
    function x(t, e, i, s, o = {}) {
      let r = p(t, e, i, s, o),
        {
          r: a = 0,
          brBoxHeight: n = s,
          brBoxY: l = e
        } = o,
        h = e - l,
        d = l + n - (e + s),
        c = h - a > -.1 ? 0 : a,
        u = d - a > -.1 ? 0 : a,
        g = Math.max(c && h, 0),
        f = Math.max(u && d, 0),
        m = [t + c, e],
        y = [t + i - c, e],
        b = [t + i, e + c],
        v = [t + i, e + s - u],
        M = [t + i - u, e + s],
        C = [t + u, e + s],
        S = [t, e + s - u],
        w = [t, e + c],
        k = (t, e) => Math.sqrt(Math.pow(t, 2) - Math.pow(e, 2));
      if (g) {
        let t = k(c, c - g);
        m[0] -= t, y[0] += t, b[1] = w[1] = e + c - g;
      }
      if (s < c - g) {
        let o = k(c, c - g - s);
        b[0] = v[0] = t + i - c + o, M[0] = Math.min(b[0], M[0]), C[0] = Math.max(v[0], C[0]), S[0] = w[0] = t + c - o, b[1] = w[1] = e + s;
      }
      if (f) {
        let t = k(u, u - f);
        M[0] += t, C[0] -= t, v[1] = S[1] = e + s - u + f;
      }
      if (s < u - f) {
        let o = k(u, u - f - s);
        b[0] = v[0] = t + i - u + o, y[0] = Math.min(b[0], y[0]), m[0] = Math.max(v[0], m[0]), S[0] = w[0] = t + u - o, v[1] = S[1] = e;
      }
      return r.length = 0, r.push(["M", ...m], ["L", ...y], ["A", c, c, 0, 0, 1, ...b], ["L", ...v], ["A", u, u, 0, 0, 1, ...M], ["L", ...C], ["A", u, u, 0, 0, 1, ...S], ["L", ...w], ["A", c, c, 0, 0, 1, ...m], ["Z"]), r;
    }
    return {
      compose: function (t, e, i) {
        let s = t.types.pie;
        if (!e.symbolCustomAttribs.includes("borderRadius")) {
          let o = i.prototype.symbols;
          r(t, "afterColumnTranslate", g, {
            order: 9
          }), r(s, "afterTranslate", m), e.symbolCustomAttribs.push("borderRadius", "brBoxHeight", "brBoxY"), c = o.arc, p = o.roundedRect, o.arc = u, o.roundedRect = x;
        }
      },
      optionsToObject: f
    };
  }), i(e, "Core/Responsive.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      diffObjects: i,
      extend: s,
      find: o,
      merge: r,
      pick: a,
      uniqueKey: n
    } = t;
    return function (t) {
      function e(t, e) {
        let i = t.condition;
        (i.callback || function () {
          return this.chartWidth <= a(i.maxWidth, Number.MAX_VALUE) && this.chartHeight <= a(i.maxHeight, Number.MAX_VALUE) && this.chartWidth >= a(i.minWidth, 0) && this.chartHeight >= a(i.minHeight, 0);
        }).call(this) && e.push(t._id);
      }
      function l(t, e) {
        let s = this.options.responsive,
          a = this.currentResponsive,
          l = [],
          h;
        !e && s && s.rules && s.rules.forEach(t => {
          void 0 === t._id && (t._id = n()), this.matchResponsiveRule(t, l);
        }, this);
        let d = r(...l.map(t => o((s || {}).rules || [], e => e._id === t)).map(t => t && t.chartOptions));
        d.isResponsiveOptions = !0, l = l.toString() || void 0;
        let c = a && a.ruleIds;
        l === c || (a && (this.currentResponsive = void 0, this.updatingResponsive = !0, this.update(a.undoOptions, t, !0), this.updatingResponsive = !1), l ? ((h = i(d, this.options, !0, this.collectionsWithUpdate)).isResponsiveOptions = !0, this.currentResponsive = {
          ruleIds: l,
          mergedOptions: d,
          undoOptions: h
        }, this.updatingResponsive || this.update(d, t, !0)) : this.currentResponsive = void 0);
      }
      t.compose = function (t) {
        let i = t.prototype;
        return i.matchResponsiveRule || s(i, {
          matchResponsiveRule: e,
          setResponsive: l
        }), t;
      };
    }(e || (e = {})), e;
  }), i(e, "masters/highcharts.src.js", [e["Core/Globals.js"], e["Core/Utilities.js"], e["Core/Defaults.js"], e["Core/Animation/Fx.js"], e["Core/Animation/AnimationUtilities.js"], e["Core/Renderer/HTML/AST.js"], e["Core/Templating.js"], e["Core/Renderer/RendererRegistry.js"], e["Core/Renderer/RendererUtilities.js"], e["Core/Renderer/SVG/SVGElement.js"], e["Core/Renderer/SVG/SVGRenderer.js"], e["Core/Renderer/HTML/HTMLElement.js"], e["Core/Axis/Axis.js"], e["Core/Axis/DateTimeAxis.js"], e["Core/Axis/LogarithmicAxis.js"], e["Core/Axis/PlotLineOrBand/PlotLineOrBand.js"], e["Core/Axis/Tick.js"], e["Core/Tooltip.js"], e["Core/Series/Point.js"], e["Core/Pointer.js"], e["Core/Legend/Legend.js"], e["Core/Legend/LegendSymbol.js"], e["Core/Chart/Chart.js"], e["Extensions/ScrollablePlotArea.js"], e["Core/Axis/Stacking/StackingAxis.js"], e["Core/Axis/Stacking/StackItem.js"], e["Core/Series/Series.js"], e["Core/Series/SeriesRegistry.js"], e["Series/Column/ColumnDataLabel.js"], e["Series/Pie/PieDataLabel.js"], e["Core/Series/DataLabel.js"], e["Extensions/OverlappingDataLabels.js"], e["Extensions/BorderRadius.js"], e["Core/Responsive.js"], e["Core/Color/Color.js"], e["Core/Time.js"]], function (t, e, i, s, o, r, a, n, l, h, d, c, p, u, g, f, m, x, y, b, v, M, C, S, w, k, A, T, P, L, j, O, D, E, I, B) {
    return t.AST = r, t.Axis = p, t.Chart = C, t.Color = I, t.DataLabel = j, t.Fx = s, t.HTMLElement = c, t.Legend = v, t.LegendSymbol = M, t.OverlappingDataLabels = t.OverlappingDataLabels || O, t.PlotLineOrBand = f, t.Point = y, t.Pointer = b, t.RendererRegistry = n, t.Series = A, t.SeriesRegistry = T, t.StackItem = k, t.SVGElement = h, t.SVGRenderer = d, t.Templating = a, t.Tick = m, t.Time = B, t.Tooltip = x, t.animate = o.animate, t.animObject = o.animObject, t.chart = C.chart, t.color = I.parse, t.dateFormat = a.dateFormat, t.defaultOptions = i.defaultOptions, t.distribute = l.distribute, t.format = a.format, t.getDeferredAnimation = o.getDeferredAnimation, t.getOptions = i.getOptions, t.numberFormat = a.numberFormat, t.seriesType = T.seriesType, t.setAnimation = o.setAnimation, t.setOptions = i.setOptions, t.stop = o.stop, t.time = i.defaultTime, t.timers = s.timers, D.compose(t.Series, t.SVGElement, t.SVGRenderer), P.compose(t.Series.types.column), j.compose(t.Series), u.compose(t.Axis), c.compose(t.SVGRenderer), v.compose(t.Chart), g.compose(t.Axis), O.compose(t.Chart), L.compose(t.Series.types.pie), f.compose(t.Chart, t.Axis), b.compose(t.Chart), E.compose(t.Chart), S.compose(t.Axis, t.Chart, t.Series), w.compose(t.Axis, t.Chart, t.Series), x.compose(t.Pointer), e.extend(t, e), t;
  }), i(e, "Core/Axis/Color/ColorAxisComposition.js", [e["Core/Color/Color.js"], e["Core/Utilities.js"]], function (t, e) {
    var i;
    let {
        parse: s
      } = t,
      {
        addEvent: o,
        extend: r,
        merge: a,
        pick: n,
        splat: l
      } = e;
    return function (t) {
      let e;
      function i() {
        let {
          userOptions: t
        } = this;
        this.colorAxis = [], t.colorAxis && (t.colorAxis = l(t.colorAxis), t.colorAxis.map(t => new e(this, t)));
      }
      function h(t) {
        let e = this.chart.colorAxis || [],
          i = e => {
            let i = t.allItems.indexOf(e);
            -1 !== i && (this.destroyItem(t.allItems[i]), t.allItems.splice(i, 1));
          },
          s = [],
          o,
          r;
        for (e.forEach(function (t) {
          (o = t.options) && o.showInLegend && (o.dataClasses && o.visible ? s = s.concat(t.getDataClassLegendSymbols()) : o.visible && s.push(t), t.series.forEach(function (t) {
            (!t.options.showInLegend || o.dataClasses) && ("point" === t.options.legendType ? t.points.forEach(function (t) {
              i(t);
            }) : i(t));
          }));
        }), r = s.length; r--;) t.allItems.unshift(s[r]);
      }
      function d(t) {
        t.visible && t.item.legendColor && t.item.legendItem.symbol.attr({
          fill: t.item.legendColor
        });
      }
      function c(t) {
        this.chart.colorAxis?.forEach(e => {
          e.update({}, t.redraw);
        });
      }
      function p() {
        (this.chart.colorAxis && this.chart.colorAxis.length || this.colorAttribs) && this.translateColors();
      }
      function u() {
        let t = this.axisTypes;
        t ? -1 === t.indexOf("colorAxis") && t.push("colorAxis") : this.axisTypes = ["colorAxis"];
      }
      function g(t) {
        let e = this,
          i = t ? "show" : "hide";
        e.visible = e.options.visible = !!t, ["graphic", "dataLabel"].forEach(function (t) {
          e[t] && e[t][i]();
        }), this.series.buildKDTree();
      }
      function f() {
        let t = this,
          e = this.getPointsCollection(),
          i = this.options.nullColor,
          s = this.colorAxis,
          o = this.colorKey;
        e.forEach(e => {
          let r = e.getNestedProperty(o),
            a = e.options.color || (e.isNull || null === e.value ? i : s && void 0 !== r ? s.toColor(r, e) : e.color || t.color);
          a && e.color !== a && (e.color = a, "point" === t.options.legendType && e.legendItem && e.legendItem.label && t.chart.legend.colorizeItem(e, e.visible));
        });
      }
      function m() {
        this.elem.attr("fill", s(this.start).tweenTo(s(this.end), this.pos), void 0, !0);
      }
      function x() {
        this.elem.attr("stroke", s(this.start).tweenTo(s(this.end), this.pos), void 0, !0);
      }
      t.compose = function (t, s, l, y, b) {
        let v = s.prototype,
          M = l.prototype,
          C = b.prototype;
        v.collectionsWithUpdate.includes("colorAxis") || (e = t, v.collectionsWithUpdate.push("colorAxis"), v.collectionsWithInit.colorAxis = [v.addColorAxis], o(s, "afterGetAxes", i), function (t) {
          let i = t.prototype.createAxis;
          t.prototype.createAxis = function (t, s) {
            if ("colorAxis" !== t) return i.apply(this, arguments);
            let o = new e(this, a(s.axis, {
              index: this[t].length,
              isX: !1
            }));
            return this.isDirtyLegend = !0, this.axes.forEach(t => {
              t.series = [];
            }), this.series.forEach(t => {
              t.bindAxes(), t.isDirtyData = !0;
            }), n(s.redraw, !0) && this.redraw(s.animation), o;
          };
        }(s), M.fillSetter = m, M.strokeSetter = x, o(y, "afterGetAllItems", h), o(y, "afterColorizeItem", d), o(y, "afterUpdate", c), r(C, {
          optionalAxis: "colorAxis",
          translateColors: f
        }), r(C.pointClass.prototype, {
          setVisible: g
        }), o(b, "afterTranslate", p, {
          order: 1
        }), o(b, "bindAxes", u));
      }, t.pointSetVisible = g;
    }(i || (i = {})), i;
  }), i(e, "Core/Axis/Color/ColorAxisDefaults.js", [], function () {
    return {
      lineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      gridLineColor: "#ffffff",
      gridLineWidth: 1,
      tickPixelInterval: 72,
      startOnTick: !0,
      endOnTick: !0,
      offset: 0,
      marker: {
        animation: {
          duration: 50
        },
        width: .01,
        color: "#999999"
      },
      labels: {
        distance: 8,
        overflow: "justify",
        rotation: 0
      },
      minColor: "#e6e9ff",
      maxColor: "#0022ff",
      tickLength: 5,
      showInLegend: !0
    };
  }), i(e, "Core/Axis/Color/ColorAxisLike.js", [e["Core/Color/Color.js"], e["Core/Utilities.js"]], function (t, e) {
    var i, s;
    let {
        parse: o
      } = t,
      {
        merge: r
      } = e;
    return (s = i || (i = {})).initDataClasses = function (t) {
      let e = this.chart,
        i = this.legendItem = this.legendItem || {},
        s = this.options,
        a = t.dataClasses || [],
        n,
        l,
        h = e.options.chart.colorCount,
        d = 0,
        c;
      this.dataClasses = l = [], i.labels = [];
      for (let t = 0, i = a.length; t < i; ++t) n = r(n = a[t]), l.push(n), (e.styledMode || !n.color) && ("category" === s.dataClassColor ? (e.styledMode || (h = (c = e.options.colors || []).length, n.color = c[d]), n.colorIndex = d, ++d === h && (d = 0)) : n.color = o(s.minColor).tweenTo(o(s.maxColor), i < 2 ? .5 : t / (i - 1)));
    }, s.initStops = function () {
      let t = this.options,
        e = this.stops = t.stops || [[0, t.minColor || ""], [1, t.maxColor || ""]];
      for (let t = 0, i = e.length; t < i; ++t) e[t].color = o(e[t][1]);
    }, s.normalizedValue = function (t) {
      let e = this.max || 0,
        i = this.min || 0;
      return this.logarithmic && (t = this.logarithmic.log2lin(t)), 1 - (e - t) / (e - i || 1);
    }, s.toColor = function (t, e) {
      let i, s, o, r, a, n;
      let l = this.dataClasses,
        h = this.stops;
      if (l) {
        for (n = l.length; n--;) if (s = (a = l[n]).from, o = a.to, (void 0 === s || t >= s) && (void 0 === o || t <= o)) {
          r = a.color, e && (e.dataClass = n, e.colorIndex = a.colorIndex);
          break;
        }
      } else {
        for (i = this.normalizedValue(t), n = h.length; n-- && !(i > h[n][0]););
        s = h[n] || h[n + 1], i = 1 - ((o = h[n + 1] || s)[0] - i) / (o[0] - s[0] || 1), r = s.color.tweenTo(o.color, i);
      }
      return r;
    }, i;
  }), i(e, "Core/Axis/Color/ColorAxis.js", [e["Core/Axis/Axis.js"], e["Core/Axis/Color/ColorAxisComposition.js"], e["Core/Axis/Color/ColorAxisDefaults.js"], e["Core/Axis/Color/ColorAxisLike.js"], e["Core/Defaults.js"], e["Core/Legend/LegendSymbol.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n) {
    let {
        defaultOptions: l
      } = o,
      {
        series: h
      } = a,
      {
        defined: d,
        extend: c,
        fireEvent: p,
        isArray: u,
        isNumber: g,
        merge: f,
        pick: m,
        relativeLength: x
      } = n;
    l.colorAxis = f(l.xAxis, i);
    class y extends t {
      static compose(t, i, s, o) {
        e.compose(y, t, i, s, o);
      }
      constructor(t, e) {
        super(t, e), this.coll = "colorAxis", this.visible = !0, this.init(t, e);
      }
      init(t, e) {
        let i = t.options.legend || {},
          s = e.layout ? "vertical" !== e.layout : "vertical" !== i.layout;
        this.side = e.side || s ? 2 : 1, this.reversed = e.reversed || !s, this.opposite = !s, super.init(t, e, "colorAxis"), this.userOptions = e, u(t.userOptions.colorAxis) && (t.userOptions.colorAxis[this.index] = e), e.dataClasses && this.initDataClasses(e), this.initStops(), this.horiz = s, this.zoomEnabled = !1;
      }
      hasData() {
        return !!(this.tickPositions || []).length;
      }
      setTickPositions() {
        if (!this.dataClasses) return super.setTickPositions();
      }
      setOptions(t) {
        let e = f(l.colorAxis, t, {
          showEmpty: !1,
          title: null,
          visible: this.chart.options.legend.enabled && !1 !== t.visible
        });
        super.setOptions(e), this.options.crosshair = this.options.marker;
      }
      setAxisSize() {
        let t = this.chart,
          e = this.legendItem?.symbol,
          {
            width: i,
            height: s
          } = this.getSize();
        e && (this.left = +e.attr("x"), this.top = +e.attr("y"), this.width = i = +e.attr("width"), this.height = s = +e.attr("height"), this.right = t.chartWidth - this.left - i, this.bottom = t.chartHeight - this.top - s, this.pos = this.horiz ? this.left : this.top), this.len = (this.horiz ? i : s) || y.defaultLegendLength;
      }
      getOffset() {
        let t = this.legendItem?.group,
          e = this.chart.axisOffset[this.side];
        if (t) {
          this.axisParent = t, super.getOffset();
          let i = this.chart.legend;
          i.allItems.forEach(function (t) {
            t instanceof y && t.drawLegendSymbol(i, t);
          }), i.render(), this.chart.getMargins(!0), this.chart.series.some(t => t.isDrilling) || (this.isDirty = !0), this.added || (this.added = !0, this.labelLeft = 0, this.labelRight = this.width), this.chart.axisOffset[this.side] = e;
        }
      }
      setLegendColor() {
        let t = this.horiz,
          e = this.reversed,
          i = e ? 1 : 0,
          s = e ? 0 : 1,
          o = t ? [i, 0, s, 0] : [0, s, 0, i];
        this.legendColor = {
          linearGradient: {
            x1: o[0],
            y1: o[1],
            x2: o[2],
            y2: o[3]
          },
          stops: this.stops
        };
      }
      drawLegendSymbol(t, e) {
        let i = e.legendItem || {},
          s = t.padding,
          o = t.options,
          r = this.options.labels,
          a = m(o.itemDistance, 10),
          n = this.horiz,
          {
            width: l,
            height: h
          } = this.getSize(),
          d = m(o.labelPadding, n ? 16 : 30);
        this.setLegendColor(), i.symbol || (i.symbol = this.chart.renderer.symbol("roundedRect").attr({
          r: o.symbolRadius ?? 3,
          zIndex: 1
        }).add(i.group)), i.symbol.attr({
          x: 0,
          y: (t.baseline || 0) - 11,
          width: l,
          height: h
        }), i.labelWidth = l + s + (n ? a : m(r.x, r.distance) + (this.maxLabelLength || 0)), i.labelHeight = h + s + (n ? d : 0);
      }
      setState(t) {
        this.series.forEach(function (e) {
          e.setState(t);
        });
      }
      setVisible() {}
      getSeriesExtremes() {
        let t = this.series,
          e,
          i,
          s,
          o,
          r,
          a,
          n = t.length,
          l,
          c;
        for (this.dataMin = 1 / 0, this.dataMax = -1 / 0; n--;) {
          if (i = (a = t[n]).colorKey = m(a.options.colorKey, a.colorKey, a.pointValKey, a.zoneAxis, "y"), o = a.pointArrayMap, r = a[i + "Min"] && a[i + "Max"], a[i + "Data"]) e = a[i + "Data"];else if (o) {
            if (e = [], s = o.indexOf(i), l = a.yData, s >= 0 && l) for (c = 0; c < l.length; c++) e.push(m(l[c][s], l[c]));
          } else e = a.yData;
          if (r) a.minColorValue = a[i + "Min"], a.maxColorValue = a[i + "Max"];else {
            let t = h.prototype.getExtremes.call(a, e);
            a.minColorValue = t.dataMin, a.maxColorValue = t.dataMax;
          }
          d(a.minColorValue) && d(a.maxColorValue) && (this.dataMin = Math.min(this.dataMin, a.minColorValue), this.dataMax = Math.max(this.dataMax, a.maxColorValue)), r || h.prototype.applyExtremes.call(a);
        }
      }
      drawCrosshair(t, e) {
        let i;
        let s = this.legendItem || {},
          o = e && e.plotX,
          r = e && e.plotY,
          a = this.pos,
          n = this.len;
        e && ((i = this.toPixels(e.getNestedProperty(e.series.colorKey))) < a ? i = a - 2 : i > a + n && (i = a + n + 2), e.plotX = i, e.plotY = this.len - i, super.drawCrosshair(t, e), e.plotX = o, e.plotY = r, this.cross && !this.cross.addedToColorAxis && s.group && (this.cross.addClass("highcharts-coloraxis-marker").add(s.group), this.cross.addedToColorAxis = !0, this.chart.styledMode || "object" != typeof this.crosshair || this.cross.attr({
          fill: this.crosshair.color
        })));
      }
      getPlotLinePath(t) {
        let e = this.left,
          i = t.translatedValue,
          s = this.top;
        return g(i) ? this.horiz ? [["M", i - 4, s - 6], ["L", i + 4, s - 6], ["L", i, s], ["Z"]] : [["M", e, i], ["L", e - 6, i + 6], ["L", e - 6, i - 6], ["Z"]] : super.getPlotLinePath(t);
      }
      update(t, e) {
        let i = this.chart.legend;
        this.series.forEach(t => {
          t.isDirtyData = !0;
        }), (t.dataClasses && i.allItems || this.dataClasses) && this.destroyItems(), super.update(t, e), this.legendItem && this.legendItem.label && (this.setLegendColor(), i.colorizeItem(this, !0));
      }
      destroyItems() {
        let t = this.chart,
          e = this.legendItem || {};
        if (e.label) t.legend.destroyItem(this);else if (e.labels) for (let i of e.labels) t.legend.destroyItem(i);
        t.isDirtyLegend = !0;
      }
      destroy() {
        this.chart.isDirtyLegend = !0, this.destroyItems(), super.destroy(...[].slice.call(arguments));
      }
      remove(t) {
        this.destroyItems(), super.remove(t);
      }
      getDataClassLegendSymbols() {
        let t;
        let e = this,
          i = e.chart,
          s = e.legendItem && e.legendItem.labels || [],
          o = i.options.legend,
          a = m(o.valueDecimals, -1),
          n = m(o.valueSuffix, ""),
          l = t => e.series.reduce((e, i) => (e.push(...i.points.filter(e => e.dataClass === t)), e), []);
        return s.length || e.dataClasses.forEach((o, h) => {
          let d = o.from,
            u = o.to,
            {
              numberFormatter: g
            } = i,
            f = !0;
          t = "", void 0 === d ? t = "< " : void 0 === u && (t = "> "), void 0 !== d && (t += g(d, a) + n), void 0 !== d && void 0 !== u && (t += " - "), void 0 !== u && (t += g(u, a) + n), s.push(c({
            chart: i,
            name: t,
            options: {},
            drawLegendSymbol: r.rectangle,
            visible: !0,
            isDataClass: !0,
            setState: t => {
              for (let e of l(h)) e.setState(t);
            },
            setVisible: function () {
              this.visible = f = e.visible = !f;
              let t = [];
              for (let e of l(h)) e.setVisible(f), e.hiddenInDataClass = !f, -1 === t.indexOf(e.series) && t.push(e.series);
              i.legend.colorizeItem(this, f), t.forEach(t => {
                p(t, "afterDataClassLegendClick");
              });
            }
          }, o));
        }), s;
      }
      getSize() {
        let {
            chart: t,
            horiz: e
          } = this,
          {
            height: i,
            width: s
          } = this.options,
          {
            legend: o
          } = t.options;
        return {
          width: m(d(s) ? x(s, t.chartWidth) : void 0, o?.symbolWidth, e ? y.defaultLegendLength : 12),
          height: m(d(i) ? x(i, t.chartHeight) : void 0, o?.symbolHeight, e ? 12 : y.defaultLegendLength)
        };
      }
    }
    return y.defaultLegendLength = 200, y.keepProps = ["legendItem"], c(y.prototype, s), Array.prototype.push.apply(t.keepProps, y.keepProps), y;
  }), i(e, "masters/modules/coloraxis.src.js", [e["Core/Globals.js"], e["Core/Axis/Color/ColorAxis.js"]], function (t, e) {
    return t.ColorAxis = t.ColorAxis || e, t.ColorAxis.compose(t.Chart, t.Fx, t.Legend, t.Series), t;
  }), i(e, "Maps/MapNavigationDefaults.js", [], function () {
    return {
      lang: {
        zoomIn: "Zoom in",
        zoomOut: "Zoom out"
      },
      mapNavigation: {
        buttonOptions: {
          alignTo: "plotBox",
          align: "left",
          verticalAlign: "top",
          x: 0,
          width: 18,
          height: 18,
          padding: 5,
          style: {
            color: "#666666",
            fontSize: "1em",
            fontWeight: "bold"
          },
          theme: {
            fill: "#ffffff",
            stroke: "#e6e6e6",
            "stroke-width": 1,
            "text-align": "center"
          }
        },
        buttons: {
          zoomIn: {
            onclick: function () {
              this.mapZoom(.5);
            },
            text: "+",
            y: 0
          },
          zoomOut: {
            onclick: function () {
              this.mapZoom(2);
            },
            text: "-",
            y: 28
          }
        },
        mouseWheelSensitivity: 1.1
      }
    };
  }), i(e, "Maps/MapPointer.js", [e["Core/Utilities.js"]], function (t) {
    var e;
    let {
      defined: i,
      extend: s,
      pick: o,
      wrap: r
    } = t;
    return function (t) {
      let e,
        a = 0;
      function n(t) {
        let e = this.chart;
        t = this.normalize(t), e.options.mapNavigation.enableDoubleClickZoomTo ? e.pointer.inClass(t.target, "highcharts-tracker") && e.hoverPoint && e.hoverPoint.zoomTo() : e.isInsidePlot(t.chartX - e.plotLeft, t.chartY - e.plotTop) && e.mapZoom(.5, void 0, void 0, t.chartX, t.chartY);
      }
      function l(t) {
        let s = this.chart,
          o = i((t = this.normalize(t)).wheelDelta) && -t.wheelDelta / 120 || t.deltaY || t.detail;
        Math.abs(o) >= 1 && (a += Math.abs(o), e && clearTimeout(e), e = setTimeout(() => {
          a = 0;
        }, 50)), a < 10 && s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop) && s.mapView && s.mapView.zoomBy(-((s.options.mapNavigation.mouseWheelSensitivity - 1) * o), void 0, [t.chartX, t.chartY], !(1 > Math.abs(o)) && void 0);
      }
      function h(t, e, i) {
        let o = this.chart;
        if (e = t.call(this, e, i), o && o.mapView) {
          let t = o.mapView.pixelsToLonLat({
            x: e.chartX - o.plotLeft,
            y: e.chartY - o.plotTop
          });
          t && s(e, t);
        }
        return e;
      }
      function d(t) {
        let e = this.chart.options.mapNavigation;
        e && o(e.enableTouchZoom, e.enabled) && (this.chart.zooming.pinchType = "xy"), t.apply(this, [].slice.call(arguments, 1));
      }
      t.compose = function (t) {
        let e = t.prototype;
        e.onContainerDblClick || (s(e, {
          onContainerDblClick: n,
          onContainerMouseWheel: l
        }), r(e, "normalize", h), r(e, "zoomOption", d));
      };
    }(e || (e = {})), e;
  }), i(e, "Maps/MapSymbols.js", [], function () {
    let t;
    function e(e, i, s, o, r) {
      if (r) {
        let t = r?.r || 0;
        r.brBoxY = i - t, r.brBoxHeight = o + t;
      }
      return t.roundedRect(e, i, s, o, r);
    }
    function i(e, i, s, o, r) {
      if (r) {
        let t = r?.r || 0;
        r.brBoxHeight = o + t;
      }
      return t.roundedRect(e, i, s, o, r);
    }
    return {
      compose: function (s) {
        (t = s.prototype.symbols).bottombutton = e, t.topbutton = i;
      }
    };
  }), i(e, "Maps/MapNavigation.js", [e["Core/Defaults.js"], e["Core/Globals.js"], e["Maps/MapNavigationDefaults.js"], e["Maps/MapPointer.js"], e["Maps/MapSymbols.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r) {
    let {
        setOptions: a
      } = t,
      {
        composed: n
      } = e,
      {
        addEvent: l,
        extend: h,
        merge: d,
        objectEach: c,
        pick: p,
        pushUnique: u
      } = r;
    function g(t) {
      t && (t.preventDefault && t.preventDefault(), t.stopPropagation && t.stopPropagation(), t.cancelBubble = !0);
    }
    class f {
      static compose(t, e, r) {
        s.compose(e), o.compose(r), u(n, "Map.Navigation") && (l(t, "beforeRender", function () {
          this.mapNavigation = new f(this), this.mapNavigation.update();
        }), a(i));
      }
      constructor(t) {
        this.chart = t, this.navButtons = [];
      }
      update(t) {
        let e = this,
          i = e.chart,
          s = e.navButtons,
          o = function (t) {
            this.handler.call(i, t), g(t);
          },
          r = i.options.mapNavigation;
        for (t && (r = i.options.mapNavigation = d(i.options.mapNavigation, t)); s.length;) s.pop().destroy();
        if (!i.renderer.forExport && p(r.enableButtons, r.enabled)) {
          e.navButtonsGroup || (e.navButtonsGroup = i.renderer.g().attr({
            zIndex: 7
          }).add()), c(r.buttons, (t, a) => {
            let n = {
              padding: (t = d(r.buttonOptions, t)).padding
            };
            !i.styledMode && t.theme && (h(n, t.theme), n.style = d(t.theme.style, t.style));
            let {
                text: c,
                width: p = 0,
                height: u = 0,
                padding: f = 0
              } = t,
              m = i.renderer.button("+" !== c && "-" !== c && c || "", 0, 0, o, n, void 0, void 0, void 0, "zoomIn" === a ? "topbutton" : "bottombutton").addClass("highcharts-map-navigation highcharts-" + {
                zoomIn: "zoom-in",
                zoomOut: "zoom-out"
              }[a]).attr({
                width: p,
                height: u,
                title: i.options.lang[a],
                zIndex: 5
              }).add(e.navButtonsGroup);
            if ("+" === c || "-" === c) {
              let e = p + 1,
                s = [["M", f + 3, f + u / 2], ["L", f + e - 3, f + u / 2]];
              "+" === c && s.push(["M", f + e / 2, f + 3], ["L", f + e / 2, f + u - 3]), i.renderer.path(s).addClass("highcharts-button-symbol").attr(i.styledMode ? {} : {
                stroke: t.style?.color,
                "stroke-width": 3,
                "stroke-linecap": "round"
              }).add(m);
            }
            if (m.handler = t.onclick, l(m.element, "dblclick", g), s.push(m), h(t, {
              width: m.width,
              height: 2 * (m.height || 0)
            }), i.hasLoaded) m.align(t, !1, t.alignTo);else {
              let e = l(i, "load", () => {
                m.element && m.align(t, !1, t.alignTo), e();
              });
            }
          });
          let t = (t, e) => !(e.x >= t.x + t.width || e.x + e.width <= t.x || e.y >= t.y + t.height || e.y + e.height <= t.y);
          i.hasLoaded || l(i, "render", function () {
            let s = i.exportingGroup && i.exportingGroup.getBBox();
            if (s) {
              let i = e.navButtonsGroup.getBBox();
              if (t(s, i)) {
                let t = -i.y - i.height + s.y - 5,
                  o = s.y + s.height - i.y + 5,
                  a = r.buttonOptions && r.buttonOptions.verticalAlign;
                e.navButtonsGroup.attr({
                  translateY: "bottom" === a ? t : o
                });
              }
            }
          });
        }
        this.updateEvents(r);
      }
      updateEvents(t) {
        let e = this.chart;
        p(t.enableDoubleClickZoom, t.enabled) || t.enableDoubleClickZoomTo ? this.unbindDblClick = this.unbindDblClick || l(e.container, "dblclick", function (t) {
          e.pointer.onContainerDblClick(t);
        }) : this.unbindDblClick && (this.unbindDblClick = this.unbindDblClick()), p(t.enableMouseWheelZoom, t.enabled) ? this.unbindMouseWheel = this.unbindMouseWheel || l(e.container, "wheel", function (t) {
          return e.pointer.inClass(t.target, "highcharts-no-mousewheel") || (e.pointer.onContainerMouseWheel(t), g(t)), !1;
        }) : this.unbindMouseWheel && (this.unbindMouseWheel = this.unbindMouseWheel());
      }
    }
    return f;
  }), i(e, "Series/ColorMapComposition.js", [e["Core/Series/SeriesRegistry.js"], e["Core/Renderer/SVG/SVGElement.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s;
    let {
        column: {
          prototype: o
        }
      } = t.seriesTypes,
      {
        addEvent: r,
        defined: a
      } = i;
    return function (t) {
      function i(t) {
        let i = this.series,
          s = i.chart.renderer;
        this.moveToTopOnHover && this.graphic && (i.stateMarkerGraphic || (i.stateMarkerGraphic = new e(s, "use").css({
          pointerEvents: "none"
        }).add(this.graphic.parentGroup)), t?.state === "hover" ? (this.graphic.attr({
          id: this.id
        }), i.stateMarkerGraphic.attr({
          href: `${s.url}#${this.id}`,
          visibility: "visible"
        })) : i.stateMarkerGraphic.attr({
          href: ""
        }));
      }
      t.pointMembers = {
        dataLabelOnNull: !0,
        moveToTopOnHover: !0,
        isValid: function () {
          return null !== this.value && this.value !== 1 / 0 && this.value !== -1 / 0 && (void 0 === this.value || !isNaN(this.value));
        }
      }, t.seriesMembers = {
        colorKey: "value",
        axisTypes: ["xAxis", "yAxis", "colorAxis"],
        parallelArrays: ["x", "y", "value"],
        pointArrayMap: ["value"],
        trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
        colorAttribs: function (t) {
          let e = {};
          return a(t.color) && (!t.state || "normal" === t.state) && (e[this.colorProp || "fill"] = t.color), e;
        },
        pointAttribs: o.pointAttribs
      }, t.compose = function (t) {
        return r(t.prototype.pointClass, "afterSetState", i), t;
      };
    }(s || (s = {})), s;
  }), i(e, "Core/Chart/MapChart.js", [e["Core/Chart/Chart.js"], e["Core/Defaults.js"], e["Core/Renderer/SVG/SVGRenderer.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    var o;
    let {
        getOptions: r
      } = e,
      {
        isNumber: a,
        merge: n,
        pick: l
      } = s;
    class h extends t {
      init(t, e) {
        let i = r().credits,
          s = n({
            chart: {
              panning: {
                enabled: !0,
                type: "xy"
              },
              type: "map"
            },
            credits: {
              mapText: l(i.mapText, ' \xa9 <a href="{geojson.copyrightUrl}">{geojson.copyrightShort}</a>'),
              mapTextFull: l(i.mapTextFull, "{geojson.copyright}")
            },
            mapView: {},
            tooltip: {
              followTouchMove: !1
            }
          }, t);
        super.init(s, e);
      }
      mapZoom(t, e, i, s, o) {
        this.mapView && (a(t) && (t = Math.log(t) / Math.log(.5)), this.mapView.zoomBy(t, a(e) && a(i) ? this.mapView.projection.inverse([e, i]) : void 0, a(s) && a(o) ? [s, o] : void 0));
      }
      update(t) {
        t.chart && "map" in t.chart && this.mapView?.recommendMapView(this, [t.chart.map, ...(this.options.series || []).map(t => t.mapData)], !0), super.update.apply(this, arguments);
      }
    }
    return (o = h || (h = {})).maps = {}, o.mapChart = function (t, e, i) {
      return new o(t, e, i);
    }, o.splitPath = function (t) {
      let e;
      return e = "string" == typeof t ? (t = t.replace(/([A-Z])/gi, " $1 ").replace(/^\s*/, "").replace(/\s*$/, "")).split(/[ ,;]+/).map(t => /[A-Z]/i.test(t) ? t : parseFloat(t)) : t, i.prototype.pathToSegments(e);
    }, h;
  }), i(e, "Maps/MapUtilities.js", [], function () {
    return {
      boundsFromPath: function (t) {
        let e = -Number.MAX_VALUE,
          i = Number.MAX_VALUE,
          s = -Number.MAX_VALUE,
          o = Number.MAX_VALUE,
          r;
        if (t.forEach(t => {
          let a = t[t.length - 2],
            n = t[t.length - 1];
          "number" == typeof a && "number" == typeof n && (i = Math.min(i, a), e = Math.max(e, a), o = Math.min(o, n), s = Math.max(s, n), r = !0);
        }), r) return {
          x1: i,
          y1: o,
          x2: e,
          y2: s
        };
      }
    };
  }), i(e, "Series/Map/MapPoint.js", [e["Series/ColorMapComposition.js"], e["Maps/MapUtilities.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
        boundsFromPath: o
      } = e,
      r = i.seriesTypes.scatter.prototype.pointClass,
      {
        extend: a,
        isNumber: n,
        pick: l
      } = s;
    class h extends r {
      static getProjectedPath(t, e) {
        return t.projectedPath || (e && t.geometry ? (e.hasCoordinates = !0, t.projectedPath = e.path(t.geometry)) : t.projectedPath = t.path), t.projectedPath || [];
      }
      applyOptions(t, e) {
        let i = this.series,
          s = super.applyOptions(t, e),
          o = i.joinBy;
        if (i.mapData && i.mapMap) {
          let t = o[1],
            e = super.getNestedProperty(t),
            r = void 0 !== e && i.mapMap[e];
          r ? a(s, {
            ...r,
            name: s.name ?? r.name
          }) : -1 !== i.pointArrayMap.indexOf("value") && (s.value = s.value || null);
        }
        return s;
      }
      getProjectedBounds(t) {
        let e = o(h.getProjectedPath(this, t)),
          i = this.properties,
          s = this.series.chart.mapView;
        if (e) {
          let o = i && i["hc-middle-lon"],
            r = i && i["hc-middle-lat"];
          if (s && n(o) && n(r)) {
            let i = t.forward([o, r]);
            e.midX = i[0], e.midY = i[1];
          } else {
            let t = i && i["hc-middle-x"],
              s = i && i["hc-middle-y"];
            e.midX = e.x1 + (e.x2 - e.x1) * l(this.middleX, n(t) ? t : .5);
            let o = l(this.middleY, n(s) ? s : .5);
            this.geometry || (o = 1 - o), e.midY = e.y2 - (e.y2 - e.y1) * o;
          }
          return e;
        }
      }
      onMouseOver(t) {
        s.clearTimeout(this.colorInterval), !this.isNull && this.visible || this.series.options.nullInteraction ? super.onMouseOver.call(this, t) : this.series.onMouseOut();
      }
      setVisible(t) {
        this.visible = this.options.visible = !!t, this.dataLabel && this.dataLabel[t ? "show" : "hide"](), this.graphic && this.graphic.attr(this.series.pointAttribs(this));
      }
      zoomTo(t) {
        let e = this.series.chart,
          i = e.mapView,
          s = this.bounds;
        if (i && s) {
          let o = n(this.insetIndex) && i.insets[this.insetIndex];
          if (o) {
            let t = o.projectedUnitsToPixels({
                x: s.x1,
                y: s.y1
              }),
              e = o.projectedUnitsToPixels({
                x: s.x2,
                y: s.y2
              }),
              r = i.pixelsToProjectedUnits({
                x: t.x,
                y: t.y
              }),
              a = i.pixelsToProjectedUnits({
                x: e.x,
                y: e.y
              });
            s = {
              x1: r.x,
              y1: r.y,
              x2: a.x,
              y2: a.y
            };
          }
          i.fitToBounds(s, void 0, !1), this.series.isDirty = !0, e.redraw(t);
        }
      }
    }
    return a(h.prototype, {
      dataLabelOnNull: t.pointMembers.dataLabelOnNull,
      moveToTopOnHover: t.pointMembers.moveToTopOnHover,
      isValid: t.pointMembers.isValid
    }), h;
  }), i(e, "Series/Map/MapSeriesDefaults.js", [e["Core/Utilities.js"]], function (t) {
    let {
      isNumber: e
    } = t;
    return {
      affectsMapView: !0,
      animation: !1,
      dataLabels: {
        crop: !1,
        formatter: function () {
          let {
              numberFormatter: t
            } = this.series.chart,
            {
              value: i
            } = this.point;
          return e(i) ? t(i, -1) : this.point.name;
        },
        inside: !0,
        overflow: !1,
        padding: 0,
        verticalAlign: "middle"
      },
      linecap: "round",
      marker: null,
      nullColor: "#f7f7f7",
      stickyTracking: !1,
      tooltip: {
        followPointer: !0,
        pointFormat: "{point.name}: {point.value}<br/>"
      },
      turboThreshold: 0,
      allAreas: !0,
      borderColor: "#e6e6e6",
      borderWidth: 1,
      joinBy: "hc-key",
      states: {
        hover: {
          halo: void 0,
          borderColor: "#666666",
          borderWidth: 2
        },
        normal: {
          animation: !0
        },
        select: {
          color: "#cccccc"
        }
      },
      legendSymbol: "rectangle"
    };
  }), i(e, "Maps/MapViewDefaults.js", [], function () {
    return {
      center: [0, 0],
      fitToGeometry: void 0,
      maxZoom: void 0,
      padding: 0,
      projection: {
        name: void 0,
        parallels: void 0,
        rotation: void 0
      },
      zoom: void 0,
      insetOptions: {
        borderColor: "#cccccc",
        borderWidth: 1,
        padding: "10%",
        relativeTo: "mapBoundingBox",
        units: "percent"
      }
    };
  }), i(e, "Maps/GeoJSONComposition.js", [e["Core/Globals.js"], e["Core/Templating.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s;
    let {
        win: o
      } = t,
      {
        format: r
      } = e,
      {
        error: a,
        extend: n,
        merge: l,
        wrap: h
      } = i;
    return function (t) {
      function e(t) {
        return this.mapView && this.mapView.lonLatToProjectedUnits(t);
      }
      function i(t) {
        return this.mapView && this.mapView.projectedUnitsToLonLat(t);
      }
      function s(t, e) {
        let i = this.options.chart.proj4 || o.proj4;
        if (!i) {
          a(21, !1, this);
          return;
        }
        let {
            jsonmarginX: s = 0,
            jsonmarginY: r = 0,
            jsonres: n = 1,
            scale: l = 1,
            xoffset: h = 0,
            xpan: d = 0,
            yoffset: c = 0,
            ypan: p = 0
          } = e,
          u = i(e.crs, [t.lon, t.lat]),
          g = e.cosAngle || e.rotation && Math.cos(e.rotation),
          f = e.sinAngle || e.rotation && Math.sin(e.rotation),
          m = e.rotation ? [u[0] * g + u[1] * f, -u[0] * f + u[1] * g] : u;
        return {
          x: ((m[0] - h) * l + d) * n + s,
          y: -(((c - m[1]) * l + p) * n - r)
        };
      }
      function d(t, e) {
        let i = this.options.chart.proj4 || o.proj4;
        if (!i) {
          a(21, !1, this);
          return;
        }
        if (null === t.y) return;
        let {
            jsonmarginX: s = 0,
            jsonmarginY: r = 0,
            jsonres: n = 1,
            scale: l = 1,
            xoffset: h = 0,
            xpan: d = 0,
            yoffset: c = 0,
            ypan: p = 0
          } = e,
          u = {
            x: ((t.x - s) / n - d) / l + h,
            y: ((t.y - r) / n + p) / l + c
          },
          g = e.cosAngle || e.rotation && Math.cos(e.rotation),
          f = e.sinAngle || e.rotation && Math.sin(e.rotation),
          m = i(e.crs, "WGS84", e.rotation ? {
            x: u.x * g + -(u.y * f),
            y: u.x * f + u.y * g
          } : u);
        return {
          lat: m.y,
          lon: m.x
        };
      }
      function c(t, e) {
        e || (e = Object.keys(t.objects)[0]);
        let i = t.objects[e];
        if (i["hc-decoded-geojson"] && i["hc-decoded-geojson"].title === t.title) return i["hc-decoded-geojson"];
        let s = t.arcs;
        if (t.transform) {
          let e, i, o;
          let r = t.arcs,
            {
              scale: a,
              translate: n
            } = t.transform;
          s = [];
          for (let t = 0, l = r.length; t < l; ++t) {
            let l = r[t];
            s.push(e = []), i = 0, o = 0;
            for (let t = 0, s = l.length; t < s; ++t) e.push([(i += l[t][0]) * a[0] + n[0], (o += l[t][1]) * a[1] + n[1]]);
          }
        }
        let o = t => "number" == typeof t[0] ? t.reduce((t, e, i) => {
            let o = e < 0 ? s[~e] : s[e];
            return e < 0 ? (o = o.slice(0, 0 === i ? o.length : o.length - 1)).reverse() : i && (o = o.slice(1)), t.concat(o);
          }, []) : t.map(o),
          r = i.geometries,
          a = [];
        for (let t = 0, e = r.length; t < e; ++t) a.push({
          type: "Feature",
          properties: r[t].properties,
          geometry: {
            type: r[t].type,
            coordinates: r[t].coordinates || o(r[t].arcs)
          }
        });
        let n = {
          type: "FeatureCollection",
          copyright: t.copyright,
          copyrightShort: t.copyrightShort,
          copyrightUrl: t.copyrightUrl,
          features: a,
          "hc-recommended-mapview": i["hc-recommended-mapview"],
          bbox: t.bbox,
          title: t.title
        };
        return i["hc-decoded-geojson"] = n, n;
      }
      function p(t, e) {
        e = l(!0, this.options.credits, e), t.call(this, e), this.credits && this.mapCreditsFull && this.credits.attr({
          title: this.mapCreditsFull
        });
      }
      t.compose = function (t) {
        let o = t.prototype;
        o.transformFromLatLon || (o.fromLatLonToPoint = e, o.fromPointToLatLon = i, o.transformFromLatLon = s, o.transformToLatLon = d, h(o, "addCredits", p));
      }, t.geojson = function (t, e = "map", i) {
        let s = [],
          o = "Topology" === t.type ? c(t) : t,
          a = o.features;
        for (let t = 0, i = a.length; t < i; ++t) {
          let i;
          let o = a[t],
            r = o.geometry || {},
            l = r.type,
            h = r.coordinates,
            d = o.properties;
          if (("map" === e || "mapbubble" === e) && ("Polygon" === l || "MultiPolygon" === l) ? h.length && (i = {
            geometry: {
              coordinates: h,
              type: l
            }
          }) : "mapline" === e && ("LineString" === l || "MultiLineString" === l) ? h.length && (i = {
            geometry: {
              coordinates: h,
              type: l
            }
          }) : "mappoint" === e && "Point" === l && h.length && (i = {
            geometry: {
              coordinates: h,
              type: l
            }
          }), i) {
            let t = d && (d.name || d.NAME),
              e = d && d.lon,
              o = d && d.lat;
            s.push(n(i, {
              lat: "number" == typeof o ? o : void 0,
              lon: "number" == typeof e ? e : void 0,
              name: "string" == typeof t ? t : void 0,
              properties: d
            }));
          }
        }
        return i && o.copyrightShort && (i.chart.mapCredits = r(i.chart.options.credits?.mapText, {
          geojson: o
        }), i.chart.mapCreditsFull = r(i.chart.options.credits?.mapTextFull, {
          geojson: o
        })), s;
      }, t.topo2geo = c;
    }(s || (s = {})), s;
  }), i(e, "Core/Geometry/PolygonClip.js", [], function () {
    function t(s, o, r = !0) {
      let a = o[o.length - 1],
        n,
        l,
        h,
        d = s;
      for (let t = 0; t < o.length; t++) {
        let s = d;
        n = o[t], d = [], l = r ? s[s.length - 1] : s[0];
        for (let t = 0; t < s.length; t++) e(a, n, h = s[t]) ? (e(a, n, l) || d.push(i(a, n, l, h)), d.push(h)) : e(a, n, l) && d.push(i(a, n, l, h)), l = h;
        a = n;
      }
      return d;
    }
    function e(t, e, i) {
      return (e[0] - t[0]) * (i[1] - t[1]) > (e[1] - t[1]) * (i[0] - t[0]);
    }
    function i(t, e, i, s) {
      let o = [t[0] - e[0], t[1] - e[1]],
        r = [i[0] - s[0], i[1] - s[1]],
        a = t[0] * e[1] - t[1] * e[0],
        n = i[0] * s[1] - i[1] * s[0],
        l = 1 / (o[0] * r[1] - o[1] * r[0]),
        h = [(a * r[0] - n * o[0]) * l, (a * r[1] - n * o[1]) * l];
      return h.isIntersection = !0, h;
    }
    return {
      clipLineString: function (e, i) {
        let s = [],
          o = t(e, i, !1);
        for (let t = 1; t < o.length; t++) o[t].isIntersection && o[t - 1].isIntersection && (s.push(o.splice(0, t)), t = 0), t === o.length - 1 && s.push(o);
        return s;
      },
      clipPolygon: t
    };
  }), i(e, "Maps/Projections/LambertConformalConic.js", [], function () {
    let t = Math.sign || (t => 0 === t ? 0 : t > 0 ? 1 : -1),
      e = Math.PI / 180,
      i = Math.PI / 2,
      s = t => Math.tan((i + t) / 2);
    return class {
      constructor(i) {
        let o = (i.parallels || []).map(t => t * e),
          r = o[0] || 0,
          a = o[1] ?? r,
          n = Math.cos(r);
        "object" == typeof i.projectedBounds && (this.projectedBounds = i.projectedBounds);
        let l = r === a ? Math.sin(r) : Math.log(n / Math.cos(a)) / Math.log(s(a) / s(r));
        1e-10 > Math.abs(l) && (l = 1e-10 * (t(l) || 1)), this.n = l, this.c = n * Math.pow(s(r), l) / l;
      }
      forward(t) {
        let {
            c: o,
            n: r,
            projectedBounds: a
          } = this,
          n = t[0] * e,
          l = t[1] * e;
        o > 0 ? l < -i + 1e-6 && (l = -i + 1e-6) : l > i - 1e-6 && (l = i - 1e-6);
        let h = o / Math.pow(s(l), r),
          d = h * Math.sin(r * n) * 63.78137,
          c = (o - h * Math.cos(r * n)) * 63.78137,
          p = [d, c];
        return a && (d < a.x1 || d > a.x2 || c < a.y1 || c > a.y2) && (p.outside = !0), p;
      }
      inverse(s) {
        let {
            c: o,
            n: r
          } = this,
          a = s[0] / 63.78137,
          n = o - s[1] / 63.78137,
          l = t(r) * Math.sqrt(a * a + n * n),
          h = Math.atan2(a, Math.abs(n)) * t(n);
        return n * r < 0 && (h -= Math.PI * t(a) * t(n)), [h / r / e, (2 * Math.atan(Math.pow(o / l, 1 / r)) - i) / e];
      }
    };
  }), i(e, "Maps/Projections/EqualEarth.js", [], function () {
    let t = Math.sqrt(3) / 2;
    return class {
      constructor() {
        this.bounds = {
          x1: -200.37508342789243,
          x2: 200.37508342789243,
          y1: -97.52595454902263,
          y2: 97.52595454902263
        };
      }
      forward(e) {
        let i = Math.PI / 180,
          s = Math.asin(t * Math.sin(e[1] * i)),
          o = s * s,
          r = o * o * o;
        return [e[0] * i * Math.cos(s) * 74.03120656864502 / (t * (1.340264 + -.24331799999999998 * o + r * (.0062510000000000005 + .034164 * o))), 74.03120656864502 * s * (1.340264 + -.081106 * o + r * (893e-6 + .003796 * o))];
      }
      inverse(e) {
        let i = e[0] / 74.03120656864502,
          s = e[1] / 74.03120656864502,
          o = 180 / Math.PI,
          r = s,
          a,
          n,
          l,
          h;
        for (let t = 0; t < 12 && (n = (a = r * r) * a * a, l = r * (1.340264 + -.081106 * a + n * (893e-6 + .003796 * a)) - s, r -= h = l / (1.340264 + -.24331799999999998 * a + n * (.0062510000000000005 + .034164 * a)), !(1e-9 > Math.abs(h))); ++t);
        n = (a = r * r) * a * a;
        let d = o * t * i * (1.340264 + -.24331799999999998 * a + n * (.0062510000000000005 + .034164 * a)) / Math.cos(r),
          c = o * Math.asin(Math.sin(r) / t);
        return Math.abs(d) > 180 ? [NaN, NaN] : [d, c];
      }
    };
  }), i(e, "Maps/Projections/Miller.js", [], function () {
    let t = Math.PI / 4,
      e = Math.PI / 180;
    return class {
      constructor() {
        this.bounds = {
          x1: -200.37508342789243,
          x2: 200.37508342789243,
          y1: -146.91480769173063,
          y2: 146.91480769173063
        };
      }
      forward(i) {
        return [i[0] * e * 63.78137, 79.7267125 * Math.log(Math.tan(t + .4 * i[1] * e))];
      }
      inverse(i) {
        return [i[0] / 63.78137 / e, 2.5 * (Math.atan(Math.exp(.8 * (i[1] / 63.78137))) - t) / e];
      }
    };
  }), i(e, "Maps/Projections/Orthographic.js", [], function () {
    let t = Math.PI / 180;
    return class {
      constructor() {
        this.antimeridianCutting = !1, this.bounds = {
          x1: -63.78460826781007,
          x2: 63.78460826781007,
          y1: -63.78460826781007,
          y2: 63.78460826781007
        };
      }
      forward(e) {
        let i = e[0],
          s = e[1] * t,
          o = [Math.cos(s) * Math.sin(i * t) * 63.78460826781007, 63.78460826781007 * Math.sin(s)];
        return (i < -90 || i > 90) && (o.outside = !0), o;
      }
      inverse(e) {
        let i = e[0] / 63.78460826781007,
          s = e[1] / 63.78460826781007,
          o = Math.sqrt(i * i + s * s),
          r = Math.asin(o),
          a = Math.sin(r);
        return [Math.atan2(i * a, o * Math.cos(r)) / t, Math.asin(o && s * a / o) / t];
      }
    };
  }), i(e, "Maps/Projections/WebMercator.js", [], function () {
    let t = Math.PI / 180;
    return class {
      constructor() {
        this.bounds = {
          x1: -200.37508342789243,
          x2: 200.37508342789243,
          y1: -200.3750834278071,
          y2: 200.3750834278071
        }, this.maxLatitude = 85.0511287798;
      }
      forward(e) {
        let i = Math.sin(e[1] * t),
          s = [63.78137 * e[0] * t, 63.78137 * Math.log((1 + i) / (1 - i)) / 2];
        return Math.abs(e[1]) > this.maxLatitude && (s.outside = !0), s;
      }
      inverse(e) {
        return [e[0] / (63.78137 * t), (2 * Math.atan(Math.exp(e[1] / 63.78137)) - Math.PI / 2) / t];
      }
    };
  }), i(e, "Maps/Projections/ProjectionRegistry.js", [e["Maps/Projections/LambertConformalConic.js"], e["Maps/Projections/EqualEarth.js"], e["Maps/Projections/Miller.js"], e["Maps/Projections/Orthographic.js"], e["Maps/Projections/WebMercator.js"]], function (t, e, i, s, o) {
    return {
      EqualEarth: e,
      LambertConformalConic: t,
      Miller: i,
      Orthographic: s,
      WebMercator: o
    };
  }), i(e, "Maps/Projection.js", [e["Core/Geometry/PolygonClip.js"], e["Maps/Projections/ProjectionRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        clipLineString: s,
        clipPolygon: o
      } = t,
      {
        clamp: r,
        erase: a
      } = i,
      n = 2 * Math.PI / 360,
      l = t => (t < -180 && (t += 360), t > 180 && (t -= 360), t),
      h = t => (1 - Math.cos(t)) / 2,
      d = (t, e) => {
        let i = Math.cos,
          s = t[1] * n,
          o = t[0] * n,
          r = e[1] * n,
          a = e[0] * n;
        return h(r - s) + i(s) * i(r) * h(a - o);
      };
    class c {
      static add(t, e) {
        c.registry[t] = e;
      }
      static distance(t, e) {
        let {
            atan2: i,
            sqrt: s
          } = Math,
          o = d(t, e);
        return 6371e3 * (2 * i(s(o), s(1 - o)));
      }
      static geodesic(t, e, i, s = 5e5) {
        let {
            atan2: o,
            cos: r,
            sin: a,
            sqrt: l
          } = Math,
          h = c.distance,
          d = t[1] * n,
          p = t[0] * n,
          u = e[1] * n,
          g = e[0] * n,
          f = r(d) * r(p),
          m = r(u) * r(g),
          x = r(d) * a(p),
          y = r(u) * a(g),
          b = a(d),
          v = a(u),
          M = h(t, e),
          C = M / 6371e3,
          S = a(C),
          w = Math.round(M / s),
          k = [];
        if (i && k.push(t), w > 1) {
          let t = 1 / w;
          for (let e = t; e < .999; e += t) {
            let t = a((1 - e) * C) / S,
              i = a(e * C) / S,
              s = t * f + i * m,
              r = t * x + i * y,
              h = o(t * b + i * v, l(s * s + r * r)),
              d = o(r, s);
            k.push([d / n, h / n]);
          }
        }
        return i && k.push(e), k;
      }
      static insertGeodesics(t) {
        let e = t.length - 1;
        for (; e--;) if (Math.max(Math.abs(t[e][0] - t[e + 1][0]), Math.abs(t[e][1] - t[e + 1][1])) > 10) {
          let i = c.geodesic(t[e], t[e + 1]);
          i.length && t.splice(e + 1, 0, ...i);
        }
      }
      static toString(t) {
        let {
          name: e,
          rotation: i
        } = t || {};
        return [e, i && i.join(",")].join(";");
      }
      constructor(t = {}) {
        this.hasCoordinates = !1, this.hasGeoProjection = !1, this.maxLatitude = 90, this.options = t;
        let {
          name: e,
          projectedBounds: i,
          rotation: s
        } = t;
        this.rotator = s ? this.getRotator(s) : void 0;
        let o = e ? c.registry[e] : void 0;
        o && (this.def = new o(t));
        let {
          def: r,
          rotator: a
        } = this;
        r && (this.maxLatitude = r.maxLatitude || 90, this.hasGeoProjection = !0), a && r ? (this.forward = t => r.forward(a.forward(t)), this.inverse = t => a.inverse(r.inverse(t))) : r ? (this.forward = t => r.forward(t), this.inverse = t => r.inverse(t)) : a && (this.forward = a.forward, this.inverse = a.inverse), this.bounds = "world" === i ? r && r.bounds : i;
      }
      lineIntersectsBounds(t) {
        let {
            x1: e,
            x2: i,
            y1: s,
            y2: o
          } = this.bounds || {},
          r = (t, e, i) => {
            let [s, o] = t,
              r = e ? 0 : 1;
            if ("number" == typeof i && s[e] >= i != o[e] >= i) {
              let t = (i - s[e]) / (o[e] - s[e]),
                a = s[r] + t * (o[r] - s[r]);
              return e ? [a, i] : [i, a];
            }
          },
          a,
          n = t[0];
        return (a = r(t, 0, e)) ? (n = a, t[1] = a) : (a = r(t, 0, i)) && (n = a, t[1] = a), (a = r(t, 1, s)) ? n = a : (a = r(t, 1, o)) && (n = a), n;
      }
      getRotator(t) {
        let e = t[0] * n,
          i = (t[1] || 0) * n,
          s = (t[2] || 0) * n,
          o = Math.cos(i),
          r = Math.sin(i),
          a = Math.cos(s),
          l = Math.sin(s);
        if (0 !== e || 0 !== i || 0 !== s) return {
          forward: t => {
            let i = t[0] * n + e,
              s = t[1] * n,
              h = Math.cos(s),
              d = Math.cos(i) * h,
              c = Math.sin(i) * h,
              p = Math.sin(s),
              u = p * o + d * r;
            return [Math.atan2(c * a - u * l, d * o - p * r) / n, Math.asin(u * a + c * l) / n];
          },
          inverse: t => {
            let i = t[0] * n,
              s = t[1] * n,
              h = Math.cos(s),
              d = Math.cos(i) * h,
              c = Math.sin(i) * h,
              p = Math.sin(s),
              u = p * a - c * l;
            return [(Math.atan2(c * a + p * l, d * o + u * r) - e) / n, Math.asin(u * o - d * r) / n];
          }
        };
      }
      forward(t) {
        return t;
      }
      inverse(t) {
        return t;
      }
      cutOnAntimeridian(t, e) {
        let i;
        let s = [],
          o = [t];
        for (let i = 0, o = t.length; i < o; ++i) {
          let o = t[i],
            a = t[i - 1];
          if (!i) {
            if (!e) continue;
            a = t[t.length - 1];
          }
          let n = a[0],
            l = o[0];
          if ((n < -90 || n > 90) && (l < -90 || l > 90) && n > 0 != l > 0) {
            let t = r((180 - (n + 360) % 360) / ((l + 360) % 360 - (n + 360) % 360), 0, 1),
              e = a[1] + t * (o[1] - a[1]);
            s.push({
              i,
              lat: e,
              direction: n < 0 ? 1 : -1,
              previousLonLat: a,
              lonLat: o
            });
          }
        }
        if (s.length) {
          if (e) {
            s.length % 2 == 1 && (i = s.slice().sort((t, e) => Math.abs(e.lat) - Math.abs(t.lat))[0], a(s, i));
            let e = s.length - 2;
            for (; e >= 0;) {
              let i = s[e].i,
                r = l(180 + 1e-6 * s[e].direction),
                a = l(180 - 1e-6 * s[e].direction),
                n = t.splice(i, s[e + 1].i - i, ...c.geodesic([r, s[e].lat], [r, s[e + 1].lat], !0));
              n.push(...c.geodesic([a, s[e + 1].lat], [a, s[e].lat], !0)), o.push(n), e -= 2;
            }
            if (i) for (let t = 0; t < o.length; t++) {
              let {
                  direction: e,
                  lat: s
                } = i,
                r = o[t],
                a = r.indexOf(i.lonLat);
              if (a > -1) {
                let t = (s < 0 ? -1 : 1) * this.maxLatitude,
                  o = l(180 + 1e-6 * e),
                  n = l(180 - 1e-6 * e),
                  h = c.geodesic([o, s], [o, t], !0);
                for (let i = o + 120 * e; i > -180 && i < 180; i += 120 * e) h.push([i, t]);
                h.push(...c.geodesic([n, t], [n, i.lat], !0)), r.splice(a, 0, ...h);
                break;
              }
            }
          } else {
            let e = s.length;
            for (; e--;) {
              let i = s[e].i,
                r = t.splice(i, t.length, [l(180 + 1e-6 * s[e].direction), s[e].lat]);
              r.unshift([l(180 - 1e-6 * s[e].direction), s[e].lat]), o.push(r);
            }
          }
        }
        return o;
      }
      path(t) {
        let e;
        let {
            bounds: i,
            def: r,
            rotator: a
          } = this,
          n = [],
          l = "Polygon" === t.type || "MultiPolygon" === t.type,
          h = this.hasGeoProjection,
          d = !r || !1 !== r.antimeridianCutting,
          p = d ? a : void 0,
          u = d && r || this;
        i && (e = [[i.x1, i.y1], [i.x2, i.y1], [i.x2, i.y2], [i.x1, i.y2]]);
        let g = t => {
          let r = t.map(t => {
              if (d) {
                p && (t = p.forward(t));
                let e = t[0];
                1e-6 > Math.abs(e - 180) && (e = e < 180 ? 179.999999 : 180.000001), t = [e, t[1]];
              }
              return t;
            }),
            a = [r];
          h && (c.insertGeodesics(r), d && (a = this.cutOnAntimeridian(r, l))), a.forEach(t => {
            let r, a;
            if (t.length < 2) return;
            let p = !1,
              g = !1,
              f = t => {
                p ? n.push(["L", t[0], t[1]]) : (n.push(["M", t[0], t[1]]), p = !0);
              },
              m = !1,
              x = !1,
              y = t.map(t => {
                let e = u.forward(t);
                return e.outside ? m = !0 : x = !0, e[1] === 1 / 0 ? e[1] = 1e10 : e[1] === -1 / 0 && (e[1] = -1e10), e;
              });
            if (d) {
              if (l && y.push(y[0]), m) {
                if (!x) return;
                if (e) {
                  if (l) y = o(y, e);else if (i) {
                    s(y, e).forEach(t => {
                      p = !1, t.forEach(f);
                    });
                    return;
                  }
                }
              }
              y.forEach(f);
            } else for (let e = 0; e < y.length; e++) {
              let i = t[e],
                s = y[e];
              s.outside ? g = !0 : (l && !r && (r = i, t.push(i), y.push(s)), g && a && (l && h ? c.geodesic(a, i).forEach(t => f(u.forward(t))) : p = !1), f(s), a = i, g = !1);
            }
          });
        };
        return "LineString" === t.type ? g(t.coordinates) : "MultiLineString" === t.type ? t.coordinates.forEach(t => g(t)) : "Polygon" === t.type ? (t.coordinates.forEach(t => g(t)), n.length && n.push(["Z"])) : "MultiPolygon" === t.type && (t.coordinates.forEach(t => {
          t.forEach(t => g(t));
        }), n.length && n.push(["Z"])), n;
      }
    }
    return c.registry = e, c;
  }), i(e, "Maps/MapView.js", [e["Core/Globals.js"], e["Maps/MapViewDefaults.js"], e["Maps/GeoJSONComposition.js"], e["Core/Geometry/GeometryUtilities.js"], e["Maps/MapUtilities.js"], e["Maps/Projection.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a) {
    let {
        composed: n
      } = t,
      {
        pointInPolygon: l
      } = s,
      {
        topo2geo: h
      } = i,
      {
        boundsFromPath: d
      } = o,
      {
        addEvent: c,
        clamp: p,
        crisp: u,
        fireEvent: g,
        isArray: f,
        isNumber: m,
        isObject: x,
        isString: y,
        merge: b,
        pick: v,
        pushUnique: M,
        relativeLength: C
      } = a,
      S = {};
    function w(t, e) {
      let {
        width: i,
        height: s
      } = e;
      return Math.log(400.979322 / Math.max((t.x2 - t.x1) / (i / 256), (t.y2 - t.y1) / (s / 256))) / Math.log(2);
    }
    function k(t) {
      t.seriesOptions.mapData && this.mapView?.recommendMapView(this, [this.options.chart.map, t.seriesOptions.mapData], this.options.drilldown?.mapZooming);
    }
    class A {
      static compose(t) {
        M(n, "MapView") && (S = t.maps, c(t, "afterInit", function () {
          this.mapView = new A(this, this.options.mapView);
        }, {
          order: 0
        }), c(t, "addSeriesAsDrilldown", k), c(t, "afterDrillUp", k));
      }
      static compositeBounds(t) {
        if (t.length) return t.slice(1).reduce((t, e) => (t.x1 = Math.min(t.x1, e.x1), t.y1 = Math.min(t.y1, e.y1), t.x2 = Math.max(t.x2, e.x2), t.y2 = Math.max(t.y2, e.y2), t), b(t[0]));
      }
      static mergeInsets(t, e) {
        let i = t => {
            let e = {};
            return t.forEach((t, i) => {
              e[t && t.id || `i${i}`] = t;
            }), e;
          },
          s = b(i(t), i(e));
        return Object.keys(s).map(t => s[t]);
      }
      constructor(t, i) {
        this.allowTransformAnimation = !0, this.eventsToUnbind = [], this.insets = [], this.padding = [0, 0, 0, 0], this.recommendedMapView = {}, this instanceof T || this.recommendMapView(t, [t.options.chart.map, ...(t.options.series || []).map(t => t.mapData)]), this.userOptions = i || {};
        let s = b(e, this.recommendedMapView, i),
          o = this.recommendedMapView?.insets,
          a = i && i.insets;
        o && a && (s.insets = A.mergeInsets(o, a)), this.chart = t, this.center = s.center, this.options = s, this.projection = new r(s.projection), this.playingField = t.plotBox, this.zoom = s.zoom || 0, this.minZoom = s.minZoom, this.createInsets(), this.eventsToUnbind.push(c(t, "afterSetChartSize", () => {
          this.playingField = this.getField(), (void 0 === this.minZoom || this.minZoom === this.zoom) && (this.fitToBounds(void 0, void 0, !1), !this.chart.hasRendered && m(this.userOptions.zoom) && (this.zoom = this.userOptions.zoom), this.userOptions.center && b(!0, this.center, this.userOptions.center));
        })), this.setUpEvents();
      }
      createInsets() {
        let t = this.options,
          e = t.insets;
        e && e.forEach(e => {
          let i = new T(this, b(t.insetOptions, e));
          this.insets.push(i);
        });
      }
      fitToBounds(t, e, i = !0, s) {
        let o = t || this.getProjectedBounds();
        if (o) {
          let r = v(e, t ? 0 : this.options.padding),
            a = this.getField(!1),
            n = f(r) ? r : [r, r, r, r];
          this.padding = [C(n[0], a.height), C(n[1], a.width), C(n[2], a.height), C(n[3], a.width)], this.playingField = this.getField();
          let l = w(o, this.playingField);
          t || (this.minZoom = l);
          let h = this.projection.inverse([(o.x2 + o.x1) / 2, (o.y2 + o.y1) / 2]);
          this.setView(h, l, i, s);
        }
      }
      getField(t = !0) {
        let e = t ? this.padding : [0, 0, 0, 0];
        return {
          x: e[3],
          y: e[0],
          width: this.chart.plotWidth - e[1] - e[3],
          height: this.chart.plotHeight - e[0] - e[2]
        };
      }
      getGeoMap(t) {
        if (y(t)) return S[t] && "Topology" === S[t].type ? h(S[t]) : S[t];
        if (x(t, !0)) {
          if ("FeatureCollection" === t.type) return t;
          if ("Topology" === t.type) return h(t);
        }
      }
      getMapBBox() {
        let t = this.getProjectedBounds(),
          e = this.getScale();
        if (t) {
          let i = this.padding,
            s = this.projectedUnitsToPixels({
              x: t.x1,
              y: t.y2
            });
          return {
            width: (t.x2 - t.x1) * e + i[1] + i[3],
            height: (t.y2 - t.y1) * e + i[0] + i[2],
            x: s.x - i[3],
            y: s.y - i[0]
          };
        }
      }
      getProjectedBounds() {
        let t = this.projection,
          e = this.chart.series.reduce((t, e) => {
            let i = e.getProjectedBounds && e.getProjectedBounds();
            return i && !1 !== e.options.affectsMapView && t.push(i), t;
          }, []),
          i = this.options.fitToGeometry;
        if (i) {
          if (!this.fitToGeometryCache) {
            if ("MultiPoint" === i.type) {
              let e = i.coordinates.map(e => t.forward(e)),
                s = e.map(t => t[0]),
                o = e.map(t => t[1]);
              this.fitToGeometryCache = {
                x1: Math.min.apply(0, s),
                x2: Math.max.apply(0, s),
                y1: Math.min.apply(0, o),
                y2: Math.max.apply(0, o)
              };
            } else this.fitToGeometryCache = d(t.path(i));
          }
          return this.fitToGeometryCache;
        }
        return this.projection.bounds || A.compositeBounds(e);
      }
      getScale() {
        return 256 / 400.979322 * Math.pow(2, this.zoom);
      }
      getSVGTransform() {
        let {
            x: t,
            y: e,
            width: i,
            height: s
          } = this.playingField,
          o = this.projection.forward(this.center),
          r = this.projection.hasCoordinates ? -1 : 1,
          a = this.getScale(),
          n = a * r,
          l = t + i / 2 - o[0] * a,
          h = e + s / 2 - o[1] * n;
        return {
          scaleX: a,
          scaleY: n,
          translateX: l,
          translateY: h
        };
      }
      lonLatToPixels(t) {
        let e = this.lonLatToProjectedUnits(t);
        if (e) return this.projectedUnitsToPixels(e);
      }
      lonLatToProjectedUnits(t) {
        let e = this.chart,
          i = e.mapTransforms;
        if (i) {
          for (let s in i) if (Object.hasOwnProperty.call(i, s) && i[s].hitZone) {
            let o = e.transformFromLatLon(t, i[s]);
            if (o && l(o, i[s].hitZone.coordinates[0])) return o;
          }
          return e.transformFromLatLon(t, i.default);
        }
        for (let e of this.insets) if (e.options.geoBounds && l({
          x: t.lon,
          y: t.lat
        }, e.options.geoBounds.coordinates[0])) {
          let i = e.projection.forward([t.lon, t.lat]),
            s = e.projectedUnitsToPixels({
              x: i[0],
              y: i[1]
            });
          return this.pixelsToProjectedUnits(s);
        }
        let s = this.projection.forward([t.lon, t.lat]);
        if (!s.outside) return {
          x: s[0],
          y: s[1]
        };
      }
      projectedUnitsToLonLat(t) {
        let e = this.chart,
          i = e.mapTransforms;
        if (i) {
          for (let s in i) if (Object.hasOwnProperty.call(i, s) && i[s].hitZone && l(t, i[s].hitZone.coordinates[0])) return e.transformToLatLon(t, i[s]);
          return e.transformToLatLon(t, i.default);
        }
        let s = this.projectedUnitsToPixels(t);
        for (let t of this.insets) if (t.hitZone && l(s, t.hitZone.coordinates[0])) {
          let e = t.pixelsToProjectedUnits(s),
            i = t.projection.inverse([e.x, e.y]);
          return {
            lon: i[0],
            lat: i[1]
          };
        }
        let o = this.projection.inverse([t.x, t.y]);
        return {
          lon: o[0],
          lat: o[1]
        };
      }
      recommendMapView(t, e, i = !1) {
        this.recommendedMapView = {};
        let s = e.map(t => this.getGeoMap(t)),
          o = [];
        s.forEach(t => {
          if (t && (Object.keys(this.recommendedMapView).length || (this.recommendedMapView = t["hc-recommended-mapview"] || {}), t.bbox)) {
            let [e, i, s, r] = t.bbox;
            o.push({
              x1: e,
              y1: i,
              x2: s,
              y2: r
            });
          }
        });
        let r = o.length && A.compositeBounds(o);
        g(this, "onRecommendMapView", {
          geoBounds: r,
          chart: t
        }, function () {
          if (r && this.recommendedMapView) {
            if (!this.recommendedMapView.projection) {
              let {
                x1: t,
                y1: e,
                x2: i,
                y2: s
              } = r;
              this.recommendedMapView.projection = i - t > 180 && s - e > 90 ? {
                name: "EqualEarth",
                parallels: [0, 0],
                rotation: [0]
              } : {
                name: "LambertConformalConic",
                parallels: [e, s],
                rotation: [-(t + i) / 2]
              };
            }
            this.recommendedMapView.insets || (this.recommendedMapView.insets = void 0);
          }
        }), this.geoMap = s[0], i && t.hasRendered && !t.userOptions.mapView?.projection && this.recommendedMapView && this.update(this.recommendedMapView);
      }
      redraw(t) {
        this.chart.series.forEach(t => {
          t.useMapGeometry && (t.isDirty = !0);
        }), this.chart.redraw(t);
      }
      setView(t, e, i = !0, s) {
        t && (this.center = t), "number" == typeof e && ("number" == typeof this.minZoom && (e = Math.max(e, this.minZoom)), "number" == typeof this.options.maxZoom && (e = Math.min(e, this.options.maxZoom)), m(e) && (this.zoom = e));
        let o = this.getProjectedBounds();
        if (o) {
          let t = this.projection.forward(this.center),
            {
              x: e,
              y: i,
              width: s,
              height: r
            } = this.playingField,
            a = this.getScale(),
            n = this.projectedUnitsToPixels({
              x: o.x1,
              y: o.y1
            }),
            l = this.projectedUnitsToPixels({
              x: o.x2,
              y: o.y2
            }),
            h = [(o.x1 + o.x2) / 2, (o.y1 + o.y2) / 2];
          if (!this.chart.series.some(t => t.isDrilling)) {
            let o = n.x,
              d = l.y,
              c = l.x,
              p = n.y;
            c - o < s ? t[0] = h[0] : o < e && c < e + s ? t[0] += Math.max(o - e, c - s - e) / a : c > e + s && o > e && (t[0] += Math.min(c - s - e, o - e) / a), p - d < r ? t[1] = h[1] : d < i && p < i + r ? t[1] -= Math.max(d - i, p - r - i) / a : p > i + r && d > i && (t[1] -= Math.min(p - r - i, d - i) / a), this.center = this.projection.inverse(t);
          }
          this.insets.forEach(t => {
            t.options.field && (t.hitZone = t.getHitZone(), t.playingField = t.getField());
          }), this.render();
        }
        g(this, "afterSetView"), i && this.redraw(s);
      }
      projectedUnitsToPixels(t) {
        let e = this.getScale(),
          i = this.projection.forward(this.center),
          s = this.playingField,
          o = s.x + s.width / 2,
          r = s.y + s.height / 2;
        return {
          x: o - e * (i[0] - t.x),
          y: r + e * (i[1] - t.y)
        };
      }
      pixelsToLonLat(t) {
        return this.projectedUnitsToLonLat(this.pixelsToProjectedUnits(t));
      }
      pixelsToProjectedUnits(t) {
        let {
            x: e,
            y: i
          } = t,
          s = this.getScale(),
          o = this.projection.forward(this.center),
          r = this.playingField,
          a = r.x + r.width / 2,
          n = r.y + r.height / 2;
        return {
          x: o[0] + (e - a) / s,
          y: o[1] - (i - n) / s
        };
      }
      setUpEvents() {
        let t, e, i;
        let {
            chart: s
          } = this,
          o = o => {
            let {
                lastTouches: r,
                pinchDown: a
              } = s.pointer,
              n = this.projection,
              l = o.touches,
              {
                mouseDownX: h,
                mouseDownY: d
              } = s,
              c = 0;
            if (a?.length === 1 ? (h = a[0].chartX, d = a[0].chartY) : a?.length === 2 && (h = (a[0].chartX + a[1].chartX) / 2, d = (a[0].chartY + a[1].chartY) / 2), l?.length === 2 && r && (c = Math.log(Math.sqrt(Math.pow(r[0].chartX - r[1].chartX, 2) + Math.pow(r[0].chartY - r[1].chartY, 2)) / Math.sqrt(Math.pow(l[0].chartX - l[1].chartX, 2) + Math.pow(l[0].chartY - l[1].chartY, 2))) / Math.log(.5)), m(h) && m(d)) {
              let r = `${h},${d}`,
                {
                  chartX: a,
                  chartY: u
                } = o.originalEvent;
              l?.length === 2 && (a = (l[0].chartX + l[1].chartX) / 2, u = (l[0].chartY + l[1].chartY) / 2), r !== e && (e = r, t = this.projection.forward(this.center), i = (this.projection.options.rotation || [0, 0]).slice());
              let g = n.def && n.def.bounds,
                f = g && w(g, this.playingField) || -1 / 0;
              if ("Orthographic" === n.options.name && 2 > (l?.length || 0) && (this.minZoom || 1 / 0) < 1.3 * f) {
                let t = 440 / (this.getScale() * Math.min(s.plotWidth, s.plotHeight));
                if (i) {
                  let e = (h - a) * t - i[0],
                    o = p(-i[1] - (d - u) * t, -80, 80),
                    r = this.zoom;
                  this.update({
                    projection: {
                      rotation: [-e, -o]
                    }
                  }, !1), this.fitToBounds(void 0, void 0, !1), this.zoom = r, s.redraw(!1);
                }
              } else if (m(a) && m(u)) {
                let e = this.getScale(),
                  i = this.projection.hasCoordinates ? 1 : -1,
                  s = this.projection.inverse([t[0] + (h - a) / e, t[1] - (d - u) / e * i]);
                isNaN(s[0] + s[1]) || this.zoomBy(c, s, void 0, !1);
              }
              o.preventDefault();
            }
          };
        c(s, "pan", o), c(s, "touchpan", o), c(s, "selection", t => {
          if (t.resetSelection) this.zoomBy();else {
            let e = t.x - s.plotLeft,
              i = t.y - s.plotTop,
              {
                y: o,
                x: r
              } = this.pixelsToProjectedUnits({
                x: e,
                y: i
              }),
              {
                y: a,
                x: n
              } = this.pixelsToProjectedUnits({
                x: e + t.width,
                y: i + t.height
              });
            this.fitToBounds({
              x1: r,
              y1: o,
              x2: n,
              y2: a
            }, void 0, !0, !t.originalEvent.touches && void 0), /^touch/.test(t.originalEvent.type) || s.showResetZoom(), t.preventDefault();
          }
        });
      }
      render() {
        this.group || (this.group = this.chart.renderer.g("map-view").attr({
          zIndex: 4
        }).add());
      }
      update(t, e = !0, i) {
        let s = t.projection,
          o = s && r.toString(s) !== r.toString(this.options.projection),
          a = !1;
        b(!0, this.userOptions, t), b(!0, this.options, t), "insets" in t && (this.insets.forEach(t => t.destroy()), this.insets.length = 0, a = !0), (o || "fitToGeometry" in t) && delete this.fitToGeometryCache, (o || a) && (this.chart.series.forEach(t => {
          let e = t.transformGroups;
          if (t.clearBounds && t.clearBounds(), t.isDirty = !0, t.isDirtyData = !0, a && e) for (; e.length > 1;) {
            let t = e.pop();
            t && t.destroy();
          }
        }), o && (this.projection = new r(this.options.projection)), a && this.createInsets(), !t.center && Object.hasOwnProperty.call(t, "zoom") && !m(t.zoom) && this.fitToBounds(void 0, void 0, !1)), t.center || m(t.zoom) ? this.setView(this.options.center, t.zoom, !1) : "fitToGeometry" in t && this.fitToBounds(void 0, void 0, !1), e && this.chart.redraw(i);
      }
      zoomBy(t, e, i, s) {
        let o = this.chart,
          r = this.projection.forward(this.center);
        if ("number" == typeof t) {
          let a, n, l;
          let h = this.zoom + t;
          if (i) {
            let [t, e] = i,
              s = this.getScale(),
              a = t - o.plotLeft - o.plotWidth / 2,
              h = e - o.plotTop - o.plotHeight / 2;
            n = r[0] + a / s, l = r[1] + h / s;
          }
          if ("number" == typeof n && "number" == typeof l) {
            let t = 1 - Math.pow(2, this.zoom) / Math.pow(2, h),
              e = r[0] - n,
              i = r[1] - l;
            r[0] -= e * t, r[1] += i * t, a = this.projection.inverse(r);
          }
          this.setView(e || a, h, void 0, s);
        } else this.fitToBounds(void 0, void 0, void 0, s);
      }
    }
    class T extends A {
      constructor(t, e) {
        if (super(t.chart, e), this.id = e.id, this.mapView = t, this.options = b({
          center: [0, 0]
        }, t.options.insetOptions, e), this.allBounds = [], this.options.geoBounds) {
          let e = t.projection.path(this.options.geoBounds);
          this.geoBoundsProjectedBox = d(e), this.geoBoundsProjectedPolygon = e.map(t => [t[1] || 0, t[2] || 0]);
        }
      }
      getField(t = !0) {
        let e = this.hitZone;
        if (e) {
          let i = t ? this.padding : [0, 0, 0, 0],
            s = e.coordinates[0],
            o = s.map(t => t[0]),
            r = s.map(t => t[1]),
            a = Math.min.apply(0, o) + i[3],
            n = Math.max.apply(0, o) - i[1],
            l = Math.min.apply(0, r) + i[0],
            h = Math.max.apply(0, r) - i[2];
          if (m(a) && m(l)) return {
            x: a,
            y: l,
            width: n - a,
            height: h - l
          };
        }
        return super.getField.call(this, t);
      }
      getHitZone() {
        let {
            chart: t,
            mapView: e,
            options: i
          } = this,
          {
            coordinates: s
          } = i.field || {};
        if (s) {
          let o = s[0];
          if ("percent" === i.units) {
            let s = "mapBoundingBox" === i.relativeTo && e.getMapBBox() || b(t.plotBox, {
              x: 0,
              y: 0
            });
            o = o.map(t => [C(`${t[0]}%`, s.width, s.x), C(`${t[1]}%`, s.height, s.y)]);
          }
          return {
            type: "Polygon",
            coordinates: [o]
          };
        }
      }
      getProjectedBounds() {
        return A.compositeBounds(this.allBounds);
      }
      isInside(t) {
        let {
          geoBoundsProjectedBox: e,
          geoBoundsProjectedPolygon: i
        } = this;
        return !!(e && t.x >= e.x1 && t.x <= e.x2 && t.y >= e.y1 && t.y <= e.y2 && i && l(t, i));
      }
      render() {
        let {
            chart: t,
            mapView: e,
            options: i
          } = this,
          s = i.borderPath || i.field;
        if (s && e.group) {
          let o = !0;
          this.border || (this.border = t.renderer.path().addClass("highcharts-mapview-inset-border").add(e.group), o = !1), t.styledMode || this.border.attr({
            stroke: i.borderColor,
            "stroke-width": i.borderWidth
          });
          let r = this.border.strokeWidth(),
            a = "mapBoundingBox" === i.relativeTo && e.getMapBBox() || e.playingField,
            n = (s.coordinates || []).reduce((e, s) => s.reduce((e, s, o) => {
              let [n, l] = s;
              return "percent" === i.units && (n = t.plotLeft + C(`${n}%`, a.width, a.x), l = t.plotTop + C(`${l}%`, a.height, a.y)), n = u(n, r), l = u(l, r), e.push(0 === o ? ["M", n, l] : ["L", n, l]), e;
            }, e), []);
          this.border[o ? "animate" : "attr"]({
            d: n
          });
        }
      }
      destroy() {
        this.border && (this.border = this.border.destroy()), this.eventsToUnbind.forEach(t => t());
      }
      setUpEvents() {}
    }
    return A;
  }), i(e, "Series/Map/MapSeries.js", [e["Core/Animation/AnimationUtilities.js"], e["Series/ColorMapComposition.js"], e["Series/CenteredUtilities.js"], e["Core/Globals.js"], e["Core/Chart/MapChart.js"], e["Series/Map/MapPoint.js"], e["Series/Map/MapSeriesDefaults.js"], e["Maps/MapView.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, a, n, l, h) {
    let {
        animObject: d,
        stop: c
      } = t,
      {
        noop: p
      } = s,
      {
        splitPath: u
      } = o,
      {
        column: g,
        scatter: f
      } = l.seriesTypes,
      {
        extend: m,
        find: x,
        fireEvent: y,
        getNestedProperty: b,
        isArray: v,
        defined: M,
        isNumber: C,
        isObject: S,
        merge: w,
        objectEach: k,
        pick: A,
        splat: T
      } = h;
    class P extends f {
      constructor() {
        super(...arguments), this.processedData = [];
      }
      animate(t) {
        let {
            chart: e,
            group: i
          } = this,
          s = d(this.options.animation);
        t ? i.attr({
          translateX: e.plotLeft + e.plotWidth / 2,
          translateY: e.plotTop + e.plotHeight / 2,
          scaleX: .001,
          scaleY: .001
        }) : i.animate({
          translateX: e.plotLeft,
          translateY: e.plotTop,
          scaleX: 1,
          scaleY: 1
        }, s);
      }
      clearBounds() {
        this.points.forEach(t => {
          delete t.bounds, delete t.insetIndex, delete t.projectedPath;
        }), delete this.bounds;
      }
      doFullTranslate() {
        return !!(this.isDirtyData || this.chart.isResizing || !this.hasRendered);
      }
      drawMapDataLabels() {
        super.drawDataLabels(), this.dataLabelsGroup && this.dataLabelsGroup.clip(this.chart.clipRect);
      }
      drawPoints() {
        let t = this,
          {
            chart: e,
            group: i,
            transformGroups: s = []
          } = this,
          {
            mapView: o,
            renderer: r
          } = e;
        if (o) {
          this.transformGroups = s, s[0] || (s[0] = r.g().add(i));
          for (let t = 0, e = o.insets.length; t < e; ++t) s[t + 1] || s.push(r.g().add(i));
          this.doFullTranslate() && (this.points.forEach(t => {
            let {
              graphic: e
            } = t;
            t.group = s["number" == typeof t.insetIndex ? t.insetIndex + 1 : 0], e && e.parentGroup !== t.group && e.add(t.group);
          }), g.prototype.drawPoints.apply(this), this.points.forEach(i => {
            let s = i.graphic;
            if (s) {
              let o = s.animate,
                r = "";
              i.name && (r += "highcharts-name-" + i.name.replace(/ /g, "-").toLowerCase()), i.properties && i.properties["hc-key"] && (r += " highcharts-key-" + i.properties["hc-key"].toString().toLowerCase()), r && s.addClass(r), e.styledMode && s.css(this.pointAttribs(i, i.selected && "select" || void 0)), s.attr({
                visibility: !i.visible && (i.visible || i.isNull) ? "hidden" : "inherit"
              }), s.animate = function (i, r, a) {
                let n = C(i["stroke-width"]) && !C(s["stroke-width"]),
                  l = C(s["stroke-width"]) && !C(i["stroke-width"]);
                if (n || l) {
                  let o = A(t.getStrokeWidth(t.options), 1) / (e.mapView && e.mapView.getScale() || 1);
                  n && (s["stroke-width"] = o), l && (i["stroke-width"] = o);
                }
                return o.call(s, i, r, l ? function () {
                  s.element.removeAttribute("stroke-width"), delete s["stroke-width"], a && a.apply(this, arguments);
                } : a);
              };
            }
          })), s.forEach((i, s) => {
            let a = (0 === s ? o : o.insets[s - 1]).getSVGTransform(),
              n = A(this.getStrokeWidth(this.options), 1),
              l = a.scaleX,
              h = a.scaleY > 0 ? 1 : -1,
              p = e => {
                (t.points || []).forEach(t => {
                  let i;
                  let s = t.graphic;
                  s && s["stroke-width"] && (i = this.getStrokeWidth(t.options)) && s.attr({
                    "stroke-width": i / e
                  });
                });
              };
            if (r.globalAnimation && e.hasRendered && o.allowTransformAnimation) {
              let t = Number(i.attr("translateX")),
                e = Number(i.attr("translateY")),
                s = Number(i.attr("scaleX")),
                o = (o, r) => {
                  let d = s + (l - s) * r.pos;
                  i.attr({
                    translateX: t + (a.translateX - t) * r.pos,
                    translateY: e + (a.translateY - e) * r.pos,
                    scaleX: d,
                    scaleY: d * h,
                    "stroke-width": n / d
                  }), p(d);
                },
                c = w(d(r.globalAnimation)),
                u = c.step;
              c.step = function () {
                u && u.apply(this, arguments), o.apply(this, arguments);
              }, i.attr({
                animator: 0
              }).animate({
                animator: 1
              }, c, function () {
                "boolean" != typeof r.globalAnimation && r.globalAnimation.complete && r.globalAnimation.complete({
                  applyDrilldown: !0
                }), y(this, "mapZoomComplete");
              }.bind(this));
            } else c(i), i.attr(w(a, {
              "stroke-width": n / l
            })), p(l);
          }), this.isDrilling || this.drawMapDataLabels();
        }
      }
      getProjectedBounds() {
        if (!this.bounds && this.chart.mapView) {
          let {
              insets: t,
              projection: e
            } = this.chart.mapView,
            i = [];
          (this.points || []).forEach(s => {
            if (s.path || s.geometry) {
              if ("string" == typeof s.path ? s.path = u(s.path) : v(s.path) && "M" === s.path[0] && (s.path = this.chart.renderer.pathToSegments(s.path)), !s.bounds) {
                let i = s.getProjectedBounds(e);
                if (i) {
                  s.labelrank = A(s.labelrank, (i.x2 - i.x1) * (i.y2 - i.y1));
                  let {
                    midX: e,
                    midY: o
                  } = i;
                  if (t && C(e) && C(o)) {
                    let r = x(t, t => t.isInside({
                      x: e,
                      y: o
                    }));
                    r && (delete s.projectedPath, (i = s.getProjectedBounds(r.projection)) && r.allBounds.push(i), s.insetIndex = t.indexOf(r));
                  }
                  s.bounds = i;
                }
              }
              s.bounds && void 0 === s.insetIndex && i.push(s.bounds);
            }
          }), this.bounds = n.compositeBounds(i);
        }
        return this.bounds;
      }
      getStrokeWidth(t) {
        let e = this.pointAttrToOptions;
        return t[e && e["stroke-width"] || "borderWidth"];
      }
      hasData() {
        return !!this.processedXData.length;
      }
      pointAttribs(t, e) {
        let {
            mapView: i,
            styledMode: s
          } = t.series.chart,
          o = s ? this.colorAttribs(t) : g.prototype.pointAttribs.call(this, t, e),
          r = this.getStrokeWidth(t.options);
        if (e) {
          let i = w(this.options.states && this.options.states[e], t.options.states && t.options.states[e] || {}),
            s = this.getStrokeWidth(i);
          M(s) && (r = s), o.stroke = i.borderColor ?? t.color;
        }
        r && i && (r /= i.getScale());
        let a = this.getStrokeWidth(this.options);
        return o.dashstyle && i && C(a) && (r = a / i.getScale()), t.visible || (o.fill = this.options.nullColor), M(r) ? o["stroke-width"] = r : delete o["stroke-width"], o["stroke-linecap"] = o["stroke-linejoin"] = this.options.linecap, o;
      }
      updateData() {
        return !this.processedData && super.updateData.apply(this, arguments);
      }
      setData(t, e = !0, i, s) {
        delete this.bounds, super.setData(t, !1, void 0, s), this.processData(), this.generatePoints(), e && this.chart.redraw(i);
      }
      processData() {
        let t, e, i;
        let o = this.options,
          a = o.data,
          n = this.chart,
          l = n.options.chart,
          h = this.joinBy,
          d = o.keys || this.pointArrayMap,
          c = [],
          p = {},
          u = this.chart.mapView,
          g = u && (S(o.mapData, !0) ? u.getGeoMap(o.mapData) : u.geoMap),
          f = n.mapTransforms = l.mapTransforms || g && g["hc-transform"] || n.mapTransforms;
        f && k(f, t => {
          t.rotation && (t.cosAngle = Math.cos(t.rotation), t.sinAngle = Math.sin(t.rotation));
        }), v(o.mapData) ? i = o.mapData : g && "FeatureCollection" === g.type && (this.mapTitle = g.title, i = s.geojson(g, this.type, this)), this.processedData = [];
        let m = this.processedData;
        if (a) {
          let t;
          for (let e = 0, i = a.length; e < i; ++e) {
            if (C(t = a[e])) m[e] = {
              value: t
            };else if (v(t)) {
              let i = 0;
              m[e] = {}, !o.keys && t.length > d.length && "string" == typeof t[0] && (m[e]["hc-key"] = t[0], ++i);
              for (let s = 0; s < d.length; ++s, ++i) d[s] && void 0 !== t[i] && (d[s].indexOf(".") > 0 ? r.prototype.setNestedProperty(m[e], t[i], d[s]) : m[e][d[s]] = t[i]);
            } else m[e] = a[e];
            h && "_i" === h[0] && (m[e]._i = e);
          }
        }
        if (i) {
          this.mapData = i, this.mapMap = {};
          for (let s = 0; s < i.length; s++) e = (t = i[s]).properties, t._i = s, h[0] && e && e[h[0]] && (t[h[0]] = e[h[0]]), p[t[h[0]]] = t;
          if (this.mapMap = p, h[1]) {
            let t = h[1];
            m.forEach(e => {
              let i = b(t, e);
              p[i] && c.push(p[i]);
            });
          }
          if (o.allAreas) {
            if (h[1]) {
              let t = h[1];
              m.forEach(e => {
                c.push(b(t, e));
              });
            }
            let t = "|" + c.map(function (t) {
              return t && t[h[0]];
            }).join("|") + "|";
            i.forEach(e => {
              h[0] && -1 !== t.indexOf("|" + e[h[0]] + "|") || m.push(w(e, {
                value: null
              }));
            });
          }
        }
        this.processedXData = Array(m.length);
      }
      setOptions(t) {
        let e = super.setOptions(t),
          i = e.joinBy;
        return null === e.joinBy && (i = "_i"), (i = this.joinBy = T(i))[1] || (i[1] = i[0]), e;
      }
      translate() {
        let t = this.doFullTranslate(),
          e = this.chart.mapView,
          i = e && e.projection;
        if (this.chart.hasRendered && (this.isDirtyData || !this.hasRendered) && (this.processData(), this.generatePoints(), delete this.bounds, !e || e.userOptions.center || C(e.userOptions.zoom) || e.zoom !== e.minZoom ? this.getProjectedBounds() : e.fitToBounds(void 0, void 0, !1)), e) {
          let s = e.getSVGTransform();
          this.points.forEach(o => {
            let a = C(o.insetIndex) && e.insets[o.insetIndex].getSVGTransform() || s;
            a && o.bounds && C(o.bounds.midX) && C(o.bounds.midY) && (o.plotX = o.bounds.midX * a.scaleX + a.translateX, o.plotY = o.bounds.midY * a.scaleY + a.translateY), t && (o.shapeType = "path", o.shapeArgs = {
              d: r.getProjectedPath(o, i)
            }), o.hiddenInDataClass || (o.projectedPath && !o.projectedPath.length ? o.setVisible(!1) : o.visible || o.setVisible(!0));
          });
        }
        y(this, "afterTranslate");
      }
      update(t) {
        t.mapData && this.chart.mapView?.recommendMapView(this.chart, [this.chart.options.chart.map, ...(this.chart.options.series || []).map((e, i) => i === this._i ? t.mapData : e.mapData)], !0), super.update.apply(this, arguments);
      }
    }
    return P.defaultOptions = w(f.defaultOptions, a), m(P.prototype, {
      type: "map",
      axisTypes: e.seriesMembers.axisTypes,
      colorAttribs: e.seriesMembers.colorAttribs,
      colorKey: e.seriesMembers.colorKey,
      directTouch: !0,
      drawDataLabels: p,
      drawGraph: p,
      forceDL: !0,
      getCenter: i.getCenter,
      getExtremesFromAll: !0,
      getSymbol: p,
      isCartesian: !1,
      parallelArrays: e.seriesMembers.parallelArrays,
      pointArrayMap: e.seriesMembers.pointArrayMap,
      pointClass: r,
      preserveAspectRatio: !0,
      searchPoint: p,
      trackerGroups: e.seriesMembers.trackerGroups,
      useMapGeometry: !0
    }), e.compose(P), l.registerSeriesType("map", P), P;
  }), i(e, "Series/MapLine/MapLineSeriesDefaults.js", [], function () {
    return {
      lineWidth: 1,
      fillColor: "none",
      legendSymbol: "lineMarker"
    };
  }), i(e, "Series/MapLine/MapLineSeries.js", [e["Series/MapLine/MapLineSeriesDefaults.js"], e["Series/Map/MapSeries.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
      extend: o,
      merge: r
    } = s;
    class a extends e {
      pointAttribs(t, e) {
        let i = super.pointAttribs(t, e);
        return i.fill = this.options.fillColor, i;
      }
    }
    return a.defaultOptions = r(e.defaultOptions, t), o(a.prototype, {
      type: "mapline",
      colorProp: "stroke",
      pointAttrToOptions: {
        stroke: "color",
        "stroke-width": "lineWidth"
      }
    }), i.registerSeriesType("mapline", a), a;
  }), i(e, "Series/MapPoint/MapPointPoint.js", [e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        scatter: i
      } = t.seriesTypes,
      {
        isNumber: s
      } = e;
    class o extends i.prototype.pointClass {
      isValid() {
        return !!(this.options.geometry || s(this.x) && s(this.y) || s(this.options.lon) && s(this.options.lat));
      }
    }
    return o;
  }), i(e, "Series/MapPoint/MapPointSeriesDefaults.js", [], function () {
    return {
      dataLabels: {
        crop: !1,
        defer: !1,
        enabled: !0,
        formatter: function () {
          return this.point.name;
        },
        overflow: !1,
        style: {
          color: "#000000"
        }
      },
      legendSymbol: "lineMarker"
    };
  }), i(e, "Series/MapPoint/MapPointSeries.js", [e["Core/Globals.js"], e["Series/MapPoint/MapPointPoint.js"], e["Series/MapPoint/MapPointSeriesDefaults.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Renderer/SVG/SVGRenderer.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r) {
    let {
        noop: a
      } = t,
      {
        map: n,
        scatter: l
      } = s.seriesTypes,
      {
        extend: h,
        fireEvent: d,
        isNumber: c,
        merge: p
      } = r;
    class u extends l {
      constructor() {
        super(...arguments), this.clearBounds = n.prototype.clearBounds;
      }
      drawDataLabels() {
        super.drawDataLabels(), this.dataLabelsGroup && this.dataLabelsGroup.clip(this.chart.clipRect);
      }
      projectPoint(t) {
        let e = this.chart.mapView;
        if (e) {
          let {
              geometry: i,
              lon: s,
              lat: o
            } = t,
            r = i && "Point" === i.type && i.coordinates;
          if (c(s) && c(o) && (r = [s, o]), r) return e.lonLatToProjectedUnits({
            lon: r[0],
            lat: r[1]
          });
        }
      }
      translate() {
        let t = this.chart.mapView;
        if (this.processedXData || this.processData(), this.generatePoints(), this.getProjectedBounds && this.isDirtyData && (delete this.bounds, this.getProjectedBounds()), t) {
          let e = t.getSVGTransform(),
            {
              hasCoordinates: i
            } = t.projection;
          this.points.forEach(s => {
            let o,
              {
                x: r,
                y: a
              } = s,
              n = c(s.insetIndex) && t.insets[s.insetIndex].getSVGTransform() || e,
              l = this.projectPoint(s.options) || s.properties && this.projectPoint(s.properties);
            if (l ? (r = l.x, a = l.y) : s.bounds && (r = s.bounds.midX, a = s.bounds.midY, n && c(r) && c(a) && (s.plotX = r * n.scaleX + n.translateX, s.plotY = a * n.scaleY + n.translateY, o = !0)), c(r) && c(a)) {
              if (!o) {
                let e = t.projectedUnitsToPixels({
                  x: r,
                  y: a
                });
                s.plotX = e.x, s.plotY = i ? e.y : this.chart.plotHeight - e.y;
              }
            } else s.y = s.plotX = s.plotY = void 0;
            s.isInside = this.isPointInside(s), s.zone = this.zones.length ? s.getZone() : void 0;
          });
        }
        d(this, "afterTranslate");
      }
    }
    return u.defaultOptions = p(l.defaultOptions, i), o.prototype.symbols.mapmarker = (t, e, i, s, o) => {
      let r, a;
      let n = o && "legend" === o.context;
      n ? (r = t + i / 2, a = e + s) : o && "number" == typeof o.anchorX && "number" == typeof o.anchorY ? (r = o.anchorX, a = o.anchorY) : (r = t + i / 2, a = e + s / 2, e -= s);
      let l = n ? s / 3 : s / 2;
      return [["M", r, a], ["C", r, a, r - l, e + 1.5 * l, r - l, e + l], ["A", l, l, 1, 1, 1, r + l, e + l], ["C", r + l, e + 1.5 * l, r, a, r, a], ["Z"]];
    }, h(u.prototype, {
      type: "mappoint",
      axisTypes: ["colorAxis"],
      forceDL: !0,
      isCartesian: !1,
      pointClass: e,
      searchPoint: a,
      useMapGeometry: !0
    }), s.registerSeriesType("mappoint", u), u;
  }), i(e, "Series/Bubble/BubbleLegendDefaults.js", [], function () {
    return {
      borderColor: void 0,
      borderWidth: 2,
      className: void 0,
      color: void 0,
      connectorClassName: void 0,
      connectorColor: void 0,
      connectorDistance: 60,
      connectorWidth: 1,
      enabled: !1,
      labels: {
        className: void 0,
        allowOverlap: !1,
        format: "",
        formatter: void 0,
        align: "right",
        style: {
          fontSize: "0.9em",
          color: "#000000"
        },
        x: 0,
        y: 0
      },
      maxSize: 60,
      minSize: 10,
      legendIndex: 0,
      ranges: {
        value: void 0,
        borderColor: void 0,
        color: void 0,
        connectorColor: void 0
      },
      sizeBy: "area",
      sizeByAbsoluteValue: !1,
      zIndex: 1,
      zThreshold: 0
    };
  }), i(e, "Series/Bubble/BubbleLegendItem.js", [e["Core/Color/Color.js"], e["Core/Templating.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
        parse: o
      } = t,
      {
        noop: r
      } = i,
      {
        arrayMax: a,
        arrayMin: n,
        isNumber: l,
        merge: h,
        pick: d,
        stableSort: c
      } = s;
    return class {
      constructor(t, e) {
        this.setState = r, this.init(t, e);
      }
      init(t, e) {
        this.options = t, this.visible = !0, this.chart = e.chart, this.legend = e;
      }
      addToLegend(t) {
        t.splice(this.options.legendIndex, 0, this);
      }
      drawLegendSymbol(t) {
        let e;
        let i = d(t.options.itemDistance, 20),
          s = this.legendItem || {},
          o = this.options,
          r = o.ranges,
          a = o.connectorDistance;
        if (!r || !r.length || !l(r[0].value)) {
          t.options.bubbleLegend.autoRanges = !0;
          return;
        }
        c(r, function (t, e) {
          return e.value - t.value;
        }), this.ranges = r, this.setOptions(), this.render();
        let n = this.getMaxLabelSize(),
          h = this.ranges[0].radius,
          p = 2 * h;
        e = (e = a - h + n.width) > 0 ? e : 0, this.maxLabel = n, this.movementX = "left" === o.labels.align ? e : 0, s.labelWidth = p + e + i, s.labelHeight = p + n.height / 2;
      }
      setOptions() {
        let t = this.ranges,
          e = this.options,
          i = this.chart.series[e.seriesIndex],
          s = this.legend.baseline,
          r = {
            zIndex: e.zIndex,
            "stroke-width": e.borderWidth
          },
          a = {
            zIndex: e.zIndex,
            "stroke-width": e.connectorWidth
          },
          n = {
            align: this.legend.options.rtl || "left" === e.labels.align ? "right" : "left",
            zIndex: e.zIndex
          },
          l = i.options.marker.fillOpacity,
          c = this.chart.styledMode;
        t.forEach(function (p, u) {
          c || (r.stroke = d(p.borderColor, e.borderColor, i.color), r.fill = d(p.color, e.color, 1 !== l ? o(i.color).setOpacity(l).get("rgba") : i.color), a.stroke = d(p.connectorColor, e.connectorColor, i.color)), t[u].radius = this.getRangeRadius(p.value), t[u] = h(t[u], {
            center: t[0].radius - t[u].radius + s
          }), c || h(!0, t[u], {
            bubbleAttribs: h(r),
            connectorAttribs: h(a),
            labelAttribs: n
          });
        }, this);
      }
      getRangeRadius(t) {
        let e = this.options,
          i = this.options.seriesIndex,
          s = this.chart.series[i],
          o = e.ranges[0].value,
          r = e.ranges[e.ranges.length - 1].value,
          a = e.minSize,
          n = e.maxSize;
        return s.getRadius.call(this, r, o, a, n, t);
      }
      render() {
        let t = this.legendItem || {},
          e = this.chart.renderer,
          i = this.options.zThreshold;
        for (let s of (this.symbols || (this.symbols = {
          connectors: [],
          bubbleItems: [],
          labels: []
        }), t.symbol = e.g("bubble-legend"), t.label = e.g("bubble-legend-item").css(this.legend.itemStyle || {}), t.symbol.translateX = 0, t.symbol.translateY = 0, t.symbol.add(t.label), t.label.add(t.group), this.ranges)) s.value >= i && this.renderRange(s);
        this.hideOverlappingLabels();
      }
      renderRange(t) {
        let e = this.ranges[0],
          i = this.legend,
          s = this.options,
          o = s.labels,
          r = this.chart,
          a = r.series[s.seriesIndex],
          n = r.renderer,
          l = this.symbols,
          h = l.labels,
          d = t.center,
          c = Math.abs(t.radius),
          p = s.connectorDistance || 0,
          u = o.align,
          g = i.options.rtl,
          f = s.borderWidth,
          m = s.connectorWidth,
          x = e.radius || 0,
          y = d - c - f / 2 + m / 2,
          b = (y % 1 ? 1 : .5) - (m % 2 ? 0 : .5),
          v = n.styledMode,
          M = g || "left" === u ? -p : p;
        "center" === u && (M = 0, s.connectorDistance = 0, t.labelAttribs.align = "center"), l.bubbleItems.push(n.circle(x, d + b, c).attr(v ? {} : t.bubbleAttribs).addClass((v ? "highcharts-color-" + a.colorIndex + " " : "") + "highcharts-bubble-legend-symbol " + (s.className || "")).add(this.legendItem.symbol)), l.connectors.push(n.path(n.crispLine([["M", x, y], ["L", x + M, y]], s.connectorWidth)).attr(v ? {} : t.connectorAttribs).addClass((v ? "highcharts-color-" + this.options.seriesIndex + " " : "") + "highcharts-bubble-legend-connectors " + (s.connectorClassName || "")).add(this.legendItem.symbol));
        let C = n.text(this.formatLabel(t)).attr(v ? {} : t.labelAttribs).css(v ? {} : o.style).addClass("highcharts-bubble-legend-labels " + (s.labels.className || "")).add(this.legendItem.symbol),
          S = {
            x: x + M + s.labels.x,
            y: y + s.labels.y + .4 * C.getBBox().height
          };
        C.attr(S), h.push(C), C.placed = !0, C.alignAttr = S;
      }
      getMaxLabelSize() {
        let t, e;
        return this.symbols.labels.forEach(function (i) {
          e = i.getBBox(!0), t = t ? e.width > t.width ? e : t : e;
        }), t || {};
      }
      formatLabel(t) {
        let i = this.options,
          s = i.labels.formatter,
          o = i.labels.format,
          {
            numberFormatter: r
          } = this.chart;
        return o ? e.format(o, t) : s ? s.call(t) : r(t.value, 1);
      }
      hideOverlappingLabels() {
        let t = this.chart,
          e = this.options.labels.allowOverlap,
          i = this.symbols;
        !e && i && (t.hideOverlappingLabels(i.labels), i.labels.forEach(function (t, e) {
          t.newOpacity ? t.newOpacity !== t.oldOpacity && i.connectors[e].show() : i.connectors[e].hide();
        }));
      }
      getRanges() {
        let t = this.legend.bubbleLegend,
          e = t.chart.series,
          i = t.options.ranges,
          s,
          o,
          r = Number.MAX_VALUE,
          c = -Number.MAX_VALUE;
        return e.forEach(function (t) {
          t.isBubble && !t.ignoreSeries && (o = t.zData.filter(l)).length && (r = d(t.options.zMin, Math.min(r, Math.max(n(o), !1 === t.options.displayNegative ? t.options.zThreshold : -Number.MAX_VALUE))), c = d(t.options.zMax, Math.max(c, a(o))));
        }), s = r === c ? [{
          value: c
        }] : [{
          value: r
        }, {
          value: (r + c) / 2
        }, {
          value: c,
          autoRanges: !0
        }], i.length && i[0].radius && s.reverse(), s.forEach(function (t, e) {
          i && i[e] && (s[e] = h(i[e], t));
        }), s;
      }
      predictBubbleSizes() {
        let t = this.chart,
          e = t.legend.options,
          i = e.floating,
          s = "horizontal" === e.layout,
          o = s ? t.legend.lastLineHeight : 0,
          r = t.plotSizeX,
          a = t.plotSizeY,
          n = t.series[this.options.seriesIndex],
          l = n.getPxExtremes(),
          h = Math.ceil(l.minPxSize),
          d = Math.ceil(l.maxPxSize),
          c = Math.min(a, r),
          p,
          u = n.options.maxSize;
        return i || !/%$/.test(u) ? p = d : (p = (c + o) * (u = parseFloat(u)) / 100 / (u / 100 + 1), (s && a - p >= r || !s && r - p >= a) && (p = d)), [h, Math.ceil(p)];
      }
      updateRanges(t, e) {
        let i = this.legend.options.bubbleLegend;
        i.minSize = t, i.maxSize = e, i.ranges = this.getRanges();
      }
      correctSizes() {
        let t = this.legend,
          e = this.chart.series[this.options.seriesIndex].getPxExtremes();
        Math.abs(Math.ceil(e.maxPxSize) - this.options.maxSize) > 1 && (this.updateRanges(this.options.minSize, e.maxPxSize), t.render());
      }
    };
  }), i(e, "Series/Bubble/BubbleLegendComposition.js", [e["Series/Bubble/BubbleLegendDefaults.js"], e["Series/Bubble/BubbleLegendItem.js"], e["Core/Defaults.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    let {
        setOptions: r
      } = i,
      {
        composed: a
      } = s,
      {
        addEvent: n,
        objectEach: l,
        pushUnique: h,
        wrap: d
      } = o;
    function c(t, e, i) {
      let s, o, r;
      let a = this.legend,
        n = p(this) >= 0;
      a && a.options.enabled && a.bubbleLegend && a.options.bubbleLegend.autoRanges && n ? (s = a.bubbleLegend.options, o = a.bubbleLegend.predictBubbleSizes(), a.bubbleLegend.updateRanges(o[0], o[1]), s.placed || (a.group.placed = !1, a.allItems.forEach(t => {
        (r = t.legendItem || {}).group && (r.group.translateY = void 0);
      })), a.render(), s.placed || (this.getMargins(), this.axes.forEach(function (t) {
        t.visible && t.render(), s.placed || (t.setScale(), t.updateNames(), l(t.ticks, function (t) {
          t.isNew = !0, t.isNewLabel = !0;
        }));
      }), this.getMargins()), s.placed = !0, t.call(this, e, i), a.bubbleLegend.correctSizes(), m(a, u(a))) : (t.call(this, e, i), a && a.options.enabled && a.bubbleLegend && (a.render(), m(a, u(a))));
    }
    function p(t) {
      let e = t.series,
        i = 0;
      for (; i < e.length;) {
        if (e[i] && e[i].isBubble && e[i].visible && e[i].zData.length) return i;
        i++;
      }
      return -1;
    }
    function u(t) {
      let e = t.allItems,
        i = [],
        s = e.length,
        o,
        r,
        a,
        n = 0,
        l = 0;
      for (n = 0; n < s; n++) if (r = e[n].legendItem || {}, a = (e[n + 1] || {}).legendItem || {}, r.labelHeight && (e[n].itemHeight = r.labelHeight), e[n] === e[s - 1] || r.y !== a.y) {
        for (i.push({
          height: 0
        }), o = i[i.length - 1]; l <= n; l++) e[l].itemHeight > o.height && (o.height = e[l].itemHeight);
        o.step = n;
      }
      return i;
    }
    function g(t) {
      let i = this.bubbleLegend,
        s = this.options,
        o = s.bubbleLegend,
        r = p(this.chart);
      i && i.ranges && i.ranges.length && (o.ranges.length && (o.autoRanges = !!o.ranges[0].autoRanges), this.destroyItem(i)), r >= 0 && s.enabled && o.enabled && (o.seriesIndex = r, this.bubbleLegend = new e(o, this), this.bubbleLegend.addToLegend(t.allItems));
    }
    function f(t) {
      let e;
      if (t.defaultPrevented) return !1;
      let i = t.legendItem,
        s = this.chart,
        o = i.visible;
      this && this.bubbleLegend && (i.visible = !o, i.ignoreSeries = o, e = p(s) >= 0, this.bubbleLegend.visible !== e && (this.update({
        bubbleLegend: {
          enabled: e
        }
      }), this.bubbleLegend.visible = e), i.visible = o);
    }
    function m(t, e) {
      let i = t.allItems,
        s = t.options.rtl,
        o,
        r,
        a,
        n,
        l = 0;
      i.forEach((t, i) => {
        (n = t.legendItem || {}).group && (o = n.group.translateX || 0, r = n.y || 0, ((a = t.movementX) || s && t.ranges) && (a = s ? o - t.options.maxSize / 2 : o + a, n.group.attr({
          translateX: a
        })), i > e[l].step && l++, n.group.attr({
          translateY: Math.round(r + e[l].height / 2)
        }), n.y = r + e[l].height / 2);
      });
    }
    return {
      compose: function (e, i) {
        h(a, "Series.BubbleLegend") && (r({
          legend: {
            bubbleLegend: t
          }
        }), d(e.prototype, "drawChartBox", c), n(i, "afterGetAllItems", g), n(i, "itemClick", f));
      }
    };
  }), i(e, "Series/Bubble/BubblePoint.js", [e["Core/Series/Point.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        seriesTypes: {
          scatter: {
            prototype: {
              pointClass: s
            }
          }
        }
      } = e,
      {
        extend: o
      } = i;
    class r extends s {
      haloPath(e) {
        let i = (e && this.marker && this.marker.radius || 0) + e;
        if (this.series.chart.inverted) {
          let t = this.pos() || [0, 0],
            {
              xAxis: e,
              yAxis: s,
              chart: o
            } = this.series;
          return o.renderer.symbols.circle(e.len - t[1] - i, s.len - t[0] - i, 2 * i, 2 * i);
        }
        return t.prototype.haloPath.call(this, i);
      }
    }
    return o(r.prototype, {
      ttBelow: !1
    }), r;
  }), i(e, "Series/Bubble/BubbleSeries.js", [e["Series/Bubble/BubbleLegendComposition.js"], e["Series/Bubble/BubblePoint.js"], e["Core/Color/Color.js"], e["Core/Globals.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r) {
    let {
        parse: a
      } = i,
      {
        composed: n,
        noop: l
      } = s,
      {
        series: h,
        seriesTypes: {
          column: {
            prototype: d
          },
          scatter: c
        }
      } = o,
      {
        addEvent: p,
        arrayMax: u,
        arrayMin: g,
        clamp: f,
        extend: m,
        isNumber: x,
        merge: y,
        pick: b,
        pushUnique: v
      } = r;
    function M() {
      let t = this.len,
        {
          coll: e,
          isXAxis: i,
          min: s
        } = this,
        o = i ? "xData" : "yData",
        r = (this.max || 0) - (s || 0),
        a = 0,
        n = t,
        l = t / r,
        h;
      ("xAxis" === e || "yAxis" === e) && (this.series.forEach(t => {
        if (t.bubblePadding && t.reserveSpace()) {
          this.allowZoomOutside = !0, h = !0;
          let e = t[o];
          if (i && ((t.onPoint || t).getRadii(0, 0, t), t.onPoint && (t.radii = t.onPoint.radii)), r > 0) {
            let i = e.length;
            for (; i--;) if (x(e[i]) && this.dataMin <= e[i] && e[i] <= this.max) {
              let o = t.radii && t.radii[i] || 0;
              a = Math.min((e[i] - s) * l - o, a), n = Math.max((e[i] - s) * l + o, n);
            }
          }
        }
      }), h && r > 0 && !this.logarithmic && (n -= t, l *= (t + Math.max(0, a) - Math.min(n, t)) / t, [["min", "userMin", a], ["max", "userMax", n]].forEach(t => {
        void 0 === b(this.options[t[0]], this[t[1]]) && (this[t[0]] += t[2] / l);
      })));
    }
    class C extends c {
      static compose(e, i, s) {
        t.compose(i, s), v(n, "Series.Bubble") && p(e, "foundExtremes", M);
      }
      animate(t) {
        !t && this.points.length < this.options.animationLimit && this.points.forEach(function (t) {
          let {
            graphic: e,
            plotX: i = 0,
            plotY: s = 0
          } = t;
          e && e.width && (this.hasRendered || e.attr({
            x: i,
            y: s,
            width: 1,
            height: 1
          }), e.animate(this.markerAttribs(t), this.options.animation));
        }, this);
      }
      getRadii() {
        let t = this.zData,
          e = this.yData,
          i = [],
          s,
          o,
          r,
          a = this.chart.bubbleZExtremes,
          {
            minPxSize: n,
            maxPxSize: l
          } = this.getPxExtremes();
        if (!a) {
          let t,
            e = Number.MAX_VALUE,
            i = -Number.MAX_VALUE;
          this.chart.series.forEach(s => {
            if (s.bubblePadding && s.reserveSpace()) {
              let o = (s.onPoint || s).getZExtremes();
              o && (e = Math.min(b(e, o.zMin), o.zMin), i = Math.max(b(i, o.zMax), o.zMax), t = !0);
            }
          }), t ? (a = {
            zMin: e,
            zMax: i
          }, this.chart.bubbleZExtremes = a) : a = {
            zMin: 0,
            zMax: 0
          };
        }
        for (o = 0, s = t.length; o < s; o++) r = t[o], i.push(this.getRadius(a.zMin, a.zMax, n, l, r, e && e[o]));
        this.radii = i;
      }
      getRadius(t, e, i, s, o, r) {
        let a = this.options,
          n = "width" !== a.sizeBy,
          l = a.zThreshold,
          h = e - t,
          d = .5;
        if (null === r || null === o) return null;
        if (x(o)) {
          if (a.sizeByAbsoluteValue && (o = Math.abs(o - l), e = h = Math.max(e - l, Math.abs(t - l)), t = 0), o < t) return i / 2 - 1;
          h > 0 && (d = (o - t) / h);
        }
        return n && d >= 0 && (d = Math.sqrt(d)), Math.ceil(i + d * (s - i)) / 2;
      }
      hasData() {
        return !!this.processedXData.length;
      }
      markerAttribs(t, e) {
        let i = super.markerAttribs(t, e),
          {
            height: s = 0,
            width: o = 0
          } = i;
        return this.chart.inverted ? m(i, {
          x: (t.plotX || 0) - o / 2,
          y: (t.plotY || 0) - s / 2
        }) : i;
      }
      pointAttribs(t, e) {
        let i = this.options.marker.fillOpacity,
          s = h.prototype.pointAttribs.call(this, t, e);
        return 1 !== i && (s.fill = a(s.fill).setOpacity(i).get("rgba")), s;
      }
      translate() {
        super.translate.call(this), this.getRadii(), this.translateBubble();
      }
      translateBubble() {
        let {
            data: t,
            options: e,
            radii: i
          } = this,
          {
            minPxSize: s
          } = this.getPxExtremes(),
          o = t.length;
        for (; o--;) {
          let r = t[o],
            a = i ? i[o] : 0;
          "z" === this.zoneAxis && (r.negative = (r.z || 0) < (e.zThreshold || 0)), x(a) && a >= s / 2 ? (r.marker = m(r.marker, {
            radius: a,
            width: 2 * a,
            height: 2 * a
          }), r.dlBox = {
            x: r.plotX - a,
            y: r.plotY - a,
            width: 2 * a,
            height: 2 * a
          }) : (r.shapeArgs = r.plotY = r.dlBox = void 0, r.isInside = !1);
        }
      }
      getPxExtremes() {
        let t = Math.min(this.chart.plotWidth, this.chart.plotHeight),
          e = e => {
            let i;
            return "string" == typeof e && (i = /%$/.test(e), e = parseInt(e, 10)), i ? t * e / 100 : e;
          },
          i = e(b(this.options.minSize, 8)),
          s = Math.max(e(b(this.options.maxSize, "20%")), i);
        return {
          minPxSize: i,
          maxPxSize: s
        };
      }
      getZExtremes() {
        let t = this.options,
          e = (this.zData || []).filter(x);
        if (e.length) {
          let i = b(t.zMin, f(g(e), !1 === t.displayNegative ? t.zThreshold || 0 : -Number.MAX_VALUE, Number.MAX_VALUE)),
            s = b(t.zMax, u(e));
          if (x(i) && x(s)) return {
            zMin: i,
            zMax: s
          };
        }
      }
    }
    return C.defaultOptions = y(c.defaultOptions, {
      dataLabels: {
        formatter: function () {
          let {
              numberFormatter: t
            } = this.series.chart,
            {
              z: e
            } = this.point;
          return x(e) ? t(e, -1) : "";
        },
        inside: !0,
        verticalAlign: "middle"
      },
      animationLimit: 250,
      marker: {
        lineColor: null,
        lineWidth: 1,
        fillOpacity: .5,
        radius: null,
        states: {
          hover: {
            radiusPlus: 0
          }
        },
        symbol: "circle"
      },
      minSize: 8,
      maxSize: "20%",
      softThreshold: !1,
      states: {
        hover: {
          halo: {
            size: 5
          }
        }
      },
      tooltip: {
        pointFormat: "({point.x}, {point.y}), Size: {point.z}"
      },
      turboThreshold: 0,
      zThreshold: 0,
      zoneAxis: "z"
    }), m(C.prototype, {
      alignDataLabel: d.alignDataLabel,
      applyZones: l,
      bubblePadding: !0,
      isBubble: !0,
      pointArrayMap: ["y", "z"],
      pointClass: e,
      parallelArrays: ["x", "y", "z"],
      trackerGroups: ["group", "dataLabelsGroup"],
      specialGroup: "group",
      zoneAxis: "z"
    }), p(C, "updatedData", t => {
      delete t.target.chart.bubbleZExtremes;
    }), p(C, "remove", t => {
      delete t.target.chart.bubbleZExtremes;
    }), o.registerSeriesType("bubble", C), C;
  }), i(e, "Series/MapBubble/MapBubblePoint.js", [e["Series/Bubble/BubblePoint.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i) {
    let {
        seriesTypes: {
          map: {
            prototype: {
              pointClass: {
                prototype: s
              }
            }
          }
        }
      } = e,
      {
        extend: o
      } = i;
    class r extends t {
      isValid() {
        return "number" == typeof this.z;
      }
    }
    return o(r.prototype, {
      applyOptions: s.applyOptions,
      getProjectedBounds: s.getProjectedBounds
    }), r;
  }), i(e, "Series/MapBubble/MapBubbleSeries.js", [e["Series/Bubble/BubbleSeries.js"], e["Series/MapBubble/MapBubblePoint.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let {
        seriesTypes: {
          map: {
            prototype: o
          },
          mappoint: {
            prototype: r
          }
        }
      } = i,
      {
        extend: a,
        merge: n
      } = s;
    class l extends t {
      constructor() {
        super(...arguments), this.clearBounds = o.clearBounds;
      }
      searchPoint(t, e) {
        return this.searchKDTree({
          plotX: t.chartX - this.chart.plotLeft,
          plotY: t.chartY - this.chart.plotTop
        }, e, t);
      }
      translate() {
        r.translate.call(this), this.getRadii(), this.translateBubble();
      }
      updateParallelArrays(t, e, i) {
        super.updateParallelArrays.call(this, t, e, i);
        let s = this.processedXData,
          o = this.xData;
        s && o && (s.length = o.length);
      }
    }
    return l.defaultOptions = n(t.defaultOptions, {
      lineWidth: 0,
      animationLimit: 500,
      joinBy: "hc-key",
      tooltip: {
        pointFormat: "{point.name}: {point.z}"
      }
    }), a(l.prototype, {
      type: "mapbubble",
      axisTypes: ["colorAxis"],
      getProjectedBounds: o.getProjectedBounds,
      isCartesian: !1,
      pointArrayMap: ["z"],
      pointClass: e,
      processData: o.processData,
      projectPoint: r.projectPoint,
      kdAxisArray: ["plotX", "plotY"],
      setData: o.setData,
      setOptions: o.setOptions,
      updateData: o.updateData,
      useMapGeometry: !0,
      xyFromShape: !0
    }), i.registerSeriesType("mapbubble", l), l;
  }), i(e, "Series/Heatmap/HeatmapPoint.js", [e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        scatter: {
          prototype: {
            pointClass: i
          }
        }
      } = t.seriesTypes,
      {
        clamp: s,
        defined: o,
        extend: r,
        pick: a
      } = e;
    class n extends i {
      applyOptions(t, e) {
        return (this.isNull || null === this.value) && delete this.color, super.applyOptions(t, e), this.formatPrefix = this.isNull || null === this.value ? "null" : "point", this;
      }
      getCellAttributes() {
        let t = this.series,
          e = t.options,
          i = (e.colsize || 1) / 2,
          r = (e.rowsize || 1) / 2,
          n = t.xAxis,
          l = t.yAxis,
          h = this.options.marker || t.options.marker,
          d = t.pointPlacementToXValue(),
          c = a(this.pointPadding, e.pointPadding, 0),
          p = {
            x1: s(Math.round(n.len - n.translate(this.x - i, !1, !0, !1, !0, -d)), -n.len, 2 * n.len),
            x2: s(Math.round(n.len - n.translate(this.x + i, !1, !0, !1, !0, -d)), -n.len, 2 * n.len),
            y1: s(Math.round(l.translate(this.y - r, !1, !0, !1, !0)), -l.len, 2 * l.len),
            y2: s(Math.round(l.translate(this.y + r, !1, !0, !1, !0)), -l.len, 2 * l.len)
          };
        for (let t of [["width", "x"], ["height", "y"]]) {
          let e = t[0],
            i = t[1],
            s = i + "1",
            r = i + "2",
            a = Math.abs(p[s] - p[r]),
            d = h && h.lineWidth || 0,
            u = Math.abs(p[s] + p[r]) / 2,
            g = h && h[e];
          if (o(g) && g < a) {
            let t = g / 2 + d / 2;
            p[s] = u - t, p[r] = u + t;
          }
          c && (("x" === i && n.reversed || "y" === i && !l.reversed) && (s = r, r = i + "1"), p[s] += c, p[r] -= c);
        }
        return p;
      }
      haloPath(t) {
        if (!t) return [];
        let {
          x: e = 0,
          y: i = 0,
          width: s = 0,
          height: o = 0
        } = this.shapeArgs || {};
        return [["M", e - t, i - t], ["L", e - t, i + o + t], ["L", e + s + t, i + o + t], ["L", e + s + t, i - t], ["Z"]];
      }
      isValid() {
        return this.value !== 1 / 0 && this.value !== -1 / 0;
      }
    }
    return r(n.prototype, {
      dataLabelOnNull: !0,
      moveToTopOnHover: !0,
      ttBelow: !1
    }), n;
  }), i(e, "Series/Heatmap/HeatmapSeriesDefaults.js", [e["Core/Utilities.js"]], function (t) {
    let {
      isNumber: e
    } = t;
    return {
      animation: !1,
      borderRadius: 0,
      borderWidth: 0,
      interpolation: !1,
      nullColor: "#f7f7f7",
      dataLabels: {
        formatter: function () {
          let {
              numberFormatter: t
            } = this.series.chart,
            {
              value: i
            } = this.point;
          return e(i) ? t(i, -1) : "";
        },
        inside: !0,
        verticalAlign: "middle",
        crop: !1,
        overflow: "allow",
        padding: 0
      },
      marker: {
        symbol: "rect",
        radius: 0,
        lineColor: void 0,
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {}
        }
      },
      clip: !0,
      pointRange: null,
      tooltip: {
        pointFormat: "{point.x}, {point.y}: {point.value}<br/>"
      },
      states: {
        hover: {
          halo: !1,
          brightness: .2
        }
      },
      legendSymbol: "rectangle"
    };
  }), i(e, "Series/InterpolationUtilities.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        doc: i
      } = t,
      {
        defined: s,
        pick: o
      } = e;
    return {
      colorFromPoint: function (t, e) {
        let i = e.series.colorAxis;
        if (i) {
          let r = i.toColor(t || 0, e).split(")")[0].split("(")[1].split(",").map(t => o(parseFloat(t), parseInt(t, 10)));
          return r[3] = 255 * o(r[3], 1), s(t) && e.visible || (r[3] = 0), r;
        }
        return [0, 0, 0, 0];
      },
      getContext: function (t) {
        let {
          canvas: e,
          context: s
        } = t;
        return e && s ? (s.clearRect(0, 0, e.width, e.height), s) : (t.canvas = i.createElement("canvas"), t.context = t.canvas.getContext("2d", {
          willReadFrequently: !0
        }) || void 0, t.context);
      }
    };
  }), i(e, "Series/Heatmap/HeatmapSeries.js", [e["Core/Color/Color.js"], e["Series/ColorMapComposition.js"], e["Series/Heatmap/HeatmapPoint.js"], e["Series/Heatmap/HeatmapSeriesDefaults.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Renderer/SVG/SVGRenderer.js"], e["Core/Utilities.js"], e["Series/InterpolationUtilities.js"]], function (t, e, i, s, o, r, a, n) {
    let {
        series: l,
        seriesTypes: {
          column: h,
          scatter: d
        }
      } = o,
      {
        prototype: {
          symbols: c
        }
      } = r,
      {
        addEvent: p,
        extend: u,
        fireEvent: g,
        isNumber: f,
        merge: m,
        pick: x
      } = a,
      {
        colorFromPoint: y,
        getContext: b
      } = n;
    class v extends d {
      constructor() {
        super(...arguments), this.valueMax = NaN, this.valueMin = NaN, this.isDirtyCanvas = !0;
      }
      drawPoints() {
        let t = this,
          e = t.options,
          i = e.interpolation,
          s = e.marker || {};
        if (i) {
          let {
              image: e,
              chart: i,
              xAxis: s,
              yAxis: o
            } = t,
            {
              reversed: r = !1,
              len: a
            } = s,
            {
              reversed: n = !1,
              len: l
            } = o,
            h = {
              width: a,
              height: l
            };
          if (!e || t.isDirtyData || t.isDirtyCanvas) {
            let a = b(t),
              {
                canvas: l,
                options: {
                  colsize: d = 1,
                  rowsize: c = 1
                },
                points: p,
                points: {
                  length: u
                }
              } = t,
              g = i.colorAxis && i.colorAxis[0];
            if (l && a && g) {
              let {
                  min: g,
                  max: f
                } = s.getExtremes(),
                {
                  min: m,
                  max: x
                } = o.getExtremes(),
                b = f - g,
                v = x - m,
                M = Math.round(b / d / 8 * 8),
                C = Math.round(v / c / 8 * 8),
                [S, w] = [[M, M / b, r, "ceil"], [C, C / v, !n, "floor"]].map(([t, e, i, s]) => i ? i => Math[s](t - e * i) : t => Math[s](e * t)),
                k = l.width = M + 1,
                A = k * (l.height = C + 1),
                T = (u - 1) / A,
                P = new Uint8ClampedArray(4 * A),
                L = (t, e) => 4 * Math.ceil(k * w(e - m) + S(t - g));
              t.buildKDTree();
              for (let t = 0; t < A; t++) {
                let e = p[Math.ceil(T * t)],
                  {
                    x: i,
                    y: s
                  } = e;
                P.set(y(e.value, e), L(i, s));
              }
              a.putImageData(new ImageData(P, k), 0, 0), e ? e.attr({
                ...h,
                href: l.toDataURL("image/png", 1)
              }) : (t.directTouch = !1, t.image = i.renderer.image(l.toDataURL("image/png", 1)).attr(h).add(t.group));
            }
            t.isDirtyCanvas = !1;
          } else (e.width !== a || e.height !== l) && e.attr(h);
        } else (s.enabled || t._hasPointMarkers) && (l.prototype.drawPoints.call(t), t.points.forEach(e => {
          e.graphic && (e.graphic[t.chart.styledMode ? "css" : "animate"](t.colorAttribs(e)), null === e.value && e.graphic.addClass("highcharts-null-point"));
        }));
      }
      getExtremes() {
        let {
          dataMin: t,
          dataMax: e
        } = l.prototype.getExtremes.call(this, this.valueData);
        return f(t) && (this.valueMin = t), f(e) && (this.valueMax = e), l.prototype.getExtremes.call(this);
      }
      getValidPoints(t, e) {
        return l.prototype.getValidPoints.call(this, t, e, !0);
      }
      hasData() {
        return !!this.xData;
      }
      init() {
        super.init.apply(this, arguments);
        let t = this.options;
        t.pointRange = x(t.pointRange, t.colsize || 1), this.yAxis.axisPointRange = t.rowsize || 1, c.ellipse = c.circle, t.marker && f(t.borderRadius) && (t.marker.r = t.borderRadius);
      }
      markerAttribs(t, e) {
        let i = t.shapeArgs || {};
        if (t.hasImage) return {
          x: t.plotX,
          y: t.plotY
        };
        if (e && "normal" !== e) {
          let s = t.options.marker || {},
            o = this.options.marker || {},
            r = o.states && o.states[e] || {},
            a = s.states && s.states[e] || {},
            n = (a.width || r.width || i.width || 0) + (a.widthPlus || r.widthPlus || 0),
            l = (a.height || r.height || i.height || 0) + (a.heightPlus || r.heightPlus || 0);
          return {
            x: (i.x || 0) + ((i.width || 0) - n) / 2,
            y: (i.y || 0) + ((i.height || 0) - l) / 2,
            width: n,
            height: l
          };
        }
        return i;
      }
      pointAttribs(e, i) {
        let s = l.prototype.pointAttribs.call(this, e, i),
          o = this.options || {},
          r = this.chart.options.plotOptions || {},
          a = r.series || {},
          n = r.heatmap || {},
          h = e && e.options.borderColor || o.borderColor || n.borderColor || a.borderColor,
          d = e && e.options.borderWidth || o.borderWidth || n.borderWidth || a.borderWidth || s["stroke-width"];
        if (s.stroke = e && e.marker && e.marker.lineColor || o.marker && o.marker.lineColor || h || this.color, s["stroke-width"] = d, i && "normal" !== i) {
          let r = m(o.states && o.states[i], o.marker && o.marker.states && o.marker.states[i], e && e.options.states && e.options.states[i] || {});
          s.fill = r.color || t.parse(s.fill).brighten(r.brightness || 0).get(), s.stroke = r.lineColor || s.stroke;
        }
        return s;
      }
      translate() {
        let {
            borderRadius: t,
            marker: e
          } = this.options,
          i = e && e.symbol || "rect",
          s = c[i] ? i : "rect",
          o = -1 !== ["circle", "square"].indexOf(s);
        if (!this.processedXData) {
          let {
            xData: t,
            yData: e
          } = this.getProcessedData();
          this.processedXData = t, this.processedYData = e;
        }
        for (let e of (this.generatePoints(), this.points)) {
          let r = e.getCellAttributes(),
            a = Math.min(r.x1, r.x2),
            n = Math.min(r.y1, r.y2),
            l = Math.max(Math.abs(r.x2 - r.x1), 0),
            h = Math.max(Math.abs(r.y2 - r.y1), 0);
          if (e.hasImage = 0 === (e.marker && e.marker.symbol || i || "").indexOf("url"), o) {
            let t = Math.abs(l - h);
            a = Math.min(r.x1, r.x2) + (l < h ? 0 : t / 2), n = Math.min(r.y1, r.y2) + (l < h ? t / 2 : 0), l = h = Math.min(l, h);
          }
          e.hasImage && (e.marker = {
            width: l,
            height: h
          }), e.plotX = e.clientX = (r.x1 + r.x2) / 2, e.plotY = (r.y1 + r.y2) / 2, e.shapeType = "path", e.shapeArgs = m(!0, {
            x: a,
            y: n,
            width: l,
            height: h
          }, {
            d: c[s](a, n, l, h, {
              r: f(t) ? t : 0
            })
          });
        }
        g(this, "afterTranslate");
      }
    }
    return v.defaultOptions = m(d.defaultOptions, s), p(v, "afterDataClassLegendClick", function () {
      this.isDirtyCanvas = !0, this.drawPoints();
    }), u(v.prototype, {
      axisTypes: e.seriesMembers.axisTypes,
      colorKey: e.seriesMembers.colorKey,
      directTouch: !0,
      getExtremesFromAll: !0,
      parallelArrays: e.seriesMembers.parallelArrays,
      pointArrayMap: ["y", "value"],
      pointClass: i,
      specialGroup: "group",
      trackerGroups: e.seriesMembers.trackerGroups,
      alignDataLabel: h.prototype.alignDataLabel,
      colorAttribs: e.seriesMembers.colorAttribs,
      getSymbol: l.prototype.getSymbol
    }), e.compose(v), o.registerSeriesType("heatmap", v), v;
  }), i(e, "masters/modules/map.src.js", [e["Core/Globals.js"], e["Maps/MapNavigation.js"], e["Series/ColorMapComposition.js"], e["Series/MapBubble/MapBubbleSeries.js"], e["Maps/GeoJSONComposition.js"], e["Core/Chart/MapChart.js"], e["Maps/MapView.js"], e["Maps/Projection.js"]], function (t, e, i, s, o, r, a, n) {
    return t.ColorMapComposition = i, t.MapChart = t.MapChart || r, t.MapNavigation = t.MapNavigation || e, t.MapView = t.MapView || a, t.Projection = t.Projection || n, t.mapChart = t.Map = t.MapChart.mapChart, t.maps = t.MapChart.maps, t.geojson = o.geojson, t.topo2geo = o.topo2geo, o.compose(t.Chart), s.compose(t.Axis, t.Chart, t.Legend), e.compose(r, t.Pointer, t.SVGRenderer), a.compose(r), t;
  }), i(e, "masters/highmaps.src.js", [e["masters/highcharts.src.js"]], function (t) {
    return t.product = "Highmaps", t;
  }), e["masters/highmaps.src.js"]._modules = e, e["masters/highmaps.src.js"];
});