import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Ooo } from '../models/ooo';



@Injectable({
  providedIn: 'root'
})
export class OooService extends ApiService {
  private readonly platformUrl: string;
  public ooo: Ooo;

  private readonly oooChangedSource = new Subject<any>();
  oooChanged$ = this.oooChangedSource.asObservable();

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public translateService: TranslateService,
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getOutOfOffice(hcpUid: string): Observable<any> {
    return new Observable(observer => {
      // temp fallback:
      observer.next(new Ooo({
        enabled: false
      }));
      observer.complete();

      /*
      const url = `${this.platformUrl}/hcps/${hcpUid}/out-of-office`;
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapOoo(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
      */
    });
  }

  update(hcpUid: string, data): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${hcpUid}/out-of-office`;
      this.authenticatedPut(url, data).subscribe(result => {
        this.oooChangedSource.next(data);
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  mapOoo(item) {
    return new Ooo(item);
  }
}
