<div class="modal-body">
  <button type="button" class="close" id="delete-note-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  <div>
    <h2>{{ 'modals.delete_note.title' | translate }}</h2>
  </div>
  <form [formGroup]="form">
    <app-select
      id="select_reason"
      [placeholder]="('form.labels.reason' | translate)"
      [clearable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="reasonOptions"
      [bindValue]="'value'"

      [autoSortOnLabel]="true"
      [selectedItemsOnTop]="false"

      formControlName="reason"

      [asFilter]="false"
      [showCheckboxes]="false"
    ></app-select>
  </form>
</div>
<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="delete-note-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" id="delete-note-modal-delete" (click)="handleDelete()" [ngClass]="{'loader': isLoading}" [disabled]="!form.valid">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'modals.delete_note.delete_note' | translate }}</span>
    </button>
  </div>
</div>