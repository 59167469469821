<div class="layout-header-pusher header-filters"></div>

<div class="layout-header header-filters">
  <div class="header-content">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">
          <h1>{{ 'pages.default.calendar.calendar' | translate }}</h1>
        </div>
        <div class="col col-auto">

          <button href="" class="btn btn-sm btn-secondary" id="add-appointment" (click)="addAppointment($event)">{{ 'pages.default.calendar.add_new_appointment' | translate }}</button>

          <button class="btn btn-sm btn-muted with-badge m-start-1" id="toggle-pending-invitations" (click)="togglePendingInvitations($event)" *ngIf="pendingAppointments?.length">
            <span [inlineSVG]="'/assets/svg/mail.svg'"></span>
          </button>

          <app-calendar-pending-invitations *ngIf="showPendingInvitations" (clickOutside)="clickOutsidePendingInvitations()" [delayClickOutsideInit]="'true'" [appointments]="pendingAppointments" (onAppointmentSelect)="openPendingAppointment($event)"></app-calendar-pending-invitations>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col col-auto">

          <div class="filters-group">

            <div class="custom-scope-range" *ngIf="currentView == 'year'">
              <button class="previous" id="calendar-prev-year" (click)="prevYear()">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
              </button>
              <span class="label">{{ currentYear | translateNumber }}</span>
              <button class="next" id="calendar-next-year" (click)="nextYear()">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
              </button>
            </div>

            <div class="custom-scope-range" *ngIf="currentView == 'week'">
              <button class="previous" id="calendar-prev-week" (click)="prevWeek()">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
              </button>
              <span class="label">

                {{ currentWeekFirstDay?.format('D') }}

                <ng-container *ngIf="currentWeekFirstDay?.format('MMMM') != currentWeekLastDay?.format('MMMM')">
                  {{ currentWeekFirstDay?.format('MMMM') }}
                </ng-container>

                <ng-container *ngIf="currentWeekFirstDay?.format('YYYY') != currentWeekLastDay?.format('YYYY')">
                  {{ currentWeekFirstDay?.format('YYYY') }}
                </ng-container>

                <ng-container>&nbsp;-&nbsp;</ng-container>

                <ng-container>{{ currentWeekLastDay?.format('D') }}&nbsp;</ng-container>
                <ng-container>{{ currentWeekLastDay?.format('MMMM') }}&nbsp; </ng-container>
                <ng-container>{{ currentWeekLastDay?.format('YYYY') }}</ng-container>

              </span>
              <button class="next" id="calendar-next-week" (click)="nextWeek()">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
              </button>
            </div>

            <div class="custom-scope-range" *ngIf="currentView == 'month'">
              <button class="previous" id="calendar-prev-month" (click)= "prevMonth()">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
              </button>
              <span class="label">{{ currentMonthFirstDay?.format('MMMM') }} {{ currentMonthFirstDay?.format('YYYY') }}</span>
              <button class="next" id="calendar-next-month" (click)= "nextMonth()">
                <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
                <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
              </button>
            </div>

            <div class="divider"></div>

            <app-select
              id="calendar-select-patient"
              [placeholder]="'pages.default.calendar.patient' | translate"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="patients"
              [searchFn]="customSearchFn"

              [(ngModel)]="patientFilter"

              (ngModelChange)="setFilter()"
              (search)="searchPatientLastNameEvent.next($event)"

              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
            ></app-select>

            <div class="divider"></div>

            <app-select
              id="calendar-select-hcp"
              [placeholder]="'pages.default.calendar.hcp' | translate"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="hcps"
              [searchFn]="customSearchFn"

              [(ngModel)]="hcpFilter"

              (ngModelChange)="setFilter()"
              (search)="searchHcpLastNameEvent.next($event)"

              [asFilter]="true"
              [showUserDetails]="true"
              [showCheckboxes]="false"
            ></app-select>

            <div class="divider" *ngIf="filtersAreSet"></div>

            <button id="reset-filters" type="button" class="btn btn-link text-info" *ngIf="filtersAreSet" (click)="resetFilters()">{{ 'action.reset' | translate }}</button>
          </div>

        </div>
        <div class="col col-auto">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-sm" id="calendar-change-view-to-year" [ngClass]="(currentView == 'year') ? 'btn-primary' : 'btn-muted'" (click)="changeViewTo('year')">{{ 'pages.default.calendar.list' | translate }}</button>
            <button type="button" class="btn btn-sm" id="calendar-change-view-to-week" [ngClass]="(currentView == 'week') ? 'btn-primary' : 'btn-muted'" (click)="changeViewTo('week')">{{ 'pages.default.calendar.week' | translate }}</button>
            <button type="button" class="btn btn-sm" id="calendar-change-view-to-month" [ngClass]="(currentView == 'month') ? 'btn-primary' : 'btn-muted'" (click)="changeViewTo('month')">{{ 'pages.default.calendar.month' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loader start -->

<!--
<div class="body-content">
  <div class="container">
    <app-page-loader></app-page-loader>
  </div>
</div>
-->

<!-- Loader end -->

<app-calendar-appointment-compact *ngIf="compactAppointment"
  [style.left.px]="compactAppointmentCoords?.x"
  [style.top.px]="compactAppointmentCoords?.y"

  [ngClass]="{'downwards': compactAppointmentCoords?.downwards, 'start': (compactAppointmentCoords?.horSided=='start'), 'end': (compactAppointmentCoords?.horSided=='end')}"
  [appointment]="compactAppointment"
  (onViewDetails)="compactAppointmentViewDetails()"
  (clickOutside)="clearCompactComponent()"
  (onCloseCompact)="clearCompactComponent()"
  [delayClickOutsideInit]="'true'"
  >
</app-calendar-appointment-compact>

<app-calendar-year-view *ngIf="currentView === 'year'"
                        [dateFormat]="dateFormat"
                        [time24Hours]="time_24_hours"
                        [timeZone]="timeZone"
                        [yearChanged]="yearChanged"
                        [filters]="filters"
                        (onAppointmentSelect)="openAppointmentModal($event);"></app-calendar-year-view>
<app-calendar-week-view *ngIf="currentView === 'week'"
                        (onAppointmentSelect)="openAppointmentCompact($event);"
                        [currentWeekFirstDay]="currentWeekFirstDay"
                        [filters]="filters"
                        [timeZone]="timeZone"
                        [renderMwlView]="'true'">
</app-calendar-week-view>
<app-calendar-month-view *ngIf="currentView === 'month'"
                        (onAppointmentSelect)="openAppointmentCompact($event);"
                        [currentMonthFirstDay]="currentMonthFirstDay"
                        [timeZone]="timeZone"
                        [filters]="filters">
</app-calendar-month-view>
