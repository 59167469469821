<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="!isLoading"></app-breadcrumb>
    </div>

    <div class="container mt-auto">
      <h1>{{ 'pages.default.timeline.timeline' | translate }}</h1>
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="timeline-vertical">

      <div *ngFor="let phase of phases; last as last; first as first; index as index">

        <!-- TODAY MARKER -->
        <div class="timeline-item-wrapper heading" [ngClass]="{ 'pt-4': !first }" *ngIf="printToday(index)">
          <div class="tl-day">
            <div class="tl-bullet"></div>
            <p class="text-secondary m-0"><strong>{{ 'pages.default.timeline.today' | translate }}, {{ today |
                date:dateFormat.format }}</strong></p>
          </div>
        </div>
        <!-- /TODAY MARKER -->

        <div *ngIf="printUpcoming(index)">

          <!-- UPCOMING APPOINTMENTS -->
          <div *ngIf="futureAppointments?.length>0">
            <div class="timeline-item-wrapper muted">
              <div class="tl-title">
                <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/history.svg'"></span>
                <p><strong>{{ 'pages.default.timeline.upcoming_appointments' | translate }}</strong></p>
              </div>
            </div>

            <div id="future-appointment-{{appointmentIndex}}" class="timeline-item-wrapper muted" *ngFor="let appointment of futureAppointments; let appointmentIndex = index" [ngClass]="{'pb-0': last}">
              <div class="single-item-block">
                <div class="block-wrapper p-start-3 p-end-1">
                  <span class="icon" [inlineSVG]="'/assets/svg/calendar.svg'"></span>
                </div>
                <div class="block-wrapper">
                  <p class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format }}</p>

                  <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                  <p class="label" *ngIf="!appointment?.title">{{ appointment?.translationKey | translate| evalTranslation }}
                  </p>

                </div>
              </div>
            </div>
          </div>
          <!-- /UPCOMING APPOINTMENTS -->

          <!-- UPCOMING MARKER -->
          <div class="timeline-item-wrapper muted" *ngIf="!phase.isDummy">
            <div class="tl-title">
              <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/history.svg'"></span>
              <p><strong>{{ 'pages.default.timeline.upcoming' | translate }}</strong></p>
            </div>
          </div>
          <!-- /UPCOMING MARKER -->
        </div>

        <!-- PHASE  -->
        <div class="timeline-item-wrapper heading" *ngIf="!phase.isSubPhase && !phase.isDummy"
          [ngClass]="{muted: phase.inTheFuture, 'pb-0': last, 'pt-4': (!first && !phase.inTheFuture), 'pt-0': first}"
          id="phase-{{phase?.id}}">

          <!-- force pt-4 on the item that markes the treatment as stopped, which is the very very last item -->
          <div class="tl-title-block secondary">
            <div class="tl-bullet"></div>
            <h2>{{ phase?.translationKey | translate | evalTranslation }}</h2>
          </div>
        </div>
        <!-- /PHASE  -->

        <!-- SUBPHASE subphases are never shown in future -->
        <div id="sub-phase-{{phase?.id}}" class="timeline-item-wrapper" *ngIf="phase.isSubPhase && !phase.inTheFuture && phase.phase_visible"
          [ngClass]="{muted: phase.inTheFuture, 'pb-0': phase.isDummy}">
          <div class="tl-sub-phase-card">
            <div class="tl-bullet"></div>
            <div class="card">
              <div class="card-body">
                <p class="small">{{ phase?.started_at | timeZoneDate:dateFormat.format }}</p>
                <h3>{{ phase?.translationKey | translate | evalTranslation }}</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- /SUBPHASE -->

        <div *ngIf="!phase.inTheFuture">

          <!-- PASSED APPOINTMENTS -->
          <div class="timeline-item-wrapper" *ngFor="let appointment of phase?.appointments; let appointmentIndex = index">
            <div class="single-item-block" id="appointment-{{appointmentIndex}}">
              <div class="block-wrapper p-start-3 p-end-1">
                <span class="icon" [inlineSVG]="'/assets/svg/calendar.svg'"></span>
              </div>
              <div class="block-wrapper">
                <p class="value">{{ appointment?.start_date | timeZoneDate:dateFormat.format }}</p>

                <p class="label" *ngIf="appointment?.title">{{ appointment?.title }}</p>
                <p class="label" *ngIf="!appointment?.title">{{ appointment?.translationKey | translate | evalTranslation }}
                </p>
              </div>
            </div>
          </div>
          <!-- PASSED APPOINTMENTS -->
        </div>

      </div>

    </div>

    <!-- Spacer - do not remove -->
    <div class="pb-4"></div>
    <!-- Spacer - do not remove -->

  </div>
</div>
