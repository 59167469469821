import { HealthCareProfessional } from './health-care-professional';

export class MedicalTeam {
  public uid: string;
  public hospital_uid: string;
  public name: string;
  public hcp_uids: Array<string>;

  public hcps: HealthCareProfessional[] = [];
  public hcpsAllNames: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  get className(): string {
    return 'MedicalTeam';
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.hospital_uid = item.hospital_uid ? item.hospital_uid : '';
    this.name = item.name ? item.name : '';
    this.hcp_uids = item.hcp_uids ? item.hcp_uids : '';

    if (item.hcps) {
      this.fillHcpsFromJson(item.hcps);
    }
  }

  fillHcpsFromJson(hcpsFromJson: any[]) {

    this.hcps = [];

    if (hcpsFromJson && hcpsFromJson.length) {
      hcpsFromJson.forEach(hcpData => {
        this.hcps.push(new HealthCareProfessional(hcpData));
      });

      this.setAllNames();
    }
  }

  setAllNames(): void {
    this.hcpsAllNames = this.hcps.map(hcp => {
      return hcp.getFullName();
    }).join(', ');
  }
}
