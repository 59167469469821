export class HcpFunction {
  public key: string;
  public is_medical: boolean;
  public value: string;
  public translationKey: string;

  constructor(item?) {
    if (!item) {
      return;
    }

    this.key = item.key;
    this.is_medical = item.is_medical;

    if(this.key) {
      this.value = this.key;
    }

    this.translationKey = `shared.business_function_keys.${this.key}`;
  }
}
