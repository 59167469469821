import { KpiPeriod } from "./kpi-period";

export class Kpi {
  public key: string;
  public title: string;
  public description: string;
  public value: any;
  public unit: string;


  public current_period: KpiPeriod;
  public previous_period: KpiPeriod;

  constructor(item?) {
    if (item) {
      this.key = item.label?.key;
      this.title = item.title;
      this.description = item.description;
      this.value = item.value;
      this.unit = item.unit;

      if (item.current_period) this.current_period = new KpiPeriod({ ...item.current_period, scope: item.title });
      if (item.previous_period) this.previous_period = new KpiPeriod(item.previous_period);
    }
  }

  get hasTooltip(): boolean {
    return ['successful', 'unsuccessful', 'hospital_approval_pending', 'consent_pending'].includes(this.title);
  }
}
