import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../services/locale.service';

@Pipe({name: 'simpleDate'})
export class SimpleDatePipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly localeService: LocaleService
  ) {}

  transform(date: string): string {
    const timeZone = this.localeService.getLocalePreferences().locale.time_zone;
    const dateFormat = this.localeService.getLocalePreferences().dateFormat.format;

    const mDate = moment(date).tz(timeZone);
    
    const today = moment().tz(timeZone);
    const yesterday = today.clone().subtract(1, 'days').startOf('day');

    if(mDate.isSame(today, 'day')) {
      return mDate.format('HH:mm');
    } else if(mDate.isSame(yesterday, 'day')) {
      return this.translateService.instant('pages.default.conversations.yesterday');
    } else if(today.diff(mDate,'days') < 7) {
      return(mDate.format('dddd'));
    } else {
      const momentDateFormat = this.localeService.transformFormatToMomentFormat(dateFormat);
      return mDate.format(momentDateFormat);
    }
  }
}