import { Injectable } from "@angular/core";

import { NoteDetailComponent } from "../pages/default/note-detail/note-detail.component";

@Injectable()
export class NoteCanDeactivateGuard
   {
  canDeactivate(component) {
    return component.canDeactivate();
  }
}