import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {TimedCode} from "../../models/timed-code";
import {GeneralService} from "../../services/general.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {TimedCodeService} from "../../services/timed-code.service";
import {DateFormat} from "../../models/date-format";
import { HospitalCode } from '../../models/hospital-code';

@Component({
  selector: 'app-generate-onboarding-code-modal',
  templateUrl: './generate-onboarding-code-modal.component.html',
  styleUrls: ['./generate-onboarding-code-modal.component.scss']
})
export class GenerateOnboardingCodeModalComponent implements OnInit {
  @Input() dateFormat: DateFormat;
  @Input() hospital_uid: string;

  @Output() public onCodeCreated: EventEmitter<boolean> = new EventEmitter();

  public isLoading: boolean;
  public validationVisible: boolean;
  public form: UntypedFormGroup;

  public showNewCode: boolean;
  public newTimedCode: TimedCode;
  public hospitalCodes: HospitalCode[];

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public generalService: GeneralService,
    public timedCodeService: TimedCodeService,
    public toastrService: ToastrService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getHospitalCodes();
    this.formSetup();
  }

  getHospitalCodes() {
    this.timedCodeService.getHospitalCodes(this.hospital_uid, 0, 999).subscribe(result => {
      this.hospitalCodes = result;
    });
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      pathway: ['', Validators.required]
    });
  }

  handleGenerate() {
    if (this.form.valid) {
      this.form.get('pathway').disable();
      this.validationVisible = false;
      this.isLoading = true;
      this.timedCodeService.createTimedCode(this.hospital_uid, this.form.get('pathway').value, new Date().toISOString()).subscribe(
        res => this.onCreateSuccess(res),
        error => this.onCreateError(error));
    } else {
      this.validationVisible = true;
    }
  }

  handleCancel() {
    this.bsModalRef.hide();
  }

  getCodeNotation(): string {
    return this.newTimedCode.hospital_code + '' + this.newTimedCode.code;
  }

  onCreateSuccess(tc: TimedCode) {
    this.newTimedCode = tc;
    this.showNewCode = true;

    this.toastrService.info(this.translate.instant('modals.generate_onboarding_code.new_code_success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.onCodeCreated.emit(true);

    this.isLoading = false;
  }

  onCreateError(error: any) {
    this.isLoading = false;
    this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  actionCopyCode(event) {
    event.preventDefault();

    const value = this.getCodeNotation();
    this.generalService.copyToClipboard(value);

    this.toastrService.info(this.translate.instant('pages.default.administration.onboarding_codes.code_copied_to_clipboard'), null, {
      disableTimeOut: false,
      timeOut: 3000
    });
  }
}
