import {Component, OnInit} from '@angular/core';
import {CareModule} from '../../../../models/care-module';
import {DateFormat} from '../../../../models/date-format';
import {LocaleService} from '../../../../services/locale.service';
import {HospitalService} from '../../../../services/hospital.service';
import {ConfirmModalComponent} from '../../../../modals/confirm-modal/confirm-modal.component';
import {GeneralService} from '../../../../services/general.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {TimedCode} from "../../../../models/timed-code";
import {TimedCodeService} from "../../../../services/timed-code.service";
import {GenerateOnboardingCodeModalComponent} from "../../../../modals/generate-onboarding-code-modal/generate-onboarding-code-modal.component";
import { Observable } from 'rxjs';
import { HcpService } from '../../../../services/hcp.service';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-admin-onboarding-codes',
  templateUrl: './admin-onboarding-codes.component.html',
  styleUrls: ['./admin-onboarding-codes.component.scss']
})
export class AdminOnboardingCodesComponent implements OnInit {
  public hospital_uid: string;
  public hcp_uid: string;
  public dateFormat: DateFormat;

  public isLoading: boolean;

  public moreFiltersAreShown: boolean;
  public filtersAreSet: boolean;
  public filters: any;
  public showAllCodes: boolean;

  public timedCodes: Array<TimedCode> //type: any, until model is available
  public careModules: Array<CareModule>;

  public currentPage: number = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize: number = 10;

  constructor(
    public generalService: GeneralService,
    public hospitalService: HospitalService,
    public localeService: LocaleService,
    public modalService: BsModalService,
    public hcpService: HcpService,
    public timedCodeService: TimedCodeService,
    public toastrService: ToastrService,
    public translateService: TranslateService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.clearFilters();
    this.getScope();
    this.getTimedCodes().subscribe(() => {});
  }

  clearFilters() {
    this.filtersAreSet = false;

    this.filters = {
      care_module_uid: [],
      status: ['ACTIVE']
    }

    this.showAllCodes = false;
  }

  actionResetFilters() {
    this.clearFilters();
    this.getTimedCodes().subscribe(() => {});
  }

  getScope() {
    const hcp = this.hcpService.getCurrentStoredHcp();
    this.hcp_uid = hcp.uid;
    this.hospital_uid = hcp.hospital_uid;

    this.hospitalService.getCareModules(this.hospital_uid).subscribe(careModules => {
      this.careModules = careModules;
    })
  }

  getTimedCodes(showLoading = true): Observable<any> {
    if(showLoading) {
      this.timedCodes = undefined;
      this.isLoading = true;
    }

    return new Observable(observer => {
      this.timedCodeService.getTimedCodes(this.hospital_uid, this.filters, this.currentPage-1, this.currentPageSize, ['end_at,desc']).subscribe(result => {
        this.isLoading = false;
        this.timedCodes = result.codes;

        if(result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        if(this.totalPages && this.currentPage && this.totalPages < this.currentPage) {
          this.currentPage = 1;
          this.getTimedCodes().subscribe(() => {
            observer.next();
            observer.complete();
          })
        }

        observer.next();
        observer.complete();
      });
    });
  }

  onPaginationPageChanged(page) {
    if(page!==this.currentPage) {
      this.currentPage = page;
      this.getTimedCodes().subscribe(() => {});
    }
  }

  onPaginationRangeChanged(range) {
    if(range!==this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getTimedCodes().subscribe(() => {});
    }
  }

  onShowAllCodesCheck() {
    if (this.showAllCodes) {
      delete this.filters.status;
    } else {
      this.filters.status = ['ACTIVE'];
    }

    this.filtersAreSet = true;
    this.getTimedCodes().subscribe(() => {});
  }

  onCareModuleFilterChange() {
    this.filtersAreSet = true;
    this.getTimedCodes().subscribe(() => {});
  }

  toggleMoreFilters(shown: boolean = undefined) {
    if (shown !== undefined) {
      this.moreFiltersAreShown = shown;
    } else {
      this.moreFiltersAreShown = !this.moreFiltersAreShown;
    }
  }

  actionGenerate(event) {
    event.preventDefault();

    const modalRef = this.modalService.show(GenerateOnboardingCodeModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: {
          dateFormat: this.dateFormat,
          hospital_uid: this.hospital_uid
        }
      }));

    modalRef.content.onCodeCreated.subscribe(() => {
      this.getTimedCodes(false).subscribe(() => {});
    });
  }

  actionInactivate(event, timed_code_uid: string) {
    event.preventDefault();

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('pages.default.administration.onboarding_codes.inactivate_modal_title'),
          description: this.translateService.instant('pages.default.administration.onboarding_codes.inactivate_modal_description'),
          yes: this.translateService.instant('pages.default.administration.onboarding_codes.inactivate_modal_yes')
        }
      })
    );

    modalRef.content.onChoice.subscribe(() => {
      modalRef.content.showYesLoading = true;
      this.timedCodeService.inactivateCode(this.hospital_uid, timed_code_uid).subscribe(
        res => this.onInactivateSuccess(modalRef),
        error => this.onInactivateError(modalRef, error));
    });
  }

  onInactivateSuccess(modalRef: BsModalRef) {
    this.getTimedCodes(false).subscribe(() => {
      modalRef.content.showYesLoading = false;
      modalRef.hide();
    });
  }

  onInactivateError(modalRef: BsModalRef, error: any) {
    modalRef.content.showYesLoading = false;
    modalRef.hide();
    this.toastrService.error(this.translateService.instant('form.feedback.something_went_wrong'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  getCodeNotation(timedCode: TimedCode): string {
    return timedCode.hospital_code + '' + timedCode.code;
  }

  actionCopyCode(event, timedCode: TimedCode) {
    event.preventDefault();

    const value = this.getCodeNotation(timedCode);
    this.generalService.copyToClipboard(value);

    this.toastrService.info(this.translateService.instant('pages.default.administration.onboarding_codes.code_copied_to_clipboard'), null, {
      disableTimeOut: false,
      timeOut: 3000
    });
  }

  isActive(timedCode: TimedCode): boolean {
    return timedCode.status === 'ACTIVE';
  }

  isInactivated(timedCode: TimedCode): boolean {
    return timedCode.status === 'INACTIVE' && timedCode.reason === 'INACTIVATED';
  }

  isExpired(timedCode: TimedCode): boolean {
    return timedCode.status === 'INACTIVE' && timedCode.reason === 'EXPIRED';
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1){
      return itemsLabel + " " + translatedNumber;
    }

    return  translatedNumber + " " + itemsLabel;
  }
}
