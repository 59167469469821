import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoalInstanceBase } from '../interfaces/goal-instance-base.interface';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { GoalGraphType, GoalInstance } from '../models/goal-instance';
import { GoalEntry, GoalEntryInputType } from '../models/goal-entry';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class GoalService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  // Acces: ROLE_HCP
  getDashboardGoalByHcp(hcpUid: string, patientUid: string, patientPathwayId: string, goalId: string, view_date: Date = new Date(), view_type: string = "YEAR"): Observable<{
    view_type: any,
    goal: GoalInstance
  }> {
    const urlParts = [];
    const urlParams = [];

    urlParts.push(`${environment.platformUrl}/dashboards/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayId}/goals/${goalId}`);

    if(view_date) {
      const isoViewDate = view_date.toISOString();
      urlParams.push(`view_date=${isoViewDate}`);
    }

    if(view_type) {
      urlParams.push(`view_type=${view_type}`);
    }

    if(urlParams?.length) {
      urlParts.push(`?${urlParams.join('&')}`);
    }

    const url = urlParts.join('');

    return new Observable( observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next({
          view_type: view_type,
          goal: new GoalInstance(result)
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Acces: ROLE_CC
  getDashboardGoalByHospital(hospitalUid: string, patientUid: string, patientPathwayId: string, goalId: string, view_date: Date = new Date(), view_type: string = "YEAR"): Observable<{
    view_type: any,
    goal: GoalInstance
  }> {
    const urlParts = [];
    const urlParams = [];

    urlParts.push(`${environment.platformUrl}/dashboards/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayId}/goals/${goalId}`);

    if(view_date) {
      const isoViewDate = view_date.toISOString();
      urlParams.push(`view_date=${isoViewDate}`);
    }

    if(view_type) {
      urlParams.push(`view_type=${view_type}`);
    }

    if(urlParams?.length) {
      urlParts.push(`?${urlParams.join('&')}`);
    }

    const url = urlParts.join('');

    return new Observable( observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next({
          view_type: view_type,
          goal: new GoalInstance(result)
        });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  fetchContent(content: {key,region}): Observable<any> {
    const locale = this.languageService.getCurrentLanguage()?.locale;
    return this.cmsGetContent(content, locale);
  }

  toMaxDecimal(val: number, maxDec: number = 1) {
    return Number(Number(val).toFixed(maxDec));
  }

  getProgressTitle(goalIns: GoalInstanceBase):string {
    const progress =  goalIns.evaluation?.evaluation_progress;

    if(progress?.current_progress !== undefined && progress?.target_progress) {
      return `${progress.current_progress}/${progress.target_progress}`;
    } else if (progress?.target_progress && progress?.current_progress === undefined) {
      return `${progress.target_progress}`;
    }
  }

  getProgressSubtitle(goalIns: GoalInstanceBase):string {
    return goalIns?.translationInputUnitKey;
  }

  getProgressPercentage(goalIns: GoalInstanceBase):number {
    return goalIns.evaluation?.evaluation_progress?.percentage;
  }

  getGraphXaxis(isRTL: boolean): any {
    let xAxis: any = {}

    let myDateFormat = '%b %y';

    xAxis.type = 'datetime';
    xAxis.dateTimeLabelFormats = {
      month: myDateFormat,
      year: myDateFormat
    };
    xAxis.format = myDateFormat
    xAxis.title = {
      text: null
    };

    xAxis.reversed = isRTL;

    return xAxis;
  }

  getSeries(goalInstance: GoalInstance): Array<any> {
    let goalSerie = this.getGoalSerie(goalInstance);
    let inputSeries = this.getInputSeries(goalInstance);

    const allSeries = inputSeries.concat([goalSerie]);

    return allSeries;
  }

  getGoalSerie(goalInstance: GoalInstance): any {
    var lastTargetValue: number;
    let data = [];

    if(goalInstance.entries) {
      goalInstance.entries.forEach(entry => {
        if(entry.input_target) {
          lastTargetValue = entry.input_target.value;
        }

        if(lastTargetValue) {
          data.push([
            this.toUTCDate(new Date(entry?.date)),
            lastTargetValue
          ]);
        }
      });
    }

    let serie: any = {
      name: this.translateService.instant('pages.default.goal_detail.graph_goal'),
      data: data,
      color: '#CC0099'
    };

    if(goalInstance.target_graph === GoalGraphType.BAR ) {
      serie.type = 'column';
    } else {
      serie.type = 'line';
      serie.step = 'left';
      serie.dashStyle = 'Dash';
      serie.marker = {
        enabled: false
      };
      serie.enableMouseTracking = false;
    }

    return serie;
  }

  getInputSeries(goalInstance: GoalInstance): Array<any> {
    const hasAverageInputs = goalInstance.hasAverageInputs();

    let series = [];

    if(hasAverageInputs) {
      let averageSerie = this.getAverageSerie(goalInstance);
      series.push(averageSerie);
    } else {
      let progressSeries = this.getProgressSeries(goalInstance);
      series = series.concat(progressSeries);
    }

    return series;
  }

  getAverageSerie(goalInstance: GoalInstance) {
    let averageEntries: GoalEntry[] = goalInstance.getEntriesByInputType(GoalEntryInputType.AVERAGE);

    let data = averageEntries.map(entry => {
      return [
        this.toUTCDate(new Date(entry?.date)),
        entry?.input_average?.value
      ];
    });

    let serie: any = {
      name: this.translateService.instant('pages.default.goal_detail.graph_progress'),
      data: data,
      color: '#000099',
      states: {
        hover: {
          lineWidthPlus: 0
        }
      }
    };

    if(goalInstance.input_graph === GoalGraphType.BAR ) {
      serie.type = 'column';
    } else {
      serie.type = 'line';
      serie.marker = {
        radius: 6,
        symbol: 'circle'
      }
      serie.lineWidth = 3;
    }

    return serie;
  }

  getProgressSeries(goalInstance: GoalInstance) {
    let series: any = [];

    let entries: GoalEntry[] = goalInstance.entries;
    let hcpEntries: GoalEntry[] = [];
    let patientEntries: GoalEntry[] = [];
    let combinedEntries: GoalEntry[] = [];

    entries.forEach(entry => {
      if(entry.input_hcp) {
        hcpEntries.push(entry);
        combinedEntries.push(entry);
      } else if (entry.input_patient) {
        patientEntries.push(entry);
        combinedEntries.push(entry);
      }
    });

    if(goalInstance.input_graph === GoalGraphType.LINE ) {
      let combinedData = combinedEntries.map(entry => {
        return [
          this.toUTCDate(new Date(entry?.date)),
          (entry?.input_hcp?.value || entry?.input_patient?.value)
        ];
      });

      series.push({
        name: this.translateService.instant('pages.default.goal_detail.graph_progress'),
        data: combinedData,
        color: '#000099',
        type: 'line',
        lineWidth: 3,
        marker: {
          enabled: false
        },
        enableMouseTracking: false,
        states: {
          hover: {
            lineWidthPlus: 0
          }
        }
      });
    }

    if(hcpEntries?.length) {
      let hcpData = hcpEntries.map(entry => {
        return [
          this.toUTCDate(new Date(entry?.date)),
          entry?.input_hcp?.value
        ];
      });

      let serie: any = {
        name: this.translateService.instant('pages.default.goal_detail.graph_entered_by_hcp'),
        data: hcpData,
        color: '#CA001B',
        states: {
          hover: {
            lineWidthPlus: 0
          }
        }
      };

      if(goalInstance.input_graph === GoalGraphType.BAR ) {
        serie.type = 'column';
      } else {
        serie.type = 'line';
        serie.lineWidth = 0;
        serie.marker = {
          radius: 6,
          symbol: 'circle'
        }
      }

      series.push(serie);
    }

    if(patientEntries?.length) {
      let patientData = patientEntries.map(entry => {
        return [
          this.toUTCDate(new Date(entry?.date)),
          entry?.input_patient?.value
        ];
      });

      let serie: any = {
        name: this.translateService.instant('pages.default.goal_detail.graph_entered_by_patient'),
        data: patientData,
        color: '#000099',
        states: {
          hover: {
            lineWidthPlus: 0
          }
        }
      };

      if(goalInstance.input_graph === GoalGraphType.BAR ) {
        serie.type = 'column';
      } else {
        serie.type = 'line';
        serie.lineWidth = 0;
        serie.marker = {
          radius: 6,
          symbol: 'circle'
        }
      }

      series.push(serie);
    }

    return series;
  }

  toUTCDate(date: Date) {
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }
}
