import { CareModule } from "../models/care-module";

export class HospitalCode {
  public uid: string;
  public care_module: CareModule;
  public code: string;
  public days_valid: number;

  public translationKey: string;
  public value: string;


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.care_module = new CareModule(item.care_module);
    this.code = item.code;
    this.days_valid = item.days_valid;

    this.value = this.uid;

    this.translationKey = this.care_module.translationKey;
  }
}
