import { Component, OnInit } from '@angular/core';
import { HcpService } from '../../../../services/hcp.service';
import { HcpFunction } from '../../../../models/hcp-function';
import { HcpStatus, HealthCareProfessional } from '../../../../models/health-care-professional';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';;
import { assertNever } from '../../../../helpers/assert-never.helper';
import { GeneralService } from '../../../../services/general.service';
import { EditHcpModalComponent } from '../../../../modals/edit-hcp-modal/edit-hcp-modal.component';
import { AddHcpModalComponent } from '../../../../modals/add-hcp-modal/add-hcp-modal.component';
import { ScopeService } from '../../../../services/scope.service';
import { LanguageService } from '../../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { PhoneNumberInterface } from 'src/app/interfaces/phone-number.interface';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-admin-hcps',
  templateUrl: './admin-hcps.component.html',
  styleUrls: ['./admin-hcps.component.scss']
})
export class AdminHcpsComponent implements OnInit {
  hcps: HealthCareProfessional[] = [];
  functions: HcpFunction[] = [];

  hospitalNamesByUid: any;

  filtersAreSet: boolean;
  filters: any = {
    functionKeys: null,
    hcpLastName: null
  };
  functionFilter: HcpFunction[];

  public sorting: string = 'last_name,asc';

  public isLoading: boolean;

  public currentPage = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize = 10;

  searchHcpLastNameEvent = new Subject<string>();

  constructor(
    private readonly modalService: ModalService,
    private readonly hcpService: HcpService,
    private readonly generalService: GeneralService,
    public scopeService: ScopeService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
    this.searchHcpLastNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.hcpLastName = value;
        this.currentPage = 1;
        this.filtersAreSet = true;
        this.getHcps();
      });
  }

  ngOnInit(): void {
    this.getAllFunctions();
    this.clearFiltersAndGetHcps();
  }

  private getAllFunctions(): void {
    this.hcpService.getFunctions(this.hcpService.getCurrentStoredHospitalUid()).subscribe((result: HcpFunction[]) => {
      this.functions = result;
    }, () => { });
  }

  private clearFiltersAndGetHcps(): void {
    this.filters = {
      name: null,
      functionUids: null,
      hcpLastName: null
    };
    this.functionFilter = null;
    this.filtersAreSet = false;
    this.getHcps();
  }

  private getHcps(showLoading = true): void {
    if (showLoading) {
      this.hcps = [];
      this.isLoading = true;
    }

    this.hcpService.getPaged(this.filters, this.sorting, this.currentPage - 1, this.currentPageSize)
      .subscribe(result => {
        this.isLoading = false;
        this.hcps = result.items;

        if (result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        if (this.totalPages && this.currentPage && this.totalPages < this.currentPage) {
          this.currentPage = 1;
          this.getHcps();
        }
      }, () => {
        this.isLoading = false;
      });
  }

  onPaginationPageChanged(page: number): void {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getHcps();
    }
  }

  onPaginationRangeChanged(pageSize: number): void {
    if (pageSize !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = pageSize;
      this.getHcps();
    }
  }

  onChangeSort(event: MouseEvent, sortKey: string, sortDir: string): void {
    event.preventDefault();
    this.sorting = `${sortKey},${sortDir}`;
    this.getHcps();
  }

  onFunctionFilterChange(hcpFunctions: HcpFunction[]): void {
    this.filtersAreSet = true;
    this.currentPage = 1;

    if (hcpFunctions && hcpFunctions.length) {
      this.filters.functionKeys = [];
      hcpFunctions.forEach(hcpFunc => {
        this.filters.functionKeys.push(hcpFunc.key);
      });
    } else {
      this.filters.functionKeys = null;
    }

    this.getHcps();
  }

  onResetFilters(): void {
    this.clearFiltersAndGetHcps();
  }

  onShowAddHcpModal(event): void {
    event.preventDefault();

    const modalRef = this.modalService.showWithInterceptor(
      AddHcpModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-md'
      })
    );

    modalRef.content.onPatientCreated.subscribe(() => {
      this.getHcps(false);
    });
  }

  onShowEditHcpModal(event, hcp: HealthCareProfessional): void {
    event.preventDefault();

    const modalRef = this.modalService.showWithInterceptor(
      EditHcpModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: { hcp_uid: hcp.uid }
      })
    );

    modalRef.content.hcpEditedEvent.subscribe(() => {
      this.getHcps(false);
    });
  }

  getIconForStatus(status: HcpStatus): string {
    if (!status) {
      return 'close';
    }
    switch (status) {
      case 'CC_DECLINED':
        return 'close';
      case 'CONSENT_DECLINED':
        return 'pause';
      case 'CONSENT_PENDING':
        return 'time';
      case 'ACTIVE':
      default:
        return assertNever(status);
    }
  }

  getPhonePrefix(phone: PhoneNumberInterface): string {
    return this.generalService.getDialCodeForCountry(phone.code).label;
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + " " + translatedNumber;
    }

    return translatedNumber + " " + itemsLabel;
  }
}
