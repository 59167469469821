<div class="page-tabs">
  <div class="scroll" #scrollContainer>
    <ul class="inline-nav no-wrap m-0" #itemsContainer [style.left.px]="leftPosition">
      <li *ngFor="let item of items" [ngClass]="{'active': activeItem==item}">
        <a href="" (click)="clickHandler($event, item)" [id]="['page-tabs-' + item?.htmlID]">{{ item?.translationKey | translate | evalTranslation }}</a>
        <p class="badge-element" *ngIf="item?.badge">{{item?.badge}}</p>
      </li>
    </ul>
  </div>

  <a href="" class="arrow-left" *ngIf="isScrollable" [ngClass]="{'visible': isMarkerVisible(true)}" (click)="move($event, true)" [inlineSVG]="'/assets/svg/chevron-left.svg'"></a>
  <a href="" class="arrow-right" *ngIf="isScrollable" [ngClass]="{'visible': isMarkerVisible(false)}" (click)="move($event, false)" [inlineSVG]="'/assets/svg/chevron-right.svg'"></a>
</div>
