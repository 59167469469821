import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html'
})
export class VideoCarouselComponent {
  @Input() videos: string[]
  constructor() { }
}
