import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CodeService extends ApiService{
  private readonly platformUrl: string;

  constructor (
    http: HttpClient,
    authenticationService: AuthenticationService)
  {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  validateCode(code) {
    return new Observable((observer) => {
      const url = this.platformUrl + "/oauth/token";
      const params = { grant_type: 'authorization_code', code: code };
      return this.basicAuthPost(url, params).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error)
      })
    });
  }
}
