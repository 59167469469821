<div class="calendar-pending-invitations">
  <div class="modal-content">
    <div class="modal-body">
      <h2 class="m-0 p-0">{{ 'modals.appointment.new_invites' | translate }}</h2>
    </div>

    <div class="scroll">
      <button class="invite-item clickable btn w-100 text-align-start" [id]="'pending-appointment-'+ appointment.uid"
        *ngFor="let appointment of appointments" (click)="appointmentSelectHandler(appointment)">

        <div class="date" *ngIf="appointment.start_date && !appointment.date">
          <p class="number">{{ appointment.start_date | timeZoneDate:'d' }}</p>
          <p class="label">{{ appointment.start_date | weekday:true }}</p>
        </div>

        <div class="date" *ngIf="appointment.date && !appointment.start_date">
          <p class="number">{{ appointment.date | timeZoneDate:'d' }}</p>
          <p class="label">{{ appointment.date | weekday:true }}</p>
        </div>

        <div class="meta" *ngIf="appointment.start_date && !appointment.date">
          <p class="title">{{ appointment?.title }}</p>
          <p class="description">
            {{ appointment.start_date | weekday }},
            {{ appointment.start_date | timeZoneDate }} {{ 'modals.appointment.from' | translate }}
            <span>
              {{ appointment.start_date | time }} - {{ appointment.end_date | time }}
            </span>
          </p>
        </div>

        <div class="meta" *ngIf="appointment.date && !appointment.start_date">
          <p class="title">{{ appointment?.patient_invitee.getFullName() }}</p>
          <p class="description">
            {{ appointment.date | weekday }},
            {{ appointment.date | timeZoneDate }}
            <span class="text-info font-weight-bold m-start-1">
              {{ 'pages.default.calendar.full_day' | translate }}
            </span>
          </p>
        </div>
        <div class="icon">
          <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
          <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
        </div>
      </button>
    </div>

  </div>
</div>
