import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProfilePictureModalComponent } from '../../modals/profile-picture-modal/profile-picture-modal.component';
import { SecurePipe } from '../../pipes/secure.pipe';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-avatar-input',
  templateUrl: './avatar-input.component.html',
  providers: [
    {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => AvatarInputComponent),
       multi: true
    }
 ]
})
export class AvatarInputComponent implements ControlValueAccessor {
  constructor(
    public modalService: BsModalService,
    private securePipe: SecurePipe
    ) {}

  public touched = false;
  public disabled = false;
  public preview = undefined;
  public returnValue = {};

  onChange = (value) => {};
  onTouched = () => {};

  writeValue(data: any) {
    if (data && data.url) {
      this.getBase64(data.url);
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private getBase64(url) {
    this.securePipe.transform(url).subscribe(result => {
      this.preview = result;
      this.returnValue['data'] = result;
    });
  }

  onProfilePictureInputChange(event): void {
    const files: any = event.target.files;
    if (files && files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => this.onProfilePictureInputLoaded(reader.result as string, file);
    } else {
      return;
    }
    return;
  }

  onProfilePictureInputLoaded(readerResult, file): void {
    this.openProfilePictureModal(readerResult);
  }

  clearProfilePicture() {
    this.markAsTouched();
    this.preview = undefined;
    this.returnValue['data'] = undefined;
    this.returnValue['remove'] = true;

    this.onChange(this.returnValue);
  }


  openProfilePictureModal(picture): void {
    const initialState = {
      picture
    };

    const modalRef = this.modalService.show(ProfilePictureModalComponent,
      GeneralService.BsModalOptions({
        initialState
      })
    );

    modalRef.content.onImageCropped.subscribe((event) => {
      this.markAsTouched();
      this.preview = event;

      this.returnValue['data'] = event;
      this.returnValue['remove'] = false;

      this.onChange(this.returnValue);
    });
  }
}
