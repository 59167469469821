import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'mobile-verification-code-modal',
  templateUrl: './mobile-verification-code-modal.component.html'
})
export class MobileVerificationCodeModalComponent implements OnInit {
  @Output() public onSubmitClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() public onCancelClicked: EventEmitter<boolean> = new EventEmitter();


  public form: UntypedFormGroup;
  public validationVisible = false;
  public mobile_number: any;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    public toastrService: ToastrService,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();
    this.requestMobileVerificationCode();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
  }

  requestMobileVerificationCode() {
    this.userService.verifyMobileNumber(this.mobile_number).subscribe(result => {
      this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'), null, {
        disableTimeOut: false,
        timeOut: 2000
      });
    })
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.onSubmitClicked.emit(this.form.value.code);
      this.bsModalRef.hide();
    }
  }

  handleCancel() {
    this.onCancelClicked.emit();
    this.bsModalRef.hide();
  }

  resendCode(event) {
    event.preventDefault();
    this.requestMobileVerificationCode();
  }
}
