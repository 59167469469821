import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class DatesFieldsValidator {
  static validate(startDateLabel: string, endDateLabel: string, startTimeLabel: string, endTimeLabel: string ,errorKey) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      const startControl: AbstractControl = group.get(startDateLabel);
      const endControl: AbstractControl = group.get(endDateLabel) ;
      const startTimeControl: AbstractControl = group.get(startTimeLabel);
      const endTimeControl: AbstractControl = group.get(endTimeLabel) ;

      const startDate = new Date(startControl.value);
      const endDate = new Date(endControl.value);
      const startTime = new Date(startTimeControl.value);
      const endTime = new Date(endTimeControl.value);

      const combinedStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDay(), startTime.getHours(), startTime.getMinutes());
      const combinedEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDay(), endTime.getHours(), endTime.getMinutes());

      const duration = combinedEnd.valueOf() - combinedStart.valueOf();
      const durationInMinutes = duration / 1000 / 60;
      
      if ( durationInMinutes < 60) {
        const error = {};
        error[errorKey] = true;
        return error;
      } else {
        return null;
      }
    };
  }
}
