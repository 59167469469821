<p class="font-weight-bold">{{ 'modals.add_patient_wizard.fill_patient_details' | translate }}</p>

<form [formGroup]="patientForm" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
  <!-- Email -->
  <div class="form-group styled floating-label required">
    <input type="email" id="inputEmail" class="form-control w-100"
      [placeholder]="'modals.add_patient_wizard.email' | translate" formControlName="email" autocomplete="off">
    <label for="inputEmail" class="required">{{ 'modals.add_patient_wizard.email' | translate }}</label>
    <app-feedback-field [field]="patientForm.get('email')"></app-feedback-field>
  </div>

  <div class="row">
    <div class="col-6">
      <!-- First name -->
      <div class="form-group styled floating-label">
        <input type="text" id="inputFirstName" class="form-control w-100" [maxlength]="nameMaxLength"
          [placeholder]="('modals.add_patient_wizard.first_name' | translate)" formControlName="first_name">
        <label for="inputFirstName" class="required">{{ 'modals.add_patient_wizard.first_name' | translate
          }}</label>
        <app-feedback-field [field]="patientForm.get('first_name')"></app-feedback-field>
      </div>
    </div>
    <div class="col-6">
      <!-- Last name -->
      <div class="form-group styled floating-label">
        <input type="text" id="inputLastName" class="form-control w-100" [maxlength]="nameMaxLength"
          [placeholder]="'modals.add_patient_wizard.last_name' | translate" formControlName="last_name">
        <label for="inputLastName" class="required">{{ 'modals.add_patient_wizard.last_name' | translate
          }}</label>
        <app-feedback-field [field]="patientForm.get('last_name')"></app-feedback-field>
      </div>
    </div>
  </div>

  <!-- Date of birth -->
  <div class="form-group styled floating-label required">
    <input type="text" id="inputBirth" class="form-control w-100"
      [placeholder]="('modals.add_patient_wizard.date_of_birth' | translate)" bsDatepicker
      formControlName="date_of_birth">
    <label for="inputBirth" class="required">{{ 'modals.add_patient_wizard.date_of_birth' | translate
      }}</label>
    <app-feedback-field [field]="patientForm.get('date_of_birth')"></app-feedback-field>
  </div>

  <!-- Gender -->
  <div class="pb-2">
    <label class="small d-block required">{{ 'modals.add_patient_wizard.gender' | translate }}</label>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radioSexMale" formControlName="gender" value="MALE">
      <label class="custom-control-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radioSexFemale" formControlName="gender" value="FEMALE">
      <label class="custom-control-label" for="radioSexFemale">{{ 'form.labels.female' | translate
        }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radioSexOther" formControlName="gender" value="UNKNOWN">
      <label class="custom-control-label" for="radioSexOther">{{ 'form.labels.prefer_not_say' | translate
        }}</label>
    </div>
    <app-feedback-field [field]="patientForm.get('gender')" [extraClass]="'general-feedback'">
    </app-feedback-field>
  </div>

  <!-- electronic_medical_record -->
  <div class="form-group styled floating-label">
    <input type="text" id="inputEMR" class="form-control w-100"
      [placeholder]="'modals.add_patient_wizard.emr' | translate" formControlName="electronic_medical_record">
    <label for="inputEMR">{{ 'modals.add_patient_wizard.emr' | translate }}</label>
    <app-feedback-field [field]="patientForm.get('electronic_medical_record')"></app-feedback-field>
  </div>
</form>
