import { Injectable, Output, EventEmitter, Inject, ApplicationRef  } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as Bslocales from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { LanguageCode } from '../models/language-code';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LanguageService  extends ApiService {
  @Output() onLanguageUpdated: EventEmitter<any> = new EventEmitter();

  public static StorageKeyLanguage = "C4T_language_key";
  public static DefaultLanguageCode = new LanguageCode({
    value: 'EN',
    locale: 'en'
  });

  public languageCodes: LanguageCode[];
  public currentLanguageCode: LanguageCode;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public bsLocaleService: BsLocaleService,
    @Inject(DOCUMENT) private document: Document

  ) {
    super(http, authenticationService);

    this.languageCodes = [];
    this.currentLanguageCode = LanguageService.DefaultLanguageCode;
  }

  appInitializer() {
    this.translateService.setDefaultLang(LanguageService.DefaultLanguageCode.locale);

    for (const locale in Bslocales) {
      defineLocale(Bslocales[locale].abbr, Bslocales[locale]);
    }

    return new Promise((resolve, reject) => {
      this.getLanguageCodes().subscribe(() => {
        this.setInitialLanguage().subscribe(() => {
          resolve(true);
        });
      });
    });
  }

  public getLanguageCodes(): Observable<Array<LanguageCode>> {
    return new Observable(observer => {
      const url = `${environment.platformUrl}/general/language-codes`;

      this.basicAuthGet(url, 'v2', true).subscribe(result => {
        this.languageCodes = this.mapLanguageCodes(result);
        observer.next(this.languageCodes);
        observer.complete();
      }, () => {
        observer.next(this.languageCodes);
        observer.complete();
      });
    });
  }

  public mapLanguageCodes(result: any): Array<any> {
    const languageCodes = new Array();

    for (const key in result) {
      const languageResp = result[key];

      const input: any = {};
      input.value = key;

      if((typeof languageResp).toLowerCase() === 'object') {
        input.locale = languageResp?.code;
        input.reading_direction = languageResp?.reading_direction;
      } else {
        input.locale = languageResp;
      }

      languageCodes.push(new LanguageCode(input));
    }

    return languageCodes;
  }

  getSupportedAppLanguages(): Observable<Array<LanguageCode>> {
    return new Observable(observer => {
      const languageCodes = this.languageCodes || [];
      observer.next(languageCodes);
      observer.complete();
    });
  }

  getCurrentLanguageCode(): LanguageCode {
    return this.currentLanguageCode || LanguageService.DefaultLanguageCode;
  }

  getCurrentLanguage(): LanguageCode {
    return this.getCurrentLanguageCode();
  }

  getLanguageCodeFromValue(value: string): LanguageCode {
    return this.findLanguageCodeByEnum(value);
  }

  getLanguageCodeFromLocale(locale: string) {
    return this.findLanguageCodeByLocale(locale);
  }

  setCurrentLanguageCode(languageCode: LanguageCode) {
    this.currentLanguageCode = languageCode;
    this.useLanguage(languageCode.locale);
    return localStorage.setItem(LanguageService.StorageKeyLanguage, languageCode.value);
  }

  setCurrentLanguageByEnum(value: string) {
    const languageCode = this.findLanguageCodeByEnum(value);

    if(languageCode) {
      this.setCurrentLanguageCode(languageCode);
    } else {
      this.setFallbackLanguage();
    }
  }

  setFallbackLanguage() {
    this.setCurrentLanguageCode(LanguageService.DefaultLanguageCode);
  }

  useLanguage(locale: string) {
    this.translateService.use(locale).subscribe(() => {
      this.onLanguageUpdated.emit();
    });

    document.documentElement.lang = locale;
    setTimeout(() => {
      if((<any>window).window.OneTrust) {
        (<any>window).window.OneTrust.changeLanguage(locale);
      }
    }, 1);

    this.bsLocaleService.use(locale);
    this.determineRTL();
  }

  determineRTL() {
    if(this.isRTL) {
      document.documentElement.dir = 'rtl';
      this.loadStyle('rtl');
      return true;
    } else {
      document.documentElement.dir = '';
      this.loadStyle('ltr');
      return false;
    }
  }

  loadStyle(type: string) {
    const styleName = `styles-${type}.css`;

    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink && themeLink.href) {
      if(!themeLink.href.includes(styleName)) {
        themeLink.href = styleName;
      }
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  reloadLanguage() {
    const lanCode = this.getCurrentLanguageCode();

    return new Observable(observer => {
      this.translateService.reloadLang(lanCode.locale).subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  setInitialLanguage(): Observable<any> {
    const storedEnum = localStorage.getItem(LanguageService.StorageKeyLanguage);

    return new Observable(observer => {
      if(storedEnum) {
        const foundLangCode = this.findLanguageCodeByEnum(storedEnum);

        if(!foundLangCode) {
          this.setFallbackLanguage();
        } else {
          this.setCurrentLanguageCode(foundLangCode);
        }

        observer.next();
        observer.complete();
      } else {
        const navLang = navigator.language;

        if(navLang) {
          const foundNavLangCode: LanguageCode = this.findLanguageCodeByLocale(navLang);

          if(foundNavLangCode) {
            this.setCurrentLanguageCode(foundNavLangCode);
          } else {
            this.setFallbackLanguage();
          }

          observer.next();
          observer.complete();
        } else {
          this.setFallbackLanguage();
          observer.next();
          observer.complete();
        }
      }
    });
  }

  findLanguageCodeByLocale(locale: string): LanguageCode {
    const found = this.languageCodes.filter(langCode => {
      return locale.toUpperCase().includes(langCode.value.toUpperCase());
    });

    if(found && found.length) {
      return found[0];
    } else {
      return undefined;
    }
  }

  findLanguageCodeByEnum(enumValue: string): LanguageCode {
    const found = this.languageCodes.filter(langCode => {
      return String(langCode.value).toUpperCase() === String(enumValue).toUpperCase()
    });

    if(found && found.length) {
      return found[0];
    } else {
      return undefined;
    }
  }

  get isHebrew(): boolean {
    const code: LanguageCode = this.currentLanguageCode || LanguageService.DefaultLanguageCode;
    return code.locale === 'he-il';
  }

  get isRTL(): boolean {
    const code: LanguageCode = this.currentLanguageCode || LanguageService.DefaultLanguageCode;
    return code.isRTL;
  }
}
