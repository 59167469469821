import { Component, OnInit } from '@angular/core';
import { DateFormat } from '../../../../models/date-format';
import { HealthCareProfessional } from '../../../../models/health-care-professional';
import { Ooo } from '../../../../models/ooo';
import { HcpService } from '../../../../services/hcp.service';
import { LocaleService } from '../../../../services/locale.service';
import { OooService } from '../../../../services/ooo.service';

@Component({
  selector: 'app-settings-overview',
  templateUrl: './settings-overview.component.html',
  styleUrls: ['./settings-overview.component.scss']
})
export class SettingsOverviewComponent implements OnInit {

  public hcp: HealthCareProfessional;
  public ooo: Ooo;
  public dateFormat: DateFormat;

  constructor(
    public hcpService: HcpService,
    public oooService: OooService,
    public localeService: LocaleService
  ) { }

  ngOnInit() {
    this.getOoo();
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.hcp = this.hcpService.getCurrentStoredHcp();
  }

  getFullName() {
    const currentHcp = this.hcpService.getCurrentStoredHcp();
    if(currentHcp) {
      return currentHcp.getFullName();
    }
  }

  getProfilePicture() {
    const currentHcp = this.hcpService.getCurrentStoredHcp();
    if (currentHcp) {
      return currentHcp.profile_picture;
    }
  }

  getOoo() {
    const currentHcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.oooService.getOutOfOffice(currentHcpUid).subscribe(result  => {
      this.ooo = result;
    });
  }
}
