import { PhoneNumberInterface } from '../interfaces/phone-number.interface';
import { LocationInterface } from '../interfaces/location.interface';
import { HcpFunction } from './hcp-function';
import { Image } from './image';

export class HealthCareProfessional {

  public uid: string;
  public hospital_uid: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public phone_number: PhoneNumberInterface;
  public gender: string;
  public profile_picture: Image;
  public language: string;
  public location: LocationInterface = {name: '', url: ''};
  public status: HcpStatus;
  public function: HcpFunction;
  public job_title: string;
  public is_care_coordinator: boolean;
  public reviewed: boolean;
  public email_change_allowed: boolean;

  //for select purposes
  public label: string;
  public value: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  get className(): string {
    return 'HealthCareProfessional';
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.hospital_uid = item.hospital_uid;
    this.first_name  = item.first_name;
    this.last_name  = item.last_name;
    this.email  = item.email;
    this.phone_number  = item.phone_number;
    this.gender  = item.gender;

    if(item.profile_picture) {
      this.profile_picture  = new Image(item.profile_picture);
    }

    this.language = item.language;

    if(item.location) {
      this.location = item.location;
    }

    this.status = item.status;
    this.is_care_coordinator = item.is_care_coordinator;
    this.reviewed = item.reviewed;

    // Function from PF is returned as string;
    // On handling function from localStorage this is a HcpFunction
    if(item.function && !item.function.key) {
      this.function = new HcpFunction({key: item.function});
    } else if (item.function) {
      this.function = new HcpFunction(item.function);
    }

    this.job_title = item.job_title;
    this.email_change_allowed = item.email_change_allowed;

    // for select purposes
    this.label = this.getFullName();
    this.value = this.uid;
  }

  fromStorage(hcp: HealthCareProfessional) {
    this.fillFromJson(hcp);
  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }

  public get isActive(): boolean {
    return this.status === 'ACTIVE';
  }
}

export type HcpStatus = 'CONSENT_PENDING' | 'CC_DECLINED' | 'ACTIVE' | 'CONSENT_DECLINED' | 'TEST';
