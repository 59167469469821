import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../services/user.service";
import { Profile } from '../../models/profile';
import { GeneralService } from '../../services/general.service';
import { cloneDeep} from 'lodash';
import { LanguageService } from '../../services/language.service';


@Component({
  selector: 'app-edit-phone-modal',
  templateUrl: './edit-phone-modal.component.html',
  styleUrls: ['./edit-phone-modal.component.scss']
})
export class EditPhoneModalComponent implements OnInit {
  @Output() onProfileUpdated: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;
  public profile: Profile;

  public isLoading = false;
  public validationVisible = false;
  public step: "phone" | "code" | "forget" = 'phone';
  public dialCodeOptions = [];

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public userService: UserService,
    public generalService: GeneralService,
    public languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.dialCodeOptions = this.generalService.dialCodes;
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }


    this.form = new UntypedFormGroup({
      country_code: new UntypedFormControl(this.profile?.mobile_number?.code, Validators.required),
      phone: new UntypedFormControl(this.profile?.mobile_number?.number, Validators.required),
      notifications_method_sms: new UntypedFormControl(['']),
      code: new UntypedFormControl('', [Validators.required])
    });

    if (this.profile.locale.notification_channels.includes('SMS')) {
      this.form.get('notifications_method_sms').setValue(true);
    }
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    this.validationVisible = false;

    if(this.step === "phone") {
      if (this.form.get('country_code').invalid || this.form.get('phone').invalid) {
        this.validationVisible = true;
        return;
      }

      // verification code is required
      if(this.verificationCodeRequired()) {
        const input:any = {
          code: this.form.value.country_code,
          number: this.form.value.phone
        }

        this.userService.verifyMobileNumber(input).subscribe(result => {
          this.step = "code";
          this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'), null, {
            disableTimeOut: false,
            timeOut: 2000
          });
          return;
        });
      } else {
        this.updateProfile();
      }
    }

    // On the mfa step
    if(this.step === "code") {
      if (this.form.get('code').errors) {
        this.validationVisible = true;
        return;
      } else {
        this.updateProfile(this.form.get('code'). value);
      }
    }
  }

  requestMobileVerificationCode() {
    const input:any = {
      code: this.form.value.country_code,
      number: this.form.value.phone
    }

    this.userService.verifyMobileNumber(input).subscribe(result => {
      this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'), null, {
        disableTimeOut: false,
        timeOut: 2000
      });
    });
  }

  verificationCodeRequired() {
    const smsNotificationSelected = (this.form.value.notifications_method_sms && !this.profile.locale.notification_channels.includes('SMS'))
    const phoneNumberChanged = (this.form.value.phone !== this.profile.mobile_number?.number || this.form.value.country_code !== this.profile.mobile_number?.code)

    if((smsNotificationSelected || phoneNumberChanged) && this.form.value.notifications_method_sms) {
      return true;
    }
    return false;
  }

  updateProfile(code?) {
    this.isLoading = true;

    const profile = this.createProfilePayLoad(code)

    this.userService.updateProfile(profile).subscribe(
      () => this.onUpdateSuccess(),
      (error) => this.onUpdateError(error)
    );
  }

  createProfilePayLoad(code?):Profile {
    const profile:Profile = cloneDeep(this.profile, true);

    if(profile.mobile_number) {
      profile.mobile_number.code = this.form.value.country_code;
      profile.mobile_number.number = this.form.value.phone;
    }

    if(code) {
      profile.mobile_verification_code = code;
    }

    if( profile.locale) {
      profile.locale.notification_channels = [];

      if (this.form.value.notifications_method_sms) {
        profile.locale.notification_channels.push('SMS');
      }
    }

    return profile;
  }

  onUpdateSuccess() {
    this.isLoading = false;
    this.toastrService.info(this.translate.instant('modals.edit_contact_details.successfully_updated'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    // visual update
    const mobile_number = {
      number: this.form.value.phone,
      code: this.form.value.country_code
    }

    this.onProfileUpdated.emit({mobile_number: mobile_number, notifications_method_sms: this.form.value.notifications_method_sms})

    if(this.step === "code") {
      this.step = "forget";
    } else {
      this.bsModalRef.hide();
    }
  }

  onUpdateError(error) {
    this.isLoading = false;

    if(error?.error && error?.error['key'] === "ERROR_IDENTITY_VERIFICATION_CODE_INVALID" || error?.error['key'] === "ERROR_IDENTITY_VERIFICATION_CODE_EXPIRED") {
      this.toastrService.error(this.translate.instant('pages.flow.onboarding_details.wrong_code'), null, {
        disableTimeOut: false,
        timeOut: 4000
      });
    } else {

      return this.bsModalRef.hide();
    }
  }

  resendCode(event) {
    event.preventDefault();
    this.requestMobileVerificationCode();
  }
}
