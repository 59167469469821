<div class="wizard modal-content-add-patient-wizard">
  <div class="is-loading row" *ngIf="isLoading">
    <app-page-loader class="col-12 align-self-center"></app-page-loader>
  </div>

  <div class="row" *ngIf="!isLoading">
    <div class="col-5 with-background">
      <div class="m-start-2 mt-8">
        <div class="icon icon-lg mb-2" [inlineSVG]="'/assets/svg/logo-hcp-icon.svg'"></div>
        <h2 class="text-white">{{ getWizardTitle() }}</h2>

        <ul class="steps-progress" *ngIf="showTimeLine">
          <li *ngFor="let step of steps" [ngClass]="{'complete': isStepDone(step), 'active': isStepActive(step)}">
            <div class="step-item" *ngIf="step === stepsEnum.CAREMODULE">
              <div class="head">
                <span class="step-marker"></span>
                <span class="label text-truncate">{{ 'modals.add_patient_wizard.pathway' | translate }}</span>
              </div>
            </div>

            <div class="step-item" *ngIf="step === stepsEnum.PATIENT">
              <div class="head">
                <span class="step-marker"></span>
                <span class="label text-truncate">{{ 'modals.add_patient_wizard.patient_details' | translate }}</span>
              </div>
            </div>

            <div class="step-item" *ngIf="step === stepsEnum.REGION">
              <div class="head">
                <span class="step-marker"></span>
                <span class="label text-truncate">{{ 'modals.add_patient_wizard.language_and_region' | translate }}</span>
              </div>
            </div>

            <div class="step-item"
              *ngIf="step === stepsEnum.HOSPITALTEAM && selectedCareModule?.type === CareModuleType.CLINICAL">
              <div class="head">
                <span class="step-marker"></span>
                <span class="label text-truncate">{{ 'modals.add_patient_wizard.hospital_team' | translate }}</span>
              </div>
            </div>

            <div class="step-item" *ngIf="step === stepsEnum.OVERVIEW">
              <div class="head">
                <span class="step-marker"></span>
                <span class="label text-truncate">{{ 'modals.add_patient_wizard.check_and_confirm' | translate }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-7 p-start-0">
      <div class="modal-header d-block">
        <button type="button" class="close" id="add-patient-modal-close" data-dismiss="modal" aria-label="Close"
          (click)="bsModalRef.hide()">
          <span [inlineSVG]="'/assets/svg/close.svg'"></span>
        </button>
      </div>

      <div class="modal-body w-100">
        <p class="text-uppercase text-info small font-weight-bold" *ngIf="showTimeLine">{{
          'modals.add_patient_wizard.step' | translate
          }}
          {{ getStepIndex()}} {{ 'modals.add_patient_wizard.of' | translate }} {{steps.length}}
        </p>

        <!-- CARE-MODULE SELECT -->
        <!-- CARE-MODULE SELECT -->
        <!-- CARE-MODULE SELECT -->
        <div class="pathway-step" *ngIf="currentStep === stepsEnum.CAREMODULE">
          <app-pathway-step (careModulesLoaded)="careModulesLoaded()" [(careModules)]="careModules"
            [(selectedCareModule)]="selectedCareModule" [(selectedCareModuleWasUpdated)]="selectedCareModuleWasUpdated"
            (careModuleSelected)="careModuleSelected()"></app-pathway-step>
        </div>

        <!-- PATIENT SELECT -->
        <!-- PATIENT SELECT -->
        <!-- PATIENT SELECT -->
        <div class="patient-step" *ngIf="currentStep === stepsEnum.PATIENT">
          <app-patient-step [(patient)]="patient" (patientValid)="patientValid($event)"></app-patient-step>
        </div>

        <!-- REGION SELECT -->
        <!-- REGION SELECT -->
        <!-- REGION SELECT -->
        <div class="language-step" *ngIf="currentStep === stepsEnum.REGION">
          <app-region-step [(country)]="country" [(language)]="language"></app-region-step>
        </div>

        <!-- HOSPITAL TEAM SELECT -->
        <!-- HOSPITAL TEAM SELECT -->
        <!-- HOSPITAL TEAM SELECT -->
        <div class="hospital-team-step" *ngIf="currentStep === stepsEnum.HOSPITALTEAM">
          <app-hospital-team-step [(selectedCareModule)]="selectedCareModule"
            [(selectedCareModuleWasUpdated)]="selectedCareModuleWasUpdated"></app-hospital-team-step>
        </div>

        <!-- OVERVIEW -->
        <!-- OVERVIEW -->
        <!-- OVERVIEW -->

        <div class="overview-step" *ngIf="currentStep === stepsEnum.OVERVIEW">
          <app-overview-step [selectedCareModule]="selectedCareModule" [patient]="patient" [language]="language"
            [country]="country"></app-overview-step>
        </div>
      </div>

      <div class="modal-footer" [ngClass]="{'box-shadow': showPreviousBtn() || showNextBtn() }">
        <div class="m-start-auto btn-group">
          <button *ngIf="showPreviousBtn()" class="btn btn-link" id="add-patient-modal-previous"
            (click)="onHandleStep('backward')">
            {{ 'modals.add_patient_wizard.previous_step' | translate }}
          </button>
          <button *ngIf="showNextBtn()" class="btn btn-secondary" id="add-patient-modal-next"
            (click)="onHandleStep('forward')" [disabled]="nextStepIsDisabled()">
            {{ 'modals.add_patient_wizard.next_step' | translate }}
          </button>
          <button *ngIf="currentStep === stepsEnum.OVERVIEW" class="btn btn-secondary" id="add-patient-modal-confirm"
            (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}">
            <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
            <span>{{ 'modals.add_patient_wizard.confirm' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
