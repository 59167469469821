import {EventEmitter, Injectable, Output} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {HcpService} from './hcp.service';
import {HealthCareProfessional} from '../models/health-care-professional';
import { PermissionHospital } from '../models/permissions';
import { CareModule } from '../models/care-module';
import { HospitalService } from './hospital.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {
  @Output() public onScopeChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    public userService: UserService,
    public hcpService: HcpService,
    public hospitalService: HospitalService
  ) {
  }

  public initializeScope() {
    return new Observable((observer) => {
      this.userService.getPermissions().subscribe(() => {
        this.defineFirstHcp().subscribe(() => {
          const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
          this.defineCareModules(hospitalUid).subscribe();
          observer.next();
          observer.complete();
        });
      });
    });
  }

  public defineFirstHcp() {
    const permissions: any = this.userService.getStoredPermissions();
    if (!permissions.hospitals || !permissions.hospitals[0].hcp) {
      return new Observable(observer => {
        observer.error();
      });
    } else {
      const hcpMeta: any =  permissions.hospitals[0].hcp;
      return this.changeCurrentHcp(hcpMeta.uid, false);
    }
  }

  public defineCareModules(hospitalUid:string) {
    return new Observable((observer) => {
      this.hospitalService.getCareModules(hospitalUid).subscribe(result => {
        this.hcpService.storeCurrentCareModules(result);
        observer.next();
        observer.complete();
      });
      
    });
  }

  //
  // Used to change the app's current hcp context
  //
  public changeCurrentHcp(uid: string, broadcastScopeChange = true): Observable<HealthCareProfessional> {
    return new Observable((observer) => {
      this.hcpService.setCurrentHcp(uid).subscribe(patient => {
        observer.next(patient);

        if (broadcastScopeChange) {
          this.onScopeChanged.emit();
        }

        observer.complete();
      });
    });
  }

  public getCurrentHospitalBasic(): PermissionHospital {
    const permissions = this.userService.getStoredPermissions();
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();

    if (permissions && hospital_uid != null) {
      return permissions.getHospital(hospital_uid);
    } else {
      return null;
    }
  }


  // 
  // USE THIS METHODE TO CHECK FOR A SINGLE PERMISSION RULE
  //
  public doesCurrentHcpHasPermission(rule: string) {
    const storedHcp = this.hcpService.getCurrentStoredHcp();
    const permissions = this.userService.getStoredPermissions();

    if (permissions) {
      return permissions.doesHcpHasPermission(storedHcp.uid, rule);
    } else {
      return false;
    }
  }

  // 
  // USE THIS METHODE TO CHECK FOR MULTIPLE PERMISSION RULES
  //
  public doesCurrentHcpHasOneOfPermissions(rules: string[]) {
    let hasOneOf = false;

    rules.forEach(rule => {
      if(this.doesCurrentHcpHasPermission(rule)) {
        hasOneOf = true;
      }
    });

    return hasOneOf;
  }

  public doesCurrentPatientHasClicinalCareModule(): Boolean {
    const careModules: CareModule[] = this.hcpService.getCurrentStoredCareModules();
    if(!careModules) {
      return false;
    }
    const types = careModules.map((cm) => {return cm.type})
    return types.includes('CLINICAL');
  }

  public refreshCurrentHcp(): Observable<HealthCareProfessional> {
    const hcp = this.hcpService.getCurrentStoredHcp();
    return this.changeCurrentHcp(hcp.uid);
  }

  public refreshPermissions(): Observable<any> {
    return this.userService.getPermissions();
  }
}
