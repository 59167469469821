import { HealthCareProfessional } from './health-care-professional';
import { MedicalTeam } from './medical-team';

export class PatientMdt {
  /*
  PatientPathwayPatientMdt_V1
  PatientPathwayDashboardGetResponse_V1
  */

  mdts: Array<MedicalTeam> = [];
  hcps: Array<HealthCareProfessional> = [];
  otherHcps: Array<HealthCareProfessional> = [];

  /*
  Patient_Basic_V1
  */

  hcp_uids: Array<string> = [];
  mdt_uids: Array<string> = [];


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.hcps && item.hcps.length > 0) {
      this.hcps = item.hcps.map(hcp => new HealthCareProfessional(hcp));
    }

    if (item.hcp_details && item.hcp_details.length > 0) {
      this.hcps = item.hcp_details.map(hcp_detail => new HealthCareProfessional(hcp_detail));
    }

    if (item.mdts && item.mdts.length > 0) {
      this.mdts = item.mdts.map(mdt => new MedicalTeam(mdt));
    } else {
      this.mdts = [];
    }

    if (this.hcps.length > 0) {
      this.attachHcpsToMdts(this.hcps, this.mdts);
    }

    if (item.hcp_uids && item.hcp_uids.length > 0) {
      this.hcp_uids = item.hcp_uids;
    }

    if (item.mdt_uids && item.mdt_uids.length > 0) {
      this.mdt_uids = item.mdt_uids;
    }

  }

  attachHcpsToMdts(hcps: HealthCareProfessional[], mdts: MedicalTeam[]) {
    this.otherHcps = [];

    hcps.forEach((hcp: HealthCareProfessional) => {
      let hcpHasMdt = false;

      mdts.forEach((mdt: MedicalTeam) => {
        if (mdt.hcp_uids.includes(hcp.uid)) {
          mdt.hcps.push(hcp);
          hcpHasMdt = true;
        }
      });

      if (!hcpHasMdt) {
        this.otherHcps.push(hcp);
      }
    });
  }

  private filterOnlyActiveHcps(list: Array<HealthCareProfessional>): Array<HealthCareProfessional> {
    if (list) {
      return list.filter(_hcp => _hcp.status === 'ACTIVE');
    } else {
      return undefined;
    }
  }

  private excludeHcps(list: HealthCareProfessional[], excludeHcps: string[]): HealthCareProfessional[] {
    if (list.length > 0) {
      return list.filter((hcp: HealthCareProfessional) => !excludeHcps.includes(hcp.uid));
    } else {
      return undefined;
    }
  }

  public getAllHcps(onlyActiveHcps: boolean = true): HealthCareProfessional[] {
    if (onlyActiveHcps) {
      return this.filterOnlyActiveHcps(this.hcps);
    } else {
      return this.hcps;
    }
  }

  public getMdts(onlyActiveHcps: boolean = true, excludeHcps: string[] = []): MedicalTeam[] {
    let mdts: MedicalTeam[] = this.mdts;

    if (onlyActiveHcps) {
      mdts = mdts.map((mdt: MedicalTeam) => {
        mdt.hcps = this.filterOnlyActiveHcps(mdt.hcps);
        return mdt;
      });
    }

    if (excludeHcps && excludeHcps.length > 0) {
      mdts = mdts.map((mdt: MedicalTeam) => {
        mdt.hcps = this.excludeHcps(mdt.hcps, excludeHcps);
        return mdt;
      });
    }

    return mdts;
  }


  public getOtherHcps(onlyActiveHcps: boolean = true, excludeHcps: string[] = []): HealthCareProfessional[] {
    let otherHcps: HealthCareProfessional[] = this.otherHcps;

    if (onlyActiveHcps) {
      otherHcps = this.filterOnlyActiveHcps(otherHcps);
    }

    if (excludeHcps && excludeHcps.length > 0) {
      otherHcps = this.excludeHcps(otherHcps, excludeHcps);
     }

    return otherHcps;
  }
}
