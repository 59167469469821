<!-- -->
<!-- Conversation Empty States Start -->
<!-- -->
<div class="pane-detail empty" *ngIf="!conversation">
  <div class="empty-state">
    <span [inlineSVG]="'/assets/svg-color/message.svg'"></span>
    <h3>{{ 'pages.default.conversations.convo_empty_title' | translate }}</h3>
    <p>{{ 'pages.default.conversations.convo_empty_text' | translate }}</p>
  </div>
</div>
<!-- -->
<!-- Conversation Empty States End -->
<!-- -->

<div class="pane-detail conversation" *ngIf="conversation">

  <!-- -->
  <!-- Conversation Header Start -->
  <!-- -->
  <div class="layout-header-pusher header-conversation"></div>
  <div class="layout-header header-conversation">
    <div class="header-content">
      <div class="title-button">
        <h3 class="text-truncate">{{ conversation.subject }}</h3>

        <div class="btn-group" dropdown placement="bottom right" *ngIf="showEditBtns">
          <button id="button-convo-settings" dropdownToggle type="button" class="dropdown-toggle btn btn-settings btn-sm"
                  aria-controls="dropdown-convo-settings">
            <span [inlineSVG]="'/assets/svg/more.svg'"></span>
          </button>
          <ul id="dropdown-convo-settings" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-convo-settings">
            <li role="menuitem">
              <a class="dropdown-item" id="conversation-edit-subject" href="" (click)="showEditSubjectModal($event)">{{ 'pages.default.conversations.edit_subject' | translate }}</a>
              <a class="dropdown-item" id="conversation-edit-participants" href="" (click)="showEditParticipantsModal($event)">{{ 'pages.default.conversations.edit_participants' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="participants mb-1">
        <div class="d-inline-block" *ngIf="conversation?.patient">
          <p class="title">{{ 'pages.default.conversations.patient' | translate }}</p>

          <div id="conversation-participant-patient" class="item m-end-2">
            <app-avatar [profilePicture]="conversation?.patient?.profile_picture"></app-avatar>
            <span class="label">{{ conversation?.patient?.getFullName() }}</span>
          </div>
        </div>

        <div id="participant-hcps" class="d-inline-block" *ngIf="conversation.activeHcpParticipants().length > 0">
          <p class="title">{{ 'pages.default.conversations.hcps' | translate }}</p>

          <div *ngIf="(conversation.activeHcpParticipants().length <= visibleParticipantsNum)">
            <div id="participant-hcp-{{index}}" class="item" *ngFor="let participant of conversation.activeHcpParticipants(); index as index">
              <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>
              <span class="label">{{ participant?.getFullName() }}</span>
            </div>
          </div>

          <div *ngIf="(conversation.activeHcpParticipants().length > visibleParticipantsNum)">
            <div class="item" *ngFor="let participant of conversation.activeHcpParticipants().slice(0,(visibleParticipantsNum-1))">
              <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>
              <span class="label">{{ participant?.getFullName() }}</span>
            </div>
            <a href="" class="item" id="conversation-show-participants-modal"  (click)="showParticipantsModal($event, conversation.activeHcpParticipants().slice(visibleParticipantsNum-1))">
              <span class="avatar">
                <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
              </span>
              <span class="label">{{ 'pages.default.conversations.and' | translate }} {{ conversation.activeHcpParticipants().length - (visibleParticipantsNum-1) }} {{ 'pages.default.conversations.others' | translate }}</span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- -->
  <!-- Conversation Header End -->
  <!-- -->

  <!-- -->
  <!-- Conversation Start -->
  <!-- -->
  <div class="body-content pb-0 conversation_items" infiniteScroll
  [infiniteScrollDistance]="0"
  [infiniteScrollThrottle]="50"
  (scrolledUp)="onScrollItems($event)"
  [scrollWindow]="false" >

    <div class="conversation-note note-primary" *ngIf="conversation.standard_reply_in_days">
      <div class="note-content">
        {{ 'pages.default.conversations.expect_response_in_days' | translate:{days:conversation.standard_reply_in_days} }}
      </div>
    </div>

    <div class="text-center text-muted py-3" *ngIf="isConversationLoading">
      <em class="spinner-border spinner-border-sm"></em>
    </div>

    <div>
      <div *ngFor="let item of conversation.items; let i = index; let last = last; trackBy: trackByFn" >

        <div *ngIf="(i == 0 || differentPage(item, conversation.items[i-1]))" [attr.id]="'page' + item.page">
          <div class="text-center text-muted py-3" *ngIf="loadingPage(item.page)">
            <em class="spinner-border spinner-border-sm"></em>
          </div>
        </div>

        <div class="conversation-day" *ngIf="i == 0 || differentDate(item, conversation.items[i-1])">
          <p>{{ item.created_at | simpleDate }}</p>
        </div>

        <div class="convo-message"
          [ngClass]="{
            'side-sender': isSender(item),
            'side-receiver': !isSender(item),
            'last': last
          }"
          *ngIf="['MESSAGE', 'FILE_MESSAGE', 'AUDIO_MESSAGE', 'MESSAGE_REMOVED'].includes(item.type)"
          (mouseleave)="onEntryMouseLeave(item)"
          >

          <div class="wrapper wrapper-{{ item.type | lowercase }}"
            [ngClass]="{'wrapper-muted': ['PENDING', 'TOO_LARGE', 'VIRUS_DETECTED', 'ERROR', 'INCONSISTENT_MIME_TYPE'].includes(item.file_status) }"
            (mouseenter)="onEntryMouseEnter(item)">

            <div class="btn-group options-button" dropdown placement="top right" *ngIf="mustShowItemOptions(item)">
              <button id="button_convo_entry_dropdown" dropdownToggle type="button" class="dropdown-toggle" aria-controls="note_dropdown">
                <span [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
              </button>

              <ul id="note_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_convo_entry_dropdown">
                <li role="menuitem">
                  <a class="dropdown-item text-danger" href="" (click)="deleteMessage($event, item)" >{{ 'pages.default.conversations.delete_message' | translate }}</a>
                </li>
              </ul>
            </div>

            <app-avatar [profilePicture]="conversation.getParticipantForUid(item.created_by)?.profile_picture" *ngIf="!isSender(item)"></app-avatar>

            <div class="name" *ngIf="!isSender(item)">{{ conversation.getParticipantForUid(item.created_by)?.first_name }} {{ conversation.getParticipantForUid(item.created_by)?.last_name }}</div>

            <!--  -->
            <!-- Text Message - Start -->
            <!--  -->
            <div class="message" *ngIf="item.type === 'MESSAGE'">
              <p>{{ item.message }}</p>
            </div>
            <!--  -->
            <!-- Text Message - End -->
            <!--  -->

            <!--  -->
            <!-- File Message - Start -->
            <!--  -->
            <div class="attachment" *ngIf="item.type === 'FILE_MESSAGE'">
              <a href="" class="preview" (click)="openAttachment($event, item)" *ngIf="item?.file_status === 'SAFE'">
                <span [inlineSVG]="'/assets/svg/page.svg'" [ngClass]="{'text-secondary': !isSender(item)}"></span>
                <p class="file-name">{{ item?.file?.file_name}} {{ item?.file?.created_at | timeZoneDate }} {{ 'general.at' | translate }} {{ item?.file?.created_at | time }}</p>
                <p class="meta"><span class="text-uppercase">{{ item?.file?.extension }}</span> {{ 'pages.default.conversations.file' | translate }}<span *ngIf="item?.file?.size"> ({{ item?.file?.size | bytes}})</span></p>
                <p class="file-link"><span [inlineSVG]="'/assets/svg/link.svg'"></span><span>{{ 'pages.default.conversations.view_attachment' | translate }}</span></p>
              </a>

              <div *ngIf="item?.file_status !== 'SAFE'">
                <div class="preview" [ngSwitch]="item?.file_status">
                  <span [inlineSVG]="'/assets/svg/page.svg'"></span>
                  <p class="file-name" *ngIf="item?.file">{{ item.file?.file_name}} {{ item.file?.created_at | timeZoneDate }} {{ 'general.at' | translate }} {{ item.file?.created_at | time }}</p>

                  <p class="error-message" *ngSwitchCase="'INCONSISTENT_MIME_TYPE'">{{ 'pages.default.conversations.upload_failed_inconistent_mime_type' | translate }}</p>
                  <p class="error-message" *ngSwitchCase="'VIRUS_DETECTED'">{{ 'pages.default.conversations.upload_failed_virus_detected' | translate }}</p>
                  <p class="error-message" *ngSwitchCase="'PENDING'">{{ 'pages.default.conversations.processing' | translate }}</p>
                  <p class="error-message" *ngSwitchDefault>{{ 'pages.default.conversations.upload_failed' | translate }}</p>

                  <div class="pb-1"></div>
                </div>
              </div>

              <div class="message" *ngIf="item?.message">
                <p>{{ item.message }}</p>
              </div>
            </div>
            <!--  -->
            <!-- File Message - End -->
            <!--  -->

            <!--  -->
            <!-- Removed Message - Start -->
            <!--  -->
            <div class="conversation-note" *ngIf="item.type === 'MESSAGE_REMOVED'">
              <div class="note-content">
                {{ 'pages.default.conversations.message_removed' | translate }}
              </div>
            </div>
            <!--  -->
            <!-- Removed Message - End -->
            <!--  -->

            <!--  -->
            <!-- Audio Message - Start -->
            <!--  -->
            <div class="sound" *ngIf="item.type === 'AUDIO_MESSAGE'">
              <app-audio-player
                [convo]="conversation"
                [convoItem]="item"
                [inputClass]="!isSender(item) ? '' : 'blue'"
              ></app-audio-player>
            </div>
            <!--  -->
            <!-- Audio Message - End -->
            <!--  -->

            <div class="timestamp">{{ item.created_at | time }}</div>
          </div>
        </div>

        <div class="conversation-note" *ngIf="item.type === 'HCP_JOINED'">
          <div class="note-content">
            {{ 'pages.default.conversations.added_to_convo' | translate:{ firstPerson:conversation.getParticipantForUid(item.created_by)?.getFullName(), secondPerson:conversation.getParticipantForUid(item.hcp_uid)?.getFullName() } }}
          </div>
        </div>

        <div class="conversation-note" *ngIf="item.type === 'HCP_LEFT'">
          <div class="note-content">
            {{ 'pages.default.conversations.left_the_convo' | translate:{ person:conversation.getParticipantForUid(item.hcp_uid)?.getFullName() } }}
          </div>
        </div>

        <div class="conversation-note" *ngIf="item.type === 'HCP_REMOVED' && item.created_by">
          <div class="note-content">
            {{ 'pages.default.conversations.removed_from_convo_by_hcp' | translate:{ firstPerson:conversation.getParticipantForUid(item.created_by)?.getFullName(), secondPerson:conversation.getParticipantForUid(item.hcp_uid)?.getFullName() } }}
          </div>
        </div>

        <div class="conversation-note" *ngIf="item.type === 'HCP_REMOVED' && !item.created_by">
          <div class="note-content">
            {{ 'pages.default.conversations.removed_from_convo_by_system' | translate:{ person:conversation.getParticipantForUid(item.hcp_uid)?.getFullName() } }}
          </div>
        </div>

        <div class="conversation-note" *ngIf="item.type === 'CHANGED_SUBJECT' || item.type === 'SUBJECT'">
          <div class="note-content">
            {{ 'pages.default.conversations.subject_change' | translate:{ person:conversation.getParticipantForUid(item.created_by)?.getFullName(), newValue:item.new_message } }}
          </div>
        </div>
      </div>
    </div>

    <div class="scroll-start-position body-content pt-0" #end></div>
  </div>
  <!-- -->
  <!-- Conversation End -->
  <!-- -->

  <!-- -->
  <!-- Scroll 'Start' Position Start -->
  <!-- -->

  <!-- -->
  <!-- Scroll 'Start' Position End -->
  <!-- -->

  <!-- -->
  <!-- Conversation Footer Start -->
  <!-- -->
  <div class="layout-footer-pusher footer-conversation"></div>
  <div class="layout-footer footer-conversation">
    <div class="footer-notice">{{ 'pages.default.conversations.dont_share_sensitive_info' | translate }}</div>


    <div class="footer-pending-attachments" *ngIf="pendingAttachments?.length">
      <!-- attachment - start -->
      <div class="single-item-block mt-2 attachment" [ngClass]="{'block-danger': ['TOO_LARGE', 'VIRUS_DETECTED', 'ERROR', 'INCONSISTENT_MIME_TYPE'].includes(attachment.status) }" *ngFor="let attachment of pendingAttachments">
        <div class="block-wrapper p-start-3 p-end-1 attachment-icon">
          <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/page.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label attachment-name">{{ attachment.meta.file_name}} {{ 'general.at' | translate }} {{ attachment.meta?.created_at | timeZoneDate }} {{ attachment.meta?.created_at | time }}</p>
          <p class="value attachment-info"><span class="text-uppercase">{{ attachment?.meta?.extension }}</span> {{  'pages.default.conversations.file' | translate }}<span *ngIf="attachment.meta.size"> ({{ attachment.meta.size | bytes}})</span></p>
        </div>

        <div class="block-wrapper text-nowrap m-start-auto attachment-processing" *ngIf="attachment.status === 'WAITING'">
          <p>
            <span class="align-middle">{{ 'pages.default.conversations.processing' | translate }}</span>
            <span class="align-middle m-start-1" [inlineSVG]="'/assets/svg/question.svg'"></span>
          </p>
        </div>

        <div class="block-wrapper text-nowrap m-start-auto attachment-exceeds" *ngIf="(attachment.status === 'TOO_LARGE')">
          <p class="text-secondary small">{{ 'pages.default.conversations.size_exceeds' | translate }}</p>
        </div>

        <div class="block-wrapper text-nowrap m-start-auto attachment-unsafe" *ngIf="(attachment.status === 'VIRUS_DETECTED')">
          <p class="text-secondary small">{{ 'pages.default.conversations.upload_failed_virus_detected' | translate }}</p>
        </div>

        <div class="block-wrapper text-nowrap m-start-auto attachment-error" *ngIf="(attachment.status === 'ERROR')">
          <p class="text-secondary small">{{ 'pages.default.conversations.upload_failed' | translate }}</p>
        </div>

        <div class="block-wrapper text-nowrap m-start-auto attachment-incosistent-mime-type" *ngIf="(attachment.status === 'INCONSISTENT_MIME_TYPE')">
          <p class="text-secondary small">{{ 'pages.default.conversations.upload_failed_inconistent_mime_type' | translate }}</p>
        </div>

        <div class="block-action-wrapper attachment-done m-start-auto" *ngIf="attachment.status !== 'WAITING'">
          <a href="" class="block-action action-muted bg-transparent" (click)="setStateToDeleted($event, attachment)">
            <span class="icon" [inlineSVG]="'/assets/svg/close.svg'"></span>
          </a>
        </div>
      </div>
      <!-- attachment - start -->
    </div>

    <div class="footer-content">
      <input type="text" #newMsg id="newMsg" [(ngModel)]="newMsgInput" [placeholder]="('pages.default.conversations.type_a_message' | translate)" maxlength="{{newMsgMaxLength}}"
              (keyup.enter)="addMessage()" required>

      <label class="input-button" for="file-input" id="conversation-new-attachment">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/attachment.svg'"></span>
        <input #fileInput id="file-input" type="file" class="invisible position-absolute" accept="{{ extensionList }}" (change)="handleAttachmentInput($event.target.files)" multiple>
      </label>

      <button class="input-button" id="conversation-send-new-message" (click)="addMessage()" [disabled]="!canAddMessage">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
      </button>
    </div>
  </div>
  <!-- -->
  <!-- Conversation Footer End -->
  <!-- -->
</div>
