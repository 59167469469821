import { KpiPeriodDirection } from "../enums/kpi-period-direction";

export class KpiPeriod {
  public amount: number;
  public percentage: number;
  public direction: KpiPeriodDirection
  private scope: string;

  constructor(item?) {
    if (item) {
      this.amount = item.amount;
      this.percentage = item.percentage;
      this.direction = item.direction;
      this.scope = item.scope;
    }
  }

  get isUp(): boolean {
    return this.direction === KpiPeriodDirection.UP;
  }

  get isDown(): boolean {
    return this.direction === KpiPeriodDirection.DOWN;
  }

  get isEqual(): boolean {
    return this.direction === KpiPeriodDirection.EQUAL;
  }

  get isPositive(): boolean {
    if(this.scope === 'successful') {
      return this.isUp;
    } else if (['unsuccessful', 'hospital_approval_pending', 'consent_pending'].includes(this.scope)) {
      return this.isDown;
    } else {
      return false;
    }
  }

  get isNegative(): boolean {
    if(this.scope === 'successful') {
      return this.isDown;
    } else if (['unsuccessful', 'hospital_approval_pending', 'consent_pending'].includes(this.scope)) {
      return this.isUp;
    } else {
      return false;
    }
  }
}
