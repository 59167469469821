<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <button type="button" id="stop-pathway-form-close" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.stop_pathway_modal.stop_pathway' | translate }}</h2>

  <div class="user-compact mb-3">
    <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

    <div class="user-compact-info">
      <p class="title text-truncate">{{ patient?.getFullName() || '...' }}</p>
      <p *ngIf="patient">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{ patient?.age() |
        translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</p>
    </div>
  </div>

  <div *ngIf="!patientPathways?.length && !isLoading">
    <p class="text-muted">{{ 'modals.stop_pathway_modal.no_available_pathways' | translate }}</p>
  </div>

  <form [formGroup]="form" *ngIf="patientPathways?.length">
    <div class="form-group required">
      <app-select
      [placeholder]="('modals.stop_pathway_modal.pathway' | translate)"
      [clearable]="false"
      [searchable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="patientPathways"
      [bindValue]="'uid'"
      formControlName="pathway"
      [asFilter]="false"
      [showCheckboxes]="false"
      [showUserDetails]="false"
      [readonly]="readonly"
      >
      </app-select>
      <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
    </div>

    <hr/>

    <div class="form-group required">
      <app-select
      [placeholder]="('modals.stop_pathway_modal.reason' | translate)"
      [clearable]="false"
      [searchable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="reasons"
      [bindValue]="'key'"
      [bindLabel]="'name'"
      formControlName="reason_code"
      [asFilter]="false"
      [showCheckboxes]="false"
      [showUserDetails]="false"
      >
      </app-select>
      <app-feedback-field [field]="form.get('reason')"></app-feedback-field>
    </div>

    <div class="form-group styled required floating-label" *ngIf="form.get('reason_code').value === 'OTHER'">
      <textarea autosize id="reason" class="form-control w-100" formControlName="reason" [placeholder]="'modals.stop_pathway_modal.specify_reason' | translate" required [maxlength]="reasonMaxLength"></textarea>
      <label for="reason" class="required">{{ 'modals.stop_pathway_modal.specify_reason' | translate }}</label>
      <p class="validation-feedback">{{ 'modals.stop_pathway_modal.specify_reason' | translate }}</p>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !form?.controls?.reason?.value)">
        {{( form?.controls?.reason?.value?.length || '0' ) | translateNumber }}/{{reasonMaxLength | translateNumber}}
      </p>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="stop-pathway-form-cancel" (click)="onHandleClose()">{{ 'action.cancel' |
      translate}}</button>
    <button class="btn btn-secondary m-start-3" id="stop-pathway-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()" [disabled]="!form.valid">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'modals.stop_pathway_modal.stop_pathway' | translate }}</span>
    </button>
  </div>
</div>
