import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EditHospitalTeamModalComponent } from '../../../../modals/edit-hospital-team-modal/edit-hospital-team-modal.component';
import { CareModule } from '../../../../models/care-module';
import { GeneralService } from '../../../../services/general.service';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-admin-care-modules-details',
  templateUrl: './admin-care-modules-details.component.html'
})
export class AdminCareModulesDetailsComponent implements OnInit {
  public isLoading: boolean = false;
  public careModuleUid: string;
  public careModule: CareModule;

  constructor(
    public activatedRoute: ActivatedRoute,
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public modalService: BsModalService,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.careModuleUid = params.careModuleUid;
    });

    this.getCareModule();
  }

  getCareModule(showLoading: boolean = true): void {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    if (showLoading) {
      this.isLoading = true;
    }

    this.hospitalService.getCareModule(hospitalUid, this.careModuleUid).subscribe((careModule: CareModule) => {
      this.careModule = careModule;
      this.dataService.set(DataService.BreadCrumbCareModuleTitle, careModule.translationKey);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  editHospitalTeam(event: MouseEvent): void {
    event.preventDefault();

    const modalRef = this.modalService.show(EditHospitalTeamModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: { careModule: this.careModule }
      }));

    modalRef.content.hospitalTeamUpdatedEvent.subscribe(() => {
      this.getCareModule(false);
    });
  }
}
