import { TranslationInterface } from '../interfaces/translation.interface';
import { PhoneNumberInterface } from '../interfaces/phone-number.interface';

export class BulkApproval {
  uid: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  email: string;
  phone_number: PhoneNumberInterface;
  gender: string;
  country: string;
  language: string;
  reviewed: boolean;
  date_of_birth: string;
  submission_date: string;
  approval_date: string;
  approval_status: string;
  care_module_name: TranslationInterface;

  public careModuleTranslationKey: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.first_name = item.first_name ? item.first_name : '';
    this.last_name = item.last_name ? item.last_name : '';
    this.preferred_name = item.preferred_name ? item.preferred_name : '';
    this.email = item.email ? item.email : '';
    this.phone_number = item.phone_number ? item.phone_number : '';
    this.gender = item.gender ? item.gender : '';
    this.country = item.country ? item.country : '';
    this.language = item.language ? item.language : '';
    this.reviewed = item.reviewed ? item.reviewed :  '';
    this.date_of_birth = item.date_of_birth ? item.date_of_birth: '';
    this.submission_date = item.submission_date ? item.submission_date :  '';
    this.care_module_name = item.care_module_name ? item.care_module_name : '';
    this.approval_date = item.approval_date ? item.approval_date : '';
    this.approval_status = item.approval_status ? item.approval_status : '';

    if(this.care_module_name) {
      this.careModuleTranslationKey = `shared.${this.care_module_name?.region}.${this.care_module_name?.key}`;
    }
  }

  get approved() {
    return (this.approval_status == 'APPROVED');
  }

  get declined() {
    return (this.approval_status == 'CC_DECLINED');
  }
}
