<div class="modal-body">
  <button type="button" class="close" id="query-list-candidate-users-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.candidate_users.other_candidate_users' | translate }}</h2>

  <div class="participant-item" *ngFor="let participant of participants">
    <app-avatar [profilePicture]="participant?.profile_picture"></app-avatar>

    <div class="meta">
      <p class="title">{{ participant?.getFullName() }}</p>
    </div>
  </div>
</div>

<div class="modal-footer py-1">
  <div class="m-start-auto">
    <button class="btn btn-link" id="query-list-candidate-users-modal-handle-close" (click)="handleClose()">{{ 'action.close' | translate }}</button>
  </div>
</div>
