import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class PhoneCodeFieldsValidator {
  static validate(number: string, code: string) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      const numberControl: AbstractControl = group.get(number);
      const codeControl: AbstractControl = group.get(code);

      if(numberControl.value && !codeControl.value) {
        codeControl.setErrors({'required': true});
      } else if (!numberControl.value && codeControl.value) {
        numberControl.setErrors({'required': true});
      } else {
        if (codeControl.hasError('required')) {
          delete codeControl.errors['required'];
          codeControl.updateValueAndValidity();
        }

        if( numberControl.hasError('required')) {
          delete numberControl.errors['required'];
          numberControl.updateValueAndValidity();
        }
        return {};
      }
    };
  }
}