import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserTaskAppointmentModalComponent } from '../modals/user-task-appointment-modal/user-task-appointment-modal.component';
import { UserTaskStandardModalComponent } from '../modals/user-task-standard-modal/user-task-standard-modal.component';
import { UserTaskBasic } from '../models/user-task-basic';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class UserTaskModalService {

  constructor(
    public modalService: BsModalService
  ) { }

  /*
  ** openUserTask()
  **
  ** SHOULD ALWAYS RETURN THE MODAL.
  ** WE NEED TO BE ABLE TO LISTN TO MODAL OUTPUT EVENTS
  ** Example => Output Event for SUBMIT SUCCESS
  */
  openUserTask(userTaskBasic: UserTaskBasic): BsModalRef {
    switch (userTaskBasic.type) {
      case 'APPOINTMENT': return this.showUserTaskAppointmentModal(userTaskBasic);;
      case 'CHECKLIST': return this.showUserTaskChecklistModal(userTaskBasic);
      case 'STANDARD': return this.showUserTaskStandardModal(userTaskBasic);
    }
  }

  showUserTaskAppointmentModal(userTaskBasic: UserTaskBasic): BsModalRef {
    const initialState = {
      userTaskBasic: userTaskBasic,
      task_id: userTaskBasic.uid,

    };

    return this.modalService.show(UserTaskAppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  showUserTaskChecklistModal(userTask: UserTaskBasic): BsModalRef {
    return null; //temp, should return BsModalRef
  }

  showUserTaskStandardModal(userTaskBasic: UserTaskBasic): BsModalRef {
    const initialState = {
      userTaskBasic
    };

    return this.modalService.show(UserTaskStandardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-xl modal-compact',
        initialState
      })
    );
  }
}
