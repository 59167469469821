import { Injectable } from "@angular/core";

import { QueryListDetailComponent } from "../pages/default/query-list-detail/query-list-detail.component";

@Injectable()
export class QueryListCanDeactivateGuard
   {
  canDeactivate(component) {
    return component.canDeactivate();
  }
}