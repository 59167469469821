import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DateFormat } from '../../models/date-format';
import { Pathway, PathwayStatus, PatientPathwayStopReason } from '../../models/pathway';
import { Patient } from '../../models/patient';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import { LocaleService } from '../../services/locale.service';
import { PathwayService } from '../../services/pathway.service';
import { StopPathwayConfirmModalComponent } from '../stop-pathway-confirm-modal/stop-pathway-confirm-modal.component';

@Component({
  selector: 'app-stop-pathway-modal',
  templateUrl: './stop-pathway-modal.component.html'
})
export class StopPathwayModalComponent implements OnInit {
  public patient: Patient;
  public selectedPathway: Pathway;
  public patientPathways: Pathway[];
  public reasons: { key: string; name: any; }[];

  public validationVisible: boolean = false;
  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public readonly: boolean = false;
  public form: UntypedFormGroup;
  public dateFormat: DateFormat;
  public hospitalUid: string;

  public reasonMaxLength: number = 50;

  @Output() public pathwayStopped = new EventEmitter<Pathway>();

  constructor(
    public readonly bsModalRef: BsModalRef,
    public pathwayService: PathwayService,
    public hcpService: HcpService,
    public localeService: LocaleService,
    public translateService: TranslateService,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.reasons = Object.keys(PatientPathwayStopReason).map(key => ({
      key,
      name: this.translateService.instant(`stop_pathway_reason.${PatientPathwayStopReason[key].toLowerCase()}`)
    }));

    this.getPatientPathways(this.patient?.uid);
    this.formSetup();
  }

  getPatientPathways(patientUid: string): void {
    this.isLoading = true;

    this.pathwayService.getPathwaysByHospital(this.hospitalUid, patientUid).subscribe((pathways: Pathway[]) => {
      this.handlePathwayResult(pathways);
    }, () => this.isLoading = false);
  }

  handlePathwayResult(pathways: Pathway[]) {
    this.patientPathways = pathways.filter((pathway: Pathway) => pathway.status === PathwayStatus.ACTIVE);

    if (this.patientPathways?.length === 1 && this.patientPathways[0].uid === this.selectedPathway?.uid) {
      this.readonly = true;
    }

    this.isLoading = false;
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    const activePathwayUid = (this.selectedPathway?.status === PathwayStatus.ACTIVE) ? this.selectedPathway.uid : '';

    this.form = new UntypedFormGroup({
      pathway: new UntypedFormControl(activePathwayUid, Validators.required),
      reason_code: new UntypedFormControl('', Validators.required),
      reason: new UntypedFormControl('')
    });

    this.form.get('reason_code').valueChanges.subscribe(value => {
      if (value === PatientPathwayStopReason.OTHER) {
        this.form.get('reason').setValidators([Validators.required, Validators.maxLength(this.reasonMaxLength)])
      } else {
        this.form.get('reason').clearValidators();
      }

      this.form.get('reason').updateValueAndValidity();
    });
  }

  handleSubmit() {
    if (!this.form.valid) {
      this.validationVisible = true;
      return;
    }

    this.validationVisible = false;

    const initialState = {
      patient: this.patient,
      pathway: this.patientPathways.find((pathway: Pathway) => pathway.uid === this.form.get('pathway').value),
      reason_code: this.reasons.find(reason => reason.key === this.form.get('reason_code').value),
      reason: this.form.get('reason').value
    };

    const modalref = this.modalService.show(StopPathwayConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-m',
        initialState
      })
    );

    modalref.content.pathwayStopConfirmed.subscribe(isConfirmed => {
      if (isConfirmed) {
        modalref.hide();
        this.pathwayStopped.emit();
      }
    });
  }

  onHandleClose(): void {
    this.bsModalRef.hide();
  }
}
