import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { QueryList, QueryListStatus } from "../../models/query-list";
import { HcpService } from "../../services/hcp.service";
import { QueryListService } from "../../services/query-list.service";

@Component({
  selector: 'app-patient-detail-checklists',
  templateUrl: './patient-detail-checklists.component.html'
})
export class PatientDetailChecklistsComponent implements OnInit, OnChanges {
  public QueryListStatus = QueryListStatus;
  public hcpUid: string;

  @Input() patientUid: string;
  @Input() pathwayUid: string;

  queryLists: QueryList[];
  loading: boolean;
  totalChecklists: number;


  constructor(
    private hcpService: HcpService,
    public queryListService: QueryListService
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['pathwayUid']) {
      this.loadChecklists();
    }
  }

  grayedOut(form): boolean {
    return (form.status === QueryListStatus.COMPLETED|| form.status === QueryListStatus.TIMED_OUT);
  }

  loadChecklists(showLoading = true) {
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();
    if (showLoading) {
      this.queryLists = [];
      this.loading = true;
    }

    const checklistsObservable = this.queryListService.getQueryLists(this.hcpUid, this.patientUid, this.pathwayUid, {query_list_type: 'CHECKLIST'}, 'status,desc,changed_at,desc', 0, 3);

    checklistsObservable.subscribe(result => {
      this.loading = false;
      this.queryLists = result['queryLists'];
      this.totalChecklists = result['pagination']['total_elements'];
    });
  }
}
