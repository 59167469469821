<div class="modal-body">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.edit_personal_details.title' | translate }}</h2>

  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}">
    <!-- Profile picture -->
    <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>

    <div class="control-profile-picture mb-3">
      <app-avatar-input formControlName="profile_picture"></app-avatar-input>
    </div>

    <!-- Gender -->
    <div class="pb-2">
      <label class="small d-block required">{{ 'form.labels.gender' | translate }}</label>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="radioSexMale" formControlName="gender" value="MALE">
        <label class="custom-control-label" for="radioSexMale">{{ 'form.labels.male' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="radioSexFemale" formControlName="gender" value="FEMALE">
        <label class="custom-control-label" for="radioSexFemale">{{ 'form.labels.female' | translate }}</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline mb-1">
        <input type="radio" class="custom-control-input" id="radioSexOther" formControlName="gender" value="UNKNOWN">
        <label class="custom-control-label" for="radioSexOther">{{ 'form.labels.prefer_not_say' | translate }}</label>
      </div>
      <app-feedback-field [field]="form.get('gender')" [extraClass]="'general-feedback'"></app-feedback-field>
    </div>

    <!-- First name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputFirstName" class="form-control w-100" [placeholder]="('form.labels.first_name' | translate)" formControlName="first_name">
      <label for="inputFirstName" class="required">{{ 'form.labels.first_name' | translate }}</label>
      <app-feedback-field [field]="form.get('first_name')"></app-feedback-field>
    </div>

    <!-- Last name -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputLastName" class="form-control w-100" [placeholder]="'.form.labels.last_name' | translate" formControlName="last_name">
      <label for="inputLastName" class="required">{{ 'form.labels.last_name' | translate }}</label>
      <app-feedback-field [field]="form.get('last_name')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select
        [placeholder]="('form.labels.language' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="languageOptions"
        [bindValue]="'value'"

        [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false"

        formControlName="language"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
      <app-feedback-field [field]="form.get('language')"></app-feedback-field>
    </div>

    <!-- Function -->
    <div class="form-group required" *ngIf="authenticationService.hasCcRole()">
      <app-select
        [placeholder]="('form.labels.function' | translate)"
        [clearable]="false"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="functionOptions"
        [bindValue]="'value'"

        formControlName="function"

        [asFilter]="false"
        [showCheckboxes]="false"
      ></app-select>
    </div>

    <!-- Job Title -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputJobTitle" class="form-control w-100" [placeholder]="'.form.labels.job_title' | translate" formControlName="job_title">
      <label for="inputJobTitle" class="required">{{ 'form.labels.job_title' | translate }}</label>
    </div>

    <!-- Location -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputLocation" class="form-control w-100" [placeholder]="'.form.labels.location' | translate" formControlName="location">
      <label for="inputLocation">{{ 'form.labels.location' | translate }}</label>
    </div>

    <!-- Location URL -->
    <div class="form-group styled floating-label">
      <input type="text" id="inputLocation_url" class="form-control w-100" [placeholder]="'.form.labels.location_url' | translate"
             formControlName="location_url">
      <label for="inputLocation_url">{{ 'form.labels.location_url' | translate }}</label>
    </div>
  </form>
</div>


<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-personal-modal-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="edit-personal-modal-confirm" [ngClass]="{'loader': isSaving}" (click)="handleConfirm()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
