import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Country } from '../../models/country';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { LanguageCode } from '../../models/language-code';
import { ErrorService } from '../../services/error.service';
import { HcpService } from '../../services/hcp.service';
import { NotMatchingFieldsValidator } from '../../validators/not-matching-fields-validator';
import { CareModule } from '../../models/care-module';
import { DateFormat } from '../../models/date-format';
import { TimedCode } from '../../models/timed-code';
import { CountryService } from '../../services/country.service';
import { GeneralService } from '../../services/general.service';
import { HospitalService } from '../../services/hospital.service';
import { LanguageService } from '../../services/language.service';
import { LocaleService } from '../../services/locale.service';
import { PatientService } from '../../services/patient.service';
import { ScopeService } from '../../services/scope.service';
import { TimedCodeService } from '../../services/timed-code.service';
import { PhoneCodeFieldsValidator } from '../../validators/phone-code-fields.validator';
import { HospitalCode } from '../../models/hospital-code';


@Component({
  selector: 'app-add-patient-modal',
  templateUrl: './add-patient-modal.component.html'
})
export class AddPatientModalComponent implements OnInit {
  @Output() onPatientCreated: EventEmitter<any> = new EventEmitter();

  public method: 'onboarding' | 'selfRegistration' = 'selfRegistration';
  public step: 'choice' | 'form' = 'choice';
  public careModules: CareModule[];
  public selectedCareModule: CareModule;
  public dateFormat: DateFormat;
  public consent = false;

  public hcpSearchResults: HealthCareProfessional[] = [];
  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  public form: UntypedFormGroup;
  public validationVisible = false;
  public languageOptions: LanguageCode[];
  public isSaving: boolean;
  public countryOptions: Country[];
  public dialCodeOptions = [];
  public isLoadingTimedCode: boolean;
  public timedCode: TimedCode;
  public defaultCaseManager: HealthCareProfessional;
  public defaultClinicalLead: HealthCareProfessional;
  public hospitalUid: string;

  constructor(
    public readonly bsModalRef: BsModalRef,
    public bsDatepickerConfig: BsDatepickerConfig,
    public readonly hospitalService: HospitalService,
    public readonly scopeService: ScopeService,
    public readonly hcpService: HcpService,
    public readonly timedCodeService: TimedCodeService,
    public readonly localeService: LocaleService,
    public readonly languageService: LanguageService,
    public readonly countryService: CountryService,
    public readonly generalService: GeneralService,
    public readonly formBuilder: UntypedFormBuilder,
    public readonly patientService: PatientService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public errorService: ErrorService
  ) { }

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();

    this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat(preferences.locale);
    this.bsDatepickerConfig.adaptivePosition = true;

    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.getCareModules();
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  onHandleNext(): void {
    this.step = 'form';
    this.formSetup();
    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
    });

    this.countryService.getCountriesFromCms().subscribe((countries: Country[]) => {
      this.countryOptions = countries;
    });

    this.dialCodeOptions = this.generalService.dialCodes;

    this.searchHcpList(); // fetch all hcps

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });
  }

  getCareModules() {
    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModules(hospital_uid).subscribe(result => {
      this.careModules = result;
    }, error => { });
  }

  careModuleSelected(careMod) {
    this.selectedCareModule = undefined;

    if (careMod) {
      this.selectedCareModule = careMod;
      this.getTimedCodes(careMod.uid);
    }
  }

  careModuleSelectedInForm(careMod) {
    this.getCareModule(careMod.uid);
  }

  getCareModule(uid: string) {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModule(hospitalUid, uid).subscribe(result => {
      if (result.default_case_manager) {
        this.form.get('patient_pathway').get('case_manager_uid').setValue(result.default_case_manager.uid);

        this.defaultCaseManager = result.default_case_manager;
        if (!this.hcpSearchResults.includes(this.defaultCaseManager)) {
          this.hcpSearchResults.push(this.defaultCaseManager);
        }
      } else {
        this.form.get('patient_pathway').get('case_manager_uid').setValue('');
      }

      if (result.default_clinical_lead) {
        this.form.get('patient_pathway').get('clinical_lead_uid').setValue(result.default_clinical_lead.uid);

        this.defaultClinicalLead = result.default_clinical_lead;
        if (!this.hcpSearchResults.includes(this.defaultClinicalLead)) {
          this.hcpSearchResults.push(this.defaultClinicalLead);
        }
      } else {
        this.form.get('patient_pathway').get('clinical_lead_uid').setValue('');
      }
    })
  }

  getTimedCodes(careModuleId: string) {
    this.isLoadingTimedCode = true;
    this.timedCode = undefined;
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    this.timedCodeService.getTimedCodes(this.hospitalUid, { care_module_uid: [careModuleId], status: ['ACTIVE'] }, 0, 1, ['end_at,desc']).subscribe(result => {
      this.onGetTimedCodesSuccess(result, careModuleId)
    }, () => {
      this.isLoadingTimedCode = false;
    });
  }

  onGetTimedCodesSuccess(result, careModuleId): void {
    if (result?.codes?.length) {
      const timedCode = result.codes[0];
      const codeEnd = moment(timedCode?.end_at);
      const today = moment(new Date());

      if (today.isBefore(codeEnd)) {
        this.timedCode = timedCode;
      }

      if (today.add(6, 'days').isBefore(codeEnd)) {
        this.timedCode = timedCode;
        this.isLoadingTimedCode = false;
      } else {
        this.createTimedCodeForCareModule(this.hospitalUid, careModuleId);
      }
    } else {
      this.createTimedCodeForCareModule(this.hospitalUid, careModuleId);
    }
  }

  createTimedCodeForCareModule(hospitalUid: string, careModuleId: string): void {
    this.timedCodeService.getHospitalCodes(hospitalUid).subscribe((hospitalCodes: HospitalCode[]) => {
      this.getHospitalCodesSuccess(hospitalCodes, hospitalUid, careModuleId);
    }, () => this.isLoadingTimedCode = false);
  }

  getHospitalCodesSuccess(hospitalCodes: HospitalCode[], hospitalUid: string, careModuleId: string): void {
    const hospitalCode: HospitalCode = hospitalCodes.filter((hospitalCode: HospitalCode) => hospitalCode.care_module.uid === careModuleId)[0];

    if (hospitalCode) {
      this.timedCodeService.createTimedCode(hospitalUid, hospitalCode.uid, new Date().toISOString()).subscribe((timedCode: TimedCode) => {
        this.timedCode = timedCode;
        this.isLoadingTimedCode = false

      }, () => this.isLoadingTimedCode = false);

    } else {
      this.isLoadingTimedCode = false;
    }
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  searchHcpList(searchTerm?): void {
    if (!searchTerm) {
      this.hcpSearchResults = [];
    }

    this.hcpService.getPaged({ last_name: searchTerm || '', status: 'ACTIVE' }, 'last_name,asc').subscribe(
      response => {
        this.hcpSearchResults = response.items;
      }
    );
  }

  /// step create patient
  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      patient_pathway: new UntypedFormGroup({
        care_module_uid: new UntypedFormControl('', [Validators.required]),
        clinical_lead_uid: new UntypedFormControl('', [Validators.required]),
        case_manager_uid: new UntypedFormControl('', [Validators.required]),
      }),
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      electronic_medical_record: [''],
      preferred_name: [''],
      gender: ['', [Validators.required]],
      date_of_birth: ['', [Validators.required]],
      language: ['', [Validators.required]],
      country: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: new UntypedFormGroup({
        code: new UntypedFormControl(''),
        number: new UntypedFormControl('')
      }),

    }, {
      validators: [
        NotMatchingFieldsValidator.validate('patient_pathway.clinical_lead_uid', 'patient_pathway.case_manager_uid', 'roles'),
        PhoneCodeFieldsValidator.validate('phone_number.number', 'phone_number.code')
      ]
    });
  }

  handleSubmit() {
    if (this.isSaving) {
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.isSaving = true;
      const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();

      this.patientService.createPatientByHospital(hospital_uid, this.form.value).subscribe(result => {
        this.onCreateSuccess();
      }, error => {
        this.onRequestError(error);
      });
    }
  }

  onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach(err => {
        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  onCreateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.add_patient.patient_created'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.bsModalRef.hide();
    this.onPatientCreated.emit();
  }

  actionCopyCode(event, value) {
    event.preventDefault();
    this.generalService.copyToClipboard(value);
    this.toastrService.info(this.translate.instant('pages.default.administration.onboarding_codes.code_copied_to_clipboard'), null, {
      disableTimeOut: false,
      timeOut: 3000
    });
  }
}
