import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MfaMethod } from '../models/mfa-method';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static RoleMfaRequired = "ROLE_MFA_REQUIRED";
  public static RoleConsentRequired = "ROLE_CONSENT_REQUIRED";
  public static RolePwChangeRequired = "ROLE_PW_CHANGE_REQUIRED";
  public static RoleHcp = "ROLE_HCP";
  public static RoleCc = "ROLE_CC";
  public accessTokenValid = true;
  public authenticationSubject: BehaviorSubject<any>;
  private readonly platformUrl: string;

  @Output() onLogOut: EventEmitter<any> = new EventEmitter();

  public static isFinalRole(role: string) {
    return ([
      AuthenticationService.RoleHcp,
      AuthenticationService.RoleCc
    ]).includes(role);
  }


  constructor(
    public dataService: DataService
  ) {
    this.authenticationSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('authentication')));
  }

  public get authentication() {
    return this.authenticationSubject.value;
  }

  getPayloadFromToken(token: string): any {
    const parts = token.split('.');
    return JSON.parse(atob(parts[1]));
  }


  setAuthenticationData(data: object): boolean {
    if (data && data['access_token']) {
      const d = JSON.stringify(data);
      sessionStorage.setItem('authentication', d);
      this.authenticationSubject.next(d);
    }
    return true;
  }

  getAuthenticationData(): string {
    return sessionStorage.getItem('authentication');
  }

  getAccessToken(): string {
    const authentication_data = JSON.parse(this.getAuthenticationData());
    if (!authentication_data) {
      return '';
    }

    return authentication_data['access_token'];
  }

  getCorrelationId(): string {
    const authentication_data = JSON.parse(this.getAuthenticationData());

    if (!authentication_data?.access_token) {
      return '';
    }
    const data = this.getPayloadFromToken(authentication_data.access_token);
    return data.correlation_id;
  }

  getCurrentRoles(): string[] {
    const authentication_data = JSON.parse(this.getAuthenticationData());
    if (!authentication_data) {
      return [];
    }
    const data = this.getPayloadFromToken(authentication_data['access_token']);
    const roles = data['authorities'];
    return roles;
  }

  getCurrentFirstRole(): string {
    const roles = this.getCurrentRoles();

    if(roles && roles.length) {
      return roles[0];
    } else {
      return '';
    }
  }

  hasFinalRole() {
    const roles = this.getCurrentRoles();
    let hasFinalRole = false;

    roles.forEach(role => {
      if(AuthenticationService.isFinalRole(role)) {
        hasFinalRole = true;
      }
    });

    return hasFinalRole;
  }

  hasCcRole(): boolean {
    const roles = this.getCurrentRoles();

    if (roles && roles.length) {
      return roles.includes(AuthenticationService.RoleCc);
    } else {
      return false;
    }
  }

  destroyAuth() {
    sessionStorage.removeItem('authentication');
    sessionStorage.removeItem('C4T_shown_banner');

    this.authenticationSubject.next(null);
  }

  logout() {
    // destroy the auth
    this.destroyAuth();

    // clear the dataservice
    this.dataService.clearAll();

    // emit the logOut event
    this.onLogOut.emit();
  }



  getRefreshToken(): string {
    const authenticationData = JSON.parse(this.getAuthenticationData());
    return !authenticationData ? '' : authenticationData.refresh_token;
  }

  validateTimestamp(timestamp: number): boolean {
    const expiresAt = timestamp * 1000;
    return (expiresAt > Date.now()) ? true : false;
  }

  refreshTokenIsValid(): boolean {
    const refreshToken = this.getRefreshToken();

    if (refreshToken) {
      const payload = this.getPayloadFromToken(refreshToken);
      return this.validateTimestamp(payload.exp);
    }

    return false;
  }

  tokenIsValid(token: string): boolean {
    const payload = this.getPayloadFromToken(token);
    return this.validateTimestamp(payload.exp);
  }

  getMfaMethods(primary?: boolean): Array<MfaMethod> {
    const authentication_data = JSON.parse(this.getAuthenticationData());
    const mfas: any[] = authentication_data?.mfas || [];
    const mfaMethods: MfaMethod[] = mfas.map(mfa => {
      return new MfaMethod(mfa);
    });

    if(primary === true) {
      return mfaMethods.filter((_mfaMethod) => _mfaMethod.primary === true);
    } else if(primary === false) {
      return mfaMethods.filter((_mfaMethod) => _mfaMethod.primary === false);
    } else {
      return mfaMethods;
    }
  }
}
