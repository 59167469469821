export class Hospital {
  uid: string;
  name: string;
  defaultLanguage: string;
  defaultCountry: string;

  constructor(item?) {
    if (item) {
      this.uid = item.uid;
      this.name = item.name;
      this.defaultLanguage = item?.default_locale?.language;
      this.defaultCountry = item?.address?.country;
    }
  }
}
