<div class="h-100 d-flex align-items-center justify-content-center" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<form class="filters" *ngIf="filters && filters.groups.length > 0 && !isLoading">
  <h5>{{ 'pages.default.analytics.filters.title' | translate }}</h5>

  <div class="filter-group" *ngFor="let filter of filters.groups">
    <h6 class="filter-group-label" (click)="toggleFilterGroup(filter.id)"
      [attr.aria-expanded]="!isCollapsed(filter.id)">
      {{ 'pages.default.analytics.filters.group.' + filter.id + '.title' | translate }}
    </h6>

    <div class="filter-group-items" [collapse]="isCollapsed(filter.id)" [isAnimated]="true">
      <div class="custom-control custom-checkbox" *ngIf="filter.toggleAll">
        <input type="checkbox" class="custom-control-input" [id]="filter.id + '--all'" [name]="filter.id + '--all'"
          [ngModel]="isAllSelected(filter.items)" [indeterminate]="isIndeterminate(filter.id)"
          (change)="toggleAllItems(filter.id)">

        <label class="custom-control-label" [for]="filter.id + '--all'">
          <span>{{ 'pages.default.analytics.filters.toggle_all' | translate }}</span>
        </label>
      </div>

      <div class="custom-control custom-checkbox" *ngFor="let item of filter.items">
        <input type="checkbox" class="custom-control-input" [id]="filter.id + '--' + item.id"
          [name]="filter.id + '--' + item.id" [(ngModel)]="item.selected" (change)="toggleItem(item.id)">

        <label class="custom-control-label" [for]="filter.id + '--' + item.id">
          @if(item.key === 'countries') {
            <span>{{ item.label }}</span>
          } @else {
            <span>{{ 'pages.default.analytics.filters.group.'+ item.key +'.item.'+ item.label | translate }}</span>
          }

        </label>

      </div>
    </div>
  </div>
</form>
