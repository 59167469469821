import { HealthCareProfessional } from "./health-care-professional";

export class Ooo {
  public enabled: boolean = false;
  public start_date: string;
  public end_date: string;
  public task_delegate_enabled: boolean = false;
  public task_delegate: HealthCareProfessional;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.enabled = item.enabled;
    this.start_date = item.start_date;
    this.end_date = item.end_date;
    this.task_delegate_enabled = item.task_delegate_enabled;
    this.task_delegate = new HealthCareProfessional(item.task_delegate);
  }

  currentlyEnabled() {
    const startTime = new Date(this.start_date).getTime();
    const currentTime = new Date().getTime();
    const endTime = new Date(this.end_date).getTime();
    return (this.enabled && startTime < currentTime  && currentTime < endTime);
  }
}
