import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'evalTranslation'})
export class EvalTranslation implements PipeTransform {
  constructor() {}

  transform(translation: string): string {
    return translation;
  }
}
