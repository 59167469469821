<div class="modal-body user-task-standard-modal">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close"
    (click)="handleCancel()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <!-- Title User Task -->
  <h2>
    <span *ngIf="userTask?.title">{{ userTask?.translationKeyTitle | translate | evalTranslation }}</span>
    <span *ngIf="!userTask?.title">...</span>
  </h2>

  <p *ngIf="userTask?.description">{{ userTask?.translationKeyDescription | translate | evalTranslation }}</p>

  <div *ngIf="isLoading">
    <app-page-loader [padding]="'20px 0'"></app-page-loader>
  </div>

  <div class="row">
    <div class=" mb-3 col-6 ">
      <p class="text-muted small mb-1">{{ 'modals.user_task.patient' | translate }}</p>
      <div class="card card-muted">
        <div class="card-body user-compact">
          <app-avatar [profilePicture]="userTask?.patient?.profile_picture"></app-avatar>
          <div class="user-compact-info">
            <p class="title text-truncate">{{ userTask?.patient.getFullName() || '...' }}</p>
            <p *ngIf="userTask?.patient">{{ userTask?.patient?.date_of_birth | date:dateFormat?.format }} ({{
              userTask?.patient?.age() }}{{ 'pages.default.dashboard.yo' | translate }})</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3 col-6">
      <p class="text-muted small mb-1">{{ 'modals.user_task.assigned_to' | translate }}</p>
      <div class="card card-muted" *ngIf="userTask?.assignee">
        <div class="card-body user-compact">
          <app-avatar [profilePicture]="userTask?.assignee?.profile_picture"></app-avatar>
          <div class="user-compact-info">
            <p class="title text-truncate" *ngIf="userTask?.assignee?.uid === hcpUid">{{ 'modals.user_task.you' |
              translate }}</p>
            <p class="title text-truncate" *ngIf="userTask?.assignee?.uid !== hcpUid">{{
              userTask?.assignee?.getFullName() || '...' }}</p>
            <p>{{ userTask?.assignee?.job_title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="userTask?.candidate_users?.length > 1">
    <div class="col">
      <p class="text-muted small mb-1">{{ 'modals.user_task.shared_task_with' | translate }}</p>
      <ng-container *ngIf="!showCandidateUsers">
        <app-avatar class="m-end-1 mb-1 e2e-candidate-avatar-collapsed" [profilePicture]="user?.profile_picture"
          *ngFor="let user of userTask?.sortedCandidateUsers(hcpUid)"></app-avatar>
      </ng-container>
    </div>
    <div class="col text-end">
      <a href="#" (click)="setShowCandidateUsers($event, true)" class="text-icon-link hoverable text-secondary"
        *ngIf="!showCandidateUsers" id="collapse-candidates-show">
        <span class="label">{{ 'modals.user_task.show' | translate }} &#x200E;</span>
        <span [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
      </a>
      <a href="#" (click)="setShowCandidateUsers($event, false)" class="text-icon-link hoverable text-secondary"
        *ngIf="showCandidateUsers" id="collapse-candidates-hide">
        <span class="label">{{ 'modals.user_task.hide' | translate }} &#x200E;</span>
        <span [inlineSVG]="'/assets/svg/chevron-up.svg'" class="icon"></span>
      </a>
    </div>
  </div>

  <div class="row" *ngIf="userTask?.candidate_users?.length > 1 && showCandidateUsers">
    <div class="col-6" *ngFor="let user of userTask?.sortedCandidateUsers(hcpUid)">
      <div class="card card-muted">
        <div class="card-body user-compact e2e-candidate">
          <app-avatar [profilePicture]="user?.profile_picture"></app-avatar>
          <div class="user-compact-info">
            <p class="title text-truncate" *ngIf="user?.uid === hcpUid">{{ 'modals.user_task.you' | translate }}</p>
            <p class="title text-truncate" *ngIf="user?.uid !== hcpUid">{{ user?.getFullName() || '...' }}</p>
            <p class="description">{{ user?.job_title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="inline-page-notification mx-n3" *ngIf="userTask?.accessible_by_patient">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto">
          <span class="notification-icon" [inlineSVG]="'/assets/svg/stethoscope.svg'"></span>
        </div>
        <div class="col">
          <p class="title">{{ 'pages.default.query_lists.accessible_by_patient.title' | translate }}</p>
          <p>{{ 'pages.default.query_lists.accessible_by_patient.description' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="user-task-form" *ngIf="userTask">
    <app-user-task-form [userTask]="userTask" [isReadOnly]="false" [validationVisible]="validationVisible" [(form)]="form"></app-user-task-form>
  </div>

  <!-- <ng-container *ngFor="let field of userTask?.form_fields | sortBy:'asc': 'order'">
      <app-dynamic-input *ngIf="field.visible" (onEvaluationRequired)="evaluateControls()" id="no-section-{{field?.id}}"
        [field]="field" [form]="form"></app-dynamic-input>
    </ng-container>

    <div *ngIf="userTask?.form_fields?.length>0 && userTask?.sections?.length>0">
      <hr class="mt-4 mb-3" />
    </div>

    <div *ngFor="let section of userTask?.sections | sortBy:'asc': 'order'; first as first"
      class="d-block e2e-task-section order-{{section?.order}}">
      <div *ngIf="section?.visible">

        <h3 class="text-dark mt-4 mb-2" *ngIf="section?.title">{{ section?.translationKey | translate | evalTranslation
          }}</h3>
        <p *ngIf="section?.description" class="text-muted">{{ section.description | translateHelp | translate }}</p>

        <div class="mb-2" *ngIf="!section?.title"></div>

        <ng-container *ngFor="let field of section.form_fields | sortBy:'asc': 'order'">
          <app-dynamic-input *ngIf="field.visible" (onEvaluationRequired)="evaluateControls()"
            id="{{section?.id}}_{{field?.id}}" [field]="field" [form]="form"></app-dynamic-input>
        </ng-container>
      </div>
    </div> -->
  <!-- </form> -->
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="user-task-standard-cancel" (click)="handleCancel()"
      *ngIf="userTask?.isAssignedTo(hcpUid)">{{
      'action.cancel' | translate }}</button>
    <button class="btn btn-link" id="user-task-standard-close" (click)="handleCancel()"
      *ngIf="!userTask?.isAssignedTo(hcpUid)">{{
      'action.close' | translate }}</button>

    <button class="btn btn-muted m-start-2" id="user-task-standard-save" [ngClass]="{'loader': isSaving}"
      (click)="handleSaveChanges()" [disabled]="!userTask" *ngIf="userTask?.isAssignedTo(hcpUid) && userTask.canSave">
      <span class="loader text-muted"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>

    <button class="btn btn-secondary m-start-2" id="user-task-standard-submit" [ngClass]="{'loader': isSubmitting}"
      (click)="handleSubmit()" [disabled]="!userTask" *ngIf="userTask?.isAssignedTo(hcpUid)">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSubmitting"></em></span>
      <span>{{ 'action.submit' | translate }}</span>
    </button>
  </div>
</div>
