<div class="layout-header-pusher header-back"></div>
<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a class="" [routerLink]="['/help-center']" class="text-secondary font-weight-bold d-flex align-items-center">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to' | translate }} <span>{{ 'pages.default.help_center.help_center' | translate }}</span></span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ helpcenterSection?.title }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div class="faq-block" *ngFor="let category of faqCategories">
      <div class="heading">
        <img [src]="category?.icon" alt="">
        <h3>{{ category.title }}</h3>
      </div>
      <div class="items">
        <a href="" (click)="goToFaq($event,item, category)" *ngFor="let item of category.items" class="d-flex align-items-center">
          <span>{{ item.question }}</span>
          <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        </a>
      </div>
    </div>
  </div>
</div>
