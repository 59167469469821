import { CareModule } from "../models/care-module";

interface StatisticsInterface {
  valid_uses: number;
  invalid_uses: number;
  last_valid: string;
  last_invalid: string;
}

export class TimedCode {
  uid: string;
  hospital_code: string;
  code: string;
  start_at: string;
  end_at: string;
  care_module: CareModule;
  status: string;
  reason: string;
  statistics: StatisticsInterface;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid ? item.uid : '';
    this.hospital_code = item.hospital_code ? item.hospital_code : '';
    this.code = item.code ? item.code : '';
    this.start_at = item.start_at ? item.start_at : '';
    this.end_at = item.end_at ? item.end_at : '';
    this.care_module = new CareModule(item.care_module);
    this.status = item.status ? item.status : '';
    this.reason = item.reason ? item.reason : '';
    this.statistics = item.statistics ? item.statistics : {};
  }
}
