<div class="modal-body pb-0">
  <button type="button" class="close" id="start-new-conversation-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.start_new_convo.start_new_convo' | translate }}</h2>

  <div class="custom-control custom-checkbox custom-control-inline mb-1">
    <input type="checkbox" class="custom-control-input" id="check_regarding_patient" [(ngModel)]="checkIsRegardingPatient" [ngModelOptions]="{standalone: true}" (change)="onRegardingPatientCheckChange()">
    <label class="custom-control-label" for="check_regarding_patient" [innerHtml]="( 'modals.start_new_convo.convo_regarding_a_patient' | translate )"></label>
  </div>

  <!-- -->
  <!-- Form Patient - Start -->
  <!-- -->
  <form [formGroup]="formPatient" id="formPatient" *ngIf="checkIsRegardingPatient" [ngClass]="{'validation-visible': validationVisible}">
    <p class="text-secondary small mt-1" *ngIf="!isModalValid() && validationVisible">{{ 'modals.start_new_convo.complete_all_fields' | translate }}</p>

    <div class="form-group required">
      <app-select
        [placeholder]="('modals.start_new_convo.patient' | translate)"
        [clearable]="true"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="patientsList"
        [searchFn]="customSearchFn"
        (open)="searchPatients()"
        [loading]="isloadingPatients"
        formControlName="patient"

        (ngModelChange)="patientSelected()"
        (search)="searchPatientListEvent.next($event)"

        [showUserDetails]="true"
      ></app-select>

      <app-feedback-field [field]="formPatient.get('patient')"></app-feedback-field>
    </div>

    <div class="form-group required" *ngIf="patientsPathwaysList.length>1">
      <app-select
        [placeholder]="('modals.start_new_convo.pathway' | translate)"
        [clearable]="true"
        [searchable]="true"
        [hideSelected]="false"
        [multiple]="false"
        [items]="patientsPathwaysList"

        formControlName="pathway_compact"

        (ngModelChange)="patientPathwaySelected()"
      ></app-select>

      <app-feedback-field [field]="formPatient.get('pathway_compact')"></app-feedback-field>
    </div>

    <div class="form-group styled floating-label">
      <input type="text" id="inputSubject" class="form-control w-100" [placeholder]="('modals.start_new_convo.subject' | translate)" formControlName="subject" maxlength="{{subjectMaxLength}}">
      <label for="inputSubject" class="required">{{ 'modals.start_new_convo.subject' | translate }}</label>
      <app-feedback-field [field]="formPatient.get('subject')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !formPatient?.controls?.subject?.value)">
        {{( formPatient?.controls?.subject?.value?.length || '0' ) | translateNumber}}/{{subjectMaxLength | translateNumber}}
      </p>
    </div>
  </form>
  <!-- -->
  <!-- Form Patient - End -->
  <!-- -->

  <!-- -->
  <!-- Form Hcps - Start -->
  <!-- -->
  <form [formGroup]="formHcps" id="formHcps" *ngIf="!checkIsRegardingPatient" [ngClass]="{'validation-visible': validationVisible}">
    <p class="text-secondary small mt-1" *ngIf="!isModalValid() && validationVisible">{{ 'modals.start_new_convo.complete_all_fields' | translate }}</p>

    <div class="form-group styled floating-label">
      <input type="text" id="inputSubject" class="form-control w-100" [placeholder]="('modals.start_new_convo.subject' | translate)" formControlName="subject" maxlength="{{subjectMaxLength}}">
      <label for="inputSubject" class="required">{{ 'modals.start_new_convo.subject' | translate }}</label>
      <app-feedback-field [field]="formHcps.get('subject')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !formHcps?.controls?.subject?.value)">
        {{( formHcps?.controls?.subject?.value?.length || '0' ) | translateNumber }}/{{subjectMaxLength | translateNumber }}
      </p>
    </div>
  </form>
  <!-- -->
  <!-- Form Hcps - End -->
  <!-- -->

  <div class="text-center text-muted py-3" *ngIf="isLoadingPathway">
    <em class="spinner-border spinner-border-sm"></em>
  </div>

  <div *ngIf="checkIsRegardingPatient && currentPathway">
    <hr class="medium"/>

    <div class="row justify-content-between align-items-center mb-2">
      <div class="col-auto">
        <p class="m-0 font-weight-bold small">{{ 'modals.start_new_convo.participants' | translate }}<span class="text-danger">*</span></p>
      </div>
      <div class="col-auto">
        <p class="m-0">
          <button id="start-new-conversation-select-everyone" class="btn btn-link" (click)="selectEveryone()">
            {{ 'modals.start_new_convo.select_everyone' | translate }}
          </button>
        </p>
      </div>
    </div>

    <button class="btn text-align-start w-100 participant-item clickable mb-3" [ngClass]="{'selected': isPatientSelected }" (click)="togglePatient()">
      <app-avatar [profilePicture]="formPatient?.get('patient')?.value?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title">{{ formPatient.get('patient').value?.getFullName() }}</p>
        <p class="description">{{ 'modals.start_new_convo.patient' | translate }}</p>
      </div>
      <div class="checkbox">
        <div class="custom-control custom-checkbox custom-control-inline">
          <input type="checkbox" class="custom-control-input" id="check_patient"  [(ngModel)]="isPatientSelected" [ngModelOptions]="{standalone: true}" (click)="togglePatient()">
          <label class="custom-control-label" for="check_patient"></label>
        </div>
      </div>
    </button>

    <div class="mb-3" *ngIf="currentPathway?.clinical_lead?.uid !==  currentHcp.uid">
      <p class="small">{{ 'modals.start_new_convo.clinical_lead' | translate }}</p>

      <button class="btn w-100 text-align-start participant-item clickable" [ngClass]="{'selected': selectedHcps[currentPathway?.clinical_lead?.uid]}" (click)="togglePatientHcp(currentPathway?.clinical_lead)">
        <app-avatar [profilePicture]="currentPathway?.clinical_lead?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ currentPathway?.clinical_lead?.getFullName() }}</p>
          <p class="description">{{ currentPathway?.clinical_lead?.job_title }}</p>
        </div>
        <div class="checkbox" *ngIf="currentPathway?.clinical_lead?.uid != currentHcp.uid">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input"
              id="check_participant_{{ currentPathway?.clinical_lead?.uid }}"
              [(ngModel)]="selectedHcps[currentPathway?.clinical_lead?.uid]" [ngModelOptions]="{standalone: true}" (click)="togglePatientHcp(currentPathway?.clinical_lead)">
            <label class="custom-control-label"
              for="check_participant_{{ currentPathway?.clinical_lead?.uid }}"></label>
          </div>
        </div>
      </button>
    </div>

    <div class="mb-3" *ngIf="currentPathway?.case_manager?.uid !== currentHcp.uid">
      <p class="small">{{ 'modals.start_new_convo.case_manager' | translate }}</p>

      <button class="btn w-100 text-align-start participant-item clickable mb-3"
        [ngClass]="{'selected': selectedHcps[currentPathway?.case_manager?.uid]}"
        (click)="togglePatientHcp(currentPathway?.case_manager)">

        <app-avatar [profilePicture]="currentPathway?.case_manager?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ currentPathway?.case_manager?.getFullName() }}</p>
          <p class="description">{{ currentPathway?.case_manager?.job_title }}</p>
        </div>
        <div class="checkbox" *ngIf="currentPathway?.case_manager?.uid != currentHcp.uid">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input"
              id="check_participant_{{ currentPathway?.case_manager?.uid }}"
              [(ngModel)]="selectedHcps[currentPathway?.case_manager?.uid]" [ngModelOptions]="{standalone: true}" (click)="togglePatientHcp(currentPathway?.case_manager)">
            <label class="custom-control-label" for="check_participant_{{ currentPathway?.case_manager?.uid }}"></label>
          </div>
        </div>
      </button>
    </div>

    <div *ngFor="let mdt of mdts" class="mb-3">
      <div class="row justify-content-between align-items-center mb-1" *ngIf="mdt?.hcps.length > 0">
        <div class="col-auto">
          <p class="small m-0">{{ mdt.name }}</p>
        </div>
        <div class="col-auto">
          <p class="m-0">
            <button class="btn btn-link" id="start-new-conversation-select-all-from-mdt" (click)="selectAllFromMdt(mdt)">
              {{ 'modals.start_new_convo.select_all_from_team' | translate }}
            </button>
          </p>
        </div>
      </div>

      <button class="btn w-100 text-align-start participant-item clickable" *ngFor="let hcp of mdt?.hcps" [ngClass]="{'selected': selectedHcps[hcp?.uid]}" (click)="togglePatientHcp(hcp)">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>
        <div class="checkbox">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="check_participant_{{ hcp?.uid }}"
              [(ngModel)]="selectedHcps[hcp?.uid]" [ngModelOptions]="{standalone: true}" (click)="togglePatientHcp(hcp)">
            <label class="custom-control-label" for="check_participant_{{ hcp?.uid }}"></label>
          </div>
        </div>
      </button>
    </div>

    <div class="mb-3" *ngIf="otherHcps && otherHcps.length>0">
      <div class="row justify-content-between align-items-center mb-1">
        <div class="col-auto">
          <p class="small m-0">{{ 'modals.start_new_convo.other' | translate }}</p>
        </div>
        <div class="col-auto">
          <p class="m-0">
            <button class="btn btn-link"
              id="start-new-conversation-select-all-from-group" (click)="selectAllFromGroup()">
              {{ 'modals.start_new_convo.select_all_from_group' | translate }}
            </button>
          </p>
        </div>
      </div>

      <button class="btn w-100 text-align-start participant-item clickable" *ngFor="let hcp of otherHcps" [ngClass]="{'selected': selectedHcps[hcp?.uid]}" (click)="togglePatientHcp(hcp)">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>
        <div class="checkbox">
          <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="check_participant_{{ hcp?.uid }}"  [(ngModel)]="selectedHcps[hcp?.uid]" [ngModelOptions]="{standalone: true}" (click)="togglePatientHcp(hcp)">
            <label class="custom-control-label" for="check_participant_{{ hcp?.uid }}"></label>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div *ngIf="!checkIsRegardingPatient">
    <hr class="medium my-3"/>

    <p class="font-weight-bold">{{ 'modals.start_new_convo.participants' | translate }}<span class="text-danger">*</span></p>

    <div class="form-group">
      <ng-select
        class="users-select"
        [placeholder]="('modals.start_new_convo.search_for_hcp' | translate)"
        [items]="generalSearchHcpsList"
        (search)="searchHcpListEvent.next($event)"
        [searchFn]="customSearchFn"
        bindLabel="first_name"
        (change)="onLosslessHcpSelect($event)"
        (open)="searchHCPList()"
        [loading]="isloadingHcps"
        [(ngModel)]="losslessHcpSelectModel"
        >
        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item">{{item.getFullName()}}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="wrapper" *ngIf="item">
            <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>
            <div class="user-info">
              <p class="title">{{item?.getFullName()}}</p>
              <p class="sub-text">{{ item?.job_title }}</p>
            </div>
            <div class="status small text-muted" *ngIf="isHcpSelected(item)">
              <span>{{ 'modals.start_new_convo.already_added' | translate }}</span>
            </div>
            <div class="status small text-secondary" *ngIf="!isHcpSelected(item)">
              <span class="icon" [inlineSVG]="'/assets/svg/add.svg'"></span>
              <span>{{ 'modals.start_new_convo.add' | translate }}</span>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="participant-item" *ngFor="let hcp of losslessHcpSelection">
      <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ hcp?.getFullName() }}</p>
        <p class="description">{{ hcp?.job_title }}</p>
      </div>

      <button class="btn btn-link status" id="start-new-conversation-remove-lossless-hcp-{{hcp.uid}}" (click)="removeLosslessHcp(hcp)">
        <span class="icon" [inlineSVG]="'/assets/svg/close.svg'"></span>
        <span>{{ 'modals.start_new_convo.remove' | translate }}</span>
      </button>
    </div>

  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="start-new-conversation-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>
    <button class="btn btn-secondary m-start-3" id="start-new-conversation-submit" [ngClass]="{'loader': isCreating}" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isCreating"></em></span>
      <span>{{ 'modals.start_new_convo.start_convo' | translate }}</span>
    </button>
  </div>
</div>
