import { TranslationInterface } from '../interfaces/translation.interface';
import { CareModule } from './care-module';
import { HealthCareProfessional } from './health-care-professional';
import { Patient } from './patient';

export class UserTaskBasic {
  public uid: string;
  public title: TranslationInterface;
  public assignee: HealthCareProfessional;
  public due_date: string;
  public created_at: string;
  public care_module: CareModule;
  public labels: Array<string>;
  public patient: Patient;
  public type: string;
  public priority: number;
  public translationKeyTitle: string;
  public className: string = "UserTaskBasic";
  public candidate_users: Array<HealthCareProfessional> = [];

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }
  fillFromJson(item) {
    if(item.id) {
      this.uid = item.id;
    }

    if(item.uid) {
      this.uid = item.uid;
    }

    this.title = item.title;
    this.due_date = item.due_date;
    this.created_at = item.created_at;
    this.type = item.type;
    this.priority = item.priority;
    this.labels = item.labels;

    if(item.assignee) {
      this.assignee = new HealthCareProfessional(item.assignee);
    }

    if(item.patient) {
      this.patient = new Patient(item.patient);
    }

    if(item.care_module) {
      this.care_module = new CareModule(item.care_module);
    }

    if (this.title && this.title.region && this.title.key) {
      this.translationKeyTitle = 'shared.' + this.title.region + '.' + this.title.key;
    }

    if (item.candidate_users) {
      item.candidate_users.forEach(user => {
        this.candidate_users.push(new HealthCareProfessional(user));
      })
    }
  }

  passed() {
    const date = new Date();
    date.setDate(date.getDate() - 2);
    const hours48Ago: Date = date;

    if (new Date(this.created_at).getTime() < hours48Ago.getTime()) {
      return true;
    }
    return false;
  }

  sortedCandidateUsers(hcpUid) {
    return this.candidate_users.sort((a, b) => a.uid == hcpUid ? -1 : 1);
  }
}
