import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Material } from '../models/material';
import { MaterialPhase } from '../models/material-phase';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import {DomSanitizer} from '@angular/platform-browser';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialService extends ApiService {
  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public languageService: LanguageService,
    public sanitizer: DomSanitizer
  ) {
    super(http, authenticationService);
  }


  getMaterial(id, locale): Observable<Material> {
    return new Observable(observer => {
      const url = environment.cmsUrl + `/v3/content_types/educational_material/entries/${id}`;
      this.cmsGet(url, locale).subscribe(result => {
        observer.next(new Material(result['entry']));
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getMaterialsByCc(hospital_uid, patient_uid, patient_pathway_uid): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${patient_pathway_uid}/educational-materials`;
    return new Observable(observer => {

      this.authenticatedGet(url).subscribe(result => {
        const phases = [];
        result.educational_materials.forEach(phase => {
          phases.push(new MaterialPhase(phase));
        });

        observer.next({total: result.total, completed: result.completed.individual, phases});
        observer.complete();
      }, error => {
        observer.error(error);
      });

    });
  }

  getMaterialsByHcp(hcp_uid, patient_uid, patient_pathway_uid): Observable<any> {
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}/patient-pathways/${patient_pathway_uid}/educational-materials`;
    return new Observable(observer => {

      this.authenticatedGet(url).subscribe(result => {
        const phases = [];
        result.educational_materials.forEach(phase => {
          phases.push(new MaterialPhase(phase));
        });

        observer.next({total: result.total, completed: result.completed.individual, phases});
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getMaterialsDetails(ids: string[]): Observable<Array<Material>> {
    return new Observable(observer => {
      const locale: string = this.languageService.getCurrentLanguage().locale;
      const url = environment.cmsUrl + '/v3/content_types/educational_material/entries';

      this.cmsGet(url, locale, null, ids).subscribe(result => {
        const entries = result['entries']?.map(entry => {return new Material(entry)});
        observer.next(entries);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}

