import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    if(req.url.includes('eu-cdn.contentstack.com')) {
      return next.handle(req);
    }

    let headers = req.headers;

    if (req.headers.get('content-type') === 'multipart/form') {  /// Delete multipart form header.  The browser is setting this automaticly.
      headers = headers.delete('content-type');
    } else {
      if (!req.headers.get('content-type')) {
        headers = headers.append('content-type', environment.content_type_header);
      }
    }

    if (!req.headers.get('accept')) {
      headers = headers.append('accept', environment.accept_header);
    }



    let clonedRequest = req.clone({
      headers: headers
    });

    // TEMP FIX! DEMO AND QA CAN NOT HANDLE THIS YET. SO THEY ARE NULL IN .ENV
    
    if(environment.app_version) {
      clonedRequest = req.clone({
        headers: clonedRequest.headers
          .append('X-App-Version', environment.app_version)
      });
    }
    

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
