<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back-filters"></div>

  <div class="layout-header header-back-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <p>
              <a [routerLink]="['/administration']" class="text-secondary font-weight-bold d-flex align-items-center" id="admin-codes-back">
                <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
                <span class="align-middle">{{ 'action.back_to_administration' | translate }}</span>
              </a>
            </p>
          </div>
          <div class="col col-auto">
            <a href="" class="btn btn-sm btn-secondary m-start-1" id="admin-codes-generate"
              (click)="actionGenerate($event)">{{ 'pages.default.administration.onboarding_codes.generate_new_code' | translate }}</a>
          </div>
        </div>
      </div>

      <div class="container">
        <h1>{{ 'pages.default.administration.onboarding_codes.onboarding_codes' | translate }}</h1>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">
              <app-select
                id="admin-onboarding-codes-select-care-module"
                class="d-inline-block"
                [placeholder]="('pages.default.administration.patients.pathway' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="true"
                [items]="careModules"

                [(ngModel)]="filters.care_module_uid"
                [bindValue]="'value'"
                (ngModelChange)="onCareModuleFilterChange()"

                [asFilter]="true"
                [showCheckboxes]="true"
              ></app-select>

              <div class="divider"></div>

              <div class="custom-control custom-checkbox checkbox-small custom-control-inline align-middle m-0">
                <input type="checkbox" class="custom-control-input" id="checkbox_all_codes" [(ngModel)]="showAllCodes" [ngModelOptions]="{standalone: true}"
                      (change)="onShowAllCodesCheck()">
                <label class="custom-control-label"
                      for="checkbox_all_codes">{{ 'pages.default.administration.onboarding_codes.show_all_codes' | translate }}</label>
              </div>

              <!--
              <div class="divider"></div>

              <button (click)="toggleMoreFilters()" class="custom-filter-checks-dropdown" id="admin-more-filters">
                <span class="label">More Filters</span>
                <span *ngIf="!moreFiltersAreShown" [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
                <span *ngIf="moreFiltersAreShown" [inlineSVG]="'/assets/svg/chevron-up.svg'" class="icon"></span>
              </button>
              -->

              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info py-0" id="admin-codes-reset-filters" *ngIf="filtersAreSet" (click)="actionResetFilters()">{{ 'action.reset' | translate }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div (clickOutside)="toggleMoreFilters(false)" *ngIf="moreFiltersAreShown"
        [delayClickOutsideInit]="true"
        class="header-overlap-block header-filters-block">
      <div class="header-content">
        <div class="container">

        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div *ngFor="let timedCode of timedCodes; let index = index;">
        <div id="admin-oboarding-codes-timecode-{{index}}" class="single-item-block mb-2" [ngClass]="{ 'block-danger': isInactivated(timedCode), 'block-muted': isExpired(timedCode) }">
          <div class="row d-flex align-items-center w-100">
            <div class="col-4">
              <div class="block-wrapper py-3 p-start-3">
                <p class="label text-truncate e2e-care-module">{{ timedCode?.care_module?.translationKey | translate }}</p>
                <p *ngIf="isActive(timedCode)" class="text-truncate e2e-valid-until">
                  @if(timedCode.end_at) {
                    {{ 'pages.default.administration.onboarding_codes.valid_until' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}
                  } @else {
                    {{ 'pages.default.administration.onboarding_codes.no_expiry' | translate }}
                  }

                </p>
                <p *ngIf="isInactivated(timedCode)"
                  class="text-truncate text-secondary e2e-inactivated-on">{{ 'pages.default.administration.onboarding_codes.inactivated_on' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}</p>
                <p *ngIf="isExpired(timedCode)"
                  class="text-truncate text-secondary e2e-expired-on">{{ 'pages.default.administration.onboarding_codes.expired_on' | translate }} {{ timedCode.end_at | timeZoneDate:dateFormat.format }}</p>
              </div>
            </div>

            <div class="col-4">
              <div class="block-wrapper">
                <p class="text-truncate text-dark e2e-timedcode">{{ getCodeNotation(timedCode) }}</p>
              </div>
            </div>

            <div class="col-4">
              <div class="block-wrapper">
                <p class="text-truncate e2e-valid-uses">{{ timedCode.statistics.valid_uses }} {{ 'pages.default.administration.onboarding_codes.times_used' | translate }}</p>
                <p *ngIf="!isActive(timedCode)" class="text-truncate text-secondary e2e-invalid-uses">{{ timedCode.statistics.invalid_uses }} {{ 'pages.default.administration.onboarding_codes.expired_attempts' | translate }}</p>
              </div>
            </div>

          </div>

          <div class="block-action-wrapper justify-content-end" style="min-width: 220px;">
            <a *ngIf="isActive(timedCode)" href="" class="block-action action-light" [id]="'admin-codes-inactivate-code-' + timedCode.uid" (click)="actionInactivate($event, timedCode.uid)">
              <span [inlineSVG]="'/assets/svg/close.svg'"></span>
              <span class="m-start-1">{{ 'pages.default.administration.onboarding_codes.inactivate' | translate }}</span>
            </a>
            <a *ngIf="isActive(timedCode)" href="" class="block-action action-compact" [id]="'admin-codes-copy-code-' + timedCode.uid" (click)="actionCopyCode($event, timedCode)">
              <span [inlineSVG]="'/assets/svg/multpage.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
