<div class="modal-body pb-0">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>
  <h2>{{ pathwayMessage?.translationKey | translate }}</h2>

  <div *ngIf="isLoading">
    <app-page-loader [padding]="'30px 0'"></app-page-loader>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="user-compact mb-2" *ngIf="pathwayMessage?.patient">
      <div class="avatar" [style.backgroundImage]="'url(' + (pathwayMessage?.patient?.profile_picture?.url | secure | async) + ')'" [ngClass]="{'empty': !pathwayMessage?.patient?.profile_picture}"></div>

      <div class="user-compact-info">
        <p class="title text-truncate text-danger">{{ pathwayMessage?.patient.getFullName() }}</p>
        <p *ngIf="pathwayMessage?.care_module">{{ pathwayMessage?.care_module?.translationKey | translate }}</p>
      </div>
    </div>

    <ng-container *ngIf="pathwayMessage?.message_content">
      <ng-container *ngFor="let text of pathwayMessage?.message_content">
        <div [innerHtml]="text"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!pathwayMessage?.message_content">
      <div class="alert alert-danger alert-icon mb-0" role="alert">
        <span [inlineSVG]="'/assets/svg/deny.svg'" class="icon small"></span>
        <span>{{ 'error.general' | translate }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-secondary" (click)="handleClose()">Close</button>
  </div>
</div>
