<div class="modal-body pb-0">
  <button type="button" class="close" id="edit-personal-modal-back" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.resend_onboarding_email.title' | translate }}</h2>

  <div class="user-compact mb-3">
    <app-avatar [profilePicture]="patient.profile_picture"></app-avatar>

    <div class="user-compact-info">
      <p class="title text-truncate">{{ patient.getFullName() || '...' }}</p>
      <p>{{ patient.date_of_birth | timeZoneDate }} ({{ patient.age() | translateNumber }}{{ 'pages.default.dashboard.yo' | translate }})</p>
    </div>
  </div>

  <p>{{ 'modals.resend_onboarding_email.description' | translate }}</p>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="resend-onboarding-email-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="resend-onboarding-email-submit" [ngClass]="{'loader': isSending}" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSending"></em></span>
      <span>{{ 'modals.resend_onboarding_email.submit' | translate }}</span>
    </button>
  </div>
</div>
