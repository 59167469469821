import { Component, EventEmitter, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { AssignTaskModalComponent } from "../../../../modals/assign-task-modal/assign-task-modal.component";
import { UserTaskAppointmentModalComponent } from "../../../../modals/user-task-appointment-modal/user-task-appointment-modal.component";
import { UserTaskStandardModalComponent } from "../../../../modals/user-task-standard-modal/user-task-standard-modal.component";
import { DateFormat } from "../../../../models/date-format";
import { HealthCareProfessional } from "../../../../models/health-care-professional";
import { Patient } from "../../../../models/patient";
import { UserTaskBasic } from "../../../../models/user-task-basic";
import { GeneralService } from "../../../../services/general.service";
import { HcpService } from "../../../../services/hcp.service";
import { LocaleService } from "../../../../services/locale.service";
import { PatientService } from "../../../../services/patient.service";
import { UserTaskService } from "../../../../services/user-task.service";
import { TranslateNumberPipe } from "../../../../pipes/translate-number.pipe";
import { LanguageService } from "../../../../services/language.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-admin-tasks',
  templateUrl: './admin-tasks.component.html'
})
export class AdminTasksComponent implements OnInit {
  public selectedTasksMap = new Map<UserTaskBasic, boolean>();
  public selectedTasksUids: string[] = [];
  public selectedAll = false;
  public currentPage = 1;
  public currentPageSize = 20;
  public totalPages: number;
  public totalElements: number;
  public isLoading = false;
  public isLoadingPatients = false;
  public isLoadingHcps = false;
  public filters = {
    hcp_uid: undefined,
    patient_uid: undefined,
    max_priority: UserTaskService.OVERVIEW_MAX_PRIORITY,
    min_priority: UserTaskService.OVERVIEW_MIN_PRIORITY
  };
  public filtersAreSet = false;
  public hcpUid: string;
  public hospitalUid: string;
  public sorting: string = 'created_at,asc';
  public tasks: UserTaskBasic[];
  public dateFormat: DateFormat;
  public hcps: Array<HealthCareProfessional>;
  public patients: Array<Patient>;

  public searchPatientListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public hcpService: HcpService,
    public userTaskService: UserTaskService,
    public localeService: LocaleService,
    public router: Router,
    public modalService: BsModalService,
    public generalService: GeneralService,
    public patientService: PatientService,
    public translateService: TranslateService,
    public translateNumber: TranslateNumberPipe,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.getTasks();

    this.searchPatientListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.getPatients(result);
    });

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.getHCPs(result);
    });
  }

  ngOnDestroy() {
    this.searchPatientListEvent.unsubscribe();
    this.searchHcpListEvent.unsubscribe();
  }

  getTasks(showLoading = true): void {
    if (showLoading) {
      this.isLoading = true;
      this.tasks = [];
      this.selectedTasksMap.clear();
    }

    this.userTaskService.getHospitalUserTasks(this.hospitalUid, this.filters, this.currentPage - 1, this.currentPageSize, this.sorting)
      .subscribe(result => {
        this.tasks = [];
        this.selectedTasksMap.clear();

        for (const task of result?.tasks) {
          this.tasks.push(task);
          this.selectedTasksMap.set(task, false);
        }

        if (result.pagination) {
          this.totalPages = result.pagination.total_pages;
          this.totalElements = result.pagination.total_elements;
        }

        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  getHCPs(event?) {
    let term = '';

    if (event && event.term && event.term.length) {
      term = event.term;
    } else {
      this.hcps = undefined;
    }

    this.isLoadingHcps = true;

    this.hcpService.getPaged({ last_name: term }, 'last_name,asc', 0, 50).subscribe({
      next: result => {
        this.hcps = result.items;
      }, complete: () => this.isLoadingHcps = false
    });
  }

  getPatients(event?) {
    let term = '';

    if (event && event.term && event.term.length) {
      term = event.term;
    } else {
      this.patients = undefined;
    }

    this.isLoadingPatients = true;

    this.patientService.getPatientsByHospital(this.hospitalUid, { last_name: term }, 'last_name,asc;first_name,asc', 0, 50).subscribe({
      next: result => {
        this.patients = result.patients;
      }, complete: () => this.isLoadingPatients = false
    });
  }

  onToggleCheckbox(task: UserTaskBasic): void {
    const taskWasSelected = this.selectedTasksMap.get(task);

    // update selected-list
    if (taskWasSelected) {
      // was true, will toggle to false
      const index = this.selectedTasksUids.indexOf(task.uid);
      if (index > -1) {
        this.selectedTasksUids.splice(index, 1);
      }
    } else {
      this.selectedTasksUids.push(task.uid);
    }

    // toggle select
    this.selectedTasksMap.set(task, !taskWasSelected);
  }

  onToggleAllCheckbox(): void {
    this.selectedAll = !this.selectedAll;

    for (const task of this.tasks) {
      this.selectedTasksMap.set(task, this.selectedAll);

      if (this.selectedAll) {
        this.selectedTasksUids.push(task.uid);
      }
    }

    if (!this.selectedAll) {
      this.selectedTasksUids = [];
    }
  }

  goToPatientDetail(event, patient: Patient) {
    event.preventDefault();

    this.router.navigate(['/patient/', patient.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }
    });
  }

  openUserTask(event, userTaskBasic: UserTaskBasic) {
    event?.preventDefault();

    switch (userTaskBasic?.type.toLowerCase()) {
      case 'appointment': this.showUserTaskAppointmentModal(userTaskBasic); break;
      case 'standard': this.showUserTaskStandardModal(userTaskBasic); break;
      case 'checklist': this.showUserTaskStandardModal(userTaskBasic); break;
      case 'questionnaire': this.showUserTaskStandardModal(userTaskBasic); break;
      default: console.error('User-task type not supported'); break;
    }
  }

  openAssignModal(event, taskUid?) {
    event?.preventDefault();
    const taskUids = taskUid ? [taskUid] : this.selectedTasksUids;
    const initialState = {
      taskUids: taskUids
    };
    const modal = this.modalService.show(AssignTaskModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      }));

    if (modal) {
      modal.content.assignmentSuccessEvent.subscribe((event) => {
        const tasks = event['tasks'];
        const hcp: HealthCareProfessional = event['hcp'];
        this.applyHcpToTasks(hcp, tasks)

        this.selectedTasksUids = [];
        this.selectedTasksMap = new Map<UserTaskBasic, boolean>();
        this.getTasks(false);
      });

      modal.content.assignmentFailedEvent.subscribe((event) => {
        const tasks = event['succeeded'];
        const failed = event['failed'];
        const hcp: HealthCareProfessional = event['hcp'];

        this.applyHcpToTasks(hcp, tasks);

        this.selectedTasksUids = [];
        this.selectedTasksMap = new Map<UserTaskBasic, boolean>();

        failed.forEach(fail => {
          const task: UserTaskBasic = this.tasks.find((p) => fail.task_id === p.uid);
          this.selectedTasksMap.set(task, true);
          this.selectedTasksUids.push(task.uid);
        });
      });
    }
  }

  applyHcpToTasks(hcp: HealthCareProfessional, taskIds: string[]) {
    taskIds.forEach(uid => {
      const task = this.tasks.find(task => task.uid === uid);
      task.assignee = hcp;
    });
  }

  showUserTaskAppointmentModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic: userTaskBasic,
      task_id: userTaskBasic.uid,
      asCc: true
    };
    const modal = this.modalService.show(UserTaskAppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );
  }

  showUserTaskStandardModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic,
      asCc: true
    };
    const modal = this.modalService.show(UserTaskStandardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-xl modal-compact',
        initialState
      })
    );
  }

  onFilterChange() {
    this.filtersAreSet = true;
    this.currentPage = 1;
    this.getTasks();
  }

  onActionResetFilters(): void {
    this.clearFilters();
    this.getTasks();
  }

  private clearFilters(): void {
    this.selectedTasksUids = [];

    this.filters = {
      hcp_uid: undefined,
      patient_uid: undefined,
      max_priority: UserTaskService.OVERVIEW_MAX_PRIORITY,
      min_priority: UserTaskService.OVERVIEW_MIN_PRIORITY
    };

    this.filtersAreSet = false;
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getTasks();
    }
  }


  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getTasks();
    }
  }

  onChangeSort(event: MouseEvent, sortKey: string, sortDir: string): void {
    event.preventDefault();
    this.sorting = `${sortKey},${sortDir}`;
    this.getTasks();
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  tooltip(user) {
    if (user.uid === this.hcpUid) {
      return this.translateService.instant('pages.default.tasks.you');
    } else {
      return user.getFullName() + ' (' + user.job_title + ') ';
    }

  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + " " + translatedNumber;
    }

    return translatedNumber + " " + itemsLabel;
  }
}
