import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Country } from '../../../../models/country';
import { Hospital } from '../../../../models/hospital';
import { LanguageCode } from '../../../../models/language-code';
import { CountryService } from '../../../../services/country.service';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-region-step',
  templateUrl: './region-step.component.html'
})
export class RegionStepComponent implements OnInit {
  @Input() country: Country;
  @Output() countryChange = new EventEmitter<Country>();

  @Input() language: LanguageCode;
  @Output() languageChange = new EventEmitter<LanguageCode>();

  public hospitalDefaultLanguage: String | LanguageCode;
  public hospitalDefaultCountry: String | Country;
  public countryOptions: Country[];
  public languageOptions: LanguageCode[];
  public regionForm: UntypedFormGroup;

  public isLoading: boolean;

  constructor(
    public languageService: LanguageService,
    public countryService: CountryService,
    public hcpService: HcpService,
    public hospitalService: HospitalService
  ) { }

  ngOnInit(): void {
    this.initializeRegionStep();
  }

  initializeRegionStep() {
    this.isLoading = true;

    let observableArray = [];
    if (!this.languageOptions) observableArray.push(this.languageService.getSupportedAppLanguages());

    if (!this.countryOptions) observableArray.push(this.countryService.getCountriesFromCms());

    if (!this.hospitalDefaultLanguage) {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      observableArray.push(this.hospitalService.get(hospitalUid));
    }

    if (observableArray?.length) {
      forkJoin(observableArray).subscribe(data => {

        data.forEach(response => {
          if (response instanceof Array && response[0] instanceof LanguageCode) {
            this.languageOptions = response as LanguageCode[];
          }

          if (response instanceof Array && response[0] instanceof Country) {
            this.countryOptions = response as Country[];
          }

          if (response instanceof Hospital) {
            const hospital: Hospital = response;

            this.hospitalDefaultLanguage = this.languageOptions.find(lang =>
              lang.value === hospital.defaultLanguage
            );

            this.hospitalDefaultCountry = this.countryOptions.find(country =>
              country.code === hospital.defaultCountry
            );
          }
        });

        this.regionFormSetup();
        this.isLoading = false;
      });
    }
  }

  regionFormSetup(): void {
    if (this.regionForm) {
      return;
    }

    const selectedLanguage = (this.language) ? this.language : this.hospitalDefaultLanguage;

    const selectedCountry = (this.country) ? this.country : this.hospitalDefaultCountry

    this.regionForm = new UntypedFormGroup({
      language: new UntypedFormControl(selectedLanguage, [Validators.required]),
      country: new UntypedFormControl(selectedCountry, [Validators.required]),
    });

    if (this.regionForm.valid) {
      this.handleFormUpdates();
    }

    this.regionForm.valueChanges.subscribe(() => {
      this.handleFormUpdates();
    })
  }

  handleFormUpdates(): void {
    this.country = this.regionForm.get('country')?.value;
    this.countryChange.emit(this.country);

    this.language = this.regionForm.get('language')?.value;
    this.languageChange.emit(this.language);
  }
}
