import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from '../models/patient';
import { Surgery } from '../models/surgery';
import { DataService } from './data.service';
import moment, { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public dataService: DataService,
    public translateService: TranslateService
  ) {
    super(http, authenticationService);
  }

  createPatientByHospital(
    hospital_uid: string,
    params
  ): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients`;
    return new Observable(observer => {
      if(params.country === "") {
        delete params.country;
      }

      if(params.preferred_name === "") {
        delete params.preferred_name;
      }

      if (params?.electronic_medical_record === '') {
        delete params.electronic_medical_record;
      }

      if(params?.phone_number?.number === "") {
        delete params.phone_number;
      }

      if (params.date_of_birth) {
        params.date_of_birth = this.convertDate(moment(params.date_of_birth));
      }

      this.authenticatedPost(url, params).subscribe(result => {
        observer.next(new Patient(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }


  getPatientByHospital(
    hospital_uid: string,
    patient_uid: string
  ): Observable<Patient> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(new Patient(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getDashboardPatientByHospital(
    hospital_uid: string,
    patient_uid: string
  ): Observable<any> {
    const url = `${environment.platformUrl}/dashboards/hospitals/${hospital_uid}/patients/${patient_uid}`;

    return new Observable(observer => {
      this.authenticatedGet(url, 'v2').subscribe(result => {
        observer.next(new Patient(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  updatePatientByHospital(
    hospital_uid: string,
    patient_uid: string,
    data
  ): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}`;
    const patient = this.postablePatient(data);

    let profilePictureData: string;
    if (patient.profile_picture && patient.profile_picture.data) {
      profilePictureData = patient.profile_picture.data;
      patient.profile_picture.data = patient.profile_picture.data.split(',')[1];
    }

    return new Observable(observer => {
      this.authenticatedPut(url, this.postablePatient(patient), 'v2').subscribe(result => {
        const returnedPatient = new Patient(result);

        if (profilePictureData) {
          this.dataService.set(`PROFILE_PICTURE_${returnedPatient.profile_picture.uuid}`, profilePictureData);
        }

        observer.next(returnedPatient);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  changeEmailAddressForPatient(
    hospitalUid: string,
    patientUid: string,
    new_email: string,
    reason: string
  ): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/change-email`;
    const data = {
      new_email,
      reason
    }

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  resendOnboardingEmailForPatient(
    hospitalUid: string,
    patientUid: string,
  ): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/resend-onboarding-email`;

    return new Observable(observer => {
      this.authenticatedPost(url, null).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPatientsByHospital(
    hospital_uid: string,
    filters: any = {},
    sort = 'last_name,desc;first_name,desc',
    page = 0,
    size = 30
  ): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients?${this.definePatientsParams(filters, sort, page, size)}`;
    return this.getPatientsByEndpoint(url, filters);
  }

  getPatientsByHcp(
    hcp_uid: string,
    filters: any = {},
    sort = 'last_name,desc;first_name,desc',
    page = 0,
    size = 30
  ): Observable<any> {
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patients?${this.definePatientsParams(filters, sort, page, size)}`;
    return this.getPatientsByEndpoint(url, filters);
  }

  definePatientsParams(filters: any, sort: string, page: number, size: number): string {
    const paramBits = [
      `page=${String(page)}`,
      `size=${String(size)}`
    ];

    sort.split(';').forEach((property) => {
      paramBits.push(`sort=${property}`);
    });

    if (filters.hasOwnProperty('last_name') && filters.last_name.length) {
      paramBits.push(`last_name=${filters.last_name}`);
    }

    if (filters.hasOwnProperty('assigned_mdt') && filters.assigned_mdt !== null) {
      paramBits.push(`patient_pathways.assigned_mdt=${filters.assigned_mdt}`);
    }

    if (filters.hasOwnProperty('assigned_surgery') && filters.assigned_surgery !== null) {
      paramBits.push(`assigned_surgery=${filters.assigned_surgery}`);
    }

    if (filters.hasOwnProperty('status') && filters.status !== null) {
      paramBits.push(`status=${filters.status}`);
    }

    let care_module_uid = filters.care_module_uid || filters.care_module_uids;
    if (care_module_uid) {
      if (Array.isArray(care_module_uid)) {
        care_module_uid.forEach(cmu => {
          paramBits.push(`patient_pathways.care_module.uid=${cmu}`);
        });
      } else {
        paramBits.push(`patient_pathways.care_module.uid=${care_module_uid}`);
      }
    }

    if(!filters.hasOwnProperty('extensions') || filters.extensions === undefined) {
      filters.extensions = 'PATIENT_PATHWAYS';
    }
    if (filters.hasOwnProperty('extensions') && filters.extensions !== null) {
      paramBits.push(`extensions=${filters.extensions}`);
    }

    return paramBits.join('&');
  }

  getPatientsByEndpoint(url: string, filters: any = {}): Observable<any> {
    return new Observable(observer => {
      this.authenticatedGet(url, 'v2').subscribe(result => {
        const patients: Array<Patient> = new Array();

        for (const item of result.items) {
          const patient = new Patient(item);

          if(!filters?.extensions?.includes('PATIENT_PATHWAYS') || patient.pathways.length) {
            patients.push(patient);
          }
        }

        observer.next({ patients: patients, pagination: result.pagination });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPatientByHcp(
    hcp_uid: string,
    patient_uid: string
  ): Observable<Patient> {
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}`;
    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(new Patient(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getDashboardPatientByHcp(
    hcp_uid: string,
    patient_uid: string
  ): Observable<any> {
    const url = `${environment.platformUrl}/dashboards/hcps/${hcp_uid}/patients/${patient_uid}`;
    return new Observable(observer => {
      this.authenticatedGet(url, 'v2').subscribe(result => {
        observer.next(new Patient(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getDashboardPatientsByHospital(
    hospital_uid: string,
    filters: any = {},
    sort = 'last_name,asc;first_name,asc',
    page = 0,
    size = 30
  ): Observable<any> {
    const url = `${environment.platformUrl}/dashboards/hospitals/${hospital_uid}/patients?${this.defineDashboardPatientsParams(filters, sort, page, size)}`;
    return this.getDashboardPatientsByEndpoint(url);
  }

  getDashboardPatientsByHcp(
    hcp_uid: string,
    filters: any = {},
    sort = 'last_name,asc;first_name,asc',
    page = 0,
    size = 30
  ): Observable<any> {
    const url = `${environment.platformUrl}/dashboards/hcps/${hcp_uid}/patients?${this.defineDashboardPatientsParams(filters, sort, page, size)}`;
    return this.getDashboardPatientsByEndpoint(url);
  }

  defineDashboardPatientsParams(filters: any, sort: string, page: number, size: number): string {
    const paramBits = [
      `page=${String(page)}`,
      `size=${String(size)}`
    ];

    sort.split(';').forEach((property) => {
      paramBits.push(`sort=${property}`);
    });

    if (filters.hasOwnProperty('last_name') && filters.last_name.length) {
      paramBits.push(`last_name=${filters.last_name}`);
    }

    let patient_mdt_hcp_uid = filters.patient_mdt_hcp_uid || filters.patient_mdt_hcp_uids;
    if (patient_mdt_hcp_uid) {
      if (Array.isArray(patient_mdt_hcp_uid)) {
        patient_mdt_hcp_uid.forEach(pmhu => {
          paramBits.push(`patient_pathways.hcp_uid=${pmhu}`);
        });
      } else {
        paramBits.push(`patient_pathways.hcp_uid=${patient_mdt_hcp_uid}`);
      }
    }

    let care_module_uid = filters.care_module_uid || filters.care_module_uids;
    if (care_module_uid) {
      if (Array.isArray(care_module_uid)) {
        care_module_uid.forEach(cmu => {
          paramBits.push(`patient_pathways.care_module.uid=${cmu}`);
        });
      } else {
        paramBits.push(`patient_pathways.care_module.uid=${care_module_uid}`);
      }
    }

    return paramBits.join('&');
  }

  getDashboardPatientsByEndpoint(url: string): Observable<any> {
    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const patients: Array<Patient> = new Array();

        for (const item of result.items) {
          patients.push(new Patient(item));
        }

        observer.next({ patients: patients, pagination: result.pagination });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  bulkAssignment(
    hospitalUid: string,
    params: {
      surgery?: Surgery,
      mdt_uids?: string[],
      hcp_uids?: string[],
      clinical_lead_uid?: string,
      case_manager_uid?: string,
      patient_pathway_ids?: string[]
    }
  ): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/bulk-assignments`;

    return new Observable<void>(observer => {
      this.authenticatedPost(url, params).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  postablePatient(patient: Patient) {
    const resp: any = {};

    resp.first_name = patient.first_name;
    resp.last_name = patient.last_name;
    resp.preferred_name = patient.preferred_name;
    resp.profile_picture = patient.profile_picture;
    resp.gender = patient.gender;

    resp.date_of_birth = patient.date_of_birth;
    resp.language = patient.language;
    resp.electronic_medical_record = patient.electronic_medical_record;

    if (patient.country !== '') {
      resp.country = patient.country;
    }

    return resp;
  }

  convertDate(date: Moment): string {
    const dateString = moment(date).year() + '-' + (moment(date).month() + 1).toString().padStart(2, "0") + '-' + moment(date).date().toString().padStart(2, "0");
    return dateString;
  }
}


