export class Crumb {
  public label:string;
  public link: string;
  public queryParams: any = {};

  constructor(label:string, link:string, queryParams?: any) {
    this.label = label;
    this.link = link;
    this.queryParams = queryParams;
  }
}
