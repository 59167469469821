import { Component, OnInit, ViewChild, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { AppointmentSelectionEvent } from '../../events/appointment-selection-event';
import moment, { Moment } from 'moment';
import { AppointmentService } from '../../services/appointment.service';
import { Appointment } from '../../models/appointment';
import { Profile } from '../../models/profile';
import { Subscription } from 'rxjs';
import { AttentionCenterService } from '../../services/attention-center.service';
import { LanguageService } from '../../services/language.service';

class MonthDay {
  label: string;
  key: string;
  appointments: Array<Appointment> =  [];
}

@Component({
  selector: 'app-calendar-month-view',
  templateUrl: './calendar-month-view.component.html',
  styleUrls: ['./calendar-month-view.component.scss']
})
export class CalendarMonthViewComponent implements OnInit {
  @Output() onAppointmentSelect: EventEmitter<AppointmentSelectionEvent> = new EventEmitter();
  @Output() onMore: EventEmitter<Date> = new EventEmitter();

  @ViewChild('monthDaysContainer') monthDaysContainer: ElementRef;

  private _appointmentServiceSubscription: any;
  private _currentMonthFirstDay;
  private _filters;
  public isLoading: boolean;
  @Input() timeZone: string;


  get currentMonthFirstDay(): Moment {
    return this._currentMonthFirstDay;
  }

  @Input()
  set currentMonthFirstDay(val: Moment) {
    this._currentMonthFirstDay = val;
    this._currentMonthFirstDay.status = 'In Process';
    this.fillUpDays();
    this.setDays();
    this.getAppointments();

    let totalBlocks = this.days.length;
    totalBlocks += this.fillUpDaysAtStart;
    totalBlocks += this.fillUpDaysAtEnd;
    this.storeTotalBlocksCount(totalBlocks);
  }

  @Input()
  set filters(val: any) {
    this._filters = val;
    this.getAppointments();
  }

  public totalBlocksCount;
  public maxEventsPerBlock;
  public blockHeight;
  public fillUpDaysAtStart:number;
  public fillUpDaysAtEnd:number;
  public days = [];
  public weekdays = [];
  public today = moment();

  private appointmentsFetchInterval: any;

  public onAppointmentAddedSubscription: Subscription;
  public onAppointmentChangeSubscription: Subscription;
  public onAppointmentStatusChangedSubscription: Subscription;
  public onAppointmentRemovedSubscription: Subscription;
  public onNewAppointmentsPendingSubscription: Subscription
  public onAppointmentCancelledSubscription: Subscription;
  ;

  constructor(
    public appointmentService: AppointmentService,
    public attentionCenterService: AttentionCenterService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.setWeekDays();

    this.onAppointmentAddedSubscription = this.appointmentService.onAppointmentAdded.subscribe(() => this.getAppointments(false));
    this.onAppointmentChangeSubscription = this.appointmentService.onAppointmentChange.subscribe(() => this.getAppointments(false));
    this.onAppointmentStatusChangedSubscription = this.appointmentService.onAppointmentStatusChanged.subscribe(() => this.getAppointments(false));
    this.onAppointmentRemovedSubscription = this.appointmentService.onAppointmentRemoved.subscribe((appointment) => this.removeAppointmentFromDay(appointment));
    this.onNewAppointmentsPendingSubscription = this.attentionCenterService.onNewAppointmentsPending.subscribe(() => this.getAppointments(false));

    this.onAppointmentCancelledSubscription = this.appointmentService.onAppointmentCancelled.subscribe(appointment => {
      if(appointment.hasRemovedState()) {
        this.removeAppointmentFromDay(appointment);
      }
    });

    this.appointmentsFetchInterval = setInterval(() => {
      this.getAppointments(false);
    }, 60000);
  }

  ngOnDestroy() {
    this.onAppointmentAddedSubscription?.unsubscribe();
    this.onAppointmentChangeSubscription?.unsubscribe();
    this.onAppointmentStatusChangedSubscription?.unsubscribe();
    this.onAppointmentRemovedSubscription?.unsubscribe();
    this.onNewAppointmentsPendingSubscription?.unsubscribe();
    this.onAppointmentCancelledSubscription?.unsubscribe();

    clearInterval(this.appointmentsFetchInterval);
  }

  storeTotalBlocksCount(count: number) {
    this.totalBlocksCount = count;

    setTimeout(() => {
      this.calculateEventsPerBlock();
    });
  }

  setWeekDays() {
    this.weekdays = moment.weekdays(true);
  }

  fillUpDays() {
    this.fillUpDaysAtStart = this.currentMonthFirstDay.weekday();
    this.fillUpDaysAtEnd = 6 - this.currentMonthFirstDay.clone().endOf('M').weekday();
  }

  setDays() {
    this.days = [];
    const daysInMonth = this.currentMonthFirstDay.daysInMonth();
    for (var _i = 1; _i < (daysInMonth + 1); _i++) {
      const day:MonthDay = new MonthDay();
      day.label = _i.toString();
      day.key = _i + (this.currentMonthFirstDay.month() + 1).toString() + this.currentMonthFirstDay.year().toString();
      this.days.push(day);
    }
  }

  getAppointments(loader = true) {
    this.resetDays();

    if(loader) {
      this.isLoading = true;
    }

    if(!this._filters || !this.currentMonthFirstDay) {
      return;
    }
    const startDate = this.currentMonthFirstDay;
    const endDate = this.currentMonthFirstDay.clone().endOf('M');

    const days = {startDate, endDate}
    const filters = {...days, ...this._filters}  // combine objects

    if (this._appointmentServiceSubscription) {
      this._appointmentServiceSubscription.unsubscribe();
    }

    this._appointmentServiceSubscription = this.appointmentService.getAllAppointments(filters).subscribe(result => {
      this._appointmentServiceSubscription = undefined;
      this.isLoading = false;
      result.forEach(appointment => {
        this.addAppointmentToDay(appointment);
      });

      this.sortAppointments();

      setTimeout(() => {
        this.calculateEventsPerBlock();
      });
    });
  }

  resetDays() {
    this.days.forEach(day => {
      day.appointments = [];
    });
  }

  removeAppointmentFromDay(appointment) {
    const appointmentKey = this.dateToKey(appointment.start_date);
    const days = this.days.filter((day:MonthDay) => {
      return (day.key === appointmentKey)
    });

    if (days.length > 0) {
      const foundAppointments = days[0].appointments.filter(item => { return item.uid === appointment.uid });

      if (foundAppointments && foundAppointments.length) {
        const foundAppointment:Appointment = foundAppointments[0];
        days[0].appointments.splice(days[0].appointments.indexOf(foundAppointment), 1);
      }
    }
  }

  addAppointmentToDay(appointment) {
    const appointmentKey = this.dateToKey(appointment.start_date || appointment.date);

    const days = this.days.filter((day: MonthDay) => {
      return (day.key.toString() === appointmentKey.toString());
    });

    if (days.length > 0) {
      days[0].appointments.push(appointment);
    }
  }

  sortAppointments() {
    this.days.forEach(day => {
      day.appointments = day.appointments.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateEventsPerBlock();
  }

  calculateEventsPerBlock() {
    if(!this.totalBlocksCount || !this.monthDaysContainer) {
      return;
    }

    const height = this.monthDaysContainer.nativeElement.offsetHeight;
    const rows = Math.ceil(this.totalBlocksCount/7);

    const averageHeight = height / rows;
    const averageOccupiedHeight = 70;
    const averageEventHeight = 22;

    this.blockHeight = 100/rows;
    this.maxEventsPerBlock = Math.floor((averageHeight - averageOccupiedHeight ) / averageEventHeight);
  }

  getVisibleItems(list: any[]) {
    return {
      visible: list.slice(0, this.maxEventsPerBlock),
      invisible: list.slice(this.maxEventsPerBlock, list.length)
    }
  }

  onMoreClicked(event: MouseEvent, day: MonthDay) {
    event?.preventDefault();

    const first: Appointment = day.appointments[0];
    const date: Date = new Date((first?.start_date || first?.date));

    this.appointmentService.showMoreEventsInYear(date);
  }

  selectAppointment(event, appointment) {
    event.preventDefault();
    this.onAppointmentSelect.emit(new AppointmentSelectionEvent(event, appointment));
  }

  toTime(dateString) {
    const storageProfile = localStorage.getItem('profile');

    if (storageProfile) {
      let timeFormat;
      const profile = JSON.parse(storageProfile) as Profile;

      if (profile.locale.time_24_hours) {
        timeFormat = "HH:mm"
      } else {
        timeFormat = "hh:mm A"
      }

      return moment(dateString).tz(this.timeZone).format(timeFormat);
    } else {
      return '';
    }
  }


  isToday(day) {
    const today = this.dateToKey(this.today);
    return today === day;
  }

  private dateToKey(date) {
    return moment(date).tz(this.timeZone).date() + '' + (moment(date).tz(this.timeZone).month() + 1) + '' + moment(date).tz(this.timeZone).year();
  }
}
