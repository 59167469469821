export interface FilterGroup {
  id: string,
  collapsed: boolean,
  toggleAll?: boolean,
  items: FilterItem[]
}

export interface FilterItem {
  id: string,
  key: string,
  label: string,
  selected: boolean
}

export class Filters {
  public groups: FilterGroup[] = [];

  constructor(items?) {
    if (items) {
      this.fillFromJson(items);
    }
  }

  fillFromJson(items) {
    Object.entries(items).forEach(([key, rawValues]) => {
      const values = rawValues as Array<string>;
      const items = [];

      values.forEach(value => {
        if (!value) return;

        items.push({
          id: value,
          label: value,
          key: key,
          selected: false
        } as FilterItem)
      });

      this.groups.push({
        id: key,
        collapsed: false,
        toggleAll: key !== 'sources',
        items: items
      } as FilterGroup);
    });
  }

  get selected(): Object {
    const selected = {};

    this.groups.forEach(group => {
      group.items.forEach(item => {
        if (item.selected) {
          if (!selected.hasOwnProperty(group.id)) {
            selected[group.id] = [];
          }

          selected[group.id].push(item.id);
        }
      });
    });

    return selected;
  }
}
