import { TranslationInterface } from '../interfaces/translation.interface';

interface AppointmentSubjectInterface {
  user_provided: string;
  translation: TranslationInterface;
}

export class Reminder {
  uid: string;
  order: number;
  reminder_type: string;

  // appointment
  start_date: string;
  end_date: string;
  date: string;
  appointmentSubject: AppointmentSubjectInterface;

  // task
  taskType: string;
  createdAt: string;
  dueAt: string;
  title: TranslationInterface;

  // message
  counter: number;
  messageSubject: string;

  public className: string = "Reminder";

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;

    if (item.id) {
      this.uid = item.id;
    }
    this.order = item.order;

    this.start_date = item.start_date;
    this.end_date = item.end_date;
    this.date = item.date;

    if (item.subject && item.subject.user_provided) {
      this.appointmentSubject = item.subject;
    }

    this.taskType = item.type;
    this.createdAt = item.created_at;
    this.dueAt = item.due_at;
    this.title = item.title;

    this.counter = item.counter;

    if (item.subject && typeof item.subject === 'string') {
      this.messageSubject = item.subject;
    }

    this.reminder_type = item?.reminder_type;
  }

  passed() {
    const date = new Date();
    date.setDate(date.getDate() - 2);
    const hours48Ago: Date = date;

    if (this.type === 'task' && new Date(this.createdAt).getTime() < hours48Ago.getTime()) {
      return true;
    }
    return false;
  }

  get translationKey():string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get type(): string {
    if((this.start_date || this.date) || this.reminder_type?.includes('PatientPathwayAppointmentReminder')) {
      return 'appointment';
    } else if(this.counter || this.reminder_type?.includes('PatientPathwayConversationReminder')) {
      return 'message';
    } else if(this.taskType) {
      return 'task';
    }
    return null;
  }
}
