import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';
import { LogoutModalComponent } from './../../modals/logout-modal/logout-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../services/general.service';
import { ScopeService } from '../../services/scope.service';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { UserService } from '../../services/user.service';
import { HcpService } from '../../services/hcp.service';
import { OooService } from '../../services/ooo.service';
import { Ooo } from '../../models/ooo';
import { AttentionCenterService } from '../../services/attention-center.service';
import { AttentionCenter } from '../../models/attention-center';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  public sidebarOpen: boolean;
  public backdropVisible: boolean;
  public backdropShown: boolean;
  public activeState: string;

  public currentHcp: HealthCareProfessional;
  public currentPermissionHospital: any;

  public conversation_messages_unread: number = 0;
  public appointments_pending_total: number = 0;

  public attentionCenter: AttentionCenter;
  public attentionCenterFetchInterval:any;
  public ooo: Ooo;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public modalService: BsModalService,
    public scopeService: ScopeService,
    public hcpService: HcpService,
    public generalService: GeneralService,
    public userService: UserService,
    public oooService: OooService,
    public attentionCenterService: AttentionCenterService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.defineActiveState();
      }
    });

    this.defineActiveState();

    this.scopeService.onScopeChanged.subscribe(() => this.updateVisualScope());

    this.updateVisualScope();

    this.oooService.oooChanged$.subscribe((result) => {
      this.ooo.enabled = result['enabled'];
      this.ooo.start_date = result['start_date'];
      this.ooo.end_date = result['end_date'];
    });

    this.attentionCenterFetchInterval = setInterval(() => {
      if (this.authenticationService.accessTokenValid) {
        this.getAttentionCenter();
      }

    }, 30000);

    this.getAttentionCenter();
    this.getOutOfOffice();
  }

  ngOnDestroy() {
    clearInterval(this.attentionCenterFetchInterval);
  }

  openSidebarHandler(event?) {
    event.preventDefault();
    this.sidebarOpen = true;
    this.backdropVisible = true;
    setTimeout(() => {
      this.backdropShown = true;
    });
  }

  closeSidebarHandler(event?) {
    event.preventDefault();
    this.backdropShown = false;
    this.sidebarOpen = false;
    setTimeout(() => {
      this.backdropVisible = false;
    }, 150);
  }

  showLogoutModal(event) {
    event.preventDefault();

    this.modalService.show(LogoutModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  defineActiveState() {
    const url = this.router.url;

    if(url.indexOf('/dashboard') === 0 || url.indexOf('/patient/') === 0) {
      this.activeState = 'dashboard';
    } else if(url.indexOf('/settings') === 0) {
      this.activeState = 'settings';
    } else if(url.indexOf('/conversations') === 0) {
      this.activeState = 'messages';
    } else if(url.indexOf('/calendar') === 0) {
      this.activeState = 'calendar';
    } else if(url.indexOf('/administration') === 0) {
      this.activeState = 'administration';
    } else if(url.indexOf('/analytics') === 0) {
      this.activeState = 'analytics';
    } else if(url.indexOf('/tasks') === 0) {
      this.activeState = 'tasks';
    } else if(url.indexOf('/help-center') === 0) {
      this.activeState = 'help-center';
    }
  }

  updateVisualScope() {
    this.currentHcp = this.hcpService.getCurrentStoredHcp();
    this.currentPermissionHospital = this.scopeService.getCurrentHospitalBasic();
  }

  triggerCookies(event) {
    event.preventDefault();
    this.generalService.openCookieSettings();
  }

  goToCookiePolicy(event) {
    event.preventDefault();
    this.generalService.goToCookiePolicy();
  }

  getAttentionCenter() {
    this.attentionCenterService.get().subscribe(ac => {
      this.attentionCenter = ac;
    });
  }

  getOutOfOffice() {
    const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
    this.oooService.getOutOfOffice(hcp_uid).subscribe(result => {
      this.ooo = result;
    });
  }
}
