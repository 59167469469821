export class PageTabItem {
  public translationKey: string;
  public htmlID: string;
  public data: any;
  public badge: number;

  constructor(translationKey: string, htmlID?: string, data?: any, badge?: number) {
    // The first param should now always be a complete transation slug, and not the translated value.
    this.translationKey = translationKey;
    this.htmlID = htmlID;
    this.data = data;
    this.badge = badge;
  }
}
