<div class="is-loading row" *ngIf="isLoading">
  <app-page-loader class="col-12 align-self-center"></app-page-loader>
</div>

<div *ngIf="!isLoading">
  <p class="font-weight-bold">{{ 'modals.add_patient_wizard.fill_hospital_team' | translate }}</p>

  <div class="form-group required">
    <app-select [placeholder]="('modals.add_patient.clinical_lead' | translate)" [hideSelected]="false"
      [multiple]="false" [clearable]="false" [searchable]="true" [items]="hcps" [searchFn]="customSearchFn"
      (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
      [(ngModel)]="selectedCareModule.default_clinical_lead" [asFilter]="false" [showCheckboxes]="false"
      [showUserDetails]="true">
    </app-select>
  </div>

  <div class="form-group required">
    <app-select [placeholder]="('modals.add_patient.case_manager' | translate)" [hideSelected]="false"
      [multiple]="false" [clearable]="false" [searchable]="true" [items]="hcps" [searchFn]="customSearchFn"
      (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()"
      [(ngModel)]="selectedCareModule.default_case_manager" [asFilter]="false" [showCheckboxes]="false"
      [showUserDetails]="true">
    </app-select>
  </div>

  <hr>

  <div class="form-group styled floating-label">
    <ng-select (change)="onAddMdtOrHcp()" (search)="searchMdtOtherHcpListEvent.next($event)"
      [(ngModel)]="mdtOrHcpSelect" [clearable]="true" [items]="allMdtsOrHcps"
      [placeholder]="'modals.add_patient_wizard.search_team_hcp' | translate" [searchFn]="customSearchFn"
      class="users-select">

      <ng-template let-item="item" ng-label-tmp>
        <span *ngIf="item?.className === 'MedicalTeam'">{{item?.name}}</span>
        <span *ngIf="item?.className === 'HealthCareProfessional'">{{item?.getFullName()}}</span>
      </ng-template>

      <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
        <div *ngIf="item?.className === 'MedicalTeam'" class="wrapper" id="mdt-info">
          <div class="avatar">
            <span [inlineSVG]="'/assets/svg/community.svg'" class="icon"></span>
          </div>
          <div class="user-info">
            <p class="title">{{item?.name}}</p>
            <p class="sub-text">{{item?.hcpsAllNames}}</p>
          </div>
          <div *ngIf="isMdtSelected(item)" class="status small text-muted">
            <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
          </div>
          <div *ngIf="!isMdtSelected(item)" class="status text-secondary">
            <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
        <div *ngIf="item?.className === 'HealthCareProfessional'" class="wrapper" id="hcp-info">
          <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

          <div class="user-info">
            <p class="title">{{item?.getFullName()}}</p>
            <p class="sub-text">{{ item?.job_title }}</p>
          </div>
          <div *ngIf="isHcpSelected(item)" class="status small text-muted">
            <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
          </div>
          <div *ngIf="!isHcpSelected(item)" class="status text-secondary">
            <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
      </ng-template>

    </ng-select>
  </div>

  <div *ngIf="selectedCareModule?.default_mdts?.length" class="mt-4">
    <p class="mb-2 font-weight-bold">{{ 'modals.add_patient_wizard.teams' | translate }}</p>

    <div *ngFor="let mdt of selectedCareModule?.default_mdts" class="participant-item">
      <div class="avatar">
        <span [inlineSVG]="'/assets/svg/community.svg'" class="icon"></span>
      </div>
      <div class="meta">
        <p class="title">{{ mdt?.name }}</p>
        <p class="description">
          <span *ngFor="let hcp of mdt?.hcps; let i=index">
            <span>{{ hcp?.getFullName() }}</span>
            <span *ngIf="(i < (mdt?.hcps?.length - 1))">, </span>
          </span>
        </p>
      </div>
      <a (click)="onRemoveMdt($event, mdt)" [id]="'default-mdt-modal-remove-mdt-' + mdt.uid"
        class="status text-secondary" href="">
        <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
  </div>

  <div *ngIf="selectedCareModule?.default_hcps?.length" class="my-4">
    <p class="mb-2 font-weight-bold">{{ 'modals.add_patient_wizard.other_hcps' | translate }}</p>

    <div *ngFor="let hcp of selectedCareModule?.default_hcps" class="participant-item">
      <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title">{{ hcp?.getFullName() }}</p>
        <p class="description">{{ hcp?.job_title }}</p>
      </div>
      <a (click)="onRemoveHcp($event, hcp)" [id]="'default-mdt-modal-remove-hcp-' + hcp.uid"
        class="status text-secondary" href="">
        <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
  </div>
</div>
