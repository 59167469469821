import {Component, OnInit} from '@angular/core';
import {Consent} from '../../../../models/consent';
import {UserService} from '../../../../services/user.service';
import {DataService} from '../../../../services/data.service';
import {Router} from '@angular/router';
import {Profile} from '../../../../models/profile';
import {forkJoin} from 'rxjs';
import {GeneralService} from './../../../../services/general.service';
import {DateFormat} from './../../../../models/date-format';
import { ConsentService } from '../../../../services/consent.service';

@Component({
  selector: 'app-settings-consents-overview',
  templateUrl: './settings-consents-overview.component.html',
  styleUrls: ['./settings-consents-overview.component.scss']
})
export class SettingsConsentsOverviewComponent implements OnInit {
  public consents: Consent[];
  public consentsLoaded: boolean;
  public profile: Profile;
  public dateFormat: DateFormat;

  constructor(
    public userService: UserService,
    public dataService: DataService,
    public generalService: GeneralService,
    public router: Router,
    public consentService: ConsentService
  ) {
  }

  ngOnInit() {
    forkJoin([
      this.consentService.getUserConsents(),
      this.userService.getProfile(),
    ]).subscribe(data => this.onDataLoaded(data));
  }

  onDataLoaded(data) {
    this.consents = this.filterDuplicates(data[0]);
    this.profile = data[1];
    this.consentsLoaded = true;

    this.dateFormat = this.generalService.dateFormatMap.get(this.profile.locale.date_format);
  }

  goToConsent(consent) {
    this.dataService.set(DataService.KeyConsentSelected, consent);
    this.router.navigateByUrl(`/settings/privacy/${consent.key}`);
  }

  filterDuplicates(consents: Consent[]): Consent[] {
    if (consents && consents.length > 1) {
      consents.forEach((consent: Consent) => {
        let duplicateConsents = consents.filter(con => con.key === consent.key);

        if (duplicateConsents.length > 1) {
          duplicateConsents.sort(
            (objA, objB) => Number(new Date(objB.status_at)) - Number(new Date(objA.status_at)),
          );

          consents = consents.filter(con => con.key !== consent.key);
          consents.push(duplicateConsents[0]);
        }
      });
    }

    return consents;
  }
}
