import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CareModule, CareModuleType } from '../../models/care-module';
import { Country } from '../../models/country';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { LanguageCode } from '../../models/language-code';
import { MedicalTeam } from '../../models/medical-team';
import { Patient } from '../../models/patient';
import { CountryService } from '../../services/country.service';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import { HospitalService } from '../../services/hospital.service';
import { LanguageService } from '../../services/language.service';
import { LocaleService } from '../../services/locale.service';
import { MdtService } from '../../services/mdt.service';
import { PatientService } from '../../services/patient.service';

enum Steps {
  CAREMODULE = 'CAREMODULE',
  PATIENT = 'PATIENT',
  REGION = 'REGION',
  HOSPITALTEAM = 'HOSPITALTEAM',
  OVERVIEW = 'OVERVIEW',
}

@Component({
  selector: 'app-add-patient-wizard-modal',
  templateUrl: './add-patient-wizard-modal.component.html'
})

export class AddPatientWizardModalComponent implements OnInit {
  @Output() onPatientCreated: EventEmitter<any> = new EventEmitter();

  public isSaving: boolean = false;
  public isLoading: boolean = false;

  public stepsEnum = Steps;
  public steps: Steps[] = Object.values(Steps).filter(step => step !== this.stepsEnum.HOSPITALTEAM);
  public currentStep: Steps;
  public showTimeLine: boolean = false;

  // Care-module
  public CareModuleType = CareModuleType;
  public careModules: CareModule[];
  public selectedCareModule: CareModule;

  // Patient
  public patient: Patient;
  public isPatientValid: boolean = false;

  // Region
  public language: LanguageCode;
  public country: Country;

  //Hospital Team
  public selectedCareModuleWasUpdated: boolean;

  private _closeAnyway = false;
  public get showModalInterceptor(): boolean {
    if (this._closeAnyway) {
      return false;
    } else if (this.selectedCareModule || this.patient || this.language || this.country || this.selectedCareModuleWasUpdated) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    public bsModalRef: BsModalRef,
    public hcpService: HcpService,
    public patientService: PatientService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public hospitalService: HospitalService,
    public languageService: LanguageService,
    public countryService: CountryService,
    public mdtService: MdtService,
    public localeService: LocaleService,
    public modalService: BsModalService,
    public GeneralService: GeneralService
  ) { }

  ngOnInit(): void {
    this.onHandleStep();
  }

  onHandleStep(direction: 'backward' | 'forward' = 'forward'): void {
    const index: number = this.steps.findIndex(step => step === this.currentStep);

    if (direction === 'forward' && index <= this.steps.length) {
      this.currentStep = this.steps[index + 1];
    }

    if (direction === 'backward' && index > 0) {
      this.currentStep = this.steps[index - 1];
    }

    if (this.currentStep === this.stepsEnum.HOSPITALTEAM && this.selectedCareModule.type === CareModuleType.EDUCATION) {
      this.steps = this.steps.filter(step => step !== this.stepsEnum.HOSPITALTEAM);
      this.onHandleStep();
    }
  }

  // Care-module
  // Care-module
  // Care-module

  careModulesLoaded() {
    if (this.careModules?.length === 1) {
      this.steps = this.steps.filter(step => step !== this.stepsEnum.CAREMODULE);
    }

    this.showTimeLine = true;
  }

  careModuleSelected() {
    if (this.selectedCareModule.type === CareModuleType.CLINICAL) {
      if (!this.steps.includes(this.stepsEnum.HOSPITALTEAM)) {
        const overviewStep = this.steps.findIndex(step => step === this.stepsEnum.OVERVIEW);
        this.steps.splice(overviewStep, 0, this.stepsEnum.HOSPITALTEAM);
      }
    } else if (this.selectedCareModule.type === CareModuleType.EDUCATION) {
      this.steps = this.steps.filter(step => step !== this.stepsEnum.HOSPITALTEAM);
    }

    this.onHandleStep();
  }

  getWizardTitle(): string {
    if (this.careModules?.length === 1) {
      return `${this.translate.instant("modals.add_patient_wizard.add_patient_to")} ${this.translate.instant(this.selectedCareModule.translationKey)}`;
    }

    return this.translate.instant("modals.add_patient_wizard.add_patient");
  }


  // Patient
  // Patient
  // Patient

  patientValid(validity: boolean): void {
    this.isPatientValid = validity;
  }

  // GENERAL
  // GENERAL
  // GENERAL

  onHandleSubmit() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const patientPayload = {
      first_name: this.patient.first_name,
      last_name: this.patient.last_name,
      email: this.patient.email,
      gender: this.patient.gender,
      country: this.country.code,
      language: this.language.value,
      date_of_birth: this.patient.date_of_birth,
      electronic_medical_record: this.patient.electronic_medical_record,
      patient_pathway: {
        care_module_uid: this.selectedCareModule.uid,
        case_manager_uid: this.selectedCareModule.default_case_manager.uid,
        clinical_lead_uid: this.selectedCareModule.default_clinical_lead.uid,
        mdt_uids: this.selectedCareModule.default_mdts.map((mdt: MedicalTeam) => mdt.uid),
        hcp_uids: this.selectedCareModule.default_hcps.map((hcp: HealthCareProfessional) => hcp.uid)
      }
    };

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.patientService.createPatientByHospital(hospitalUid, patientPayload).subscribe(result => {
      this.onCreateSuccess();
    }, () => {
      this.isSaving = false;
    });
  }

  onCreateSuccess() {
    this.isSaving = false;

    this.toastrService.info(this.translate.instant('modals.add_patient.patient_created'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this._closeAnyway = true;
    this.bsModalRef.hide();
    this.onPatientCreated.emit();
  }

  isStepActiveOrDone(step: Steps): boolean {
    if (!step || !this.currentStep) {
      return false;
    }

    const stepIndex = this.steps.indexOf(step);
    const currentstepIndex = this.steps.indexOf(this.currentStep);
    return stepIndex <= currentstepIndex;
  }

  isStepDone(step: Steps): boolean {
    const stepIndex = this.steps.indexOf(step);
    const currentstepIndex = this.steps.indexOf(this.currentStep);
    return stepIndex < currentstepIndex;
  }

  isStepActive(step: Steps): boolean {
    const stepIndex = this.steps.indexOf(step);
    const currentstepIndex = this.steps.indexOf(this.currentStep);
    return stepIndex === currentstepIndex;
  }

  showPreviousBtn(): boolean {
    if (this.currentStep === this.stepsEnum.CAREMODULE &&
      this.steps.findIndex(step => step === this.currentStep) < 1) {
      return false;
    }

    return true;
  }

  showNextBtn(): boolean {
    if (this.currentStep === this.stepsEnum.CAREMODULE) {
      if (this.careModules && this.careModules?.length <= 5 || !this.selectedCareModule) {
        return false;
      }
    }

    if (this.currentStep === this.stepsEnum.OVERVIEW) {
      return false;
    }

    return true;
  }

  nextStepIsDisabled(): boolean {
    if (this.currentStep === this.stepsEnum.CAREMODULE) {
      if (this.careModules?.length > 5 && this.selectedCareModule) {
        return false;
      }
    }

    if (this.currentStep === this.stepsEnum.PATIENT) {
      if (this.patient && this.isPatientValid) {
        return false;
      }
    }

    if (this.currentStep === this.stepsEnum.REGION) {
      if (this.country && this.language) {
        return false;
      }
    }

    if (this.currentStep === this.stepsEnum.HOSPITALTEAM) {
      if (this.selectedCareModule?.default_case_manager?.uid && this.selectedCareModule?.default_clinical_lead?.uid) {
        return false;
      }
    }

    return true;
  }

  getStepIndex(): number {
    return this.steps.findIndex(step => step === this.currentStep) + 1;
  }
}
