<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h1>{{ 'pages.default.settings.overview.settings' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div class="single-item-block item-clickable mb-3 mb-lg-4 mb-xl-5"
         id="edit-personal-information"
         [routerLink]="['/settings/personal-information']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="avatar" [style.backgroundImage]="'url(' + (hcp?.profile_picture?.url | secure | async) + ')'" [ngClass]="{'empty': !getProfilePicture()}"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{getFullName()}}</p>
        <p class="value">{{ 'pages.default.settings.overview.personal_info_contact_details' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none"
              id="edit-personal-information-arrow"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline"
              id="edit-personal-information-arrow-rtl"
              [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <!-- Temp hidden with 'd-none' -->
    <div class="d-none single-item-block item-clickable mb-2" [ngClass]="{'block-info text-info': ooo?.currentlyEnabled()}"
         id="edit-ooo"
         [routerLink]="['/settings/ooo']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/building.svg'"></span>
      </div>
      <div class="block-wrapper m-end-auto">
        <p class="label" [ngClass]="{'text-info': ooo?.currentlyEnabled()}">{{ 'pages.default.settings.overview.ooo' | translate }}</p>
        <p class="value" [ngClass]="{'text-info': ooo?.currentlyEnabled()}">{{ 'pages.default.settings.overview.ooo_info' | translate }}</p>
      </div>
      <div class="block-wrapper m-end-2" *ngIf="ooo?.currentlyEnabled()">

        <p class="text-info">
          <span class="icon icon-sm m-end-1" [inlineSVG]="'/assets/svg/check-free.svg'"></span>
          <span class="align-middle">Active until {{ ooo?.end_date | timeZoneDate:dateFormat?.format }}</span>
        </p>
      </div>
      <div class="block-action">
        <span class="rtl-d-none"
              id="edit-ooo-arrow"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline"
              id="edit-ooo-arrow-rtl"
              [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2"
         id="edit-preferences"
         [routerLink]="['/settings/preferences']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/approve.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.preferences' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.preferences_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none"
              id="edit-preferences-arrow"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline"
              id="edit-preferences-arrow-rtl"
              [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2"
         id="edit-security"
         [routerLink]="['/settings/security']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/password.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.security' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.security_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none"
              id="edit-security-arrow"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline"
              id="edit-security-arrow-rtl"
              [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

    <div class="single-item-block item-clickable mb-2"
         id="edit-privacy-legal"
         [routerLink]="['/settings/privacy']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/multpage.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.privacy' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.privacy_info' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none"
              id="edit-privacy-legal-arrow"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline"
              id="edit-privacy-legal-arrow-rtl"
              [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>


    <!-- d-none: temp hidden -->
    <div class="single-item-block item-clickable mb-2 d-none"
         id="edit-personal-data"
         [routerLink]="['/settings/personal-data']">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/package.svg'"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ 'pages.default.settings.overview.personal_data' | translate }}</p>
        <p class="value">{{ 'pages.default.settings.overview.manage_your_account' | translate }}</p>
      </div>
      <div class="block-action m-start-auto">
        <span class="rtl-d-none"
              id="edit-personal-data-arrow"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
        <span class="d-none rtl-d-inline"
              id="edit-personal-data-arrow-rtl"
              [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
      </div>
    </div>

  </div>
</div>
