<div class="pagination-range-component">
  <label>{{ 'components.pagination.items_per_page' | translate }}</label>
  <ng-select 
             [clearable]="false"
             [searchable]="false"
             (change)="onRangeChange($event)"
             [(ngModel)]="currentRange">


    <ng-option *ngFor="let option of rangeOptions" [value]="option">{{option | translateNumber}}</ng-option>
  </ng-select>
</div>