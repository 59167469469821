import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Filters } from '../../models/filters';
import { AnalyticsService, AnalyticsType } from '../../services/analytics.service';
import { CountryService } from '../../services/country.service';
import { forkJoin } from 'rxjs';
import { Country } from '../../models/country';

@Component({
  selector: 'app-dna-filters',
  templateUrl: './dna-filters.component.html'
})
export class DnaFiltersComponent implements OnInit {
  public filters: Filters;
  public isLoading = false;

  @Output() onFiltersChange = new EventEmitter<Object>();
  @Input() hospital: string;
  @Input() type: AnalyticsType;

  constructor(
    public analyticsService: AnalyticsService,
    public countryService: CountryService
  ) { }

  ngOnInit(): void {
    this.initFilters();
  }

  initFilters(): void {
    this.isLoading = true;

    forkJoin([
      this.countryService.getCountriesFromCms(),
      this.analyticsService.filterValues(this.hospital, this.type)
    ]).subscribe({
      next: (data: Array<any>) => {
        this.filters = data[1];

        this.mapCountriesToFilter(data[0]);
      },
      complete: () => this.isLoading = false
    });
  }

  mapCountriesToFilter(countries: Country[]) {
    const countriesGroup = this.filters.groups.find(group => group.id === 'countries');

    if (countriesGroup) {
      countriesGroup.items.forEach(item => {
        const country = countries.find(country => country.code === item.id);

        if (country) {
          item.label = country.label;
        }
      });
    }
  }


  filter(identifier: string): any {
    return this.filters.groups.find(f => f.id == identifier);
  }

  isCollapsed(identifier: string): boolean {
    return this.filter(identifier).collapsed;
  }

  toggleFilterGroup(identifier: string): void {
    const group = this.filter(identifier);
    group.collapsed = !group.collapsed;
  }

  toggleItem(identifier: string): void {
    this.onFiltersChange.emit(this.filters.selected);
  }

  toggleAllItems(identifier: string): void {
    const items = this.filter(identifier).items;
    let state = true;

    if (this.isAllSelected(items)) {
      state = false;
    }

    items.forEach(item => {
      item.selected = state;
    });

    this.onFiltersChange.emit(this.filters.selected);
  }

  isAllSelected(items: any): boolean {
    return (items.findIndex(i => i.selected === false) == -1);
  }

  isNoneSelected(items: any): boolean {
    return (items.findIndex(i => i.selected === true) == -1);
  }

  isIndeterminate(identifier: string): boolean {
    const items = this.filter(identifier).items;

    return (!this.isNoneSelected(items) && !this.isAllSelected(items));
  }
}
