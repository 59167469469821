import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Appointment } from '../../../models/appointment';
import { DateFormat } from '../../../models/date-format';
import { PageTabItem } from '../../../models/page-tab-item';
import { Pathway } from '../../../models/pathway';
import { PhaseInstance, PhaseStatus, PhaseType } from '../../../models/phase-instance';
import { DataService } from '../../../services/data.service';
import { GeneralService } from '../../../services/general.service';
import { LocaleService } from '../../../services/locale.service';
import { PathwayService } from '../../../services/pathway.service';
import { PatientService } from '../../../services/patient.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  public isLoading;
  public pageTabItems: PageTabItem[] = [];
  public pathway: Pathway;
  public patientUid: string;
  public patientPathwayUid: string;
  public patientPathwayKey: string;

  public phases: PhaseInstance[];
  public futureAppointments: Appointment[] = [];
  public status = PhaseStatus;
  public dateFormat: DateFormat;
  public today = new Date();
  public timeZone: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public patientService: PatientService,
    public localeService: LocaleService,
    public generalService: GeneralService,
    protected pathwayService: PathwayService,
    protected translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.pathway = this.dataService.get(DataService.SelectedPathway);
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.timeZone = this.localeService.getLocalePreferences().locale?.time_zone;
    this.today = new Date();

    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      this.pageTabItems = [new PageTabItem('...')];
      this.loadPhases();
    });
  }

  loadPhases() {
    this.isLoading = true;

    this.pathwayService.getPathwayPhasesWithAppointments(this.patientUid, this.patientPathwayUid).subscribe(
      pathway => this.phaseHandler(pathway)
    );
  }

  phaseHandler(pathway: { phases: PhaseInstance[], appointments: Appointment[] }) {
    this.phases = [];
    this.pageTabItems = [];

    let currentPhasePageTabItem: PageTabItem;
    const pathwayPhases = pathway.phases;

    pathwayPhases.forEach(phase => {
      const name = this.translateService.instant(phase?.translationKey);
      const pageTabItem = new PageTabItem(name, `phase-${phase.id}`);
      this.pageTabItems.push(pageTabItem);
      this.phases.push(phase);

      if (phase.isCurrent) {
        currentPhasePageTabItem = pageTabItem;
      }

      if (phase.sub_phase_instances) {
        phase.sub_phase_instances.forEach(subphase => {
          if (!subphase.inTheFuture) {
            this.phases.push(subphase);
          }
        });
      }
    });

    /*
    ** The use of the dummy - explained:
    ** - - - - - - - - - - - - - - - - -
    **
    ** In the loop of phases we render a block for the phase or subphase title.
    ** and before that, if truethfull to the conditionals, we render the upcoming appointments and next steps titles.
    ** but in case we only have 1 (main) phase or our current phase is also the last phase, we encouter a problem:
    ** The upcoming and next steps titles will never be able to render as last, because in the loop it is placed before
    ** the rendering of the block for a phase or subphase.
    ** Therefor we need to use a dummy, which always comes last, but is never rendered.
    **
    */
    this.phases.push(new PhaseInstance({id: 'dummy', order: 999999, type: PhaseType.PHASE, status: PhaseStatus.FUTURE}));
    /*
    ** End - of dummy info
    */

    this.phases = this.mapAppointmentsToPhases(pathway.appointments, this.phases);
    this.isLoading = false;

    if (currentPhasePageTabItem) {
      setTimeout(() => {
        this.generalService.scrollToPageTabItem(currentPhasePageTabItem);
      }, 250);
    }
  }

  mapAppointmentsToPhases(appointments: Appointment[], phases: PhaseInstance[]): PhaseInstance[] {

    appointments.forEach((appointment: Appointment) => {
      const appointmentStartDate = moment(new Date(appointment.start_date)).tz(this.timeZone);

      if (appointmentStartDate.isSameOrAfter(this.today, 'day')) {
        this.futureAppointments.push(appointment);
      } else {

        if (appointment.created_for_phase_id) {
          const phase = phases.find((ph: PhaseInstance) => ph.id === appointment.created_for_phase_id);

          if (phase) {
            phase.appointments.push(appointment);
          }

        } else {
          const phase = phases.find((ph: PhaseInstance) => ph.appointment_uid === appointment.uid);

          if (phase) {
            phase.appointments.push(appointment);

          } else {
            const phase = phases.find((ph: PhaseInstance, index) => {
              const phaseStartDate = moment(new Date(ph.started_at)).tz(this.timeZone);

              if (!phases[index + 1]) {
                return appointmentStartDate.isSameOrAfter(phaseStartDate);
              }

              const nextPhaseStartDate = moment(new Date(phases[index + 1].started_at)).tz(this.timeZone);
              const isAfterStartAndBeforeNext = appointmentStartDate.isSameOrAfter(phaseStartDate) && appointmentStartDate.isBefore(nextPhaseStartDate);
              const isAfterStartAndNextDateInvalid = appointmentStartDate.isSameOrAfter(phaseStartDate) && !nextPhaseStartDate.isValid();

              return isAfterStartAndBeforeNext || isAfterStartAndNextDateInvalid;
            });

            if (phase) {
              phase.appointments.push(appointment);
            }
          }
        }
      }
    });

    phases.forEach((phase: PhaseInstance) => {
      if (phase.appointments.length > 1) {
        this.generalService.sortByKey(phase.appointments, 'start_date');
      }
    });
    this.generalService.sortByKey(this.futureAppointments, 'start_date');

    return phases;
  }

  printToday(phaseIndex: number): boolean {
    const phase: PhaseInstance = this.phases[phaseIndex];
    const phaseStartDate = moment(new Date(phase.started_at)).tz(this.timeZone);
    const prevPhase: PhaseInstance = this.phases[phaseIndex - 1];

    if (!prevPhase) {
      return phaseStartDate.isSameOrAfter(this.today, 'day');
    }

    const prevPhaseStartDate = moment(new Date(prevPhase.started_at)).tz(this.timeZone);
    const todayisBetweenPrevAndCurrent = phaseStartDate.isSameOrAfter(this.today, 'day') && prevPhaseStartDate.isBefore(this.today, 'day');
    const todayIsPastPrevAndCurrentDateInvalid = !phaseStartDate.isValid() && prevPhaseStartDate.isBefore(this.today, 'day');

    return todayisBetweenPrevAndCurrent || todayIsPastPrevAndCurrentDateInvalid;
  }

  printUpcoming(phaseIndex: number): boolean {
    const phase = this.phases[phaseIndex];

    if (!phaseIndex) {
      return (phase.inTheFuture);
    }

    const prevPhase = this.phases[phaseIndex - 1];

    return (!prevPhase.inTheFuture && phase.inTheFuture);
  }
}
