import { CareModule } from './care-module';
import { PatientMdt } from './patient_mdt';
import {Surgery} from '../models/surgery';
import { HealthCareProfessional } from './health-care-professional';
import { Reminder } from './reminder';
import { GoalInstanceOverview } from './goal-instance-overview';
import { PhaseInstance } from './phase-instance';
import { environment } from 'src/environments/environment';
import { Appointment } from './appointment';

interface CurrentEducationalMaterialProgress {
  total: number;
  read: number;
}

export enum PathwayStatus {
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  FAILED = 'FAILED',
  GDPR_RESTRICTED = 'GDPR_RESTRICTED',
  PENDING = 'PENDING',
  STOPPED = 'STOPPED'
}

export enum PatientPathwayStopReason {
  NO_LONGER_WANTS_TREATMENT = 'NO_LONGER_WANTS_TREATMENT',
  CHOSEN_ALTERNATIVE_THERAPY = 'CHOSEN_ALTERNATIVE_THERAPY',
  NOT_MEDICALLY_FIT = 'NOT_MEDICALLY_FIT',
  SURGERY_NO_LONGER_INDICATED = 'SURGERY_NO_LONGER_INDICATED',
  NOT_ACHIEVED_GOALS = 'NOT_ACHIEVED_GOALS',
  GONE_PRIVATELY = 'GONE_PRIVATELY',
  PATIENT_UNAVAILABLE = 'PATIENT_UNAVAILABLE',
  PATIENT_MOVED_AWAY = 'PATIENT_MOVED_AWAY',
  NOT_ELIGIBLE_FOR_TREATMENT = 'NOT_ELIGIBLE_FOR_TREATMENT',
  NO_INSURANCE = 'NO_INSURANCE',
  PATIENT_DIED = 'PATIENT_DIED',
  SELECTED_INCORRECT_PATHWAY = 'SELECTED_INCORRECT_PATHWAY',
  MOVED_TO_NEW_PATHWAY = 'MOVED_TO_NEW_PATHWAY',
  OTHER = 'OTHER',
}

export class Pathway {
  uid: string;
  started_at: string;
  ended_at: string;
  care_module: CareModule;
  patient_mdt: PatientMdt;
  clinical_lead: HealthCareProfessional;
  case_manager: HealthCareProfessional;
  reminders: Reminder[];
  goals: GoalInstanceOverview[];
  current_educational_material_progress: CurrentEducationalMaterialProgress;
  current_phases: PhaseInstance[];
  patient_uid: string;
  patient_pathway_id: string;
  appointments: Appointment[];
  checklists: null;
  notes: null;
  phases: PhaseInstance[];
  status: PathwayStatus;
  stop_reason_code: PatientPathwayStopReason;

  value: any;

  translationKey: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    // used by pathway-details
    this.uid = item.uid || item.id;
    // used by dashboard-pathway
    this.patient_pathway_id = item.patient_pathway_uid || item.patient_pathway_id;

    if(!this.uid && this.patient_pathway_id) {
      // PathwayDashboard calls don't have uid, they use patient_pathway_id for this.
      // TEMP FIX!
      this.uid = this.patient_pathway_id;
    }

    this.value = this.uid;

    this.started_at = item.started_at ? item.started_at : '';
    this.ended_at = item.ended_at ? item.ended_at : '';

    this.current_educational_material_progress = item.current_educational_material_progress;

    if (item.care_module) {
      this.care_module = new CareModule(item.care_module);
      this.translationKey = this.care_module.translationKey;
    }

    if (item.patient_mdt) {
      this.patient_mdt = new PatientMdt(item.patient_mdt);
    }

    if (item.clinical_lead) {
      this.clinical_lead = new HealthCareProfessional(item.clinical_lead);
    }

    if (item.case_manager) {
      this.case_manager = new HealthCareProfessional(item.case_manager);
    }

    this.current_phases =  [];
    if (item.current_phases && item.current_phases.length) {

      item.current_phases.forEach(_phase => {
        this.current_phases.push(new PhaseInstance(_phase));
      });
    }

    this.reminders = [];
    if (item.reminders) {
      item.reminders.forEach(reminder => {
        this.reminders.push(new Reminder(reminder));
      });
    }

    this.goals = [];
    if (item.goals) {
      item.goals.forEach(goal => {
        this.goals.push(new GoalInstanceOverview(goal));
      });
    }

    this.appointments = [];
    if (item.appointments) {
      this.appointments = item.appointments.map(appointment => new Appointment(appointment));
    }

    if (item.phases && item.phases.length) {
      this.phases = [];

      item.phases.forEach(_phase => {
        this.phases.push(new PhaseInstance(_phase));
      });
    }

    this.patient_uid = item.patient_uid;

    this.status = item.status;
    this.stop_reason_code = item.stop_reason_code;
  }

  getHcps(
    onlyActiveHcps: boolean = true,
    includeLeadpositions: boolean = true,
    excludeLeadpositions: boolean = false,
  ): Array<HealthCareProfessional> {
    if (!this.patient_mdt?.hcps) {
      return;
    }

    const patientMdtHcps = this.patient_mdt.getAllHcps(onlyActiveHcps);
    const hcpsUids = [];

    let hcps: Array<HealthCareProfessional> = [];

    patientMdtHcps.forEach(_hcp => {
      hcps.push(_hcp);
      hcpsUids.push(_hcp.uid);
    });

    if (includeLeadpositions && this.case_manager && !hcpsUids.includes(this.case_manager.uid)) {
      if (!onlyActiveHcps || (onlyActiveHcps && this.case_manager.status === 'ACTIVE')) {
        hcps.unshift(this.case_manager);
        hcpsUids.push(this.case_manager.uid);
      }
    }

    if (includeLeadpositions && this.clinical_lead && !hcpsUids.includes(this.clinical_lead.uid)) {
      if (!onlyActiveHcps || (onlyActiveHcps && this.clinical_lead.status === 'ACTIVE')) {
        hcps.unshift(this.clinical_lead);
        hcpsUids.push(this.clinical_lead.uid);
      }
    }

    if (excludeLeadpositions) {
      hcps = hcps.filter(_hcp => {
        const ignoreUids = [this.case_manager?.uid, this.clinical_lead?.uid];
        return !ignoreUids.includes(_hcp.uid);
      });
    }

    return hcps;
  }

  getCurrentPhase() {
    const filtered = this.current_phases.filter(phase => phase.status === 'CURRENT');
    if (filtered.length > 0) {
      return filtered[0];
    }
  }
}
