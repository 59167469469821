<div class="modal-body">
  <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
    <button type="button" class="close" id="appointment-form-close" data-dismiss="modal" aria-label="Close"
      (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2 *ngIf="type === 'NEW'">{{ 'modals.appointment.add_new_appointment' | translate }}</h2>
    <h2 *ngIf="type === 'EDIT'">{{ 'modals.appointment.edit' | translate }}</h2>

    <div class="form-group styled required floating-label" *ngIf="type === 'EDIT'">
      <input type="text" id="inputPatient" class="form-control w-100" formControlName="patient" required disabled>
      <label for="inputPatient">{{ 'modals.appointment.patient' | translate }}</label>
    </div>

    <div class="form-group required" *ngIf="type === 'NEW'">
      <app-select [placeholder]="('modals.appointment.patient' | translate)" [hideSelected]="false" [multiple]="false"
        [clearable]="true" [searchable]="true" [items]="patients" [searchFn]="customSearchFn"
        (search)="getPatients($event)" (change)="patientSelected($event)" formControlName="patient" [asFilter]="false"
        [showCheckboxes]="false" [showUserDetails]="true"></app-select>

      <app-feedback-field [field]="form.get('patient')"></app-feedback-field>
    </div>

    <div class="form-group required" *ngIf="type== 'NEW'">
      <ng-select formControlName="pathway" class="users-select"
        placeholder="{{ 'modals.appointment.form.pathways' | translate }}" [items]="pathways"
        [searchFn]="customSearchFn" bindLabel="item.care_module.label" (change)="pathwaySelected($event)">
        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item">{{ item?.care_module?.translationKey | translate }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="wrapper" *ngIf="item">
            <div class="user-info">
              <p class="title">{{ item?.care_module?.translationKey | translate }}</p>
            </div>
          </div>
        </ng-template>
      </ng-select>

      <app-feedback-field [field]="form.get('pathway')"></app-feedback-field>
    </div>

    <div class="form-group styled required floating-label">
      <input type="text" id="inputTitle" class="form-control w-100" formControlName="title" required
        [maxlength]="titleMaxLength" [placeholder]="'modals.appointment.form.title' | translate">
      <label for="inputTitle" class="required">{{ 'modals.appointment.form.title' | translate }}</label>
      <app-feedback-field [field]="form.get('title')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !form?.controls?.title?.value)">
        {{( (form?.controls?.title?.value?.length || '0') | translateNumber )}}/{{titleMaxLength | translateNumber}}
      </p>
      <p class="text-danger small mb-4">{{ 'modals.appointment.form.medical_date' | translate }}</p>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <div class="form-group styled required floating-label">
          <input type="text" id="inputDate" class="form-control w-100" formControlName="start_date" required
            bsDatepicker>
          <label for="inputDate" class="required">{{ 'modals.appointment.form.date' | translate }}</label>
        </div>
      </div>
      <div class="col-6">
        <div class="custom-control custom-checkbox custom-control-inline mb-1">
          <input type="checkbox" class="custom-control-input" id="check-full-day" formControlName="full_day">
          <label class="custom-control-label" for="check-full-day">{{ 'modals.appointment.form.full_day' | translate
            }}</label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!form.get('full_day')?.value">
      <div class="col-6">
        <div class="form-group">
          <timepicker id="inputStartTime" class="with-label" formControlName="start_time"
            [showMeridian]="!time_24_hours" [showSpinners]="false" dir="ltr"></timepicker>
          <label for="inputStartTime" [ngClass]="{'required': !form.get('full_day')?.value}">{{ 'modals.appointment.form.start_time' | translate }}</label>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <timepicker id="inputEndTime" class="with-label" formControlName="end_time" dir="ltr"
            [showMeridian]="!time_24_hours" [showSpinners]="false"></timepicker>
          <label for="inputEndTime" [ngClass]="{'required': !form.get('full_day')?.value}">{{ 'modals.appointment.form.end_time' | translate }}</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <p id="feedback_time_after" class="validation-feedback general-feedback" *ngIf="form?.errors?.after">{{
          'modals.appointment.time_validator' | translate }}</p>
      </div>
    </div>

    <hr>

    <div class="form-group">
      <ng-select class="users-select" #hcpselect
        placeholder="{{ 'modals.appointment.form.search_role_or_hcp' | translate }}" [items]="hcps"
        [searchFn]="customSearchFn" (change)="hcpSelected($event)" [readonly]="hcps.length < 1">

        <ng-template ng-label-tmp let-item="item">
          <span *ngIf="item">{{ item?.getFullName() }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="wrapper" *ngIf="item">
            <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>
            <div class="user-info">
              <p class="title">{{ item?.getFullName() }}</p>
              <p class="sub-text">{{ item?.job_title }}</p>
            </div>
            <div class="status small text-muted" *ngIf="isHcpSelected(item)">
              <span>{{ 'modals.appointment.form.already_added' | translate }}</span>
            </div>
            <div class="status small text-secondary" *ngIf="!isHcpSelected(item)">
              <span class="icon" [inlineSVG]="'/assets/svg/add.svg'"></span>
              <span>{{ 'modals.appointment.form.add' | translate }}</span>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="participant-item" *ngIf="appointment?.patient_invitee">
      <app-avatar [profilePicture]="appointment?.patient_invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ appointment?.patient_invitee?.getFullName() }}</p>
        <p class="description">{{ 'modals.appointment.form.patient' | translate }}</p>
      </div>
      <div class="text-muted text-align-end">{{ 'modals.appointment.form.patient_not_removed' | translate }}</div>
    </div>

    <div class="participant-item clickable" *ngFor="let invitee of appointment?.invitees">
      <app-avatar [profilePicture]="invitee?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ invitee?.getFullName() }}</p>
        <p class="description">{{ invitee?.job_title }}</p>
      </div>

      <a href="" class="status text-secondary" id="appointment-form-remove-hcp" (click)="removeHcp($event, invitee)">
        <span class="icon" [inlineSVG]="'/assets/svg/close.svg'"></span>
        <span>{{ 'modals.appointment.form.remove' | translate }}</span>
      </a>
    </div>

    <hr>

    <div class="form-group styled floating-label mb-0">
      <input type="text" id="inputLocation" class="form-control w-100" formControlName="location"
        [maxlength]="locationMaxLength" [placeholder]="'modals.appointment.form.location' | translate">
      <label for="inputLocation">{{ 'modals.appointment.form.location' | translate }}</label>
      <app-feedback-field [field]="form.get('location')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0" *ngIf="!(validationVisible && !form?.controls?.location?.value)">
        {{( (form?.controls?.location?.value?.length || '0') | translateNumber )}}/{{locationMaxLength |
        translateNumber}}
      </p>
    </div>

    <div class="custom-control custom-checkbox custom-control-inline mb-1">
      <input type="checkbox" class="custom-control-input" id="check_link" [(ngModel)]="linkAvailable"
        [ngModelOptions]="{standalone: true}">
      <label class="custom-control-label" for="check_link">{{ 'modals.appointment.form.include_link' | translate
        }}</label>
    </div>

    <div class="form-group styled  floating-label" *ngIf="linkAvailable">
      <input type="text" id="inputLink" class="form-control w-100" formControlName="link"
        [placeholder]="'modals.appointment.form.link' | translate">
      <label for="inputLink">{{ 'modals.appointment.form.link' | translate }}</label>
    </div>

    <hr>

    <div class="form-group styled floating-label">
      <textarea appTextareaAutoresize id="inputDescription" class="form-control w-100" formControlName="description"
        [placeholder]="'modals.appointment.form.description' | translate"
        [maxlength]="descriptionMaxLength"></textarea>
      <label for="inputDescription">{{ 'modals.appointment.form.description' | translate }}</label>
      <app-feedback-field [field]="form.get('description')"></app-feedback-field>
      <p class="small text-muted text-align-end m-0"
        *ngIf="!(validationVisible && !form?.controls?.description?.value)">
        {{( (form?.controls?.description?.value?.length || '0') | translateNumber )}}/{{descriptionMaxLength |
        translateNumber}}
      </p>
      <p class="text-danger small">{{ 'modals.appointment.form.medical_date' | translate }}</p>

    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="appointment-form-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate
      }}</button>

    <button class="btn btn-secondary m-start-3" id="appointment-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
