import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-flow-welcome',
  templateUrl: './flow-welcome.component.html',
  styleUrls: ['./flow-welcome.component.scss']
})
export class FlowWelcomeComponent implements OnInit {
  @Input() inSidebar: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
