<div class="rowi" *ngIf="isLoading">
  <app-page-loader padding="50px"></app-page-loader>
</div>

<div class="row" *ngIf="!isLoading && kpis?.length">
  <div class="col-3" *ngFor="let kpi of kpis">
    <div class="card bg-white h-100">
      <div class="card-body d-flex flex-column justify-content-between">
        <div>
          <p class="h3 font-weight-black mb-1 kpi-title">{{ kpi.title }}</p>
          <p class="text-muted kpi-description">{{ kpi.description }}</p>
        </div>

        <div>
          <p class="h1 font-weight-black m-0 kpi-value">{{ kpi.value }} </p>
          <p class="m-0">{{ kpi.unit }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
