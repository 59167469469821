import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {PhoneValidator} from "../../validators/phone-validator";
import {HealthCareProfessional} from "../../models/health-care-professional";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {LocaleService} from "../../services/locale.service";
import {HcpService} from "../../services/hcp.service";
import {Profile} from "../../models/profile";
import {GeneralService} from "../../services/general.service";
import {BaseLocaleInterface} from "../../interfaces/base-locale.interface";
import {LanguageService} from "../../services/language.service";
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from "ngx-bootstrap/modal";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: 'app-onboarding-details-form',
  templateUrl: './onboarding-details-form.component.html',
  styleUrls: ['./onboarding-details-form.component.scss']
})
export class OnboardingDetailsFormComponent implements OnInit {
  @ViewChild('inputProfilePicture') inputProfilePicture: ElementRef;

  @Input() validationVisible: boolean;
  @Input() mode: string;

  @Input('data')
  public set data(data: {
    locale: BaseLocaleInterface,
    hcp?: HealthCareProfessional,
    profile?: Profile,
  }) {
    if (data) {
      this.formSetup();

      this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat(data.locale);
      this.bsDatepickerConfig.adaptivePosition = true;

      if (data.hcp) {
        this.addHCP(data.hcp);
      }

      if (data.profile) {
        this.addProfile(data.profile);
      }
    }
  }

  @Output() onFormInitialized: EventEmitter<UntypedFormGroup> = new EventEmitter();

  public dialCodeOptions: any[];
  public functionOptions: any[];
  public languageOptions: any[];

  public currentHCP: HealthCareProfessional;
  public currentProfile: Profile;

  public form: UntypedFormGroup;

  constructor(
    public bsDatepickerConfig: BsDatepickerConfig,
    public formBuilder: UntypedFormBuilder,
    public generalService: GeneralService,
    public hcpService: HcpService,
    public languageService: LanguageService,
    public localeService: LocaleService,
    public translateService: TranslateService,
    public modalService: BsModalService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.formSetup();
    this.dialCodeOptions = this.generalService.dialCodes;

    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
    });
  }

  formSetup() {
    if (!this.form) {
      this.form = this.formBuilder.group({
        profile_picture: [''],
        gender: [''],
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        language: ['', [Validators.required]], // read only
        job_title: ['', [Validators.required]],
        location: [''],
        location_url: [''],

        email: ['', [Validators.required, Validators.email]], // read only
        country_code: ['', [Validators.required]],
        phone: ['', [Validators.required, PhoneValidator.validate()]],
        contact_channel: [''],
        notifications_method_email: [''],
        notifications_method_sms: [''],
      });
    }

    this.onFormInitialized.emit(this.form);
  }

  addHCP(hcp: HealthCareProfessional) {
    this.currentHCP = hcp;

    this.form.get('gender').setValue(this.currentHCP.gender);
    this.form.get('first_name').setValue(this.currentHCP.first_name);
    this.form.get('last_name').setValue(this.currentHCP.last_name);
    this.form.get('language').setValue(this.currentHCP.language);
    this.form.get('location').setValue(this.currentHCP.location.name);
    this.form.get('location_url').setValue(this.currentHCP.location.url);
    this.form.get('job_title').setValue(this.currentHCP.job_title);
    this.form.get('profile_picture').setValue(this.currentHCP.profile_picture);
    this.form.get('phone').setValue(this.currentHCP.phone_number?.number);
    this.form.get('country_code').setValue(this.currentHCP.phone_number?.code);

    this.form.get('language').disable();
  }

  addProfile(profile: Profile) {
    this.currentProfile = profile;

    this.form.get('email').setValue(this.currentProfile.email);
    this.form.get('contact_channel').setValue(this.currentProfile.contact_channel);
    this.form.get('email').disable();

    if (this.currentProfile.contact_consented) {
      this.form.get('contact_channel').setValidators([Validators.required]);
    }

    if (profile.locale && profile.locale.notification_channels) {
      if (profile.locale.notification_channels.includes('EMAIL')) {
        this.form.get('notifications_method_email').setValue(true);
      }

      if (profile.locale.notification_channels.includes('SMS')) {
        this.form.get('notifications_method_sms').setValue(true);
      }
    }
  }
}
