import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Consent } from '../models/consent';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LanguageService } from './language.service';
import { HcpService } from './hcp.service';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ConsentService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public languageService: LanguageService,
    public hcpService: HcpService,
    public generalService: GeneralService
  ) {
    super(http, authenticationService);
  }

  fetchAllConsents(): Observable<any> {
    return new Observable((observer) => {
      const url = environment.platformUrl + '/oauth/consents';
      this.authenticatedGet(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getAllConsents(): Observable<Array<Consent>> {
    return new Observable((observer) => {
      this.fetchAllConsents().subscribe(result => {
        const consents: Array<Consent> = new Array();

        for (const item of result) {
          const consent = new Consent(item);
          consents.push(consent);
        }

        this.getCmsInfo(consents).subscribe(fullConsents => {
          fullConsents = this.sortByOrder(fullConsents);
          observer.next(fullConsents);
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
      });
    });
  }

  getCmsInfo(consents: Array<Consent>): Observable<Array<Consent>> {
    return new Observable((observer) => {
      if (!consents) {
        observer.next([]);
        observer.complete();
        return;
      }

      const cmsCalls = consents.map((consent: Consent) => this.fillConsentFromCms(consent));

      forkJoin(cmsCalls).subscribe(results => {
        observer.next(consents);
        observer.complete();
      });
    });
  }

  fillConsentFromCms(consent: Consent) {
    const url = `${environment.cmsUrl}/v3/content_types/consents/entries`;
    const locale = this.languageService.getLanguageCodeFromValue(consent.language)?.locale || 'en';

    return new Observable(observer => {
      this.cmsGet(url, locale, null, [consent.key], true).subscribe(response => {
        if(response && response?.entries?.length) {
          let contentEntry: any = response.entries[0];
          consent.addDetails(contentEntry);
        }

        observer.next(consent);
        observer.complete();
      }, error => {
        observer.next(error);
        observer.complete();
      });
    });
  }

  postConsents(consents: Array<Consent>): Observable<any> {
    return new Observable((observer) => {
      const url = `${environment.platformUrl}/oauth/consents`;
      const params = this.postableConsents(consents);

      this.authenticatedPost(url, params).subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }

  getCookiePolicy(locale): Observable<any> {
    return new Observable(observer => {
      const url = environment.cmsUrl + '/v3/content_types/cookie_policy/entries';
      this.cmsGet(url, locale).subscribe(result => {
        let entry: any;

        if(result?.entry) {
          entry = result?.entry;
        } else if (result?.entries) {
          entry = result?.entries[0];
        } else {
          entry = result;
        }

        observer.next(entry);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  postableConsents(consents: Array<Consent>): Object {
    return consents.map(consent => this.postableConsent(consent));
  }

  postableConsent(consent: Consent): Object {
    if (consent.isHospitalConsent()) {
      return {
        type: consent.type,
        hospital_uid: consent.hospital_uid,
        key: consent.key,
        language: consent.language,
        category: consent.category,
        status: consent.status.toUpperCase(),
        version: consent.version,
        hcp_uid: consent.hcp_uid
      };
    }

    if (consent.isPlatformConsent()) {
      return {
        type: consent.type,
        key: consent.key,
        language: consent.language,
        category: consent.category,
        status: consent.status.toUpperCase(),
        version: consent.version,
        platform_application_uid: environment.auth_username
      };
    }
  }

  getUserConsents(): Observable<Array<Consent>> {
    return new Observable((observer) => {
      const url = `${environment.platformUrl}/user/consents`;

      this.authenticatedGet(url).subscribe(result => {
        const consents: Array<Consent> = [];

        for (const item of result.items) {
          const consent = new Consent(item);
          consents.push(consent);
        }

        this.getCmsInfo(consents).subscribe(consentResult => {
          observer.next(consentResult);
          observer.complete();
        }, error => {
          observer.next(error);
          observer.complete();
        });
      });
    });
  }

  updateConsentStatus(consent: Consent, status: string): Observable<any> {
    return new Observable((observer) => {
      const url = `${environment.platformUrl}/user/consents`;

      const putConsent = {
        type: consent.type,
        key: consent.key,
        language: consent.language,
        status,
        version: consent.version,
        category: consent.category
      };

      if (consent.isHospitalConsent()) {
        putConsent['hospital_uid'] = consent.hospital_uid;
        putConsent['hcp_uid'] = this.hcpService.getCurrentStoredHcpUid();
      }

      const putConsents: any[] = []; // expects an array
      putConsents.push(putConsent);

      this.authenticatedPut(url, putConsents).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  sortByOrder(consents: Array<Consent>): Array<Consent> {
    return consents.sort((t1, t2) => {
      if (t1.order > t2.order) { return 1; }
      if (t1.order < t2.order) { return -1; }
      return 0;
    });
  }
}
