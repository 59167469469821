<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-hcp.svg'"></div>
    </div>

    <div class="content-part">
      <div style="max-width: 350px;">
        <h2>{{ 'pages.flow.link_invalid.link_expired' | translate }}</h2>
        <p>{{ 'pages.flow.link_invalid.pls_use' | translate }} <a href="" [routerLink]="['/forgot-password']" id="link_invalid_page_forgot_pw">{{ 'pages.flow.link_invalid.forgot_pw' | translate }}</a> {{ 'pages.flow.link_invalid.receive_new_link' | translate }}</p>
      </div>
    </div>

    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>
</div>
