import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {Observable, forkJoin} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import { environment } from '../../environments/environment';
import {ScopeService} from './scope.service';
import {Conversation} from '../models/conversation';
import { HcpService } from './hcp.service';
import { Attachment } from '../models/attachment';

@Injectable({
  providedIn: 'root'
})
export class ConversationService extends ApiService {
  private readonly platformUrl: string;
  private readonly environment: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public scopeService: ScopeService,
    public hcpService: HcpService,
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getConversations(
    filters: any = {conversation_type: ''},
    page = 0,
    size = 30,
    ): Observable<{
      conversations: Array<Conversation>,
      pagination: any
    }> {
    return new Observable(observer => {

      const paramBits = [
        `page=${String(page)}`,
        `size=${String(size)}`,
        'sort=latest_message_time,desc',
        'sort=created_at,desc'
      ]

      if(filters.hasOwnProperty('conversation_type') && filters.conversation_type.length ) {
        paramBits.push(`conversation_type=${filters.conversation_type}`);
      }

      const paramsString = paramBits.join('&');
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations?${paramsString}`;


      this.authenticatedGet(url).subscribe(result => {
        const conversations = this.mapConversations(result['items'], result['participants_details']);
        observer.next({'conversations': conversations, 'pagination': result['pagination']});
        observer.complete();
      }, error => {
        observer.error(error);
      })
    })
  }

  getConversation(uid: string): Observable<Conversation> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${uid}`;

      this.authenticatedGet(url).subscribe(result => {
        const conversation = this.mapConversation(result);
        observer.next(conversation);
        observer.complete();
      });
    });
  }

  getConversationEntries(conversation: Conversation, page = 0, size = 30): Observable<{
    conversation: Conversation, pagination: any
  }> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversation.uid}/entries?page=${page}&size=${size}`;
      this.authenticatedGet(url, 'v2').subscribe(result => {
        const filled_conversation = this.mapConversationEntries(result, conversation, page);
        observer.next({'conversation': filled_conversation, 'pagination': result['pagination']});
        observer.complete();
      });
    })
  }

  addConversationEntry(conversationUid: string, message: string, attachment?: Attachment): Observable<any> {
    return new Observable(observer => {
      if(!message || !message.length) {
        message = null;
      }

      if(attachment && attachment.uid) {
        if(attachment.isAudio) {
          this.postConversationAudio(conversationUid, message, attachment, observer);
        } else {
          this.postConversationFile(conversationUid, message, attachment, observer);
        }

      } else {
        this.postConversationEntry(conversationUid, message, observer);
      }
    });
  }

  loadAudioDetails(file): Observable<any> {
    return new Observable(observer => {
      var audio: any = document.createElement('audio');
      var reader = new FileReader();

      reader.onload = function (e) {
        audio.src = e.target.result;
        audio.addEventListener('loadedmetadata', function(){
            observer.next({
              duration: audio.duration
            });
            observer.complete();
        },false);
      };

      reader.readAsDataURL(file);
    });
  }

  postConversationEntry(conversationUid: string, message: string, observer) {
    const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversationUid}/entries`;

    let input: any = {
      message_type: 'TEXT_MESSAGE',
      message: message
    }

    this.authenticatedPost(url, input, 'v2').subscribe(result => {
      observer.next(result);
      observer.complete();
    });
  }

  postConversationFile(conversationUid: string, message: string, attachment: Attachment, observer) {
    const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversationUid}/entries`;

    let input: any = {
      message_type: 'FILE_MESSAGE',
      file_uid: attachment.uid,
      message: message
    }

    this.authenticatedPost(url, input, 'v2').subscribe(result => {
      observer.next(result);
      observer.complete();
    });
  }

  postConversationAudio(conversationUid: string, message: string, attachment: Attachment, observer) {
    const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversationUid}/entries`;

    let input: any = {
      message_type: 'AUDIO_MESSAGE',
      audio_file_uid: attachment.uid
    }

    this.loadAudioDetails(attachment.file).subscribe(audioDetails => {
      input.audio_length = Number(audioDetails.duration);

      this.authenticatedPost(url, input, 'v2').subscribe(audioPostResult => {
        if(message) {
          this.postConversationEntry(conversationUid, message, observer);
        } else {
          observer.next(audioPostResult);
          observer.complete();
        }
      });
    });
  }

  deleteConversationEntry(conversation_uid, entry_id): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversation_uid}/entries/${entry_id}`;
      this.authenticatedDelete(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  addHcp(conversation_uid, hcp_uid): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversation_uid}/hcps`;
      this.authenticatedPost(url,{ hcp_uid: hcp_uid}).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  addHcps(conversation_uid, hcp_uid: Array<string>): Observable<any> {
    return new Observable(observer => {
      const addHcpRequests = hcp_uid.map(uid => this.addHcp(conversation_uid, uid));

      forkJoin(addHcpRequests).subscribe(results => {
        observer.next(results);
        observer.complete();
      });
    })
  }

  removeHcp(conversation_uid, hcp_uid):Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversation_uid}/hcps/${hcp_uid}`;

      this.authenticatedDelete(url).subscribe(result => {
        observer.next(result);
        observer.complete();
      })
    })
  }

  removeHcps(conversation_uid, hcp_uid: Array<string>): Observable<any> {
    return new Observable(observer => {
      const removeHcpRequests = hcp_uid.map(uid => this.removeHcp(conversation_uid, uid));

      forkJoin(removeHcpRequests).subscribe(results => {
        observer.next(results);
        observer.complete();
      });
    })
  }

  mapConversations(items: Array<any>, participants: Array<any>): Array<Conversation> {
    const conversations = [];
    for (const item of items) {
      const conversation = this.mapConversation(item);
      conversation.setParticipantDetails(participants);
      conversations.push(conversation);
    }
    return conversations;
  }

  mapConversation(item): Conversation {
    const convo = new Conversation(item);
    convo.fillCurrentHcpUid(this.hcpService.getCurrentStoredHcpUid());
    return convo;
  }

  mapConversationEntries(result, conversation:Conversation, page): Conversation {
    conversation.fillHcps(result['participants_details']);
    conversation.addItems(result['items'], page);
    return conversation;
  }

  getDownloadLink(conversationUid: string, entryId: string): Observable<string>{
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversationUid}/entries/${entryId}/download-link`;

      this.authenticatedGet(url).subscribe(result => {
        observer.next(result?.download_link);
        observer.complete();
      });
    });
  }

  addConversation(newConversationData: any): Observable<Conversation> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations`;

      this.authenticatedPost(url, newConversationData).subscribe(result => {
        const convo = this.mapConversation(result);
        observer.next(convo);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  }

  editConversationSubject(conversation_id, conversation_type: string, subject: string): Observable<any> {
    return new Observable(observer => {
      const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversation_id}`;
      const data = {
        conversation_type: conversation_type,
        subject: subject
      };

      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  }

  // Attachment
  postAttachment(conversation_id: string, file) {
    const url = `${this.platformUrl}/hcps/${this.hcpService.getCurrentStoredHcpUid()}/conversations/${conversation_id}/files`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return new Observable(observer => {
      this.authenticatedPost(url, formData, null, { 'content-type': 'multipart/form' }).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }
}
