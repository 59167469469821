<div class="layout-header-pusher header-title-only"></div>

<div class="layout-header header-title-only">
  <div class="header-content">
    <div class="container">
      <h1>{{ 'pages.default.analytics.overview.analytics' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <ng-container *ngIf="scopeService?.doesCurrentHcpHasOneOfPermissions([
      'ANALYTICS.PATHWAYS_OVERVIEW',
      'ANALYTICS.PATIENT_ONBOARDING'
    ])">
      <div class="heading-block mb-2">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.analytics.overview.patients' | translate }}</h2>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2"
        id="analytics-overview-pathways-overview"
        [routerLink]="['/analytics/pathways-overview']"
        *ngIf="scopeService?.doesCurrentHcpHasPermission('ANALYTICS.PATHWAYS_OVERVIEW')"
        >
        <div class="block-wrapper p-start-3 p-end-1">
          <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/screen.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.analytics.overview.pathways-overview-title' | translate }}</p>
          <p class="value">{{ 'pages.default.analytics.overview.pathways-overview-desc' | translate }}</p>
        </div>
        <div class="block-action m-start-auto">
          <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>

      <div class="single-item-block item-clickable mb-2"
        id="analytics-overview-onboarding"
        [routerLink]="['/analytics/registration']"
        *ngIf="scopeService?.doesCurrentHcpHasPermission('ANALYTICS.PATIENT_ONBOARDING')"
        >
        <div class="block-wrapper p-start-3 p-end-1">
          <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/person.svg'"></span>
        </div>
        <div class="block-wrapper">
          <p class="label">{{ 'pages.default.analytics.overview.onboarding-title' | translate }}</p>
          <p class="value">{{ 'pages.default.analytics.overview.onboarding-desc' | translate }}</p>
        </div>
        <div class="block-action m-start-auto">
          <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="rtl-mirrored-inline-svg"></span>
        </div>
      </div>
    </ng-container>

  </div>
</div>
