import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CareModule } from '../../../../models/care-module';
import { HealthCareProfessional } from '../../../../models/health-care-professional';
import { MedicalTeam } from '../../../../models/medical-team';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';
import { MdtService } from '../../../../services/mdt.service';

@Component({
  selector: 'app-hospital-team-step',
  templateUrl: './hospital-team-step.component.html'
})
export class HospitalTeamStepComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Input() selectedCareModule: CareModule;
  @Output() selectedCareModuleChange = new EventEmitter<CareModule>();

  @Input() selectedCareModuleWasUpdated: boolean;
  @Output() selectedCareModuleWasUpdatedChange = new EventEmitter<boolean>();

  public isHospitalTeamValid: boolean;
  public hcps: HealthCareProfessional[] = [];
  public mdts: MedicalTeam[] = [];
  public allMdtsOrHcps: Array<MedicalTeam | HealthCareProfessional> = [];
  public mdtOrHcpSelect: MedicalTeam | HealthCareProfessional;

  public searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();
  public searchMdtOtherHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  public isLoading: boolean;

  constructor(
    public hcpService: HcpService,
    public mdtService: MdtService,
    public hospitalService: HospitalService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    forkJoin([
      this.getCareModuleDetails(),
      this.getAllMdtsAndHcps('')
    ]).subscribe(() => {
      this.isLoading = false;
    })

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });

    this.searchMdtOtherHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.getAllMdtsAndHcps(result.term).subscribe();
    });
  }

  ngOnDestroy(): void {
    this.searchHcpListEvent.unsubscribe();
    this.searchMdtOtherHcpListEvent.unsubscribe();
  }

  getCareModuleDetails(): Observable<any> {
    return new Observable(observer => {
      if (!this.selectedCareModuleWasUpdated) {
        const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
        this.hospitalService.getCareModule(hospitalUid, this.selectedCareModule?.uid).subscribe((careModule: CareModule) => {
          this.selectedCareModule = careModule;
          this.selectedCareModuleChange.emit(careModule);

          this.selectedCareModuleWasUpdatedChange.emit(true);

          observer.next();
          observer.complete();
        });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  searchHcpList(searchTerm?): void {
    if (!searchTerm) {
      this.hcps = [];
    }

    this.getHcps(searchTerm).subscribe(response => {
      this.hcps = response;
    });
  }

  getAllMdtsAndHcps(term: string): Observable<any> {
    return new Observable(observer => {
      forkJoin([
        this.getMdts(term),
        this.getHcps(term)
      ]).pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(results => {
        this.allMdtsOrHcps = [];
        this.allMdtsOrHcps = [...this.allMdtsOrHcps, ...results[0], ...results[1]];

        observer.next();
        observer.complete();
      });
    });
  }

  getHcps(searchTerm?: string): Observable<HealthCareProfessional[]> {
    return new Observable(observer => {
      this.hcpService.getPaged({ last_name: searchTerm || '', status: 'ACTIVE' }, 'last_name,asc').subscribe(result => {
        this.hcps = result.items;
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  getMdts(searchTerm: string): Observable<MedicalTeam[]> {
    return new Observable(observer => {
      this.mdtService.getPaged({ name: searchTerm }, 'name,asc', 0, 50).subscribe(result => {
        observer.next(result.items);
        observer.complete();
      });
    });
  }

  isMdtSelected(mdt: MedicalTeam): boolean {
    const filtered = this.selectedCareModule?.default_mdts?.filter(item => item.uid === mdt.uid);
    return filtered?.length > 0;
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    const filtered = this.selectedCareModule?.default_hcps?.filter(item => item.uid === hcp.uid);
    return filtered?.length > 0;
  }

  onAddMdtOrHcp(): void {
    if (!this.mdtOrHcpSelect) {
      return;
    }

    if (this.mdtOrHcpSelect instanceof MedicalTeam && !this.isMdtSelected(this.mdtOrHcpSelect)) {
      if (!this.selectedCareModule?.default_mdts) {
        this.selectedCareModule.default_mdts = [];
      }

      this.selectedCareModule.default_mdts.push(this.mdtOrHcpSelect);
    }

    if (this.mdtOrHcpSelect instanceof HealthCareProfessional && !this.isHcpSelected(this.mdtOrHcpSelect)) {
      if (!this.selectedCareModule.default_hcps) {
        this.selectedCareModule.default_hcps = [];
      }

      this.selectedCareModule.default_hcps.push(this.mdtOrHcpSelect);
    }

    if (this.ngSelectComponent) {
      this.ngSelectComponent.handleClearClick();
    }

    this.getAllMdtsAndHcps('').subscribe();
  }

  onRemoveHcp(event, hcp: HealthCareProfessional): void {
    event.preventDefault();

    const index = this.selectedCareModule.default_hcps.indexOf(hcp);

    if (index >= 0) {
      this.selectedCareModule.default_hcps.splice(index, 1);
    }
  }

  onRemoveMdt(event, mdt: MedicalTeam): void {
    event.preventDefault();

    const index = this.selectedCareModule.default_mdts.indexOf(mdt);
    if (index >= 0) {
      this.selectedCareModule.default_mdts.splice(index, 1);
    }
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }
}
