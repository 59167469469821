export class Faq {
  public question: string;
  public answer: Array<any>;
  public uid: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.question = item.question;
    this.answer = item.answer;
    if (item._metadata) {
      this.uid = item._metadata.uid;
    }
  }
}
