<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <app-flow-welcome [inSidebar]="true"></app-flow-welcome>
    </div>

    <div class="content-part">
      <p class="text-uppercase text-info font-weight-bolder" *ngIf="homeFlowService.getSidebarTitleKey()">{{
        homeFlowService.getSidebarTitleKey() | translate }}</p>

      <app-onboarding-icon-steps *ngIf="showOnboardingIconSteps"
        [currentState]="currentState"></app-onboarding-icon-steps>
    </div>

    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body">
  <div class="body-content">

    <div class="container" *ngIf="!isFormDataLoaded">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="isFormDataLoaded">
      <h1 class="display-1 mb-3">{{ 'pages.flow.onboarding_details.your_details' | translate }}</h1>
      <app-onboarding-details-form (onFormInitialized)="onFormInitialized($event)" [mode]="'onboarding'"
        [data]="formComponentData" [validationVisible]="validationVisible"></app-onboarding-details-form>
    </div>
  </div>

  <div class="layout-footer-pusher"></div>
  <div class="layout-footer">
    <div class="footer-content">
      <div class="container">
        <button id="button_onboarding_details_confirm" class="btn btn-secondary m-start-auto" (click)="formSubmit()"
          [ngClass]="{'loader': isUpdatingDetails}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isUpdatingDetails"></em></span>
          <span>{{ 'action.continue' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
