import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class AfterFieldsValidator {
  static validate(startTimeLabel: string, endTimeLabel: string, errorKey) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      const startControl: AbstractControl = group.get(startTimeLabel);
      const endControl: AbstractControl = group.get(endTimeLabel) ;

      const startTime = new Date(startControl.value);
      const endTime = new Date(endControl.value);
      
      if ( endTime <= startTime) {
        const error = {};
        error[errorKey] = true;
        return error;
      } else {
        return null;
      }
    };
  }
}
