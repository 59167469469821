import { TranslationInterface } from '../interfaces/translation.interface';
import { FormFieldValue } from './form-field-value';

export class FormField {
  field_value: FormFieldValue;
  id: string;
  order: number;
  read_only: boolean;
  required: boolean;
  visible: boolean;
  evaluation_required: boolean;
  label: TranslationInterface;
  description: TranslationInterface;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id ? item.id : '';
    this.order = item.order || 0;
    this.required = item.required;
    this.read_only = item.read_only;
    this.visible = item.visible;
    this.evaluation_required = item.evaluation_required;
    this.label  = item.label ||  '';
    this.field_value = new FormFieldValue(item.field_value);
    this.description = item.description;
  }

  get translationKey():string {
    return `shared.${this.label?.region}.${this.label?.key}`;
  }
}
