import { TranslationInterface } from '../interfaces/translation.interface';
import { HealthCareProfessional } from './health-care-professional';

export enum CareModuleType {
  CLINICAL = 'CLINICAL',
  EDUCATION = 'EDUCATION'
}

export interface DefaultMdtGroup {
  uid?: string,
  name?: string
  hcps?: HealthCareProfessional[]
}

export class CareModule {
  public uid: string;
  public name: TranslationInterface;
  public description: TranslationInterface;
  public pathway_template_uid: string;
  public value: string;
  public type: string;
  public default_case_manager: HealthCareProfessional;
  public default_clinical_lead: HealthCareProfessional;
  public default_mdts: DefaultMdtGroup[];
  public default_hcps: HealthCareProfessional[];


  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.name = item.name;
    this.description = item.description;
    this.value = this.uid;
    this.type = item.type;
    this.pathway_template_uid = item.pathway_template_uid;

    // hospitals/{hospitalUid}/care-modules returns string uid of cl + cm
    // hospitals/{hospitalUid}/care-modules/{careModuleUid} returns object of cl + cm
    if (typeof item.default_case_manager === 'string') {
      this.default_case_manager = new HealthCareProfessional({ uid: item.default_case_manager });
    } else {
      this.default_case_manager = new HealthCareProfessional(item.default_case_manager);
    }

    if (typeof item.default_clinical_lead === 'string') {
      this.default_clinical_lead = new HealthCareProfessional({ uid: item.default_clinical_lead });
    } else {
      this.default_clinical_lead = new HealthCareProfessional(item.default_clinical_lead);
    }

    this.default_mdts = [];
    this.default_hcps = [];

    if (item.default_mdts?.length > 0) {
      item.default_mdts.forEach(mdt => {
        let mdtGroup: DefaultMdtGroup = {};
        mdtGroup.uid = mdt.uid;
        mdtGroup.name = mdt.name;
        mdtGroup.hcps = [];

        mdt.hcps?.forEach(hcp => {
          mdtGroup.hcps.push(new HealthCareProfessional(hcp));
        });

        this.default_mdts.push(mdtGroup);
      });
    }

    if (item.default_hcps?.length > 0) {
      item.default_hcps.forEach(hcp => {
        this.default_hcps.push(new HealthCareProfessional(hcp));
      });
    }
  }

  get translationKey(): string {
    return `shared.${this.name?.region}.${this.name?.key}`;
  }

  get descriptionTranslationKey(): string {
    return `shared.${this.description?.region}.${this.description?.key}`;
  }

  get isClinical(): boolean {
    return this.type === CareModuleType.CLINICAL;
  }

  get isEducation(): boolean {
    return this.type === CareModuleType.EDUCATION;
  }
}
