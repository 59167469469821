import { OnboardingChartPeriod } from "./onboarding-chart-period";
import { Kpi } from "./kpi";
import { AnalyticsPeriod } from "../enums/analytics-period";

export class DnaOnboardingDashboard {
  public period_type: AnalyticsPeriod;
  public status_data: OnboardingChartPeriod[];
  public status_kpis: Kpi[];

  constructor(item?: any) {
    if (item) {
      this.period_type = item.period_type;
      this.status_kpis = this.mapKpis(item.status_kpis);

      if (item.status_data) {
        this.status_data = item.status_data.map(data => new OnboardingChartPeriod(data));

        if (this.period_type === AnalyticsPeriod.WEEK_MONDAY || this.period_type === AnalyticsPeriod.WEEK_SUNDAY) this.status_data = this.sort(this.status_data, 'week');
        if (this.period_type === AnalyticsPeriod.MONTH) this.status_data = this.sort(this.status_data, 'month');
      }
    }
  }

  mapKpis(kpis: any): Kpi[] {
    let result = [];

    for (let kpi in kpis) {
      const k = new Kpi({ ...kpis[kpi], title: kpi, key: kpi });
      result.push(k);
    }

    return result;
  }

  sort(data: Array<OnboardingChartPeriod>, type: 'week' | 'month'): Array<OnboardingChartPeriod> {
      return data.sort((t1, t2) => {
        if (t1.period.year > t2.period.year) return 1;
        if (t1.period.year < t2.period.year) return -1;

        if (t1.period[type] > t2.period[type]) return 1;
        if (t1.period[type] < t2.period[type]) return -1;

        return 0;
      });
  }
}
