import { Component, OnInit, Output, EventEmitter, Input, Renderer2 } from '@angular/core';
import { CalendarEvent, DAYS_OF_WEEK, CalendarView, CalendarDateFormatter } from 'angular-calendar';
import moment, { Moment } from 'moment';
import { CustomCalendarDateFormatter } from './../../providers/custom-calendar-date-formatter.provider';
import { AppointmentSelectionEvent } from '../../events/appointment-selection-event';
import { AppointmentService } from '../../services/appointment.service';
import { Appointment } from '../../models/appointment';
import { Subscription } from 'rxjs';
import { AttentionCenterService } from '../../services/attention-center.service';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-calendar-week-view',
  templateUrl: './calendar-week-view.component.html',
  styleUrls: ['./calendar-week-view.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomCalendarDateFormatter,
    },
  ]
})
export class CalendarWeekViewComponent implements OnInit {
  @Output() onAppointmentSelect: EventEmitter<AppointmentSelectionEvent> = new EventEmitter();
  @Input() renderMwlView: boolean;
  @Input() timeZone: string;


  public isLoading: boolean;
  private _currentWeekFirstDay;
  private _filters;
  private appointmentsFetchInterval: any;

  public onAppointmentAddedSubscription: Subscription;
  public onAppointmentChangeSubscription: Subscription;
  public onAppointmentStatusChangedSubscription: Subscription;
  public onAppointmentRemovedSubscription: Subscription;
  public onAppointmentCancelledSubscription: Subscription;
  public onNewAppointmentsPendingSubscription: Subscription;

  get currentWeekFirstDay(): Moment {
    return this._currentWeekFirstDay;
  }

  @Input()
  set currentWeekFirstDay(val: Moment) {
    this._currentWeekFirstDay = val;
    this._currentWeekFirstDay.status = 'In Process';

    this.viewDate = val.toDate();
    this.getAppointments();
  }

  @Input()
  set filters(val: any) {
    this._filters = val;
    this.getAppointments();
  }

  public weekStartsOn: number;
  public viewDate: Date;
  public events: CalendarEvent[];
  public colors: any;
  public appointments: Appointment[];

  constructor(
    public appointmentService: AppointmentService,
    public attentionCenterService: AttentionCenterService,
    public localeService: LocaleService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.events = [];

    const first_day_of_week = this.localeService.getLocalePreferences().locale.first_day_of_week;
    this.weekStartsOn = +DAYS_OF_WEEK[first_day_of_week];

    if (!this.weekStartsOn || Number.isNaN(this.weekStartsOn)) {
      this.weekStartsOn = 0;
    }


    this.onAppointmentAddedSubscription = this.appointmentService.onAppointmentAdded.subscribe(() => this.getAppointments(false));
    this.onAppointmentChangeSubscription = this.appointmentService.onAppointmentChange.subscribe(appointment => this.changeAppointment(appointment));
    this.onAppointmentStatusChangedSubscription = this.appointmentService.onAppointmentStatusChanged.subscribe(() => this.getAppointments(false));
    this.onAppointmentRemovedSubscription = this.appointmentService.onAppointmentRemoved.subscribe(appointment => this.removeAppointment(appointment));
    this.onNewAppointmentsPendingSubscription = this.attentionCenterService.onNewAppointmentsPending.subscribe(() => this.getAppointments(false));

    this.onAppointmentCancelledSubscription = this.appointmentService.onAppointmentCancelled.subscribe(appointment => {
      if (appointment.hasRemovedState()) {
        this.removeAppointment(appointment);
      }
    });

    this.appointmentsFetchInterval = setInterval(() => {
      this.getAppointments(false);
    }, 60000);
  }

  ngOnDestroy() {
    this.onAppointmentAddedSubscription?.unsubscribe();
    this.onAppointmentChangeSubscription?.unsubscribe();
    this.onAppointmentStatusChangedSubscription?.unsubscribe();
    this.onAppointmentRemovedSubscription?.unsubscribe();
    this.onNewAppointmentsPendingSubscription?.unsubscribe();
    this.onAppointmentCancelledSubscription?.unsubscribe();

    clearInterval(this.appointmentsFetchInterval);
  }

  getAppointments(loader = true) {
    if (loader) {
      this.isLoading = true;
    }

    if (loader || !this.appointments) {
      this.appointments = [];
    }

    if (!this._filters || !this.currentWeekFirstDay) {
      return;
    }

    if (this.events && this.events.length) {
      this.events.splice(0, this.events.length);
    }

    const startDate = this.currentWeekFirstDay;
    const endDate = startDate.clone().add(7, 'days');

    const days = { startDate, endDate };
    const filters = { ...days, ...this._filters };  // combine objects
    this.appointmentService.getAllAppointments(filters).subscribe(allAppointments => {
      this.appointments = allAppointments;
      this.convertAppointmentsToEvents(this.appointments);
      this.isLoading = false;
    });
  }

  removeAppointment(appointment: Appointment) {
    const foundAppointment = this.appointments.find(item => item.uid === appointment.uid);

    if (foundAppointment) {
      this.appointments.splice(this.appointments.indexOf(foundAppointment), 1);
    }

    this.convertAppointmentsToEvents(this.appointments);
  }

  changeAppointment(appointment: Appointment) {
    const foundAppointment = this.appointments.find(item => item.uid === appointment.uid);

    if (foundAppointment) {
      foundAppointment.merge(appointment);
    }

    this.convertAppointmentsToEvents(this.appointments);
  }

  convertAppointmentsToEvents(appointments) {
    const evts = [];
    this.events = [];

    appointments.forEach(appointment => {
      evts.push(this.convertToMwlCalendarEvent(appointment));
    });
    this.events = evts;
  }

  convertToMwlCalendarEvent(appointment: Appointment): CalendarEvent {
    const event: CalendarEvent = {} as CalendarEvent;

    // Time Appointment
    if (!appointment.date && appointment.start_date && appointment.end_date) {
      event.title = appointment.title;

      event.start = this.convertDate(appointment.start_date);
      event.end = this.convertDate(appointment.end_date);

      event.allDay = false;

      // the color object of the event.
      event.color = {
        primary: '#000099',
        secondary: '#000099',
      };
    }

    // All-day Appointment
    if (appointment.date && !appointment.start_date && !appointment.end_date) {
      event.title = appointment.patient_invitee.getFullName();

      event.start = this.convertDate(appointment.date);
      event.end = this.convertDate(appointment.date);

      event.allDay = true;

      // the color object of the event.
      event.color = {
        primary: '#CC0099',
        secondary: '#CC0099',
      };
    }

    // resizable event or not
    event.resizable = {
      beforeStart: false,
      afterEnd: false
    };

    // draggable event or not
    event.draggable = false;

    const dif_minutes = moment(appointment.end_date).diff(moment(appointment.start_date), 'minutes');

    const cssClasses = [];

    if (dif_minutes < 20) {
      cssClasses.push('event-is-tiny');
    }

    if (appointment.hasCancelledState()) {
      cssClasses.push('event-cancelled');
    } else if (appointment.hasNoneState()) {
      cssClasses.push('event-pending');
    }

    // class to enforce some styling rules
    event.cssClass = cssClasses.join(' ');


    // app specific attached data or models
    event.meta = {
      model: appointment
    };

    return event;
  }

  calendarEventClickHandler(events) {
    const meta = events.event.meta;
    const model = meta.model;

    this.onAppointmentSelect.emit(new AppointmentSelectionEvent(events.sourceEvent, model));
  }

  convertDate(date) {
    const current_locale = moment().locale();
    moment.locale('en');
    const dateString = moment(date).tz(this.timeZone).format('MM/DD/YYYY HH:mm:ss');
    moment(date).tz(this.timeZone);
    moment.locale(current_locale);
    return new Date(dateString);
  }
}
