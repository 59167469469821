import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Appointment } from '../../models/appointment';
import { FormField } from '../../models/form-field';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { Invitee } from '../../models/invitee';
import { Pathway } from '../../models/pathway';
import { UserTask } from '../../models/user-task';
import { UserTaskBasic } from '../../models/user-task-basic';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralService } from '../../services/general.service';
import { HcpService } from '../../services/hcp.service';
import { LocaleService } from '../../services/locale.service';
import { PathwayService } from '../../services/pathway.service';
import { AfterFieldsValidator } from '../../validators/after-validator';
import { DateFormat } from '../../models/date-format';
import { PatientInvitee } from '../../models/patient-invitee';
import { ErrorService } from '../../services/error.service';
import { UserTaskService } from '../../services/user-task.service';

@Component({
  selector: 'app-user-task-appointment-modal',
  templateUrl: './user-task-appointment-modal.component.html',
  styleUrls: ['./user-task-appointment-modal.component.scss']
})
export class UserTaskAppointmentModalComponent implements OnInit {
  public userTaskBasic: UserTaskBasic;
  public userTask: UserTask;

  public titleMaxLength = 50;
  public locationMaxLength = 255;
  public descriptionMaxLength = 1000;

  public isSaving: boolean = false;
  public isSubmitting: boolean;
  public validationVisible: boolean;
  public time_24_hours: boolean;

  public form: UntypedFormGroup;
  public linkAvailable: Boolean = false;

  public patient_uid: string;
  public patient_pathway_uid: string;
  public appointment: Appointment;
  public hcps: HealthCareProfessional[] = [];

  public dateFormat: DateFormat;
  public asCc: boolean = false;
  public fullDayAppointment: boolean = false;
  public formValidators: ValidatorFn[] = [AfterFieldsValidator.validate('start_time', 'end_time', 'after')];

  @Output() taskSubmitSuccess: EventEmitter<any> = new EventEmitter();

  @ViewChild('hcpselect', { read: NgSelectComponent }) hcpselect: NgSelectComponent;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public localeService: LocaleService,
    public bsDatepickerConfig: BsDatepickerConfig,
    public hcpService: HcpService,
    public authenticationService: AuthenticationService,
    public pathwayService: PathwayService,
    public taskService: UserTaskService,
    public translate: TranslateService,
    public errorService: ErrorService,
    public toastrService: ToastrService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();

    let dateFormat = this.localeService.getBsDatePickerInputFormat(preferences.locale);
    dateFormat = this.localeService.transformDateFormatToArabic(dateFormat);
    this.bsDatepickerConfig.dateInputFormat = dateFormat;
    this.bsDatepickerConfig.adaptivePosition = true;

    this.time_24_hours = preferences.locale.time_24_hours;
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.formsSetup();
    this.loadData();
  }

  formsSetup() {
    this.form = this.formBuilder.group({
      pathway: [{value: '', disabled: true}],
      title: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]],
      location_name: [''],
      location_url: [''],
      description: [''],
      full_day: new UntypedFormControl(this.fullDayAppointment)
    });

    this.form.setValidators(this.formValidators);

    this.form.get('full_day').valueChanges.subscribe(() => this.fullDayChanged());
  }

  loadData() {
    this.userTask = new UserTask();
    this.userTask.addBasicData(this.userTaskBasic);
    this.populateFormData();

    this.getUserTask(this.userTaskBasic.uid);
    this.appointment = new Appointment({});
     // load first hcps in the dropdown, for the initial view
  }

  getUserTask(id) {
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const observable = this.asCc ? this.taskService.getHospitalUserTask(id, hospitalUid) : this.taskService.getUserTask(id, hcpUid);

    observable.subscribe(result => {
      this.userTask = result;
      this.patient_uid = this.userTask.patient.uid;
      this.patient_pathway_uid = this.userTask.patient_pathway_id;
      this.appointment.patient_invitee = new PatientInvitee(this.userTask.patient);
      this.populateFormData();
      this.getHCPs();
    });
  }

  // should be called after using the basic data, and after loading the complete data
  populateFormData() {
    if (this.userTask.care_module) {
      const pathwayName = this.translate.instant(this.userTask.care_module?.translationKey);
      this.form.get('pathway').setValue(pathwayName);
    }

    // Set preset text values;
    this.setPresetValue('title', 'title_key');
    this.setPresetValue('description', 'description_key');
    this.setPresetValue('location_name', 'location_name_key');
    this.setPresetValue('location_url', 'location_url_key');

    // Set preset time values;
    this.setPresetTime('start_time', 'start_date');
    this.setPresetTime('end_time', 'end_date');

    // check if location_url now has a value
    if(this.form.get('location_url')?.value) {
      this.linkAvailable = true;
    }
  }

  setPresetValue(formControlName: string, fieldID: string): void {
    const field:FormField = this.userTask.form_fields.find((field) => field.id === fieldID);

    if(field?.field_value?.value?.length) {
      let slug: string =  'shared.' + field.field_value.value.replace("::", ".");
      const translation: string = this.translate.instant(slug);
      this.form.get(formControlName).setValue(translation);
    }
  }

  setPresetTime(formControlName: string, fieldID: any): void {
    const field:FormField = this.userTask.form_fields.find((field) => field.id === fieldID);

    if(field?.field_value?.value?.length) {
      const d: Date = this.localeService.dateWithoutTimeZone(field.field_value.value);
      this.form.get(formControlName).setValue(d);
      this.form.get('start_date').setValue(d); //start_date is always the same, for both start and end date objects.
    }
  }

  fullDayChanged() {
    if (this.form.get('full_day').value) {
      this.form.get('start_time').disable();
      this.form.get('start_time').setValidators([]);

      this.form.get('end_time').disable();
      this.form.get('end_time').setValidators([]);

      this.form.clearValidators();
    } else {
      this.form.get('start_time').enable();
      this.form.get('start_time').setValidators([Validators.required]);

      this.form.get('end_time').enable();
      this.form.get('end_time').setValidators([Validators.required]);

      this.form.setValidators(this.formValidators);
    }

    this.form.updateValueAndValidity();
  }

  searchHCPs(event?) {
    this.getHCPs(event);
  }

  getHCPs(event?) {
    let term = '';

    if (event && event['term'] && event['term'].length) {
      term = event['term'];
    } else {
      this.hcps = [];
    }

    if (this.authenticationService.hasCcRole()) {
      const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
      this.pathwayService.getPathwayByHospital(hospital_uid, this.patient_uid, this.patient_pathway_uid).subscribe((result: Pathway) => {
        this.handleHcpsResult(result);
      });
    } else {
      const hcp_uid = this.hcpService.getCurrentStoredHcpUid();
      this.pathwayService.getPathwayByHcp(hcp_uid, this.patient_uid, this.patient_pathway_uid).subscribe((result: Pathway) => {
        this.handleHcpsResult(result);
      });
    }
  }

  handleHcpsResult(pathway: Pathway) {
    this.hcps = pathway.getHcps(true, true, false);
  }

  hcpSelected(hcp) {
    if (!hcp || this.isHcpSelected(hcp)) {
      return;
    }

    if (!this.appointment.invitees) {
      this.appointment.invitees = [];
    }

    const invitee = new Invitee(hcp);
    this.appointment.invitees.push(invitee);
    this.hcpselect.handleClearClick();
  }

  isHcpSelected(hcp: HealthCareProfessional): boolean {
    if (!this.appointment.invitees) {
      return false;
    }
    const index = this.appointment.invitees.map(invitee => invitee.uid).indexOf(hcp.uid);
    return (index >= 0);
  }

  removeHcp($event, hcp) {
    $event.preventDefault();

    const index = this.appointment.invitees.indexOf(hcp);
    if (index >= 0) {
      this.appointment.invitees.splice(index, 1);
    }
  }

  customSearchFn(term: string, item: any) {
    return true; // always return, searching is done at the backend
  }

  handleSaveChanges() {}

  isFormValid(): boolean {
    if (!this.appointment.patient_invitee) {
      return false;
    }

    return this.form.valid;
  }

  handleSubmit() {
    if(this.isSaving) {
      return;
    }

    if (!this.isFormValid()) {
      this.validationVisible = true;
    } else {
      this.isSaving = true;
      const hcpUid = this.hcpService.getCurrentStoredHcpUid();

      this.taskService.completeUserTask(this.userTask.uid, hcpUid, {variables: this.createPayLoad()}).subscribe(result => {
        this.resultHandler();
      }, error => {
        this.errorHandler(error);
      });
    }
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  resultHandler() {
    this.isSaving = false;
    this.toastrService.info(this.translate.instant('modals.user_task_appointment.update_success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.bsModalRef.hide();
    this.taskSubmitSuccess.emit();
  }

  errorHandler(error: any) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach( err => {
        let field = err.field;
        if (err.field === 'end_date') {
          field = 'end_time';
        }

        this.form.get(field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }

  createPayLoad() {
    const payload:any = {
      title: this.form.value['title'],
      description: this.form.value['description'],
      hcp_uids: this.appointment.invitees.map(i => i.uid),
      location_name: this.form.value['location_name']
    };

    if (this.linkAvailable && this.form.value['location_url']) {
      payload.location_url = this.form.value['location_url'];
    }

    if (this.form.get('full_day').value) {
      payload.date = this.generalService.dateToString(this.form.get('start_date').value);
    } else {
      const start_date: Date = this.form.get('start_date').value;

      const day = start_date.getDate();
      const month = start_date.getMonth();
      const year = start_date.getFullYear();

      const start_time: Date = this.form.get('start_time').value;
      start_time.setDate(day);
      start_time.setMonth(month);
      start_time.setFullYear(year);

      const end_time: Date = this.form.get('end_time').value;
      end_time.setDate(day);
      end_time.setMonth(month);
      end_time.setFullYear(year);

      payload.start_date = this.localeService.dateWithTimeZone(start_time);
      payload.end_date = this.localeService.dateWithTimeZone(end_time);
    }

    return payload;
  }

  checkIfChanged(key, field) {
    if(!key || key?.field_value?.value === undefined) {
      return true;
    }
    let value = key?.field_value?.value;
    value = this.translate.instant('shared.' + value.replace("::", "."));
    return (this.form.get(field).value != value);
  }
}
