import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-feedback-field',
  templateUrl: './feedback-field.component.html'
})
export class FeedbackFieldComponent implements OnInit {
  @Input() field: UntypedFormControl;
  @Input() extraClass: string;
  @Input() meta: any;

  ngOnInit(): void {
  }
}
