import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserTask } from '../models/user-task';
import { UserTaskBasic } from '../models/user-task-basic';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserTaskService extends ApiService {
  private readonly platformUrl: string;

  public static OVERVIEW_MIN_PRIORITY: number = 0;
  public static OVERVIEW_MAX_PRIORITY: number = 89;

  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  getUserTasks(
    hcpUid: string,
    filters: any = {},
    page = 0,
    size = 10,
    sort: string[] = []): Observable<{
      tasks: Array<UserTaskBasic>,
      pagination: any
    }> {
    return new Observable(observer => {
      const paramBits = [
        `page=${page}`,
        `size=${size}`
      ];

      if (sort.length) {
        sort.forEach(s => {
          paramBits.push(`sort=${s}`);
        });
      }

      /*
      ** Filter - Patient UID - Start
      */
      if (filters.hasOwnProperty('patient_uid') && filters.patient_uid !== null) {
        paramBits.push(`patient_uid=${filters.patient_uid}`);
      }
      /*
      ** Filter - Patient UID - End
      */


      /*
      ** Filter - Care Module Uid(s) - Start
      */
      let care_module_uid = filters.care_module_uid || filters.care_module_uids;
      if (care_module_uid) {
        if (Array.isArray(care_module_uid)) {
          care_module_uid.forEach(cmu => {
            paramBits.push(`care_module_uid=${cmu}`);
          });
        } else {
          paramBits.push(`care_module_uid=${care_module_uid}`);
        }
      }
      /*
      ** Filter - Care Module Uid(s) - End
      */


      /*
      ** Filter - Min Priority - Start
      */
      if (filters.hasOwnProperty('min_priority')) {
        paramBits.push(`min_priority=${filters.min_priority}`);
      }
      /*
      ** Filter - Min Priority - End
      */


      /*
      ** Filter - Max Priority - Start
      */
      if (filters.hasOwnProperty('max_priority')) {
        paramBits.push(`max_priority=${filters.max_priority}`);
      }
      /*
      ** Filter - Max Priority - End
      */


      /*
      ** Filter - Goal ID - Start
      */
      if (filters.hasOwnProperty('goal_id')) {
        paramBits.push(`goal_id=${filters.goal_id}`);
      }
      /*
      ** Filter - Goal ID - End
      */


      /*
      ** Filter - Patient Pathway ID - Start
      */
      if (filters.hasOwnProperty('patient_pathway_id')) {
        paramBits.push(`patient_pathway_id=${filters.patient_pathway_id}`);
      }
      /*
      ** Filter - Patient Pathway ID - End
      */


      const paramsString = paramBits.join('&');
      let url = `${this.platformUrl}/hcps/${hcpUid}/user-tasks?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const tasks = this.mapBasicUserTasks(result['items']);

        observer.next({ 'tasks': tasks, 'pagination': result['pagination'] });
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getHospitalUserTasks(
    hospitalUid: string,
    filters: any = {},
    page = 0,
    size = 10,
    sort: string): Observable<{
      tasks: Array<UserTaskBasic>,
      pagination: any
    }> {
    return new Observable(observer => {
      const paramBits = [
        `page=${page}`,
        `size=${size}`
      ];

      paramBits.push(`sort=${sort}`);

      if (filters.hasOwnProperty('hcp_uid') && filters.hcp_uid) {
        paramBits.push(`hcp_uid=${filters.hcp_uid}`);
      }

      if (filters.hasOwnProperty('patient_uid') && filters.patient_uid) {
        paramBits.push(`patient_uid=${filters.patient_uid}`);
      }

      if (filters.hasOwnProperty('min_priority')) {
        paramBits.push(`min_priority=${filters.min_priority}`);
      }

      if (filters.hasOwnProperty('max_priority')) {
        paramBits.push(`max_priority=${filters.max_priority}`);
      }

      paramBits.push(`candidate_user_filter_type=INCLUDE`);

      const paramsString = paramBits.join('&');
      let url = `${this.platformUrl}/hospitals/${hospitalUid}/user-tasks?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {
        const tasks = this.mapBasicUserTasks(result['items']);

        observer.next({ 'tasks': tasks, 'pagination': result['pagination'] });
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getUserTask(id, hcp_uid): Observable<UserTask> {
    const url = `${this.platformUrl}/hcps/${hcp_uid}/user-tasks/${id}`;
    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, err => {
        observer.error(err);
        observer.complete();
      });
    })
  }

  getHospitalUserTask(id, hospital_uid): Observable<UserTask> {
    const url = `${this.platformUrl}/hospitals/${hospital_uid}/user-tasks/${id}`;
    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, err => {
        observer.error(err);
        observer.complete();
      });
    })
  }

  evaluateHcpUserTask(id: string, hcp_uid: string, data: any): Observable<any | null> {
    return this.evaluateUserTask(`${this.platformUrl}/hcps/${hcp_uid}/user-tasks/${id}/evaluate-form`, data);
  }

  evaluateHospitalUserTask(id: string, hospital_uid: string, data: any): Observable<any | null> {
    return this.evaluateUserTask(`${this.platformUrl}/hospitals/${hospital_uid}/user-tasks/${id}/evaluate-form`, data);
  }

  protected evaluateUserTask(url: string, data: any): Observable<any | null> {
    return new Observable(observer => {
      this.authenticatedPost(url, { fields: data }).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  completeUserTask(id, hcp_uid, data): Observable<UserTask> {
    const url = `${this.platformUrl}/hcps/${hcp_uid}/user-tasks/${id}`;
    return new Observable(observer => {
      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, error => {
        observer.error(error);
      });
    })
  }

  saveUserTask(id, hcp_uid, data): Observable<UserTask> {
    const url = `${this.platformUrl}/hcps/${hcp_uid}/user-tasks/${id}`;

    return new Observable(observer => {
      this.authenticatedPatch(url, { variables: this.mapPayload(data) }).subscribe(result => {
        observer.next(this.mapUserTask(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  assignUserTasks(hospitalUid: string, hcpUid: string, userTaskUids: string[]) {
    const url = `${this.platformUrl}/hospitals/${hospitalUid}/user-tasks/bulk-assign`;
    const data = {
      'assign_to_hcp_uid': hcpUid,
      'user_task_ids': userTaskUids
    };

    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  mapBasicUserTasks(result: Array<any>): Array<UserTaskBasic> {
    return result.map(item => { return this.mapBasicUserTask(item) })
  }

  mapBasicUserTask(item: any): UserTaskBasic {
    return new UserTaskBasic(item);
  }

  mapUserTask(item): UserTask {
    return new UserTask(item);
  }

  mapPayload(data: any): any {
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }
    }

    return data;
  }
}
