<form [formGroup]="form" id="form" class="form-width-limit" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">
  <!-- Profile picture -->
  <label class="small">{{ 'form.labels.profile_picture' | translate }}</label>

  <div class="control-profile-picture mb-3">
    <app-avatar-input formControlName="profile_picture"></app-avatar-input>
  </div>

  <!-- Gender -->
  <div class="pb-2">
    <label class="small d-block required" id="label_">{{ 'form.labels.gender' | translate }}</label>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radio_details_form_sex_male" formControlName="gender" value="MALE">
      <label class="custom-control-label" for="radio_details_form_sex_male">{{ 'form.labels.male' | translate }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radio_details_form_sex_female" formControlName="gender" value="FEMALE">
      <label class="custom-control-label" for="radio_details_form_sex_female">{{ 'form.labels.female' | translate }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radio_details_form_sex_other" formControlName="gender" value="UNKNOWN">
      <label class="custom-control-label" for="radio_details_form_sex_other">{{ 'form.labels.other' | translate }}</label>
    </div>
  </div>

  <!-- First name -->
  <div class="form-group styled floating-label">
    <input type="text" id="input_details_form_first_name" class="form-control w-100" [placeholder]="('form.labels.first_name' | translate)" formControlName="first_name">
    <label for="input_details_form_first_name" class="required">{{ 'form.labels.first_name' | translate }}</label>
  </div>

  <!-- Last name -->
  <div class="form-group styled floating-label">
    <input type="text" id="input_details_form_last_name" class="form-control w-100" [placeholder]="'.form.labels.last_name' | translate" formControlName="last_name">
    <label for="input_details_form_last_name" class="required">{{ 'form.labels.last_name' | translate }}</label>
  </div>

  <!-- Language -->
  <div class="form-group required">
    <app-select
      id="select_details_form_language"
      [placeholder]="('form.labels.language' | translate)"
      [clearable]="false"
      [hideSelected]="false"
      [multiple]="false"
      [items]="languageOptions"
      [bindValue]="'value'"

      [autoSortOnLabel]="true"
      [selectedItemsOnTop]="false"

      formControlName="language"

      [asFilter]="false"
      [showCheckboxes]="false"
    ></app-select>
  </div>

  <!-- Job title -->
  <div class="form-group styled floating-label">
    <input [placeholder]="'.form.labels.job_title' | translate" class="form-control w-100" formControlName="job_title" id="input_details_form_job_title" type="text">
    <label for="input_details_form_job_title" class="required">{{ 'form.labels.job_title' | translate }}</label>
  </div>

  <!-- Location -->
  <div class="form-group styled floating-label">
    <input type="text" id="input_details_form_location" class="form-control w-100" [placeholder]="'.form.labels.location' | translate" formControlName="location">
    <label for="input_details_form_location">{{ 'form.labels.location' | translate }}</label>
  </div>

  <!-- Location URL -->
  <div class="form-group styled floating-label">
    <input type="text" id="input_details_form_location_url" class="form-control w-100" [placeholder]="'.form.labels.location_url' | translate" formControlName="location_url">
    <label for="input_details_form_location_url">{{ 'form.labels.location_url' | translate }}</label>
  </div>

  <hr/>

  <!-- Email -->
  <div class="mb-2">
    <div class="form-group styled floating-label mb-1">
      <input type="email" id="input_details_form_email" class="form-control w-100" [placeholder]="('form.labels.email_address' | translate)" formControlName="email">
      <label for="input_details_form_email">{{ 'form.labels.email_address' | translate }}</label>
      <p id="feedback_details_form_email_invalid" class="validation-feedback" *ngIf="form?.controls['email']?.errors?.email">{{ 'form.feedback.email_invalid' | translate }}</p>
    </div>
  </div>

  <!-- Country code & phone -->
  <div class="mb-2">
    <div class="row">
      <div class="col-5" [ngClass]="{'order-last': languageService.isHebrew}">
        <div class="form-group required">
          <app-select
            id="select_details_form_country_code"
            [placeholder]="('form.labels.country_code' | translate)"
            [clearable]="true"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="dialCodeOptions"
            [bindValue]="'value'"

            formControlName="country_code"

            [asFilter]="false"
            [showCheckboxes]="false"
          ></app-select>
        </div>
      </div>
      <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
        <div class="form-group styled floating-label">
          <input type="tel" id="input_details_form_phone_number" class="form-control w-100" [placeholder]="('form.labels.phone_number' | translate)" formControlName="phone">
          <label for="input_details_form_phone_number" class="required">{{ 'form.labels.phone_number' | translate }}</label>
          <p id="feedback_details_form_phone_is_wrong" class="validation-feedback" *ngIf="form?.controls['phone']?.errors?.phone_is_wrong">{{ 'form.feedback.phone_wrong_format' | translate }}</p>
          <p id="feedback_details_form_phone_required" class="validation-feedback" *ngIf="form?.controls['phone']?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>
        </div>
      </div>
    </div>

    <!--
    <div class="custom-control custom-checkbox custom-control-inline mb-1">
      <input type="checkbox" class="custom-control-input" id="checkbox_details_form_alerts_phone" formControlName="notifications_method_sms" value="TEXT">
      <label class="custom-control-label" for="checkbox_details_form_alerts_phone">{{ 'form.labels.alerts_phone' | translate }}</label>
    </div>
    -->
  </div>

  <!-- Contact channel -->
  
  <!--  -->
  <!-- HIDDEN BY D-NONE - AS PER REQUEST OF: AGIK-5007 -->
  <!--  -->
  <div class="position-relative mb-2 d-none" *ngIf="currentProfile?.contact_consented">
    <label class="small d-block">{{ 'components.onboarding_details_form.preferred_contact_method' | translate }}</label>

    <div class="custom-control custom-radio custom-control-inline mb-1">
      <input type="radio" class="custom-control-input" id="radio_details_form_contact_email" formControlName="contact_channel" value="EMAIL">
      <label class="custom-control-label" for="radio_details_form_contact_email">{{ 'form.labels.email' | translate }}</label>
    </div>


    <div class="custom-control custom-radio custom-control-inline mb-1" *ngIf="form?.controls['phone']?.value">
      <input type="radio" class="custom-control-input" id="radio_details_form_contact_phone" formControlName="contact_channel" value="PHONE">
      <label class="custom-control-label" for="radio_details_form_contact_phone">{{ 'form.labels.phone' | translate }}</label>
    </div>

    <div class="custom-control custom-radio custom-control-inline mb-1" *ngIf="!form?.controls['phone']?.value">
      <input type="radio" class="custom-control-input" id="radio_details_form_contact_phone_disabled" disabled="true">
      <label class="custom-control-label" for="radio_details_form_contact_phone_disabled">{{ 'form.labels.phone' | translate }}</label>
    </div>

  </div>

  <hr/>
 <p class="small text-muted">{{ 'components.onboarding_details_form.notifications' | translate }}</p>

 <div class="form-group custom-control custom-checkbox custom-control-inline mb-1">
  <input type="checkbox" class="custom-control-input" id="checkbox_details_form_alerts_email" formControlName="notifications_method_email" value="EMAIL">
  <label class="custom-control-label" for="checkbox_details_form_alerts_email">{{ 'components.onboarding_details_form.email_notifications_channel' | translate }}</label>
</div>

<p class="text-muted">{{ 'components.onboarding_details_form.push_notifications_message' | translate }}</p>
</form>
