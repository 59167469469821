import { AnalyticsPeriod } from "../enums/analytics-period";
import { GeneralService } from "../services/general.service";

export class Period {
  public period_type: AnalyticsPeriod;
  public week: number;
  public week_end_date: string;
  public week_start_date: string;
  public year: number;
  public month: number;
  public month_start_date: string;

  constructor(item) {
    if (item) {
      this.period_type = item.period_type;
      this.week = item.week;
      this.week_end_date = item.week_end_date;
      this.week_start_date = item.week_start_date;
      this.year = item.year;
      this.month = item.month;
      this.month_start_date = `${this.year}-${this.month}-01`;
    }
  }
}

export class OnboardingChartPeriod {
  public consent_pending: number;
  public hospital_approval_pending: number;
  public successful: number;
  public unsuccessful: number;
  public active: number;
  public period: Period;

  constructor(item) {
    this.consent_pending = item.consent_pending;
    this.hospital_approval_pending = item.hospital_approval_pending;
    this.successful = item.successful;
    this.unsuccessful = item.unsuccessful;
    this.active = item.active;
    this.period = new Period(item.period);
  }
}
