import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LanguageService } from './language.service';
import { Country } from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends ApiService {
  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  getCountriesFromCms(): Observable<Country[]> {
    const url = environment.cmsUrl + '/v3/content_types/countries/entries?asc=title';
    const locale = this.languageService.getCurrentLanguage().locale;

    return new Observable<Country[]>(observer => {
      this.cmsGet(url, locale, null, null, true).subscribe(result => {
        if (result['entries']) {
          observer.next(result['entries'].map(country => new Country(country)));
          observer.complete();
        }
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}

