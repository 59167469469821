import {Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StartNewConversationModalComponent } from '../../../modals/start-new-conversation-modal/start-new-conversation-modal.component';
import {Conversation} from '../../../models/conversation';
import {DateFormat} from './../../../models/date-format';
import {GeneralService} from '../../../services/general.service';
import {UserService} from '../../../services/user.service';
import {ConversationService} from '../../../services/conversation.service';
import { PatientService } from '../../../services/patient.service';
import { HealthCareProfessional } from '../../../models/health-care-professional';
import { LocaleService } from '../../../services/locale.service';
import { HcpService } from '../../../services/hcp.service';
import { AttentionCenterService } from '../../../services/attention-center.service';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../services/modal.service';


@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit, OnDestroy {
  @ViewChild('newMsg') newMsgField: ElementRef;
  @ViewChild( "end" )
  public viewportRef!: ElementRef;

  public currentHcp: HealthCareProfessional;

  public loadingInitialConversations: boolean;
  public currentConversation: Conversation;
  public conversations: Array<Conversation> = [];
  public dateFormat: DateFormat;
  public time_24_hours = false;
  public timeZone: string;
  public isLoading:Boolean = false;
  public visibleParticipantsNum: number = 3;

  public conversationsPagination = {};
  public itemsPagination = {};
  public selectConversationAfterLoad: Conversation;

  public loading:Number = null;
  public newMsgInput;
  public newMsgMaxLength = 1000;

  public conversationsFilter: any;
  public currentConversationsType: string;

  public onNewConversationMessagesUnreadSubscription:Subscription;

  constructor(
    @Inject(DOCUMENT) public document: any,
    public pageScrollService: PageScrollService,
    public modalService: ModalService,
    public conversationService: ConversationService,
    public generalService: GeneralService,
    public userService: UserService,
    public hcpService: HcpService,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public patientService: PatientService,
    public localeService: LocaleService,
    public attentionCenterService: AttentionCenterService
  ) {
  }

  ngOnInit(): void {
    this.currentHcp = this.hcpService.getCurrentStoredHcp();

    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
    this.time_24_hours = this.localeService.getLocalePreferences().locale.time_24_hours;
    this.timeZone = this.localeService.getLocalePreferences().locale.time_zone;

    this.conversationsFilter = {};
    this.loadingInitialConversations = true;
    this.getConversations(0);

    this.onNewConversationMessagesUnreadSubscription = this.attentionCenterService.onNewConversationMessagesUnread.subscribe(() => {
      this.onNewConversationMessagesUnread();
    })
  }

  ngOnDestroy() {
    this.onNewConversationMessagesUnreadSubscription?.unsubscribe();
  }

  getConversations(page) {
    this.isLoading = true;
    this.conversationService.getConversations(this.conversationsFilter, page).subscribe(result => {
      this.isLoading = false;
      this.loadingInitialConversations = false;

      if (page === 0) {
        this.conversations = result['conversations'];
      } else {
        this.conversations = this.conversations.concat(result['conversations']);
      }

      this.conversationsPagination = result['pagination'];

      if(this.currentConversation) {
        const storeCurrentConversation = Object.assign({}, this.currentConversation) as Conversation;
        this.conversations.forEach(_convo => {
          if(this.currentConversation.uid  === _convo.uid ) {
            _convo.items = storeCurrentConversation.items;
            this.currentConversation = _convo;
          }
        });
      }
    });
  }

  onNewConversationMessagesUnread() {
    this.getConversations(0);
  }

  actionFilterOnType(type?: string) {
    if (type === this.currentConversationsType) {
      return;
    } else {
      this.currentConversationsType = type;
    }

    if (type && type === 'patient') {
      this.conversationsFilter.conversation_type = ['PATIENT_CONVERSATION'];
    } else if (type && type === 'internal') {
      this.conversationsFilter.conversation_type = ['HCP_CONVERSATION'];
    } else {
      delete this.conversationsFilter.conversation_type;
    }

    this.conversations = undefined;
    this.conversationsPagination = undefined;
    this.getConversations(0);
  }

  selectConversation(conversation: Conversation) {
    if(conversation !== this.currentConversation) {
      conversation.items = [];
      this.currentConversation = conversation;
    }
  }

  showNewConversationModal() {
    const modal = this.modalService.showWithInterceptor(StartNewConversationModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-lg'
      })
    );

    modal.content.onCreate.subscribe(newConversation => {
      this.conversations.unshift(newConversation);
      this.selectConversation(newConversation);
      this.getConversations(0);
      this.scrollToConversationsListTop();
    });
  }

  onScroll(event) {
    const currentPage = this.conversationsPagination['current_page'];
    const totalPages = this.conversationsPagination['total_pages'];

    if ((currentPage < (totalPages - 1 ))) {
      const newPage = currentPage + 1;
      this.getConversations(newPage);
    }
  }

  scrollToConversationsListTop() {
    let itemsContainers:HTMLCollection = document.getElementsByClassName('items');
    let itemsContainer:Element = itemsContainers[0];

    if(itemsContainer?.scroll) {
      itemsContainer.scroll(0, 0);
    }
  }
}
