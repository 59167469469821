<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/settings']" class="text-secondary font-weight-bold d-flex align-items-center" id="settings-personal-back">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ hcp?.getFullName() }}</h1>
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">

    <div id="zone_your_information">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.personal_info.personal_details' | translate }}</h2>
        </div>
      </div>

      <div class="action-item-block mt-2 mb-5">
        <div class="block-wrapper p-3 w-100" *ngIf="!hcp">
          <app-page-loader [padding]="'30px 0'"></app-page-loader>
        </div>

        <div class="block-wrapper p-3 w-100" *ngIf="hcp">
          <div class="row align-items-center" *ngIf="hcp">
            <div class="col-auto m-end-4" style="width: 160px;">
              <div class="profile-picture avatar" [style.backgroundImage]="'url(' + (hcp?.profile_picture?.url | secure | async) + ')'" [ngClass]="{ 'empty': !hcp.profile_picture }"></div>
            </div>

            <div class="col">
              <div class="row">
                <div class="col-4">
                  <div class="label-value-pairs">
                    <div id="gender" class="pair">
                      <label class="tiny m-0">{{ 'form.labels.gender' | translate }}</label>
                      <p *ngIf="hcp?.gender==='MALE'">{{ 'form.labels.male' | translate }}</p>
                      <p *ngIf="hcp?.gender==='FEMALE'">{{ 'form.labels.female' | translate }}</p>
                      <p *ngIf="hcp?.gender==='UNKNOWN'">{{ 'form.labels.other' | translate }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="label-value-pairs">
                    <div class="pair">
                      <label class="tiny m-0">{{ 'form.labels.first_name' | translate }}</label>
                      <p id="first-name">{{ hcp.first_name }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="label-value-pairs">
                    <div class="pair">
                      <label class="tiny m-0">{{ 'form.labels.last_name' | translate }}</label>
                      <p id="last-name">{{ hcp.last_name }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-4 mt-2">
                  <div class="label-value-pairs">
                    <div class="pair">
                      <label class="tiny m-0">{{ 'form.labels.language' | translate }}</label>
                      <p id="language">{{ language?.translationKey | translate }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-4 mt-2">
                  <div class="label-value-pairs">
                    <div id="function-name" class="pair">
                      <label class="tiny m-0">{{ 'form.labels.job_title' | translate }}</label>
                      <p *ngIf="hcp?.job_title">{{ hcp?.job_title }}</p>
                      <p *ngIf="!hcp?.job_title">-</p>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-2"  *ngIf="hcp?.location?.url || hcp?.location?.name">
                  <div class="label-value-pairs">
                    <div id="location" class="pair">
                      <label class="tiny m-0">{{ 'form.labels.location' | translate }}</label>
                      <p>
                        <a *ngIf="hcp?.location?.url" [href]="hcp?.location?.url" class="text-secondary text-icon-link" id="settings-personal-hcp-location" target="_blank">
                          <span class="label">{{ hcp?.location?.name || hcp?.location?.url }}</span>
                          <span class="icon-end rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/external.svg'"></span>
                        </a>

                        <span *ngIf="!hcp?.location?.url">{{ hcp?.location?.name }}</span>
                      </p>
                      <p *ngIf="!hcp?.location">-</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a href="" class="block-action m-start-auto" id="settings-personal-show-personal-details" (click)="showEditPersonalDetailsModal($event)" *ngIf="hcp">
          <span>{{ 'action.edit' | translate }}</span>
          <span class="icon m-start-1" [inlineSVG]="'/assets/svg/edit.svg'"></span>
        </a>
      </div>
    </div>

    <div id="zone_contact_details">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.personal_info.contact_details' | translate }}</h2>
        </div>
      </div>

      <div class="mt-2 mb-5">
        <div id="personal-info-email" class="single-item-block mb-2">
          <div class="block-wrapper p-start-3 p-end-1">
            <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/mail.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label">{{ 'form.labels.email' | translate }}</p>
            <p class="value" id="EmailValue">{{ profile?.email }}</p>
          </div>

          <a href="" class="block-action m-start-auto" id="settings-personal-info-edit-email" (click)="showEditEmailModal($event)" *ngIf="hcp">
            <span>{{ 'action.edit' | translate }}</span>
            <span class="icon m-start-1 " [inlineSVG]="'/assets/svg/edit.svg'"></span>
          </a>
        </div>

        <div id="personal-info-phone" class="single-item-block">
          <div class="block-wrapper p-start-3 p-end-1">
            <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/phone.svg'"></span>
          </div>
          <div class="block-wrapper">
            <p class="label">{{ 'form.labels.phone' | translate }}</p>
            <p class="value" id="PhoneValue">{{ dialCode?.label }} {{ profile?.mobile_number?.number }}</p>
          </div>

          <a href="" class="block-action m-start-auto" id="settings-personal-show-edit-phone-modal" (click)="showEditPhoneModal($event)" *ngIf="hcp">
            <span>{{ 'action.edit' | translate }}</span>
            <span class="icon m-start-1" [inlineSVG]="'/assets/svg/edit.svg'"></span>
          </a>
        </div>
      </div>
    </div>

    <!--  -->
  <!-- HIDDEN BY D-NONE - AS PER REQUEST OF: AGIK-5009 -->
  <!--  -->
    <div id="zone_contact" class="d-none">
      <div id="personal-settings-contact-consented" *ngIf="profile?.contact_consented">
        <p>{{ 'pages.default.settings.personal_info.contact_info' | translate }}</p>

        <div class="mt-3 mb-5">
          <form [formGroup]="contactForm" id="contactForm" class="form-width-limit">
            <div class="custom-control custom-radio custom-control-inline mb-1">
              <input type="radio" class="custom-control-input" id="radioContactEmail" value="EMAIL"
                     formControlName="contact_channel" (change)="handleContactFormSubmission()">
              <label class="custom-control-label" for="radioContactEmail">{{ 'form.labels.email' | translate }}</label>
            </div>


            <div class="custom-control custom-radio custom-control-inline mb-3" *ngIf="profile?.mobile_number?.number">
              <input type="radio" class="custom-control-input" id="radioContactPhone" value="PHONE"
                     formControlName="contact_channel" (change)="handleContactFormSubmission()">
              <label class="custom-control-label" for="radioContactPhone">{{ 'form.labels.phone' | translate }}</label>
            </div>
          </form>

          <div class="custom-control custom-radio custom-control-inline mb-3" *ngIf="!profile?.mobile_number?.number">
            <input type="radio" class="custom-control-input" id="radioContactPhoneDisabled" disabled="disabled">
            <label class="custom-control-label" for="radioContactPhoneDisabled">{{ 'form.labels.phone' | translate }}
              <p class="small text-muted">{{ 'pages.default.settings.preferences.please_update_with_phone' | translate }}</p>
            </label>
          </div>

          <div class="bg-white p-3 mt-4">
            <h3 class="mb-1">
              <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
              <span class="align-middle">{{ 'pages.default.settings.preferences.prefer_no_contact' | translate }}</span>
            </h3>

            <p class="m-0">{{ 'pages.default.settings.preferences.prefer_no_contact_info1' | translate }}</p>
            <p class="m-0">
              <a routerLink="/settings/privacy" class="font-weight-bold text-secondary" id="settings-personal-prefer-no-contact">
                {{ 'pages.default.settings.preferences.prefer_no_contact_info2' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div id="personal-settings-not-contact-consented" class="mt-3 mb-5" *ngIf="!profile?.contact_consented">
        <div class="bg-white p-3 mt-4">
          <h3 class="mb-1">
            <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
            <span class="align-middle">{{ 'pages.default.settings.preferences.no_consent_given' | translate }}</span>
          </h3>

          <p class="m-0">{{ 'pages.default.settings.preferences.no_consent_given_text' | translate }}</p>
          <p class="m-0">
            <a routerLink="/settings/privacy" class="font-weight-bold text-secondary" id="settings-personal-contact-consent" >
              {{ 'pages.default.settings.preferences.prefer_no_contact_info2' | translate }}
            </a>
          </p>
        </div>
      </div>
    </div>

    <div id="zone_notifications">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.personal.notifications' | translate }}</h2>
        </div>
      </div>

      <div class="mt-2">
        <form [formGroup]="notificationsForm" id="notificationsForm" class="form-width-limit">
          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="checkboxNotificationsEmail" value="EMAIL"
                  formControlName="email_notifications" (change)="onHandleNotificationsFormSubmission()">
            <label class="custom-control-label" for="checkboxNotificationsEmail">{{ 'form.labels.email' | translate }}</label>
          </div>
          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="checkboxNotificationsPhone" value="PUSH"
                  formControlName="phone_notifications">
            <label class="custom-control-label" for="checkboxNotificationsPhone">{{ 'form.labels.push' | translate }}</label>
          </div>

          <p class="text-muted">{{'pages.default.settings.personal.modified_phone' | translate }}</p>
        </form>
      </div>
    </div>
  </div>
</div>
