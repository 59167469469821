import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Image } from '../../models/image';
import { SecurePipe } from '../../pipes/secure.pipe';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @HostBinding('class.avatar') avatarClass: boolean = true;
  @HostBinding('class.empty') emptyClass: boolean = false;

  @HostBinding('style.background-image') bgImage: any;

  constructor(
    public securePipe: SecurePipe,
    public sanitizer: DomSanitizer
  ) { }

  @Input('profilePicture')
  public set profilePicture(image: Image) {

    if(image) {
      this._profilePicture = image;
      this.emptyClass = false;

      this.securePipe.transform(this._profilePicture.url).subscribe(resp => {
        this.bgImage = this.sanitizer.bypassSecurityTrustStyle(`url('${resp}')`);
      });
    } else {
      this.emptyClass = true;
      this.bgImage = undefined;
    }
  }
  public get profilePicture(): Image {
    return this._profilePicture;
  }

  private _profilePicture: Image;


  ngOnInit(): void {
  }

}
