import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CareModule } from '../../../../models/care-module';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';

@Component({
  selector: 'app-pathway-step',
  templateUrl: './pathway-step.component.html'
})
export class PathwayStepComponent implements OnInit {
  public isLoading: boolean = false;
  public hospitalUid: string;
  public _selectedCareModule: CareModule;

  @Output() careModuleSelected = new EventEmitter();
  @Output() careModulesLoaded = new EventEmitter();

  @Input() careModules: CareModule[];
  @Output() careModulesChange = new EventEmitter<CareModule[]>();

  @Input()
  public get selectedCareModule(): CareModule {
    return this._selectedCareModule;
  }

  public set selectedCareModule(careModule: CareModule) {
    if (this._selectedCareModule !== careModule) {
      this._selectedCareModule = careModule;
    }
  }

  @Input() selectedCareModuleWasUpdated: boolean;
  @Output() selectedCareModuleWasUpdatedChange = new EventEmitter<boolean>();

  @Output() selectedCareModuleChange = new EventEmitter<CareModule>();

  constructor(
    public hospitalService: HospitalService,
    public hcpService: HcpService
  ) { }

  ngOnInit(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.getCareModules();
  }

  getCareModules() {
    if (this.careModules) return;

    this.isLoading = true;

    this.hospitalService.getCareModules(this.hospitalUid).subscribe((careModules: CareModule[]) => {
      this.careModules = careModules;
      this.careModulesChange.emit(this.careModules);

      if (this.careModules.length === 1) this.selectCareModule(careModules[0]);

      this.careModulesLoaded.emit();
      this.isLoading = false;
    });
  }

  selectCareModule(careModule: CareModule): void {
    if (this.selectedCareModule?.uid !== careModule.uid) {
      this.selectedCareModule = careModule;

      if (this.selectedCareModuleWasUpdated) {
        this.selectedCareModuleWasUpdatedChange.emit(false);
      }
    }

    this.selectedCareModuleChange.emit(this.selectedCareModule);

    this.careModuleSelected.emit();
  }

  showCareModuleBtns(): boolean {
    if (this.careModules && this.careModules.length <= 5) return true;

    return false;
  }

}
