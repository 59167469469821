import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpcenterSection } from '../../../../models/helpcenter-section';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-help-center-faqs-overview',
  templateUrl: './help-center-faqs-overview.component.html',
  styleUrls: ['./help-center-faqs-overview.component.scss']
})
export class HelpCenterFaqsOverviewComponent implements OnInit {
  public helpcenterSection: HelpcenterSection;
  public faqCategories = [];

  constructor(
    public dataService: DataService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.helpcenterSection = this.dataService.get(DataService.HelpcenterSectionSelected );
    this.faqCategories = this.helpcenterSection.faqs;
  }

  goToFaq(event, faq, category): boolean {
    event.preventDefault();
    this.dataService.set(DataService.HelpcenterFaqSelected, faq);
    this.dataService.set(DataService.HelpcenterFaqCategorySelected, category);
    this.router.navigate(['/help-center', 'faqs', faq.uid], {
      queryParams: {
        'back-to-url': this.router.url
      }}
    );
    return false;
  }
}
