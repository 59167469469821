<div class="layout-header-pusher header-patient header-shrunk" [ngClass]="{'header-shrunk': !showFullHeader}"></div>

<div class="layout-header header-patient header-shrunk" [ngClass]="{'header-shrunk': !showFullHeader}">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="!backButtonData && patient"></app-breadcrumb>

      <a class="" id="patient-detail-action-back-to-specific-url" [routerLink]="backButtonData?.url" class="text-secondary font-weight-bold" *ngIf="backButtonData">
        <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
        <span class="align-middle">{{ backButtonData?.titleKey | translate }}</span>
      </a>
    </div>

    <div class="container">

      <div class="row">
        <div class="col">
          <div class="patient-info-block">
            <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

            <div class="info-wrapper">
              <div class="mb-2">
                <h1 id="patient-detail-name" class="text-truncate mb-0 pb-0">{{ patient?.getFullName() || '...' }}</h1>
                <span class="title-subline" *ngIf="patient?.preferred_name" id="patient-detail-preferred-name">({{ 'pages.default.patient_detail.prefers' | translate }} {{ patient?.preferred_name }})</span>
              </div>

              <div class="row">
                <div class="col-4" id="patient-detail-birthday">
                  <p class="text-truncate">
                    <span [inlineSVG]="'/assets/svg/birthday-cake.svg'"></span>
                    <span>{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format  }} ({{ patient?.age() | translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</span>
                  </p>
                </div>

                <div class="col-4" id="patient-detail-id">
                  <p class="text-truncate">
                    <span [inlineSVG]="'/assets/svg/id.svg'"></span>
                    <span>{{ (patient?.electronic_medical_record | translateNumber) || ('pages.default.patient_detail.not_available' | translate) }}</span>
                  </p>
                </div>


                <div class="col-4" *ngIf="showFullHeader" id="patient-detail-language">
                  <p class="text-truncate">
                    <span [inlineSVG]="'/assets/svg/planet.svg'"></span>
                    <span>{{ getLanguage()?.translationKey | translate }}</span>
                  </p>
                </div>

                <div class="col-4" *ngIf="showFullHeader" id="patient-detail-phone">
                  <p class="text-truncate">
                    <span [inlineSVG]="'/assets/svg/phone.svg'"></span>
                    <span *ngIf="patient?.phone_number" dir="ltr">{{ generalService.getDialCodeForCountry(patient?.phone_number?.code)?.label}} {{ patient?.phone_number?.number }}</span>
                    <span *ngIf="!patient?.phone_number">{{ 'pages.default.patient_detail.not_available' | translate }}</span>
                  </p>
                </div>

                <div class="col-4" *ngIf="showFullHeader && patient?.email" id="patient-detail-mail">
                    <a href="mailto:{{ patient?.email }}" id="patient-detail-mailto" target="_blank" class="btn btn-link p-start-0 text-truncate d-flex align-items-centre">
                      <span [inlineSVG]="'/assets/svg/mail.svg'" class="p-end-1"></span>
                      <span>{{ patient?.email }}</span>
                    </a>
                </div>

                <div class="col-4" *ngIf="showFullHeader" id="patient-detail-gender">
                  <p class="text-truncate">
                    <span [inlineSVG]="'/assets/svg/gender.svg'"></span>
                    <span *ngIf="patient?.gender==='MALE'">{{ 'form.labels.male' | translate }}</span>
                    <span *ngIf="patient?.gender==='FEMALE'">{{ 'form.labels.female' | translate }}</span>
                    <span *ngIf="patient?.gender==='UNKNOWN'">{{ 'form.labels.other' | translate }}</span>
                  </p>
                </div>

                <div [ngClass]="showFullHeader ? 'col-12' : 'col-4'">

                    <button (click)="actionToggleHeader($event)" class="btn btn-link d-flex align-items-centre p-0" id="action_patientHeaderToggle">
                      <span *ngIf="!showFullHeader" class="align-middle" id="label_header_show_more_details" >{{ 'pages.default.patient_detail.header_show_more_details' | translate }}</span>
                      <span *ngIf="!showFullHeader" [inlineSVG]="'/assets/svg/chevron-down.svg'" class="m-start-1 align-middle"></span>

                      <span *ngIf="showFullHeader" class="align-middle" id="label_header_show_less_details">{{ 'pages.default.patient_detail.header_show_less_details' | translate }}</span>
                      <span *ngIf="showFullHeader" [inlineSVG]="'/assets/svg/chevron-up.svg'" class="m-start-1 align-middle"></span>
                    </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">

          <button id="button_patient_detail_edit_details" class="btn btn-secondary btn-sm" (click)="editPatient()"  *ngIf="hasCcRole">{{'pages.default.patient_detail.edit_details' | translate}}</button>

          <div class="btn-group m-start-1" dropdown placement="bottom right" *ngIf="hasCcRole && pathwaysAvailable && scopeService.doesCurrentPatientHasClicinalCareModule()">
            <button id="button_patient_detail_settings" dropdownToggle type="button" class="dropdown-toggle btn btn-settings btn-sm" aria-controls="dropdown_patient_detail_settings_dropdown">
              <span [inlineSVG]="'/assets/svg/more.svg'"></span>
            </button>
            <ul id="dropdown_patient_detail_settings_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_patient_detail_settings">
              <li role="menuitem">
                <a id="patient-detial-edit-mdt" class="dropdown-item" href="" (click)="editMdt($event)">{{'pages.default.patient_detail.edit_mdt' | translate }}</a>
              </li>

              <li role="menuitem">
                <a id="patient-detail-add-pathway" class="dropdown-item" href="" (click)="addPathway($event)">{{'pages.default.patient_detail.add_pathway' | translate }}</a>
              </li>

              <li role="menuitem">
                <a id="patient-detail-stop-pathway" class="dropdown-item" href="" (click)="stopPathway($event)">{{'pages.default.patient_detail.stop_pathway' | translate }}</a>
              </li>

            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="action-tabs-bar">
      <div class="container" *ngIf="selectedPathway">
        <div class="bar-actions">

          <!-- When there is only 1 patient pathway, show read-only -->
          <div id="button-patient-pathways"
            class="action read-only"
            style="min-width: 165px"
            *ngIf="getPathwayListWithoutSelected(selectedPathway)?.length<1"
            >
            <span *ngIf="selectedPathway?.care_module">{{ selectedPathway?.care_module?.name | translateHelp | translate }}</span>
            <span *ngIf="!selectedPathway?.care_module">...</span>
          </div>

          <!-- when there are more than 1 patient pathways, show the dropdown button -->
          <div class="btn-group" dropdown placement="top right" *ngIf="getPathwayListWithoutSelected(selectedPathway)?.length>0">
            <button id="button-patient-pathways"
                    dropdownToggle
                    type="button"
                    class="action"
                    aria-controls="dropdown-patient-pathways"
                    style="min-width: 165px">
              <span *ngIf="selectedPathway?.care_module">{{ selectedPathway?.care_module?.name | translateHelp | translate }}</span>
              <span *ngIf="!selectedPathway?.care_module">...</span>
              <span [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
            </button>
            <ul id="dropdown-patient-pathways" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-patient-pathways">
              <li role="menuitem">
                <a (click)="onSelectPathway($event, pathway)"
                  *ngFor="let pathway of getPathwayListWithoutSelected(selectedPathway)"
                  class="dropdown-item"
                  [id]="['button-patient-pathway-' + pathway?.uid]"
                  href="">
                  {{ pathway?.care_module?.name | translateHelp | translate }}
                </a>
              </li>
            </ul>
          </div>

        </div>
        <div class="bar-tabs">
          <app-page-tabs [items]="pageTabItems"></app-page-tabs>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="body-content" style="min-height: 120vh;">
  <div class="container" *ngIf="pathwayLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!pathwayLoading && !pathwaysAvailable">
    <div class="empty-state my-5 my-lg-7 my-xl-9">
      <span [inlineSVG]="'/assets/svg-color/stethoscope.svg'"></span>
      <h3>{{ 'pages.default.patient_detail.no_active_pathways' | translate }}</h3>
    </div>
  </div>

  <div class="container" *ngIf="!pathwayLoading && !pathwayAvailable">
    <div class="empty-state my-5 my-lg-7 my-xl-9">
      <span [inlineSVG]="'/assets/svg-color/stethoscope.svg'"></span>
      <h3>{{ 'pages.default.patient_detail.pathway_not_available' | translate }}</h3>
    </div>
  </div>

  <div class="container"  *ngIf="!pathwayLoading && selectedPathway && pathwayAvailable">

    <app-patient-detail-reminders [pathway]="selectedPathway" [patient]="patient" [tasks]="tasks" (changed)="refreshPathway()" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()"></app-patient-detail-reminders>

    <div id="zone_timeline" class="mb-5" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.patient_detail.zone_timeline' | translate }}</h2>
        </div>
        <a id="patient-timeline" href="" class="block-action m-start-auto" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/timeline']">
          <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>
    </div>

    <app-patient-detail-surgery-dates [pathway]="selectedPathway" [patient]="patient" [tasks]="surgeryTasks" [appointments]="surgeryAppointments" (changed)="updateSurgeryDates()"></app-patient-detail-surgery-dates>

    <div id="zone_materials" class="mb-5">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.patient_detail.zone_materials' | translate }}</h2>
        </div>
        <div class="block-wrapper m-start-auto">
          <div class="progress-bar-wrapper m-end-2" *ngIf="previewMaterials?.length">
            <label>{{ materialCompleted | translateNumber}}/{{materialTotal | translateNumber}} {{'pages.default.patient_detail.materials_read' | translate }}</label>
            <div class="progress">
              <div class="progress-bar" role="progressbar" [style.width]="materialPercent"></div>
            </div>
          </div>
        </div>
        <a href="" id="show-more-learning-materials" class="block-action mx-0" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/learning-materials']">
          <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>

      <div class="bg-white px-2 py-2" *ngIf="materialsLoading">
        <app-page-loader></app-page-loader>
      </div>

      <div *ngIf="!materialsLoading">

        <div class="bg-white px-2 py-4"  *ngIf="!previewMaterials?.length">
          <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_materials' | translate }}</p>
        </div>

        <div class="row" *ngIf="previewMaterials?.length">
          <div class="col-6" id="material-detail-{{index}}" *ngFor="let material of previewMaterials; index as index">
            <button class="btn w-100 text-align-start p-0 single-item-block item-clickable mb-2"
              [ngClass]="{'block-muted': material?.isCompleted}"
              (click)="goToMaterialDetail(material)">

              <div class="block-wrapper p-start-3 p-end-1" [style.opacity]="( material?.isCompleted ? '0.7' : '1' )" >
                <circle-progress-medium [percent]="material?.progress" *ngIf="!material?.isCompleted"></circle-progress-medium>
                <span class="icon text-dark" [inlineSVG]="'/assets/svg/check-selected.svg'" *ngIf="material?.isCompleted"></span>
              </div>

              <div class="block-wrapper overflow-hidden">
                <p class="value text-truncate">{{ material?.phaseName  | translateHelp | translate |evalTranslation }}</p>
                <h3 class="text-truncate">{{ material?.title || '...' }}</h3>
              </div>

              <div class="block-action-wrapper m-start-auto">
                <div class="block-action action-compact">
                  <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
                </div>
              </div>
            </button>
          </div>
        </div>

        <p *ngIf="previewMaterials?.length < materials?.length" class="text-end">
          <a href="" id="link_show_more" class="text-icon-link hoverable text-secondary" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/learning-materials']">
            <span class="label">{{ 'pages.default.patient_detail.show_all' | translate }} ({{materials?.length}}) </span>
            <span class="icon-end">
              <span class="" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </span>
          </a>
        </p>
      </div>
    </div>


    <app-patient-detail-checklists [patientUid]="patientUid" [pathwayUid]="selectedPathway?.uid"></app-patient-detail-checklists>

    <app-patient-detail-essential-forms [patientUid]="patientUid" [pathwayUid]="selectedPathway?.uid" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()"></app-patient-detail-essential-forms>


    <div id="zone_goals" class="mb-5" *ngIf="selectedPathway?.goals && scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.patient_detail.zone_goals' | translate }}</h2>
        </div>
      </div>

      <div class="bg-white px-2 py-4" *ngIf="!selectedPathway?.goals?.length">
        <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_goals' | translate }}</p>
      </div>

      <div class="row" *ngIf="selectedPathway?.goals?.length">
        <div class="col-4 mb-2" id=goal-instance-{{index}} *ngFor="let goalInstance of selectedPathway?.goals; index as index">
          <div class="card card-goal h-100">
            <div class="card-body card-body">
              <h3>{{ goalInstance?.title | translateHelp | translate }}</h3>

              <div class="goal-box goal-box-graph" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'PROGRESS' && goalInstance.evaluation?.available()">
                <circle-progress
                  [percent]="goalService.getProgressPercentage(goalInstance)"
                  [title]="goalService.getProgressTitle(goalInstance)"
                  [subtitle]="goalService.getProgressSubtitle(goalInstance) | translate"
                ></circle-progress>
              </div>

              <div class="goal-box goal-box-graph" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'PROGRESS' && !goalInstance.evaluation?.available()">
                <label class="position-absolute top-50 start-0 translate-middle-y w-50 text-center"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
                <svg style="width: 100%; height: 100%;" viewBox="0 0 214 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M79.3165 210.317C22.2564 195.027 -11.6056 136.377 3.68359 79.3165C18.9728 22.2564 77.6235 -11.6056 134.684 3.68363C191.744 18.9728 225.606 77.6235 210.316 134.684C195.027 191.744 136.377 225.606 79.3165 210.317ZM127.025 32.2654C85.7503 21.2058 43.3249 45.7002 32.2654 86.975C21.2058 128.25 45.7001 170.675 86.9749 181.735C128.25 192.794 170.675 168.3 181.735 127.025C192.794 85.7503 168.3 43.325 127.025 32.2654Z" fill="#F4F4F4"/>
                </svg>
              </div>

              <div class="goal-box goal-box-text" *ngIf="goalInstance?.evaluation?.evaluation_graph === 'TEXT'">
                <p class="title text-truncate">{{ goalInstance?.evaluation?.evaluation_value || '-' }}</p>
                <p *ngIf="goalInstance?.evaluation?.evaluation_unit">{{ goalInstance?.evaluation?.evaluation_unit | translateHelp | translate }}</p>
                <label class="text-center mt-2" *ngIf="!goalInstance.evaluation?.available()"> {{ 'pages.default.goal_detail.no_recent_data_available' | translate }}</label>
              </div>

            </div>
            <div class="card-footer">
              <a href="" class="btn btn-secondary m-start-auto" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + '/goal/' + goalInstance?.id]">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="zone_notes" class="mb-5" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden m-end-auto">
          <h2 class="text-truncate">{{ 'pages.default.patient_detail.zone_notes' | translate }}</h2>
        </div>



        <a href="" class="block-action mx-0" id="add-new-note" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/new']" [queryParams]="{'back-to': 'patient-detail'}">
          <span>{{ 'pages.default.notes.add_note' | translate }}</span>
          <span class="icon m-start-1 rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/add.svg'"></span>
        </a>
        <a href="" id="show-more-notes" class="block-action mx-0" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes']">
          <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
        </a>
      </div>

      <div class="bg-white px-2 py-2" *ngIf="notesLoading">
        <app-page-loader></app-page-loader>
      </div>

      <div *ngIf="!notesLoading">
        <div class="bg-white px-2 py-4" *ngIf="notes?.length == 0">
          <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_notes' | translate }}</p>
        </div>

        <div class="row" *ngIf="notes?.length > 0">
          <div class="col-6 mb-2" *ngFor="let note of notes">
            <div class="card h-100">
              <div class="card-body card-body">
                <div class="row text-muted small justify-content-between">
                  <div class="col-auto">
                    <p class="mb-1">{{ note?.changed_at | timeZoneDate}}</p>
                  </div>
                  <!--
                  <div class="col-auto">
                    <p class="mb-1"><span class="icon xs rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/attachment.svg'"></span><span class="align-middle">3</span></p>
                  </div>
                -->
                </div>

                <h3>{{ note?.title }}</h3>

                <div class="user-compact mb-2">
                  <app-avatar [profilePicture]="note?.created_by?.profile_picture"></app-avatar>

                  <div class="user-compact-info">
                    <p class="title text-truncate">{{ note?.created_by?.getFullName() }}</p>
                    <p>{{ note?.created_by?.job_title }}</p>
                  </div>
                </div>

                <p class="m-0">{{ note?.note_preview }}</p>
              </div>
              <div class="card-footer">
                <div class="m-end-auto"></div>

                <div class="btn-group" dropdown placement="top right"  *ngIf="note?.created_by?.uid == currentHcpUid">

                  <button id="button_note_dropdown" dropdownToggle type="button" class="dropdown-toggle btn btn-settings" aria-controls="note_dropdown">
                    <span [inlineSVG]="'/assets/svg/more.svg'"></span>
                  </button>

                  <ul id="note_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_note_dropdown">
                    <li role="menuitem">
                      <a class="dropdown-item" href="" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/' + note?.uid + '/edit']" [queryParams]="{'back-to': 'patient-detail'}">{{ 'pages.default.patient_detail.edit_note' | translate }}</a>
                      <a class="dropdown-item text-danger" href="" (click)="deleteNote($event, note?.uid)">{{ 'pages.default.patient_detail.delete_note' | translate }}</a>
                    </li>
                  </ul>
                </div>

                <a href="" class="btn btn-secondary" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/notes/' + note?.uid + '/view']" [queryParams]="{'back-to': 'patient-detail'}">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="text-end" *ngIf="totalNotes > 0">
        <a href="" id="link_notes_show_more" class="text-icon-link hoverable text-secondary" [routerLink]="['/patient/' + patientUid + '/' + selectedPathway?.uid + '/' + 'notes']">
          <span class="label">{{ 'pages.default.patient_detail.show_all' | translate }} ( {{ totalNotes }} ) </span>
          <span class="icon-end">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          </span>
        </a>
      </p>
    </div>


    <div id="zone_mdt" class="mb-5" *ngIf="scopeService.doesCurrentPatientHasClicinalCareModule()">
      <div class="heading-block mb-3">
        <div class="block-wrapper overflow-hidden m-end-auto">
          <h2 class="text-truncate">{{ 'pages.default.patient_detail.zone_mdt' | translate }}</h2>
        </div>
        <a href="" class="block-action mx-0" id="edit-mdt" (click)="editMdt($event)">
          <span>{{'pages.default.patient_detail.edit_mdt' | translate }}</span>
          <span class="icon m-start-1 rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/edit.svg'"></span>
        </a>
      </div>

      <div class="bg-white px-2 py-4" *ngIf="!selectedPathway?.clinical_lead && !selectedPathway?.case_manager && !selectedPathway?.patient_mdt?.mdts?.length"></div>

      <div class="row mb-3" *ngIf="selectedPathway?.clinical_lead || selectedPathway?.case_manager">
        <div id="clinical-lead" class="col-6" *ngIf="selectedPathway?.clinical_lead">
          <h3>{{ 'pages.default.patient_detail.clinical_lead' | translate }}</h3>
          <div class="participant-item bg-white">

            <app-avatar [profilePicture]="selectedPathway?.clinical_lead?.profile_picture"></app-avatar>

            <div class="meta">
              <p class="title">{{selectedPathway?.clinical_lead?.getFullName()}}</p>
              <p class="description">{{ selectedPathway?.clinical_lead?.job_title }}</p>
            </div>
          </div>
        </div>

        <div id="case-manager" class="col-6" *ngIf="selectedPathway?.case_manager">
          <h3>{{ 'pages.default.patient_detail.case_manager' | translate }}</h3>
          <div class="participant-item bg-white">
            <div class="avatar"
                [style.backgroundImage]="'url(' + (selectedPathway?.case_manager?.profile_picture?.url | secure | async) + ')'"
                [ngClass]="{ 'empty': !selectedPathway?.case_manager?.profile_picture }"></div>
            <div class="meta">
              <p class="title">{{selectedPathway?.case_manager?.getFullName()}}</p>
              <p class="description">{{ selectedPathway?.case_manager?.job_title }}</p>
            </div>
          </div>
        </div>
      </div>

      <div id="mdt-{{index}}" class="mb-3" *ngFor="let mdt of selectedPathway?.patient_mdt.mdts; index as index">
        <h3>{{mdt.name}}</h3>
        <div class="row">

          <div class="col-6 mb-2" *ngFor="let hcp of mdt.hcps">
            <div class="participant-item bg-white">
              <div class="avatar"
                  [style.backgroundImage]="'url(' + (hcp?.profile_picture?.url | secure | async) + ')'"
                  [ngClass]="{ 'empty': !hcp.profile_picture }"></div>
              <div class="meta">
                <p class="title">{{hcp.getFullName()}}</p>
                <p class="description">{{ hcp?.job_title }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="mdt-other" class="mb-3" *ngIf="selectedPathway?.patient_mdt?.otherHcps?.length">
        <h3>{{ 'pages.default.patient_detail.mdt.other' | translate }}</h3>
        <div class="row">
          <div class="col-6 mb-2" *ngFor="let hcp of selectedPathway?.patient_mdt.otherHcps">
            <div class="participant-item bg-white">
              <div class="avatar"
                  [style.backgroundImage]="'url(' + (hcp?.profile_picture?.url | secure | async) + ')'"
                  [ngClass]="{ 'empty': !hcp.profile_picture }"></div>
              <div class="meta">
                <p class="title">{{hcp.getFullName()}}</p>
                <p class="description">{{ hcp?.job_title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
