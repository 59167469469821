import { TranslationInterface } from "../interfaces/translation.interface";
import { FormField } from "./form-field";

export class UserTaskSection {
  id: string;
  order: number;
  title: TranslationInterface;
  description: TranslationInterface;
  form_fields: Array<FormField> = [];
  visible: boolean;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.id = item.id;
    this.order = item.order || 0;
    this.title = item.title;
    this.description = item.description;
    this.visible = item.visible;

    if (item.form_fields) {
      item.form_fields.forEach(formField => {
        this.form_fields.push(new FormField(formField));
      });
    }
  }

  get translationKey():string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  // get id(): string {
  //   if(this.order !== undefined && this.order !== null) {
  //     return `section_${this.order}`;
  //   } else {
  //     return `section`;
  //   }
  // }
}
