import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './../../../services/login.service';
import { HomeFlowService } from './../../../services/home-flow.service';
import { ErrorService } from '../../../services/error.service';
import { environment } from '../../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralService } from '../../../services/general.service';
import { HelpCenterModalComponent } from '../../../modals/help-center-modal/help-center-modal.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public credentialsIncorrect: boolean;
  public isLoading: boolean;
  public accountLocked = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public loginService: LoginService,
    public homeFlowService: HomeFlowService,
    public errorService: ErrorService,
    public modalService: BsModalService
  ) { }

  ngOnInit() {
    this.formSetup();
    this.route.queryParams.subscribe(params => this.queryParamsChanged(params));
  }

  queryParamsChanged(params) {
    if(params.code && params.flow) {
      this.homeFlowService.initialize();
    }
  }

  formSetup() {
    let emailValue = '';

    if(environment['dev_remember_login_email']) {
      emailValue = localStorage.getItem('dev_remember_login_email') || '';
    }

    this.form = this.formBuilder.group({
      email: [emailValue, [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  hasInput() {
    const emailControl = this.form.get('email');
    const passwordControl = this.form.get('password');
    return Boolean(emailControl.value || passwordControl.value);
  }

  formSubmit() {
    if(this.isLoading) {
      return;
    }

    this.credentialsIncorrect = false;

    if(!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.isLoading = true;
      this.accountLocked = false;

      this.loginService.login(this.form.value.email, this.form.value.password).subscribe(
        () => this.loginSuccessHandler(),
        response => this.loginErrorHandler(response));
    }
  }

  loginSuccessHandler() {
    if(environment['dev_remember_login_email']) {
      localStorage.setItem('dev_remember_login_email', this.form.value.email);
    }

    this.homeFlowService.goNextStepOrDone(HomeFlowService.FlowLogin).subscribe(() => {
      this.isLoading = false;
    });
  }

  loginErrorHandler(response: any) {
    this.isLoading = false;

    if(!response) {
      return;
    }

    if(response?.error?.key) {
      const key = response?.error?.key;

      if(key == 'ERROR_IDENTITY_ACCOUNT_LOCKED') {
        this.markAccountLocked();
      } else {
        this.markCredentialsInvalid();
      }
    } else {
      this.markCredentialsInvalid();
    }
  }

  markCredentialsInvalid() {
    this.isLoading = false;
    this.validationVisible = true;
    this.credentialsIncorrect = true;
  }

  markAccountLocked() {
    this.isLoading = false;
    this.validationVisible = true;
    this.accountLocked = true;
  }

  openHelpCenter() {
    this.modalService.show(HelpCenterModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg modal-compact'
      })
    );
  }
}
