import { Component } from '@angular/core';
import { ScopeService } from '../../../../services/scope.service';

@Component({
  selector: 'app-dna-overview',
  templateUrl: './dna-overview.component.html',
  styleUrls: ['./dna-overview.component.scss']
})
export class DnaOverviewComponent {

  constructor(
    public scopeService: ScopeService
  ) { }
}
