<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back-filters"></div>

  <div class="layout-header header-back-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <p>
              <a [routerLink]="['/administration']" class="text-secondary font-weight-bold d-flex align-items-center" id="admin-patients-back">
                <span [inlineSVG]="'/assets/svg/left-arrow-big.svg'" class="rtl-mirrored-inline-svg m-end-1"></span>
                <span class="align-middle">{{ 'action.back_to_administration' | translate }}</span>
              </a>
            </p>
          </div>
          <div class="col col-auto">
            <div dropdown placement="top right" class="d-inline-block" >
              <button id="button-patient-more-actions" dropdownToggle type="button" class="btn btn-sm btn-secondary"
                      [ngClass]="{'disabled' : (selectedPatientUids?.length === 0)}"
                      aria-controls="dropdown-task-more-actions" style="min-width: 56px; border: none">
                <span [inlineSVG]="'/assets/svg/more-filled.svg'"></span>
              </button>
              <ul id="dropdown-task-more-actions" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-task-more-actions">
                <li role="menuitem">
                  <a id="admin-patients-assign-mdt" class="dropdown-item" href="" (click)="onBulkAssignMdt($event)">{{ 'pages.default.administration.patients.bulk_assign_mdt' | translate }}</a>
                </li>
                <li role="menuitem">
                  <a id="admin-patients-edit-cl" class="dropdown-item" href="" (click)="onEditCmCL($event, 'CL')">{{ 'pages.default.administration.patients.edit_cl' | translate }}</a>
                </li>
                <li role="menuitem">
                  <a id="admin-patients-edit-cm" class="dropdown-item" href="" (click)="onEditCmCL($event, 'CM')">{{ 'pages.default.administration.patients.edit_cm' | translate }}</a>
                </li>
              </ul>
            </div>

            <button (click)="onAddNewPatient($event)"
                    [disabled]="selectedPatientUids?.length !== 0"
                    class="btn btn-sm btn-secondary m-start-1"
                    id="admin-patients-add-new-patient"
                    href="">
              {{ 'pages.default.administration.patients.add_new_patient' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="container">
        <h1>{{ 'pages.default.administration.patients.patients' | translate }}</h1>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">

              <div class="form-group styled floating-label">
                <input (ngModelChange)="patientLastNameSearch.next($event)" [(ngModel)]="filters.last_name"
                      [placeholder]="('form.labels.patient_last_name' | translate)"
                      class="form-control w-100"
                      id="patientLastNameSearch"
                      required type="text">
                <label for="patientLastNameSearch">{{ 'form.labels.patient_last_name' | translate }}</label>
              </div>

              <div class="divider"></div>

              <app-select
                id="select-care-module"
                class="d-inline-block"
                [placeholder]="('pages.default.administration.patients.pathway' | translate)"
                [clearable]="false"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="careModules"

                [(ngModel)]="filters.care_module_uid"
                [bindValue]="'value'"
                (ngModelChange)="onCareModuleFilterChange()"

                [asFilter]="true"
                [showCheckboxes]="false"
              ></app-select>

              <div class="divider"></div>

              <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
                <button aria-controls="dropdown-basic" class="btn btn-primary dropdown-toggle" dropdownToggle
                        id="dropdown-btn-assignments"
                        type="button">
                  <span>{{ assignmentOption | translate }}</span>
                  <span [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
                </button>
                <ul *dropdownMenu
                    aria-labelledby="dropdown-btn-sort"
                    class="dropdown-menu"
                    id="dropdown-basic-assignments"
                    role="menu">
                  <li role="menuitem" *ngFor="let assignmentOption of assignmentOptionsList">
                    <a (click)="onChangeAssignment($event, assignmentOption)" class="dropdown-item" id="admin-patients-change-assignment" href="">
                      {{ assignmentOption | translate }}
                    </a>
                  </li>
                </ul>
              </div>

              <div *ngIf="filtersAreSet" class="divider"></div>

              <button (click)="onActionResetFilters()" *ngIf="filtersAreSet" class="btn btn-link text-info py-0"
                      type="button" id="admin-patients-reset-filters">{{ 'action.reset' | translate }}</button>
            </div>

          </div>
          <div class="col col-auto">
            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button aria-controls="dropdown-basic" class="btn btn-primary dropdown-toggle" dropdownToggle
                      id="dropdown-btn-sort"
                      type="button">

                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sorting == 'last_name,asc;first_name,asc'">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'last_name,desc;first_name,desc'">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'created_at,asc'">{{ 'form.labels.date_added_sort_asc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'created_at,desc'">{{ 'form.labels.date_added_sort_desc' | translate }}&#x200E;</span>

                <span [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name,asc;first_name,asc')">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name,desc;first_name,desc')">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'created_at,asc')">{{ 'form.labels.date_added_sort_asc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'created_at,desc')">{{ 'form.labels.date_added_sort_desc' | translate }}&#x200E; </a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div (clickOutside)="toggleMoreFilters(false)" *ngIf="moreFiltersAreShown"
        [delayClickOutsideInit]="true"
        class="header-overlap-block header-filters-block">
      <div class="header-content">
        <div class="container">

        </div>
      </div>
    </div>

  </div>

  <div class="body-content">
    <div *ngIf="isLoading" class="container">
      <app-page-loader></app-page-loader>
    </div>

    <div *ngIf="!isLoading" class="container">
      <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="allPatients && allPatients.length <= 0">
        <span [inlineSVG]="'/assets/svg-color/stethoscope.svg'"></span>
        <h3>{{ 'pages.default.dashboard.no_patients_yet' | translate }}</h3>
      </div>

      <div *ngIf="allPatients && allPatients.length > 0">
        <div class="block-checkbox pl-2 pb-1">
          <div class="custom-control custom-checkbox custom-control-inline ">
            <input (change)="onToggleAllCheckbox()" class="custom-control-input"
                    id="checkbox_all"
                    [checked]="selectedAll"
                    type="checkbox">
            <label class="custom-control-label text-muted" for="checkbox_all">{{ 'general.select_all' | translate }}</label>
          </div>
        </div>
      </div>
      <div *ngFor="let patient of allPatients; let index = index;">
        <div id="patient-{{index}}" [ngClass]="{'block-selected': selectedPatientsMap.get(patient)}" class="single-item-block mb-2">

          <div class="block-checkbox">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input (change)="onToggleCheckbox(patient)" class="custom-control-input"
                    id="checkbox_item_A{{index}}"
                    type="checkbox" [checked]="selectedPatientsMap.get(patient)">
              <label class="custom-control-label" for="checkbox_item_A{{index}}"></label>
            </div>
          </div>

          <div class="row d-flex align-items-center w-100">
            <div class="col-3">
              <div class="block-wrapper py-3">
                <p class="label text-truncate">{{ patient.first_name}} {{ patient.last_name }}</p>
                <p *ngIf="patient.date_of_birth"
                  class="value text-truncate">{{ patient.date_of_birth | date:dateFormat.format  }}</p>
              </div>
            </div>

            <div class="col-5">
              <div class="block-wrapper">
                <p class="text-truncate text-dark font-weight-bold">{{ getCareModuleForPatient(patient)?.care_module?.name | translateHelp | translate }}</p>
                <ul class="small">
                  <li *ngIf="patient.created_at" class="e2e-added-on text-muted">
                    {{ 'pages.default.administration.patients.added_on' | translate }}&nbsp;{{ patient.created_at | date:dateFormat.format }}
                  </li>
                  <li *ngIf="!patient.consented_at && patient.status !== 'ACTIVE'" class="e2e-not-consented text-muted">
                    {{ 'pages.default.administration.patients.not_consented' | translate }}
                  </li>
                  <li *ngIf="patient.consented_at" class="text-muted text-semibold e2e-consented">
                    {{ 'pages.default.administration.patients.consented_on' | translate }} {{patient.consented_at | date:dateFormat.format }}
                  </li>
                  <li *ngIf="!patient.consented_at && patient.status === 'ACTIVE'" class="text-muted text-semibold e2e-consented-and-active">
                    {{ 'pages.default.administration.patients.no_consent_required' | translate }} {{patient.consented_at | date:dateFormat.format }}
                  </li>
                </ul>

                <p class="text-truncate e2e-no-mdt-assigned text-dark" *ngIf="!hasAssignedMdt(patient)">
                  {{ 'pages.default.administration.patients.no_mdt_assigned' | translate }}
                </p>
                <p class="text-truncate text-dark text-semibold e2e-mdt-assigned text-dark" *ngIf="hasAssignedMdt(patient)">
                  {{ 'pages.default.administration.patients.mdt_assigned' | translate }}
                </p>
              </div>
            </div>

            <div class="col-4">
              <div class="block-wrapper">
                  <div class="item">
                    <label class="tiny mb-0">{{ 'components.patient_tabbed_details.clinical_lead' | translate }}</label>
                    <p class="text-dark font-weight-bold">{{ getCareModuleForPatient(patient)?.clinical_lead?.getFullName() || '...' }}</p>
                  </div>

                  <div class="item">
                    <label class="tiny mb-0">{{ 'components.patient_tabbed_details.case_manager' | translate }}</label>
                    <p class="text-dark font-weight-bold">{{ getCareModuleForPatient(patient)?.case_manager?.getFullName() || '...' }}</p>

                  </div>
                <!--
                <div *ngIf="!hasAssignedSurgery(patient)"
                    class="d-flex flex-row align-items-center">
                  <span [inlineSVG]="'/assets/svg/stethoscope.svg'" class="m-end-1"></span>
                  <p class="text-truncate">
                    {{ 'pages.default.administration.patients.no_surgery_assigned' | translate }}
                  </p>
                </div>
                -->
                <div class="d-flex flex-row align-items-center"></div> <!-- TEMP DIV -->


                <!-- <div *ngIf="hasAssignedSurgery(patient)"
                    class="d-flex flex-row align-items-center">
                  <span [inlineSVG]="'/assets/svg/stethoscope.svg'" class="m-end-1"></span>
                  <p class="text-truncate text-dark text-semibold">
                    {{ 'pages.default.administration.patients.surgery_assigned' | translate }}
                  </p>
                </div> -->
              </div>
            </div>
          </div>

          <div class="block-action-wrapper justify-content-end" style="min-width:60px">
            <div class="btn-group" dropdown placement="top right" *ngIf="patient.resend_onboarding_email_allowed">
              <button [id]="'button-admin-patients-more-actions-' + patient.uid" dropdownToggle type="button" class="block-action action-compact action-muted"
                      aria-controls="dropdown-task-more-actions" style="min-width: 56px; border: none">
                <span [inlineSVG]="'/assets/svg/more-filled.svg'"></span>
              </button>
              <ul [id]="'dropdown-admin-patients-more-actions-' + patient.uid" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [attr.aria-labelledby]="'button-admin-patients-more-actions-' + patient.uid">
                <li role="menuitem">
                  <a class="dropdown-item" href="" (click)="resendOnboardingEmail($event, patient)">{{ 'pages.default.administration.patients.resend_onboarding_email' | translate }}</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
