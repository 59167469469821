export class AttentionCenter {
  public appointmentsPending: number;
  public tasks: number;
  public conversationMessagesUnread: number;

  public userTasksPending: number;
  public patientPathwayMessagesUnread: number;
  public selfRegisteredPatientsPending: number;

  constructor(item?) {
    this.fillFromJson(item || {});
  }

  fillFromJson(item) {
    this.appointmentsPending = item.appointments_pending || 0;
    this.conversationMessagesUnread = item.conversation_messages_unread || 0;


    this.userTasksPending = item.user_tasks_pending || 0;
    this.patientPathwayMessagesUnread = item.patient_pathway_messages_unread || 0;
    this.tasks = this.userTasksPending + this.patientPathwayMessagesUnread;
    this.selfRegisteredPatientsPending = item.self_registered_patients_pending || 0;
  }
}
