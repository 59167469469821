import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, from } from 'rxjs';
import { DeleteNoteModalComponent } from '../../../modals/delete-note-modal/delete-note-modal.component';
import { Note } from '../../../models/note';
import { AuthenticationService } from '../../../services/authentication.service';
import { GeneralService } from '../../../services/general.service';
import { HcpService } from '../../../services/hcp.service';
import { NotesService } from '../../../services/notes.service';
import marked, { Renderer } from 'marked';
import DOMPurify from 'dompurify';
import ClassicEditor from '../../../ckeditor/ckeditor';
import { ConfirmModalComponent } from '../../../modals/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Attachment } from '../../../models/attachment';
import { mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-note-detail',
  templateUrl: './note-detail.component.html',
  styleUrls: ['./note-detail.component.scss']
})
export class NoteDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('editor') editorComponent: CKEditorComponent;
  @ViewChild('titleTextArea') titleTextArea: ElementRef;

  public hasCcRole: boolean;
  public currentHcpUid: string;
  public note: Note;
  public originalNote: Note;

  public Editor = ClassicEditor;
  public editorInstance: any;
  public editorButtonGroups: Array<any>;

  public isLoadingEditor: boolean;
  public isLoadingNote: boolean;
  public isEditingMode: boolean;
  public isUploading: boolean = false;

  public noteUid: string;
  public patientUid: string;
  public patientPathwayUid: string;
  public title: string;
  public backToParam: string;
  public mdConvertor: any;
  public validationVisible = false;
  public attachments: Array<Attachment> = [];
  public extensions: Array<string>;
  public extensionList: string;
  public deactivate: boolean;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public notesService: NotesService,
    public authService: AuthenticationService,
    public hcpService: HcpService,
    public modalService: BsModalService,
    public generalService: GeneralService,
    public sanitizer: DomSanitizer,
    public translateService: TranslateService,
    public toastrService: ToastrService,
    public dataService: DataService
  ) {
    this.mdConvertor = marked.setOptions({ renderer: new Renderer() });
  }

  @HostListener('window:beforeunload', [ '$event' ]) //Don't know what this is... but we'll let it be.
  ngOnInit(): void {
    this.hasCcRole = this.authService.hasCcRole();
    this.currentHcpUid = this.hcpService.getCurrentStoredHcpUid();
    this.extensionList = this.generalService.supportedFileTypes.fileInputAccept;

    this.initializeEditor().subscribe(() => this.onEditorInitialized());
  }

  onEditorInitialized() {
    this.activatedRoute.params.subscribe(params => {
      this.noteUid = params.noteUid;
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;

      if (!this.noteUid) {
        this.showEditMode();
      } else {
        if (!this.note) {
          this.loadNote();
        }

        const action = params.action;
        if (action === 'view') {
          this.showViewMode();
        } else {
          this.showEditMode();
        }
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.backToParam = params['back-to'];
    });
  }

  ngAfterViewInit() {
    this.titleTextArea.nativeElement.addEventListener('keydown', (event) => {
      if (event?.key?.toUpperCase() === 'ENTER') {
        event.preventDefault();
      }
    });
  }

  public get mustShowLoader(): boolean {
    return this.isLoadingNote;
  }

  initializeEditor(): Observable<any> {
    if (this.editorInstance) {
      return new Observable(observer => {
        observer.next();
        observer.complete();
      });
    } else {
      this.isLoadingEditor = true;
      return new Observable(observer => {
        setTimeout(() => {
          ClassicEditor
            .create(document.querySelector('#editor'), {
              toolbar: ['bold', 'italic', '|', 'bulletedList', 'numberedList']
            })
            .then(editorInstance => this.onEditorReady(editorInstance, observer));
        });
      });
    }
  }

  onEditorReady(editorInstance, observer) {
    this.editorInstance = editorInstance;
    this.isLoadingEditor = false;

    observer.next();
    observer.complete();
  }

  setEditorData(markdownInput: string) {
    this.editorInstance?.setData(markdownInput);
  }

  getEditorData(): string {
    return this.editorInstance ? this.editorInstance.getData() : '';
  }

  getEditorDataHtml(): SafeHtml {
    const mdData = this.editorInstance?.getData();
    if (mdData) {
      return this.markdownToSafeHtml(mdData);
    } else {
      return '';
    }
  }

  markdownToSafeHtml(markdown: string): SafeHtml {
    const html = this.mdConvertor(markdown);
    const safeHtml = DOMPurify.sanitize(html);
    return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
  }

  loadNote() {
    if (!this.note) {
      this.isLoadingNote = true;
    }

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();

    const notesObservable = this.hasCcRole
      ? this.notesService.getNoteByCc(hospitalUid, this.patientUid, this.patientPathwayUid, this.noteUid)
      : this.notesService.getNoteByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.noteUid);


    notesObservable.subscribe(result => {
      this.note = result;
      this.attachments = this.note.attachments;

      this.originalNote = cloneDeep(this.note);
      this.isLoadingNote = false;
      this.title = this.note.title;
      this.dataService.set(DataService.BreadCrumbNoteTitle, this.note.title);

      this.setEditorData(this.note.note);
    });
  }

  showViewMode() {
    this.isEditingMode = false;
  }

  showEditMode() {
    this.isEditingMode = true;
  }

  goToActionRoute(action: string = 'view') {
    let url: any = `/patient/${this.patientUid}/${this.patientPathwayUid}/notes/${this.noteUid}/${action}`;

    if (this.backToParam) {
      let params: any = {
        queryParams: {
          'back-to': this.backToParam
        }
      }
      url = this.router.createUrlTree([url], params)
    }
    this.router.navigateByUrl(url);
  }

  onSaveBtnClicked(event) {
    event.preventDefault();
    this.validationVisible = false;
    if (this.title === '' || this.title === undefined) {
      this.validationVisible = true;
      return;
    }

    if (!this.note || !this.note.uid) {
      this.postNote();
    } else {
      this.updateNote();
    }
  }

  postNote() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();

    const notesObservable = this.hasCcRole
      ? this.notesService.postNoteByCc(hospitalUid, this.patientUid, this.patientPathwayUid, this.title, this.getEditorData())
      : this.notesService.postNoteByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.title, this.getEditorData());

    notesObservable.subscribe(result => {
      this.note = result;
      this.noteUid = this.note.uid;
      forkJoin(this.saveAttachments(), this.deleteAttachments()).subscribe(result => {
        this.deactivate = true;
        this.goToActionRoute('view');
        this.toastrService.info(this.translateService.instant('pages.default.notes.successfull_create'), null, {
          disableTimeOut: false,
          timeOut: 4000
        });
      });
    });
  }

  isDirty(): boolean {
    const pending: Boolean = (this.attachments.filter(a => { return (a.status === "UNSAVED" || a.status === "DELETED" || a.status === "WAITING") }).length > 0);
    const titleChanged: Boolean = (this.note?.title !== this.title);
    const noteChanged: Boolean = (this.note?.note !== this.getEditorData());

    if (this.note) {
      if (pending || titleChanged || noteChanged) {
        return true;
      }
    } else {
      if (this.title !== undefined || this.getEditorData() !== '') {
        return true;
      }
    }

    return false;
  }

  updateNote() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();

    const notesObservable = this.hasCcRole
      ? this.notesService.updateNoteByCc(hospitalUid, this.patientUid, this.patientPathwayUid, this.note?.uid, this.title, this.getEditorData())
      : this.notesService.updateNoteByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.note?.uid, this.title, this.getEditorData());

    notesObservable.subscribe(result => {
      this.note = result;
      this.noteUid = this.note.uid;
      this.dataService.set(DataService.BreadCrumbNoteTitle, this.note.title);

      forkJoin([
        this.saveAttachments(),
        this.deleteAttachments()
      ]).subscribe(result => {
        this.deactivate = true;
        this.goToActionRoute('view');
        this.toastrService.info(this.translateService.instant('pages.default.notes.successfull_update'), null, {
          disableTimeOut: false,
          timeOut: 4000
        });
      });
    });
  }

  deleteNote(event, id: string) {
    event.preventDefault();

    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const initialState = {
      patientUid: this.patientUid,
      hospitalUid,
      hcpUid,
      patientPathwayUid: this.patientPathwayUid,
      noteUid: id,
      hasCcRole: this.hasCcRole
    };

    const modalref = this.modalService.show(DeleteNoteModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState
      })
    );

    modalref.content?.noteDeleted.subscribe(() => {
      this.handleGoBack();
    });
  }

  saveAttachments() {
    return new Observable(observer => {
      const unsaved = this.attachments.filter(a => { return a.status === "UNSAVED" });
      if (unsaved.length === 0) {
        observer.next();
        observer.complete();
      }

      const queued = from(unsaved)
        .pipe(mergeMap(attachment => this.saveAttachment(attachment), null, 1));

      queued.subscribe(result => {
        if (this.attachments.filter(a => { return a.status === "UNSAVED" }).length === 0) {
          observer.next();
          observer.complete();
        }
      });
    });
  }

  saveAttachment(attachment: Attachment) {
    return new Observable(observer => {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const hcpUid = this.hcpService.getCurrentStoredHcpUid();

      const attachmentObservable = this.hasCcRole
        ? this.notesService.attachAttachmentByHospital(hospitalUid, this.patientUid, this.patientPathwayUid, this.note?.uid, attachment.uid)
        : this.notesService.attachAttachmentByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.note?.uid, attachment.uid);

      attachmentObservable.subscribe(result => {
        attachment.uid = result['uid'];
        attachment.status = result['status'];
        observer.next();
        observer.complete();
      });
    });
  }

  setStateToDeleted(event: MouseEvent, attachment: Attachment) {
    event.preventDefault();

    if (attachment.status === 'UNSAVED') {
      attachment.status = "DELETED_UNSAVED";
    }

    if (attachment.status === 'SAFE' || attachment.status === 'PENDING') {
      attachment.status = "DELETED";
    }
  }

  deleteAttachments() {
    return new Observable(observer => {
      const deleted = this.attachments.filter(a => { return a.status === "DELETED" });

      if (deleted.length === 0) {
        observer.next();
        observer.complete();
      }

      deleted.forEach(attachment => {
        this.deleteAttachment(attachment);
      })

      const queued = from(deleted)
        .pipe(mergeMap(attachment => this.deleteAttachment(attachment), null, 1));
      queued.subscribe(result => {
        if (this.attachments.filter(a => { return a.status === "DELETED" }).length === 0) {
          observer.next();
          observer.complete();
        }
      });

      const deletedUnsaved = this.attachments.filter(a => { return a.status === "DELETED_UNSAVED" });
      deletedUnsaved.forEach(attachment => {
        const index = this.attachments.indexOf(attachment);
        if (index > -1) {
          this.attachments.splice(index, 1);
        }
      });
    });
  }

  deleteAttachment(attachment: Attachment) {
    return new Observable(observer => {
      const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
      const hcpUid = this.hcpService.getCurrentStoredHcpUid();

      const attachmentObservable = this.hasCcRole
        ? this.notesService.deleteAttachmentByHospital(hospitalUid, this.patientUid, this.patientPathwayUid, this.note?.uid, attachment.uid)
        : this.notesService.deleteAttachmentByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.note?.uid, attachment.uid);

      attachmentObservable.subscribe(result => {
        const index = this.attachments.indexOf(attachment);
        if (index > -1) {
          this.attachments.splice(index, 1);
        }
        observer.next();
        observer.complete();
      });
    });
  }

  downloadAttachment(event: MouseEvent, attachment: Attachment) {
    event.preventDefault();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();

    const attachmentObservable = this.hasCcRole
      ? this.notesService.downloadAttachmentByHospital(hospitalUid, this.patientUid, this.patientPathwayUid, this.note?.uid, attachment.uid)
      : this.notesService.downloadAttachmentByHcp(hcpUid, this.patientUid, this.patientPathwayUid, this.note?.uid, attachment.uid);

    attachmentObservable.subscribe(result => {
      window.location.href = result['download_link'];
    });
  }

  onEditBtnClicked(event) {
    event.preventDefault();
    this.goToActionRoute('edit');
  }

  onCancelBtnClicked(event) {
    event.preventDefault();
    this.canDeactivate().then((result) => {
      if (this.note) {
        this.goToActionRoute('view');
        return;
      } else {
        this.handleGoBack();
      }
    });
  }

  handleGoBack(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }

    let url: string;

    if (this.backToParam === 'patient-detail') {
      url = `/patient/${this.patientUid}`;
    } else {
      url = `/patient/${this.patientUid}/${this.patientPathwayUid}/notes`;
    }
    this.router.navigateByUrl(url);
  }

  // Attachments
  handleFileInput(files: FileList) {
    Array.from(files).forEach(file => {
      const attachment = new Attachment(file);
      attachment.meta.created_at = moment().format();
      attachment.meta.file_name = file?.name;
      this.attachments.push(attachment);
    });

    this.postAttachments();
  }

  postAttachments() {
    const waiting = this.attachments.filter(a => { return a.status === "WAITING" });
    if (waiting && waiting[0]) {
      this.postAttachment(waiting[0]);
    }
  }

  postAttachment(attachment: Attachment) {
    this.isUploading = true;
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    attachment.meta.size = attachment.file.size;
    attachment.meta.extension = attachment.file.type.replace('image/', '');;

    if (attachment.file.size > 20971520) {
      attachment.status = "TOO_LARGE";
      return;
    }

    this.notesService.postAttachment(hcpUid, this.patientUid, this.patientPathwayUid, attachment.file).subscribe(result => {
      attachment.status = "UNSAVED";
      attachment.uid = result['uid'];
      this.isUploading = false;
      this.postAttachments();
    }, error => {
      this.isUploading = false;
      if (error['error'] && error['error']['errors'] && error['error']['errors'][0]['key'] === 'ERROR_FILE_INVALID_EXTENSION') {
        attachment.status = "INCONSISTENT_MIME_TYPE";
      } else {
        attachment.status = "ERROR";
      }
      this.postAttachments();
    });
  }

  async canDeactivate(): Promise<boolean> {
    if (!this.isDirty() || this.deactivate) {
      return true;
    }

    const modalRef = this.modalService.show(ConfirmModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered',
        initialState: {
          title: this.translateService.instant('modals.cancel_note.title'),
          description: this.translateService.instant('modals.cancel_note.description'),
          yes: this.translateService.instant('modals.cancel_note.leave'),
          no: this.translateService.instant('modals.cancel_note.back')
        }
      })
    );

    return new Promise<boolean>((resolve) => {
      modalRef.content.onChoice.subscribe(() => {
        if (this.note) {
          // Reset everything
          this.note = cloneDeep(this.originalNote);
          this.title = this.note.title;
          this.setEditorData(this.note.note);
          this.attachments = this.note.attachments;
        } else {
          this.deactivate = true;
        }

        modalRef.hide();
        resolve(true);
      });
    });
  }
}
