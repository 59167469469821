import { Faq } from './faq';

export class FaqCategory {
  public title: string;
  public icon: string;
  public items: Array<Faq>;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.icon) {
      this.icon = item.icon.url;
    } else {
      this.icon = '/assets/images/default-faq.png';
    }
    
    this.title = item.title;
    if (item.items) {
      this.items = item.items.map(x => new Faq(x.faq));
    }
    
  }
}
