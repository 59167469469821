import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CareModule } from '../../models/care-module';
import { HealthCareProfessional } from '../../models/health-care-professional';
import { Pathway, PathwayStatus } from '../../models/pathway';
import { HcpService } from '../../services/hcp.service';
import { HospitalService } from '../../services/hospital.service';
import { PathwayService } from '../../services/pathway.service';
import { ScopeService } from '../../services/scope.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-add-pathway-modal',
  templateUrl: './add-pathway-modal.component.html'
})
export class AddPathwayModalComponent implements OnInit {
  public validationVisible = false;
  public isSaving = false;
  public isLoading = false;
  public form: FormGroup = new FormGroup({
    care_module: new FormControl('', Validators.required),
    case_manager: new FormControl('', Validators.required),
    clinical_lead: new FormControl('', Validators.required),
  });
  public care_modules: CareModule[] = [];
  public pathwayList: Pathway[] = []
  public activePathwayUidList: string[] = [];
  public hcpList: Array<HealthCareProfessional> = [];
  public patientUid: string;
  public hospitalUid: string;

  @Output() public pathwayAdded = new EventEmitter<Pathway>();

  searchHcpListEvent = new EventEmitter<{ term: string, items: any[] }>();

  constructor(
    public readonly bsModalRef: BsModalRef,
    private hospitalService: HospitalService,
    private scopeService: ScopeService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private hcpService: HcpService,
    private pathwayService: PathwayService
  ) { }

  ngOnInit(): void {
    this.hospitalUid = this.scopeService.getCurrentHospitalBasic().hospital_uid;
    this.populateActivePathwayUidList(this.pathwayList);
    this.getCareModules();
    this.searchHcpList();

    this.searchHcpListEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(result => {
      this.searchHcpList(result?.term);
    });
  }

  populateActivePathwayUidList(pathwayList: Pathway[]): void {
    this.activePathwayUidList = pathwayList.map((pathway: Pathway) => {
      if (pathway.status === PathwayStatus.ACTIVE) return pathway.care_module.uid
    });
  }

  handleSubmit() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    this.pathwayService.postPathway(
      hospitalUid,
      this.patientUid,
      this.form.get('care_module')?.value,
      this.form.get('clinical_lead')?.value,
      this.form.get('case_manager')?.value
    ).subscribe((result) => {
      this.isSaving = false;
      this.successHandler(result);
      this.onHandleClose();
    }, () => {
      this.isSaving = false;
      this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'), null, {
        disableTimeOut: false,
        timeOut: 4000
      });
    });
  }

  careModuleSelectedInForm(careMod) {
    this.getCareModuleDetails(careMod.uid);
  }

  successHandler(response) {
    this.pathwayAdded.emit(response);
    this.isSaving = false;
    this.toastrService.info(this.translate.instant('modals.add_pathway_modal.success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
    this.bsModalRef.hide();
  }

  getCareModules(showLoader = true) {
    if (showLoader) {
      this.isLoading = true;
    }

    this.hospitalService.getCareModules(this.hospitalUid).subscribe({
      next: (care_modules: CareModule[]) => {
        this.care_modules = care_modules.filter(cm => !this.activePathwayUidList.includes(cm.uid));
        this.isLoading = false;
      }, error: () => this.isLoading = false
    })
  }

  getCareModuleDetails(uid: string) {
    this.hospitalService.getCareModule(this.hospitalUid, uid).subscribe(result => {

      if (result.default_case_manager) {
        if (!this.hcpList.some(hcp => hcp.uid === result.default_case_manager.uid)) {
          this.hcpList.unshift(result.default_case_manager);
        }

        this.form.get('case_manager')?.setValue(result.default_case_manager.uid);
        this.form.get('case_manager')?.updateValueAndValidity();

      } else {
        this.form.get('case_manager').setValue('');
        this.form.get('case_manager')?.updateValueAndValidity();
      }

      if (result.default_clinical_lead) {
        if (!this.hcpList.some(hcp => hcp.uid === result.default_clinical_lead.uid)) {
          this.hcpList.unshift(result.default_clinical_lead);
        }

        this.form.get('clinical_lead')?.setValue(result.default_clinical_lead.uid);
        this.form.get('clinical_lead')?.updateValueAndValidity();

      } else {
        this.form.get('clinical_lead').setValue('');
        this.form.get('clinical_lead')?.updateValueAndValidity();
      }
    });
  }

  onHandleClose(): void {
    this.bsModalRef.hide();
  }

  customSearchFn(_term: string, _item: any) {
    return true; // always return, searching is done at the backend
  }

  searchHcpList(searchTerm = ''): void {
    this.hcpService.getPaged({ last_name: searchTerm || '' }, 'last_name,asc', 0, 50).subscribe(response => {
      this.hcpList = response.items;
    });
  }
}
