<!-- PATHWAY DETAILS -->
<h2>{{ 'modals.add_patient_wizard.check_and_confirm' | translate }}</h2>
<p class="font-weight-bold">{{ 'modals.add_patient_wizard.are_you_sure' | translate }}</p>

<h3>{{ 'modals.add_patient_wizard.pathway_info' | translate }}</h3>
<p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.pathway' | translate }}</p>
<p>{{ selectedCareModule?.translationKey | translate }}</p>

<hr>

<!-- PATIENT DETAILS -->
<h3>{{ 'modals.add_patient_wizard.patient_details' | translate }}</h3>

<p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.email' | translate }}</p>
<p>{{ patient?.email }}</p>

<div class="row">
  <div class="col-6">
    <p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.first_name' | translate }}</p>
    <p>{{ patient?.first_name }}</p>
  </div>

  <div class="col-6">
    <p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.last_name' | translate }}</p>
    <p>{{ patient?.last_name }}</p>
  </div>
</div>

<div class="row">
  <div class="col-6">
    <p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.date_of_birth' | translate }}</p>
    <p>{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }}</p>
  </div>

  <div class="col-6">
    <p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.gender' | translate }}</p>

    <p [ngSwitch]="patient?.gender">
      <span *ngSwitchCase="'MALE'">{{ 'form.labels.male' | translate }}</span>
      <span *ngSwitchCase="'FEMALE'">{{ 'form.labels.female' | translate }}</span>
      <span *ngSwitchCase="'UNKNOWN'">{{ 'form.labels.prefer_not_say' | translate }}</span>
    </p>

  </div>
</div>

<p class="small text-muted mb-0" *ngIf="patient?.electronic_medical_record">{{
  'modals.add_patient_wizard.emr' | translate }}</p>
<p>{{ patient?.electronic_medical_record }}</p>

<hr>

<!-- REGION -->
<h3>{{ 'modals.add_patient_wizard.language_and_region' | translate }}</h3>

<div class="row">
  <div class="col-6">
    <p class="small text-muted mb-0">{{ 'form.labels.country' | translate }}</p>
    <p>{{ country?.label }}</p>
  </div>

  <div class="col-6">
    <p class="small text-muted mb-0">{{ 'form.labels.language' | translate }}</p>
    <p>{{ language?.translationKey | translate }}</p>
  </div>
</div>


<!-- HOSPITAL TEAM -->
<div *ngIf="selectedCareModule?.isClinical">

  <hr>

  <h3>{{ 'modals.add_patient_wizard.hospital_team' | translate }}</h3>

  <div class="row">
    <div class="col-6">
      <p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.clinical_lead' | translate }}</p>
      <p>{{ selectedCareModule?.default_clinical_lead?.getFullName() }}</p>
    </div>

    <div class="col-6">
      <p class="small text-muted mb-0">{{ 'modals.add_patient_wizard.case_manager' | translate }}</p>
      <p>{{ selectedCareModule?.default_case_manager?.getFullName() }}</p>
    </div>
  </div>

  <p class="small text-muted mb-0" *ngIf="selectedCareModule?.default_mdts?.length">
    {{ 'modals.add_patient_wizard.teams' | translate }}
  </p>

  <ul>
    <li *ngFor="let team of selectedCareModule?.default_mdts">
      {{ team.name }}
    </li>
  </ul>

  <p class="small text-muted mb-0" *ngIf="selectedCareModule?.default_hcps?.length">
    {{ 'modals.add_patient_wizard.other_hcps' | translate }}
  </p>

  <p>
    <span *ngFor="let hcp of selectedCareModule?.default_hcps; last as last">
      {{ hcp.getFullName() }}<span *ngIf="!last">, </span>
    </span>
  </p>
</div>
