import { TranslationInterface } from "../interfaces/translation.interface";

export class FormFieldValue {
  value: any;
  value_type: string;
  enum_values: Array<TranslationInterface>;
  min_length: number;
  max_length: number;
  min: number;
  max: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.value = item.value;
    this.value_type = item.value_type;

    if (item.enum_values) {
      this.enum_values = item.enum_values;
    }

    if (item.min_length) {
      this.min_length = item.min_length;
    }

    if (item.max_length) {
      this.max_length = item.max_length;
    }

    if (item.min) {
      this.min = item.min;
    }

    if (item.max) {
      this.max = item.max;
    }
  }
}
