import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MedicalTeam} from '../../models/medical-team';
import {HealthCareProfessional} from '../../models/health-care-professional';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Pathway} from '../../models/pathway';
import {PathwayService} from '../../services/pathway.service';
import {ErrorService} from '../../services/error.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-edit-role-modal',
  templateUrl: './edit-role-modal.component.html',
  styleUrls: ['./edit-role-modal.component.scss']
})
export class EditRoleModalComponent implements OnInit {

  @Input() hcp: HealthCareProfessional;
  @Input() mdt: MedicalTeam;
  @Input() pathway: Pathway;
  @Input() patientUid: string;
  @Output() roleEditedEvent = new EventEmitter<void>();

  form: UntypedFormGroup;
  validationVisible = false;
  isSaving = false;

  selectedRole: Role;
  initialRole: Role;
  rolesList: Role[] = ['unassigned', 'case_manager', 'clinical_lead'];

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly pathwayService: PathwayService,
    private readonly hcpService: HcpService,
    public readonly errorService: ErrorService,
    public readonly toastService: ToastrService,
    private readonly translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.initSelectedRole();
  }

  private initSelectedRole(): void {
    switch (this.hcp.uid) {
      case this.pathway.case_manager.uid:
        this.selectedRole = 'case_manager';
        break;
      case this.pathway.clinical_lead.uid:
        this.selectedRole = 'clinical_lead';
        break;
      default:
        this.selectedRole = 'unassigned';
    }
    this.initialRole = this.selectedRole;
  }

  onHandleClose(): void {
    return this.bsModalRef.hide();
  }

  onHandleSave(): void {
    /*
    const patientMdtToSave = new PatientMdt();
    patientMdtToSave.hcps = this.pathway.patient_mdt.hcps;
    patientMdtToSave.mdts = this.pathway.patient_mdt.mdts;
    */

    let clinical_lead_uid = this.pathway.clinical_lead.uid;
    let case_manager_uid = this.pathway.case_manager.uid;

    if (this.selectedRole === 'case_manager') {
      case_manager_uid = this.hcp.uid;
    } else if (this.selectedRole === 'clinical_lead') {
      clinical_lead_uid = this.hcp.uid;
    } else if (this.selectedRole === 'unassigned') {

      if (this.initialRole === 'case_manager') {
        case_manager_uid = null;
      } else if (this.initialRole === 'clinical_lead') {
        clinical_lead_uid = null;
      }
    }

    // if role was case manager, and is then changed => patientMdt.case_manager field needs to be cleared
    /*

    */

    /*const pathwayToSave = new Pathway();
    pathwayToSave.uid = this.pathway.uid;
    pathwayToSave.patient_mdt = patientMdtToSave;
    */



    this.isSaving = true;
    this.pathwayService.putPathway(this.hcpService.getCurrentStoredHospitalUid(), this.patientUid, this.pathway.uid, clinical_lead_uid, case_manager_uid).subscribe(() => {
      this.isSaving = false;
      this.showSuccessToast();
      this.roleEditedEvent.emit();
      this.onHandleClose();
    }, () => {
      this.isSaving = false;
      this.showErrorToast();
    });
  }

  showSuccessToast(): void {
    this.toastService.info(this.translateService.instant('modals.edit_role.edit_role_success_notification'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  showErrorToast(): void {
    this.errorService.showGeneralBackendErrorToast();
  }
}

type Role = 'unassigned'| 'case_manager' | 'clinical_lead';
