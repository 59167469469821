<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back-filters"></div>

  <div class="layout-header header-back-filters">
    <div class="header-content">

      <div class="container">
        <app-breadcrumb></app-breadcrumb>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <h1>{{ 'pages.default.notes.notes' | translate }}</h1>
          </div>
          <div class="col col-auto">
            <a href="" class="btn btn-sm btn-secondary m-start-1" id="admin-hcps-add-hcp"
              (click)="createNewNote($event)">{{ 'pages.default.notes.add_note' | translate }}</a>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">
              <app-select
                id="hcpNameSearch"
                [placeholder]="('pages.default.notes.hcp' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="false"
                [items]="hcps"
                [searchFn]="customSearchFn"
                [bindValue]="'last_name'"
                (open)="getHCPs()"
                [loading]="isLoadingHCPs"
                [(ngModel)]="filters.hcp_name"

                (ngModelChange)="onFilterChange()"
                (search)="hcpNameSearch.next($event)"

                [asFilter]="true"
                [showUserDetails]="true"
                [showCheckboxes]="false"
              ></app-select>

              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info py-0" id="admin-hcps-reset-filters" *ngIf="filtersAreSet" (click)="resetFilters()">{{ 'action.reset' | translate }}</button>
            </div>

          </div>

          <div class="col col-auto">
            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sorting == 'changed_at,asc'">{{ 'form.labels.date_edited_sort_asc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'changed_at,desc'">{{ 'form.labels.date_edited_sort_desc' | translate }}&#x200E;</span>

                <span [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'changed_at', 'asc')">{{ 'form.labels.date_edited_sort_asc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'changed_at', 'desc')">{{ 'form.labels.date_edited_sort_desc' | translate }}&#x200E; </a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div class="row">
        <div class="col-6 mb-2" *ngFor="let note of notes">
          <div class="card h-100">
            <div class="card-body card-body">
              <div class="row text-muted small justify-content-between">
                <div class="col-auto">
                  <p class="mb-1">{{ note?.changed_at | timeZoneDate}}</p>
                </div>
                <!--
                <div class="col-auto">
                  <p class="mb-1"><span class="icon xs rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/attachment.svg'"></span><span class="align-middle">3</span></p>
                </div>
                -->
              </div>

              <h3>{{ note?.title }}</h3>

              <div class="user-compact mb-2">
                <app-avatar [profilePicture]="note?.created_by?.profile_picture"></app-avatar>

                <div class="user-compact-info">
                  <p class="title text-truncate">{{ note?.created_by?.getFullName() }}</p>
                  <p>{{ note?.created_by?.job_title }}</p>
                </div>
              </div>

              <!-- IN CASE OF VALID CONTENT PROVIDED => -->
              <p class="m-0">{{ note?.note_preview }}</p>

              <!-- IN CASE OF NO CONTENT PROVIDED => -->
              <!-- <p class="m-0 text-muted small">{{ 'pages.default.notes.no_contend_provided' | translate }}</p> -->

            </div>
            <div class="card-footer">
              <div class="m-end-auto"></div>

              <div class="btn-group" dropdown placement="top right" *ngIf="note?.created_by?.uid == currentHcpUid">
                <button id="button_note_dropdown" dropdownToggle type="button" class="dropdown-toggle btn btn-settings" aria-controls="note_dropdown">
                  <span [inlineSVG]="'/assets/svg/more.svg'"></span>
                </button>
                <ul id="note_dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button_note_dropdown">
                  <li role="menuitem">
                    <a class="dropdown-item" href="" [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/notes/' + note.uid + '/edit']">{{ 'pages.default.patient_detail.edit_note' | translate }}</a>
                    <a class="dropdown-item text-danger" href="" (click)="deleteNote($event, note?.uid)">{{ 'pages.default.patient_detail.delete_note' | translate }}</a>
                  </li>
                </ul>
              </div>

              <a href="" class="btn btn-secondary" [routerLink]="['/patient/' + patientUid + '/' + patientPathwayUid + '/notes/' + note.uid + '/view']">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
