import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { AppointmentModalComponent } from "../../modals/appointment-modal/appointment-modal.component";
import { ConversationModalComponent } from "../../modals/conversation-modal/conversation-modal.component";
import { UserTaskAppointmentModalComponent } from "../../modals/user-task-appointment-modal/user-task-appointment-modal.component";
import { UserTaskStandardModalComponent } from "../../modals/user-task-standard-modal/user-task-standard-modal.component";
import { DateFormat } from "../../models/date-format";
import { Pathway } from "../../models/pathway";
import { Patient } from "../../models/patient";
import { Reminder } from "../../models/reminder";
import { UserTaskBasic } from "../../models/user-task-basic";
import { AppointmentService } from "../../services/appointment.service";
import { GeneralService } from "../../services/general.service";
import { LocaleService } from "../../services/locale.service";
import { UserTaskService } from "../../services/user-task.service";

@Component({
  selector: 'app-patient-detail-reminders',
  templateUrl: './patient-detail-reminders.component.html'
})
export class PatientDetailRemindersComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() pathway: Pathway;
  @Input() tasks: UserTaskBasic[];
  @Output() changed: EventEmitter<string> = new EventEmitter();

  loading: boolean;
  time_24_hours: boolean;
  dateFormat: DateFormat;
  reminders: Reminder[];

  constructor(
    public userTaskService: UserTaskService,
    public  modalService: BsModalService,
    public appointmentService: AppointmentService,
    public localeService: LocaleService,
    public router: Router
  ) {}

  ngOnInit(): void {
    const preferences = this.localeService.getLocalePreferences();
    this.time_24_hours = preferences.locale.time_24_hours;
    this.dateFormat = preferences.dateFormat;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['pathway']) {
      this.reminders = this.pathway?.reminders;
      this.reminders = this.sortReminders(this.reminders);
    }
  }

  sortReminders(items): Reminder[] {
    return items.sort((a, b) => {
      if ( a.type < b.type ){
        return 1;
      }
      if ( a.type > b.type){
        return -1;
      }

      if(a.type === b.type && a.type == 'message')  {
        if ( a.messageSubject > b.messageSubject ){
          return 1;
        }
        if ( a.messageSubject < b.messageSubject){
          return -1;
        }
        return 0;
      }

      if(a.type === b.type && a.type == 'appointment')  {
        let dateA = a.start_date || a.date;
        let dateB = b.start_date || b.date;

        if ( dateA < dateB ){
          return -1;
        }
        if ( dateA > dateB){
          return 1;
        }
        return 0;
      }

      return 0;
    })
  }

  openReminder(reminder: Reminder | UserTaskBasic) {
    if(reminder.className === 'UserTaskBasic') {
        this.openUserTask(reminder.uid, reminder.type);
    } else {
      if (reminder.type === 'appointment') {
        this.openAppointment(reminder.uid);
      }

      if (reminder.type === 'message') {
        this.openConversation(reminder.uid);
      }
    }
  }


  openAppointment(appointmentUid: string) {
    this.appointmentService.getAppointment(this.patient.uid, appointmentUid).subscribe(result => {
      const initialState = {
        appointment: result,
        dateFormat: this.dateFormat,
        time_24_hours: this.time_24_hours
      };

      const modalref = this.modalService.show(AppointmentModalComponent,
        GeneralService.BsModalOptions({
          class: 'modal-dialog-centered modal-xl',
          initialState
        })
      );
    });
  }

  openConversation(id) {
    const initialState = {
      conversationUid: id
    };
    const modal = this.modalService.show(ConversationModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-lg modal-compact',
        initialState
      })
    );

    modal.content?.onAllEntriesRead.subscribe(() => {
      this.changed.emit();
    })
  }

  openUserTask(id, type) {
    const userTaskBasic = new UserTaskBasic({ uid: id });

    switch (type) {
      case 'APPOINTMENT':
        this.showUserTaskAppointmentModal(userTaskBasic);
        break;
      case 'STANDARD':
        this.showUserTaskStandardModal(userTaskBasic);
        break;
      case 'CHECKLIST':
        this.router.navigate(['/patient/' + this.patient?.uid + '/' + this.pathway?.uid + '/' + '/query-lists/' + id], { queryParams: { type: type, filter: type } })
        break;
      case 'QUESTIONNAIRE':
        this.router.navigate(['/patient/' + this.patient?.uid + '/' + this.pathway?.uid + '/' + '/query-lists/' + id], { queryParams: { type: type, filter: type } })
        break;
    }
  }

  showUserTaskAppointmentModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic,
      task_id: userTaskBasic.uid,

    };
    const modal = this.modalService.show(UserTaskAppointmentModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-xl',
        initialState
      })
    );

    modal?.content?.taskSubmitSuccess.subscribe(result => {
      this.changed.emit();
    });

  }

  showUserTaskStandardModal(userTaskBasic: UserTaskBasic) {
    const initialState = {
      userTaskBasic
    };
    const modal = this.modalService.show(UserTaskStandardModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-xl modal-compact',
        initialState
      })
    );

    modal?.content?.taskSubmitSuccess.subscribe(result => {
      this.changed.emit();
    });

  }
}
