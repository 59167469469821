import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MdtService } from '../../../../services/mdt.service';
import { MedicalTeam } from '../../../../models/medical-team';
import { HcpFunction } from '../../../../models/hcp-function';
import { HcpService } from '../../../../services/hcp.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../../../services/general.service';
import { AddMdtModalComponent } from '../../../../modals/add-mdt-modal/add-mdt-modal.component';
import { EditMdtModalComponent } from '../../../../modals/edit-mdt-modal/edit-mdt-modal.component';
import { LanguageService } from '../../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-admin-mdts',
  templateUrl: './admin-mdts.component.html',
  styleUrls: ['./admin-mdts.component.scss']
})
export class AdminMdtsComponent implements OnInit {
  hospitalUid: string;
  mdts: MedicalTeam[] = [];
  functions: HcpFunction[] = [];

  filtersAreSet: boolean;
  filters: any = {
    name: null,
    functionKeys: null,
    hcpLastName: null
  };
  functionFilter: HcpFunction[];
  sortDirectionAsc = true;

  isLoading: boolean;

  public currentPage = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize = 10;

  searchMdtNameEvent = new Subject<string>();
  searchHcpLastNameEvent = new Subject<string>();

  constructor(
    public router: Router,
    public modalService: ModalService,
    public mdtService: MdtService,
    public hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService
  ) {
    // Debounce mdt name search
    this.searchMdtNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.name = value;
        this.currentPage = 1;
        this.filtersAreSet = true;
        this.getMdts();
      });
    this.searchHcpLastNameEvent.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.filters.hcpLastName = value;
        this.currentPage = 1;
        this.filtersAreSet = true;
        this.getMdts();
      });
  }

  ngOnInit(): void {
    this.getScope();
    this.getAllFunctions();
    this.clearFiltersAndGetMdts();
  }

  private getScope(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
  }

  // run only after getScope()
  private getAllFunctions(): void {
    this.hcpService.getFunctions(this.hospitalUid).subscribe((result: HcpFunction[]) => {
      this.functions = result;
    }, () => {

    });
  }

  private clearFiltersAndGetMdts(): void {
    this.filters = {
      name: null,
      functionKeys: null,
      hcpLastName: null
    };
    this.functionFilter = null;
    this.sortDirectionAsc = true;
    this.filtersAreSet = false;
    this.getMdts();
  }

  private getMdts(showLoading = true): void {
    if (showLoading) {
      this.mdts = [];
      this.isLoading = true;
    }

    const sortingString = this.sortDirectionAsc ? 'name,asc' : 'name,desc';
    this.mdtService.getPaged(this.filters, sortingString, this.currentPage - 1, this.currentPageSize).subscribe(result => {
      this.isLoading = false;
      this.mdts = result.items;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }

      if (this.totalPages && this.currentPage && this.totalPages < this.currentPage) {
        this.currentPage = 1;
        this.getMdts();
      }
    }, () => {
      this.isLoading = false;

    });
  }

  onPaginationPageChanged(page: number): void {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getMdts();
    }
  }

  onPaginationRangeChanged(pageSize: number): void {
    if (pageSize !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = pageSize;
      this.getMdts();
    }
  }

  onChangeSortDirectionAsc(event, isAsc: boolean): void {
    event.preventDefault();
    this.sortDirectionAsc = isAsc;
    this.filtersAreSet = true;
    this.getMdts();
  }

  onFunctionFilterChange(hcpFunctions: HcpFunction[]): void {
    this.filtersAreSet = true;
    this.currentPage = 1;

    if (hcpFunctions && hcpFunctions.length) {
      this.filters.functionKeys = [];
      hcpFunctions.forEach(hcpFunc => {
        this.filters.functionKeys.push(hcpFunc.key);
      });
    } else {
      this.filters.functionKeys = null;
    }

    this.getMdts();
  }

  onResetFilters(): void {
    this.clearFiltersAndGetMdts();
  }

  onShowAddMdtModal(event): void {
    event.preventDefault();

    const modalRef = this.modalService.showWithInterceptor(AddMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg'
      }));

    modalRef.content.mdtCreatedEvent.subscribe(() => {
      this.getMdts(false);
    });
  }

  onShowEditMdtModal(event, mdt: MedicalTeam): void {
    event.preventDefault();

    const modalRef = this.modalService.showWithInterceptor(EditMdtModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg',
        initialState: { mdt }
      }));

    modalRef.content.mdtEditedEvent.subscribe(() => {
      this.getMdts(false);
    });
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + " " + translatedNumber;
    }

    return translatedNumber + " " + itemsLabel;
  }
}
