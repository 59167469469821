<div class="layout-header-pusher header-back"></div>

<div class="layout-header header-back">
  <div class="header-content">
    <div class="container">
      <p>
        <a class="" [routerLink]="['/settings']" class="text-secondary font-weight-bold d-flex align-items-center" id="settings-consent-overview-back">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.settings.consents_overview.privacy_legal' | translate }}</h1>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="!consentsLoaded">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="consentsLoaded">
    <button (click)="goToConsent(consent)"
         *ngFor="let consent of consents; let i = index"
         class="btn w-100 p-0 text-align-start single-item-block mb-2 item-clickable"
         id="consent-{{consent?.type}}-{{i}}">
      <div class="block-wrapper p-start-3 p-end-1">
        <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="consent?.icon()"></span>
      </div>
      <div class="block-wrapper">
        <p class="label">{{ consent.title }}</p>
        <p *ngIf="consent.isConsented()" class="value">
          {{ 'pages.default.settings.consents_overview.consented_on' | translate }} {{ consent.status_at | timeZoneDate:dateFormat.format }}
        </p>
      </div>
      <div class="block-action m-start-auto" id="settings-consent-overview-consent-arrow-{{i}}">
        <span class="rtl-mirrored-inline-svg"
              id="consentArrow-{{i}}"
              [inlineSVG]="'/assets/svg/right-arrow-big.svg'">
        </span>
      </div>
    </button>
  </div>
</div>
