import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Patient } from '../../../../models/patient';

@Component({
  selector: 'app-patient-step',
  templateUrl: './patient-step.component.html'
})
export class PatientStepComponent implements OnInit {
  @Output() patientValid = new EventEmitter<boolean>();

  @Input() patient: Patient;
  @Output() patientChange = new EventEmitter<Patient>();

  public patientForm: UntypedFormGroup;
  public nameMaxLength: number = 50;
  public validationVisible: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.patientFormSetup();
  }

  patientFormSetup(): void {
    if (this.patientForm) return;

    if (!this.patient) this.patient = new Patient();

    this.patientForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.patient.email, [Validators.required, Validators.email]),
      first_name: new UntypedFormControl(this.patient.first_name, [Validators.required, Validators.maxLength(this.nameMaxLength), Validators.pattern(/^\D+$/)]),
      last_name: new UntypedFormControl(this.patient.last_name, [Validators.required, Validators.maxLength(this.nameMaxLength), Validators.pattern(/^\D+$/)]),
      date_of_birth: new UntypedFormControl(this.patient.date_of_birth, [Validators.required]),
      gender: new UntypedFormControl(this.patient.gender, [Validators.required]),
      electronic_medical_record: new UntypedFormControl(this.patient.electronic_medical_record),
    });

    this.patientForm.statusChanges.subscribe(status => {
      this.patient = new Patient(this.patientForm.value)
      this.patientChange.emit(this.patient);

      this.patientValid.emit(this.patientForm.valid);

      if (status === 'INVALID' && this.allRequiredIsDirty()) {
        this.validationVisible = true;
      } else {
        this.validationVisible = false;
      }
    });
  }

  allRequiredIsDirty(): boolean {
    const emailDirty = this.patientForm.get('email').dirty;
    const firstNameDirty = this.patientForm.get('first_name').dirty;
    const lastNameDirty = this.patientForm.get('last_name').dirty;
    const dOBDirty = this.patientForm.get('date_of_birth').dirty;
    const genderDirty = this.patientForm.get('gender').dirty;

    return emailDirty && firstNameDirty && lastNameDirty && dOBDirty && genderDirty;
  }
}
