interface PermissionHcp {
  uid: string,
  first_name: string,
  last_name: string,
  departments: string[],
  functions: string[],
  permissions: string[]
}

export interface PermissionHospital {
  hcp: PermissionHcp,
  hospital_uid: string,
  name: string,
  site: any
}

export class Permissions {
  public hospitals: PermissionHospital[];

  constructor(item?) {
    if (!item) {
      return;
    }

    if(item.hospitals) {
      this.hospitals = item.hospitals;
    } else {
      this.hospitals = [];
    }
  }

  getHospital(uid: string): PermissionHospital {
    const found = this.hospitals.filter(item => {
      return String(item.hospital_uid) === String(uid);
    });

    if(found && found.length) {
      return found[0];
    } else {
      return undefined;
    }
  }

  getHcp(uid: string): PermissionHcp {
    let found = undefined;

    this.hospitals.forEach(hospitalData => {
      if(hospitalData.hcp && (String(hospitalData.hcp.uid) === String(uid))) {
        found = hospitalData.hcp;
      }
    });

    return found;
  }

  doesHcpHasPermission(uid: string, rule) {
    const hcp = this.getHcp(uid);

    if(!hcp || !hcp.permissions) {
      return false;
    }

    return hcp.permissions.includes(rule);
  }
}
