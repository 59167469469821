import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from "ngx-bootstrap/modal";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Appointment } from "../../models/appointment";
import { Patient } from "../../models/patient";
import { UserTask } from "../../models/user-task";
import { AppointmentService } from '../../services/appointment.service';
import { GeneralService } from '../../services/general.service';
import { LocaleService } from '../../services/locale.service';
import { UserTaskService } from '../../services/user-task.service';
import { HcpService } from '../../services/hcp.service';
import { Invitee } from '../../models/invitee';

@Component({
  selector: 'app-surgery-date-modal',
  templateUrl: './surgery-date-modal.component.html',
  styleUrls: ['./surgery-date-modal.component.scss']
})
export class SurgeryDateModalComponent implements OnInit {
  @Output() onSurgeryDateUpdated = new EventEmitter<void>();

  public isSaving: boolean;
  public form: UntypedFormGroup;
  public formSet: Boolean;
  public formInitialized: Boolean = false;
  public patient: Patient;
  public task: UserTask;
  public appointment: Appointment;

  constructor(
    public bsModalRef: BsModalRef,
    public bsDatepickerConfig: BsDatepickerConfig,
    public formBuilder: UntypedFormBuilder,
    public appointmentService: AppointmentService,
    public toastrService: ToastrService,
    public translateService: TranslateService,
    public taskService: UserTaskService,
    public hcpService: HcpService,
    public localeService: LocaleService,
    public generalService: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.bsDatepickerConfig.dateInputFormat = this.localeService.getBsDatePickerInputFormat();
    this.bsDatepickerConfig.adaptivePosition = true;

    this.formSet = (this.task == null) ? false : true;
    this.formSetup();
  }

  formSetup(): void {
    if (this.form) {
      return;
    }

    let date: any = '';

    if (this.formSet) {
      this.taskService.getUserTask(this.task.uid, this.task.assignee.uid).subscribe(result => {
        this.task = result;
        this.formInitialized = true;
      });
    } else {
      date = new Date(this.appointment?.date || this.appointment?.start_date);
      this.formInitialized = true;
    }

    this.form = this.formBuilder.group({
      surgeryDate: [date, [Validators.required]]
    });
  }

  handleSave(): void {
    this.isSaving = true;

    if (this.formSet) {
      this.updateTask();
    } else {
      this.updateAppointment();
    }
  }

  handleCancel(): void {
    this.handleClose();
  }

  handleClose(): void {
    this.bsModalRef.hide();
  }

  updateTask(): void {
    const hcp = this.task.assignee.uid;
    const key = this.task.form_fields.find(f => f.id = 'title_key').field_value.value;

    const payload: any = {
      title_key: key,
      hcp_uids: [hcp],
      date: this.getSurgeryDate()
    };

    this.taskService.completeUserTask(this.task.uid, hcp, { variables: payload }).subscribe(result => {
      this.updateResult();
    });
  }

  updateAppointment(): void {
    this.appointmentService.updateAppointment(this.appointment.uid, this.patient.uid, this.appointmentPayload()).subscribe(() => {
      this.updateResult();
    });
  }

  appointmentPayload(): any {
    const payload: any = this.appointment;

    payload.date = this.getSurgeryDate();
    payload.hcp_uids = this.appointment.invitees.map(i => i.uid)

    return payload;
  }

  updateResult(): void {
    this.isSaving = false;
    this.onSurgeryDateUpdated.emit();
    this.handleClose();

    this.toastrService.info(this.translateService.instant('modals.surgery_date.success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });
  }

  getSurgeryDate(): string {
    return this.generalService.dateToString(this.form.get('surgeryDate').value);
  }
}
