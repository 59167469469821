import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Kpi } from '../../models/kpi';
import { PhaseDna } from '../../models/phase-dna';
import { AnalyticsService } from '../../services/analytics.service';
import { HcpService } from '../../services/hcp.service';

@Component({
  selector: 'app-dna-kpis',
  templateUrl: './dna-kpis.component.html'
})
export class DnaKpisComponent implements OnInit {
  @Input() phase: PhaseDna;
  @Input() careModuleUid: string;

  public kpis: Kpi[];
  public isLoading: boolean = false;

  constructor(
    public analyticsService: AnalyticsService,
    public hcpService: HcpService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['careModuleUid']?.currentValue || this.careModuleUid) {
      this.loadKpis();
    }
  }

  loadKpis() {
    this.isLoading = true;
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.analyticsService.kpis(hospitalUid, hcpUid, this.careModuleUid, this.phase?.uid).subscribe(result => {

      // Filter completed an time to complete kpi AGIK-5694
      this.kpis = result.filter((kpi: Kpi) => !['bltf769132d6ea2563c', 'bltc63c43faffb4aaf2'].includes(kpi.key));

      this.isLoading = false;
    }, () => this.isLoading = false);
  }
}
