<div class="body-content">
  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoading">
    <div class="empty-state my-5 my-lg-7 my-xl-9" *ngIf="!appointments || !appointments?.length">
      <span [inlineSVG]="'/assets/svg-color/calendar.svg'"></span>
      <h3>{{ 'pages.default.calendar.no_appointments_yet' | translate }}</h3>
    </div>

    <div class="calendar-list" *ngIf="appointments?.length > 0">
      <ng-container *ngFor="let appointment of appointments; let i = index;">
        <div *ngIf="(appointment.status_of_user !== 'REMOVED') && (!appointment.hasRemovedState())">
          <div class="heading" *ngIf="showHeading(i, appointment, appointments[i-1])">
            {{ ((appointment?.start_date)? appointment?.start_date : appointment?.date) | timeZoneDate:'MMMM' }}
          </div>

          <button id="item-{{ appointment.uid }}" class="item clickable btn w-100 text-align-start p-0" (click)="selectAppointment($event, appointment)"
            [ngClass]="{ 'cancelled': appointment.hasCancelledState() }">

            <div class="date">
              <p *ngIf="appointment.start_date">{{ appointment.start_date | timeZoneDate }}</p>
              <p *ngIf="appointment.date">{{ appointment.date | timeZoneDate }}</p>
            </div>

            <div class="time" [ngClass]="{'dir-ltr': languageService.isHebrew}">
              <p *ngIf="appointment?.start_date && appointment?.end_date">
                {{ appointment?.start_date | time }} - {{appointment?.end_date | time }}
              </p>
              <p class="font-weight-bold" [ngClass]="(appointment.hasCancelledState() ? 'text-muted' : 'text-info')" *ngIf="appointment?.date">
                {{ 'pages.default.calendar.full_day' | translate }}
              </p>
            </div>

            <div class="text-value sized-2">
              <p><strong>{{ appointment.patient_invitee?.getFullName() }}</strong></p>
            </div>

            <div class="text-value sized-3">
              <p>{{ appointment.title }}</p>
            </div>

            <div class="icon">
              <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
              <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
            </div>

            <div *ngIf="appointment.hasNoneState()" class="highlight"></div>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
