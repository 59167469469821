import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AttentionCenter } from '../models/attention-center';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { HcpService } from './hcp.service';
import { UserTaskService } from './user-task.service';

@Injectable({
  providedIn: 'root'
})
export class AttentionCenterService extends ApiService {
  public ac: AttentionCenter;

  @Output() onInitData: EventEmitter<any> = new EventEmitter();

  @Output() onNewConversationMessagesUnread: EventEmitter<any> = new EventEmitter();
  @Output() onNewAppointmentsPending: EventEmitter<any> = new EventEmitter();
  @Output() onNewselfRegisteredPatientsPending: EventEmitter<void> = new EventEmitter();

  @Output() onNewUserTasksPending: EventEmitter<any> = new EventEmitter();
  @Output() onNewPatientPathwayMessagesUnread: EventEmitter<any> = new EventEmitter();
  @Output() onNewTask: EventEmitter<any> = new EventEmitter(); // combination of the both


  constructor(
    http: HttpClient,
    public authenticationService: AuthenticationService,
    public hcpService: HcpService

  ) {
    super(http, authenticationService);
  }

  get(): Observable<AttentionCenter> {
    return new Observable(observer => {
      const paramBits = [];

      paramBits.push(`user_task_min_priority=${UserTaskService.OVERVIEW_MIN_PRIORITY}`);
      paramBits.push(`user_task_max_priority=${UserTaskService.OVERVIEW_MAX_PRIORITY}`);

      const paramsString = paramBits.join('&');
      const url = `${environment.platformUrl}/user/attention-center?${paramsString}`;

      this.authenticatedGet(url).subscribe(result => {

        /*
        ** First time?
        */
        if(!this.ac) {
          this.ac = new AttentionCenter(this.findHcp(result));
          this.onInitData.emit();
        /*
        ** In case of updating
        */
        } else {
          const original: AttentionCenter = Object.assign({}, this.ac) as AttentionCenter;
          this.ac.fillFromJson(this.findHcp(result));

          //
          // Evaluate the conversationMessagesUnread
          //
          if(this.ac.conversationMessagesUnread !== original.conversationMessagesUnread) {
            this.onNewConversationMessagesUnread.emit();
          }

          //
          // Evaluate the userTasksPending
          //
          if(this.ac.userTasksPending !== original.userTasksPending) {
            this.onNewUserTasksPending.emit();
            this.onNewTask.emit(); // executed on both
          }

          //
          // Evaluate the patientPathwayMessagesUnread
          //
          if(this.ac.patientPathwayMessagesUnread !== original.patientPathwayMessagesUnread) {
            this.onNewPatientPathwayMessagesUnread.emit();
            this.onNewTask.emit(); // executed on both
          }

          //
          // Evaluate the appointmentsPending
          //
          if(this.ac.appointmentsPending !== original.appointmentsPending) {
            this.onNewAppointmentsPending.emit();
          }

          //
          // Evaluate the selfRegisteredPatientsPending
          //
          if(this.ac.selfRegisteredPatientsPending !== original.selfRegisteredPatientsPending) {
            this.onNewselfRegisteredPatientsPending.emit();
          }
        }

        observer.next(this.ac);
      });
    });
  }

  refresh() {
    this.get().subscribe(() => {});
  }

  findHcp(result):any {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    const hcpUid = this.hcpService.getCurrentStoredHcpUid();
    const hscps = result['hcps'] || [];

    return hscps.find(_hcp => {
      return (_hcp.hcp_uid === hcpUid && _hcp.hospital_uid === hospitalUid);
    });
  }
}
