import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CareModule, CareModuleType } from '../../../../models/care-module';
import { AttentionCenterService } from '../../../../services/attention-center.service';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';

@Component({
  selector: 'app-admin-overview',
  templateUrl: './admin-overview.component.html',
  styleUrls: ['./admin-overview.component.scss']
})
export class AdminOverviewComponent implements OnInit {
  public hasClinicalCareModules: boolean = false;
  public isLoading: boolean = false;

  public attentionCenterInitSubscription: Subscription;
  public attentionCenterRegisteredPatientsPendingSubscription: Subscription;

  public pendingRequests: number = 0;

  constructor(
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public attentionCenterService: AttentionCenterService
  ) { }

  ngOnInit(): void {
    this.getCareModules();
    this.connectAttentioncenter();
  }

  getCareModules(): void {
    this.isLoading = true;

    this.hospitalService.getCareModules(this.hcpService.getCurrentStoredHospitalUid()).subscribe((careModules: CareModule[]) => {
      this.successHandler(careModules)
    }, () => this.isLoading = false);
  }

  successHandler(careModules: CareModule[]): void {
    if (careModules.some((careModule: CareModule) => careModule.type === CareModuleType.CLINICAL)) {
      this.hasClinicalCareModules = true;
    }

    this.isLoading = false;
  }

  connectAttentioncenter() {
    this.fillAttentionCenter();

    this.attentionCenterInitSubscription = this.attentionCenterService.onInitData.subscribe(() => this.fillAttentionCenter());
    this.attentionCenterRegisteredPatientsPendingSubscription = this.attentionCenterService.onNewselfRegisteredPatientsPending.subscribe(() => this.fillAttentionCenter());
  }

  fillAttentionCenter() {
    this.pendingRequests = this.attentionCenterService.ac?.selfRegisteredPatientsPending;
  }

  disconnectAttentioncenter() {
    this.attentionCenterInitSubscription?.unsubscribe();
    this.attentionCenterRegisteredPatientsPendingSubscription?.unsubscribe();

  }

}
