import {Component, OnInit} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {RestrictProcessingDataModalComponent} from "../../../../modals/restrict-processing-data-modal/restrict-processing-data-modal.component";
import {ExportPersonalDataModalComponent} from "../../../../modals/export-personal-data-modal/export-personal-data-modal.component";
import {DeleteAccountModalComponent} from "../../../../modals/delete-account-modal/delete-account-modal.component";
import { GeneralService } from "../../../../services/general.service";

@Component({
  selector: 'app-settings-personal-data',
  templateUrl: './settings-personal-data.component.html',
  styleUrls: ['./settings-personal-data.component.scss']
})
export class SettingsPersonalDataComponent implements OnInit {

  constructor(public modalService: BsModalService) {
  }

  ngOnInit() {
  }

  showExportPersonalDataModal(event: any) {
    event.preventDefault();

    this.modalService.show(ExportPersonalDataModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  showRestrictProcessingDataModal(event: any) {
    event.preventDefault();

    this.modalService.show(RestrictProcessingDataModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered'
      })
    );
  }

  showDeleteAccountModal(event: any) {
    event.preventDefault();

    this.modalService.show(DeleteAccountModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-dialog-centered modal-lg'
      })
    );
  }
}
