import { Component, Input, OnInit } from '@angular/core';
import { CareModule } from '../../../../models/care-module';
import { Country } from '../../../../models/country';
import { DateFormat } from '../../../../models/date-format';
import { LanguageCode } from '../../../../models/language-code';
import { Patient } from '../../../../models/patient';
import { LocaleService } from '../../../../services/locale.service';

@Component({
  selector: 'app-overview-step',
  templateUrl: './overview-step.component.html'
})
export class OverviewStepComponent implements OnInit {
  @Input() selectedCareModule: CareModule;
  @Input() patient: Patient;
  @Input() language: LanguageCode;
  @Input() country: Country;

  public dateFormat: DateFormat;

  constructor(
    public localeService: LocaleService
  ) { }

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;
  }

}

