
<div class="container" *ngIf="isLoading">
  <app-page-loader></app-page-loader>
</div>

<div *ngIf="!isLoading">
  <p *ngIf="!phaseData?.length" class="text-muted">{{ 'pages.default.analytics.pathways-overview.no_data_found' | translate }}</p>

  <div class="card bg-white" *ngIf="phaseData?.length > 0">
    <div class="card-body py-3">
      <p class="h3">{{ 'pages.default.analytics.pathways-overview.phases-overview' | translate }}</p>

      <div class="row">
        <div class="col-6">
          <div class="highcharts-wrapper" [chart]="pieChart"></div>
          <div id="legend">
            <p *ngFor="let phase of phaseData; let i = index" class="d-block text-truncate mr-3 phase">
              <span class="m-end-1">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" >
                  <rect y="0.5" width="10" height="10" [attr.fill]="colors[i]"/>
                </svg>
              </span>
              <span class="e2e-phase-title">{{ phase.title }}</span>
            </p>
          </div>
        </div>
        <div class="col-6">
          <div *ngFor="let phase of phaseData; let i = index; let last = last;" [ngClass]="{ 'mb-3': !last }">
            <p class="font-weight-bold mb-1 text-truncate">{{ phase.title }}</p>
            <div class="text-white text-center mb-1 e2e-phase-value" [style.background-color]="colors[i]" [style.width]="barPercent(phase.value)" *ngIf="phase.value > 0">{{ phase.value }}</div>
            <div class="text-center mb-1 e2e-phase-value-zero" style="background-color:#EBEBEB; width:100%;" *ngIf="(phase.value === 0)">{{ phase.value }}</div>
            <p *ngIf="phase.has_sub_phases">
              <a href="" class="text-icon-link hoverable text-secondary" (click)="selectPhase($event, phase)">
                <span class="label e2e-phase-details">{{ 'pages.default.analytics.pathways-overview.phase-details' | translate }}</span>
                <span class="icon-end">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
