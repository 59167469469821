import { TranslationInterface } from '../interfaces/translation.interface';
import { Material } from './material';

export class MaterialPhase {
  public name: TranslationInterface;
  public total = 0;
  public completed = 0;
  public educational_materials;
  public phase_id: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    if (item.phase) {
      this.name = item.phase.name;
      this.phase_id = item.phase.id;
    }

    this.total = item.total;
    if (item.completed) {
      this.completed = item.completed.individual;
    }

    this.educational_materials = [];

    // load educational material for the highest level
    item.educational_materials.forEach(result => {
      const material = new Material(result);
      material.phaseName = this.name;
      this.educational_materials.push(material);
    });

    // load educational material for all lower levels
    if(item.sub_phases && item.sub_phases.length) {
      this.addSubPhaseMaterials(item);
    }

    this.educational_materials = this.educational_materials.sort( this.order );

  }

  order( a, b ) {
    if ( a.progress < b.progress ) {
      return -1;
    }
    if ( a.progress > b.progress ) {
      return 1;
    }

    
    if (a.progress === b.progress) {
      if ( a.order < b.order ) {
        return -1;
      }
      if ( a.order > b.order ) {
        return 1;
      }
    }
    return 0;
  }

  percent() {
     return ((this.completed / this.total) * 100).toString() + '%';
  }

  public get translationKey():string {
    return `shared.${this.name?.region}.${this.name?.key}`;
  }

  addSubPhaseMaterials(phase) {
    phase.sub_phases.forEach(subphase => {
      subphase.educational_materials.forEach(result => {
        const material = new Material(result);
        material.phaseName = this.name;
        this.educational_materials.push(material);
      });

      if(subphase.sub_phases && subphase.sub_phases.length) {
        this.addSubPhaseMaterials(subphase);
      }
    });
  }
}
