<div class="panes-view">

  <div class="pane-list">

    <!-- -->
    <!-- List Header Start -->
    <!-- -->
    <div class="layout-header">
      <div class="header-content">
        <div class="title-button">
          <h2>{{ 'pages.default.conversations.my_convos' | translate }}</h2>
          <button class="btn btn-secondary btn-circle" id="conversations-add-new" (click)="showNewConversationModal()">
            <span class="icon" [inlineSVG]="'/assets/svg/add.svg'"></span>
          </button>
        </div>

        <!-- not in mvp
        <div class="form-group styled floating-label mb-0 mt-1">
          <input type="search" id="inputSearch" class="form-control w-100" placeholder="Search messages">
          <label for="inputSearch">Search messages</label>
        </div>
        -->
        <!-- -->
        <!-- Type Filter Start -->
        <!-- -->
        <div class="mb-1" *ngIf="!loadingInitialConversations">
          <div class="btn-group w-100" role="group">
            <button id="tab-all" type="button" class="btn btn-sm"
              [ngClass]="{'btn-primary': !currentConversationsType, 'btn-muted': currentConversationsType}"
              (click)="actionFilterOnType()">{{ 'pages.default.conversations.tab_all' | translate }}</button>
            <button id="tab-patient" type="button" class="btn btn-sm"
              [ngClass]="{'btn-primary': currentConversationsType==='patient', 'btn-muted': currentConversationsType!=='patient'}"
              (click)="actionFilterOnType('patient')">{{ 'pages.default.conversations.tab_patient' | translate
              }}</button>
            <button id="tab-internal" type="button" class="btn btn-sm"
              [ngClass]="{'btn-primary': currentConversationsType==='internal', 'btn-muted': currentConversationsType!=='internal'}"
              (click)="actionFilterOnType('internal')">{{ 'pages.default.conversations.tab_internal' | translate
              }}</button>
          </div>
        </div>
        <!-- -->
        <!-- Type Filter End -->
        <!-- -->
      </div>
    </div>
    <!-- -->
    <!-- List Header End -->
    <!-- -->

    <!-- -->
    <!-- List Loader Start -->
    <!-- -->
    <app-page-loader *ngIf="loadingInitialConversations"></app-page-loader>
    <!-- -->
    <!-- List Loader End -->
    <!-- -->

    <!-- -->
    <!-- List Empty State Start -->
    <!-- -->
    <div class="empty-state pb-4" *ngIf="!loadingInitialConversations && !conversations?.length">
      <p>{{ 'pages.default.conversations.no_convos_yet' | translate }}</p>
    </div>
    <!-- -->
    <!-- List Empty State End -->
    <!-- -->

    <!-- -->
    <!-- List Items Start -->
    <!-- -->
    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll($event)"
      [scrollWindow]="false" class="items" *ngIf="!loadingInitialConversations && conversations?.length">

      <button id="convo-item-{{index}}" class="btn btn-light w-100 text-align-start convo-item p-0"
        (click)="selectConversation(conversation)" *ngFor="let conversation of conversations index as index" [ngClass]="{
          'draft': conversation?.draft,
          'has-unreads': conversation?.unread_messages,
          'selected': currentConversation?.uid == conversation?.uid,
          'convo-with-patient': conversation?.patient,
          'convo-without-patient': !conversation?.patient
        }">
        <div class="p-2 p-start-5">
          <div class="heading">
            <p class="title">{{ conversation?.subject }}</p>
            <p class="date" *ngIf='!conversation?.draft'>{{ conversation?.latest_message_time | simpleDate }}</p>
            <p class="date" *ngIf='conversation?.draft'>{{ conversation?.created_at | simpleDate }}</p>
          </div>
          <div class="content" *ngIf='!conversation?.draft' [ngSwitch]="conversation?.latest_message_type">
            <p *ngSwitchCase="'FILE_MESSAGE'">{{ conversation?.latest_message? conversation?.latest_message :
              'pages.default.conversations.event_file_message' | translate }}</p>
            <p *ngSwitchCase="'AUDIO_MESSAGE'">{{ conversation?.latest_message? conversation?.latest_message :
              'pages.default.conversations.event_audio_message' | translate }}</p>
            <p *ngSwitchCase="'MESSAGE_REMOVED'">{{ 'pages.default.conversations.event_message_removed' | translate }}
            </p>
            <p *ngSwitchCase="'CHANGED_SUBJECT'">{{ 'pages.default.conversations.event_changed_subject' |
              translate:{newValue: conversation?.subject} }}</p>
            <p *ngSwitchCase="'HCP_LEFT'">{{ 'pages.default.conversations.event_hcp_left' | translate }}</p>
            <p *ngSwitchCase="'HCP_JOINED'">{{ 'pages.default.conversations.event_hcp_joined' | translate }}</p>
            <p *ngSwitchCase="'HCP_REMOVED'">{{ 'pages.default.conversations.event_hcp_removed' | translate }}</p>
            <p *ngSwitchDefault>{{ conversation?.latest_message }}</p>
          </div>
          <div class="content" *ngIf='conversation?.draft'>
            <p>{{ 'pages.default.conversations.draft_preview_text' | translate }}</p>
          </div>

          <div class="users" *ngIf="conversation?.activeHcpParticipants()?.length && conversation.patient">
            <div class="avatar">
              <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
            </div>
            <p class="names">
              <span class="text-info font-weight-bold rtl-d-none patient-name">{{ conversation.patientFullName()
                }}</span>
              <span class="rtl-d-none">, </span>
              <span class="clinician-names">{{ conversation.hcpsFullNames() }}</span>
              <span class="d-none rtl-d-inline"> ,</span>
              <span class="text-info font-weight-bold d-none rtl-d-inline patient-name">{{
                conversation.patientFullName()
                }}</span>
            </p>
          </div>

          <div class="users" *ngIf="!conversation?.activeHcpParticipants()?.length && conversation.patient">
            <app-avatar [profilePicture]="conversation?.patient?.profile_picture"></app-avatar>
            <p class="names">
              <span class="text-info font-weight-bold">{{ conversation.patientFullName() }}</span>
            </p>
          </div>

          <div class="users" *ngIf="conversation?.activeHcpParticipants()?.length && !conversation.patient">
            <app-avatar [profilePicture]="conversation?.activeHcpParticipants()[0].profile_picture"
              *ngIf="conversation?.activeHcpParticipants()?.length===1"></app-avatar>

            <div class="avatar" *ngIf="conversation?.activeHcpParticipants()?.length>1">
              <span class="icon" [inlineSVG]="'/assets/svg/community.svg'"></span>
            </div>

            <p class="names">
              <span>{{ conversation.hcpsFullNames() }}</span>
            </p>
          </div>
        </div>
        <hr />
      </button>


      <div class="text-center text-muted py-3" *ngIf="isLoading">
        <em class="spinner-border spinner-border-sm"></em>
      </div>

    </div>
  </div>

  <app-conversation-entries [conversation]="currentConversation" [showEditBtns]="true"></app-conversation-entries>

  <!-- -->
  <!-- Scroll 'End' Position Start -->
  <!-- -->
  <div class="scroll-end-position"></div>
  <!-- -->
  <!-- Scroll 'End' Position End -->
  <!-- -->

</div>
