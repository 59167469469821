<div class="modal-body pb-0" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <button type="button" class="close" id="edit-phone-modal-close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2>{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h2>

    <div *ngIf="(step == 'phone')">
      <div class="row">
        <div class="col-5" [ngClass]="{'order-last': languageService.isHebrew}">
          <div class="form-group required">
            <app-select
              [placeholder]="('form.labels.country_code' | translate)"
              [clearable]="true"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="dialCodeOptions"
              [bindValue]="'value'"

              formControlName="country_code"

              [asFilter]="false"
              [showCheckboxes]="false"
            ></app-select>
            <app-feedback-field [field]="form.get('country_code')"></app-feedback-field>
          </div>
        </div>
        <div class="col-7" [ngClass]="{'order-first': languageService.isHebrew}">
          <div class="form-group styled floating-label">
            <input type="tel" id="inputPhone" class="form-control w-100" [placeholder]="('form.labels.phone_number' | translate)" formControlName="phone">
            <label for="inputPhone" class="required">{{ 'form.labels.phone_number' | translate }}</label>
            <app-feedback-field [field]="form.get('phone')"></app-feedback-field>
          </div>
        </div>
      </div>

      <!--
      <div class="form-group styled floating-label">
        <div class="custom-control custom-checkbox custom-control-inline mb-1">
          <input type="checkbox" class="custom-control-input" id="checkboxNotificationsPhone" formControlName="notifications_method_sms">
          <label class="custom-control-label" for="checkboxNotificationsPhone">{{ 'modals.edit_contact_details.send_notification_number' | translate }}</label>
        </div>
      </div>
      !-->
    </div>

    <div *ngIf="(step === 'code')">
      <p>{{ 'modals.edit_contact_details.enter_code' | translate }}</p>

      <div class="form-group styled floating-label">
        <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_contact_details.verification_code' | translate)" formControlName="code" required>
        <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>

        <p class="validation-feedback general-feedback" *ngIf="form?.controls['code']?.errors?.required">{{ 'form.feedback.field_required' | translate }}</p>

        <p class="mt-1"><a href="" (click)="resendCode($event)">Resend code</a></p>

        <p class="text-muted mt-1">{{ 'modals.edit_contact_details.not_mobile_number' | translate }}</p>

      </div>


    </div>

    <div class="bg-light p-3" *ngIf="(step === 'forget')">
      <h3 class="mb-1">
        <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
        <span class="align-middle">{{ 'modals.edit_contact_details.forget_title' | translate }}</span>
      </h3>
      <p>{{ 'modals.edit_contact_details.forget_description'| translate }}</p>
      <a href="/settings/security" class="text-secondary font-weight-bold" id="edit-phone-modal-forget-link">{{ 'modals.edit_contact_details.forget_link'| translate }}</a>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-phone-modal-cancel" (click)="handleCancel()" *ngIf="(step !== 'forget')">{{ 'action.cancel' | translate }}</button>


    <button class="btn btn-secondary m-start-3" id="edit-phone-modal-submit" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'phone')" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span *ngIf="verificationCodeRequired()">{{ 'action.next_step' | translate }}</span>
      <span *ngIf="!verificationCodeRequired()">{{ 'action.confirm' | translate }}</span>
    </button>

    <button class="btn btn-secondary m-start-3" id="edit-phone-modal-confirm" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'code')" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>

    <button class="btn btn-secondary" id="edit-phone-modal-cancel"  *ngIf="(step === 'forget')" (click)="handleCancel()">
      <span>{{ 'action.close' | translate }}</span>
    </button>
  </div>
</div>
