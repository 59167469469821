<div class="modal-body">
  <button type="button" class="close" id="stop-pathway-confirm-close" data-dismiss="modal" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2>{{ 'modals.stop_pathway_confirm_modal.stop_pathway' | translate }}</h2>

  <p>{{'modals.stop_pathway_confirm_modal.description' | translate}}</p>

  <p class="font-weight-bold">{{'modals.stop_pathway_confirm_modal.about_to_stop_following_pathway' | translate}}</p>

  <div class="bg-light p-2">
    <div class="user-compact mb-2">
      <app-avatar [profilePicture]="patient?.profile_picture"></app-avatar>

      <div class="user-compact-info">
        <p class="title text-truncate">{{ patient?.getFullName() || '...' }}</p>
        <p *ngIf="patient">{{ patient?.date_of_birth | timeZoneDate:dateFormat?.format }} ({{ patient?.age() |
          translateNumber }} {{ 'pages.default.dashboard.yo' | translate }})</p>
      </div>
    </div>

    <div class="pathway">
      <p class="small text-muted mb-0">{{ 'modals.stop_pathway_confirm_modal.pathway' | translate}}</p>
      <p class="text-truncate">{{pathway?.translationKey | translate}}</p>
    </div>

    <hr>

    <div class="reason">
      <p class="small text-muted mb-0">{{ 'modals.stop_pathway_confirm_modal.reason' | translate}}</p>
      <p>{{reason_code?.name}}</p>

      <p class="small text-muted mb-0" *ngIf="reason">{{ 'modals.stop_pathway_confirm_modal.specified_reason' |
        translate}}
      </p>
      <p>{{reason}}</p>
    </div>

  </div>

</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="stop-pathway-confirm-cancel" (click)="onHandleClose()">{{
      'modals.stop_pathway_confirm_modal.go_back' |
      translate}}</button>
    <button class="btn btn-secondary m-start-3" id="stop-pathway-confirm-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'modals.stop_pathway_confirm_modal.stop_pathway_confirm' | translate }}</span>
    </button>
  </div>
</div>
