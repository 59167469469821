import { PatientDnaMeasure } from "./patient-dna-measure";
import { PhaseInstance } from "./phase-instance";

export class PatientDna {
  uid: string;
  first_name: string;
  last_name: string;
  pathway_started_at: string;
  measures: Array<any>;
  phase: PhaseInstance;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.patient_uid;
    this.first_name = item.first_name;
    this.last_name = item.last_name;
    this.pathway_started_at = item.pathway_started_at;
    this.phase = new PhaseInstance(item.phase);
    this.measures = [];

    if (item.measures) {
      item.measures.forEach(element => {
        this.measures.push(new PatientDnaMeasure(element));
      });
    }
  }

  public getFullName() {
    return [this.first_name, this.last_name].join(' ');
  }
}