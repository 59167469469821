import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective implements OnInit {
  private additionalSpacer: number = 20

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener(':keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      // This removes scrollbars on pressing ENTER. Since this creates a new line but doesn't increase scrollheight.
      this.additionalSpacer += 16;
    } else {
      this.additionalSpacer = 20;
    }

    this.resize();
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.elementRef.nativeElement.scrollHeight) this.resize();
    });
  }

  resize() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', 0);
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', (this.elementRef.nativeElement.scrollHeight + this.additionalSpacer) + 'px');
  }
}
