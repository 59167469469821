import { Component, OnInit, Input } from '@angular/core';
import { OnboardingState } from '../../enums/onboarding-state';
import { Consent } from './../../models/consent';

@Component({
  selector: 'app-onboarding-icon-steps',
  templateUrl: './onboarding-icon-steps.component.html'
})
export class OnboardingIconStepsComponent implements OnInit {
  @Input() currentState: OnboardingState;

  @Input() consents: Consent[];
  @Input() currentConsent: Consent;

  public onboardingState = OnboardingState;

  public states = [OnboardingState.CONSENTS, OnboardingState.PASSWORD, OnboardingState.PROFILE];

  constructor() {
  }

  ngOnInit() {
  }

  isStateActiveOrDone(state) {
    if (!state || !this.currentState) {
      return false;
    }

    const stateIndex = this.states.indexOf(state);
    const currentStateIndex = this.states.indexOf(this.currentState);
    return stateIndex <= currentStateIndex;
  }

}
