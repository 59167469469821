<div class="is-loading row" *ngIf="isLoading">
  <app-page-loader class="col-12 align-self-center"></app-page-loader>
</div>

<div *ngIf="!isLoading">
  <p class="font-weight-bold">{{ 'modals.add_patient_wizard.fill_region_details' | translate }}</p>

  <form [formGroup]="regionForm" autocomplete="off">
    <!-- Country -->
    <div class="form-group required">
      <app-select [placeholder]="('form.labels.country' | translate)" [clearable]="false" [searchable]="true"
        [hideSelected]="false" [multiple]="false" [items]="countryOptions" [autoSortOnLabel]="true"
        formControlName="country" [asFilter]="false" [showCheckboxes]="false" autocomplete="off">
      </app-select>
      <app-feedback-field [field]="regionForm.get('country')"></app-feedback-field>
    </div>

    <!-- Language -->
    <div class="form-group required">
      <app-select [placeholder]="('form.labels.language' | translate)" [clearable]="false" [searchable]="true"
        [hideSelected]="false" [multiple]="false" [items]="languageOptions" [autoSortOnLabel]="true"
        [selectedItemsOnTop]="false" formControlName="language" [asFilter]="false" [showCheckboxes]="false">
      </app-select>
      <app-feedback-field [field]="regionForm.get('language')"></app-feedback-field>
    </div>
  </form>
</div>
