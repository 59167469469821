import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PatientDna } from '../../models/patient-dna';
import { PhaseDna } from '../../models/phase-dna';
import { AnalyticsService } from '../../services/analytics.service';
import { HcpService } from '../../services/hcp.service';
import { TranslateNumberPipe } from '../../pipes/translate-number.pipe';
import { LanguageService } from '../../services/language.service';

@Component({
    selector: 'app-dna-patients-table',
    templateUrl: './dna-patients-table.component.html'
})
export class DnaPatientsTableComponent implements OnInit {
    @Input() phase: PhaseDna;
    @Input() careModuleUid: string;

    public patients: PatientDna[] = [];
    public isLoading = false;

    // pagination
    public currentPage = 1;
    public totalPages: number;
    public totalElements: number;
    public currentPageSize = 10;

    public sorting = null; /* 'last_name,asc' */
    public sortDirectionAsc: boolean;


    constructor(
        public analyticsService: AnalyticsService,
        public hcpService: HcpService,
        public translateNumber: TranslateNumberPipe,
        public translateService: TranslateService,
        public languageService: LanguageService
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadPatients();
    }

    loadPatients(showLoading: boolean = true) {
        if (!this.phase || !this.careModuleUid) {
            return;
        }

        this.isLoading = showLoading;

        if (showLoading || !this.patients) {
            this.patients = [];
        }

        const hcpUid = this.hcpService.getCurrentStoredHcpUid();
        const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
        this.analyticsService.patientDetails(hospitalUid, hcpUid, this.careModuleUid, this.phase?.uid, this.sorting, (this.currentPage - 1), this.currentPageSize).subscribe({
            next: result => {
                this.patients = result['items'];

                if (result.pagination) {
                    this.totalPages = result.pagination.total_pages;
                    this.totalElements = result.pagination.total_elements;
                }

                this.isLoading = false;
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }

    onChangeSort(event: MouseEvent, sortKey: string, sortDir: string): void {
        event?.preventDefault();
        this.sorting = `${sortKey},${sortDir}`;
        this.loadPatients(false);
    }

    // pagination
    onPaginationPageChanged(page) {
        if (page !== this.currentPage) {
            this.currentPage = page;
            this.loadPatients();
        }
    }

    onPaginationRangeChanged(range) {
        if (range !== this.currentPageSize) {
            this.currentPage = 1; // also reset the currentPage
            this.currentPageSize = range;
            this.loadPatients();
        }
    }

    getLabelForTotalElements(): string {
        const translatedNumber = this.translateNumber.transform(this.totalElements);
        const itemsLabel = (this.totalElements > 1) ?
            this.translateService.instant('components.pagination.items_total') :
            this.translateService.instant('components.pagination.items_total_singular');

        if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
            return itemsLabel + ' ' + translatedNumber;
        }

        return translatedNumber + ' ' + itemsLabel;
    }
}
