<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <div class="sidebar-logo" [inlineSVG]="'/assets/svg/logo-hcp.svg'"></div>
    </div>

    <div class="content-part">
      <h2>{{ 'pages.flow.locale_registration.confirm_language' | translate }}</h2>

      <div class="form-group required">
        <ng-select id="select_locale_registration_language" [items]="languageOptions" bindValue="value" [loading]="!languageOptions" [placeholder]="('form.labels.preferred_language' | translate)" [clearable]="false" [(ngModel)]="selectedLanguage" (change)="onLanguageSelection()"></ng-select>
      </div>

      <p class="mb-0 text-muted">{{ 'pages.flow.locale_registration.confirm_language_info' | translate }}</p>

      <button id="button_locale_registration_continue" type="submit" class="btn btn-sm btn-secondary mt-3 mt-lg-4" (click)="submit()">{{ 'action.continue' | translate }}</button>
    </div>

    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="body-content">
    <app-flow-welcome></app-flow-welcome>
  </div>
</div>