import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../services/user.service";
import { Profile } from '../../models/profile';
import { AuthenticationService } from '../../services/authentication.service';


@Component({
  selector: 'app-edit-email-modal',
  templateUrl: './edit-email-modal.component.html',
  styleUrls: ['./edit-email-modal.component.scss']
})
export class EditEmailModalComponent implements OnInit {
  @Output() onProfileUpdated: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;
  public profile: Profile;
  public isLoading = false;
  public validationVisible = false;
  public step: "email" | "code" | "forget" = 'email';


  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public userService: UserService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.formSetup();
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      email: [this.profile?.email, [Validators.email, Validators.required]],
      code: ['', [Validators.required]]
    });
  }

  handleCancel() {
    if (this.step === 'forget') {
      this.authenticationService.logout();
    }
    return this.bsModalRef.hide();
  }

  handleSubmit() {
    this.validationVisible = false;
    if(this.step === 'email') {
      // email is not valid
      if (this.form.get('email').errors) {
        this.validationVisible = true;

        return;
      }


      if(this.isEmailChanged()) {
        this.requestEmailVerificationCode(this.form.get('email').value);
      }

      if (!this.isEmailChanged()) {
        this.bsModalRef.hide();
      }
    }

    if(this.step === 'code') {
      if (this.form.get('code').errors) {
        this.validationVisible = true;
        return;
      }

      this.verifyEmailVerificationCode(this.form.get('code').value);
    }
  }

  isEmailChanged():Boolean {
    return (this.form.get('email').value !== this.profile.email);
  }

  requestEmailVerificationCode(email) {
    this.userService.changeEmail(email).subscribe(result => {
      this.step = 'code';
      this.toastrService.info(this.translate.instant('modals.edit_contact_details.verification_code_sent'), null, {
        disableTimeOut: false,
        timeOut: 2000
      });
    }, error => {

      this.onRequestError(error);
    })
  }

  verifyEmailVerificationCode(code) {
    this.isLoading = true;
    this.userService.verifyChangeEmailCode(code).subscribe(result => {

      this.onUpdateSuccess();
    }, error => {
      this.onRequestError(error);
    })
  }

  onUpdateSuccess() {
    this.isLoading = false;
    this.toastrService.info(this.translate.instant('modals.edit_contact_details.successfully_updated'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.onProfileUpdated.emit({email: this.form.get('email').value, notifications_method_email: this.form.value.notifications_method_email})

    if(this.step === "code") {
      this.step = "forget";
    } else {
      this.bsModalRef.hide();
    }
  }

  onRequestError(error) {
    this.isLoading = false;
    const errorArray = error?.error?.errors;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach( err => {
        if (err.field === 'new_email') {
          this.form.get('email').setErrors({
            backend_errors: true,
            message: err.key
          });
        }

        if (err.field === 'verification_code') {
          this.form.get('code').setErrors({
            backend_errors: true,
            message: err.key
          });
        }
      });
    } else {
      this.toastrService.error(this.translate.instant('form.feedback.something_went_wrong'), null, {
        disableTimeOut: false,
        timeOut: 4000
      });
      return this.bsModalRef.hide();
    }
  }

  resendCode(event) {
    event.preventDefault();
    this.requestEmailVerificationCode(this.form.get('email').value);
  }
}
