import { Attachment } from './attachment';
import { HealthCareProfessional } from './health-care-professional';

export class Note {
  public uid: string;
  public title: string;
  public note_preview: string;
  public created_at: string;
  public changed_at: string;
  public created_by: HealthCareProfessional;
  public changed_by: HealthCareProfessional;
  public note: string;
  public attachments: Attachment[] = [];

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.title = item.title;
    this.note_preview = item.note_preview;
    this.created_at = item.created_at;
    this.changed_at = item.changed_at;
    this.created_by = new HealthCareProfessional(item.created_by);
    this.changed_by = new HealthCareProfessional(item.changed_by);
    this.note = item.note;

    if(item.attachments) {
      item.attachments.forEach(a => {
        this.attachments.push(new Attachment(a));
      });
    }
  }
}
