<div id="zone_reminders" class="mb-2 mb-lg-3" *ngIf="(reminders && reminders?.length) > 0  || tasks?.length > 0">
  <h2 class="text-secondary">{{ 'pages.default.patient_detail.reminders' | translate }}</h2>

  <div class="row">
    <div class="col-6" *ngFor="let task of tasks | sortBy:'asc': 'order'">

      <button class="reminder-item-block mb-2 item-clickable testing_task btn text-align-start w-100 p-0" [ngClass]="{'block-danger' : task?.passed() }" (click)="openReminder(task)">
        <div class="block-wrapper p-end-0">
          <span class="icon" [inlineSVG]="'/assets/svg/approve.svg'"></span>
        </div>

        <div class="block-wrapper overflow-hidden">
          <p class="label text-truncate">{{ task?.title | translateHelp | translate | evalTranslation }}</p>
          <p class="value text-truncate" *ngIf="task?.due_date ">{{ 'pages.default.patient_detail.reminders.due_on' | translate }} {{ task?.due_date |  timeZoneDate  }}</p>
        </div>

        <div class="block-action-wrapper m-start-auto" >
          <div class="block-action">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          </div>
        </div>
      </button>
    </div>

    <ng-container *ngFor="let reminder of reminders" >
      <div class="col-6" *ngIf="reminder?.type == 'appointment' || reminder?.type == 'message'">
        <button class="reminder-item-block mb-2 item-clickable testing_{{reminder?.type}} btn text-align-start w-100 p-0" [ngClass]="{'block-danger' : reminder?.passed() }" (click)="openReminder(reminder)">

          <div class="block-wrapper p-end-0">
            <span class="icon" [inlineSVG]="'/assets/svg/calendar.svg'" *ngIf="reminder?.type == 'appointment'"></span>
            <span class="icon" [inlineSVG]="'/assets/svg/chat.svg'" *ngIf="reminder?.type == 'message'"></span>
          </div>
          <div class="block-wrapper" *ngIf="reminder?.type == 'message'">
            <p class="label" >{{ 'pages.default.patient_detail.reminders.unread_messages' | translate }}"{{ reminder?.messageSubject }}"</p>
          </div>

          <div class="block-wrapper overflow-hidden"*ngIf="reminder?.type == 'appointment'" >
            <p class="label text-truncate">{{ 'pages.default.patient_detail.reminders.appointment_with' | translate }} {{ patient?.first_name }}</p>
            <p class="value text-truncate" *ngIf="reminder?.start_date">
              <span>{{ reminder?.start_date |  timeZoneDate  }}</span>
              <span>&nbsp;{{ 'pages.default.patient_detail.reminders.at' | translate }}&nbsp;</span>
              <span >{{ reminder?.start_date |  time }}</span>
            </p>
            <p class="value text-truncate" *ngIf="!reminder?.start_date && reminder?.date">
              <span>{{ reminder?.date |  timeZoneDate  }}</span>
            </p>
          </div>

          <div class="block-action-wrapper m-start-auto" >
            <div class="block-action">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </div>
          </div>
        </button>
      </div>
    </ng-container>

  </div>
</div>
