<div class="modal-body">
  <button type="button" class="close" id="confirm-modal-close" data-dismiss="modal" aria-label="Close"
    (click)="onHandleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <div *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div *ngIf="!isLoading">
    <h2 class="mb-3">{{ 'modals.edit_hospital_team_modal.edit_default_hospital_team' | translate }}</h2>

    <p class="small text-muted mb-0">{{ 'modals.edit_hospital_team_modal.care_module' | translate }}</p>
    <p>{{careModule?.translationKey | translate }}</p>

    <hr />

    <form [formGroup]="form" [ngClass]="{'validation-visible': validationVisible}" autocomplete="off">

      <!-- default_clinical_lead -->
      <div class="form-group">
        <app-select [placeholder]="('modals.edit_hospital_team_modal.default_clinical_lead' | translate)"
          [hideSelected]="false" [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcps"
          [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()" formControlName="default_clinical_lead" [asFilter]="false" [showCheckboxes]="false"
          [showUserDetails]="true">
        </app-select>
        <app-feedback-field [field]="form.get('default_clinical_lead')"></app-feedback-field>
      </div>

      <!-- default_case_manager -->
      <div class="form-group">
        <app-select [placeholder]="('modals.edit_hospital_team_modal.default_case_manager' | translate)"
          [hideSelected]="false" [multiple]="false" [clearable]="true" [searchable]="true" [items]="hcps"
          [searchFn]="customSearchFn" (search)="searchHcpListEvent.next($event)" (close)="searchHcpList()" formControlName="default_case_manager" [asFilter]="false" [showCheckboxes]="false"
          [showUserDetails]="true">
        </app-select>
        <app-feedback-field [field]="form.get('default_case_manager')"></app-feedback-field>
      </div>
    </form>

    <hr />
    <!-- default MDTs and other hcps -->

    <h3>{{ 'modals.edit_hospital_team_modal.mdts' | translate }}</h3>

    <div class="form-group styled floating-label">
      <ng-select (change)="onAddMdtOrHcp()" (search)="searchMdtOtherHcpListEvent.next($event)" [(ngModel)]="mdtOrHcpSelect" [clearable]="true"
        [items]="allMdtsOrHcps" [placeholder]="'modals.edit_hospital_team_modal.search_team_hcp' | translate"
        [searchFn]="customSearchFn" class="users-select">

        <ng-template let-item="item" ng-label-tmp>
          <span *ngIf="item?.className === 'MedicalTeam'">{{item?.name}}</span>
          <span *ngIf="item?.className === 'HealthCareProfessional'">{{item?.getFullName()}}</span>
        </ng-template>

        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
          <div *ngIf="item?.className === 'MedicalTeam'" class="wrapper" id="mdt-info">
            <div class="avatar">
              <span [inlineSVG]="'/assets/svg/community.svg'" class="icon"></span>
            </div>
            <div class="user-info">
              <p class="title">{{item?.name}}</p>
              <p class="sub-text">{{item?.hcpsAllNames}}</p>
            </div>
            <div *ngIf="isMdtSelected(item)" class="status small text-muted">
              <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
            </div>
            <div *ngIf="!isMdtSelected(item)" class="status text-secondary">
              <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
              <span>{{ 'action.add' | translate }}</span>
            </div>
          </div>
          <div *ngIf="item?.className === 'HealthCareProfessional'" class="wrapper" id="hcp-info">
            <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

            <div class="user-info">
              <p class="title">{{item?.getFullName()}}</p>
              <p class="sub-text">{{ item?.job_title }}</p>
            </div>
            <div *ngIf="isHcpSelected(item)" class="status small text-muted">
              <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
            </div>
            <div *ngIf="!isHcpSelected(item)" class="status text-secondary">
              <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
              <span>{{ 'action.add' | translate }}</span>
            </div>
          </div>
        </ng-template>

      </ng-select>
    </div>

    <div *ngIf="careModule?.default_mdts?.length" class="mt-4">
      <p class="mb-2 font-weight-bold">{{ 'modals.edit_hospital_team_modal.teams' | translate }}</p>

      <div *ngFor="let mdt of careModule?.default_mdts" class="participant-item">
        <div class="avatar">
          <span [inlineSVG]="'/assets/svg/community.svg'" class="icon"></span>
        </div>
        <div class="meta">
          <p class="title">{{ mdt?.name }}</p>
          <p class="description">
            <span *ngFor="let hcp of mdt?.hcps; let i=index">
              <span>{{ hcp?.getFullName() }}</span>
              <span *ngIf="(i < (mdt?.hcps?.length - 1))">, </span>
            </span>
          </p>
        </div>
        <a (click)="onRemoveMdt($event, mdt)" [id]="'default-mdt-modal-remove-mdt-' + mdt.uid"
          class="status text-secondary" href="">
          <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </a>
      </div>

      <p class="text-secondary" *ngIf="defaultMdtsError">{{ defaultMdtsError  | translate }}</p>
    </div>

    <div *ngIf="careModule?.default_hcps?.length" class="my-4">
      <p class="mb-2 font-weight-bold">{{ 'modals.edit_hospital_team_modal.other_hcps' | translate }}</p>

      <div *ngFor="let hcp of careModule?.default_hcps" class="participant-item">
        <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

        <div class="meta">
          <p class="title">{{ hcp?.getFullName() }}</p>
          <p class="description">{{ hcp?.job_title }}</p>
        </div>
        <a (click)="onRemoveHcp($event, hcp)" [id]="'default-mdt-modal-remove-hcp-' + hcp.uid"
          class="status text-secondary" href="">
          <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
          <span>{{ 'action.remove' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
  <p *ngIf="!isLoading" class="text-muted"><span class="font-weight-bold">{{ 'modals.edit_hospital_team_modal.please_note' | translate }}</span>: {{ 'modals.edit_hospital_team_modal.note' | translate }}</p>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-cl-cm-form-cancel" (click)="onHandleClose()">{{ 'action.cancel' |
      translate}}</button>
    <button class="btn btn-secondary m-start-3" id="edit-cl-cm-form-submit" [ngClass]="{'loader': isSaving}"
      (click)="handleConfirm()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>
  </div>
</div>
