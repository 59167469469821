import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { PageTabItem } from '../../../models/page-tab-item';
import { PhaseInstance } from '../../../models/phase-instance';
import { AuthenticationService } from '../../../services/authentication.service';
import { DataService } from '../../../services/data.service';
import { GeneralService } from '../../../services/general.service';
import { HcpService } from '../../../services/hcp.service';
import { PathwayService } from '../../../services/pathway.service';
import { MaterialPhase } from '../../../models/material-phase';
import { Pathway } from '../../../models/pathway';
import { LanguageService } from '../../../services/language.service';
import { MaterialService } from '../../../services/material.service';

@Component({
  selector: 'app-patient-materials',
  templateUrl: './patient-materials.component.html',
  styleUrls: ['./patient-materials.component.scss']
})
export class PatientMaterialsComponent implements OnInit {
  public patientUid: string;
  public patientPathwayUid: string;
  public patientPathwayKey: string;

  public pageTabItems: PageTabItem[] = [];

  public learningMaterials: any[];
  public isLoadingMaterials: boolean;

  public selectedPhase: MaterialPhase;

  public phases: any[];
  public pathway: Pathway;

  public hasCcRole = false;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public materialService: MaterialService,
    public hcpService: HcpService,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public authService: AuthenticationService,
    public pathwayService: PathwayService,
    public generalService: GeneralService
  ) { }

  ngOnInit() {
    this.hasCcRole = this.authService.hasCcRole();
    this.activatedRoute.params.subscribe(params => {
      this.patientUid = params.patientUid;
      this.patientPathwayUid = params.patientPathwayUid;
      this.patientPathwayKey = params.patientPathwayKey;
      this.pageTabItems = [];

      this.loadMaterials();
    });

    this.pathway = this.dataService.get(DataService.SelectedPathway);
  }

  loadMaterials() {
    this.isLoadingMaterials = true;
    this.pageTabItems = [new PageTabItem('...', null)];

    const hospital_uid = this.hcpService.getCurrentStoredHospitalUid();
    const hcp_uid = this.hcpService.getCurrentStoredHcpUid();

    const materialsObservable = this.hasCcRole
      ? this.materialService.getMaterialsByCc(hospital_uid, this.patientUid, this.patientPathwayUid)
      : this.materialService.getMaterialsByHcp(hcp_uid, this.patientUid, this.patientPathwayUid);

    forkJoin([
      this.pathwayService.getPathWayPhases(this.patientUid, this.patientPathwayUid),
      materialsObservable
    ]).subscribe(result => {
      this.createTabs(result);
      this.loadDetails(result[1].phases);
    });
  }

  loadDetails(phases) {
    if (!phases) {
      return false;
    }

    phases.forEach(phase => {
      const ids: string[] = phase.educational_materials.map(mat => mat.reference.key);

      this.materialService.getMaterialsDetails(ids).subscribe(result => {
        this.mapResults(result, phase);
        this.isLoadingMaterials = false;
      }, () => {
        this.isLoadingMaterials = false;
      });
    });
  }

  createTabs(data) {
    const phases: MaterialPhase[] = data[1].phases;
    const currentPhase: PhaseInstance = data[0].find((phase: PhaseInstance) => phase.isCurrent && !phase.isSubPhase);
    let currentPhasePageTabItem: PageTabItem;

    if (!phases) {
      return false;
    }
    const _pageTabItems = [];

    phases.forEach((phase: MaterialPhase) => {
      const tabItem = new PageTabItem(null, null, phase);
      tabItem.translationKey = phase.translationKey;
      _pageTabItems.push(tabItem);


      if(this.dataService.get('selectedPhase') && this.dataService.get('selectedPhase').phase_id === phase.phase_id) {
        currentPhasePageTabItem = tabItem;

      } else if (phase.phase_id === currentPhase?.id  && !this.dataService.get('selectedPhase')) {
        currentPhasePageTabItem = tabItem;
      }
    });

    this.pageTabItems = _pageTabItems;

    if (currentPhasePageTabItem) {
      setTimeout(() => {
        this.generalService.scrollToPageTabItem(currentPhasePageTabItem, false);
        this.onTabChangeHandler(currentPhasePageTabItem);
      });
    } else{
      this.generalService.scrollToPageTabItem(this.pageTabItems[0], false);
      this.onTabChangeHandler(this.pageTabItems[0]);
    }
  }

  onTabChangeHandler(pageTabItem: PageTabItem) {
    this.selectedPhase = pageTabItem.data;
    this.dataService.set('selectedPhase', pageTabItem.data);
  }

  openMaterial(event, material?: any) {
    event.preventDefault();
    this.dataService.set(DataService.KeyLearningMaterialSelected, material);
    this.router.navigate(['/patient', this.patientUid, this.patientPathwayUid, 'learning-materials', 'article', material.reference.key ]);
  }

  mapResults(entries, phase) {
    entries.forEach(entry => {
      const materials = phase.educational_materials.filter(material =>  entry.uid ===  material.reference.key);
      if (materials.length > 0) {
        const material = materials[0];
        material['title'] = entry.title;
        material['description'] = entry.description;
        material['thumbnail'] = entry.thumbnail;
        material['disclaimer'] = entry.disclaimer;
      }
    });
  }
}
