import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgHttpCachingModule, NgHttpCachingSessionStorage, NgHttpCachingStrategy } from 'ng-http-caching';
import { forkJoin, Observable } from 'rxjs';
import { CommonModule, DatePipe } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import moment from 'moment';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter as CalendarDateAdapter,
  MOMENT
} from 'angular-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ErrorInterceptor } from './http-interceptors/error-interceptor';
import { ClickOutsideModule } from 'ng-click-outside';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ChartModule } from 'angular-highcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';

//
// Layouts
//
import { FlowLayoutComponent } from './layouts/flow-layout/flow-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';

//
// Flow Layout Pages
//
import { HomePageComponent } from './pages/flow/home-page/home-page.component';
import { ExpiredPasswordResetComponent } from './pages/flow/expired-password-reset/expired-password-reset.component';
import { OnboardingDetailsComponent } from './pages/flow/onboarding-details/onboarding-details.component';
import { DeviceNotSupportedPageComponent } from './pages/flow/device-not-supported-page/device-not-supported-page.component';
import { LocaleRegistrationComponent } from './pages/flow/locale-registration/locale-registration.component';
import { LinkInvalidPageComponent } from './pages/flow/link-invalid-page/link-invalid-page.component';

//
// Default Layout Pages
//
import { DashboardPageComponent } from './pages/default/dashboard-page/dashboard-page.component';
import {
  SettingsConsentsOverviewComponent
} from './pages/default/settings/settings-consents-overview/settings-consents-overview.component';
import { SettingsConsentDetailComponent } from './pages/default/settings/settings-consent-detail/settings-consent-detail.component';
import { SettingsOverviewComponent } from './pages/default/settings/settings-overview/settings-overview.component';
import { SettingsPersonalDataComponent } from './pages/default/settings/settings-personal-data/settings-personal-data.component';
import { SettingsPreferencesComponent } from './pages/default/settings/settings-preferences/settings-preferences.component';
import { SettingsPersonalInfoComponent } from './pages/default/settings/settings-personal-info/settings-personal-info.component';
import { SettingsSecurityComponent } from './pages/default/settings/settings-security/settings-security.component';
import { AdminOverviewComponent } from './pages/default/admin/admin-overview/admin-overview.component';
import { AdminOnboardingRequestsComponent } from './pages/default/admin/admin-onboarding-requests/admin-onboarding-requests.component';
import { AdminOnboardingCodesComponent } from './pages/default/admin/admin-onboarding-codes/admin-onboarding-codes.component';
import { ConversationsComponent } from './pages/default/conversations/conversations.component';
import { TasksComponent } from './pages/default/tasks/tasks.component';
import { TimelineComponent } from './pages/default/timeline/timeline.component';
import { GoalDetailComponent } from './pages/default/goal-detail/goal-detail.component';
import { DnaOverviewComponent } from './pages/default/dna/dna-overview/dna-overview.component';
import { PatientNotesComponent } from './pages/default/patient-notes/patient-notes.component';
import { NoteDetailComponent } from './pages/default/note-detail/note-detail.component';

//
// Generic Pages
//
import { GenericErrorComponent } from './pages/general/generic-error/generic-error.component';

//
// Components
//
import { FlowWelcomeComponent } from './components/flow-welcome/flow-welcome.component';
import { FlowCopyrightsComponent } from './components/flow-copyrights/flow-copyrights.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { PageTabsComponent } from './components/page-tabs/page-tabs.component';
import { MfaCodeComponent } from './pages/flow/mfa-code/mfa-code.component';
import { PasswordPolicyRulesComponent } from './components/password-policy-rules/password-policy-rules.component';
import { OnboardingPasswordComponent } from './pages/flow/onboarding-password/onboarding-password.component';
import { OnboardingConsentComponent } from './pages/flow/onboarding-consent/onboarding-consent.component';
import { ForgotPasswordResetComponent } from './pages/flow/forgot-password-reset/forgot-password-reset.component';
import { ForgotPasswordComponent } from './pages/flow/forgot-password/forgot-password.component';
import { OnboardingDetailsFormComponent } from './components/onboarding-details-form/onboarding-details-form.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationRangeComponent } from './components/pagination-range/pagination-range.component';
import { PatientTabbedDetailsComponent } from './components/patient-tabbed-details/patient-tabbed-details.component';
import { AppointmentModalComponent } from './modals/appointment-modal/appointment-modal.component';
import { CalendarComponent } from './pages/default/calendar/calendar.component';
import { StartNewConversationModalComponent } from './modals/start-new-conversation-modal/start-new-conversation-modal.component';
import {
  EditConversationParticipantsModalComponent
} from './modals/edit-conversation-participants-modal/edit-conversation-participants-modal.component';
import { CalendarYearViewComponent } from './components/calendar-year-view/calendar-year-view.component';
import { CalendarMonthViewComponent } from './components/calendar-month-view/calendar-month-view.component';
import { CalendarWeekViewComponent } from './components/calendar-week-view/calendar-week-view.component';
import { CalendarAppointmentCompactComponent } from './components/calendar-appointment-compact/calendar-appointment-compact.component';
import { CalendarPendingInvitationsComponent } from './components/calendar-pending-invitations/calendar-pending-invitations.component';
import { ConversationEntriesComponent } from './components/conversation-entries/conversation-entries.component';
import { AppSelectComponent } from './components/app-select/app-select.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CircleProgressSmallComponent } from './components/circle-progress-small/circle-progress-small.component';
import { CircleProgressMediumComponent } from './components/circle-progress-medium/circle-progress-medium.component';
import { MaintenanceComponent } from './pages/general/maintenance/maintenance.component';
import { InactiveComponent } from './pages/general/inactive/inactive.component';
import { HelpCenterFaqListComponent } from './components/help-center-faq-list/help-center-faq-list.component';
import { HelpCenterContactComponent } from './components/help-center-contact/help-center-contact.component';
import { FaqBlockComponent } from './components/faq-block/faq-block.component';
import { FaqContentComponent } from './components/faq-content/faq-content.component';
import { AdminMdtsComponent } from './pages/default/admin/admin-mdts/admin-mdts.component';
import { PatientDetailComponent } from './pages/default/patient-detail/patient-detail.component';
import { AdminHcpsComponent } from './pages/default/admin/admin-hcps/admin-hcps.component';
import { PatientMaterialsComponent } from './pages/default/patient-materials/patient-materials.component';
import { MaterialDetailComponent } from './pages/default/material-detail/material-detail.component';
import { AdminPatientsComponent } from './pages/default/admin/admin-patients/admin-patients.component';
import { CookiePageComponent } from './pages/general/cookie-page/cookie-page.component';
import { MobileRedirectComponent } from './pages/general/mobile-redirect/mobile-redirect.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { SettingsOooComponent } from './pages/default/settings/settings-ooo/settings-ooo.component';
import { DnaPatientOnboardingComponent } from './pages/default/dna/dna-patient-onboarding/dna-patient-onboarding.component';
import { HelpCenterOverviewComponent } from './pages/default/help-center/help-center-overview/help-center-overview.component';
import {
  HelpCenterArticlesOverviewComponent
} from './pages/default/help-center/help-center-articles-overview/help-center-articles-overview.component';
import { HelpCenterFaqsOverviewComponent } from './pages/default/help-center/help-center-faqs-overview/help-center-faqs-overview.component';
import {
  HelpCenterArticlesDetailComponent
} from './pages/default/help-center/help-center-articles-detail/help-center-articles-detail.component';
import { HelpCenterFaqsDetailComponent } from './pages/default/help-center/help-center-faqs-detail/help-center-faqs-detail.component';
import { FeedbackFieldComponent } from './components/feedback-field/feedback-field.component';
import { AvatarInputComponent } from './components/avatar-input/avatar-input.component';
import { PatientDetailChecklistsComponent } from './components/patient-detail-checklists/patient-detail-checklists.component';
import { PatientDetailEssentialFormsComponent } from './components/patient-detail-essential-forms/patient-detail-essential-forms.component';
import { QueryListOverviewComponent } from './pages/default/query-list-overview/query-list-overview.component';
import { QueryListDetailComponent } from './pages/default/query-list-detail/query-list-detail.component';
import { PatientDetailRemindersComponent } from './components/patient-detail-reminders/patient-detail-reminders.component';
import { PatientDetailSurgeryDatesComponent } from './components/patient-detail-surgery-dates/patient-detail-surgery-dates.component';
import { AdminTasksComponent } from './pages/default/admin/admin-tasks/admin-tasks.component';
import { DnaFiltersComponent } from './components/dna-filters/dna-filters.component';
import { DnaKpisComponent } from './components/dna-kpis/dna-kpis.component';
import { DnaPhasesComponent } from './components/dna-phases/dna-phases.component';
import { DnaPathwaysOverviewComponent } from './pages/default/dna/dna-pathways-overview/dna-pathways-overview.component';
import { DnaPatientsTableComponent } from './components/dna-patients-table/dna-patients-table.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { DateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';
import { AdminCareModulesComponent } from './pages/default/admin/admin-care-modules/admin-care-modules.component';
import { AdminCareModulesDetailsComponent } from './pages/default/admin/admin-care-modules-details/admin-care-modules-details.component';
import { VideoCarouselComponent } from './components/video-carousel/video-carousel.component';
import { OnboardingIconStepsComponent } from './components/onboarding-icon-steps/onboarding-icon-steps.component';
import { UserTaskFormComponent } from './components/user-task-form/user-task-form.component';

//
// Modals
//
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { CreatingAccountModalComponent } from './modals/creating-account-modal/creating-account-modal.component';
import { DeleteAccountModalComponent } from './modals/delete-account-modal/delete-account-modal.component';
import { DoNotConsentModalComponent } from './modals/do-not-consent-modal/do-not-consent-modal.component';
import { ExportPersonalDataModalComponent } from './modals/export-personal-data-modal/export-personal-data-modal.component';
import { OnboardingLoadingModalComponent } from './modals/onboarding-loading-modal/onboarding-loading-modal.component';
import { RestrictProcessingDataModalComponent } from './modals/restrict-processing-data-modal/restrict-processing-data-modal.component';
import { EditConversationSubjectModalComponent } from './modals/edit-conversation-subject-modal/edit-conversation-subject-modal.component';
import { EditPersonalDetailsModalComponent } from './modals/edit-personal-details-modal/edit-personal-details-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { GenerateOnboardingCodeModalComponent } from './modals/generate-onboarding-code-modal/generate-onboarding-code-modal.component';
import { ConversationParticipantsModalComponent } from './modals/conversation-participants-modal/conversation-participants-modal.component';
import { AssignMdtModalComponent } from './modals/assign-mdt-modal/assign-mdt-modal.component';
import { AppointmentFormModalComponent } from './modals/appointment-form-modal/appointment-form-modal.component';
import { MobileVerificationCodeModalComponent } from './modals/mobile-verification-code-modal/mobile-verification-code-modal.component';
import { EditEmailModalComponent } from './modals/edit-email-modal/edit-email-modal.component';
import { EditPhoneModalComponent } from './modals/edit-phone-modal/edit-phone-modal.component';
import { UserTaskAppointmentModalComponent } from './modals/user-task-appointment-modal/user-task-appointment-modal.component';
import { UserTaskStandardModalComponent } from './modals/user-task-standard-modal/user-task-standard-modal.component';
import { DeleteNoteModalComponent } from './modals/delete-note-modal/delete-note-modal.component';
import { ResendOnboardingEmailModalComponent } from './modals/resend-onboarding-email-modal/resend-onboarding-email-modal.component';
import { SurgeryDateModalComponent } from './modals/surgery-date-modal/surgery-date-modal.component';
import { HelpCenterModalComponent } from './modals/help-center-modal/help-center-modal.component';
import { AddMdtModalComponent } from './modals/add-mdt-modal/add-mdt-modal.component';
import { EditMdtModalComponent } from './modals/edit-mdt-modal/edit-mdt-modal.component';
import { EditRoleModalComponent } from './modals/assign-role-modal/edit-role-modal.component';
import { EditHcpModalComponent } from './modals/edit-hcp-modal/edit-hcp-modal.component';
import { EditPatientModalComponent } from './modals/edit-patient-modal/edit-patient-modal.component';
import { AddPatientModalComponent } from './modals/add-patient-modal/add-patient-modal.component';
import { AddHcpModalComponent } from './modals/add-hcp-modal/add-hcp-modal.component';
import { ConversationModalComponent } from './modals/conversation-modal/conversation-modal.component';
import { BulkApprovalModalComponent } from './modals/bulk-approval-modal/bulk-approval-modal.component';
import { ProfilePictureModalComponent } from './modals/profile-picture-modal/profile-picture-modal.component';
import { QueryListParticipantsModalComponent } from './modals/query-list-participants-modal/query-list-participants-modal.component';
import { AssignTaskModalComponent } from './modals/assign-task-modal/assign-task-modal.component';
import { EditCmClModalComponent } from './modals/edit-cm-cl-modal/edit-cm-cl-modal.component';
import { AddPathwayModalComponent } from './modals/add-pathway-modal/add-pathway-modal.component';
import { AddMfaBackupModalComponent } from './modals/add-mfa-backup-modal/add-mfa-backup-modal.component';
import { EditOtherEmailModalComponent } from './modals/edit-other-email-modal/edit-other-email-modal.component';
import { PathwayMessageModalComponent } from './modals/pathway-message-modal/pathway-message-modal.component';
import { StopPathwayModalComponent } from './modals/stop-pathway-modal/stop-pathway-modal.component';
import { StopPathwayConfirmModalComponent } from './modals/stop-pathway-confirm-modal/stop-pathway-confirm-modal.component';
import { AddPatientWizardModalComponent } from './modals/add-patient-wizard-modal/add-patient-wizard-modal.component';
import { EditHospitalTeamModalComponent } from './modals/edit-hospital-team-modal/edit-hospital-team-modal.component';
import { PathwayStepComponent } from './modals/add-patient-wizard-modal/steps/pathway-step/pathway-step.component';
import { PatientStepComponent } from './modals/add-patient-wizard-modal/steps/patient-step/patient-step.component';
import { RegionStepComponent } from './modals/add-patient-wizard-modal/steps/region-step/region-step.component';
import { HospitalTeamStepComponent } from './modals/add-patient-wizard-modal/steps/hospital-team-step/hospital-team-step.component';
import { OverviewStepComponent } from './modals/add-patient-wizard-modal/steps/overview-step/overview-step.component';

//
// Services
//
import { GeneralService } from './services/general.service';
import { LanguageService } from './services/language.service';
import { TranslationLoaderService } from './services/translation-loader.service';
import { MaintenanceService } from './services/maintenance.service';

//
// Pipes
//
import { SimpleDatePipe } from './pipes/simple_date.pipe';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { TranslateHelp } from './pipes/translate-help.pipe';
import { EvalTranslation } from './pipes/eval-translation.pipe';
import { TimeZoneDatePipe } from './pipes/tz_date.pipe';
import { TimePipe } from './pipes/time.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { TranslateNumberPipe } from './pipes/translate-number.pipe';
import { VideoSafePipe } from './pipes/video-safe.pipe';
import { BytesPipe } from './pipes/bytes.pipe';

//
// Interceptors & guards
//
import { HeaderInterceptor } from './http-interceptors/header-interceptor';
import { SessionInterceptor } from './http-interceptors/session-interceptor';
import { QueryListCanDeactivateGuard } from './helpers/query-list-form.guard';
import { NoteCanDeactivateGuard } from './helpers/note-form.guard';
import { SidebarBannerComponent } from './components/sidebar-banner/sidebar-banner.component';
import { BannerInfoModalComponent } from './modals/banner-info-modal/banner-info-modal.component';
import { BannerInitModalComponent } from './modals/banner-init-modal/banner-init-modal.component';

//
// Setup language loader
//
export function CustomTranslateLoader(http: HttpClient) {
  return {
    getTranslation(lang: string): Observable<any> {
      return TranslationLoaderService.GetAllTranslations(http, lang);
    }
  };
}

//
// AppInitializer
//
export function appInitializerFactory(
  maintenanceService: MaintenanceService,
  generalService: GeneralService,
  languageService: LanguageService,
) {
  return () => {
    return new Promise((resolve, reject) => {
      forkJoin([
        maintenanceService.appInitializer(),
        generalService.appInitializer(),
        languageService.appInitializer()
      ]).subscribe(() => {
        resolve(true);
      }, () => {
        reject(true);
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppointmentModalComponent,
    AppointmentFormModalComponent,
    FlowWelcomeComponent,
    FlowCopyrightsComponent,
    FlowLayoutComponent,
    ExpiredPasswordResetComponent,
    DefaultLayoutComponent,
    DoNotConsentModalComponent,
    GenericErrorComponent,
    MaintenanceComponent,
    InactiveComponent,
    PageTabsComponent,
    LogoutModalComponent,
    ConfirmModalComponent,
    MobileVerificationCodeModalComponent,
    CreatingAccountModalComponent,
    DeleteAccountModalComponent,
    EditConversationSubjectModalComponent,
    EditPersonalDetailsModalComponent,
    ExportPersonalDataModalComponent,
    OnboardingDetailsComponent,
    OnboardingDetailsFormComponent,
    OnboardingLoadingModalComponent,
    OnboardingPasswordComponent,
    OnboardingConsentComponent,
    RestrictProcessingDataModalComponent,
    HomePageComponent,
    PageLoaderComponent,
    PasswordPolicyRulesComponent,
    DashboardPageComponent,
    SettingsConsentDetailComponent,
    SettingsConsentsOverviewComponent,
    SettingsOverviewComponent,
    SettingsPersonalDataComponent,
    SettingsPersonalInfoComponent,
    SettingsSecurityComponent,
    SettingsOooComponent,
    DeviceNotSupportedPageComponent,
    LinkInvalidPageComponent,
    LocaleRegistrationComponent,
    SettingsPreferencesComponent,
    MfaCodeComponent,
    ForgotPasswordResetComponent,
    ForgotPasswordComponent,
    AdminOverviewComponent,
    PaginationComponent,
    AdminOnboardingRequestsComponent,
    PaginationRangeComponent,
    AdminOnboardingCodesComponent,
    PatientTabbedDetailsComponent,
    GenerateOnboardingCodeModalComponent,
    ConversationParticipantsModalComponent,
    ConversationsComponent,
    SimpleDatePipe,
    TimeZoneDatePipe,
    SortByPipe,
    WeekdayPipe,
    TimePipe,
    BytesPipe,
    TranslateNumberPipe,
    StartNewConversationModalComponent,
    AssignMdtModalComponent,
    TranslateHelp,
    EvalTranslation,
    EditConversationParticipantsModalComponent,
    CalendarComponent,
    CalendarYearViewComponent,
    CalendarMonthViewComponent,
    CalendarWeekViewComponent,
    AppointmentModalComponent,
    CalendarAppointmentCompactComponent,
    CalendarPendingInvitationsComponent,
    MobileVerificationCodeModalComponent,
    AdminMdtsComponent,
    EditEmailModalComponent,
    EditPhoneModalComponent,
    AddMdtModalComponent,
    PatientDetailComponent,
    AssignMdtModalComponent,
    EditMdtModalComponent,
    AddHcpModalComponent,
    PatientDetailComponent,
    EditRoleModalComponent,
    AdminHcpsComponent,
    PatientMaterialsComponent,
    EditPatientModalComponent,
    EditHcpModalComponent,
    PatientMaterialsComponent,
    MaterialDetailComponent,
    AddPatientModalComponent,
    CookiePageComponent,
    AddPatientModalComponent,
    AdminPatientsComponent,
    MobileRedirectComponent,
    TasksComponent,
    GoalDetailComponent,
    UserTaskAppointmentModalComponent,
    UserTaskStandardModalComponent,
    DynamicInputComponent,
    TimelineComponent,
    GoalDetailComponent,
    AppSelectComponent,
    ConversationEntriesComponent,
    ConversationModalComponent,
    DnaOverviewComponent,
    DnaPatientOnboardingComponent,
    HelpCenterOverviewComponent,
    HelpCenterArticlesOverviewComponent,
    HelpCenterFaqsOverviewComponent,
    HelpCenterArticlesDetailComponent,
    HelpCenterFaqsDetailComponent,
    BulkApprovalModalComponent,
    ProfilePictureModalComponent,
    SecurePipe,
    VideoSafePipe,
    FeedbackFieldComponent,
    AvatarComponent,
    AvatarInputComponent,
    PatientNotesComponent,
    NoteDetailComponent,
    DeleteNoteModalComponent,
    SurgeryDateModalComponent,
    PatientDetailChecklistsComponent,
    PatientDetailEssentialFormsComponent,
    QueryListOverviewComponent,
    QueryListDetailComponent,
    QueryListParticipantsModalComponent,
    PatientDetailRemindersComponent,
    PatientDetailSurgeryDatesComponent,
    UserTaskStandardModalComponent,
    AdminTasksComponent,
    AssignTaskModalComponent,
    DnaFiltersComponent,
    DnaKpisComponent,
    DnaPhasesComponent,
    DnaPatientsTableComponent,
    DnaPathwaysOverviewComponent,
    EditEmailModalComponent,
    EditCmClModalComponent,
    AddPathwayModalComponent,
    BreadcrumbComponent,
    AudioPlayerComponent,
    AddMfaBackupModalComponent,
    DateTimeInputComponent,
    DateInputComponent,
    TextareaAutoresizeDirective,
    EditOtherEmailModalComponent,
    PathwayMessageModalComponent,
    StopPathwayModalComponent,
    StopPathwayConfirmModalComponent,
    CircleProgressSmallComponent,
    CircleProgressMediumComponent,
    AddPatientWizardModalComponent,
    AdminCareModulesComponent,
    AdminCareModulesDetailsComponent,
    EditHospitalTeamModalComponent,
    PathwayStepComponent,
    PatientStepComponent,
    RegionStepComponent,
    HospitalTeamStepComponent,
    OverviewStepComponent,
    VideoCarouselComponent,
    OnboardingIconStepsComponent,
    ResendOnboardingEmailModalComponent,
    HelpCenterModalComponent,
    HelpCenterFaqListComponent,
    HelpCenterContactComponent,
    FaqBlockComponent,
    FaqContentComponent,
    SidebarBannerComponent,
    BannerInfoModalComponent,
    BannerInitModalComponent,
    UserTaskFormComponent
  ],
  bootstrap: [AppComponent], imports: [ImageCropperModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxPageScrollCoreModule.forRoot({
      easingLogic: (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) {
          return c / 2 * t * t + b;
        }
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      }
    }),
    ToastrModule.forRoot({
      closeButton: false,
      disableTimeOut: true,
      preventDuplicates: true
    }),
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory,
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CalendarMomentDateFormatter,
      },
    }),
    BackButtonDisableModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ClickOutsideModule,
    InfiniteScrollModule,
    TimepickerModule.forRoot(),
    ChartModule,
    NgCircleProgressModule.forRoot({
      outerStrokeWidth: 28,
      outerStrokeLinecap: 'square',
      outerStrokeColor: '#000099',
      showInnerStroke: true,
      innerStrokeWidth: 28,
      innerStrokeColor: '#CCCCEB',
      space: -28,
      titleFontSize: '1.75rem',
      titleFontWeight: '900',
      titleColor: '#000099',
      showSubtitle: true,
      subtitleFontSize: '1.75rem',
      subtitleFontWeight: '900',
      subtitleColor: '#000099',
      showUnits: false,
      units: '%',
      unitsFontSize: '1.75rem',
      unitsFontWeight: '900',
      unitsColor: '#000099',
      maxPercent: 100,
      showZeroOuterStroke: false,
      startFromZero: true,
      animationDuration: 1000,
      renderOnClick: false,
    }),
    CKEditorModule,
    CarouselModule.forRoot(),
    NgHttpCachingModule.forRoot({
      cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL,
      store: new NgHttpCachingSessionStorage(),
      lifetime: 3600000
    })], providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    DatePipe,
    TimePipe,
    TimeZoneDatePipe,
    SortByPipe,
    SecurePipe,
    TranslateNumberPipe,
    VideoSafePipe,
    TranslateHelp,
    EvalTranslation,
    BytesPipe,
    QueryListCanDeactivateGuard,
    NoteCanDeactivateGuard,
    GeneralService,
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [
        MaintenanceService,
        GeneralService,
        LanguageService,
      ],
      multi: true
    },
    {
      provide: MOMENT,
      useValue: moment,
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
