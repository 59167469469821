import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FaqCategory } from '../models/faq-category';
import { HelpcenterArticle } from '../models/helpcenter-article';
import { HelpcenterContact } from '../models/helpcenter-contact';
import { HelpcenterSection } from '../models/helpcenter-section';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { LanguageService } from './language.service';
import { ScopeService} from './scope.service';

@Injectable({
  providedIn: 'root'
})
export class HelpcenterService extends ApiService {

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public sanitizer: DomSanitizer,
    public languageService: LanguageService,
    public scopeService: ScopeService
  ) {
    super(http, authenticationService);
  }

  getHelpCenter(): Observable<HelpcenterSection[]> {
    return new Observable<HelpcenterSection[]>(observer => {
      let entry: 'hcp_education' | 'hcp' | 'hcp_general_unauthorized';

      if(this.authenticationService.getAuthenticationData()) {
        entry = !this.scopeService.doesCurrentPatientHasClicinalCareModule() ? 'hcp_education' : 'hcp';
      } else {
        entry = 'hcp_general_unauthorized';
      }

      const url = environment.cmsUrl + '/v3/content_types/help_center/entries?limit=1&query={"title":"'+ entry +'"}';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, null, true).subscribe(result => {
        if (result['entries'] && result['entries'][0] && result['entries'][0]['sections']) {
          const sections = this.mapSections(result['entries'][0]['sections']);

          this.getSectionContents(sections).subscribe(() => {
            observer.next(sections);
            observer.complete();
          });
        } else {
          observer.error();
        }
      }, error => {
        observer.error(error);
      });
    });
  }

  getSectionContents(sections: Array<HelpcenterSection>): Observable<any> {
    return new Observable(observer => {
      let calls = [];

      sections.forEach(section => {
        calls.push(this.getSectionContent(section));
      });

      forkJoin(calls).subscribe(() => {
        observer.next(sections);
        observer.complete();
      });
    });
  }

  getSectionContent(section: HelpcenterSection): Observable<any> {
    return new Observable(observer => {
      let call: Observable<any> = null;

      if (section.isArticles) {
        call = this.getArticles(section.referenceUids);
      }

      if (section.isFaqs) {
        call = this.getFaqs(section.referenceUids);
      }

      if (section.isContact) {
        call = this.getContactInformation(section.referenceUids);
      }

      if (call) {
        call.subscribe(result => {
          if (section.isArticles) {
            section.addArticles(result);
          }

          if (section.isFaqs) {
            section.faqs = result;
          }

          if (section.isContact) {
            section.addContacts(result);
          }

          observer.next(section);
          observer.complete();
        });
      } else {
        observer.next(section);
        observer.complete();
      }
    });
  }

  getArticles(ids?): Observable<any> {
    return new Observable(observer => {
      const url = environment.cmsUrl + '/v3/content_types/help_article/entries';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, ids, true).subscribe(result => {
        const articles = this.mapArticles(result['entries']);
        observer.next(articles);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getFaqs(ids: string[]): Observable<FaqCategory[]> {
    return new Observable(observer => {
      const url = environment.cmsUrl + '/v3/content_types/faq/entries';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, ids, true).subscribe(result => {
        const categories = [];
        result['entries'].forEach(entry => {
          const items = entry['paragraphs']['items'].filter(item => { return item['category'] })
          items.forEach(item => {
            categories.push(new FaqCategory(item.category));
          });
        });

        observer.next(categories);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  getContactInformation(ids: string[]): Observable<HelpcenterContact[]> {
    return new Observable(observer => {
      const url = environment.cmsUrl + '/v3/content_types/contact_information/entries';
      const locale = this.languageService.getCurrentLanguage().locale;

      this.cmsGet(url, locale, null, ids, true).subscribe(result => {
        const contacts = this.mapContacts(result['entries']);
        observer.next(contacts);
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  mapArticles(items) {
    const articles: HelpcenterArticle[] = [];
    items.forEach(item => {
      articles.push(new HelpcenterArticle(item));
    });
    return articles;
  }

  mapContacts(items) {
    const contacts: HelpcenterContact[] = [];
    items.forEach(item => {
      contacts.push(new HelpcenterContact(item));
    });
    return contacts;
  }

  mapSections(items) {
    const sections: HelpcenterSection[] = [];
    items.forEach(item => {
      sections.push(new HelpcenterSection(item));
    });
    return sections;
  }
}
