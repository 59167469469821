import { Pipe, PipeTransform } from '@angular/core';
import { TranslationInterface } from '../interfaces/translation.interface';

@Pipe({name: 'translateHelp'})
export class TranslateHelp implements PipeTransform {
  constructor() {}

  transform(translation: TranslationInterface): string {
    if(!translation) {
      return;
    }

    if(typeof(translation)!=='string' 
    && (translation?.hasOwnProperty('region') || translation?.hasOwnProperty('key'))) {
      return `shared.${translation?.region}.${translation?.key}`;  
    } else {
      return String(translation);
    }
  }
}