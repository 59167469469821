<div class="modal-body" [ngClass]="{'validation-visible': validationVisible}">
  <form [formGroup]="form">
    <button type="button" class="close" id="edit-email-modal-close" data-dismiss="modal" aria-label="Close" (click)="handleCancel()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2>{{ 'modals.edit_contact_details.edit_contact_details' | translate }}</h2>

    <div *ngIf="(step === 'email')">
      <div class="form-group styled floating-label">
        <input type="email" id="inputEmail" class="form-control w-100" [placeholder]="('form.labels.email_address' | translate)" formControlName="email" email>
        <label for="inputEmail" class="required">{{ 'form.labels.email_address' | translate }}</label>
        <app-feedback-field [field]="form.get('email')"></app-feedback-field>
      </div>
    </div>

    <div *ngIf="(step === 'code')">
      <p>{{ 'modals.edit_contact_details.enter_code_email' | translate }}</p>

      <div class="form-group styled floating-label">
        <input type="text" id="inputCode" class="form-control w-100" [placeholder]="('modals.edit_contact_details.verification_code' | translate)" formControlName="code" required>
        <label for="inputCode">{{ 'modals.edit_contact_details.verification_code' | translate }}</label>
        <app-feedback-field [field]="form.get('code')"></app-feedback-field>
        <p class="mt-1"><a href="" (click)="resendCode($event)">Resend code</a></p>


      </div>
    </div>

    <div id="verification-code-validated" class="bg-light p-3" *ngIf="(step === 'forget')">
      <h3 class="mb-1">
        <em [inlineSVG]="'/assets/svg-color/safety.svg'"></em>
        <span class="align-middle">{{ 'modals.edit_contact_details.forget_title' | translate }}</span>
      </h3>
      <p>{{ 'modals.edit_contact_details.forget_description_email'| translate }}</p>
      <a href="/settings/security" class="text-secondary font-weight-bold">{{ 'modals.edit_contact_details.forget_link'| translate }}</a>
    </div>

    <p class="text-info font-weight-bold">{{ 'modals.edit_contact_details.warning' | translate }}</p>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-email-modal-cancel" (click)="handleCancel()" *ngIf="(step !== 'forget')">{{ 'action.cancel' | translate }}</button>


    <button class="btn btn-secondary m-start-3" id="edit-email-modal-next-step" [ngClass]="{'loader': isLoading}" *ngIf="(step === 'email') && isEmailChanged()" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span *ngIf="(step === 'email')">{{ 'action.next_step' | translate }}</span>
      <!--<span *ngIf="(step === 'code')">{{ 'action.confirm' | translate }}</span> -->
    </button>

    <button class="btn btn-secondary m-start-3" id="edit-email-modal-confirm" [ngClass]="{'loader': isLoading}" *ngIf="((step === 'code') || !isEmailChanged()) && step !=='forget'" (click)="handleSubmit()">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
      <span>{{ 'action.confirm' | translate }}</span>
    </button>

    <button class="btn btn-secondary" id="edit-email-modal-close"  *ngIf="(step === 'forget')" (click)="handleCancel()">
      <span>{{ 'action.close' | translate }}</span>
    </button>

  </div>
</div>
