import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { HealthCareProfessional } from "../../models/health-care-professional";

@Component({
  selector: 'app-query-list-participants-modal',
  templateUrl: './query-list-participants-modal.component.html',
})
export class QueryListParticipantsModalComponent {
  public participants:Array<HealthCareProfessional>;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  handleClose() {
    return this.bsModalRef.hide();
  }

}
