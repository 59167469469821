import { Component } from '@angular/core';
import { Participant } from '../../models/participant';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-conversation-participants-modal',
  templateUrl: './conversation-participants-modal.component.html',
  styleUrls: ['./conversation-participants-modal.component.scss']
})
export class ConversationParticipantsModalComponent {
  public participants:Array<Participant>;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  handleClose() {
    return this.bsModalRef.hide();
  }
}
