import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HcpService } from '../../services/hcp.service';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../models/patient';

@Component({
  selector: 'app-resend-onboarding-email-modal',
  templateUrl: './resend-onboarding-email-modal.component.html',
  styleUrls: ['./resend-onboarding-email-modal.component.scss']
})
export class ResendOnboardingEmailModalComponent {
  @Output() onResend: EventEmitter<any> = new EventEmitter();

  public patient: Patient;
  public isSending: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    public hcpService: HcpService,
    public patientService: PatientService,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) { }

  handleSubmit() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();

    this.isSending = true;
    this.patientService.resendOnboardingEmailForPatient(hospitalUid, this.patient.uid).subscribe(result => {
      this.resultHandler();
    });
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  resultHandler() {
    this.isSending = false;

    this.toastrService.info(this.translate.instant('modals.resend_onboarding_email.success'), null, {
      disableTimeOut: false,
      timeOut: 4000
    });

    this.bsModalRef.hide();
    this.onResend.emit(true);
  }
}
