import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { MfaMethod } from '../models/mfa-method';

@Injectable({
  providedIn: 'root'
})
export class MfaService extends ApiService {
  private readonly platformUrl: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    router: Router
  ) {
    super(http, authenticationService);
    this.platformUrl = environment.platformUrl;
  }

  requestCode(mfaMethod: MfaMethod): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + '/oauth/request-mfa-code';

      const params: any = {
        channel: mfaMethod.channel
      };

      this.authenticatedPost(url, params, 'v2').subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }

  postCode(code: string, mfaMethod: MfaMethod): Observable<any> {
    return new Observable(observer => {
      const url = this.platformUrl + '/oauth/mfa';

      const params: any = {
        code,
        channel: mfaMethod.channel
      };

      this.authenticatedPost(url, params, 'v2').subscribe(result => {
        observer.next(result);
      }, error => {
        observer.error(error);
      });
    });
  }
}
