import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslationInterface } from '../../interfaces/translation.interface';
import { FormField } from '../../models/form-field';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html'
})
export class DynamicInputComponent implements OnInit {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() onEvaluationRequired: EventEmitter<any> = new EventEmitter<any>();

  public control: AbstractControl;

  public enumValueAscOrder = (
    a: KeyValue<string, TranslationInterface>,
    b: KeyValue<string, TranslationInterface>
  ): number => {
    if (a.value.order && b.value.order) {
      return a.value.order - b.value.order;
    } else {
      return 0;
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.control = this.form.get(this.field.id);

    // set value
    if (this.field.field_value.value_type == 'DATE_TIME') {
      this.setupDateTimePicker();
    } else if (this.field.field_value.value_type == 'DATE') {
      this.setupDatePicker();
    } else {
      this.control?.setValue(this.field.field_value.value);
    }

    // disable
    if (this.field.read_only) {
      this.control.disable();
    }

    // validators
    const validators = this.createValidators(this.field);
    this.control.clearValidators();
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  ngOnDestroy() {
    // clear field when closed, this will make it null when reopened
    this.field.field_value.value = null;

    this.control.clearValidators();
    const validators = this.createValidators(this.field);
    this.control.setValidators(validators);

    this.control.reset();
    this.control.updateValueAndValidity();
    this.form.updateValueAndValidity();
  }

  createValidators(field): ValidatorFn[] {
    const validators = [];

    if (field.required && field.visible) {
      validators.push(Validators.required);
    }

    if (field.field_value.min) {
      validators.push(Validators.min(field.field_value.min));
    }

    if (field.field_value.max) {
      validators.push(Validators.max(field.field_value.max));
    }

    if (field.field_value.min_length) {
      validators.push(Validators.minLength(field.field_value.min_length));
    }

    if (field.field_value.max_length) {
      validators.push(Validators.maxLength(field.field_value.max_length));
    }
    return validators;
  }

  setupDateTimePicker() {
    if (this.field && this.field.field_value.value) {
      this.control?.setValue(new Date(this.field.field_value.value));
    }
  }

  setupDatePicker() {
    if (this.field && this.field.field_value.value) {
      this.control?.setValue(this.field.field_value.value);
    }
  }

  onChange(event: Event): void {
    this.formChange.emit(this.form);

    if (this.field.evaluation_required) {
      this.onEvaluationRequired.emit();
    }
  }
}
