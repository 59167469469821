<div class="body-content">

  <div class="container" *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container position-relative"  *ngIf="!isLoading">
    <mwl-calendar-week-view *ngIf="renderMwlView"
      class="style-no-interaction"
      [viewDate]="viewDate"
      [events]="events"
      [weekStartsOn]="weekStartsOn"

      (eventClicked)="calendarEventClickHandler($event)"
    >
    </mwl-calendar-week-view>
  </div>
</div>
