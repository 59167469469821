<div id="zone_checklists" class="mb-5">
  <div class="heading-block mb-3">
    <div class="block-wrapper overflow-hidden m-end-auto">
      <h2 class="text-truncate">{{ 'pages.default.patient_detail.zone_checklists' | translate }}</h2>
    </div>
    <a href="" id="show-more-checklists" class="block-action mx-0" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/query-lists']" [queryParams]="{filter: 'CHECKLIST'}" >
      <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
    </a>
  </div>

  <div class="bg-white px-2 py-2" *ngIf="loading">
    <app-page-loader></app-page-loader>
  </div>

  <div *ngIf="!loading">
    <div class="bg-white px-2 py-4" *ngIf="queryLists?.length == 0">
      <p class="m-0 text-center text-muted">{{ 'pages.default.patient_detail.patient_no_checklists' | translate }}</p>
    </div>

    <div class="row">
      <div class="col-4 mb-2" *ngFor="let form of queryLists">
        <div class="card h-100" [ngClass]="{'card-muted': grayedOut(form)}">
          <div class="card-body card-body">

            <p class="text-muted small mb-1">
              <span *ngIf="form.status === QueryListStatus.COMPLETED">{{ 'pages.default.query_lists.submitted_on' | translate }} {{ form.completed_at | timeZoneDate }}</span>
              <span *ngIf="form.status === QueryListStatus.TIMED_OUT">{{ 'pages.default.query_lists.due_at' | translate : {value:form.due_days_ago?.toString() } }}</span>
            </p>

            <h3>{{ form.title | translateHelp | translate }}</h3>

            <div class="user-compact mb-2" *ngIf="form.candidate_users.length < 2">
              <app-avatar [profilePicture]="form.assignee?.profile_picture"></app-avatar>

              <div class="user-compact-info">
                <p class="title text-truncate">{{ form.assignee?.getFullName() }}</p>
                <p *ngIf="form.assignee?.uid === hcpUid">{{ 'pages.default.query_lists.you' | translate }}</p>
                <p *ngIf="form.assignee?.uid != hcpUid">{{ form.assignee?.job_title }}</p>
                <p *ngIf="form.assignee_type === 'PATIENT'">{{ 'pages.default.query_lists.patient' | translate }}</p>
              </div>
            </div>

            <ng-container *ngIf="form.candidate_users.length > 1">
              <app-avatar class="m-end-1 mb-1" [profilePicture]="user?.profile_picture" *ngFor="let user of form.sortedCandidateUsers(hcpUid)"></app-avatar>
            </ng-container>
          </div>
          <div class="card-footer">
            
            <div class="font-weight-semibold d-flex align-items-center text-info" *ngIf="form.status === QueryListStatus.NEW">
              <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/dot.svg'"></span>
              {{ 'pages.default.query_lists.status.new' | translate }}
            </div>
            
            <div class="font-weight-semibold d-flex align-items-center" *ngIf="form.status === QueryListStatus.SAVED">
              <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/play.svg'"></span>
              {{ 'pages.default.query_lists.status.saved' | translate }}
            </div>
            
            <div class="font-weight-semibold d-flex align-items-center text-muted" *ngIf="form.status === QueryListStatus.COMPLETED">
              <span class=" m-end-1 " [inlineSVG]="'/assets/svg/check-free.svg'"></span>
              {{ 'pages.default.query_lists.status.completed' | translate }}
            </div>
            
            <div class="font-weight-semibold d-flex align-items-center text-muted" *ngIf="form.status === QueryListStatus.TIMED_OUT">
              <span class="rtl-mirrored-inline-svg m-end-1 " [inlineSVG]="'/assets/svg/close.svg'"></span>
              {{ 'pages.default.query_lists.status.timed_out' | translate }}
            </div>


            <a href="" class="btn btn-secondary m-start-auto" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/' + '/query-lists/' + form.uid]" [queryParams]="{filter: 'CHECKLIST'}">
              <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p class="text-end" *ngIf="totalChecklists > 3">
    <a href="" id="link_checklists_show_more" class="text-icon-link hoverable text-secondary" [routerLink]="['/patient/' + patientUid + '/' + pathwayUid + '/query-lists']" [queryParams]="{filter: 'CHECKLIST'}" >
      <span class="label">{{ 'pages.default.patient_detail.show_all' | translate }} ( {{ totalChecklists }} ) </span>
      <span class="icon-end">
        <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
      </span>
    </a>
  </p>
</div>
