import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DataService } from '../services/data.service';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private dataService: DataService
  ) { }

  transform(url: string) {

    return new Observable<any>((observer) => {
      // Check if file is in cache
      if (!url) {
        return {unsubscribe() {  }};
      }

      const urlParts = url.split('/');
      const uid = urlParts[urlParts.length - 1];

      if (this.dataService.get(`PROFILE_PICTURE_${uid}`)) {
        observer.next(this.dataService.get(`PROFILE_PICTURE_${uid}`));
      } else {
        // This is a tiny blank image
        observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

        // The next and error callbacks from the observer
        if (uid !== 'undefined') {
          const headers = this.apiService.getAuthHeaders();

          this.http.get(url, {headers, responseType: 'blob'}).subscribe(response => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
              observer.next(reader.result);
            };
          });

          return {unsubscribe() {  }};
        } else {
          this.http.get('/assets/images/default-avatar.png', { responseType: 'blob' }).subscribe(response => {
            const reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
              observer.next(reader.result);
            };
          }); 
        }
      }
    });
  }
}
