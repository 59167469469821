import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  public pagesList: Array<number>;

  private maxVisibleCount: number = 5;
  public visibleCount: number;

  constructor() {
  }

  ngOnInit() {

  }

  @Input()
  set blockInteraction(blockInteraction: boolean) {
    this._blockInteraction = blockInteraction;
  }
  private _blockInteraction: boolean;

  @Input()
  set activePage(activePage: number) {
    if(this._activePage!==activePage) {
      this._activePage = activePage;
      this.definePages();
    }
  }
  get activePage(): number {
    return this._activePage;
  }
  private _activePage: number;

  @Input()
  set totalPages(totalPages: number) {
    if(this._totalPages!==totalPages) {
      this._totalPages = totalPages;
      this.definePages();
    }
  }
  get totalPages(): number {
    return this._totalPages;
  }
  private _totalPages: number;

  definePages() {
    this.pagesList = [];

    if(this.totalPages < this.maxVisibleCount) {
      this.visibleCount = this.totalPages;
    } else {
      this.visibleCount = this.maxVisibleCount;
    }

    const preCount = this.activePage - Math.floor(this.visibleCount/2);

    let startIndex = 1;
    let endIndex = this.visibleCount;

    if(preCount > 0) {
      startIndex = preCount;
      endIndex = (startIndex + this.visibleCount - 1);
    }

    if(endIndex>this.totalPages) {
      endIndex = this.totalPages;
    }

    const diffBetweenVisibleAndActualStart = (endIndex-startIndex+1);
    if(diffBetweenVisibleAndActualStart < this.visibleCount) {
      startIndex -= (this.visibleCount - diffBetweenVisibleAndActualStart);
    }

    for(let i=startIndex; i<= endIndex; i++) {
      this.pagesList.push(i);
    }
  }

  actionSetPage(page: number) {
    if(!this._blockInteraction) {
      this.setAndEmitActivePage(page);
    }
  }

  actionPrev() {
    if(!this._blockInteraction) {
      this.setAndEmitActivePage(this.activePage-1);
    }
  }

  actionNext() {
    if(!this._blockInteraction) {
      this.setAndEmitActivePage(this.activePage+1);
    }
  }

  setAndEmitActivePage(page) {
    this.activePage = page;
    this.pageChange.emit(this.activePage);
  }

  hasMoreAfterLast():boolean {
    if(!this.pagesList || !this.pagesList.length) {
      return false;
    }

    const last = this.pagesList[this.pagesList.length-1];
    return last < this.totalPages;
  }

  hasMoreBeforeFirst():boolean {
    if(!this.pagesList || !this.pagesList.length) {
      return false;
    }

    const first = this.pagesList[0];
    return first > 1;
  }
}
