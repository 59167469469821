<div class="modal-body">
  <form [formGroup]="form">
    <button type="button" id="surgery-date-modal-close" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2 *ngIf="formSet">{{ 'modals.surgery_date.title.set' | translate}}</h2>
    <h2 *ngIf="!formSet">{{ 'modals.surgery_date.title.edit' | translate}}</h2>
    <div class="form-group styled required floating-label">
      <input type="text" id="surgeryDate" class="form-control w-100" formControlName="surgeryDate" required bsDatepicker>
      <label for="surgeryDate" class="required">{{ 'modals.surgery_date.form.date' | translate }}</label>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="surgery-date-modal-handle-cancel" (click)="handleCancel()">{{ 'action.cancel' | translate }}</button>

    <button class="btn btn-secondary m-start-3" id="surgery-date-modal-handle-edit" [ngClass]="{'loader': isSaving}" (click)="handleSave()" [disabled]="!formInitialized">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
