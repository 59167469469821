import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  constructor(
    private readonly router: Router
  ) {
  }

  deeplink(deepLinkUrl: string): void {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    const isIphone = ua.indexOf('iphone') > -1;
    const isMobile = isAndroid || isIphone;

    if (!deepLinkUrl || !isMobile) {
      this.router.navigateByUrl('/');
    } else if (isIphone) {
      try {
        window.location.href = deepLinkUrl;
      } catch (e) {
        this.router.navigateByUrl('/');
      }
    } else if (isAndroid) {
      try {
        window.location.replace(deepLinkUrl);
      } catch (e) {
        this.router.navigateByUrl('/');
      }
    }
  }
}
