import { Host, Optional, SkipSelf, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true
    }
  ]
})
export class DateTimeInputComponent implements OnInit {
  private control: AbstractControl;

  public editDateValue: Date;
  public editTimeValue: Date;

  public value: any;

  public onChange: any = () => { };
  public onTouched: any = () => { };
  public disabled: boolean;

  public time_24_hours: boolean;

  @Input() name: String;
  @Input() formControlName: string;
  @Output() change: EventEmitter<string> = new EventEmitter();

  constructor(
    public localeService: LocaleService,

    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    this.time_24_hours = this.localeService.getLocalePreferences().locale?.time_24_hours;

    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }
  }

  writeValue(value: Date): void {
    if(value) {
      const dateWithoutTimeZone: Date = this.localeService.dateWithoutTimeZone(value);
      this.editDateValue = dateWithoutTimeZone;
      this.editTimeValue = dateWithoutTimeZone;

      this.value = this.localeService.dateWithTimeZone(value);
    } else {
      this.editDateValue = null;
      this.editTimeValue = null;

      this.value = null;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateNgModelChange(date: Date) {
    this.tryCompileValue();
  }

  onTimeNgModelChange(date: Date) {
    this.tryCompileValue();
  }

  tryCompileValue() {
    let value: any;

    if(this.editDateValue && this.editTimeValue) {
      if(!this.value) {
        value = new Date();
      }

      this.editTimeValue.setDate(this.editDateValue.getDate());
      this.editTimeValue.setMonth(this.editDateValue.getMonth());
      this.editTimeValue.setFullYear(this.editDateValue.getFullYear());

      value = this.localeService.dateWithTimeZone(this.editTimeValue);

    } else if(this.editDateValue) {
      value = '';
    } else if(this.editTimeValue) {
      value = '';
    } else {
      value = null;
    }

    if(String(value) !== String(this.value)) { //Object to object evaluation does't work. Stringify it is! To only mark the comp as touched, this looks safe enough.
      this.onTouched();
    }

    this.value = value;
    this.onChange(this.value);
    this.change.emit(this.value);
  }

  public get editDateValid(): boolean {
    if(this.control?.errors?.required && !this.editDateValue) {
      return false;
    } else if(!this.control?.errors?.required && this.editTimeValue && !this.editDateValue) {
      return false
    }

    return true;
  }

  public get editTimeValid(): boolean {
    if(this.control?.errors?.required && !this.editTimeValue) {
      return false;
    } else if(!this.control?.errors?.required && this.editDateValue && !this.editTimeValue) {
      return false
    }

    return true;
  }

  public get isValid(): boolean {
    return this.editDateValid && this.editTimeValid;
  }
}
