import { UserProfileLocale } from './user-profile-locale';
import { PhoneNumberInterface } from '../interfaces/phone-number.interface';
import { MfaMethod } from './mfa-method';

enum ContactChannel {
  EMAIL,
  PHONE
}

export class Profile {
  public uid: string;
  public email: string;
  public first_name: string;
  public last_name: string;
  public preferred_name: string;
  public mobile_number: PhoneNumberInterface;
  public profile_picture: string;
  public locale: UserProfileLocale;
  public contact_channel: ContactChannel;
  public contact_consented: boolean;
  public mobile_verification_code: string;
  public mfa_enabled: boolean;
  public mfas: Array<MfaMethod>;

  constructor(item?) {
    if (!item) {
      return;
    }

    this.uid = item.uid;
    this.email = item.email;
    this.first_name = item.first_name;
    this.last_name = item.last_name;
    this.preferred_name = item.preferred_name;
    this.profile_picture = item.profile_picture;
    this.mobile_number = item.mobile_number;
    this.contact_channel = item.contact_channel;
    this.contact_consented = item.contact_consented;
    this.mfa_enabled = item?.mfa_enabled;

    if (item.locale) {
      this.locale = new UserProfileLocale(item.locale);
    }

    if(item?.mfas) {
      this.mfas = item.mfas.map((_mfa) => new MfaMethod(_mfa));
    }
  }

  get mfaPrimaryMethods(): MfaMethod[] {
    return this.mfas.filter((mfaMethod: MfaMethod) => mfaMethod.primary)
  }

  get mfaBackupMethods(): MfaMethod[] {
    return this.mfas.filter((mfaMethod: MfaMethod) => !mfaMethod.primary)
  }
}
