export class PhaseDna {
  public uid: string;
  public title: string;
  public titleKey: string;
  public value: number;
  public percent: number;
  public translationKey: string;
  public order: number;
  public has_sub_phases: boolean;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.value = item.in_progress_count;
    this.percent = item.in_progress_pct;
    this.translationKey = `shared.${item.phase?.name.region}.${item.phase?.name.key}`;
    this.order = item.phase?.order;
    this.uid = item.phase?.id;
    this.has_sub_phases = item.has_sub_phases;
  }
}