import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CareModule } from '../../../../models/care-module';
import { TranslateNumberPipe } from '../../../../pipes/translate-number.pipe';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-admin-care-modules',
  templateUrl: './admin-care-modules.component.html'
})
export class AdminCareModulesComponent implements OnInit {
  public isLoading: boolean = false;
  public careModules: CareModule[];
  public hospitalUid: string;

  public currentPage = 1;
  public currentPageSize = 10;
  public totalPages: number;
  public totalElements: number;

  constructor(
    public hospitalService: HospitalService,
    public hcpService: HcpService,
    public translateNumber: TranslateNumberPipe,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.getCareModules();
  }

  getCareModules(showLoading = true): void {
    if (showLoading) {
      this.isLoading = true;
    }

    this.hospitalService.getCareModulesPaginated(
      this.hospitalUid,
      this.currentPage - 1,
      this.currentPageSize
    ).subscribe((result: { pagination: any, careModules: CareModule[] }) => {
      this.careModules = result.careModules;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }

      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getCareModules();
    }
  }

  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getCareModules();
    }
  }

  getLabelForTotalElements(): string {
    let translatedNumber = this.translateNumber.transform(this.totalElements);
    let itemsLabel = (this.totalElements > 1) ? this.translateService.instant('components.pagination.items_total') : this.translateService.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + " " + translatedNumber;
    }

    return translatedNumber + " " + itemsLabel;
  }
}
