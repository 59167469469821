import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  @Output() public onChoice: EventEmitter<boolean> = new EventEmitter();
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() showYesLoading: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() yes: string;
  @Input() no: string;

  public value: boolean;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(){
    
  }

  ngOnDestroy(){
    this.onClose.emit();
  }

  handleYes() {
    if(!this.showYesLoading) {
      this.value = true;
      this.onChoice.emit(this.value);
    }
  }

  handleNo() {
    this.handleClose();
  }
  
  handleClose() {
    if(!this.showYesLoading) {
      this.value = false;
      return this.bsModalRef.hide();
    }
  }
}
