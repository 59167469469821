import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class NotMatchingFieldsValidator {
  static validate(field1: string, field2: string, errorKey) {
    return (group: UntypedFormGroup): {[key: string]: any} => {
      const control: AbstractControl = group.get(field1);
      const matchControl: AbstractControl = group.get(field2);

      if (!control.pristine && !matchControl.pristine && control.value === matchControl.value) {
        const error = {};
        error[errorKey] = true;
        return error;
      } else {
        return null;
      }
    };
  }
}

