<div [ngClass]="{'validation-visible': validationVisible}" class="modal-body">
  <button type="button" class="close" id="edit-mdt-modal-close" data-dismiss="modal" aria-label="Close" (click)="onHandleClose()">
    <span [inlineSVG]="'/assets/svg/close.svg'"></span>
  </button>

  <h2 class="mb-3">{{ 'modals.mdt.edit_mdt' | translate }}</h2>

  <form [formGroup]="form">
    <div class="form-group styled required floating-label mb-3">
      <input [placeholder]="('form.labels.input_label_name_team' | translate)"
             class="form-control w-100"
             formControlName="name"
             id="inputMdtName"
             required
             type="text">
      <label for="inputMdtName">{{ 'form.labels.input_label_name_team' | translate }}</label>
      <app-feedback-field [field]="form.get('name')"></app-feedback-field>
    </div>
  </form>

  <div class="form-group styled">
    <ng-select
      (ngModelChange)="onAddLosslessHcp($event)"
      (search)="searchHcpListEvent.next($event)"
      [loading]="isLoadingHcps"
      (open)="searchHcpList()"
      [(ngModel)]="losslessHcpSelectModel"
      [items]="hcpSearchResults"
      [placeholder]="('modals.mdt.edit_mdt_hcps' | translate)"
      [searchFn]="customSearchFn"
      [closeOnSelect]="false"
      class="users-select mb-2"
      id="inputMdtHcps"
    >
      <ng-template let-item="item" ng-label-tmp>
        <span *ngIf="item">{{ item.getFullName() }}</span>
      </ng-template>

      <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
        <div *ngIf="item" class="wrapper">
          <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>
          <div class="user-info">
            <p class="title">{{ item?.getFullName() }}</p>
            <p class="sub-text">{{ item?.job_title }}</p>
          </div>
          <div *ngIf="isHcpSelected(item)" class="status small text-muted">
            <span>{{'modals.mdt.already_added' |  translate}}</span>
          </div>
          <div *ngIf="!isHcpSelected(item)" class="status small text-secondary">
            <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
      </ng-template>
    </ng-select>

    <div *ngFor="let hcp of losslessHcpSelection" class="participant-item">
      <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
      <div class="meta">
        <p class="title">{{ hcp?.getFullName() }}</p>
        <p class="description">{{ hcp?.job_title }}</p>
      </div>

      <a (click)="onRemoveLosslessHcp($event, hcp)" class="status text-secondary" [id]="'edit-mdt-modal-remove-lossless-hcp-' + hcp.uid" href="">
        <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
    <p *ngIf="!hcpsAreValid() && !validationVisible" class="small text-muted pt-2">
      {{'modals.mdt.no_team_members_added' |  translate}}
    </p>
    <p *ngIf="!hcpsAreValid() && validationVisible"
       class="text-secondary small">{{ 'form.feedback.one_hcp_required' | translate }}
    </p>

    <p *ngIf="hcp_uid_error && validationVisible"
       class="text-secondary small">{{ 'shared.business_error_keys.' + hcp_uid_error | translate }}
    </p>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button class="btn btn-link" id="edit-mdt-modal-cancel" (click)="onHandleClose()">{{ 'action.cancel' | translate }}</button>
    <button (click)="onHandleSubmit()" [ngClass]="{'loader': isSaving}" class="btn btn-secondary m-start-3" id="edit-mdt-modal-submit">
      <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isSaving"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
