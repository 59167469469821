<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back-filters"></div>

  <div class="layout-header header-back-filters">
    <div class="header-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">
            <p>
              <a [routerLink]="['/administration']" class="text-secondary font-weight-bold d-flex align-items-center" id="admin-hcps-back">
                <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
                <span class="align-middle">{{ 'action.back_to_administration' | translate }}</span>
              </a>
            </p>
          </div>
          <div class="col col-auto">
            <a href="" class="btn btn-sm btn-secondary m-start-1" id="admin-hcps-add-hcp"
              (click)="onShowAddHcpModal($event)">{{ 'pages.default.administration.hcps.add_hcp' | translate }}</a>
          </div>
        </div>
      </div>

      <div class="container">
        <h1>{{ 'pages.default.administration.hcps.hcps' | translate }}</h1>
      </div>

      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col col-auto">

            <div class="filters-group">

              <div class="form-group styled floating-label">
                <input type="text" id="searchHcpLastName" class="form-control w-100" [placeholder]="('form.labels.hcp_last_name' | translate)"
                      [(ngModel)]="filters.hcpLastName"
                      (ngModelChange)="searchHcpLastNameEvent.next($event)" required>
                <label for="searchHcpLastName">{{ 'form.labels.hcp_last_name' | translate }}</label>
              </div>

              <div class="divider d-none"></div>

              <app-select
                id="admin-hcp-select-function"
                class="d-inline-block"
                [selectClass]="'d-none'"
                [placeholder]="('form.labels.function' | translate)"
                [clearable]="true"
                [searchable]="true"
                [hideSelected]="false"
                [multiple]="true"
                [items]="functions"

                [(ngModel)]="functionFilter"
                (ngModelChange)="onFunctionFilterChange($event)"

                [asFilter]="true"
                [showCheckboxes]="true"
              ></app-select>

              <div class="divider" *ngIf="filtersAreSet"></div>

              <button type="button" class="btn btn-link text-info py-0" id="admin-hcps-reset-filters" *ngIf="filtersAreSet" (click)="onResetFilters()">{{ 'action.reset' | translate }}</button>
            </div>

          </div>

          <div class="col col-auto">
            <div class="btn-group text-dropdown text-dropdown-sm" dropdown>
              <button id="dropdown-btn-sort" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <span class="m-end-1">{{ 'general.sort_by' | translate }}:</span>

                <span *ngIf="sorting == 'last_name,asc'">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'last_name,desc'">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'created_at,asc'">{{ 'form.labels.date_added_sort_asc' | translate }}&#x200E;</span>
                <span *ngIf="sorting == 'created_at,desc'">{{ 'form.labels.date_added_sort_desc' | translate }}&#x200E;</span>

                <span [inlineSVG]="'/assets/svg/chevron-down.svg'" class="icon"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dropdown-btn-sort">
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name', 'asc')">{{ 'form.labels.last_name_sort_asc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'last_name', 'desc')">{{ 'form.labels.last_name_sort_desc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'created_at', 'asc')">{{ 'form.labels.date_added_sort_asc' | translate }}&#x200E; </a></li>
                <li role="menuitem"><a class="dropdown-item" href="" (click)="onChangeSort($event, 'created_at', 'desc')">{{ 'form.labels.date_added_sort_desc' | translate }}&#x200E; </a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="body-content">
    <div class="container" *ngIf="isLoading">
      <app-page-loader></app-page-loader>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div *ngFor="let hcp of hcps; let index = index;">
        <div id="hcp-{{index}}" class="single-item-block mb-2" [ngClass]="{'block-muted': hcp?.status !== 'ACTIVE'}">
          <div class="block-wrapper p-end-0">
            <app-avatar class="avatar-lg" [profilePicture]="hcp?.profile_picture"></app-avatar>
          </div>

          <div class="row d-flex align-items-center w-100">
            <div class="col-3">
              <div class="block-wrapper">
                <p class="label text-truncate">{{hcp.getFullName()}}</p>
                <p class="value text-truncate">{{ hcp.job_title }}</p>
              </div>
            </div>
            <div class="col-4">
              <div class="block-wrapper">
                <p class="text-dark text-truncate" *ngIf="scopeService.getCurrentHospitalBasic()?.name">{{scopeService.getCurrentHospitalBasic()?.name}}</p>
                <p class="text-dark text-truncate" *ngIf="!scopeService.getCurrentHospitalBasic()?.name">
                  {{'pages.default.administration.hcps.unknown_hospital' | translate}}
                </p>
              </div>
            </div>
            <div class="col-5">
              <div class="block-wrapper">
                <ng-container *ngIf="(hcp.status === 'ACTIVE'); else nonActive">
                  <p class="text-truncate mb-1">
                    <a href="mailto:{{hcp.email}}" class="text-secondary text-semibold" [id]="'admin-hcps-email-' + hcp.uid">
                      <span [inlineSVG]="'/assets/svg/email.svg'" class="icon small m-end-1"></span>
                      <span class="align-middle">{{hcp.email}}</span>
                    </a>
                  </p>
                  <p *ngIf="hcp.phone_number?.number" class="text-truncate text-dark">
                    <span [inlineSVG]="'/assets/svg/phone.svg'" class="icon small rtl-mirrored-inline-svg m-end-1 align-middle"></span>
                    <span class="align-middle">{{getPhonePrefix(hcp.phone_number)}} {{hcp.phone_number?.number}}</span>
                  </p>
                </ng-container>
                <ng-template #nonActive>
                  <p id="hcp-{{index}}-non-active" class="text-truncate">
                    <span [inlineSVG]="'/assets/svg/' + getIconForStatus(hcp.status) + '.svg'" class="icon small rtl-mirrored-inline-svg m-end-1 align-middle"></span>
                    <span class="align-middle">{{'pages.default.administration.hcps.status.' + hcp.status | translate}}</span>
                  </p>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="block-action-wrapper justify-content-end" style="min-width: 60px">
            <a (click)="onShowEditHcpModal($event, hcp)" class="block-action action-compact" [id]="'admin-hcps-edit-hcp-' + hcp.uid" href="">
              <span [inlineSVG]="'/assets/svg/edit.svg'" class="icon"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && totalElements">
    <div class="layout-footer-pusher footer-paging"></div>
    <div class="layout-footer footer-paging">
      <div class="footer-content">
        <div class="container align-items-center">
          <!-- total items -->
          <div class="m-end-auto">{{ getLabelForTotalElements() }}</div>
          <!-- pagination -->
          <div *ngIf="totalPages > 1"><app-pagination [totalPages]="totalPages" [activePage]="currentPage" (pageChange)="onPaginationPageChanged($event)"></app-pagination></div>
          <!-- pagination range -->
          <div class="m-start-auto"><app-pagination-range [currentRange]="currentPageSize" (rangeChange)="onPaginationRangeChanged($event)" [rangeOptions]="[5,10]"></app-pagination-range></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
