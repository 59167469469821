<div class="layout-header-pusher header-back-tabs"></div>

<div class="layout-header header-back-tabs">
  <div class="header-content">
    <div class="container">
      <p>
        <a [routerLink]="['/settings']" class="text-secondary font-weight-bold d-flex align-items-center" id="settings-preferences-back">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back_to_settings' | translate }}</span>
        </a>
      </p>
    </div>

    <div class="container">
      <h1>{{ 'pages.default.settings.preferences.preferences' | translate }}</h1>
      <app-page-tabs [items]="pageTabItems"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container">
    <div id="zone_units">
      <div class="heading-block">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.settings.preferences.units' | translate }}</h2>
          <p>{{ 'pages.default.settings.preferences.preferences_info' | translate }}</p>
        </div>
      </div>

      <form [formGroup]="unitForm" id="unitForm">
        <div class="mt-3 mb-5">
          <div class="form-group width-limit d-none">
            <app-select
              id="preference-measurement-unit"
              [placeholder]="('pages.default.settings.preferences.units' | translate)"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="measurementUnits"
              [bindValue]="'value'"
              formControlName="measurement_unit"
              (change)="handleUnitFormSubmission()"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              id="preference-number-format"
              [placeholder]="('pages.default.settings.preferences.number_format' | translate)"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="numberFormats"
              [bindValue]="'value'"
              formControlName="number_format"
              (change)="handleUnitFormSubmission()"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              id="preference-date-format"
              [placeholder]="('pages.default.settings.preferences.date_format' | translate)"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="dateFormats"
              [bindValue]="'value'"
              formControlName="date_format"
              (change)="handleUnitFormSubmission()"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              id="preference-time-zone"
              [placeholder]="('pages.default.settings.preferences.time_zone' | translate)"
              [searchable]="true"
              [hideSelected]="false"
              [multiple]="false"
              [items]="timeZones"
              [bindValue]="'value'"
              formControlName="time_zone"
              (change)="handleUnitFormSubmission()"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              id="preference-time-24-hours"
              [placeholder]="('pages.default.settings.preferences.time_format' | translate)"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="timeFormats"
              [bindValue]="'value'"
              formControlName="time_24_hours"
              (change)="handleUnitFormSubmission()"
            ></app-select>
          </div>

          <div class="form-group width-limit">
            <app-select
              id="preference-first-day-of-week"
              [placeholder]="('pages.default.settings.preferences.first_day_of_week' | translate)"
              [searchable]="false"
              [hideSelected]="false"
              [multiple]="false"
              [items]="weekdays"
              [bindValue]="'value'"
              formControlName="first_day_of_week"
              (change)="handleUnitFormSubmission()"
            ></app-select>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
