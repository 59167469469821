import {Component, OnInit, ViewChild, ElementRef, EventEmitter, Output} from '@angular/core';
import {UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {HealthCareProfessional} from "../../models/health-care-professional";
import {LanguageService} from "../../services/language.service";
import {HcpService} from "../../services/hcp.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ErrorService} from "../../services/error.service";
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import { ScopeService } from '../../services/scope.service';
import { SecurePipe } from '../../pipes/secure.pipe';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralService } from '../../services/general.service';
import { LanguageCode } from '../../models/language-code';
import { Observable } from 'rxjs';
import { HcpFunction } from '../../models/hcp-function';

@Component({
  selector: 'app-edit-personal-details-modal',
  templateUrl: './edit-personal-details-modal.component.html',
  styleUrls: ['./edit-personal-details-modal.component.scss']
})
export class EditPersonalDetailsModalComponent implements OnInit {
  @ViewChild('inputProfilePicture') inputProfilePicture: ElementRef;
  @Output() hcpUpdatedEvent = new EventEmitter<void>();

  public hcp: HealthCareProfessional;

  public isSaving: boolean;
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public languageOptions: LanguageCode[];
  public functionOptions: HcpFunction[];
  public profilePicturePreview: string;
  public profilePictureDeleted = false;


  constructor(
    public bsModalRef: BsModalRef,
    public errorService: ErrorService,
    public formBuilder: UntypedFormBuilder,
    public hcpService: HcpService,
    public languageService: LanguageService,
    public router: Router,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public userService: UserService,
    public scopeService: ScopeService,
    public translateService: TranslateService,
    private securePipe: SecurePipe,
    public authenticationService: AuthenticationService,
    public modalService: BsModalService,
    public generalService: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.formSetup();

    this.languageService.getSupportedAppLanguages().subscribe(languages => {
      this.languageOptions = languages;
    });

    if (this.authenticationService.hasCcRole()) {
      this.hcpService.getFunctions(this.hcp.hospital_uid).subscribe(functions => {
        this.functionOptions = functions;
      });
    }

    if (this.hcp.profile_picture && this.hcp.profile_picture.url && this.hcp.profile_picture.uuid) {
      this.securePipe.transform(this.hcp.profile_picture.url).subscribe(result => {
        this.profilePicturePreview = result;
      });
    }
  }

  formSetup() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      profile_picture: [this.hcp?.profile_picture],
      gender: [this.hcp?.gender, [Validators.required]],
      first_name: [this.hcp?.first_name, [Validators.required]],
      last_name: [this.hcp?.last_name, [Validators.required]],
      language: [this.hcp?.language, [Validators.required]],
      job_title: [this.hcp?.job_title, [Validators.required]],
      function: [this.hcp?.function.key, [Validators.required]],
      location: [this.hcp?.location?.name],
      location_url: [this.hcp?.location?.url],
    });

    this.form.get('location').valueChanges.subscribe(value => {
      this.evaluateLocationStates(value);
    });

    this.evaluateLocationStates(this.form.get('location').value);

    if (!this.authenticationService.hasCcRole()) {
      this.form.get('function').disable();
    }
  }

  evaluateLocationStates(value) {
    if(value) {
      this.form.get('location_url').enable();
    } else {
      this.form.get('location_url').setValue(null);
      this.form.get('location_url').disable();
    }
  }

  handleCancel() {
    return this.bsModalRef.hide();
  }

  handleConfirm() {
    if (!this.form.dirty) {
      this.bsModalRef.hide();
      return;
    }

    if (!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.handleSubmission();
    }
  }

  handleSubmission() {
    this.isSaving = true;

    this.hcp.gender = this.form.get('gender').value;
    this.hcp.first_name = this.form.get('first_name').value;
    this.hcp.last_name = this.form.get('last_name').value;
    this.hcp.language = this.form.get('language').value;
    this.hcp.job_title = this.form.get('job_title').value;
    this.hcp.function = this.form.get('function').value;
    this.hcp.location.name = this.form.get('location').value;
    this.hcp.location.url = this.form.get('location_url').value;
    this.hcp.profile_picture = this.form.get('profile_picture').value;

    const updateObservable: Observable<HealthCareProfessional> = (this.authenticationService.hasCcRole()) ?
      this.hcpService.updateForCcRole(this.hcp) :
      this.hcpService.updateForHcpRole(this.hcp);

    updateObservable.subscribe(
      (hcp) => this.onUpdateHCPSuccess(hcp),
      (error) => this.onRequestError(error)
    );
  }

  onUpdateHCPSuccess(hcp) {
    this.hcp = hcp;
    this.hcpService.storeCurrentHcpByModel(this.hcp);
    this.scopeService.refreshCurrentHcp().subscribe(() => this.onScopeRefreshSuccess());
  }

  onScopeRefreshSuccess() {
    const profile = this.userService.getStoredProfile();

    if(this.form.get('language').dirty) {
      profile.locale.language = this.hcp.language;

      this.languageService.setCurrentLanguageByEnum(profile.locale.language);

      this.userService.updateProfile(profile).subscribe(
        () => {
          this.languageService.reloadLanguage().subscribe(() => this.onUpdateProfileSuccess());
        },
        (error) => this.onRequestError(error)
      );
    } else {
      this.onUpdateProfileSuccess();
    }
  }

  onUpdateProfileSuccess() {
    this.isSaving = false;
    this.hcpUpdatedEvent.emit();
    this.bsModalRef.hide();
  }

  private onRequestError(error) {
    const errorArray = error?.error?.errors;
    this.isSaving = false;

    if (errorArray) {
      this.validationVisible = true;

      errorArray.forEach( err => {

        this.form.get(err.field).setErrors({
          backend_errors: true,
          message: err.key
        });
      });
    }
  }
}
