<div class="layout-header-pusher header-single-link"></div>

<div class="layout-header header-single-link">
  <div class="header-content">
    <div class="container">
      <app-breadcrumb *ngIf="!backButtonData && !isLoadingMaterial"></app-breadcrumb>

      <p *ngIf="backButtonData">
        <a href="" class="text-secondary font-weight-bold" [routerLink]="backButtonData?.url">
          <span class="rtl-mirrored-inline-svg m-end-1" [inlineSVG]="'/assets/svg/left-arrow-big.svg'"></span>
          <span class="align-middle">{{ 'action.back' | translate }}</span>
        </a>
      </p>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="isLoadingMaterial">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!isLoadingMaterial">
    <ng-container *ngIf="!material || material.isUnavailable">
      <div class="article-unavailable">
        <p class="text-muted mt-2 mb-0">{{ 'pages.default.patient_materials.unavailable' | translate }}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="material && !material.isUnavailable">
      <div class="article-content">

        <!-- content-head - start -->
        <!-- (should always be here, is not in the repeater loop) -->
        <div class="content-head">
          <h1 class="mb-1">{{ material?.title}}</h1>
          <p class="introduction">{{ material?.description }}</p>
        </div>
        <!-- content-head - end -->

        <!-- content-cover - start -->
        <div class="content-cover" *ngIf="material?.displayThumb()"
          [style.background-image]="'url(' + material.thumbnail.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ' )'">
        </div>
        <!-- content-cover - end -->

        <ng-container *ngFor="let paragraph of material?.paragraphs">
          <div *ngIf="paragraph.type === 'text'" [innerHtml]="paragraph.text" class="content-partial"></div>

          <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Right'" class="content-feature">
            <div class="feature-body">
              <div class="title" [innerHtml]="paragraph.text"></div>

            </div>
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph.image?.url + '?width=926&disable=upscale&format=pjpg&auto=webp' + ')'">
            </div>
          </div>

          <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Left'" class="content-feature">
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph.image?.url + '?width=926&disable=upscale&format=pjpg&auto=webp' + ')'">
            </div>
            <div class="feature-body">
              <div class="title" [innerHtml]="paragraph.text"></div>
            </div>
          </div>

          <!-- Banner Top -->
          <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Top'" class="content-feature-top">
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ')'">
            </div>
            <div class="feature-body bg-primary">
              <div class="title" [innerHtml]="paragraph.text"></div>

              <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="text-white"
                *ngIf="paragraph?.link?.href">
                <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href
                  }}</span>
                <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </a>
            </div>
          </div>
          <!-- /Banner Top -->

          <!-- Banner Bottom -->
          <div *ngIf="paragraph.type === 'banner'  && paragraph.image_position === 'Bottom'" class="content-feature-top">
            <div class="feature-body bg-primary">
              <div class="title" [innerHtml]="paragraph.text"></div>

              <a href="{{ paragraph?.link?.href }}" target="_blank" rel="noopener noreferrer" class="text-white"
                *ngIf="paragraph?.link?.href">
                <span class="align-middle">{{ (paragraph?.link.title)? paragraph?.link.title:paragraph?.link?.href
                  }}</span>
                <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
              </a>
            </div>
            <div class="feature-image"
              [style.background-image]="'url(' + paragraph.image?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp' + ')'">
            </div>
          </div>
          <!-- /Banner Bottom -->

          <div class="content-audio" *ngIf="paragraph.type === 'audio'">
            <div class="audio-player">
              <p class="title">{{ paragraph.file?.title}}</p>
              <audio preload="auto" autobuffer controls controlsList="nodownload">
                <source [src]="paragraph.file?.url" />
              </audio>
            </div>
          </div>

          <div class="content-pdf" *ngIf="paragraph.type === 'pdf'">
            <div class="single-item-block m-0">
              <div class="block-wrapper p-start-3 p-end-1">
                <span class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/page.svg'"></span>
              </div>
              <div class="block-wrapper">
                <p class="label">{{ paragraph.file?.title}}</p>
                <p class="value">{{ 'pages.default.patient_materials.pdf_file' | translate}}</p>
              </div>
              <a href="{{ paragraph.file?.url }}" target="_blank" class="block-action m-start-auto">
                <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/download.svg'"></span>
              </a>
            </div>
          </div>

          <div class="content-video" *ngIf="paragraph.type === 'video' && paragraph?.videos">
            <app-video-carousel class="position-absolute w-100 h-100" [videos]="paragraph.videos"></app-video-carousel>
          </div>

          <div class="content-image" *ngIf="paragraph.type === 'image' && paragraph?.file">
            <img [src]="paragraph.file?.url + '?width=1640&disable=upscale&format=pjpg&auto=webp'" alt=""/>
          </div>
        </ng-container>

        <!-- content-footer - start -->
        <div class="content-footer" *ngIf="material?.disclaimer">
          <p class="small text-muted mb-2 mb-lg-3"><em>{{ material?.disclaimer}}</em></p>
        </div>
        <!-- content-footer - end -->
      </div>
    </ng-container>
  </div>
</div>
