import { TranslationInterface } from "../interfaces/translation.interface";

enum GoalEvaluationType {
  LESS_THAN,
  LESS_THAN_OR_EQUALS,
  EQUALS,
  HIGHER_THAN_OR_EQUALS,
  HIGHER_THAN
}

enum GoalEvaluationGraphType {
  TEXT,
  PROGRESS
}

enum GoalEvaluationResult {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  ON_TARGET = 'ON_TARGET',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

interface GoalEvaluationProgress {
  current_progress: number;
  percentage: number;
  target_progress: number;
}


export class GoalEvaluation {
  public evaluation_unit: TranslationInterface;
  public evaluation_type: GoalEvaluationType;
  public evaluation_graph: GoalEvaluationGraphType;
  public evaluation_result: GoalEvaluationResult;
  public evaluation_progress: GoalEvaluationProgress;
  public evaluation_is_intermediate_result: boolean;
  public evaluation_value: number;
  public evaluation_target: number;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.evaluation_unit = item.evaluation_unit;
    this.evaluation_type = item.evaluation_type;
    this.evaluation_graph = item.evaluation_graph;
    this.evaluation_result = item.evaluation_result;
    this.evaluation_is_intermediate_result = item.evaluation_is_intermediate_result;
    this.evaluation_value = item.evaluation_value;
    this.evaluation_target = item.evaluation_target;
    this.evaluation_progress = item.evaluation_progress;
  }

  get translationEvaluationUnitKey():string {
    return `shared.${this.evaluation_unit?.region}.${this.evaluation_unit?.key}`;
  }

  available() {
    return this.evaluation_result !== GoalEvaluationResult.NOT_AVAILABLE;
  }
}
