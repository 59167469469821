<div id="zone_surgery" class="mb-2 mb-lg-3" *ngIf="tasks?.length > 0 || appointments?.length > 0">

  <div class="row">
    <div [ngClass]="(appointments?.length == 1 && tasks?.length == 0) ? 'col-12' : 'col-6'" *ngFor="let appointment of appointments | sortBy:'asc': 'date'">
      <button *ngIf="canEdit(appointment)" class="surgery-date-item-block mb-2 item-clickable btn text-align-start w-100 p-0" (click)="editSurgeryDate(appointment)">
        <div class="block-wrapper overflow-hidden">
          <p class="caption text-truncate">{{ 'pages.default.patient_detail.surgery_date.caption' | translate }}</p>
          <p class="label text-truncate">{{ appointment?.title_key | translateHelp | translate | evalTranslation }}</p>
          <p class="value text-truncate" *ngIf="appointment.date">
            <span class="text-capitalize">{{ appointment.date | weekday }}</span> {{ appointment.date | timeZoneDate }}
          </p>
          <p class="value text-truncate" *ngIf="appointment.start_date">
            <span class="text-capitalize">{{ appointment.start_date | weekday }}</span> {{ appointment.start_date | timeZoneDate }}
          </p>
        </div>

        <div class="block-action-wrapper m-start-auto" >
          <div class="block-action">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/edit.svg'"></span>
          </div>
        </div>
      </button>

      <div *ngIf="!canEdit(appointment)" class="surgery-date-item-block mb-2 text-align-start w-100 p-0">
        <div class="block-wrapper overflow-hidden">
          <p class="caption text-truncate">{{ 'pages.default.patient_detail.surgery_date.caption' | translate }}</p>
          <p class="label text-truncate">{{ appointment?.title_key | translateHelp | translate | evalTranslation }}</p>
          <p class="value text-truncate" *ngIf="appointment.date">
            <span class="text-capitalize">{{ appointment.date | weekday }}</span> {{ appointment.date | timeZoneDate }}
          </p>
          <p class="value text-truncate" *ngIf="appointment.start_date">
            <span class="text-capitalize">{{ appointment.start_date | weekday }}</span> {{ appointment.start_date | timeZoneDate }}
          </p>
        </div>
      </div>
    </div>

    <div [ngClass]="(tasks?.length == 1 && appointments?.length == 0) ? 'col-12' : 'col-6'" *ngFor="let task of tasks | sortBy:'desc': 'created_at_date'">
      <button class="surgery-date-item-block mb-2 item-clickable btn text-align-start w-100 p-0" [ngClass]="{'block-danger' : task?.passed() }" (click)="setSurgeryDate(task)">
        <div class="block-wrapper overflow-hidden">
          <p class="caption text-truncate">{{ 'pages.default.patient_detail.surgery_date.caption' | translate }}</p>
          <p class="label text-truncate">{{ task?.title | translateHelp | translate | evalTranslation }}</p>
          <p class="value text-truncate">{{ 'pages.default.patient_detail.surgery_date.set' | translate }}</p>
        </div>

        <div class="block-action-wrapper m-start-auto" >
          <div class="block-action">
            <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/edit.svg'"></span>
          </div>
        </div>
      </button>
    </div>
  </div>

</div>
