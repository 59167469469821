<div class="layout-header-pusher header-tabs"></div>

<div class="layout-header header-tabs">
  <div class="header-content">
    <div class="container">
      <h1>{{ 'pages.default.help_center.help_center' | translate }}</h1>
      <app-page-tabs [items]="pageTabItems" *ngIf="!pageLoading"></app-page-tabs>
    </div>
  </div>
</div>

<div class="body-content">
  <div class="container" *ngIf="pageLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div class="container" *ngIf="!pageLoading">

    <div id="{{ helpcenterSection.slug }}" class="mb-5" *ngFor="let helpcenterSection of helpcenterSections">
      <!-- Articles -->
      <div *ngIf="helpcenterSection.isArticles">
        <div class="heading-block mb-3">
          <div class="block-wrapper overflow-hidden py-2">
            <h2 class="text-truncate">{{ helpcenterSection?.title }}</h2>
            <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
          </div>
          <a href="" class="block-action m-start-auto" (click)="goToSectionOverview($event, helpcenterSection)"
            *ngIf="(helpcenterSection.isArticles && !helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 3) || (helpcenterSection.has2LBlocks && helpcenterSection.isArticles && helpcenterSection?.articles?.length > 5)">
            <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'"
              class="icon icon-small rtl-mirrored-inline-svg"></span>
          </a>
        </div>

        <div *ngIf="!helpcenterSection?.articles || helpcenterSection?.articles?.length == 0">
          <p class="text-center text-muted">{{ 'pages.default.help_center.no_articles_yet' | translate }}</p>
        </div>

        <div *ngIf="helpcenterSection?.articles">
          <div class="row mb-2">
            <div class="mb-2" [ngClass]="(helpcenterSection.has2LBlocks && index < 2)? 'col-6' : 'col-4'"
              *ngFor="let article of helpcenterSection?.articles; index as index">

              <a href="" (click)="goToArticleDetail($event, article, helpcenterSection)" class="card h-100"
                *ngIf="index < 3 || (helpcenterSection.has2LBlocks && index < 5)">
                <!-- Small block -->
                <div class="card-cover" *ngIf="(helpcenterSection.has2LBlocks && index > 1) || !helpcenterSection.has2LBlocks"
                  [style.background-image]="'url('+ article?.thumbnail?.image?.url + '?width=920&disable=upscale&format=pjpg&auto=webp' +')'">

                  <div class="cover-meta">
                    <div class="meta-category" *ngIf="article?.thumbnail?.icon?.includes('Video')">
                      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/play.svg'"></span>
                    </div>
                  </div>
                </div>
                <!-- /Small block -->
                <!-- Big block -->
                <div class="card-cover" *ngIf="helpcenterSection.has2LBlocks && index < 2"
                  [style.background-image]="'url('+ article?.thumbnail?.image?.url + '?width=1394&disable=upscale&format=pjpg&auto=webp' +')'">

                  <div class="cover-meta">
                    <div class="meta-category" *ngIf="article?.thumbnail?.icon?.includes('Video')">
                      <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/play.svg'"></span>
                    </div>
                  </div>
                </div>
                <!-- Big block -->

                <div class="card-body bg-primary" *ngIf="helpcenterSection.has2LBlocks && index < 2">
                  <h2 class="mb-1 text-white">{{ article.title }}</h2>
                  <p class="m-0 text-white">{{ article.description }}</p>
                </div>

                <div class="card-body"
                  *ngIf="!helpcenterSection.has2LBlocks || (helpcenterSection.has2LBlocks && index >= 2)">
                  <h3 class="mb-1">{{ article.title }}</h3>
                  <p class="m-0">{{ article.description }}</p>
                </div>

                <div class="card-footer"
                  *ngIf="!helpcenterSection.has2LBlocks || (helpcenterSection.has2LBlocks && index >= 2)">
                  <div class="btn btn-secondary m-start-auto">
                    <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                  </div>
                </div>
              </a>
            </div>

          </div>

          <div class="text-end"
            *ngIf="( !helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 3) || ( helpcenterSection.has2LBlocks && helpcenterSection?.articles?.length > 5)">
            <button (click)="goToSectionOverview($event, helpcenterSection)" class="btn btn-link d-flex m-start-auto p-end-0 align-items-center">
              <span class="align-middle">{{ 'pages.default.help_center.show_all' | translate }}</span>
              <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
            </button>
          </div>

        </div>
      </div>
      <!-- /Articles -->

      <!-- Faqs -->
      <div *ngIf="helpcenterSection.isFaqs">
        <div class="heading-block mb-3">
          <div class="block-wrapper overflow-hidden py-2">
            <h2 class="text-truncate">{{ helpcenterSection?.title }}</h2>
            <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
          </div>
          <a href="" class="block-action m-start-auto" (click)="goToSectionOverview($event, helpcenterSection)"
            *ngIf="helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow">
            <span [inlineSVG]="'/assets/svg/right-arrow-big.svg'" class="icon rtl-mirrored-inline-svg"></span>
          </a>
        </div>

        <div class="faq-block">
          <div class="items">
            <div class="faq-block"
              *ngFor="let category of helpcenterSection?.faqs?.slice(0, helpcenterSection.maxCategoriesToShow)">
              <div class="heading">
                <img [src]="category.icon" alt="">
                <h3>{{ category.title }}</h3>
              </div>
              <div class="items">
                <a href="" (click)="goToFaq($event,item, category, helpcenterSection)"
                  *ngFor="let item of category.items" class="d-flex align-items-center">
                  <span>{{ item.question }}</span>
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="text-end" *ngIf="(helpcenterSection?.faqs?.length > helpcenterSection.maxCategoriesToShow)">
          <button (click)="goToSectionOverview($event, helpcenterSection)" class="btn btn-link d-flex m-start-auto p-end-0 align-items-center">
            <span class="align-middle">{{ 'pages.default.help_center.show_all' | translate }}</span>
            <span class="rtl-mirrored-inline-svg m-start-1" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
          </button>
        </div>
      </div>
      <!-- /Faqs -->

      <!-- Contact -->
      <div *ngIf="helpcenterSection.isContact">
        <div class="heading-block mb-3">
          <div class="block-wrapper overflow-hidden py-2">
            <h2 class="text-truncate">{{ helpcenterSection?.title }}</h2>
            <p class="text-truncate">{{ helpcenterSection?.subtitle }}</p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-4" *ngFor="let contact of helpcenterSection?.contacts">
            <div class="card h-100">
              <div class="card-body">
                <img [src]="contact.icon" alt="" class="img-icon-md mb-2">
                <h3 class="mb-1">{{contact.title}}</h3>
                <p class="m-0">{{ contact.description }}</p>

                <div *ngIf="contact.isPhone">
                  <div class="btn-group text-dropdown mb-1" dropdown>
                    <button id="dropdown-btn-countries" dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                      <span class="m-end-1">{{ currentCountry.title }}</span>
                      <span class="icon" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
                    </button>

                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu"
                      aria-labelledby="dropdown-btn-countries">
                      <li *ngFor="let country of countries" role="menuitem">
                        <a class="dropdown-item" href="" (click)="changeCountry($event, country)">{{country.title}}</a>
                      </li>
                    </ul>
                  </div>

                  <p [ngClass]="{'mb-0': !currentCountry.description}">{{ currentCountry.description }}</p>

                  <ul>
                    <li *ngFor="let phone of currentCountry?.phones">
                      <p class="mb-0">
                        <span *ngIf="phone.description">{{phone.description}}: </span>
                        <span class="font-weight-bold">{{ phone.number}}</span>
                      </p>
                      <p class="text-muted mb-0">{{ phone.disclaimer }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-footer" *ngIf="contact.hyperlink">
                <a href="{{ contact.hyperlink }}" class="btn btn-secondary m-start-auto"
                  target="{{(contact.isChat)? '_blank' : '_self'}}" rel="noopener noreferrer">
                  <span class="rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Contact -->

      <!-- Banner -->
      <div *ngIf="helpcenterSection.isBanner">
        <div class="picture-card mb-4 mb-lg-5">
          <div class="card bg-info text-white">
            <div class="card-body p-3">
              <h2 class="text-white">{{ helpcenterSection?.title }}</h2>
              <p class="text-white">{{ helpcenterSection?.subtitle }}</p>

              <div *ngIf="helpcenterSection?.path?.href">
                <div class="pb-2 pb-md-3"></div>

                <a href="{{ helpcenterSection?.path.href }}" target="_blank" rel="noopener noreferrer"
                  class="text-white">
                  <span class="align-middle">{{ helpcenterSection?.path?.title }}</span>
                  <span class="rtl-mirrored-inline-svg m-start-1"
                    [inlineSVG]="'/assets/svg/right-arrow-big.svg'"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="picture" [style.background-image]="'url('+ helpcenterSection?.thumbnail?.url + '?width=1394&disable=upscale&format=pjpg&auto=webp' +')'"></div>
        </div>
      </div>
      <!-- /Banner -->

    </div>
  </div>
</div>
