import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PasswordPolicy } from '../../../models/password-policy';
import { PasswordService } from '../../../services/password.service';
import { MatchingFieldsValidator } from '../../../validators/matching-fields-validator';
import { PasswordPolicyValidator } from '../../../validators/password-policy-validator';
import { HomeFlowService } from '../../../services/home-flow.service';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-expired-password-reset',
  templateUrl: './expired-password-reset.component.html',
  styleUrls: ['./expired-password-reset.component.scss']
})
export class ExpiredPasswordResetComponent implements OnInit {
  public form: UntypedFormGroup;
  public validationVisible: boolean;
  public policy: PasswordPolicy;
  public isLoading: boolean;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public passwordService: PasswordService,
    public homeFlowService: HomeFlowService,
    public errorService: ErrorService
  ) { }

  ngOnInit() {
    this.formSetup();

    this.passwordService.policy().subscribe(policy => this.onPolicySuccess(policy));
  }

  formSetup() {
    this.form = this.formBuilder.group({
      new_password: ['', [Validators.required]],
      new_password_verification: ['', [Validators.required]]
    }, {
      validators: [MatchingFieldsValidator.validate('new_password', 'new_password_verification', 'mismatch')]
    });

    this.setPolicyValidator();
  }

  onPolicySuccess(policy: PasswordPolicy) {
    this.policy = policy;
    this.setPolicyValidator();
  }

  setPolicyValidator() {
    if(this.form && this.policy)  {
      this.form.get('new_password').setValidators([
        Validators.required,
        PasswordPolicyValidator.validate(this.policy)
      ])
    }
  }

  formSubmit() {
    if(!this.form.valid) {
      this.validationVisible = true;
    } else {
      this.validationVisible = false;
      this.resetPassword();
    }
  }

  resetPassword() {
    this.isLoading = true;

    this.passwordService.setPassword(this.form.value.new_password, this.form.value.new_password_verification).subscribe(
      () => this.onResetPasswordSuccess(),
      error => this.onResetPasswordError(error)
    );
  }

  onResetPasswordSuccess() {
    this.homeFlowService.goNextStepOrDone().subscribe(() => {
      this.isLoading = false;
    });
  }

  onResetPasswordError(response) {
    this.isLoading = false;

    if(response.error && response.error.key) {
      if(response.error.key === 'ERROR_IDENTITY_PASSWORD_USED_ALREADY'
      ) {
        this.form.get('new_password').setErrors({password_already_used: true});
        this.validationVisible = true;

      } else if(response.error.status < 500) {
        this.errorService.showGeneralBackendErrorToast();
      }
    }
  }
}


