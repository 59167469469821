import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Pathway, PatientPathwayStopReason } from '../models/pathway';
import { ScopeService } from './scope.service';
import { HcpService } from './hcp.service';
import { PhaseInstance, PhaseType } from '../models/phase-instance';
import { GeneralService } from './general.service';
import { Appointment } from '../models/appointment';
import moment from 'moment';
import { LocaleService } from './locale.service';
import { PathwayMessage } from '../models/pathway-message';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class PathwayService extends ApiService {
  public today = moment();
  public timeZone: string;

  constructor(
    http: HttpClient,
    authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public scopeService: ScopeService,
    protected authService: AuthenticationService,
    protected hcpService: HcpService,
    protected generalService: GeneralService,
    protected localeService: LocaleService,
    protected languageService: LanguageService
  ) {
    super(http, authenticationService);
  }

  //Acces: ROLE_CC
  getPathwaysByHospital(hospital_uid: string, patient_uid: string): Observable<Array<Pathway>> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapPathways(result['items']));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });

    });
  }

  //Acces: ROLE_CC
  getPathwayByHospital(hospital_uid, patient_uid, pathway_id): Observable<Pathway> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${pathway_id}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapPathway(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  //Acces: ROLE_HCP
  getPathwaysByHcp(hcp_uid: string, patient_uid: string): Observable<Array<Pathway>> {
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}/patient-pathways`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapPathways(result['items']));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });

    });
  }

  //Acces: ROLE_HCP
  getPathwayByHcp(hcp_uid, patient_uid, pathway_id): Observable<Pathway> {
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}/patient-pathways/${pathway_id}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        observer.next(this.mapPathway(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    })
  }

  putPathway(hospital_uid, patient_uid, pathway_uid, clinical_lead_uid, case_manager_uid): Observable<Pathway> {
    const data = {
      clinical_lead_uid,
      case_manager_uid
    };

    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${pathway_uid}`;

    return new Observable(observer => {
      this.authenticatedPut(url, data).subscribe(result => {
        observer.next(this.mapPathway(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  postPathway(
    hospital_uid: string,
    patient_uid: string,
    care_module_uid: string,
    clinical_lead_uid: string,
    case_manager_uid
  ): Observable<Pathway> {
    const data = {
      care_module_uid: care_module_uid,
      clinical_lead_uid: clinical_lead_uid,
      case_manager_uid: case_manager_uid
    }

    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways`;
    return new Observable(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next(new Pathway(result));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  addMdt(hospital_uid, patient_uid, pathway_uid, mdt_uid): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${pathway_uid}/mdts`;

    return new Observable(observer => {
      this.authenticatedPost(url, { mdt_uid: mdt_uid }).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  addHcp(hospital_uid, patient_uid, pathway_uid, hcp_uid): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${pathway_uid}/hcps`;

    return new Observable(observer => {
      this.authenticatedPost(url, { hcp_uid: hcp_uid }).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  deleteHcp(hospital_uid, patient_uid, pathway_uid, hcp_uid): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${pathway_uid}/hcps/${hcp_uid}`;

    return new Observable(observer => {
      this.authenticatedDelete(url).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  deleteMdt(hospital_uid, patient_uid, pathway_uid, mdt_uid): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospital_uid}/patients/${patient_uid}/patient-pathways/${pathway_uid}/mdts/${mdt_uid}`;

    return new Observable(observer => {
      this.authenticatedDelete(url).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  mapPathways(result: Array<any>): Array<Pathway> {
    const pathways = new Array();
    for (const item of result) {
      const pathway = this.mapPathway(item);
      pathways.push(pathway);
    }
    return pathways;
  }

  mapPathway(item): Pathway {
    return new Pathway(item);
  }

  postablePathway(pathway: Pathway) {
    const resp: any = {};

    if (pathway.patient_mdt) {
      if (pathway.patient_mdt.hcps) {
        resp.hcps = pathway.patient_mdt.hcps.map(hcp => hcp.uid);
      }

      if (pathway.patient_mdt.mdts) {
        resp.mdts = pathway.patient_mdt.mdts.map(mdt => mdt.uid);
      }
    }

    return resp;
  }

  // Acces: ROLE_HCP
  getDashboardPatientPathwayByHcp(hcpUid: string, patientUid: string, patientPathwayId: string): Observable<Pathway> {
    const url = `${environment.platformUrl}/dashboards/hcps/${hcpUid}/patients/${patientUid}/patient-pathways/${patientPathwayId}`;

    return new Observable<Pathway>(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const pathwayDashboard = new Pathway(result);
        observer.next(pathwayDashboard);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  // Acces: ROLE_CC
  getDashboardPatientPathwayByHospital(hospitalUid: string, patientUid: string, patientPathwayId: string): Observable<Pathway> {
    const url = `${environment.platformUrl}/dashboards/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayId}`;

    return new Observable<Pathway>(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const pathwayDashboard = new Pathway(result);
        observer.next(pathwayDashboard);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getPathWayPhases(patientUid: string, patientPathwayId: string): Observable<PhaseInstance[]> {
    const pathWayObservable = this.authService.hasCcRole()
      ? this.getDashboardPatientPathwayByHospital(this.hcpService.getCurrentStoredHospitalUid(), patientUid, patientPathwayId)
      : this.getDashboardPatientPathwayByHcp(this.hcpService.getCurrentStoredHcpUid(), patientUid, patientPathwayId);

    return new Observable<PhaseInstance[]>(observer => {
      pathWayObservable.subscribe(pathwayDashboard => {

        const phases: PhaseInstance[] = this.mapPhases(pathwayDashboard.phases);

        observer.next(phases);
        observer.complete();
      });
    });
  }

  getPathwayPhasesWithAppointments(patientUid: string, patientPathwayId: string): Observable<{ phases: PhaseInstance[], appointments: Appointment[] }> {
    const pathWayObservable = this.authService.hasCcRole()
      ? this.getDashboardPatientPathwayByHospital(this.hcpService.getCurrentStoredHospitalUid(), patientUid, patientPathwayId)
      : this.getDashboardPatientPathwayByHcp(this.hcpService.getCurrentStoredHcpUid(), patientUid, patientPathwayId);

    return new Observable<{ phases: PhaseInstance[], appointments: Appointment[] }>(observer => {
      pathWayObservable.subscribe(pathwayDashboard => {

        const phases: PhaseInstance[] = this.mapPhases(pathwayDashboard.phases);
        const appointments: Appointment[] = pathwayDashboard.appointments;

        observer.next({ phases, appointments });
        observer.complete();
      });
    });
  }

  mapPhases(phases: PhaseInstance[]): PhaseInstance[] {
    if (!phases?.length) {
      phases = [];
    }

    this.generalService.sortByKey(phases, 'order');

    phases.forEach(phase => {
      phase.type = PhaseType.PHASE;

      if (phase.sub_phase_instances) {
        this.generalService.sortByKey(phase.sub_phase_instances, 'order');
        phase.sub_phase_instances.forEach(subPhase => {
          subPhase.type = PhaseType.SUBPHASE;
        });
      }
    });

    return phases;
  }

  getMessages(
    filters: any = {},
    page = 0,
    size = 10,
    sort: string[] = []
  ): Observable<{
    messages: Array<any>,
    pagination: any
  }> {
    const hcp_uid: string = this.hcpService.getCurrentStoredHcpUid();

    const paramBits = [
      `page=${page}`,
      `size=${size}`
    ];

    /*
    ** Filter - Read Status - Start
    */
    if (filters.hasOwnProperty('read_status') && filters.read_status.length) {
      paramBits.push(`read_status=${filters.read_status}`);
    } else {
      paramBits.push(`read_status=ALL`);
    }
    /*
    ** Filter - Read Status - Start
    */


    /*
    ** Filter - Patient UID - Start
    */
    if (filters.hasOwnProperty('patient_uid') && filters.patient_uid !== null) {
      paramBits.push(`patient_uid=${filters.patient_uid}`);
    }
    /*
    ** Filter - Patient UID - End
    */


    /*
    ** Filter - Care Module Uid(s) - Start
    */
    let care_module_uid = filters.care_module_uid || filters.care_module_uids;
    if (care_module_uid) {
      if (Array.isArray(care_module_uid)) {
        care_module_uid.forEach(cmu => {
          paramBits.push(`care_module_uid=${cmu}`);
        });
      } else {
        paramBits.push(`care_module_uid=${care_module_uid}`);
      }
    }
    /*
    ** Filter - Care Module Uid(s) - End
    */

    if (sort.length) {
      sort.forEach(s => {
        paramBits.push(`sort=${s}`);
      });
    }

    const paramsString = paramBits.join('&');
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patient-pathway-messages?${paramsString}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const messages = result?.items.map((item: any) => {
          return new PathwayMessage(item);
        });

        observer.next({ 'messages': messages, 'pagination': result['pagination'] });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getMessage(pathway_message_id: string): Observable<PathwayMessage> {
    const hcp_uid: string = this.hcpService.getCurrentStoredHcpUid();
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patient-pathway-messages/${pathway_message_id}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const pMessage: PathwayMessage = new PathwayMessage(result);

        this.getMessageContent(pMessage).subscribe(result => {
          observer.next(pMessage);
          observer.complete();
        }, err => {
          observer.error(err);
          observer.complete();
        });
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getMessageContent(pMessage: PathwayMessage): Observable<any> {
    return new Observable(observer => {
      const locale = this.languageService.getCurrentLanguage()?.locale;
      this.cmsGetContent(pMessage?.message, locale).subscribe(result => {
        if (result?.paragraphs?.items) {
          const texts: Array<string> = result?.paragraphs?.items.map(item => {
            return item?.text?.text || '';
          });

          pMessage.addMessageContent(texts);
        }

        observer.next(pMessage);
        observer.complete();
      }, err => {
        observer.error(err);
        observer.complete();
      });
    });
  }

  toggleReadStatusMessage(messageId: string): Observable<any> {
    return new Observable(observer => {
      const hcp_uid: string = this.hcpService.getCurrentStoredHcpUid();
      const url = `${environment.platformUrl}/hcps/${hcp_uid}/patient-pathway-messages/${messageId}`;

      this.authenticatedPatch(url, {}).subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      })
    });
  }

  readMessage(pathwayMessage: PathwayMessage, readStatus: boolean = true): Observable<any> {
    return new Observable(observer => {
      if (pathwayMessage.read_at && readStatus) {
        observer.next();
        observer.complete();
        return;
      } else if (!pathwayMessage.read_at && !readStatus) {
        observer.next();
        observer.complete();
        return;
      }

      this.toggleReadStatusMessage(pathwayMessage.id).subscribe({
        next: result => {
          observer.next(result);
          observer.complete();
        }, error: error => {
          observer.error(error);
          observer.complete();
        }
      })
    });
  }

  getPatientMessages(patient_uid: string, patient_pathway_id: string, read_status: string = 'ALL'): Observable<{
    messages: Array<PathwayMessage>,
    pagination: any
  }> {
    const paramBits = [
      `page=0`,
      `size=25`,
      `read_status=${read_status}`
    ];

    const paramsString = paramBits.join('&');

    const hcp_uid: string = this.hcpService.getCurrentStoredHcpUid();
    const url = `${environment.platformUrl}/hcps/${hcp_uid}/patients/${patient_uid}/patient-pathways/${patient_pathway_id}/messages${paramsString}`;

    return new Observable(observer => {
      this.authenticatedGet(url).subscribe(result => {
        const messages = result?.items.map((item: any) => {
          return new PathwayMessage(item);
        });

        observer.next({ 'messages': messages, 'pagination': result['pagination'] });
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  stopPatientPathway(
    hospitalUid: string,
    patientPathwayId: string,
    patientUid: string,
    reason_code: PatientPathwayStopReason,
    reason?: string): Observable<any> {
    const url = `${environment.platformUrl}/hospitals/${hospitalUid}/patients/${patientUid}/patient-pathways/${patientPathwayId}/stop`;

    let data = (reason) ? { reason_code, reason } : { reason_code };

    return new Observable<any>(observer => {
      this.authenticatedPost(url, data).subscribe(result => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
