import { Component, OnInit } from '@angular/core';
import { CareModule } from '../../../../models/care-module';
import { PhaseDna } from '../../../../models/phase-dna';
import { HcpService } from '../../../../services/hcp.service';
import { HospitalService } from '../../../../services/hospital.service';

@Component({
  selector: 'app-dna-pathways-overview',
  templateUrl: './dna-pathways-overview.component.html'
})
export class DnaPathwaysOverviewComponent implements OnInit {
  public selectedCareModule: CareModule;
  public phase: PhaseDna;

  public selectedCareModuleUid: string;
  public careModules: CareModule[];
  public view: "careModule" | 'phase' = 'careModule';
  public breadcrumb = [];


  constructor(
    public hcpService: HcpService,
    public hospitalService: HospitalService
  ) { }

  ngOnInit(): void {
    this.getCareModules();
  }

  getCareModules() {
    const hospitalUid = this.hcpService.getCurrentStoredHospitalUid();
    this.hospitalService.getCareModules(hospitalUid).subscribe(result => {
      this.careModules = result;
      this.selectedCareModule = this.careModules[0];
      this.selectedCareModuleUid = this.selectedCareModule.uid;
    })
  }

  onCareModuleChange() {
    //this.getAnalytics();

    this.selectedCareModule = this.careModules.find((_cm: CareModule) => {
      return _cm.uid == this.selectedCareModuleUid;
    });
  }

  selectPhase(phase: PhaseDna, event?: MouseEvent) {
    event?.preventDefault();

    this.view = "phase";
    this.phase = phase;

    const index = this.breadcrumb.indexOf(phase);

    if(index > -1) {
      this.breadcrumb = this.breadcrumb.slice(0, (index +1) );
    } else {
      this.breadcrumb.push(phase);
    }
  }

  selectModule(event: MouseEvent) {
    event?.preventDefault();

    this.view = "careModule";
    this.phase = undefined;
    this.breadcrumb = [];
  }

  currentPhase(phase) {
    return this.breadcrumb.slice(-1)[0] === phase;
  }
}
