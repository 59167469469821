import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Conversation } from '../../models/conversation';
import { AttentionCenterService } from '../../services/attention-center.service';
import { ConversationService } from '../../services/conversation.service';

@Component({
  selector: 'app-conversation-modal',
  templateUrl: './conversation-modal.component.html'
})

export class ConversationModalComponent implements OnInit, OnDestroy {
  public currentConversation: Conversation = new Conversation();
  public conversationUid;

  @Output() onAllEntriesRead: EventEmitter<any> = new EventEmitter();

  public onNewConversationMessagesUnreadSubscription: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    public conversationService: ConversationService,
    public attentionCenterService: AttentionCenterService
  ) { }

  ngOnInit(): void {
    this.loadConversation();

    this.onNewConversationMessagesUnreadSubscription = this.attentionCenterService.onNewConversationMessagesUnread.subscribe(() => {
      this.loadConversation();
    })
  }

  ngOnDestroy() {
    this.onNewConversationMessagesUnreadSubscription?.unsubscribe();
  }

  public loadConversation() {
    this.conversationService.getConversation(this.conversationUid).subscribe(_convo => {
      if(this.currentConversation) {
        _convo.items = this.currentConversation.items;
      }

      this.currentConversation = _convo;
    });
  }
}
