import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { QueryList, QueryListStatus } from "../../models/query-list";
import { HcpService } from "../../services/hcp.service";
import { QueryListService } from "../../services/query-list.service";

@Component({
  selector: 'app-patient-detail-essential-forms',
  templateUrl: './patient-detail-essential-forms.component.html'
})
export class PatientDetailEssentialFormsComponent implements OnInit, OnChanges {
  public QueryListStatus = QueryListStatus;
  public hcpUid: string;

  @Input() patientUid: string;
  @Input() pathwayUid: string;

  queryLists: QueryList[];
  loading: boolean;
  totalForms: number;


  constructor(
    private hcpService: HcpService,
    public   queryListService: QueryListService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['pathwayUid']) {
      this.loadForms();
    }
  }

  grayedOut(form): boolean {
    return (form.status === QueryListStatus.COMPLETED|| form.status === QueryListStatus.TIMED_OUT);
  }

  loadForms(showLoading = true) {
    this.hcpUid = this.hcpService.getCurrentStoredHcpUid();

    if (showLoading) {
      this.queryLists = [];
      this.loading = true;
    }

    const observable = this.queryListService.getQueryLists(this.hcpUid, this.patientUid, this.pathwayUid, {query_list_type: 'QUESTIONNAIRE'}, 'status,desc,changed_at', 0, 3);

    observable.subscribe(result => {
      this.loading = false;
      this.queryLists = result['queryLists'];
      this.totalForms = result['pagination']['total_elements'];
    });
  }
}
