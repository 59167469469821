import { MaterialParagraph } from './material-paragraph';
import { TranslationInterface } from '../interfaces/translation.interface';


export class Material {
  public uid: string;
  public title: string;
  public description:string;
  public thumbnail:any;
  public paragraphs: MaterialParagraph[];
  public disclaimer: string;
  public phaseName: TranslationInterface;
  public progress: number = 0;
  public reference;
  public order;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);

    }
  }

  fillFromJson(item) {
    this.uid = item.uid;
    this.title = item.title;
    this.description = item.description;
    this.thumbnail = item.thumbnail;
    this.disclaimer = item.disclaimer;
    this.reference = item.reference;
    this.progress = item.progress;
    this.order = item.order;

    this.paragraphs = [];

    if(item.paragraphs) {
      item.paragraphs.items.forEach(paragraph => {
        this.paragraphs.push(new MaterialParagraph(paragraph));
      });
    }
  }

  displayThumb(): boolean {
    return ((!this.paragraphs?.length || this.paragraphs[0].type === 'video') ? false : true);
  }

  public get cmsUid(): string {
    return this.reference?.key;
  }

  public get isArticle(): boolean {
    if (!this.thumbnail) return false;

    const icon = this.thumbnail.icon;
    return (icon.includes('HTML'));
  }

  public get isMedia(): boolean {
    if (!this.thumbnail) return false;

    const icon = this.thumbnail.icon;
    return (icon.includes('Video') || icon.includes('Audio'));
  }

  public get isDocument(): boolean {
    if (!this.thumbnail) return false;

    const icon = this.thumbnail.icon;
    return icon.includes('PDF');
  }

  public get isCompleted(): boolean {
    return Number(this.progress) >= 100;
  }

  public get isStarted(): boolean {
    return Number(this.progress) > 0 && Number(this.progress) < 100;
  }

  public get isUnstarted(): boolean {
    return Number(this.progress) == 0;
  }

  public get isUnavailable(): boolean {
    return !this.title;
  }
}
