<div [ngClass]="{'validation-visible': validationVisible}" class="modal-body">

  <div class="position-relative pb-3">
    <button (click)="onHandleCancel()" aria-label="Close" class="close" data-dismiss="modal" id="assign-mdt-modal-close"
            type="button">
      <span [inlineSVG]="'/assets/svg/close.svg'"></span>
    </button>

    <h2 *ngIf="type === 'add'">{{ 'pages.default.dashboard.assign_mdt_to_patient' | translate }}</h2>
    <h2 *ngIf="type === 'edit'">{{ 'pages.default.dashboard.edit_mdt_for_patient' | translate }}</h2>
    <h2
      *ngIf="type === 'bulk-assign'">{{ 'modals.assign_mdt.bulk_edit_mdt_for_patient' | translate:{nbOfPatients: this.patientList?.length} }}</h2>
  </div>

  <div class="form-group styled floating-label">
    <ng-select
      (change)="onAddMdtOrHcp()"
      (search)="onSearch($event)"
      [(ngModel)]="mdtOrHcpSelect"
      [clearable]="true"
      [items]="allMdtsOrHcps"
      [placeholder]="'modals.edit_mdt.search_team_hcp' | translate"
      [searchFn]="customSearchFn"
      class="users-select"
    >

      <ng-template let-item="item" ng-label-tmp>
        <span *ngIf="item?.className === 'MedicalTeam'">{{item?.name}}</span>
        <span *ngIf="item?.className === 'HealthCareProfessional'">{{item?.getFullName()}}</span>
      </ng-template>

      <ng-template let-index="index"
                   let-item="item"
                   let-search="searchTerm"
                   ng-option-tmp>
        <div *ngIf="item?.className === 'MedicalTeam'"
             class="wrapper"
             id="mdt-info">
          <div class="avatar">
            <span [inlineSVG]="'/assets/svg/community.svg'" class="icon"></span>
          </div>
          <div class="user-info">
            <p class="title">{{item?.name}}</p>
            <p class="sub-text">{{item?.hcpsAllNames}}</p>
          </div>
          <div *ngIf="isMdtSelected(item)" class="status small text-muted">
            <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
          </div>
          <div *ngIf="!isMdtSelected(item)" class="status text-secondary">
            <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
        <div *ngIf="item?.className === 'HealthCareProfessional'" class="wrapper" id="hcp-info">
          <app-avatar [profilePicture]="item?.profile_picture"></app-avatar>

          <div class="user-info">
            <p class="title">{{item?.getFullName()}}</p>
            <p class="sub-text">{{ item?.job_title }}</p>
          </div>
          <div *ngIf="isHcpSelected(item)" class="status small text-muted">
            <span>{{ 'modals.assign_mdt.already_added' | translate }}</span>
          </div>
          <div *ngIf="!isHcpSelected(item)" class="status text-secondary">
            <span [inlineSVG]="'/assets/svg/add.svg'" class="icon"></span>
            <span>{{ 'action.add' | translate }}</span>
          </div>
        </div>
      </ng-template>

    </ng-select>
  </div>

  <div *ngIf="isLoading">
    <app-page-loader></app-page-loader>
  </div>

  <div *ngIf="patientMdt?.mdts?.length" class="mt-4">
    <p class="mb-2">{{ 'modals.assign_mdt.teams' | translate }}</p>

    <div *ngFor="let mdt of patientMdt.mdts" class="participant-item">
      <div class="avatar">
        <span [inlineSVG]="'/assets/svg/community.svg'" class="icon"></span>
      </div>
      <div class="meta">
        <p class="title">{{ mdt?.name }}</p>
        <p class="description">
          <span *ngFor="let hcp of mdt?.hcps; let i=index">
            <span>{{ hcp?.getFullName() }}</span>
            <span *ngIf="(i < (mdt?.hcps.length - 1))">, </span>
          </span>
        </p>
      </div>
      <a (click)="onRemoveMdt($event, mdt)" [id]="'assign-mdt-modal-remove-mdt-' + mdt.uid"
         class="status text-secondary"
         href="">
        <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
  </div>

  <div *ngIf="patientMdt?.otherHcps?.length" class="mt-4">
    <p class="mb-2">{{ 'modals.assign_mdt.other_hcps' | translate }}</p>

    <div *ngFor="let hcp of patientMdt.otherHcps" class="participant-item">
      <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>

      <div class="meta">
        <p class="title">{{ hcp?.getFullName() }}</p>
        <p class="description">{{ hcp?.job_title }}</p>
      </div>
      <a (click)="onRemoveHcp($event, hcp)"
         [id]="'assign-mdt-modal-remove-hcp-' + hcp.uid"
         class="status text-secondary"
         href="">
        <span [inlineSVG]="'/assets/svg/close.svg'" class="icon"></span>
        <span>{{ 'action.remove' | translate }}</span>
      </a>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="m-start-auto">
    <button (click)="onHandleCancel()"
            class="btn btn-link"
            id="assign-mdt-modal-cancel">
      {{ 'action.cancel' | translate }}
    </button>
    <button (click)="onHandleAssign()"
            [disabled]="buttonDisabled()"
            [ngClass]="{'loader': isSaving}"
            class="btn btn-secondary m-start-3"
            id="assign-mdt-modal-save">
      <span class="loader"><em *ngIf="isSaving" class="spinner-border spinner-border-sm"></em></span>
      <span>{{ 'action.save' | translate }}</span>
    </button>
  </div>
</div>
