<div class="layout-page-wrapper">
  <div class="layout-header-pusher header-back"></div>

  <div class="layout-header header-back">
    <div class="header-content">
      <div class="container">
        <app-breadcrumb *ngIf="!isLoading"></app-breadcrumb>
      </div>

      <div class="container">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h1 class="mb-0 pb-0">{{ careModule?.translationKey | translate }}</h1>

          <p class="mb-0 muted" *ngIf="careModule?.isClinical">{{
            'pages.default.administration.care_module_detail.clinical' | translate }}</p>
          <p class="mb-0 muted" *ngIf="careModule?.isEducation">{{
            'pages.default.administration.care_module_detail.education' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="body-content">
    <div *ngIf="isLoading" class="container">
      <app-page-loader></app-page-loader>
    </div>

    <div *ngIf="!isLoading" class="container">
      <h2 class="mb-3">{{ 'pages.default.administration.care_module_detail.care_module_default_settings' | translate }}
      </h2>

      <div class="heading-block mb-4">
        <div class="block-wrapper overflow-hidden">
          <h2 class="text-truncate">{{ 'pages.default.administration.care_module_detail.hospital_team' | translate }}
          </h2>
        </div>
        <button class="block-action m-start-auto" (click)="editHospitalTeam($event)" id="edit-hospital-team">
          <span>{{ 'pages.default.administration.care_module_detail.edit_hospital_team' | translate }}</span>
          <span class="icon m-start-1" [inlineSVG]="'/assets/svg/edit.svg'"></span>
        </button>
      </div>

      <div class="row mb-3">
        <div id="default_clinical_lead" class="col-6 mb-2">
          <h3>{{ 'pages.default.administration.care_module_detail.default_clinical_lead' | translate }}</h3>

          <p *ngIf="!careModule?.default_clinical_lead?.uid">{{
            'pages.default.administration.care_module_detail.no_clinical_lead_selected' | translate }}</p>

          <div class="participant-item bg-white" *ngIf="careModule?.default_clinical_lead?.uid">
            <app-avatar [profilePicture]="careModule?.default_clinical_lead?.profile_picture"></app-avatar>
            <div class="meta">
              <p class="title">{{careModule?.default_clinical_lead?.getFullName()}}</p>
              <p class="description">{{ careModule?.default_clinical_lead?.job_title }}</p>
            </div>
          </div>
        </div>

        <div id="default_case_manager" class="col-6 mb-2">
          <h3>{{ 'pages.default.administration.care_module_detail.default_case_manager' | translate }}</h3>

          <p *ngIf="!careModule?.default_case_manager?.uid">{{
            'pages.default.administration.care_module_detail.no_case_manager_selected' | translate }}</p>

          <div class="participant-item bg-white" *ngIf="careModule?.default_case_manager?.uid">
            <app-avatar [profilePicture]="careModule?.default_case_manager?.profile_picture"></app-avatar>
            <div class="meta">
              <p class="title">{{careModule?.default_case_manager?.getFullName()}}</p>
              <p class="description">{{ careModule?.default_case_manager?.job_title }}</p>
            </div>
          </div>
        </div>
      </div>

      <div id="default-mdts" class="mb-4">
        <h3>{{ 'pages.default.administration.care_module_detail.default_mdts' | translate }}</h3>

        <p *ngIf="!careModule?.default_mdts?.length">{{
          'pages.default.administration.care_module_detail.no_mdt_selected' | translate }}</p>

        <div *ngFor="let mdt of careModule?.default_mdts; let index = index;">
          <div id="mdt-{{index}}" class="action-item-block mb-2">
            <div class="block-wrapper px-3 pt-3 pb-1 w-100">
              <h3>{{mdt.name}}</h3>

              <div class="row">
                <div class="col-4 mb-2" *ngFor="let hcp of mdt.hcps">
                  <div class="participant-item">
                    <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
                    <div class="meta">
                      <p class="title">{{hcp.getFullName()}}</p>
                      <p class="description">{{ hcp.job_title }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="default-hcps" class="mb-4">
        <h3>{{ 'pages.default.administration.care_module_detail.default_hcps' | translate }}</h3>

        <p *ngIf="!careModule?.default_hcps?.length">{{
          'pages.default.administration.care_module_detail.no_hcps_selected' | translate }}</p>

        <div class="row">
          <div class="col-6 mb-2" *ngFor="let hcp of careModule?.default_hcps; let index = index;">
            <div class="participant-item bg-white">
              <app-avatar [profilePicture]="hcp?.profile_picture"></app-avatar>
              <div class="meta">
                <p class="title">{{hcp?.getFullName()}}</p>
                <p class="description">{{ hcp?.job_title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
