import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Chart } from 'angular-highcharts';
import { merge } from 'lodash';

import { AnalyticsData, AnalyticsService } from '../../../../services/analytics.service';
import { HcpService } from '../../../../services/hcp.service';
import { UserService } from '../../../../services/user.service';
import { DnaOnboardingDashboard } from '../../../../models/dna-onboarding-dashboard';
import { AnalyticsPeriod } from '../../../../enums/analytics-period';
import { DateFormat } from '../../../../models/date-format';
import { LocaleService } from '../../../../services/locale.service';
import { GeneralService } from '../../../../services/general.service';
import { TimeZoneDatePipe } from '../../../../pipes/tz_date.pipe';
import { OnboardingChartPeriod } from '../../../../models/onboarding-chart-period';
import { TranslateService } from '@ngx-translate/core';
import { Kpi } from '../../../../models/kpi';

@Component({
  selector: 'app-dna-patient-onboarding',
  templateUrl: './dna-patient-onboarding.component.html',
  styleUrls: ['./dna-patient-onboarding.component.scss']
})
export class DnaPatientOnboardingComponent implements OnInit {
  public AnalyticsPeriod = AnalyticsPeriod;

  public isLoading: boolean = false;
  public currentView: AnalyticsPeriod;
  public currentFilters: any = {};
  public numPeriods: number = 13;
  public fromPeriods: string;
  public hospital: string;

  public onboardingData: DnaOnboardingDashboard;
  public patientStatusOverTimeData: DnaOnboardingDashboard;
  public kpis: Kpi[];

  public patientOnboardingChart: Chart;
  public patientStatusOverTime: Chart;

  public dateFormat: DateFormat;
  public timeZone: string;

  constructor(
    public hcpService: HcpService,
    public userService: UserService,
    public analyticsService: AnalyticsService,
    public localeService: LocaleService,
    public generalService: GeneralService,
    public timeZoneDatePipe: TimeZoneDatePipe,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.hospital = this.hcpService.getCurrentStoredHospitalUid();
    this.dateFormat = this.localeService.getLocalePreferences().dateFormat;

    this.changeViewTo('week');
  }

  initPatientOnboardingDashboard(): void {
    this.isLoading = true;

    forkJoin([
      this.getData(AnalyticsData.PATIENT_ONBOARDING),
      this.getData(AnalyticsData.PATIENT_STATUS_OVER_TIME)
    ]).subscribe({
      next: ([onboardingData, patientStatusOverTimeData]) => {
        this.onboardingData = onboardingData;
        this.kpis = this.forceOrderKpis(onboardingData.status_kpis);
        this.patientStatusOverTimeData = patientStatusOverTimeData;
        this.isLoading = false;

        this.initPatientOnboardingChart(onboardingData.status_data);
        this.initPatientStatusOverTimeChart(patientStatusOverTimeData.status_data);

      }, error: () => this.isLoading = false
    });
  }

  forceOrderKpis(kpis: Kpi[]): Kpi[] {
    // I know this is dirty. But somebody has to do it.
    const successfulKpi = kpis.find(kpi => kpi.title === 'successful');
    const unsuccessfulKpi = kpis.find(kpi => kpi.title === 'unsuccessful');
    const hospitalApprovalPendingKpi = kpis.find(kpi => kpi.title === 'hospital_approval_pending');
    const consentPendingKpi = kpis.find(kpi => kpi.title === 'consent_pending');

    return [successfulKpi, hospitalApprovalPendingKpi, consentPendingKpi, unsuccessfulKpi];
  }

  changeViewTo(view: 'week' | 'month'): void {
    if(view === 'week') {
      // change the week view based upon user profile setting
      const userPreferenceIsSunday = (this.userService.getStoredProfile().locale.first_day_of_week === 'SUNDAY');

      if (userPreferenceIsSunday) {
        this.currentView = AnalyticsPeriod.WEEK_SUNDAY;
      } else {
        this.currentView = AnalyticsPeriod.WEEK_MONDAY;
      }
    }

    if(view === 'month') {
      this.currentView = AnalyticsPeriod.MONTH;
    }

    // change from date based upon selected unit & number of periods
    // const unit = (view == AnalyticsPeriod.MONTH) ? 'months' : 'weeks';
    // this.fromPeriods = moment().subtract(this.numPeriods, unit).format('YYYY-MM-DD');

    this.updateCharts();
  }

  changeFiltersTo(filters: Object): void {
    this.currentFilters = filters;

    this.updateCharts();
  }

  updateCharts(): void {
    this.initPatientOnboardingDashboard();
  }

  initPatientOnboardingChart(data: OnboardingChartPeriod[]): void {
    this.patientOnboardingChart = new Chart(merge(this.getBaseChartParams(), {
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: this.mapChartCategoryData(data)
      },
      series: this.mapChartSeriesData(data),
      tooltip: {
        formatter: function () {
          let list = '';
          this.points.forEach((point) => {
            list += `<li><span style="background-color: ${point.color}"></span>${point.y}</li>`;
          });

          return `<small>${this.points[0].x}</small><ul>${list}</ul>`;
        }
      }
    }));
  }

  mapChartCategoryData(data: OnboardingChartPeriod[]): string[] {
    return data.map(d => {
      if (this.currentView === AnalyticsPeriod.WEEK_MONDAY || this.currentView === AnalyticsPeriod.WEEK_SUNDAY) {
        return this.timeZoneDatePipe.transform(d.period.week_start_date, this.dateFormat.format);
      }

      if (this.currentView === AnalyticsPeriod.MONTH) {
        return this.timeZoneDatePipe.transform(d.period.month_start_date, this.dateFormat.format);
      }
    })
  }

  mapChartSeriesData(data: OnboardingChartPeriod[]): any {
    return [
      {
        color: '#000099',
        name: this.translateService.instant('pages.default.analytics.onboarding.patient_onboarding_chart.successful.name'),
        data: data.map(d => {
          if (d.successful === 0) return null
          return d.successful;
        })
      },
      {
        color: '#888B8D',
        name: this.translateService.instant('pages.default.analytics.onboarding.patient_onboarding_chart.hospital_approval_pending.name'),
        data: data.map(d => {
          if (d.hospital_approval_pending === 0) return null
          return d.hospital_approval_pending;
        })
      },
      {
        color: '#CC0099',
        name: this.translateService.instant('pages.default.analytics.onboarding.patient_onboarding_chart.consent_pending.name'),
        data: data.map(d => {
          if (d.consent_pending === 0) return null
          return d.consent_pending;
        })
      },
      {
        color: '#CA001B',
        name: this.translateService.instant('pages.default.analytics.onboarding.patient_onboarding_chart.unsuccessful.name'),
        data: data.map(d => {
          if (d.unsuccessful === 0) return null
          return d.unsuccessful;
        })
      }
    ]
  }

  initPatientStatusOverTimeChart(data: OnboardingChartPeriod[]): void {
      this.patientStatusOverTime = new Chart(merge(this.getBaseChartParams(), {
        chart: {
          type: 'line'
        },
        xAxis: {
          categories: this.mapChartCategoryData(data)
        },
        series: [
          {
            color: '#000099',
            name: this.translateService.instant('pages.default.analytics.onboarding.patient_onboarding_chart.active.name'),
            data: data.map(d => {
              return d.active;
            })
          },
        ],
        tooltip: {
          formatter: function() {
            return `<small>${ this.x }</small><br /> <strong>${ this.y }</strong>`;
          }
        }
      }));
  }

  getData(chartName: AnalyticsData): Observable<DnaOnboardingDashboard> {
    return this.analyticsService.onboarding(
      chartName,
      this.hospital,
      this.currentView,
      this.numPeriods,
      null,
      this.currentFilters
    );
  }

  getBaseChartParams(): any {
    const docDirIsRTL: boolean = document?.documentElement?.dir === 'rtl';

    let baseChartParams: any = {
      chart: {
        spacing: [40, 0, 4, 0],
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'inherit'
        }
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        shared: true,
        borderRadius: 0,
        borderWidth: 1,
        borderColor: '#F4F4F4'
      },
      legend: {
        rtl: docDirIsRTL,
        align: 'left',
        margin: 24,
        padding: 0,
        itemStyle: {
          fontSize: '14px',
          fontWeight: 'normal',
          color: '#212121',
          cursor: 'default'
        },
        itemHoverStyle: {
          color: '#212121'
        },
        squareSymbol: true,
        symbolRadius: 0
      },
      xAxis: {
        lineColor: '#D8D8D8',
        lineWidth: 2,
        labels: {
          useHTML: true,
          style: {
            fontSize: '14px',
            fontWeight: '500',
            color: '#212121'
          }
        }
      },
      yAxis: {
        title: null,
        gridLineColor: '#F4F4F4',
        gridLineWidth: 1,
        labels: {
          align: 'left',
          padding: 0,
          x: 3,
          y: -6,
          style: {
            fontSize: '16px',
            fontWeight: '450',
            color: '#888B8D'
          }
        }
      },
      plotOptions: {
        line: {
          legendSymbol: 'rectangle',
          marker: {
            states: {
              hover: {
                lineWidth: 0,
                radiusPlus: 0
              }
            }
          }
        },
        column: {
          legendSymbol: 'rectangle',
          stacking: 'normal',
          borderWidth: 0,
          borderRadius: 0,
          dataLabels: {
            enabled: true,
            overflow: 'allow',
            style: {
              fontSize: '14px',
              fontWeight: '500',
              color: '#FFFFFF'
            }
          }
        },
        series: {
          inactiveOtherPoints: true,
          events: {
            legendItemClick: () => { return false; }
          }
        }
      }
    };

    return baseChartParams;
  }
}
